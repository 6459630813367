import { Transforms, Editor, Range } from 'slate';
import { createTable } from '../creator';
import { options } from "../options"
import { generateItemIds } from "components/editor/legal/helpers/node";

export function insertTable(editor, size = [3,3]) {
  if (!editor.selection) return;

  const node = Editor.above(editor, {
    match: n => n.type === options.tableType,
  });

  const isCollapsed = Range.isCollapsed(editor.selection);

  if (!node && isCollapsed) {
    let [rows, cols] = size
    if(rows === 0) rows = 1
    if(cols === 0) cols = 1
    const table = createTable(rows, cols);
    generateItemIds(table)
    Transforms.insertNodes(editor, table);
  }
}
