import { isBlock, isInline, isText, ensureProperChildren } from '../../../../types/elements';

function isRepeatableHolder(node) {
  return node.data && node.data.template && node.data.each_repeatable && node.data.each_repeatable.repeatable;
}

export const eachesOnRepeatableAddSlate = {
  id: 'eachesOnRepeatableAddSlate',
  time: -3,
  dependencies: { repeatableAdd: true },
  match: ({ entries, node }) => {
    if (!isRepeatableHolder(node)) {
      return false;
    }
    for (const entry of entries) {
      if (node.data.each_repeatable.repeatable === entry.cardId) return true;
    }
  },
  handler: function ({ state, entries, matches }) {
    for (const entry of entries) {
      const { cardId: repeatable, path } = entry;
      nodeLoop: for (const { node, parents, actions } of matches.default) {
        if (repeatable === node.data.each_repeatable.repeatable) {
          // console.log('Add ', { repeatable, node, entry });
          const parentPaths = getRepeatableParents(parents);
          // console.log('Add new rep. Parent paths are ', { parents, parentPaths, repeatable, node, entry });
          for (const parentPath of parentPaths) {
            if (!path.includes(parentPath)) {
              console.log('Do not add for ', {
                path,
                parentPath,
                parentPaths,
                node,
              });
              // We shall not add this repeatable to another parent repeatable...
              continue nodeLoop;
            }
          }

          const newChild = this.getNewRepeatableContent({ node, state, entry });

          if (
            node &&
            Array.isArray(node.children) &&
            node.children.length === 1 &&
            node.children[0].data &&
            node.children[0].data.is_each_placeholder
          ) {
            actions.replaceChildren(newChild);
          } else {
            actions.insertChildren(newChild);
          }
        }
      }
    }
  },
};

/**
 *
 * Parents is an array (tuple with [parentNode, parentPath]) ordered backwards, i.e. with the closest the the node at index 0.
 *
 * Figure out which of a node's parent are repeatable.
 * Start by identifying the parents stipulating `data.each_repeatable` (being a repeatable container).
 * Store the indices of such containers in the `repeatableParents` object, e.g.
 * { 3: "facility" }
 *
 * Then check any occurences of parents with a `node.data._path` which has an index lower
 * than a container (to ensure that such node is the (grand)child of a repeatable container).
 *
 * If so, that parent (with node.data._path) is a valid repeatable, and we push that _path
 * to an array which eventually gets returned.
 *
 * @param {array}   parents All parents of a specific node.
 * @returns {array} An array of paths of parent repeatables.
 */
function getRepeatableParents(parents) {
  const repeatableParents = {};
  const repeatablePaths = [];
  function higherIndexIsRepeatable(testIndex) {
    for (let i = testIndex + 1; i < parents.length; i++) {
      if (repeatableParents[i]) return true;
    }
    return false;
  }

  for (let i = 0; i < parents.length; i++) {
    const { node: parentNode } = parents[i];
    if (parentNode.data && parentNode.data.each_repeatable && parentNode.data.each_repeatable.repeatable) {
      repeatableParents[i] = parentNode.data.each_repeatable.repeatable;
    }
  }
  for (let i = 0; i < parents.length; i++) {
    const { node: parentNode } = parents[i];
    if (parentNode.data && parentNode.data._path && higherIndexIsRepeatable(i)) {
      repeatablePaths.push(parentNode.data._path);
    }
  }
  return repeatablePaths;
}

export const getNewRepeatableContent = function (props) {
  const { node, ...rest } = props;

  // clearPotentialPlaceholder(node);

  const toInsert = this.getEachInsert({
    node,
    children: JSON.parse(node.data.template),
    ...rest,
  });

  if (!toInsert || !Array.isArray(toInsert)) return null;

  return ensureProperChildren(toInsert);

  // this.api.utils.engine.itemJoiner(node, { language: this.language });
};

export const getEachInsert = function (props) {
  const { node, children, state, entry } = props;
  const { repeatable, filter } = node.data.each_repeatable;
  const { value: values } = entry;
  let { _uid } = values;
  let newChildren = [];

  if (filter) {
    const passed = this.applyLogic(filter, {
      local: values,
    });
    this.log('Passed is: ', passed);
    if (!passed) return;
  }

  // Ensure that children is wrapped (if necessary), so that the each content
  // is contained in a single block or inline.
  if (isText(children[0]) || (children.length > 0 && isInline(children[0]))) {
    newChildren = [
      {
        type: 'field',
        variant: 'container',
        data: {
          template_id: 'std_inline_container',
          item_id: this.api.utils.general.uuid(),
        },
        children,
      },
    ];
  } else if (children.length > 1 && isBlock(children[0])) {
    newChildren = [
      {
        type: 'clause',
        data: {
          template_id: 'std_clause_container',
          item_id: this.api.utils.general.uuid(),
        },
        children,
      },
    ];
  } else {
    newChildren = children;
  }

  this.api.utils.engine.uniqueItemIds(newChildren);

  const childLabelId = node.data.each_repeatable.eachLabel
    ? 'el_' + node.data.each_repeatable.eachLabel + '_' + _uid
    : false;

  newChildren.forEach((child) => {
    if (isText(child)) return;
    if (!child.data) child.data = {};
    child.data._path = entry.path;
    child.data._each_repeatable_name = repeatable;
    child.data._each_uid = _uid;

    if (childLabelId) child.data._each_label_id = childLabelId;

    if (node.data.each_repeatable.makeBookmark) {
      if (!child.data.bookmarks) child.data.bookmarks = [];
      child.data.bookmarks.push((node.data.each_repeatable.bookmarkPrefix || '') + _uid);
    }
  });

  this.populateEachContent(repeatable, newChildren, values, state, entry);

  return newChildren;
};
