// Import additional prototypes
import * as modularPrototypes from '../prototype/index';

export default function enhanceEngine(Engine) {
  // Add external prototypes to Engine
  for (const modularPrototype in modularPrototypes) {
    const type = typeof modularPrototypes[modularPrototype];
    if (type === 'function' || type === 'object') {
      Engine.prototype[modularPrototype] = modularPrototypes[modularPrototype];
    }
  }
}
