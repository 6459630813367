import React, { useState, useEffect } from 'react';
import { useContract, setContract, useStudioBaseRoute, setEventState, useSetStudioPage } from 'hooks';
import { useHistory } from 'react-router-dom';
import { Contract } from 'core/interfaces';
import { useSelector } from 'react-redux';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Button, Form, Input, Checkbox } from 'antd';
import { useLocation } from 'react-router-dom';
import { generateUID } from 'core/utils/general';
import RemovePage from './RemovePage';
import { getStudioPageByPage } from '../../../utils/pageMenu';

export default function AddEditPage({ selected, onFinish }) {
  const contract = useContract();
  const baseRoute = useStudioBaseRoute();
  const history = useHistory();
  const location = useLocation();
  const setStudioPage = useSetStudioPage();
  const studioPage = useSelector(({ draft }) => draft.studioPage);

  const { page } = selected || {};

  const parentPage = selected && selected.addChildPage && page;
  const currentPage = selected && selected.editPage && page;

  const contractLanguauge = contract.data.settings.language;

  const [pageId, setPageId] = useState(currentPage ? currentPage.id : '');
  const [pageIdError, setPageIdError] = useState('');
  const [pageLabel, setPageLabel] = useState(currentPage ? currentPage.header[contractLanguauge] : '');
  const [isContainer, setIsContainer] = useState(currentPage ? currentPage.collapse : false);
  const [useCustomId, setUseCustomId] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const formatMessage = useIntlMessage();

  const handlePageIdChange = (value) => {
    setPageId(value);
    if (contract.data.ui.pages[value]) {
      setPageIdError(formatMessage('studio.template.pages.errors.idExists') + value);
    } else if (pageIdError) {
      setPageIdError('');
    }
  };
  const handlePageLabelChange = (evt) => {
    const { value } = evt.target;
    setPageLabel(value);
    if (!currentPage && !useCustomId) {
      handlePageIdChange(generatePageId(value));
    }
  };

  const handleIsContainerChange = (evt) => {
    setIsContainer(evt.target.checked);
  };

  const generatePageId = (label) => {
    while (true) {
      const id = `${label
        .replace(' ', '_')
        .replace(/[^a-zA-Z_]/g, '')
        .toLowerCase()
        .slice(0, 6)}_${generateUID(4)}`;
      // Have to fix this to check for sub pages too.
      if (!contract.data.ui.pages[id]) {
        return id;
      }
    }
  };

  useEffect(() => {
    if (!currentPage && !useCustomId) {
      handlePageIdChange(generatePageId(pageLabel || 'section'));
    }
    // Only on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removePage = () => {
    setShowRemove(true);
  };

  const onPageRemoved = () => {
    setEventState('template_edit_input_sections', null);
  };

  const addPage = () => {
    if (!pageId || !pageLabel) {
      return console.log('Missing');
    }

    if (parentPage) {
      console.log('Currently no support for adding child pages.');
    }

    // Do different things depending on if we add a child page to a parent

    // const parentRoute = parentPage ? Create.getRouteByPath(contract.data.ui, location.pathname) : null;

    /* const fullRoutePath = parentRoute ? `${parentRoute.fullPath}/${pageId}` : `${baseRoute}/input/${pageId}`;
    const paths = parentRoute ? [...parentRoute.paths, pageId] : ['input', pageId];
    const level = paths.length - 2; */

    const parentRoute = parentPage && contract.data.ui.routes.find((route) => route.id === parentPage.id);

    const paths = parentPage ? ['input', parentPage, pageId] : ['input', pageId];

    const newPage = currentPage || {
      id: pageId,
      icon: 'mdi-format-list-bulleted',
      cards: [],
      pages: {},
      collapse: isContainer,
      pages_order: [],
    };
    newPage.header = {
      en: pageLabel,
      [contract.data.settings.language]: pageLabel,
    };

    // We only create a new route for new pages
    const newRoute = currentPage
      ? null
      : {
          paths,
          id: pageId,
          icon: 'mdi mdi-format-list-bulleted',
          collapse: isContainer,
          level: parentPage ? 1 : 0,
          fullPath: null,
          children: [],
        };

    if (!parentPage) {
      contract.data.ui.pages[pageId] = newPage;

      if (!currentPage) {
        contract.data.ui.routes.push(newRoute);
        contract.data.ui.pages_order.push(pageId);
      }
    } else if (!parentRoute) {
      console.log('No parentRoute found. Not supported.');
      return;
    } else {
      parentRoute.children.push(newRoute);

      if (!parentPage.pages) {
        parentPage.pages = {};
      }
      parentPage.pages[pageId] = newPage;
      if (!parentPage.pages_order) parentPage.pages_order = [];
      parentPage.pages_order.push(pageId);
    }

    setContract({
      ...contract,
    });
    Contract.ensurePagePaths(contract);
    setPageId('');
    setPageIdError('');
    setPageLabel('');
    onFinish();

    /* if (newRoute) {
      setStudioPage(XXXXXX);
    } */
  };

  const shallSetCustomId = !currentPage && useCustomId;

  return (
    <div>
      {showRemove && <RemovePage onFinish={onPageRemoved} page={page} />}
      <Form layout="vertical">
        <Form.Item
          label={<IntlMessages id="studio.template.pages.label.label" />}
          extra={
            <div>
              <small>
                <IntlMessages id="studio.template.pages.label.info" />
              </small>
            </div>
          }
        >
          <Input
            type="text"
            value={pageLabel}
            onChange={handlePageLabelChange}
            placeholder={formatMessage('studio.template.pages.label.placeholder')}
          />
        </Form.Item>
        <Form.Item label={<IntlMessages id="studio.template.pages.container.label" />}>
          <Checkbox checked={isContainer} onChange={handleIsContainerChange} disabled={!!currentPage}>
            <small>
              <IntlMessages id="studio.template.pages.container.info" />
            </small>
          </Checkbox>
        </Form.Item>
        <Input.Group compact>
          {!currentPage && (
            <Form.Item
              style={{ width: '35%' }}
              // className="mr-3"
              label={<IntlMessages id="studio.template.cards.customId.label" />}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.cards.customId.info" />
                  </small>
                </div>
              }
            >
              <Checkbox checked={useCustomId} onChange={() => setUseCustomId(!useCustomId)} />
            </Form.Item>
          )}
          {shallSetCustomId ? (
            <Form.Item
              style={{ width: '55%' }}
              label={<IntlMessages id="studio.template.pages.id.label" />}
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.pages.id.info" />
                  </small>
                  {pageIdError && <div className="text-warning">{pageIdError}</div>}
                </div>
              }
            >
              <Input
                type="text"
                value={pageId}
                onChange={(evt) => handlePageIdChange(evt.target.value)}
                disabled={!!currentPage || !useCustomId}
                placeholder={formatMessage('studio.template.pages.id.placeholder')}
              />
            </Form.Item>
          ) : (
            <Form.Item
              style={{ width: '55%' }}
              className="ml-2"
              label={<IntlMessages id="studio.template.pages.id.label" />}
            >
              <em className="pl-2" style={{ fontWeight: '600' }}>
                {pageId}
              </em>
            </Form.Item>
          )}
        </Input.Group>
      </Form>
      <Button
        type="primary"
        block
        onClick={addPage}
        disabled={pageIdError || pageId === '' || pageLabel === ''}
      >
        {currentPage
          ? formatMessage('studio.template.pages.updateButton')
          : formatMessage('studio.template.pages.addButton')}
      </Button>
      {currentPage && (
        <Button type="danger" className="mt-3" block onClick={removePage}>
          {formatMessage('studio.template.pages.removeButton')}
        </Button>
      )}
    </div>
  );
}
