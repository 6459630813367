import { Editor, Transforms, Path } from 'slate';
import uuid from 'uuid-random';
import { isList, isListItem } from 'core/types/elements';

export const cmd_indent_block = (editor) => () => {
  const lowestListItems = Array.from(
    Editor.nodes(editor, { at: editor.selection, match: isListItem, mode: 'lowest' })
  );
  const lowestLists = Array.from(
    Editor.nodes(editor, { at: editor.selection, match: isList, mode: 'lowest' })
  );

  const lowestList = lowestLists[lowestLists.length - 1];
  const lowestListItem = lowestListItems[lowestListItems.length - 1];

  if (!lowestList || !lowestListItem || !Path.isParent(lowestList[1], lowestListItem[1])) return;

  const [lowestListElement] = lowestList;

  const { type: targetType } = lowestListElement;

  const listItemIndex = lowestListItem[1][lowestListItem[1].length - 1];
  // console.log('List item index', listItemIndex)

  if (listItemIndex === 0) {
    console.log('Not wrap top level');
    return;
  }

  if (isList(lowestList[0].children[listItemIndex - 1].type)) {
    const targetPath = [
      ...lowestList[1],
      listItemIndex - 1,
      lowestList[0].children[listItemIndex - 1].children.length,
    ];
    // console.log('Should move into above sibl list', lowestListItem[1], targetPath);
    Transforms.moveNodes(editor, { at: lowestListItem[1], to: targetPath });
    if (lowestList[0].children[listItemIndex + 1] && isList(lowestList[0].children[listItemIndex + 1].type)) {
      const newTargetPath = [...targetPath];
      newTargetPath[newTargetPath.length - 1] = newTargetPath[newTargetPath.length - 1] + 1;
      Transforms.moveNodes(editor, { at: lowestListItem[1], to: newTargetPath });
    }
  } else if (lowestList[0].children.length === 1) {
    // console.log('Only child')
    return;
  } else {
    Transforms.wrapNodes(editor, {
      type: targetType,
      data: { item_join: false, item_id: uuid(), template_id: 'default_list_' },
      children: [],
    });
    // console.log('Wrapped list item ', listItemIndex, lowestList[0])
    if (
      lowestList[0].children &&
      lowestList[0].children[listItemIndex + 1] &&
      isList(lowestList[0].children[listItemIndex + 1].type)
    ) {
      const subListPath = [...lowestList[1], listItemIndex + 1];
      const newTargetPath = [...lowestListItem[1], 1];

      Transforms.moveNodes(editor, { at: subListPath, to: newTargetPath });
    }
  }
};

export const cmd_dedent_block = (editor) => () => {
  const lists = Array.from(Editor.nodes(editor, { at: editor.selection, match: isList, mode: 'all' }));
  if (!lists || lists.length < 2) return;
  Transforms.unwrapNodes(editor, {
    match: isList,
    split: true,
  });
};

export const cmd_align = (editor) => (arg) => {
  if (!editor.selection || !arg) return;
  const nodes = Editor.nodes(editor, { match: (n) => editor.isBlock(n), mode: 'lowest' }); // Selected nodes
  for (let node of nodes) {
    if (node[0] === editor) continue;
    if (!editor.isBlock(node[0])) continue;
    editor.addData({ _format: { textAlign: arg } }, node[1]);
  }
};

export const cmd_color = (editor) => (arg) => {
  if (!editor.selection || !arg) return;
  const nodes = Editor.nodes(editor, { match: (n) => editor.isBlock(n), mode: 'lowest' }); // Selected nodes
  for (let node of nodes) {
    if (node[0] === editor) continue;
    if (!editor.isBlock(node[0])) continue;
    editor.addData({ _format: { color: arg } }, node[1]);
  }
};

export const cmd_undo = (editor) => () => {
  console.log('Shall undo ?')
  editor.undo();
};

export const cmd_redo = (editor) => () => {
  console.log('Shall redo ?')
  editor.redo();
};
