// Global template UI module.
// Receives arguments `action payload`, full `input state`

// Return an object with an action to SET_INPUT, or an array of action objects.
// Redux saga calls ui templates, and receives their return values (setting additional redux state)

import { firstIsParentOfSecond } from 'core/utils/nodes';
import { Entity } from 'core/interfaces';

const newBorrower = (data, state) => {
  const { input, entities } = state;

  if (!data.value || !data.value.id) return console.log('No data.value.id for new borrower');

  const node = Entity.getFromStateById(state, data.value.id); // entities.find((item) => item.name === data.value);
  if (!node) return;

  let onlyMyGroupBorrows = true;
  for (const [key, borrower] of Object.entries(input.borrower)) {
    if (!borrower.borrowerEntity || !borrower.borrowerEntity.id) continue;
    const borrowerNode = Entity.getFromStateById(state, borrower.borrowerEntity.id);
    if (!borrowerNode) continue;
    if (borrowerNode.id !== node.id && !firstIsParentOfSecond(node, borrowerNode, entities, true))
      onlyMyGroupBorrows = false;
  }
  const actions = [];
  if (!onlyMyGroupBorrows) {
    actions.push({
      path: 'input.' + data.card + '.' + data.uid + '.party/limitation17',
      value: true,
    });
  }
  if (node.isTarget) {
    actions.push({
      path: 'input.' + data.card + '.' + data.uid + '.party/limitation21',
      value: true,
    });
  }
  if (actions.length > 0) return actions;
};

const newGuarantor = (data, state) => {
  const { input, entities } = state;

  if (!input.borrower || !data.value || !data.value.id)
    return console.log('No borrower or data.value.id for new guarantor');
  const node = Entity.getFromStateById(state, data.value.id);

  if (!node) return;

  let onlyMyGroupBorrows = true;
  for (const [key, borrower] of Object.entries(input.borrower)) {
    const borrowerNode = Entity.getFromStateById(state, borrower.borrowerEntity.id);
    if (!borrowerNode) continue;
    if (borrowerNode.id !== node.id && !firstIsParentOfSecond(node, borrowerNode, entities, true))
      onlyMyGroupBorrows = false;
  }
  const actions = [];
  if (!onlyMyGroupBorrows) {
    actions.push({
      path: 'input.' + data.card + '.' + data.uid + '.party/limitation17',
      value: true,
    });
  }
  if (node.isTarget) {
    actions.push({
      path: 'input.' + data.card + '.' + data.uid + '.party/limitation21',
      value: true,
    });
  }
  if (actions.length > 0) return actions;
  return actions;
};

const inputs = [
  {
    trigger: 'borrower->borrowerEntity',
    mode: 'single',
    function: newBorrower,
    info: {
      name: 'Detect limitation langugage requirement for borrower granting guarantee',
      category: 'legal',
    },
  },
  {
    trigger: 'guarantor->guarantorEntity',
    mode: 'single',
    function: newGuarantor,
    info: {
      name: 'Detect limitation langugage requirement for guarantors',
      category: 'legal',
    },
  },
  {
    trigger: 'sharesecurity->sharesecurity/pledgor',
    mode: 'single',
    function: newGuarantor,
    info: {
      name: 'Detect limitation langugage requirement for share pledgors',
      category: 'legal',
    },
  },
  {
    trigger: 'floatingcharge->floatingcharge/pledgor',
    mode: 'single',
    function: newGuarantor,
    info: {
      name: 'Detect limitation langugage requirement for floating charge pledgors',
      category: 'legal',
    },
  },
  {
    trigger: 'property->property/owner',
    mode: 'single',
    function: newGuarantor,
    info: {
      name: 'Detect limitation langugage requirement for property pledgors',
      category: 'legal',
    },
  },
  {
    trigger: 'intragrouploan->intragrouploan/creditor',
    mode: 'single',
    function: newGuarantor,
    info: {
      name: 'Detect limitation langugage requirement for intragroup loan pledgors',
      category: 'legal',
    },
  },
];

export default inputs;
