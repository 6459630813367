import { userToName } from 'components/ui';
import { fixDate } from 'components/ui';
import { uuidColor } from 'core/utils/general';

/**
 *
 * @param {*} userObject
 * @returns authors full name
 */
export const AuthorName = (userObject) => {
  return userObject ? userToName(userObject) : 'Unknown';
};

/**
 *
 * @param {*} name
 * @returns initials of author, e.g., FF
 */
export const AuthorInitials = (name) => {
  if (!name) return '?';
  return name.split(' ').map((letter) => {
    return letter[0][0] ? letter[0][0] : letter[0];
  });
};

/**
 *
 * @param {*} index
 * @returns background-color of avatar
 */
/* export const AvatarColorList = (index) => {
    const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae']
    return colorList[index] ? colorList[index] : ''
} */

/**
 *
 * @param {*} index
 * @returns background-color of avatar
 */
export const AvatarColor = (user) => {
  const { color } = user && user.id ? uuidColor(user.id, { mode: 'light' }) : '#ccc';
  return color;
};

/**
 *
 * @param {*} role
 * @returns a decision of color for role tag e.g., Red [Owner] Tag
 */
export const RoleTagColor = (role) => {
  switch (role) {
    case 'Owner':
      return 'red';
    case 'Administrator':
      return 'red';
    case 'ProjectManager':
      return 'red';
    case 'Legal':
      return 'red';
    case 'Member':
      return 'red';
    case 'Guest':
      return 'red';
    default:
      return 'blue';
  }
};

/**
 *
 * @description compares created date and updated date
 * @param {*} created
 * @param {*} updated
 * @returns a status that indicates whether the message has been edited
 */
export const isMessageEdited = (created, updated) => {
  const IsEdited = (
    <span style={{ marginTop: '15px', fontStyle: 'italic', color: '#ccc', fontSize: '0.8rem' }}>
      (edited {fixDate(updated)})
    </span>
  );

  return created === updated ? '' : IsEdited;
};
