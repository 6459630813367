import { Contract } from '../../../interfaces';

export const setupVariables = function (contract, options = {}) {
  this.setupEngineVariables(contract, options);
  this.setupContractVariables(contract, options);
};

export const setupEngineVariables = function (contract, options = {}) {
  const { editor, language, isBackend = false } = options;

  this.getId = () => this.id;

  this.editor = editor;
  this.isBackend = isBackend;
  this.transformBeforeMutate = true;

  this.manager = options.manager;

  this.states = {
    previous: {
      input: {},
      entities: [],
    },
    current: { input: {}, entities: [] },
  };
  this.language =
    language ||
    (contract.data.settings && contract.data.settings.language) ||
    this.settings.defaultLanguage ||
    'en';
  this._tmpDraft = {};
};

export const setupContractVariables = function (contract) {
  this.contract = contract;
  this.id = Contract.getId(this.contract);

  this._draftInfo = {
    data: {},
    status: {},
    smartReplacementIndices: {},
    smartReplacements: {},
    variables: {},
    _firstTimeDraft: 0,
  };
};
