import EventSubscriptions from '../../../components/project/EventSubscriptions/EventSubscriptions';

export default function ProjectSubscriptions({ project, initialSubscriptions, updateLength }) {
  return (
    <EventSubscriptions
      resourceType="Project"
      resourceId={project?.id}
      initialSubscriptions={initialSubscriptions}
      updateLength={updateLength}
    />
  );
}
