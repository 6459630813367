export function actionDebouncer(callFn) {
  let timeout;
  let lastFullPath;

  const later = (states) => {
    lastFullPath = null;
    clearTimeout(timeout);
    callFn(states);
  };

  return function executedFunction(states, action, wait = 0) {
    if (wait === 0) return later(states)
    if (window?.debug) console.log('Debouncing.');

    let allPaths;

    if (action?.payload) {
      if (Array.isArray(action.payload)) allPaths = action.payload.map((payload) => payload.path).join('/');
      else if (action.payload.path) allPaths = action.payload.path;
      const hasNewPaths = lastFullPath && allPaths !== lastFullPath
      lastFullPath = allPaths;
      if (hasNewPaths) {
        // console.log("Isnt same path as prior.", { lastFullPath, allPaths });
        return later(states);
      }
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => later(states), wait);
  };
}
