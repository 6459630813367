
export default function Tablify({ data }) {
  return (
    <table border={0}>
      <tbody>
        {data.map((row, rIndex) => (
          <tr key={'r' + rIndex}>
            {row.map((cell, cIndex) => (
              <td key={'c' + cIndex} style={{ verticalAlign: 'top' }}>
                <small className="mr-2" style={{ wordBreak: cIndex === 0 ? 'unset' : 'break-all' }}>
                  {cell}
                </small>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}