/**
 * ENUM.
 * An inline field container, the value of which depends on
 * underlying rules. 
 * The enums are processed in the order they appear in the enums-array.
 * If an enum's rule passes the logic test, then that item's content is
 * set as the node's children, and the `data.currentEnumIndex` is set to
 * that enum's index in the array.
 * 
 * Node example:
 		{
			 type: "field",
			 variant: "enum",
			 data: {
				 ignoreChange: false, // Default 'false'. If true, any manual edits will not be saved when enum changes
				 enums: [
					 {
						rule: {...json-logic rule...},
						content: [{
							text: "this is the first alternative node"
						}]
					 },
					 {
						rule: {...json-logic rule...},
						content: [{
							type: "field",
							variant: "vari",
							data: {...},
							children: [{text: "this is the second alternative node which itself is an inline"}]
						}]
					 },
					 {
						default: true, // default content if none above match
						content: [{
							text: "this is the first alternative node"
						}]
					 },
				 ],
				 currentEnumIndex: 0 // The current value
			 }
 		}
 */

export const enumAnyUpdateSlate = {
  id: 'enumAnyUpdateSlate',
  dependencies: {
    repeatableAdd: true,
    repeatableRemove: true,
    repeatableChange: true,
    ordinary: true,
    entityAny: true,
  },
  time: -5,
  match: ({ node }) => {
    return node.data && node.type === 'field' && node.variant === 'enum' && Array.isArray(node.data.enums);
  },
  handler: function ({ state, api, matches }) {
    // console.log('Node matches for enums are ', matches.default);

    for (const { node, parents, actions } of matches.default) {
      const supportMultiple = !!node.data.multiple;
      const localState = api.utils.engine.getLocalState(node, parents, state, this.contract);

      if (supportMultiple) {
        const { currentEnumIndices = [] } = node.data;
        const newEnumIndices = [];

        for (let i = 0; i < node.data.enums.length; i++) {
          const item = node.data.enums[i];

          if (item.default && newEnumIndices.length === 0) {
            newEnumIndices.push(i);
            break;
          }

          if (
            item.include ||
            this.applyLogic(item.rule, {
              local: localState,
            })
          ) {
            newEnumIndices.push(i);
          }
        }
        if (JSON.stringify(currentEnumIndices) === JSON.stringify(newEnumIndices)) {
          // Exactly the same rules apply. No need to update.
          continue;
        }

        let content = [];
        for (let i = 0; i < newEnumIndices.length; i++) {
          content = content.concat(JSON.parse(JSON.stringify(node.data.enums[newEnumIndices[i]].content)));
        }
        if (node.data.join && content.length > 1) {
          const joinWord = node.data.join || 'and';
          const joinMarks = node.data.joinMarks || null;
          let joinedContent = [];
          for (let i = 0; i < content.length; i++) {
            if (i + 1 === content.length) {
              joinedContent.push(content[i]);
              continue;
            }

            joinedContent.push(content[i]);

            if (i + 2 === content.length) {
              joinedContent.push(
                this.makeTextNode(' ' + this.translateText(joinWord) + ' ', joinMarks, content[i])
              );
            } else {
              joinedContent.push(this.makeTextNode(', '));
            }
          }
          content = JSON.parse(JSON.stringify(joinedContent));
        }
        actions.replaceChildren(content);
        actions.setNodeData('currentEnumIndices', newEnumIndices);
      } else {
        const { currentEnumIndex } = node.data;
        let newEnumIndex = null;
        // console.log('Multiple enum is ', node);
        let content = null;
        for (let i = 0; i < node.data.enums.length; i++) {
          const item = node.data.enums[i];

          if (item.default && !newEnumIndex) {
            newEnumIndex = i;
            content = JSON.parse(JSON.stringify(item.content));
            break;
          }

          if (
            this.applyLogic(item.rule, {
              local: localState,
            })
          ) {
            newEnumIndex = i;
            content = JSON.parse(JSON.stringify(item.content));
            break;
          }
        }
        if (currentEnumIndex === newEnumIndex) {
          // Exactly the same rules apply. No need to update.
          // console.log('Same rules ordinary enum.');
          continue;
        }
        if (!content) {
          // console.log('No ordinary enum content...');
          continue;
        }
        actions.replaceChildren(content);
        actions.setNodeData('currentEnumIndex', newEnumIndex);
        // console.log('Update ordinary enum.');
      }
    }
  },
};
