// import {Node, Path} from 'slate';

export const merge_node = (editor, app) => {
    // console.log('merge node app', app)
    if(app.properties && app.properties.type) {
        // watchNext = {type: app.type, data: {...app.properties.data}};
        // const mergeTarget = Node.get(editor, Path.previous(app.path));
        // console.log('mn', app, mergeTarget);
    }
    return true; // Return false to stop the default apply logic
}

