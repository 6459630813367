import React, { useState, useCallback } from 'react';
import { Button, Modal, Input, Checkbox, Form } from 'antd';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default function NewFolderButton(props) {
  const { children, className } = props;

  const [open, setOpen] = useState(false);

  // const formatMessage = useIntlMessage();

  // const close = () => setOpen(false);

  const buttonClassName = className || 'm-0 ml-2';

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const onFinish = (values) => {
    console.log('Success:', values);

    const data = {
      projectTemplateId: '00000000-0000-0000-0000-000000000000',
      ...values,
      data: {},
      info: {},
      customerId: null,
      isFolder: true,
    };

    api
      .post('/projects', data)
      .then(function (res) {
        if (res.data) {
          console.log('Stored new folder.');
          props.projectWasCreated(res.data);
          setOpen(false);
        } else {
          console.log('Error in posting project, ', res);
        }
      })
      .catch((err) => console.log('post err', err, err.response));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  // props.projectWasCreated

  return (
    <>
      {children ? (
        <div className="clickable" onClick={toggleOpen}>
          {children}
        </div>
      ) : (
        <Button
          type="primary"
          icon={<i className="mdi mdi-folder-outline mr-2" />}
          className={buttonClassName}
          onClick={toggleOpen}
        >
          <IntlMessages id="app.projects.NewFolder" />
        </Button>
      )}
      <Modal
        title={<IntlMessages id="app.projects.NewFolder" />}
        visible={open}
        backdrop={'static'}
        onOk={toggleOpen}
        onCancel={toggleOpen}
        footer={[
          <Button key="back" onClick={toggleOpen}>
            <IntlMessages id="desc.Cancel" />
          </Button>,
        ]}
      >
        {open && (
          <div>
            <div className="text-center">
              <div className="card-title mb-4">
                <h6>
                  <IntlMessages id="app.projects.ChooseTemplate" />
                </h6>
              </div>
            </div>
            <div className="flex-column justify-content-center align-items-center mb-3">
              <i className={'mdi mdi-folder-outline'} style={{ fontSize: 48 }} />
              <h5 align={'center'}>
                <IntlMessages id={'app.projects.NewFolder'} />
              </h5>
            </div>

            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: false }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label={<IntlMessages id={'app.general.Name'} />}
                name="name"
                rules={[{ required: true, message: 'Please enter folder name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={<IntlMessages id={'app.general.Description'} />}
                name="description"
                rules={[{ required: true, message: 'Please enter folder description' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                {...tailLayout}
                name="isSecret"
                valuePropName="checked"
                style={{ marginLeft: '33.33333333%' }}
              >
                <Checkbox>
                  <IntlMessages id={'app.projects.FolderIsSecret'} />
                </Checkbox>
              </Form.Item>

              <Form.Item {...tailLayout} style={{ marginLeft: '33.33333333%' }}>
                <Button type="primary" htmlType="submit">
                  <IntlMessages id={'app.projects.CreateFolder'} />
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>
    </>
  );
}
