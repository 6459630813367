

const financialCovenantSuggestions = (payload, state) => {
    
    const { input } = state
    
    // measurements/interval
    // 'measurements/referencedates'
    const interval = payload.name === "interval" ? payload.value : input.measurements.interval
    const referencedates = payload.name === "referencedates" ? payload.value : input.measurements.referencedates


    let yearNow       = new Date().getFullYear();
    let monthNow      = new Date().getMonth(); // Is zero-indexed.               // 7

    let availableMonths         = ['31jan', '28feb', '31mar', '30apr', '31may', '30jun', '31july', '31aug', '30sept', '31oct', '30nov', '31dec' ];
    let selectedReferenceDates  = parseInt(referencedates) - 1; // 1, 2, 3
    let selectedInterval        = interval;                
    let intervalInt             = parseInt(selectedInterval.substr(0, selectedInterval.length-1)); // 3, 6, 12

    let startIndex = selectedReferenceDates;
    while(startIndex <= monthNow) {
        
        // console.log('plus ', intervalInt);
        if(startIndex > 11) { // New year.
            yearNow++;
            break;
        }
        startIndex += intervalInt;
    }
    if(startIndex>11) { 
        startIndex-=12;
        yearNow++;
    }
    // console.log('startIndex is ', startIndex, yearNow, availableMonths[startIndex]);

    return [
        {
            path: "input.measurements.first_reference_date",
            value: availableMonths[startIndex] || '31jan'
        },
        {
            path: "input.measurements.first_measurement_year",
            value: yearNow
        }
    ]    
}

export default financialCovenantSuggestions;
