import { Transforms, Text } from 'slate';
import { getFragment } from './pasteHtml/';

function findSlateFragmentContent(editor, fragment) {
  if (fragment.length > 1) return fragment;
  for (const node of fragment) {
    if (editor.isInline(node)) return [node];
    if (Text.isText(node)) return [node];
    if (!node.children) continue;
    const nestedValue = findSlateFragmentContent(editor, node.children);
    if (nestedValue) return nestedValue;
  }
}

export const pasteHtml = (editor) => {
  const { insertData } = editor;

  editor.insertHtml = (html) => {
    try {
      const closestBlockNode = editor.getClosestBlockNode();
      const fragment = getFragment(html, editor, closestBlockNode);
      if (fragment[0]?.type === closestBlockNode.type) {
        // Insert fragment.
        Transforms.insertFragment(editor, fragment);
      } else {
        // Insert nodes.
        Transforms.insertNodes(editor, fragment);
      }

      //
    } catch (err) {
      console.log('Error pasting html', err);
    }
  };

  editor.insertData = (data) => {
    const html = data.getData('text/html');
    const isSlateData = html && html.includes('data-slate-fragment="');

    // If html, parse using custom functionality.
    if (isSlateData) {
      const fragment = data.getData('application/x-slate-fragment');
      const decoded = decodeURIComponent(window.atob(fragment));
      const parsed = JSON.parse(decoded);
      const content = findSlateFragmentContent(editor, parsed);
      console.log('slate paste data ', { data, parsed, content });
      if (content) {
        editor.insertFragment(content);
      }
      return;
    }
    if (html && !isSlateData) {
      return editor.insertHtml(html);
    }

    // Default.
    insertData(data);
  };

  return editor;
};
