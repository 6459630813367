import React, { useMemo, useState } from 'react';
import { Row, Col, Spin, Table, Button, Dropdown, Menu, Tooltip, Checkbox, Card } from 'antd';
import { Link } from 'react-router-dom';
import WidgetHeader from 'components/WidgetHeader/index';
import EntityWidget from 'components/Widgets/EntityWidget';
import { useDispatch } from 'react-redux';
import { setNumberEntities } from 'appRedux/actions';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import NewMainEntityButton from 'components/entity/NewMainEntity/NewMainEntityButton';
import { Entity } from 'core/interfaces';
import { fixDate, RemoveModal, EntitySearch } from 'components/ui';
import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { gutter } from 'config';
import { useSearch } from 'hooks';

const { Column } = Table;

export default function Entities({ type = 'LegalPerson ' }) {
  const [results, setResults] = useState('');
  const [newlyCreatedEntities, setNewlyCreatedEntities] = useState([]);
  const dispatch = useDispatch();

  const entityWasCreated = (data) => {
    if (!data.newEntities || data.newEntities.length === 0) return;
    const { newEntities } = data;
    setNewlyCreatedEntities([...newlyCreatedEntities, ...newEntities]);
    dispatch(setNumberEntities());
  };
  const entityWasRemoved = (id) => {
    setResults([...results.filter((entity) => entity.id !== id)]);
    dispatch(setNumberEntities());
  };

  const isLegalPerson = type === 'LegalPerson';
  const intlTypePlural = isLegalPerson ? 'companies' : 'persons';

  const defaultSearchParams = useMemo(
    () =>
      isLegalPerson
        ? {
            isTopCo: true,
            name: '',
            type: 'LegalPerson',
          }
        : {
            isTopCo: false,
            name: '',
            type: 'RealPerson',
          },
    [isLegalPerson]
  );

  const allEntities = useMemo(
    () => results && newlyCreatedEntities.concat(results),
    [results, newlyCreatedEntities]
  );

  return (
    <div className="padded-page light-container m-5">
      <WidgetHeader
        title={<IntlMessages id={`general.${intlTypePlural}`} cap />}
        styleName="mb-3"
      />
      <Card
        title={
          <div className="d-flex align-items-center justify-content-end">
            <EntitySearch
              setResults={setResults}
              defaults={defaultSearchParams}
              showTypes={false}
              showName={false}
              showIdentificationNumber={false}
              showIsTopCo={isLegalPerson}
              showSearch={true}
            />
            <NewMainEntityButton entityWasCreated={entityWasCreated} type={type} withLabelType />
          </div>
        }
      >
        

        {!allEntities && (
          <div className="d-flex align-items-center justify-content-center h-100">
            <Spin size="large" />
          </div>
        )}

        {allEntities && allEntities.length > 0 && (
          <EntitiesTable entities={allEntities} entityWasRemoved={entityWasRemoved} type={type} />
        )}
      </Card>
    </div>
  );
}

const config = {
  bordered: false,
  loading: false,
  pagination: { position: 'bottom' },
  size: 'default',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  scroll: undefined,
};

export function EntitiesTable({ entities, entityWasRemoved, type = 'LegalPerson' }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (selection) => {
    console.log('selection changed: ', selection);
    setSelectedRowKeys(selection);
  };

  const isLegalPerson = type === 'LegalPerson';
  const intlType = isLegalPerson ? 'company' : 'person';

  const rowSelection = null; /* {
    selectedRowKeys,
    onChange: onSelectChange,
  }; */

  const removeEntity = (id) => {
    api
      .delete('/entities/' + id)
      .then((result) => {
        entityWasRemoved(id);
      })
      .catch((err) => {
        console.log('Error removing entity ', id, err.response);
      });
  };

  return (
    <Table
      rowKey="id"
      className="table-responsive ml-3"
      {...config}
      // columns={columns}
      rowSelection={rowSelection}
      dataSource={entities}
    >
      <Column
        title={<IntlMessages id="app.general.Name" />}
        dataIndex="name"
        key="name"
        render={(text, record) => (
          <Link
            to={{
              pathname: '/entity/' + (record && record.id ? record.id : '_null'),
              state: {
                entity: record,
              },
            }}
          >
            {Entity.name(record)}
          </Link>
        )}
      />
      <Column
        title={<IntlMessages id="app.general.Created" />}
        dataIndex="createdAt"
        key="createdAt"
        render={(text) => fixDate(text)}
      />
      <Column
        title={<IntlMessages id="app.general.More" />}
        dataIndex={null}
        key="menu"
        render={(text, record) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="remove">
                  <RemoveModal
                    onConfirm={() => removeEntity(record.id)}
                    confirmText={
                      <>
                        <IntlMessages id="app.general.confirmRemoval" />{' '}
                        <IntlMessages id={'general.' + intlType} />?
                      </>
                    }
                  >
                    <span className="">
                      <IntlMessages id="desc.Remove" /> <IntlMessages id={'general.' + intlType} />
                    </span>
                  </RemoveModal>
                </Menu.Item>
              </Menu>
            }
          >
            <span className="link ant-dropdown-link">
              <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
            </span>
          </Dropdown>
        )}
      />
    </Table>
  );
}
