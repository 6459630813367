import { Contract } from '../../../../interfaces';

export const _setContent = function (content) {
  return Contract.setContent(this.contract, content);
};
export const getContractCreate = function () {
  return Contract.getCreate(this.contract);
};
export const getContractSettings = function () {
  return Contract.getSettings(this.contract);
};
export const getContractOutline = function () {
  return Contract.getOutline(this.contract);
};
