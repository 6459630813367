import { jsx } from 'slate-hyperscript';
import { GET_ELEMENT_TAGS, TEXT_TAGS } from './tagsToType';
import { onlySpaces, getElementStyles } from './utils';
import { getPastedDocumentStyles } from './getPastedStyles';
import { normalizePastedFragment } from './finalising';

export const getFragment = (html, editor, closestBlockNode) => {
  const parsed = new DOMParser().parseFromString(html, 'text/html');
  const deserializedFragment = deserializeHtml(parsed, editor, closestBlockNode);
  const finalisedFragment = normalizePastedFragment(deserializedFragment);
  // console.log('Test paste ', { html, parsed, deserializedFragment, finalisedFragment });
  return finalisedFragment;
};

export const deserializeHtml = (parsed, editor, closestBlockNode) => {
  const styles = getPastedDocumentStyles(parsed.head);
  const { contract } = editor;
  const deserialize = (el, level = 1) => {
    if (el.nodeType === 3) {
      return el.textContent;
    } else if (el.nodeType !== 1) {
      return null;
    } else if (el.nodeName === 'BR') {
      return null;
    }

    const ELEMENT_TAGS = GET_ELEMENT_TAGS(contract, closestBlockNode, level);
    const { nodeName } = el;
    const _styles = getElementStyles(el, styles);
    let parent = el;
    // console.log('Log el ', level, el);
    if (nodeName === 'PRE' && el.childNodes[0] && el.childNodes[0].nodeName === 'CODE') {
      parent = el.childNodes[0];
    }
    const children = Array.from(parent.childNodes)
      .map((child) => deserialize(child, level + 1))
      .map((child) => {
        if (typeof child !== 'string') return child;
        return child.replace(/ {4}|[\t\n\r]/gm, '');
      })
      .filter((child) => {
        if (child === null) return false;
        if (typeof child !== 'string') return true;
        if (_styles.ignoreItem) return false;
        if (onlySpaces(child)) return false;
        return true;
      })
      .flat();

    if (children.length === 0) {
      // console.log('Add children for parent ', { parent, nodeName, children, _styles });
      children.push({ text: '' });
    }

    if (el.nodeName === 'BODY') {
      return jsx('fragment', {}, children);
    }

    if (ELEMENT_TAGS[nodeName]) {
      const attrs = {
        ...ELEMENT_TAGS[nodeName](el),
        _styles,
      };
      const elementContent = jsx('element', attrs, children);
      return elementContent;
    } else if (TEXT_TAGS[nodeName]) {
      const attrs = {
        ...TEXT_TAGS[nodeName](el),
        _styles,
      };
      if (children.length === 1 && children[0]._styles) {
        attrs._styles = { ...children[0]._styles, ..._styles };
      }
      return children.map((child) => jsx('text', attrs, child));
    } else {
      // console.log('Unkown node Name ', nodeName);
    }

    return children;
  };
  return deserialize(parsed.body);
};
