import { combineReducers } from 'redux';
import settings from './Settings';
import auth from './Auth';
import common from './Common';
import input from './Input';
import draft from './Draft';
import entities from './Entities';
import tasks from './Task';
import conversations from './Conversations';
import contractData from './ContractData';

const createRootReducer = () =>
  combineReducers({
    settings,
    auth,
    entities,
    common,
    input,
    draft,
    tasks,
    conversations,
    contractData,
  });

export default createRootReducer;
