import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Path, Node } from 'slate';
import { updateInput } from 'appRedux/actions';
import { useContract, useIsTemplateStudio } from 'hooks';
import { Contract } from 'core/interfaces';

function debounceUniqueLastArg(func, wait) {
  var timeout, later, lastFirstArg;
  return function () {
    var context = this,
      args = arguments;

    let immediate = false;
    if (lastFirstArg && args[0] !== lastFirstArg) {
      clearTimeout(timeout);
      timeout = null;
      later();
      later = null;
    }
    lastFirstArg = args[0];
    later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const useStateUpdates = (editor) => {
  const studio_draftMode = useSelector(({ draft }) => draft.studio_draftMode);
  const isTemplate = useIsTemplateStudio();
  const dispatch = useDispatch();
  const contract = useContract();

  const handleInlineOperation = useCallback(
    debounceUniqueLastArg((stringPath, entry) => {
      if (!contract) return;
      const [node] = entry;
      const string = Node.string(node);
      let inputField;
      if (node.variant === 'vari') {
        const inputPath = 'input.' + node.data.name;
        inputField = Contract.getUiInputFieldDataByPath(contract, inputPath);
        if (!inputField) return console.log('Could not find input field for vari.');
        dispatch(updateInput(inputPath, string));
      } else if (node.variant === 'item') {
        inputField = Contract.getUiInputFieldDataByPath(contract, node.data.each_item_path);
        if (!inputField) return console.log('Could not find input field for item.');
        dispatch(updateInput(node.data.each_item_path, string));
      }
    }, 1000),
    [contract, dispatch]
  );

  const operationsToStateUpdates = useCallback(
    (operations) => {
      if (!editor._isFocused || editor._engineDraftSession) {
        return;
      }

      if (isTemplate || studio_draftMode !== 'inline') return;

      const textOps = operations.filter((op) => op.type === 'insert_text' || op.type === 'remove_text');
      if (textOps.length === 0) return;
      const inlinePaths = textOps
        .map((op) => {
          try {
            const parentPath = Path.parent(op.path);
            const node = Node.get(editor, parentPath);
            if (node.variant === 'item' || node.variant === 'vari')
              return [[node, parentPath], JSON.stringify(parentPath)];
          } catch (err) {
            console.log('Err getting node for operation', err, op.path);
            return null;
          }
          return null;
        })
        .filter((entry) => !!entry);
      inlinePaths.forEach((item) => {
        handleInlineOperation(item[1], item[0]);
      });
    },
    [editor, handleInlineOperation, studio_draftMode, isTemplate]
  );

  return operationsToStateUpdates;
};
