import {
  UPDATE_ENTITY,
  INSERT_ENTITY,
  REMOVE_ENTITY,
  BULK_UPSERT_ENTITIES,
  UPDATE_INPUT,
  UPDATE_INPUTS,
  SET_CONNECT_INPUT_CARDS,
  ADD_REPEATABLE,
  REMOVE_REPEATABLE,
  ADD_QA,
  REMOVE_QA,
  UPDATE_QA,
  REMOTE_APPLY_STATE,
  RELEASE_ENGINE_SESSION,
} from 'constants/ActionTypes';
import { State } from 'core/interfaces';
import manager from 'core/engine/manager';
import { actionDebouncer, statePairs } from './utils';

function manager_finishDraft(states) {
  manager.finishDraft(states, { origin: 'redux' });
}

const debouncedAction = actionDebouncer(manager_finishDraft);
let previousHadActionsToFollow = false;

function handleStateUpdate(action, states) {
  const newStatePair = statePairs(action, states);
  if (!newStatePair) {
    previousHadActionsToFollow = true;
    return;
  }
  const forceUpdate = previousHadActionsToFollow;
  previousHadActionsToFollow = false;

  switch (action.type) {
    case RELEASE_ENGINE_SESSION:
      debouncedAction(newStatePair, action, 0);
      return;
    case REMOTE_APPLY_STATE:
      /* return manager.syncNewState(states.current); */
      // console.log('Not sync states');
      return;
    case UPDATE_ENTITY:
    case INSERT_ENTITY:
    case REMOVE_ENTITY:
    case BULK_UPSERT_ENTITIES:
      return debouncedAction(newStatePair, action);
    case ADD_REPEATABLE:
    case REMOVE_REPEATABLE:
      return debouncedAction(newStatePair, action);
    case UPDATE_INPUT:
    case UPDATE_INPUTS:
    case SET_CONNECT_INPUT_CARDS:
    case ADD_QA:
    case REMOVE_QA:
    case UPDATE_QA:
      return debouncedAction(newStatePair, action, forceUpdate ? 0 : 300);
    default:
      break;
  }
}

export const engineMiddleware = ({ getState }) => {
  return (next) => (action) => {
    // Get the old state (before reducer update)
    const previous = State.produceContractState(getState());
    // Let the reducer update the store first.
    const result = next(action);
    // Get the new state
    const current = State.produceContractState(getState());

    handleStateUpdate(action, { previous, current });

    return result;
  };
};
