import React from 'react';
import { useContract, setContract, useSetStudioPage } from 'hooks';
import { useDispatch } from 'react-redux';
import RemoveModal from '../../../components/StudioInput/renderDraftUI/components/templateEdit/RemoveModal';
import { removeCard } from '../utils';

function findPageParts(pages, page, parts = []) {
  for (const id in pages) {
    if (pages[id] === page) return [...parts, id];
    if (pages[id].pages) {
      const match = findPageParts(pages[id].pages, page, [...parts, id]);
      if (match) return match;
    }
  }
}

export default function RemovePage({ onFinish, page }) {
  const contract = useContract();
  const dispatch = useDispatch();
  const setStudioPage = useSetStudioPage();

  const removePage = (pages, paths, level) => {
    const currentPath = paths[level];

    if (level === paths.length - 1) {
      return delete pages[currentPath];
      // return console.log('Deleting from pages, with path: ', pages, currentPath);
    } else {
      return removePage(pages[currentPath].pages, paths, level + 1);
    }
  };

  const onRemove = (removeCompletely) => {
    const paths = findPageParts(contract.data.ui.pages, page);
    if (!paths) {
      return console.log('Cannot find page parts');
    }
    const currentPage = page;

    if (removeCompletely) {
      currentPage.cards.forEach((cardName) => {
        dispatch(removeCard(contract, currentPage, cardName, removeCompletely));
      });
    }

    // Since there can be sub pages, we recursively go through child pages and delete the last on in "paths" = the current page we're on
    removePage(contract.data.ui.pages, paths, 0);

    // If we remove the top level, also remove from pages_order
    if (paths.length === 1) {
      const index = contract.data.ui.pages_order[paths[0]];
      contract.data.ui.pages_order.splice(index, 1);
    }

    setContract({ ...contract });

    onFinish();

    // May bug. Ensure that we're not allowed to remove a
    // page if its the only/last page.
    const gotoPageId = contract.data.ui.pages_order[0];
    setStudioPage(gotoPageId);
  };

  return <RemoveModal type="page" onRemove={onRemove} />;
}
