import { FEATURE_FLAGS, FEATURE_FLAG_OVERRIDES } from '../config/env';

export const hasFeatureFlag = (feature) => {
  const flags = [...new Set([...(FEATURE_FLAGS ? FEATURE_FLAGS.split(',') : []), ...FEATURE_FLAG_OVERRIDES])];

  return flags.includes(feature);
};

export async function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export async function blobToJson(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      try {
        resolve(JSON.parse(reader.result));
      } catch (err) {
        resolve({});
      }
    };
    reader.readAsText(blob);
  });
}
