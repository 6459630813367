export const pledgeSigningPageSlate = {
  id: 'pledgeSigningPageSlate',
  time: 1,
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true },
  match: ({ entry, node, api }) => {
    return api.utils.engine.matchModule(node, 'securityAgreement', 'pledgeSigningPage');
  },
  handler: function ({ state, handlerInvoked, engine, api, matches }) {
    if (!this.contract.data.outline || this.contract.data.outline.id_name !== 'multi_pledge_shb') return;
    const affectedConcepts = handlerInvoked.repeatableChange
      ? this._tmpDraft.allAffectedACP.changesAffectingDetailedFieldMapping
      : this._tmpDraft.allAffectedACP.changesInConceptNumber;

    if (!affectedConcepts || !affectedConcepts.includes('pledgor')) return;

    if (matches.default.size === 0) return;

    const content = generatePledgeSigningContent(engine, state, api);

    for (const { actions } of matches.default) {
      actions.replaceChildren(content);
    }
  },
};

function generatePledgeSigningContent(engine, state, api) {
  const pledgors = getPledgors(engine.contract, state, api);
  const pledgeeConcept = api.interfaces.Contract.getConcept(engine.contract, 'pledgee');
  const pledgees = api.interfaces.Concept.getConceptEntities(pledgeeConcept, engine.contract, state) || [];

  if (!pledgors || pledgors.length < 1) {
    // engine.log('No pledgors found');
    return;
  }

  const nameDescription = api.utils.general.ucfirst(engine.translateText('name')) + ':';

  const pledgorsTitleData = engine.describeConcept('the_pledgor');
  const pledgorsTitle =
    pledgorsTitleData && pledgorsTitleData.value ? pledgorsTitleData.value : 'Pantsättare';
  const pledgeeTitleData = engine.describeConcept('the_pledgee');
  const pledgeeTitle = pledgeeTitleData && pledgeeTitleData.value ? pledgeeTitleData.value : 'Panthavare';

  const content = [];

  content.push(engine.makeParagraph([engine.makeTextNode(pledgorsTitle, 'bold')]));
  content.push(engine.makeParagraph(''));

  for (const pledgor of pledgors) {
    const partyName = api.utils.general.userToName(pledgor);
    content.push(engine.makeParagraph([engine.makeTextNode(partyName, 'bold')]));
  }
  content.push(
    engine.makeTable(
      [
        ['_________________', '_________________'],
        [nameDescription, nameDescription],
      ],
      {
        _format: { border: 0 },
      }
    )
  );

  if (pledgees && pledgees.length > 0) {
    content.push(engine.makeParagraph(''));
    content.push(engine.makeParagraph([engine.makeTextNode(pledgeeTitle, 'bold')]));
    content.push(engine.makeParagraph(''));
    for (const pledgee of pledgees) {
      const partyName = api.utils.general.userToName(pledgee);
      content.push(engine.makeParagraph([engine.makeTextNode(partyName || '[namn panthavare]', 'bold')]));
    }
    content.push(
      engine.makeTable(
        [
          ['_________________', '_________________'],
          [nameDescription, nameDescription],
        ],
        {
          _format: { border: 0 },
        }
      )
    );
  }
}

function getPledgors(contract, state, api) {
  const concepts = api.interfaces.Contract.getConcepts(contract);
  if (!concepts) return [];
  const pledgorConcept = concepts.find((concept) => concept.id === 'pledgor');
  return api.interfaces.Concept.getConceptEntities(pledgorConcept, contract, state) || [];
}
