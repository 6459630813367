import { useCallback, useEffect } from 'react';
import { useSlateStatic } from 'slate-react';
import { setContractUpdates } from 'hooks';
import { find } from '../../core/engine/utils/content/find';

/**
 * hook for updating the highlighting on contract preview
 */

/*

*/

let globalType = 'recent';
export let forceContractUpdatesHighlight = {
  highlight: () => {},
  getType: () => globalType,
  setType: (type) => (globalType = type),
};
const next = { current: { above: null, below: null } };

function sortHighestFirst(a, b) {
  return b - a;
}

export function useHighlightContractUpdates(opts = {}) {
  const { isTemplate, view, disabled = false } = opts;
  const editor = useSlateStatic();

  const type = globalType;

  const highlight = useCallback(() => {
    if (isTemplate || view || disabled) return {};
    if (globalType === 'none') {
      let elems = document.querySelectorAll('.highlighted-node');
      [].forEach.call(elems, function (el) {
        el.classList.remove('highlighted-node');
      });
      setContractUpdates({ updates: 0, above: 0, below: 0 });
      return { updates: 0, above: 0, below: 0 };
    }

    let above = 0,
      below = 0,
      updates = 0;

    next.current = { above: null, below: null };

    const all = globalType === 'all';

    let latestUpdateTime;
    if (globalType === 'recent') {
      [latestUpdateTime] = [
        ...new Set(find(editor.children, (n) => n.data?._updateTime).map((n) => n.data._updateTime)),
      ].sort(sortHighestFirst);
      if (!latestUpdateTime) return;
    }
    const selector = all ? '[data-updated]' : '[data-updated="' + latestUpdateTime + '"]';

    const updatedDomNodes = window.document.querySelectorAll(selector);
    const actualUpdateDomNodes = [];

    for (const domNode of updatedDomNodes) {
      if (domNode.offsetParent === null) {
        // Skip node if updated at first load, or if not visible
        continue;
      }
      const parentUpdated = domNode.parentElement.closest(selector);
      // Skip node if a parent has also changed
      if (parentUpdated) {
        continue;
      }

      const nodeInView = isInViewport(domNode);

      if (nodeInView === -1) {
        above++;
        next.current.above = domNode;
      } else if (nodeInView === 1) {
        below++;
        if (!next.current.below) next.current.below = domNode;
      }
      updates++;
      actualUpdateDomNodes.push(domNode);
      if (!domNode.classList.contains('highlighted-node')) domNode.classList.add('highlighted-node');
    }

    // Remove highlight for previous non relevant nodes
    let elems = document.querySelectorAll('.highlighted-node');
    [].forEach.call(elems, function (el) {
      if (!actualUpdateDomNodes.includes(el)) {
        el.classList.remove('highlighted-node');
      }
    });
    // console.log('Highlight data ', { updates, above, below })
    setContractUpdates({ updates, above, below });
    forceContractUpdatesHighlight.highlight = highlight;
    return { updates, above, below };
  }, [disabled, editor.children, isTemplate, view]);

  useEffect(() => {
    highlight();
    forceContractUpdatesHighlight.highlight = highlight;
  }, [type, highlight]);

  useEffect(() => {
    return () => (forceContractUpdatesHighlight.highlight = null);
  }, []);

  const step = (direction) => {
    // console.log('next ', {next, direction})
    if (!next.current[direction]) return;
    next.current[direction].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    highlight();
  };

  return { highlight, type, step, next };
}

function isInViewport(element) {
  const rect = element.getBoundingClientRect();
  if (rect.top < 0) return -1;
  const inView =
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);
  if (inView) return 0;
  return 1;
}
