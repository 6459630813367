import React, { useEffect, useMemo, useState } from 'react';
import { Card, Badge, Tooltip, Modal, Table, Divider, Button, Tag, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { useEntityData, useMountFetch } from 'hooks';
import { Entity } from 'core/interfaces';
import { fixDate } from 'components/ui';
import { GotoDocumentViewSpan } from 'components/DocumentsList/index';
import { sortByCreatedAt } from 'core/utils/general';
import api from 'utils/api';

const { TabPane } = Tabs;
const { Column } = Table;
const LIMIT = 5;

// start-review-version
// made-version-changes

function useEventInfo(versionId, eventKey) {
  const entityId = useSelector(({ auth }) => auth?.user?.entityId);
  const [value] = useMountFetch(
    `/events?resourceType=Version&resourceId=${versionId}&where=eventKey_->_${eventKey}_AND_entityId_!->_${entityId}`
  );
  return value;
}

function EventBadge({ versionId, eventKey, color }) {
  const [number, setNumber] = useState('?');

  const events = useEventInfo(versionId, eventKey);
  useEffect(() => events && setNumber(events.length), [events]);

  return (
    <Tooltip placement="left" title={<IntlMessages id={`app.events.types.Version.${eventKey}`} />}>
      <Badge style={{ backgroundColor: color }} count={number || 0} showZero />
    </Tooltip>
  );
}

function FeedbackBadge({ sharedVersion }) {
  return (
    <Tooltip placement="left" title={<IntlMessages id={`studio.conversations.type.feedback`} />}>
      <Badge style={{ backgroundColor: 'orange' }} count={sharedVersion.feedbacks.length || 0} showZero />
    </Tooltip>
  );
}

function DisplaySharedItem({ sharedVersion, setOpenSharedVersion }) {
  return (
    <tr>
      <td>
        <small className="link" onClick={() => setOpenSharedVersion(sharedVersion)}>
          {sharedVersion.Document.name}
        </small>
      </td>
      <td>
        <EventBadge versionId={sharedVersion.versionId} eventKey="start-review-version" color="#aaa" />
        <EventBadge versionId={sharedVersion.versionId} eventKey="made-version-changes" color="blue" />
        <FeedbackBadge sharedVersion={sharedVersion} />
      </td>
    </tr>
  );
}

function collectVersions(invitations, versionFeedbacks) {
  if (!invitations) return [];
  const ids = [...new Set(invitations.map((inv) => inv.versionId))];
  return ids.reduce((acc, curr) => {
    const versionInvitations = invitations.filter((inv) => inv.versionId === curr);
    const [firstInvitation] = versionInvitations;
    acc.push({
      versionId: curr,
      Document: firstInvitation.Document,
      invitations,
      feedbacks: versionFeedbacks.filter((vf) => vf.versionId === curr),
    });
    return acc;
  }, []);
}

export default function SharedVersions({ project }) {
  const { versionFeedbacks = [] } = project;
  const [openSharedVersion, setOpenSharedVersion] = useState(null);
  const [invitations, setInvitations] = useState(null);

  useEffect(() => {
    if (!project.id) return;
    (async () => {
      const include = [
        { model: 'Document', as: 'Document' },
        { model: 'Entity', as: 'Entity' },
      ];
      const includeQuery = encodeURI(JSON.stringify(include));
      const response = await api.get(
        `/entityinvitations?where=projectId_->_${project.id}&include=${includeQuery}`
      );
      if (response.data) setInvitations(response.data);
    })();
  }, [project.id]);

  const closeModal = () => setOpenSharedVersion(null);

  const entityData = useEntityData(invitations && invitations.map((inv) => inv.entityId));

  const sharedVersions = useMemo(
    () => collectVersions(invitations, versionFeedbacks),
    [invitations, versionFeedbacks]
  );

  let content = [];

  const versionsLength = sharedVersions.length;

  if (versionsLength > 0) {
    const arr = versionsLength > LIMIT ? sharedVersions.slice(0, LIMIT) : sharedVersions;
    content = (
      <table className="w-100">
        <tbody>
          {arr.map((sharedVersion) => (
            <DisplaySharedItem
              key={sharedVersion.versionId}
              sharedVersion={sharedVersion}
              setOpenSharedVersion={setOpenSharedVersion}
            />
          ))}
        </tbody>
      </table>
    );
  } else {
    content = (
      <span>
        <em>
          <IntlMessages id="app.project.NoSharedVersions" />
        </em>
      </span>
    );
  }

  return (
    <Card title={<IntlMessages id="app.project.SharedDocuments" />}>
      <div className="media align-items-center flex-nowrap pro-contact-list">
        <div className="media-body">{content}</div>
      </div>
      <ViewSharedDocumentModal
        sharedVersion={openSharedVersion}
        closeModal={closeModal}
        entityData={entityData}
      />
    </Card>
  );
}

function ViewSharedDocumentModal({ sharedVersion, closeModal, entityData }) {
  if (!sharedVersion) return null;

  return (
    <>
      <Modal
        title={
          <div>
            <div>
              <IntlMessages id="app.project.SharedDocument" />
            </div>
            <small>
              <span className="mr-2">{sharedVersion.Document.name}</span>
              <GotoDocumentViewSpan document={sharedVersion.Document} />
            </small>
          </div>
        }
        className="modal-lg"
        visible={!!sharedVersion}
        onOk={closeModal}
        cancelButtonProps={{ className: 'd-none' }}
      >
        <ViewSharedDocument sharedVersion={sharedVersion} entityData={entityData} />
      </Modal>
    </>
  );
}

function ViewSharedDocument({ sharedVersion, entityData }) {
  const { versionId, invitations, feedbacks, Document } = sharedVersion;
  const reviewed = useEventInfo(versionId, 'start-review-version') || [];
  const changed = useEventInfo(versionId, 'made-version-changes') || [];

  const events = [...reviewed, ...changed];

  return (
    <div>
      <Tabs size="small" defaultActiveKey="invitations">
        <TabPane
          tab={
            <Badge count={invitations.length} showZero offset={[9, 0]}>
              <span>Invitations</span>
            </Badge>
          }
          key="invitations"
        >
          <ShowInvitations invitations={invitations} entityData={entityData} />
        </TabPane>
        <TabPane
          tab={
            <Badge count={feedbacks ? feedbacks.length : 0} showZero offset={[9, 0]}>
              <span>Feedbacks</span>
            </Badge>
          }
          key="feedbacks"
        >
          <ShowFeedbacks feedbacks={feedbacks} entityData={entityData} />
        </TabPane>
        <TabPane
          tab={
            <Badge count={events ? events.length : 0} showZero offset={[9, 0]}>
              <span>Event log</span>
            </Badge>
          }
          key="eventLog"
        >
          <ShowEventLog events={events} versionId={versionId} entityData={entityData} />
        </TabPane>
      </Tabs>
    </div>
  );
}

function ShowInvitations({ invitations, entityData }) {
  return (
    <Table rowKey="id" rowSelection={null} dataSource={invitations} size="small" pagination={false}>
      <Column
        title={<IntlMessages id="desc.to" cap />}
        dataIndex="entityId"
        key="entityId"
        render={(text, record) => Entity.name(entityData[text])}
      />
      <Column
        title={<IntlMessages id="app.general.time" cap />}
        dataIndex="createdAt"
        key="createdAt"
        render={(text, record) => fixDate(text)}
      />
      <Column
        title={<IntlMessages id="app.external.info.expiry" cap />}
        dataIndex="expireAt"
        key="expireAt"
        render={(text, record) => fixDate(text)}
      />
      <Column
        title={<IntlMessages id="app.general.Action" />}
        key="actions"
        render={(text, record) => (
          <>
            <Button onClick={() => console.log(record.id)}>Remove</Button>
          </>
        )}
      />
    </Table>
  );
}

function ShowFeedbacks({ feedbacks, entityData }) {
  return (
    <Table rowKey="id" rowSelection={null} dataSource={feedbacks} size="small">
      <Column
        title={<IntlMessages id="desc.from" cap />}
        dataIndex="entityId"
        key="entityId"
        render={(text, record) => Entity.name(record.Entity)}
      />
      <Column
        title={<IntlMessages id="app.project.version.VersionFeedbacks" cap />}
        dataIndex="status"
        key="status"
        render={(status, record) => {
          const color = status === 'approved' ? 'green' : 'red';

          return (
            <small className={'text-uppercase ' + color}>
              <Tag color={color}>
                <IntlMessages id={`app.approval.${status}`} />
              </Tag>
            </small>
          );
        }}
      />
      <Column
        title={<IntlMessages id="app.general.time" cap />}
        dataIndex="createdAt"
        key="createdAt"
        render={(text, record) => fixDate(text)}
      />

      {/*         <Column
          title={<IntlMessages id="app.general.Action" />}
          key="actions"
          render={(text, record) => (
            <>
              <Button onClick={() => console.log(record.id)}>Remove</Button>
            </>
          )}
        /> */}
    </Table>
  );
}

function ShowEventLog({ versionId, events, entityData }) {
  console.log('events ', events);
  return (
    <Table rowKey="id" rowSelection={null} dataSource={events.sort(sortByCreatedAt)} size="small">
      <Column
        title={<IntlMessages id="desc.from" cap />}
        dataIndex="entityId"
        key="entityId"
        render={(text, record) => Entity.name(entityData[text])}
      />
      <Column
        title={<IntlMessages id="app.general.Description" cap />}
        dataIndex="eventKey"
        key="eventKey"
        render={(eventKey, record) => {
          const color = eventKey === 'start-review-version' ? 'grey' : 'blue';
          return (
            <small className={'text-uppercase ' + color}>
              <Tag color={color}>
                <IntlMessages id={`app.events.types.${eventKey}`} />
              </Tag>
            </small>
          );
        }}
      />
      <Column
        title={<IntlMessages id="app.general.time" cap />}
        dataIndex="createdAt"
        key="createdAt"
        render={(text, record) => fixDate(text)}
      />
    </Table>
  );
}
