export const tableOnRepeatableAddCellSlate = {
  id: 'tableOnRepeatableAddCellSlate',
  dependencies: { repeatableAdd: true },
  time: -1,
  match: ({ node }) => {
    return isRelevantTable(node);
  },
  handler: function ({ state, handlerInvoked, entries, paths, matches }) {
    console.log('Fix table cells on add matches ', matches);
  },
};

function isRelevantTable(node) {
  if (node.type !== 'table') return false;
  if (!node.data) return false;
  if (!Array.isArray(node.data.column_repeatables)) return false;
  return true;
}

/* 
export const tableOnRepeatableAddCell = {
  id: 'tableOnRepeatableAddCell',
  dependencies: { repeatableAdd: true },
  time: -2,
  handler: function ({ state, handlerInvoked, entries, paths, matches }) {
    // Original Engine Flow
    return ([node, nodePath], index, options) => {
      if (!isRelevantTable(node)) return;

      for (const entry of entries) {
        if (!node.data.column_repeatables.find((cellRep) => cellRep.repeatable === entry.cardId)) continue;
        this.insertTableCellForNewRepeatable({ node, entry, state });
      }
    };
  },
};
export const insertTableCellForNewRepeatable = function ({ node, entry, state }) {
  const { cardId: repeatable, value } = entry;
  const templateColumns = node.data.column_repeatables.filter((column) => column.repeatable === repeatable);

  this.log('Shall insert the following columns: ', JSON.parse(JSON.stringify(templateColumns)));

  if (!Array.isArray(templateColumns) || templateColumns.length === 0) return;

  for (const templateColumn of templateColumns) {
    const content = JSON.parse(templateColumn.content);

    // Set custom data
    content.key = 'cell_' + this.api.utils.general.uuid();
    content.data.item_id = this.api.utils.general.uuid();
    content.data._path = this.api.interfaces.InputPaths.construct(repeatable, value._uid);

    this.populateEachContent(repeatable, content, value, state, entry);

    // this.log('I want to add a table cell: ', content, 'to ', JSON.parse(JSON.stringify(node)))
    // Add the cell content to each row.
    for (const row of node.children) {
      if (row.type !== 'table_row') {
        this.log('Child of table is now table_row. Abort adding cell content.', {
          node: JSON.parse(JSON.stringify(node)),
          entry,
          state,
          templateColumn,
        });
        continue;
      }
      row.children.push(content);
    }
  }
};
 */
