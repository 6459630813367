import deepmerge from 'deepmerge';
import uuidRandom from 'uuid-random';
import uniqolor from 'uniqolor';

export function userToName(user) {
  let name = '';
  if (!user) return name;
  if (user.firstName && user.lastName) name = user.firstName + ' ' + user.lastName;
  else {
    if (user.firstName) name += user.firstName;
    if (user.lastName) name += user.lastName;
  }
  return user.name || name;
}

export function ptToEm(pt, stdFontSize = 12) {
  return parseFloat((pt / stdFontSize).toFixed(2));
}
export function emToPt(em, stdFontSize = 12) {
  return roundHalf(em * stdFontSize);
}

function roundHalf(num) {
  return Math.round(num * 2) / 2;
}

export const uuid = () => uuidRandom();

export const makeNamedId = (name) => name.charAt(0) + Math.round(Math.random(1, 1000) * 1000) + Date.now();

export const makeRepeatableId = (name) =>
  '_/re/' + name.substr(0, 4) + '/' + Math.round(Math.random() * 1000000) + '_' + Date.now();

export function lightOrDark(color) {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
}

export const uuidColor = (uuid, options = {}) => {
  const { mode = 'any' } = options;
  if (mode === 'dark') {
    return uniqolor(uuid, {
      saturation: [17, 10],
      lightness: 25,
    });
  } else if (mode === 'light') {
    return uniqolor(uuid, {
      saturation: [97, 90],
      lightness: 92,
      differencePoint: 50,
    });
  }
  return uniqolor(uuid);
  /* const parts = uuid.split('-');
  const ints = parts.map(function (d) {
    return parseInt(d, 16);
  });
  //const code = ints[ints.length-1];
  const code = ints[0];

  const blue = (code >> 16) & 31;
  const green = (code >> 21) & 31;
  const red = (code >> 27) & 31;
  const foreColor = 'rgb(' + (red << 3) + ',' + (green << 3) + ',' + (blue << 3) + ')';

  return foreColor; */
};

/* General state etc. function */

export const findUidInInput = (input, key) => {
  for (const repeatable in input) {
    const part = input[repeatable];
    if (!Array.isArray(part)) continue;
    const match = part.find((item) => item._uid === key);
    if (match) return { repeatable, match };
  }
  return {};
};

/* Array functions */

export const arrayUnique = (array) => {
  const a = array.concat();
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
};

/**
 * 
 * @param {array} arrInp    Array of objects to make unique
 * @param {array} props     List of properties to base uniqueness on
 * 
 * @example
  const list = [{ city: 'sthlm', zip: 12345 }, { city: 'sthlm', zip: 12345 }, { city: 'gbg', zip: 33333 }]
  getUniqeArrayByProperties(list, ['city', 'zip']);
  // returns [{ city: 'sthlm', zip: 12345 }, { city: 'gbg', zip: 33333 }]
 */
export const getUniqeArrayByProperties = (arrInp = [{}], props = []) => {
  const objKey = {};
  return arrInp.reduce((res, item) => {
    const valStr = props.reduce((res, prop) => `${res}${item[prop]}`, '');
    if (objKey[valStr]) return res;
    objKey[valStr] = item;
    return [...res, item];
  }, []);
};

export const ocount = (obj) => {
  if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) return 0;
  return Object.keys(obj).length;
};

export const omap = (obj, fn) => {
  const result = [];
  for (const key in obj) {
    result.push(fn(obj[key], key));
  }
  return result;
};

export const ofilter = (obj, fn) => {
  const result = {};
  for (const key in obj) {
    if (fn(obj[key], key)) result[key] = obj[key];
  }
  return result;
};

export const oreduce = (obj, fn, target) => {
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    fn(target, obj[key], i, keys, key);
  }
  return target;
};

export const ofindKey = (obj, predicate) => {
  for (const [key, values] of Object.entries(obj)) {
    if (predicate(values)) return key;
  }
};
export const ofindValues = (obj, predicate) => {
  for (const [, values] of Object.entries(obj)) {
    if (predicate(values)) return values;
  }
};

export const isObject = (obj) => typeof obj === 'object' && !!obj && !Array.isArray(obj);

export const arrays_equal = (a, b) => !!a && !!b && !(a < b || b < a);

export const array_diff = (arr1, arr2) => {
  const diff = [];
  for (let item of arr1) {
    if (arr2.indexOf(item) === -1) diff.push(item);
  }
  return diff;
};

export const array_move = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const array_homogenous = (arr) => {
  const firstVal = arr[0];
  for (let val of arr) {
    if (firstVal !== val) return false;
  }
  return true;
};

export function compareWithTransformation(fn) {
  return function compare(a, b) {
    const firstValue = fn(a);
    const secondValue = fn(b);

    if (firstValue < secondValue) {
      return -1;
    }
    if (firstValue > secondValue) {
      return 1;
    }
    return 0;
  };
}

export function compare(a, b) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

/* Various */

export const translateText = (text) => {
  return text;
  /* if (typeof langValues === "undefined") return text;

  return langValues[text]; */
};

export const tryParseJSON = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o;
    }
  } catch (e) {}

  return false;
};

export const mergeObj = (obj1, obj2) => deepmerge(obj1, obj2, { arrayMerge: combineMerge });

const emptyTarget = (value) => (Array.isArray(value) ? [] : {});
const clone = (value, options) => deepmerge(emptyTarget(value), value, options);

export const combineMerge = (target, source, options) => {
  const destination = target.slice();

  source.forEach((item, index) => {
    if (typeof destination[index] === 'undefined') {
      const cloneRequested = options.clone !== false;
      const shouldClone = cloneRequested && options.isMergeableObject(item);
      destination[index] = shouldClone ? clone(item, options) : item;
    } else if (options.isMergeableObject(item)) {
      destination[index] = deepmerge(target[index], item, options);
    } else if (target.indexOf(item) === -1) {
      destination.push(item);
    }
  });
  return destination;
};

export const getByPath = (collection, path, instructions = {}) => {
  if (!collection) {
    console.warn('No state collection for ', path);
    return;
  }
  let ref = collection;
  const paths = path.split('.');

  if (instructions && instructions.skip) {
    if (instructions.skip > 0) {
      for (let i = 0; i < instructions.skip; i++) paths.shift();
    }
  }

  for (const pointer of paths) {
    if (!ref.hasOwnProperty(pointer)) return;
    ref = ref[pointer];
  }
  return ref;
};
export function setByPath(obj, path, value, instructions = {}) {
  var i;
  if (!path) {
    return console.trace('Invalid path!');
  }
  path = path.split('.');

  if (instructions && instructions.skip) {
    if (instructions.skip > 0) {
      for (let i = 0; i < instructions.skip; i++) path.shift();
    }
  }

  for (i = 0; i < path.length - 1; i++) {
    if (!obj[path[i]]) {
      if (instructions.force) obj[path[i]] = {};
      else {
        console.warn('Cannot find ', path[i], ' of obj ', { obj: JSON.parse(JSON.stringify(obj)) });
        return;
      }
    }
    obj = obj[path[i]];
  }

  if (instructions.deleteProperty) delete obj[path[i]];
  else obj[path[i]] = value;
}
export const isUndefined = (value) => {
  return typeof value === 'undefined';
};

export function dayAndMonth(str, opts = {}) {
  if (!str) return '(Unknown date)';
  let fixedDate;
  if (str instanceof Date) fixedDate = str;
  else {
    const dates = str.split(/[- T : .]/);
    fixedDate = new Date(Date.UTC(dates[0], dates[1] - 1, dates[2], dates[3], dates[4], dates[5]));
  }

  const monthType = opts.month === 'long' ? 'long' : 'short';

  const day = fixedDate.getDate();
  const month = fixedDate.toLocaleString('default', { month: monthType });

  return day + ' ' + month;
}

export function fullDate(date) {
  let d = date ? new Date(date) : new Date();
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export const textAbstract = (text, length) => {
  if (!text) {
    return '';
  }
  if (text.length <= length) {
    return text;
  }
  text = text.substring(0, length);
  /* let last = text.lastIndexOf(" ");
  text = text.substring(0, last); */
  return text + '...';
};
export const capText = function (str) {
  return ucwords(str.split('_').join(' '));
};

export const ucfirst = function (str) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const ucwords = function (str) {
  if (!str) return '';
  return str.replace(/(^|\s)([a-z])/g, function (m, p1, p2) {
    return p1 + p2.toUpperCase();
  });
};

export const format_number = (num, level = 0, listFormat) => {
  if (!listFormat || !listFormat.formats || !listFormat.formats[level]) return '(' + num + ')';

  const format = listFormat.formats[level].format;
  if (!format) return '(' + num + ')';

  const doAlpha = (num) => {
    return (num >= 26 ? doAlpha(((num / 26) >> 0) - 1) : '') + 'abcdefghijklmnopqrstuvwxyz'[num % 26 >> 0];
  };
  const formatization = {
    alpha: (num) => {
      return (num >= 26 ? doAlpha(((num / 26) >> 0) - 1) : '') + 'abcdefghijklmnopqrstuvwxyz'[num % 26 >> 0];
    },
    roman: (num) => {
      if (isNaN(num)) return NaN;
      var digits = String(+num).split(''),
        key = [
          '',
          'c',
          'cc',
          'ccc',
          'cd',
          'd',
          'dc',
          'dcc',
          'dccc',
          'cM',
          '',
          'x',
          'xx',
          'xxx',
          'xl',
          'l',
          'lx',
          'lxx',
          'lxxx',
          'xc',
          '',
          'i',
          'ii',
          'iii',
          'iv',
          'v',
          'vi',
          'vii',
          'viii',
          'ix',
        ],
        roman = '',
        i = 3;
      while (i--) roman = (key[+digits.pop() + i * 10] || '') + roman;
      return Array(+digits.join('') + 1).join('M') + roman;
    },
  };
  let text;

  const split = format.split('-');
  if (split.length > 1) {
    // 0: lower/upper, 1: format
    if (!formatization[split[1]]) return NaN;
    text = formatization[split[1]](split[1] === 'alpha' ? num - 1 : num);
    if (split[0] === 'upper') text = text.toUpperCase();
  }
  if (text === undefined) text = '|' + num + '|';
  return (listFormat.prefix || '') + text + (listFormat.suffix || '');
};

const romanify = (index) =>
  format_number(index + 1, 0, { formats: [{ format: 'lower-roman' }], prefix: '(', suffix: ')' });

export const inline_list = (arr) => {
  if (arr.length === 1) {
    return arr[0];
  }
  var last = arr.pop();
  var response = '';
  var mainIndex;
  arr.forEach(function (item, index) {
    response += romanify(index) + ' ' + item + ', ';
    mainIndex = index;
  });
  return response + translateText('and') + ' ' + romanify(mainIndex + 1) + ' ' + last;
};

export const imp_dyn = (arr, itemjoin) => {
  if (!Array.isArray(arr) || arr.length === 0) return '';
  if (!itemjoin) {
    return arr.join(', ');
  }
  if (arr.length === 1) return arr[0];
  const retarr = [...arr];
  const last = retarr.pop();
  return retarr.join(', ') + ' ' + itemjoin + ' ' + last;
};
export const imp_and = (arr, langAnd) => {
  if (!Array.isArray(arr) || arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  const retarr = [...arr];
  const last = retarr.pop();
  if (langAnd) return retarr.join(', ') + ' ' + langAnd + ' ' + last;
  return retarr.join(', ') + ' ' + translateText('and') + ' ' + last;
};
export const imp_or = (arr, langOr) => {
  if (!Array.isArray(arr) || arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  const retarr = [...arr];
  const last = retarr.pop();
  if (langOr) return retarr.join(', ') + ' ' + langOr + ' ' + last;
  return retarr.join(', ') + ' ' + translateText('or') + ' ' + last;
};

export const splitNumber = (val, language) => {
  if (val === '' || val === undefined || val === null) return 0;
  if (val === 0) return 0;
  val = val.toString();
  val = combineNumber(val);
  val += '';
  var rgx = /(\d+)(\d{3})/;
  var numberSeparator = language === 'sv' ? ' ' : ',';
  while (rgx.test(val)) {
    val = val.replace(rgx, '$1' + numberSeparator + '$2');
  }
  return val;
};
export const combineNumber = (numstring, language) => {
  if (numstring === undefined || numstring === 0 || numstring === null || numstring === '') {
    return '';
  }
  if (typeof numstring !== 'string') numstring = numstring.toString();
  if (typeof numstring !== 'string') return '';
  numstring = numstring.replace(/\s+/g, '');
  if (language === 'sv') {
    // Swed
    return numstring;
  } else {
    // Eng
    // Replace any initial '0,' (which would typically be 0.3 even in English)
    if (numstring.substr(0, 2) === '0,') numstring = '0.' + numstring.substr(2);
    return numstring.replace(/,/g, '');
  }
};

export function floatConverter(n, language) {
  if (!isFloat(n)) return n;
  switch (language) {
    case 'sv':
      return n.toString().replace('.', ',');
    default:
      return n;
  }
}

export function isFloat(n) {
  return n === +n && n !== (n | 0);
}

export function isInteger(n) {
  return n === +n && n === (n | 0);
}

export function isNumeric(str) {
  if (typeof str === 'number' && !isNaN(str)) return true;
  if (typeof str !== 'string') return false;
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
export function getPotentialNumber(str) {
  if (typeof str === 'number' && !isNaN(str)) return { isNumber: true, value: str };
  if (typeof str !== 'string') return { isNumber: false, value: '' };
  str = str.replace(/\s+/g, '');
  str = str.replace(/,/g, '');
  // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...

  const floatNumber = parseFloat(str);
  if (!isNaN(str) && !isNaN(floatNumber)) {
    return { isNumber: true, value: floatNumber };
  }
  return { isNumber: false, value: str };
}

export const floatify = (numstring) => {
  if (!numstring || numstring === 0 || numstring === '') return 0;
  return parseFloat(numstring.replace(/,/g, '.'));
};

/*
// http://api.currencylayer.com/live?access_key=1dea7188234bd04b00975177952b87f3&source=USD&currencies=EUR,USD,DKK,NOK,GBP,SEK&format=1
{
  "success":true,
  "terms":"https:\/\/currencylayer.com\/terms",
  "privacy":"https:\/\/currencylayer.com\/privacy",
  "timestamp":1620180784,
  "source":"USD",
  "quotes":{
    "USDEUR":0.831802,
    "USDUSD":1,
    "USDDKK":6.185701,
    "USDNOK":8.315797,
    "USDGBP":0.71895,
    "USDSEK":8.470401
  }
}
*/
export function convertCurrency(sourceCurrency, targetCurrency, options = {}) {
  if (!sourceCurrency || !targetCurrency) return null;
  if (sourceCurrency === targetCurrency) return 1;
  const { exchangeRates, round = false } = options;
  if (!exchangeRates) return null;
  const { quotes } = exchangeRates;
  if (!quotes['USD' + sourceCurrency]) {
    console.log('Unsupported source currency');
    return null;
  }
  if (!quotes['USD' + targetCurrency]) {
    console.log('Unsupported target currency');
    return null;
  }
  const USDtarget = quotes['USD' + targetCurrency];
  if (sourceCurrency === 'USD') return USDtarget;

  const USDsource = quotes['USD' + sourceCurrency];
  const result = USDtarget / USDsource;

  if (round === 0) return Math.round(result + Number.EPSILON);
  if (round === 1) return Math.round((result + Number.EPSILON) * 10) / 10;
  if (round === 2 || round === true) return Math.round((result + Number.EPSILON) * 100) / 100;

  return result;
}

export function getCurrenciesAndNumbers(string, currencies, language) {
  if (typeof string !== 'string' || !Array.isArray(currencies)) return {};
  return string.split(' ').map((item) => {
    const itemUpper = item.toLocaleUpperCase();
    if (currencies.includes(itemUpper)) return itemUpper;
    let itemCombined = combineNumber(item, language);
    if (itemCombined.match(/^\d+$/)) return itemCombined;
    return null;
  });
}
export function currenciesAndNumbersToObject(currenciesAndNumbers, currencies) {
  if (!Array.isArray(currenciesAndNumbers) || !Array.isArray(currencies)) return {};
  const collection = {};
  const result = {};
  let currentCurrency;
  for (const item of currenciesAndNumbers) {
    if (!item) continue;
    if (currencies.includes(item)) {
      collection[item] = [];
      currentCurrency = item;
    } else if (currentCurrency) {
      collection[currentCurrency].push(item);
    }
  }
  for (const currency in collection) {
    const total = parseInt(collection[currency].join(''));
    if (total) {
      result[currency] = total;
    }
  }
  return result;
}
export function stringToCurrenciesAndAmounts(string, currencies, language) {
  return currenciesAndNumbersToObject(getCurrenciesAndNumbers(string, currencies, language), currencies);
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const generateUID = (length) => {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function hasProperty(obj, prop) {
  if (typeof obj !== 'object') return false;
  if (typeof prop !== 'string' && typeof prop !== 'number') return false;
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function sortByCreatedAt(a, b) {
  if (a.createdAt < b.createdAt) return 1;
  if (a.createdAt > b.createdAt) return -1;
  return 0;
}
export function sortByUpdatedAt(a, b) {
  if (a.updatedAt < b.updatedAt) return 1;
  if (a.updatedAt > b.updatedAt) return -1;
  return 0;
}
