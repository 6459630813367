import { useState } from 'react';
import IntlMessages from 'util/IntlMessages';

export default function ShowHide({
  title = {},
  open = false,
  animated = false,
  className = '',
  wrapperClassName = '',
  children,
}) {
  const { show, hide } = title;
  const [isOpen, setIsOpen] = useState(open);

  return (
    <div className={wrapperClassName}>
      <span className="link" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <span>
            <i className="mdi mdi-chevron-down mr-1" />
            {hide || <IntlMessages id={'app.general.Hide'} />}
          </span>
        ) : (
          <span>
            <i className="mdi mdi-chevron-right mr-1" />
            {show || <IntlMessages id={'app.general.Show'} />}
          </span>
        )}
      </span>
      <div style={{ marginTop: '1em', marginLeft: '1.25em' }}>
        {animated && <div className={className + ' hidden ' + (isOpen ? 'open' : '')}>{children}</div>}
        {!animated && isOpen && children}
      </div>
    </div>
  );
}
