import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Select, Button, Checkbox } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

const { Option } = Select;

export default function InputTypeSelect({
  inputType,
  onChildUpdate,
  currentInput,
  contractLanguauge,
  propertyName = 'content',
}) {
  // Translate the content of the input data to an object with options
  let defaultOptions = {};
  if (currentInput && currentInput[propertyName]) {
    defaultOptions = Object.assign(
      {},
      ...currentInput[propertyName]
        .filter((c) => !c.disabled)
        .map((s) => {
          return {
            [s.id]: {
              label: s.label[contractLanguauge],
              value: s.values && s.values[contractLanguauge],
            },
          };
        })
    );
  }
  const formatMessage = useIntlMessage();
  const [isOpen, setIsOpen] = useState(false);

  const [optionId, setOptionId] = useState('');
  const [optionLabel, setOptionLabel] = useState('');
  const [optionIdError, setOptionIdError] = useState(null);
  const [optionLabelError, setOptionLabelError] = useState(null);
  const [options, setOptions] = useState(defaultOptions);
  const [defaultValue, setDefaultValue] = useState(currentInput ? currentInput.value : '-1');
  const [allowCustomOptions, setAllowCustomOptions] = useState(
    currentInput ? !!currentInput.allowCustomOptions : false
  );
  const [hasCustomValue, setHasCustomValue] = useState(false);
  const [customValue, setCustomValue] = useState('');

  const toggleOptionsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionIdChange = (evt) => {
    const { value } = evt.target;
    setOptionId(value);
    setOptionIdError('');
  };

  const handleOptionLabelChange = (evt) => {
    const { value } = evt.target;
    setOptionLabel(value);
    setOptionLabelError('');
  };

  const handleCustomValueChange = (value) => {
    setCustomValue(value);
  };

  const addOption = () => {
    if (!optionId) return setOptionIdError('Option id must be specified.');
    if (!optionLabel) return setOptionIdError('Option label must be specified.');

    if (options[optionId])
      return setOptionIdError('Option with the same id already exists, choose another one.');

    const newOptions = { ...options, [optionId]: { label: optionLabel, value: customValue || null } };
    setOptions(newOptions);
    setOptionId('');
    setOptionLabel('');
    setCustomValue('');
    setHasCustomValue(false);
    updateParent(newOptions, defaultValue);
  };

  const updateParent = (options, value, allow = allowCustomOptions) => {
    onChildUpdate({ options, defaultValue: value === '-1' ? null : value, allowCustomOptions: allow });
  };

  const removeOption = (id) => {
    const newOptions = { ...options };
    delete newOptions[id];
    setOptions(newOptions);
    if (id === defaultValue) setDefaultValue(null); // If default value was removed
    updateParent(newOptions, defaultValue);
  };

  const onChangeDefaultValue = (value) => {
    setDefaultValue(value);
    updateParent(options, value); // We want null in parent if no default value is chosen
  };

  const onChangeAllowCustomOptions = (e) => {
    const allow = e.target.checked;
    setAllowCustomOptions(allow);
    updateParent(options, defaultValue, allow);
  };

  const rows = Object.entries(options).map(([id, data]) => ({ id, ...data }));

  const renderOptions = () => {
    const rowsLength = rows.length;
    return (
      <div className="p-2 m-2">
        {rowsLength > 0 && (
          <>
            <Row className="flex-row">
              <h5>
                <IntlMessages id="studio.template.inputs.select.options.added" />
              </h5>
            </Row>
            <Row className={'flex-row mt-3 mb-2 pb-2'} style={{ borderBottom: '1px dashed #d9d9d9' }}>
              <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
                <strong>
                  <IntlMessages id="studio.template.inputs.select.options.id" />
                </strong>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
                <strong>
                  <IntlMessages id="studio.template.inputs.select.options.label" />
                </strong>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
                <strong>
                  <IntlMessages id="studio.template.inputs.select.options.value" />
                </strong>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
                <strong>
                  <IntlMessages id="studio.template.inputs.select.options.actions" />
                </strong>
              </Col>
            </Row>
          </>
        )}
        {rows.map((row, index) => (
          <Row
            key={row.id}
            className={'flex-row mb-2 pb-2 ' + (index + 1 !== rowsLength ? 'border-bottom' : '')}
          >
            <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
              {row.id}
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
              {row.label}
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
              {row.value || row.id}
            </Col>
            <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
              <Button type="secondary" className="m-0" onClick={() => removeOption(row.id)}>
                <IntlMessages id="studio.template.inputs.select.options.remove" />
              </Button>
            </Col>
          </Row>
        ))}
        {rowsLength > 0 && <div className="mb-4"> </div>}
      </div>
    );
  };

  const renderDefaultOption = () => {
    return (
      <Form layout="vertical">
        <Input.Group compact>
          <Form.Item
            label={<IntlMessages id="studio.template.inputs.select.option.defaultValue.label" />}
            style={{
              minWidth: '25%',
            }}
            extra={
              <div>
                <small>
                  <IntlMessages id="studio.template.inputs.select.option.defaultValue.info" />
                </small>
              </div>
            }
          >
            {inputType === 'checkbox' ? (
              <>
                <Checkbox.Group
                  options={Object.values(options)}
                  value={Array.isArray(defaultValue) ? defaultValue : []}
                  onChange={onChangeDefaultValue}
                />
              </>
            ) : (
              <Select onChange={onChangeDefaultValue} value={defaultValue} placeholder="">
                <Option value="-1" key="-1">
                  <IntlMessages id="studio.template.inputs.select.option.noDefault" className="font-italic" />
                </Option>
                {rows.map((row) => (
                  <Option value={row.id} key={row.id}>
                    {row.label}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
          {inputType === 'select' && (
            <Form.Item
              label={<IntlMessages id="studio.template.inputs.select.option.allowCustomOptions.label" />}
              className="ml-2"
              extra={
                <div>
                  <small>
                    <IntlMessages id="studio.template.inputs.select.option.allowCustomOptions.info" />
                  </small>
                </div>
              }
            >
              <Checkbox checked={!!allowCustomOptions} onChange={onChangeAllowCustomOptions} />
            </Form.Item>
          )}
        </Input.Group>
      </Form>
    );
  };

  useEffect(() => {
    if (currentInput && Object.keys(defaultOptions).length > 0) {
      updateParent(options, defaultValue);
    }
    // Only on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="border p-2 mb-3">
        <Form.Item
          label={
            <span>
              <IntlMessages id="studio.template.inputs.select.options" />
              <span className="ml-3 link" onClick={toggleOptionsOpen}>
                {isOpen && <IntlMessages id="desc.Close" className="mr-1" />}
                <IntlMessages id="desc.Add" />{' '}
                <IntlMessages id="studio.template.inputs.select.options.anOption" />
              </span>
            </span>
          }
          className="mb-0"
        >
          {isOpen && (
            <div className="additional-input-field-choices">
              <Form layout="vertical" className="p-3">
                <Input.Group compact>
                  <Form.Item
                    label={<IntlMessages id="studio.template.inputs.select.option.id.label" />}
                    extra={
                      <div>
                        <small>
                          <IntlMessages id="studio.template.inputs.select.option.id.info" />
                        </small>
                        {optionIdError && <div className="text-warning">{optionIdError}</div>}
                      </div>
                    }
                  >
                    <Input
                      type="text"
                      value={optionId}
                      onChange={handleOptionIdChange}
                      placeholder={formatMessage('studio.template.inputs.select.option.id.placeholder')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<IntlMessages id="studio.template.inputs.select.option.label.label" />}
                    extra={
                      <div>
                        <small>
                          <IntlMessages id="studio.template.inputs.select.option.label.info" />
                        </small>
                        {optionLabelError && <div className="text-warning">{optionLabelError}</div>}
                      </div>
                    }
                  >
                    <Input
                      type="text"
                      value={optionLabel}
                      onChange={handleOptionLabelChange}
                      placeholder={formatMessage('studio.template.inputs.select.option.label.placeholder')}
                    />
                  </Form.Item>

                  <Form.Item
                    label={<IntlMessages id="studio.template.inputs.select.option.hasCustomValue.label" />}
                    // style={{ width: '35%' }}
                    className="ml-2"
                    extra={
                      <div>
                        <small>
                          <IntlMessages id="studio.template.inputs.select.option.hasCustomValue.info" />
                        </small>
                      </div>
                    }
                  >
                    <Checkbox checked={hasCustomValue} onChange={() => setHasCustomValue(!hasCustomValue)} />
                  </Form.Item>
                  <Form.Item
                    label={<IntlMessages id="studio.template.inputs.select.option.customValue.label" />}
                    // style={{ width: '55%' }}
                    className="ml-2"
                    extra={
                      <div>
                        <small>
                          <IntlMessages id="studio.template.inputs.select.option.customValue.info" />
                        </small>
                      </div>
                    }
                  >
                    <Input
                      type="text"
                      value={customValue}
                      onChange={(evt) => handleCustomValueChange(evt.target.value)}
                      disabled={!hasCustomValue}
                      placeholder={formatMessage(
                        'studio.template.inputs.select.option.customValue.placeholder'
                      )}
                    />
                  </Form.Item>
                </Input.Group>
                <Button.Group className="w-100 px-4">
                  <Button
                    type="secondary"
                    // block
                    className="w-50"
                    onClick={toggleOptionsOpen}
                  >
                    <IntlMessages id="desc.Close" />
                  </Button>
                  <Button
                    type="primary"
                    // block
                    className="w-50"
                    onClick={addOption}
                    disabled={optionIdError || optionLabelError || optionId === '' || optionLabel === ''}
                  >
                    <IntlMessages id="studio.template.inputs.select.option.addButton" />
                  </Button>
                </Button.Group>
              </Form>
            </div>
          )}
        </Form.Item>
        {renderOptions()}
        {renderDefaultOption()}
      </div>
    </>
  );
}
