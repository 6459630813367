import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Spin } from 'antd';
import api from 'utils/api';

const Document = (props) => {
  const { match } = props;
  const { id } = match.params;

  const history = useHistory();

  useEffect(() => {
    async function fetch() {
      try {
        const fetchDoc = await api.get(`/documents/${id}?fields=projectId`);
        if (!fetchDoc.data) return;
        history.replace(`/project/${fetchDoc.data.projectId}/document/${id}`);
      } catch (err) {
        console.log('err fetching doc', err);
      }
    }
    fetch();
  }, [history, id]);

  return (
    <Card title="" className="card">
      <Spin className="loader-container" />
    </Card>
  );
};
export default Document;
