import { Entity } from 'core/interfaces';

const prices = {
  junior_projektledare: 1050,
  junior_scrum_master: 1050,
  junior_kravutredare: 1050,
  junior_business_analyst: 1050,
  junior_testledare: 1050,
  senior_projektledare: 1250,
  senior_scrum_master: 1250,
  senior_kravutredare: 1250,
  senior_business_analyst: 1250,
  senior_testledare: 1250,
  junior_systemutvecklare: 1050,
  senior_systemutvecklare: 1300,
  junior_it_arkitekt: 1300,
  senior_it_arkitekt: 1700,
  junior_test: 950,
  senior_test: 1050,
};

const CARD_NAME = 'instll-qilh';
const ENTITY_FIELD_NAME = 'instll-qilh//konsul_uvzw';
const PRICE_FIELD_NAME = 'instll-qilh//timpri_gkbe';
const ROLE_FIELD_NAME = 'instll-qilh//titel_uagh';
const DISCOUNT_CARD_NAME = 'beskri-boyt';
const DISCOUNT_FIELD_NAME = 'discount';

const proposePrice = (data, state) => {
  const discount = (100 - (state.input[DISCOUNT_CARD_NAME][DISCOUNT_FIELD_NAME] || 0)) / 100
  const { uid, value } = data;

  if (!prices[value]) return;

  return [
    {
      path: `input.${CARD_NAME}.${uid}.${PRICE_FIELD_NAME}`,
      value: Math.floor(prices[value] * discount),
    },
  ];
};

const proposeDiscountedPrice = (data, state) => {
  const { value } = data
  const discount = (100 - (value || 0)) / 100
  return Object.entries(state.input[CARD_NAME]).map(([key, value]) => (
    {
      path: `input.${CARD_NAME}.${key}.${PRICE_FIELD_NAME}`,
      value: Math.floor(prices[value[ROLE_FIELD_NAME]] * discount),
    }
  ))
}

const autoSetRole = (data, state) => {
  const { entities } = state;
  const { uid, value } = data;
  if (!value || !value.id) return;
  const entity = Entity.getById(entities, value.id);
  if (entity.data?.role) {
    return [
      {
        path: `input.${CARD_NAME}.${uid}.${ROLE_FIELD_NAME}`,
        value: entity.data.role,
      },
    ];
  }
};

const inputs = [
  {
    trigger: `${CARD_NAME}->${ROLE_FIELD_NAME}`,
    mode: 'single',
    function: proposePrice,
    info: {
      name: 'Propose price',
      category: 'legal',
    },
  },
  {
    trigger: `${DISCOUNT_CARD_NAME}->${DISCOUNT_FIELD_NAME}`,
    mode: 'single',
    function: proposeDiscountedPrice,
    info: {
      name: 'Propose price',
      category: 'legal',
    },
  },
  {
    trigger: `${CARD_NAME}->${ENTITY_FIELD_NAME}`,
    mode: 'single',
    function: autoSetRole,
  },
];

export default inputs;
