import React from 'react';
import FileList from 'components/File/FileList';

const FilesConnected = ({ data }) => {
  const { files, onDisconnectFile } = data;
  return (
    <FileList
      tableType="listConnected"
      hideCard={true}
      connectedFiles={files}
      onDisconnectFile={onDisconnectFile}
      hideNewUpload={true}
    />
  );
};

export default FilesConnected;
