import { Editor, Transforms, Path, Point, Node, Range } from 'slate';
import { ReactEditor } from 'slate-react';
import isHotkey from 'is-hotkey';
// import tableHotkey from './onKeyDown/table';
import { toggleMark } from './toolbar/TopToolbar';
import { isUserInteractiveUpdateField } from 'core/types/elements';
import scrollIntoView from 'scroll-into-view-if-needed';
import { isActive } from 'core/types/elements';

const MARK_HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underlined',
};
/* const CMD_HOTKEYS = {
  'tab': 'indent_block',
  'shift+tab': 'dedent_block'
} */

// arrows + tab + enter
const navigation_keyCodes = [37, 38, 39, 40, 9, 13];

const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;

function goBefore(editor, options = {}) {
  const { steps = 1, shiftKey } = options;

  if (!editor.selection?.focus?.path) return;

  let beforePoint,
    beforePath = editor.selection.focus.path;
  for (let i = 0; i < steps; i++) {
    beforePoint = Editor.before(editor, beforePath);
    if (!beforePoint) return;
    beforePath = beforePoint.path;
  }

  const newSelection = {
    anchor: beforePoint,
    focus: beforePoint,
  };
  if (shiftKey) delete newSelection.anchor;
  Transforms.setSelection(editor, newSelection);
  editor.tmp._nextRange = newSelection;
}

function goAfter(editor, options = {}) {
  const { afterPoint, setNextRange = true, shiftKey } = options;
  const newSelection = {
    anchor: afterPoint,
    focus: afterPoint,
  };
  if (shiftKey) delete newSelection.anchor;
  Transforms.setSelection(editor, newSelection);
  if (setNextRange) editor.tmp._nextRange = newSelection;
}

export default function onKeyDown(event, editor, refs = {}) {
  const { shiftKey } = event;
  if (editor.selection) {
    // For the selection handler in `set_selection.js`, indicate if and
    // how the new selection was initiated (so it know if the user is
    // going left-to-right or right-to-left)
    if (shiftKey) {
      editor.tmp._lastKeyCode = event.keyCode;
    }
    if (event.keyCode === RIGHT_ARROW && !shiftKey) {
      // Going right INTO an inline.
      let next = Editor.next(editor, { at: editor.selection.focus.path });
      if (
        next &&
        Point.equals(Editor.end(editor, editor.selection.focus.path), editor.selection.focus) &&
        editor.isInline(next[0]) &&
        !Path.equals(editor.selection.focus.path, next[1])
      ) {
        let afterPoint = Editor.after(editor, editor.selection.anchor.path);
        let setNextRange = true;

        // Dont step into an inactive inline field.
        if (!isActive(next[0])) {
          afterPoint = Editor.after(editor, afterPoint);
        }

        if (Node.get(editor, Path.parent(afterPoint.path)).type === 'tab') {
          afterPoint = Editor.after(editor, afterPoint.path);
          setNextRange = false;
        }
        event.preventDefault();
        goAfter(editor, { afterPoint, setNextRange, shiftKey });
      }
      // Going right OUT of an inline.
      else {
        const currentInlinePath = editor.currentInlinePath();
        if (
          currentInlinePath &&
          Point.equals(editor.selection.focus, Editor.end(editor, currentInlinePath))
        ) {
          const afterPoint = Editor.after(editor, currentInlinePath);
          goAfter(editor, { afterPoint, shiftKey });
          event.preventDefault();
        } else {
          editor.tmp._nextRange = null;
        }
      }
    } else if (event.keyCode === LEFT_ARROW && editor.selection.focus.offset === 0 && !shiftKey) {
      // Going left INTO an inline.
      const previous = Editor.previous(editor, { at: editor.selection.anchor.path });
      if (Array.isArray(previous) && editor.isInline(previous[0])) {
        event.preventDefault();
        let steps = 1;
        if (previous[0].type === 'tab' || !isActive(previous[0])) steps = 2;
        goBefore(editor, { steps, shiftKey });
      }
      // Going left OUT OF an inline.
      else {
        const currentInlinePath = editor.currentInlinePath();
        if (currentInlinePath) {
          event.preventDefault();
          goBefore(editor, { shiftKey });
        } else {
          editor.tmp._nextRange = null;
        }
      }
    }
    // If we are going left and into an the right edge of an inline,
    // ensure that we upon text entry stay on the edge of the text.
    // Do not prevent default as we want to allow the selection to
    // be at offset 0, but merely ensure that it stays there upon
    // text inserts.
    else if (event.keyCode === LEFT_ARROW && editor.selection.focus.offset === 1) {
      const newSelection = { focus: { ...editor.selection.focus, offset: 0 } };
      if (!shiftKey) {
        newSelection.anchor = { ...editor.selection.anchor, offset: 0 };
      }
      editor.tmp._nextRange = newSelection;
    } else if (navigation_keyCodes.includes(event.keyCode)) {
      editor.tmp._nextRange = null;
    }
  }

  /* if (editor.isCell() && navigation_keyCodes.includes(event.keyCode)) {
    tableHotkey(editor, event);
  } */

  for (const hotkey in MARK_HOTKEYS) {
    if (isHotkey(hotkey, event)) {
      event.preventDefault();
      const mark = MARK_HOTKEYS[hotkey];
      toggleMark(editor, mark);
      return;
    }
  }

  if (event.keyCode === 27) {
    if (refs && refs.onEscapeFnRef && typeof refs.onEscapeFnRef.current === 'function')
      refs.onEscapeFnRef.current();
  }

  if (
    editor.meta.isTabbingFields === true &&
    editor.selection &&
    editor.selection.anchor &&
    editor.selection.anchor.path &&
    event.keyCode === 9
  ) {
    ReactEditor.focus(editor);
    const fields = Editor.nodes(editor, {
      match: isUserInteractiveUpdateField,
      at: [editor.selection.anchor.path, Editor.last(editor, [])[1]],
    }); // Returns an iterator with fields

    const firstEntry = fields.next().value;
    if (!firstEntry) {
      return; // No fields found
    }
    let [, path] = firstEntry;
    // Loop until we find a path which is after the currently selected path
    while (!Path.isAfter(path, editor.selection.anchor.path)) {
      let entry = fields.next().value;
      if (!entry) {
        [[, path]] = Editor.nodes(editor, {
          match: isUserInteractiveUpdateField,
          at: [],
        });
        break;
      }
      [, path] = entry;
    }

    const newSelection = {
      anchor: Editor.start(editor, path),
      focus: Editor.end(editor, path),
    };

    Transforms.select(editor, newSelection);
    event.preventDefault();
    const holderDomElement = document.getElementById('top-editor-holder');
    const scrollDomElement = holderDomElement?.parentElement;
    if (scrollDomElement) {
      const newDomRange = ReactEditor.toDOMRange(editor, newSelection);
      if (!newDomRange) return;
      const leafEl = newDomRange.startContainer.parentElement;
      if (!leafEl) return;
      scrollIntoView(leafEl, {
        scrollMode: 'if-needed',
        boundary: scrollDomElement,
      });
    }
  } else if (event.keyCode === 9) {
    if (Range.isCollapsed(editor.selection)) {
      if (shiftKey) {
        event.preventDefault();
        return editor.cmd_dedent_block();
      } else if (editor.selection.anchor.offset === 0) {
        const hasListItem =
          Array.from(
            Editor.nodes(editor, {
              at: editor.selection,
              match: (n) => n.type === 'list_item',
              mode: 'lowest',
            })
          ).length > 0;
        if (hasListItem) {
          event.preventDefault();
          return editor.cmd_indent_block();
        }
      }
      const currentInline = editor.currentInline();
      if (currentInline && currentInline.type === 'tab') {
        // Transforms.move(editor)
        const afterPoint = Editor.after(editor, editor.selection);
        const newSelection = {
          anchor: JSON.parse(JSON.stringify(afterPoint)),
          focus: JSON.parse(JSON.stringify(afterPoint)),
        };
        // console.log('Set new selection at ', newSelection);
        Transforms.setSelection(editor, newSelection);
      }
      // Lets insert a tabbed text
      Transforms.insertNodes(editor, { type: 'tab', size: 40, children: [{ text: '' }] });
      // Go to next.
      Transforms.move(editor);
      event.preventDefault();
    }
  }
}
