import React from 'react';
import { Modal, Button, Row, Tabs } from 'antd';
import { useStudioEditorInputContent, setStudioEditorInputContent, getContractValues } from 'hooks';
import { Contract, InputPaths } from 'core/interfaces';
import { StudioEditorInputContent } from 'contexts/contexts';
import { findPageByStudioPage } from 'utils/draftPath';
import CardContent from 'routes/studio/components/StudioInput/renderDraftUI/components/card/CardContent';
import CreatePage from 'routes/studio/components/StudioInput/renderDraftUI/CreatePage';

const { TabPane } = Tabs;

export default function ContractInputEdit() {
  return (
    <StudioEditorInputContent.Provider initialValue={null}>
      <InputEditCard />
    </StudioEditorInputContent.Provider>
  );
}

function useCard(data) {
  if (!data) return null;
  const { cardId, path } = data;
  const contract = getContractValues();

  let relevantCardId = cardId;
  if (!relevantCardId) {
    if (!path) return null;
    relevantCardId = InputPaths.getCardId(contract, path);
  }

  const ui = Contract.getUi(contract);
  const language = Contract.getLanguage(contract);

  const card = ui.cards[relevantCardId];
  const isRepeatable = Contract.getUiIsCardRepeatable(contract, relevantCardId);
  if (!language || !card) {
    // console.warn('Cannot find card or language for cardId ', cardId);
    return null;
  }

  const inputPath = path || `input.${relevantCardId}`;

  return {
    card,
    language,
    path: inputPath,
    isRepeatable,
  };
}

function useStudioPage(data) {
  if (!data) return null;
  const { page, studioPage } = data;
  if (!page && !studioPage) {
    return null;
  }

  if (page) return { page };

  const contract = getContractValues();
  const pageFound = findPageByStudioPage(contract, studioPage);

  return pageFound ? { page: pageFound } : null;
}

function RenderInputPage({ page }) {
  const contract = getContractValues();
  const language = Contract.getLanguage(contract);
  if (page.collapse && page.pages && Object.keys(page.pages).length > 0) {
    return (
      <Tabs defaultActiveKey="0">
        {page.pages_order.map((pId, index) => {
          const child = page.pages[pId];
          if (!child) return null;
          const title = child.header?.[language] || 'No title';
          return (
            <TabPane tab={title} key={index}>
              <CreatePage page={child} />
            </TabPane>
          );
        })}
      </Tabs>
    );
  }
  return <CreatePage page={page} />;
}

function InputEditCard() {
  const data = useStudioEditorInputContent();
  const cardData = useCard(data);
  const pageData = useStudioPage(data);

  const onClose = () => {
    setStudioEditorInputContent(null);
  };

  if (!cardData && !pageData) return null;

  const { card, path, language, isRepeatable } = cardData || {};
  const { page } = pageData || {};

  if (!card && !page) return null;

  return (
    <Modal
      title={''}
      visible={true}
      onOk={onClose}
      onCancel={onClose}
      backdrop={'static'}
      size="lg"
      className="qb-modal"
      footer={
        <Button type="primary" onClick={onClose}>
          OK
        </Button>
      }
    >
      <div className="mb-3">
        {card && (
          <Row>
            <CardContent
              card={card}
              language={language}
              isRepeatable={isRepeatable}
              path={path}
              cardWithinCard={false}
            />
          </Row>
        )}
        {page && <RenderInputPage page={page} />}
      </div>
    </Modal>
  );
}
