import React, { PureComponent } from "react";
import { Row, Col, Select } from "antd";

const structureEligibleJurisdictions = ["sv"];

class SelectSelect extends React.PureComponent {
  render() {
    if (this.props.full) return this.renderFull();

    return <SingleSelect {...this.props} />;
  }

  renderFull = () => {
    const { select, dark, language, settersValue, settersChange } = this.props;

    return (
      <Row className="mt-2">
        <Col span={10}>
          <span
            style={{ fontSize: "12px" }}
            className={dark ? "text-white" : ""}
          >
            {select.label[language]}
            {select.required && "*"}
          </span>
        </Col>
        <Col span={12}>
          <SingleSelect
            select={select}
            id={select.id}
            language={language}
            settersValue={settersValue}
            settersChange={settersChange}
            dark={dark}
          />
        </Col>
      </Row>
    )
  };
}

export default SelectSelect;

class SingleSelect extends PureComponent {

  setValue = (value) => {
      this.props.settersChange(this.props.id, value)
  }

  render() {
    const { id, select, language, settersValue } = this.props;

    // const active = settersValue && settersValue[id] ? settersValue[id] : false;

    let disabled = false;
    if (
      id === "fetchGroupStructure" &&
      !structureEligibleJurisdictions.includes(settersValue.jurisdiction)
    )
      disabled = true;

    return (
      <div>
        <div>
          {select.options ? (
            <Select onChange={this.setValue} value={settersValue[id]} className="w-100">
              {select.options.map(option => (
                <Select.Option
                  key={"rbtn-option-" + option.id}
                  disabled={disabled}
                  value={option.id}
                >
                  {option.label[language]}
                </Select.Option>
              ))}
            </Select>
          ) : null}
          {select.info && select.info.en ? (
            <small className="muted d-block">{select.info.en}</small>
          ) : null}
        </div>
      </div>
    );
  }
}
