import { useEffect, useState, useRef } from 'react';
import api from 'utils/api';
import { array_diff } from 'core/utils/general';

export const useEntityData = (ids, fields = ['id', 'firstName', 'lastName']) => {
  const currentIdsRef = useRef([]);
  const [entityData, setEntityData] = useState({});
  const entityDataRef = useRef({});

  useEffect(() => {
    if (!Array.isArray(ids)) return;
    const actualIds = ids.filter((id) => !!id && typeof id === 'string');
    const uniqueIds = [...new Set(actualIds)];

    const newIds = array_diff(uniqueIds, currentIdsRef.current);
    currentIdsRef.current = [...currentIdsRef.current, ...newIds];
    if (newIds.length > 0) {
      (async () => {
        const idQuery = encodeURI(JSON.stringify(newIds));
        let url = `/entities?ids=${idQuery}`;
        if (Array.isArray(fields)) {
          url += `&fields=${fields.join(',')}`;
        }
        const newEntitiesResponse = await api.get(url);
        if (newEntitiesResponse.data && newEntitiesResponse.data.length) {
          const upd = {
            ...entityDataRef.current,
            ...newEntitiesResponse.data.reduce((acc, curr) => (acc[curr.id] = curr) && acc, {}),
          };
          entityDataRef.current = upd;
          setEntityData(upd);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);
  return entityData;
};

export function useProjectMainEntities(project, options = {}) {
  const { omitClient = true } = options;
  const url = project?.id ? `/projects/${project.id}/entities?${omitClient ? 'omitClient=true' : ''}` : '';

  const [value, error] = useMountFetch(url);
  if (error) return null;
  return value || null;
}

export function useProjectMembers(project) {
  const values = useMountFetch(
    `/permissions/entries?resourceType=Project&resourceId=${project.id}&accessorType=Entity&actions&byEntities`
  );
  return values;
}

export function useContractDbContent(id) {
  const [content] = useMountFetch(`/versions/${id}/content`);
  return content;
}
export function useContractDbState(id) {
  const [content] = useMountFetch(`/versions/${id}/state`);
  return content;
}

export function useFullContract(id) {
  const [fullContract, setFullContract] = useState(null);
  useEffect(() => {
    (async () => {
      Promise.all([
        api.get(`/versions/${id}`),
        api.get(`/versions/${id}/content`),
        api.get(`/versions/${id}/state`),
      ]).then((res) => {
        setFullContract({
          ...res[0].data,
          content: res[1].data,
          state: res[2].data,
        });
      });
    })();
  }, [id]);
  return fullContract;
}

function useFetchBase(url, dependencies, setFunction, setErrorFunction) {
  const hasSetFunction = typeof setFunction === 'function';
  const hasErrorFunction = typeof setErrorFunction === 'function';
  const [result, setResult] = useState();
  const [error, setError] = useState();
  const isSearchingRef = useRef(false);
  const abortController = useRef(new AbortController());

  useEffect(() => {
    if (!url) {
      if (hasSetFunction) {
        setFunction(null);
      } else {
        setResult(null);
      }
      return;
    }
    if (isSearchingRef.current) {
      abortController.current.abort();
      abortController.current = new AbortController();
    }
    isSearchingRef.current = true;

    api
      .get(url, { signal: abortController.current.signal })
      .then(function (res) {
        if (res.data) {
          if (hasSetFunction) {
            setFunction(res.data);
          } else {
            setResult(res.data);
          }
        } else {
          if (hasErrorFunction) {
            setErrorFunction('No projects available');
          } else {
            setError('No results');
          }
        }
        isSearchingRef.current = false;
      })
      .catch((err) => {
        const errorMsg =
          err.response && err.response.status === 401 ? 'Sign in to fetch resources' : err.response;
        if (hasErrorFunction) {
          setErrorFunction(errorMsg);
        } else {
          setError(errorMsg);
        }
        isSearchingRef.current = false;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return [result, error];
}

export function useMountFetch(url, setFunction, setErrorFunction) {
  return useFetchBase(url, [], setFunction, setErrorFunction);
}

export function useFetch(url, setFunction, setErrorFunction) {
  return useFetchBase(url, [url], setFunction, setErrorFunction);
}

function generateSearchEntityUrl(instructions = {}, options = {}) {
  const { type, isTopCo, name, identificationNumber, search } = instructions;
  const {
    defaultAll = false,
    minNameLength = 3,
    minidentificationNumberLength = 4,
    minSearch = 0,
    forceType,
    includePrincipalsAndAgents,
    fields,
    limit,
  } = options;
  const hasNameSearch = name && name.length >= minNameLength;
  const hasTypeSearch = type && type !== 'all';
  const isForcingTypeSearch = forceType && forceType !== 'none';
  const hasIdentificationNumberSearch =
    identificationNumber && identificationNumber.length >= minidentificationNumberLength;
  const hasSearch = search && search.length >= minSearch;

  const typeMatchingForceType = isForcingTypeSearch && forceType === type;
  const doTypeSearch = !typeMatchingForceType && hasTypeSearch;

  let url = '/entities';

  if (doTypeSearch || isTopCo || hasNameSearch || hasIdentificationNumberSearch || search) url += '/find?';
  else if (!defaultAll) return '';

  const params = [];
  if (hasTypeSearch) params.push(`type=${type}`);
  if (hasNameSearch) params.push(`name=${name}`);
  if (hasIdentificationNumberSearch) params.push(`identificationNumber=${identificationNumber}`);
  if (hasSearch) params.push(`search=${search}`);
  if (isTopCo) params.push(`isTopCo=true`);
  if (fields) params.push(`fields=${fields.join()}`)
  if (Number(limit)) params.push(`limit=${limit}`)

  url += params.join('&');

  if (includePrincipalsAndAgents) {
    url += '&includePrincipalsAndAgents=true';
  }

  return url;
}

export function useEntitySearch(instructions, options) {
  const url = generateSearchEntityUrl(instructions, options);
  return useFetch(url);
}
