import { Select } from 'antd';
import countries from 'core/config/countries.json';

const { Option } = Select;

export function CountrySelect({ id, ...rest }) { // avoid hinting browser of input content
  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      {...rest}
      autoComplete="off"
    >
      {countries.map((country) => (
        <Option key={country.code} value={country.code}>
          {country.name}
        </Option>
      ))}
    </Select>
  );
}
