import { useState, useEffect } from 'react';
import { Modal, notification, Button, Divider, Row, Col } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { useMountedState } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import { Entity } from 'core/interfaces';
import { AgentCapacities, SearchList } from '.';
import EditEntity from '../index';

function AddNewEntity({ onCancel, relationType, onFinishCallback, ...restProps }) {
  const [isCreatingNewEntity, setIsCreatingNewEntity] = useMountedState(false);

  const selectToCreateNewEntity = () => {
    setIsCreatingNewEntity(true);
  };

  const onCreatedNewEntity = async (data) => {
    const { status, errorMessage = '' } = data;
    if (status !== 'success') {
      console.log('Failed to create new entity.', errorMessage);
      notification.error({
        message: 'Failed to create entity',
        description: errorMessage || '',
      });
      return;
    }

    if (typeof onFinishCallback === 'function') {
      onFinishCallback(data);
    }

    onCancel();
  };

  return (
    <>
      <Button type="primary" className="m-0" onClick={selectToCreateNewEntity}>
        <IntlMessages id="app.entity.createNew" cap />
      </Button>
      {isCreatingNewEntity && (
        <EditEntity
          onCancel={() => setIsCreatingNewEntity(false)}
          onFinishCallback={onCreatedNewEntity}
          relationType={relationType}
          {...restProps}
        />
      )}
    </>
  );
}

function SelectForSelection({ onCancel, onFinishCallback, ...restProps }) {
  const selectResult = (entity) => {
    if (typeof onFinishCallback === 'function') {
      onFinishCallback({
        type: 'select',
        status: 'success',
        entity,
      });
    }
  };

  return (
    <>
      <SearchList selectResult={selectResult} {...restProps} />
    </>
  );
}

function SelectForNewRelation({
  onCancel,
  onFinishCallback,
  relationType,
  setAddNewEntityVisisble,
  ...restProps
}) {
  const { referringEntity } = restProps;
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [capacities, setCapacities] = useState([]);

  const stringedDefaultCapacities = restProps.defaultCapacities;

  useEffect(() => {
    if (!restProps.defaultCapacities) return;
    if (selectedEntity) {
      setCapacities(restProps.defaultCapacities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity, stringedDefaultCapacities]);

  const selectResult = (entity) => {
    setSelectedEntity(entity);
  };

  const unSelect = () => {
    setSelectedEntity(null);
  };

  useEffect(() => {
    if (selectedEntity) return setAddNewEntityVisisble(false);
    setAddNewEntityVisisble(true);
  }, [selectedEntity, setAddNewEntityVisisble]);

  const onAddExistingEntityForRelation = async () => {
    onFinishCallback({
      type: 'select',
      status: 'success',
      entity: selectedEntity,
      referringEntity,
      relationType,
      capacities,
    });
  };

  return (
    <>
      {!selectedEntity ? (
        <SearchList selectResult={selectResult} {...restProps} />
      ) : (
        <div>
          <div>
            <Row>
              <Col sm={24} md={6}>
                <h6>
                  <IntlMessages id="general.entity" cap />
                </h6>
                <div className="d-flex mb-2">
                  <UserAddOutlined />
                  <h5 className="ml-2">{Entity.name(selectedEntity)}</h5>
                </div>
              </Col>
              <Col sm={24} md={18}>
                <AgentCapacities value={capacities} onChange={setCapacities} />
              </Col>
            </Row>
          </div>
          <div className="mt-2 d-flex justify-content-flex-end">
            <Button type="secondary" onClick={unSelect}>
              <IntlMessages id="desc.Cancel" cap />
            </Button>
            <Button type="primary" onClick={onAddExistingEntityForRelation}>
              <IntlMessages id={`app.entity.add${relationType}`} />
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export function AddOrSelectComponent(props) {
  const [addNewEntityVisible, setAddNewEntityVisisble] = useState(true);
  const { onCancel, mode } = props;

  const SelectComponent = mode === 'select' ? SelectForSelection : SelectForNewRelation;
  
  return (
    <Modal
      className="modal-medium"
      visible={true}
      title={<IntlMessages id="app.entity.select.label" cap />}
      onCancel={onCancel}
      okButtonProps={{
        className: 'd-none',
      }}
    >
      <div>
        <div>
          {addNewEntityVisible && (
            <>
              <div className="d-flex justify-content-center">
                <AddNewEntity {...props} />
              </div>

              <Divider>
                <IntlMessages id="desc.or" cap />
              </Divider>
            </>
          )}
          <SelectComponent setAddNewEntityVisisble={setAddNewEntityVisisble} {...props} />
        </div>
      </div>
    </Modal>
  );
}
