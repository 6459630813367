import { Divider, Select, Space, Modal, Button, Checkbox } from 'antd';
import { ActionModal, fixDate } from 'components/ui';
import { getLatestVersion, getActiveVersion } from '../../../routes/project/Document';
import IntlMessages from 'util/IntlMessages';
import { HeaderSkeleton, InputSkeleton } from '../../Skeletons/Overview';
import { OverviewHeader } from '../../Headers/EntityHeader/OverviewHeader';
import api from 'utils/api';
import { useRef, useState } from 'react';

function hasLaterVersion(versions, currentVersion) {
  const { createdAt } = currentVersion;
  return !!versions.find((v) => v.createdAt > createdAt);
}

/* function validateActiveAndFinalVersionData(doc) {
  if (!doc.activeVersionId) {
    return console.warn('No activeVersionId');
    // throw new Error('No activeVersoinId)
  }
  if (doc.finalVersionId && doc.finalVersionId !== doc.activeVersionId) {
    throw new Error('Incompatible');
  }
} */

const getDocumentWarnings = ({ doc, versions, currentVersion, selectVersion, makeActive }) => {
  if (!versions || !currentVersion) return null;

  const makeCurrentActive = () => {
    makeActive(currentVersion.id)
  }

  const gotoLatest = () => {
    const latestVersion = getLatestVersion(versions);
    selectVersion(latestVersion.id);
  };

  if (!doc || !versions || !currentVersion) return null;
  if (doc.activeVersionId === currentVersion.id) {
    return null;
  }
  if (doc.activeVersionId !== currentVersion.id) {
    const activeVersion = getActiveVersion(versions, doc.activeVersionId);
    return {
      alert: (
        <div>
          <IntlMessages id="app.project.document.versionWarning" />
          <Space className="ml-3">
            <Button className="link" style={{fontWeight: 400}} onClick={makeCurrentActive}>
              <IntlMessages id="desc.Activate" />
            </Button>
          </Space>
        </div>
      ),
      alertType: 'danger',
    };
  } else if (hasLaterVersion(versions, currentVersion)) {
    return {
      alert: (
        <div>
          The currently selected version is not the latest version.{' '}
          <span className="link" onClick={gotoLatest}>
            Select latest version
          </span>
        </div>
      ),
      alertType: 'warning',
    };
  }
  return {};
};

const DocumentHeader = ({ doc, versions, currentVersion, selectVersion, setDocument, addVersion }) => {
  const checkboxRef = useRef()
  if (!doc) return <HeaderSkeleton />;

  /*   console.log('Keep track', {
    finalVersionId: doc.finalVersionId,
    activeVersionId: doc.activeVersionId,
    currentVersionId: currentVersion.id,
    num: currentVersion?.info?.versionNumber,
  }); */

  const makeActive = async (id) => {
    try {
      await api.post('/documents/' + doc.id + '/activateVersion', {
        activeVersionId: id,
      });
      setDocument({ activeVersionId: id });
    } catch (err) {
      console.log('Error making active ', err);
    }
  };

  const duplicate = async () => {
    const result = await api.post('/versions/' + currentVersion.id + '/duplicate');
    addVersion(result.data);
    return result.data.id;
  };

  const onCreateVersion = async () => {
    const id = await duplicate(currentVersion.id);
    if (checkboxRef.current.input.checked)
      makeActive(id);
  };

  const adjustedVersionStyle = {
    padding: currentVersion?.id !== doc.activeVersionId ? 4 : undefined,
  }

  return (
    <OverviewHeader
      title={doc.name}
      status={doc.status}
      description={doc.description}
      {...(getDocumentWarnings({ doc, versions, currentVersion, selectVersion, makeActive }) || {})}
      extra={
        <div className="version-selector" style={adjustedVersionStyle}>
          {!versions ? (
            <InputSkeleton size="small" />
          ) : (
            <Select placeholder="Select version" value={currentVersion?.id} onChange={selectVersion}>
              {versions.map((v) => (
                <Select.Option key={v.id} value={v.id}>
                  <div>
                    <div>
                      <IntlMessages id="general.version" cap className="mr-1" />
                      {v?.info?.versionNumber}
                    </div>
                    <small style={{ fontWeight: '400' }}>{fixDate(v.createdAt)}</small>
                  </div>
                </Select.Option>
              ))}
              <Select.Option disabled style={{cursor: 'pointer'}} className="version-selector-new" value={'_new'}>
                <ActionModal
                  onConfirm={onCreateVersion}
                  confirmTitle={<IntlMessages id="app.project.document.versionModalTitle" />}
                  confirmText={
                    <>
                      <p>
                        <IntlMessages id="app.project.document.versionModalInfoStart" />
                        {currentVersion?.info.versionNumber}
                        <IntlMessages id="app.project.document.versionModalInfoEnd" />
                      </p>
                      <p><IntlMessages id="desc.Activate" /> <Checkbox ref={checkboxRef} className='ml-2' /></p>
                    </>
                  }
                  okText={<IntlMessages id="app.general.create" cap />}
                  okButtonProps={{ className: 'ant-btn-primary' }}
                >
                  <div className="version-selector-new link">
                    <i className="mdi mdi-plus mr-1" />
                    <IntlMessages id="general.newVersion" cap />
                  </div>
                </ActionModal>
              </Select.Option>
            </Select>
          )}
        </div>
      }
    />
  );
};

export default DocumentHeader;
