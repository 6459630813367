import { getDefinitionName } from '../../../utils';

function getDefinitions(definitionsNode, definitionsPath, api, editor) {
  const defs = [];

  for (let i = 0; i < definitionsNode.children.length; i++) {
    const child = definitionsNode.children[i];
    const definitionName = getDefinitionName(child, { lowercase: true });
    if (definitionName) {
      const childPath = [...definitionsPath, i];
      const childPathRef = api.slate.Editor.pathRef(editor, childPath);
      defs.push([definitionName, [child, childPathRef], i]);
    }
  }

  return defs;
}

const findDirection = (obj, i, arr) => {
  const forward = obj[0] > arr[i - 2]?.[0] && obj[0] > arr[i - 3]?.[0];
  return forward ? 1 : -1;
};

const findIndex = (obj, i, arr, direction) => {
  const compareIndex = i + direction;
  const atEdge = compareIndex === 0 || compareIndex === arr.length - 1;
  const hasSmaller = arr[compareIndex]?.[0] < obj[0];
  if (atEdge) return compareIndex;
  if (hasSmaller) return direction === -1 ? i : compareIndex;
  return findIndex(obj, compareIndex, arr, direction);
};

const move = (obj, i, arr, direction, editor, slateApi) => {
  const newIndex = findIndex(obj, i, arr, direction);
  const displacedPath = obj[1][1].current;
  let targetPath;
  if (direction === -1) {
    targetPath = arr[newIndex][1][1].current;
    // console.log('move', obj[0], displacedPath, 'before', arr[newIndex][0], targetPath);
  } else if (obj && arr[newIndex]) {
    targetPath = slateApi.Path.next(arr[newIndex][1][1].current);
    // console.log('move', obj[0], displacedPath, 'after', arr[newIndex][0], targetPath);
  }
  if (targetPath) {
    slateApi.Transforms.moveNodes(editor, { at: displacedPath, to: targetPath });
  }
};

function validDefinitionsHolder(definitionHolder) {
  if (!definitionHolder || typeof definitionHolder !== 'object') return false;
  if (!definitionHolder.nodePathRef) return false;
  if (!definitionHolder.nodePathRef.current) return false;
  return true;
}

export const smartDefinitionOnRepeatableChangeSlate = {
  id: 'smartDefinitionOnRepeatableChangeSlate',
  dependencies: { repeatableChange: true },
  time: 15,
  match: ({ entries, node }) => {
    // SLATE_ENGINE_FIX
    return false;

    if (!node || !node.data || !node.data.isDefKey) return false;
    // console.log('Def-key node ', node);
    const { _path, key } = node.data;
    const nodePathKey = _path + '.' + key;
    for (const { path } of entries) {
      if (nodePathKey === path) return true;
    }
  },
  handler: function ({ matches, api, editor }) {
    if (matches.default && matches.default.size > 0) {
      const definitionHoldersToSort = new Map();
      for (const { parents } of matches.default) {
        const definitionHolder = parents.find(({ node }) => node.type === 'definitions');
        if (!definitionHolder || definitionHoldersToSort.has(definitionHolder)) continue;
        if (!validDefinitionsHolder(definitionHolder)) {
          console.log('Invalid definitionsholder', definitionHolder);
          continue;
        }
        definitionHoldersToSort.set(
          definitionHolder,
          getDefinitions(definitionHolder.node, definitionHolder.nodePathRef.current, api, editor)
        );
      }
      for (const [definitionsHolder, siblings] of definitionHoldersToSort) {
        // console.log('Ok check siblings ', siblings);
        siblings.forEach((obj, i, arr) => {
          const isSmaller = obj[0] < arr[i - 1]?.[0];
          const direction = findDirection(obj, i, arr);
          const displacedObj = direction === 1 ? arr[i - 1] : obj;
          if (isSmaller) move(displacedObj, i, arr, direction, editor, api.slate);
        });
      }
    }
  },
};
