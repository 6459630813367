import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import {
  Row,
  Col,
  message,
  Button,
  Alert,
  Checkbox,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  notification,
  Collapse,
} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import api from 'utils/api';
import { ActionButton } from 'components/ui';
import { Entity } from 'core/interfaces';
import EditEntity, { AddOrSelectEntity } from 'components/entity/EditEntity';
import { hasFeatureFlag } from 'utils/general';

const isCustomerDemo1 = hasFeatureFlag('customer_demo_1');

const { confirm } = Modal;
const { Panel } = Collapse;

export default function InviteEntities({
  versionId,
  documentId,
  projectId,
  onInvitation,
  btnType = 'primary',
  btnBlock = false,
  ...props
}) {
  const formatMessage = useIntlMessage();
  const [open, setOpen] = useState(false);
  const clearInviteData = useRef();

  const toggleInviteModal = () => {
    setOpen(!open);
  };

  const invite = (values) => {
    const hideLoad = message.loading(formatMessage('app.project.version.externalize.initSharing'), 7);

    api
      .post(`/versions/${versionId}/invite`, values)
      .then((res) => {
        const { status } = res.data;
        if (status !== 'success') throw new Error('Unsuccessful invite');

        hideLoad();
        confirm({
          title: formatMessage('app.project.version.externalize.successfullySharedHeader'),
          icon: <CheckCircleOutlined />,
          content: (
            <>
              <p>{formatMessage('app.project.version.externalize.successfullyShared')}</p>
              <p>{formatMessage('app.project.version.externalize.emailSent')}</p>
            </>
          ),
          onOk() {
            if (typeof clearInviteData.current === 'function') clearInviteData.current();
            if (typeof onInvitation === 'function') onInvitation(res.data);
            setOpen(false);
          },
          cancelButtonProps: { className: 'd-none' },
          onCancel: null,
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: 'Something when wrong in sharing externally. Please try again later.',
        });
        console.log('Err is ', { err, response: err.response });
      });
  };

  const title = <IntlMessages id={'app.project.version.externalize'} />;

  return (
    <>
      {open && (
        <InviteModal
          title={title}
          open={open}
          invite={invite}
          close={toggleInviteModal}
          clearInviteData={clearInviteData}
          projectId={projectId}
          documentId={documentId}
          versionId={versionId}
        />
      )}
      <ActionButton
        type={btnType}
        block={btnBlock}
        title={title}
        icon="mdi-share"
        onClick={toggleInviteModal}
        {...props}
      />
    </>
  );
}

const expirationUnits = ['years', 'quarters', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];

function useTimeOptions(intlPathStart, options = {}) {
  const { data, formatting, intlValueProperty = 'label' } = options;
  const formatMessage = useIntlMessage();
  return (data || expirationUnits).map((unit) => ({
    value: unit,
    [intlValueProperty]: formatMessage(intlPathStart + unit, formatting),
  }));
}

function ListRecipient({
  recipient,
  index,
  removeRecipient,
  updateRecipientEmails,
  updateEntity,
  recipientsLength,
}) {
  const [editingEntity, setIsEditingEntity] = useState(false);
  const onFinishCallback = ({ status, type, values }) => {
    if (status === 'success' && type === 'update' && values) {
      updateEntity(recipient.id, values);
    }
  };

  const onChangeEmails = (emails) => {
    updateRecipientEmails(recipient.id, emails);
  };

  const emails = useMemo(() => Entity.emails(recipient), [recipient]);

  useEffect(() => {
    if (emails.length > 0) updateRecipientEmails(recipient.id, [emails[0]]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emails]);

  return (
    <>
      {editingEntity && (
        <EditEntity
          id={recipient.id}
          entity={recipient}
          onCancel={() => setIsEditingEntity(false)}
          onFinishCallback={onFinishCallback}
        />
      )}
      <Row
        className={'flex-row m-0 mb-2 pb-2 pl-2 ' + (index + 1 !== recipientsLength ? 'border-bottom' : '')}
      >
        <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
          {recipient.firstName || (
            <em>
              <IntlMessages id={'app.project.version.externalize.noName'} />
            </em>
          )}
        </Col>
        <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
          {recipient.lastName || (
            <em>
              <IntlMessages id={'app.project.version.externalize.noName'} />
            </em>
          )}
        </Col>
        <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
          <Checkbox.Group
            options={emails.map((email) => ({ label: email, value: email }))}
            defaultValue={[emails[0]]}
            onChange={onChangeEmails}
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
          <div className="flex-column">
            <Button type="secondary" className="m-0" onClick={() => setIsEditingEntity(true)}>
              <IntlMessages id="app.persons.editPerson" />
            </Button>
            <Button type="secondary" className="m-0" onClick={() => removeRecipient(recipient.id)}>
              <IntlMessages id={'app.project.version.externalize.removeRecipient'} />
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}

function ListRecipients({ recipients, removeRecipient, updateEntity, updateRecipientEmails }) {
  const recipientsLength = recipients ? recipients.length : 0;
  if (!recipientsLength) {
    return (
      <div className="mb-3">
        <Alert
          message={<IntlMessages id={'app.project.version.externalize.noRecipientsAdded'} />}
          type="error"
        />
      </div>
    );
  }
  return (
    <>
      <div className="list-recipients border">
        <Row className={'flex-row m-0 mt-3 mb-2 pb-2 pl-2 border-bottom'}>
          <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
            <strong>
              <IntlMessages id={'app.general.FirstName'} />
            </strong>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
            <strong>
              <IntlMessages id={'app.general.LastName'} />
            </strong>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
            <strong>
              <IntlMessages id={'app.general.Email'} />
            </strong>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={6} className="d-flex align-items-center audi-col">
            <strong>
              <IntlMessages id={'app.general.Actions'} />
            </strong>
          </Col>
        </Row>

        {recipients.map((recipient, index) => (
          <ListRecipient
            key={recipient.id}
            recipient={recipient}
            index={index}
            removeRecipient={removeRecipient}
            updateEntity={updateEntity}
            updateRecipientEmails={updateRecipientEmails}
            recipientsLength={recipientsLength}
          />
        ))}
      </div>
    </>
  );
}

function InviteModal({ title, close, open, invite, clearInviteData, projectId, documentId, versionId }) {
  const formatMessage = useIntlMessage();
  const unitOptions = useTimeOptions('app.general.times.', {
    formatting: 'cap',
    data: ['weeks', 'days', 'hours'],
  });

  const [entities, setEntities] = useState([]);
  const [relevantAgents, setRelevantAgents] = useState([]);
  const [isSelectingRecipients, setIsSelectingRecipients] = useState(false);
  const [recipients, setRecipients] = useState([]);
  const [recipientEmails, setRecipientEmails] = useState({});
  const [hasCredentials, setHasCredentials] = useState(false);
  const [credentialType, setCredentialType] = useState('password');
  const [mainFormRef, setMainFormRef] = useState(null);

  useEffect(() => {
    async function fetch() {
      if (projectId && documentId) {
        try {
          const result = await api.get(`/projects/${projectId}/entities?documentId=${documentId}`);
          if (result && result.data) {
            const agents = Entity.getAgents(result.data);
            setEntities(result.data);
            setRelevantAgents(agents);
          }
        } catch (err) {
          console.log('Error fetching entities.');
        }
      }
    }
    fetch();
  }, [documentId, projectId]);

  const toggleIsSelectingRecipients = (evt) => {
    if (evt) evt.stopPropagation();
    setIsSelectingRecipients(!isSelectingRecipients);
  };

  const updateEntity = (id, values) => {
    if (!id || !values) return console.log('Invalid updateEntit values');
    setRecipients(
      recipients.map((r) => {
        if (r.id !== id) return r;
        return {
          ...r,
          ...values,
        };
      })
    );
  };
  const updateRecipientEmails = (id, emails) => {
    setRecipientEmails({
      ...recipientEmails,
      [id]: emails,
    });
  };
  const removeRecipient = (id) => {
    setRecipients(recipients.filter((r) => r.id !== id));
    const updEmails = { ...recipientEmails };
    delete updEmails[id];
    setRecipientEmails(updEmails);
  };
  const clearRecipients = () => setRecipients([]);

  const onFinish = (values) => {
    if (recipients.length === 0) {
      return notification.error({
        message: formatMessage('app.project.version.externalize.addRecipients'),
        description: formatMessage('app.project.version.externalize.addRecipientDesc'),
      });
    }
    // console.log('Received values of form: ', values);

    invite({ ...values, recipients, recipientEmails, send_emails: true });
  };
  const onFinishFailed = ({ values, errorFields }) => {
    // console.log('Errors ', { values, errorFields });
    return notification.error({
      message: formatMessage('app.project.version.externalize.requiredFields'),
      description: formatMessage('app.project.version.externalize.requiredFieldsDesc'),
    });
  };

  const handleFormValuesChange = (changedValues) => {
    if (changedValues.hasOwnProperty('credentials')) setHasCredentials(changedValues.credentials);
    if (changedValues.hasOwnProperty('credentialType')) setCredentialType(changedValues.credentialType);
  };

  const recipientsLength = recipients.length;

  const saveMainFormRef = useCallback(
    (node) => {
      if (node !== null) {
        setMainFormRef(node);
        clearInviteData.current = () => {
          clearRecipients();
          setHasCredentials(false);
          setCredentialType('password');
          node.resetFields();
        };
      }
    },
    [clearInviteData]
  );

  const onFinishCallback = ({ status, type, entity }) => {
    if (status === 'success') {
      notification.success({
        message: 'Added ' + Entity.name(entity),
      });
      setRecipients([...recipients, entity]);
    }
    // setIsSelectingRecipients(false);
  };

  return (
    <Modal
      title={title}
      visible={open}
      footer={
        <>
          <Button onClick={close}>
            <IntlMessages id={'desc.Cancel'} />
          </Button>
          <Button onClick={mainFormRef && mainFormRef.submit} type="primary">
            <IntlMessages id={'app.project.version.externalize.share'} />
          </Button>
        </>
      }
      onCancel={close}
      width={'70vw'}
      maskClosable={false}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        ref={saveMainFormRef}
        initialValues={{
          expirationUnit: 'days',
          expirationNumber: 3,
          allowFeedbackOnly: true,
          allowInlineComments: true,
          allowGeneralComments: false,
          allowExportPDF: false,
          allowExportWord: false,
          credentialType: 'password',
          currentRecipientEmail: '',
          currentRecipientFirstName: '',
          currentRecipientLastName: '',
        }}
        onValuesChange={handleFormValuesChange}
      >
        <Collapse defaultActiveKey={['recipients']} ghost className="no-end-border-radius">
          <Panel
            header={
              <>
                <IntlMessages id={'app.project.version.externalize.recipients'} /> ({recipientsLength})
              </>
            }
            key="recipients"
            extra={
              <Button type="primary" onClick={toggleIsSelectingRecipients}>
                <IntlMessages id={'app.project.version.externalize.addRecipient'} />
              </Button>
            }
          >
            <div className={'py-1 px-3'}>
              <Form.Item className="">
                <div className="mb-4">
                  {isSelectingRecipients && (
                    <AddOrSelectEntity
                      onFinishCallback={onFinishCallback}
                      onCancel={toggleIsSelectingRecipients}
                      // availableParents={entities}
                      // updateApi={false}
                      redux={false}
                      standardResults={relevantAgents}
                      standardResultsPrincipalDataForAgents={entities}
                      autoSelectName={true}
                      defaultNewValues={{
                        jurisdiction: 'se',
                      }}
                      searchForceType="RealPerson"
                    />
                  )}
                  <div className="d-flex">
                    {!recipients || recipients.length === 0 ? (
                      <div className="ml-2">
                        <Alert
                          message={<IntlMessages id={'app.project.version.externalize.noRecipientsAdded'} />}
                          type="error"
                        />
                      </div>
                    ) : null}
                  </div>
                  {recipients && recipients.length > 0 && (
                    <ListRecipients
                      recipients={recipients}
                      removeRecipient={removeRecipient}
                      updateEntity={updateEntity}
                      updateRecipientEmails={updateRecipientEmails}
                    />
                  )}
                </div>
              </Form.Item>
            </div>
          </Panel>
          <Panel
            header={<IntlMessages id={'app.project.version.externalize.sharingValidity'} />}
            key="validity"
          >
            <div className="py-1 px-3">
              <Form.Item className="">
                <Input.Group compact className="">
                  <Form.Item>
                    <Input.Group compact>
                      <Form.Item
                        label={<IntlMessages id={'app.project.version.externalize.expiration'} />}
                        name="expirationNumber"
                        rules={[{ type: 'number', min: 1 }]}
                      >
                        <InputNumber />
                      </Form.Item>
                      <Form.Item label=" " name="expirationUnit">
                        <Select options={unitOptions} />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
              {isCustomerDemo1 && (
                <Form.Item
                  className=""
                  label={<IntlMessages id={'app.project.version.externalize.allowSigning'} />}
                  name="allowSigning"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              )}
            </div>
          </Panel>
          <Panel
            header={<IntlMessages id={'app.project.version.externalize.credentials'} />}
            key="credentials"
          >
            <div className={'py-1 px-3'}>
              <Input.Group compact>
                <Form.Item
                  label={<IntlMessages id={'app.project.version.externalize.requireCredentials'} />}
                  name="credentials"
                  className="p-2"
                  valuePropName={'checked'}
                >
                  <Switch />
                </Form.Item>
                {hasCredentials && (
                  <>
                    <Form.Item
                      name="credentialType"
                      label={<IntlMessages id={'app.project.version.externalize.credentialsSpecification'} />}
                      className="ml-4"
                      rules={[
                        {
                          required: true,
                          message: (
                            <IntlMessages id={'app.project.version.externalize.selectCredentialType'} />
                          ),
                        },
                      ]}
                    >
                      <Select>
                        <Select.Option value="password">
                          <IntlMessages id={'app.userAuth.password'} cap />
                        </Select.Option>
                        <Select.Option value="email">
                          <IntlMessages id={'app.userAuth.confirmEmail'} />
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    {credentialType === 'password' && (
                      <Form.Item
                        label={<IntlMessages id={'app.userAuth.password'} cap />}
                        name="credentialPassword"
                        className="ml-4"
                        extra={<IntlMessages id={'app.project.version.externalize.passwordExtra'} />}
                        rules={[
                          {
                            required: true,
                            message: <IntlMessages id={'app.userAuth.pleaseEnterPassword'} />,
                          },
                          {
                            type: 'string',
                            min: 6,
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          allowClear
                          placeholder={formatMessage('app.userAuth.enterPassword')}
                        />
                      </Form.Item>
                    )}
                    {credentialType === 'email' && (
                      <Form.Item label={<span></span>} className="ml-4">
                        <Alert
                          message={
                            <IntlMessages id={'app.project.version.externalize.credentialEmailDesc'} />
                          }
                        />
                      </Form.Item>
                    )}
                  </>
                )}
              </Input.Group>
            </div>
          </Panel>
          {/* <Panel header={<IntlMessages id={'app.project.version.externalize.shareSettings'} />} key="options">
            <div className={'py-1 px-3'}>
              <Input.Group compact className="p-2">
                <Form.Item
                  label={
                    <small>
                      <IntlMessages id={'app.project.version.externalize.allowFeedback'} />
                    </small>
                  }
                  name="allowFeedbackOnly"
                  className="form-inline-switch"
                  extra={
                    <div style={{ maxWidth: '250px' }}>
                      <small>
                        <IntlMessages id={'app.project.version.externalize.allowFeedbackDesc'} />
                      </small>
                    </div>
                  }
                  valuePropName="checked"
                >
                  <Switch size="small" />
                </Form.Item>
                <Form.Item
                  label={
                    <small>
                      <IntlMessages id={'app.project.version.externalize.allowInlineComments'} />
                    </small>
                  }
                  name="allowInlineComments"
                  className="ml-4 form-inline-switch"
                  extra={
                    <div style={{ maxWidth: '250px' }}>
                      <small>
                        <IntlMessages id={'app.project.version.externalize.allowInlineCommentsDesc'} />
                      </small>
                    </div>
                  }
                  valuePropName="checked"
                >
                  <Switch size="small" />
                </Form.Item>
                <Form.Item
                  label={
                    <small>
                      <IntlMessages id={'app.project.version.externalize.allowGeneralComments'} />
                    </small>
                  }
                  name="allowGeneralComments"
                  className="ml-4 form-inline-switch"
                  extra={
                    <div style={{ maxWidth: '250px' }}>
                      <small>
                        <IntlMessages id={'app.project.version.externalize.allowGeneralCommentsDesc'} />
                      </small>
                    </div>
                  }
                  valuePropName="checked"
                >
                  <Switch size="small" />
                </Form.Item>
              </Input.Group>
            </div>
          </Panel> */}
        </Collapse>
      </Form>
    </Modal>
  );
}
