export const initActiveConditionsSlate = {
  id: 'initActiveConditionsSlate',
  genesisHandler: true, // Mutation of node matches ONLY ok in genesis mode.
  match: ({ node }) => {
    return !!node.data.acp;
  },
  handler: function ({ state, api, matches }) {
    for (const { node, parents, actions } of matches.default) {
      actions.setNodeData('_inActive', false);
      const localState = api.utils.engine.getLocalState(node, parents, state, this.contract);

      const { changed, inActive } = this.checkNodeACPrule(node, localState);
      if (changed) {
        actions.setNodeData('_inActive', inActive);
      }
    }
  },
};
