import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setNumberPendingTasks } from 'appRedux/actions';
import {
  Row,
  Col,
  Button,
  Card,
  Modal,
  Spin,
  notification,
  Checkbox,
  Tooltip,
  Alert,
  Switch,
  Form,
} from 'antd';
import { useSelector } from 'react-redux';
import { Contract, Entity, State } from 'core/interfaces';
import { useDownloadPdfBackend, useContract, useContractState } from 'hooks';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import api from 'utils/api';
import { hasFeatureFlag } from 'utils/general';
import { initSign } from 'utils/services/scrive';
import { ActionButton } from 'components/ui';
import { PlusCircleOutlined, WarningOutlined } from '@ant-design/icons';
import EditEntity, { AddAgentModal } from 'components/entity/EditEntity';
import { gutter } from 'config';

export default function SignDocument({
  document,
  setDocument,
  currentVersion,
  onSigningInitiated,
  ...props
}) {
  const [open, setOpen] = useState(false);
  const [modalLoaded, setModalLoaded] = useState(false);

  const contract = useContract();
  const state = useSelector(State.produceContractState);

  const toggleSigningModal = useCallback(() => {
    setOpen(!open);
    if (open) {
      setModalLoaded(false);
    }
  }, [open]);

  if (!hasFeatureFlag('signing')) return null;
  if (!contract || !state) return null;

  const title = <IntlMessages id="app.signing.startSigning" />;

  const wrongVersion = document.finalVersionId && document.finalVersionId !== currentVersion.id;
  const disabled = !['draft', 'published'].includes(document.status) || wrongVersion;

  return (
    <>
      {open && (
        <SigningModal
          title={title}
          open={open}
          close={toggleSigningModal}
          document={document}
          currentVersion={currentVersion}
          setDocument={setDocument}
          setModalLoaded={setModalLoaded}
          contract={contract}
          state={state}
          onSigningInitiated={onSigningInitiated}
        />
      )}
      <ActionButton
        type="primary"
        title={<IntlMessages id="app.signing.startSigning" />}
        icon="mdi-signature"
        onClick={toggleSigningModal}
        disabled={disabled}
        {...props}
      />
    </>
  );
}

export function ExposeSigningModal({
  title,
  close,
  open,
  document,
  currentVersion,
  setDocument,
  setModalLoaded,
  onSigningInitiated,
}) {
  const contract = useContract();
  const state = useContractState();

  if (!contract || !state) return null;

  if (document.finalVersionId && document.finalVersionId !== currentVersion.id) {
    console.error('Incompatible document data for signing');
    return null;
  }

  return (
    <SigningModal
      title={title}
      open={open}
      close={close}
      document={document}
      currentVersion={currentVersion}
      setDocument={setDocument}
      setModalLoaded={setModalLoaded}
      contract={contract}
      state={state}
      onSigningInitiated={onSigningInitiated}
    />
  );
}

function SigningModal({
  title,
  close,
  open,
  document,
  currentVersion,
  setDocument,
  setModalLoaded,
  contract,
  state,
  onSigningInitiated,
}) {
  const { projectId, id: documentId, finalVersionId } = document;
  const dispatch = useDispatch();
  const formatMessage = useIntlMessage();
  const { initDownloadPDF } = useDownloadPdfBackend({ documentId });
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState('');
  // const [contract, setContract] = useState(null);
  const [manual, setManual] = useState(hasFeatureFlag('deployment'));
  const [preSigned, setPreSigned] = useState(false);
  const [signWait, setSignWait] = useState(false);

  const [signatoriesErrors, setSignatoriesErrors] = useState(null);
  const [parties, setParties] = useState(null);

  const [addingAgentForContractPartyEntity, setAddingAgentForEntity] = useState(null);
  const [editingAgentForContractParty, setEditingAgentForContractParty] = useState(null);

  const [editingAgentEntity, setEditingAgentEntity] = useState(null);

  const [signatories, setSignatories] = useState(null);

  const defaultCapacities = useMemo(() => ['signatory'], []);

  useEffect(() => {
    async function init() {
      if (!contract || !state) {
        notification.error({ message: 'Contract not yet loaded.' });
      }
      try {
        const contractParties = Contract.getContractParties(contract, state) || [];
        if (contractParties.length === 0) {
          close();
          notification.error({
            message: 'No contract parties',
            description: 'Update contract and add relevant parties.',
          });
          return;
        }

        const contractPartiesEntityIds = contractParties.map((cp) => cp.id);
        const includeAgents = [{ model: 'Entity', as: 'Agents' }];
        const includeAgentsQuery = encodeURI(JSON.stringify(includeAgents));
        const idQuery = encodeURI(JSON.stringify(contractPartiesEntityIds));

        const entitiesResponse = await api.get(`/entities?ids=${idQuery}&include=${includeAgentsQuery}`);
        if (entitiesResponse.status !== 200 || !Array.isArray(entitiesResponse.data)) {
          return console.log('No entities response.');
        }

        const withOnlyRealPersonAgents = entitiesResponse.data.map((e) => {
          if (!e.Agents) e.Agents = [];
          else e.Agents = e.Agents.filter(Entity.isRealPerson);
          return e;
        });
        setParties(withOnlyRealPersonAgents);

        const initialSignatories = withOnlyRealPersonAgents.reduce((acc, entity) => {
          if (Entity.isRealPerson(entity)) {
            acc[entity.id] = {
              [entity.id]: true,
            };
            return acc;
          }
          acc[entity.id] = {};
          const multipleAgents = entity.Agents.length > 1;
          const defaultValue = multipleAgents ? false : true;
          entity.Agents.forEach((rep) => (acc[entity.id][rep.id] = defaultValue));
          return acc;
        }, {});
        // console.log('Entities response ', { withOnlyRealPersonAgents, initialSignatories });
        setSignatories(initialSignatories);
        setLoaded(true);
      } catch (err) {
        console.log('Error fetching compare data', err);
        setError(err);
      }
      if (typeof setModalLoaded === 'function') {
        setModalLoaded(true);
      }
    }
    init();
  }, [setModalLoaded]);

  useEffect(() => {
    if (!manual) setPreSigned(false);
  }, [manual]);

  const toggleSignatory = (principalEntityId, agentEntityId) => {
    setSignatories({
      ...signatories,
      [principalEntityId]: {
        ...signatories[principalEntityId],
        [agentEntityId]: !signatories[principalEntityId][agentEntityId],
      },
    });
  };

  const addRepresentativeForEntity = (party) => {
    setAddingAgentForEntity(party);
  };

  const editRepresentativeForParty = (party, representative) => {
    setEditingAgentForContractParty(party);
    setEditingAgentEntity(representative);
  };

  const clearAddingOrEditingAgent = () => {
    if (addingAgentForContractPartyEntity) setAddingAgentForEntity(null);
    if (editingAgentForContractParty) {
      setEditingAgentEntity(null);
      setEditingAgentForContractParty(null);
    }
  };

  const onAgentUpdate = (data) => {
    const { type, status, referringEntity } = data;
    if (type === 'update' && status === 'success' && referringEntity) {
      setParties(
        parties.map((p) => {
          if (p.id === referringEntity.id) return referringEntity;
          return p;
        })
      );
    } else {
      notification.error({
        message: 'Error updating',
      });
    }
  };

  const onAddedAgentToEntity = (data) => {
    const { status, entity, referringEntity } = data;
    if (status !== 'success') {
      return notification.error({
        message: 'Error adding signatory',
      });
    }

    const currentPrincipalEntity = parties.find((partyEntity) => partyEntity.id === referringEntity.id);
    if (!currentPrincipalEntity) {
      console.log('Is principal a contract party?!');
      return;
    }
    if (currentPrincipalEntity.Agents.find((a) => a.id === entity.id)) {
      console.log('Already exists.');
      return;
    }

    setParties(
      parties.map((partyEntity) => {
        if (partyEntity.id === referringEntity.id) return referringEntity;
        return partyEntity;
      })
    );
    setSignatories({
      ...signatories,
      [referringEntity.id]: {
        ...signatories[referringEntity.id],
        [entity.id]: true,
      },
    });
  };

  function validateSignatory(principalEntity, signatoryEntity) {
    let errors = [];
    const missingFields = agentMissingFields(principalEntity, signatoryEntity, manual);
    if (missingFields.length) {
      errors.push({
        entity: signatoryEntity,
        missingFields,
      });
      notification.error({
        message: 'Invalid Contract Party',
        description: (
          <div>
            <div>{Entity.name(signatoryEntity)}:</div>
            <div>
              <MissingFieldsErrorList missingFields={missingFields} />
            </div>
          </div>
        ),
      });

      return {
        status: 'error',
        errors,
      };
    }
    return {
      status: 'success',
    };
  }
  function validateContractPartySignatories(entity, signatoryEntities) {
    let missingFields = [];
    if (!Array.isArray(signatoryEntities) || signatoryEntities.length === 0) {
      console.log('No signgs');
      missingFields.push(`No signatories are added`);
    }

    if (missingFields.length > 0) {
      notification.error({
        message: 'Invalid Contract Party',
        description: (
          <div>
            <div>{Entity.name(entity)}:</div>
            <div>
              <MissingFieldsErrorList missingFields={missingFields} />
            </div>
          </div>
        ),
      });

      return {
        status: 'error',
        errors: {
          entity,
          missingFields,
        },
      };
    }

    return {
      status: 'success',
    };
  }

  const startSigning = () => {
    let errors = [];
    function createSignatoryEntry(partyEntity, representative) {
      const isLegalPerson = Entity.isLegalPerson(partyEntity);

      return {
        companyIdentificationNumber: isLegalPerson ? partyEntity.identificationNumber : null,
        companyName: isLegalPerson ? Entity.name(partyEntity) : null,
        contractPartyId: partyEntity.id,
        firstName: representative.firstName,
        lastName: representative.lastName,
        email: Entity.firstEmail(representative),
        identificationNumber: representative.identificationNumber,
        agentEntityId: representative.id,
      };
    }

    const partiesWithSignatories = parties
      .map((p) => {
        const isRealPerson = Entity.isRealPerson(p);
        if (isRealPerson) {
          return {
            ...p,
            signatories: [p],
          };
        }

        const validSignatories = p.Agents.map((agentEntity) => {
          if (!signatories[p.id][agentEntity.id]) return null;
          const { status, errors: validationErrors } = validateSignatory(p, agentEntity);
          if (status === 'success') return agentEntity;
          else if (status === 'error') {
            errors = errors.concat(validationErrors);
          }
          return null;
        }).filter((a) => !!a);

        const { status, errors: validationErrors } = validateContractPartySignatories(p, validSignatories);
        if (status === 'error') {
          errors = errors.concat(validationErrors);
        }

        if (status !== 'success') return null;

        return {
          ...p,
          signatories: validSignatories,
        };
      })
      .filter((e) => !!e);

    const signingData = { parties: [] };
    partiesWithSignatories.forEach((partyEntity) => {
      const isRealPerson = Entity.isRealPerson(partyEntity);
      if (isRealPerson) {
        return signingData.parties.push(createSignatoryEntry(partyEntity, partyEntity));
      }
      partyEntity.signatories.forEach((signatoryEntity) => {
        return signingData.parties.push(createSignatoryEntry(partyEntity, signatoryEntity));
      });
    });

    if (errors.length > 0) {
      setSignatoriesErrors(errors);
      return console.log('No signing. Error.');
    }

    setSignatoriesErrors(null);
    // console.log('Signing data ', signingData.parties);

    proceedSigning(signingData);
  };

  const proceedSigning = async (signingData) => {
    if (!finalVersionId) await publishVersion(documentId, currentVersion.id);

    if (manual) await proceedManualSigning(signingData);
    else await proceedDigitalSigning(signingData);
    if (typeof onSigningInitiated === 'function') onSigningInitiated();
    close();
  };

  const proceedManualSigning = async (values) => {
    if (!preSigned) initDownloadPDF();
    const documentSigning = {
      projectId,
      documentId,
      versionId: currentVersion.id,
      status: preSigned ? 'closed' : 'pending',
      data: {
        parties: values.parties.map((party) => ({
          is_signatory: true,
          fields: [
            {
              type: 'name',
              value: `${party.firstName} ${party.lastName}`,
            },
            {
              type: 'personal_number',
              value: party.identificationNumber,
            },
            {
              type: 'company',
              value: party.companyName,
            },
            {
              type: 'company_number',
              value: party.companyIdentificationNumber,
            },
            {
              name: 'agent_entity_id',
              value: party.agentEntityId,
            },
            {
              name: 'contract_party_id',
              value: party.contractPartyId,
            },
          ],
        })),
      },
    };
    await api.post(`/documentsignings`, documentSigning);
    setDocument({ status: preSigned ? 'signed' : 'signing_pending', finalVersionId: currentVersion.id });
  };

  const proceedDigitalSigning = async (values) => {
    const initSignResult = await initSign(projectId, documentId, currentVersion.id, values.parties);
    if (initSignResult?.success) {
      setDocument({ status: 'signing_pending', finalVersionId: currentVersion.id });
      dispatch(setNumberPendingTasks());
    } else {
      notification.error({
        message: formatMessage('app.signing.error.start'),
        description: formatMessage('app.signing.error.startDesc'),
      });
    }
  };

  if (!loaded) {
    return null;
  }
  if (error) {
    return <div>Error</div>;
  }

  return (
    <Modal
      title={title}
      visible={open}
      footer={
        <>
          <Button onClick={close}>
            <IntlMessages id={'desc.Cancel'} />
          </Button>
          {signWait && <Spin className="loader-version-exports" />}
          {!signWait && (
            <Button onClick={startSigning} type="primary">
              <IntlMessages id={'app.signing.startSigning'} />
            </Button>
          )}
        </>
      }
      onCancel={close}
      width={'70vw'}
      maskClosable={false}
    >
      <>
        <Card title="Settings">
          <Form layout="inline">
            <Form.Item label="Signing method">
              <Switch
                checkedChildren={'Digital'}
                unCheckedChildren={'Manual'}
                onChange={(on) => setManual(!on)}
                defaultChecked={!manual}
              />
            </Form.Item>
            <Form.Item label="Mark all as signed">
              <Checkbox
                checked={preSigned}
                disabled={!manual}
                onChange={(e) => setPreSigned(e.target.checked)}
              />
            </Form.Item>
          </Form>
          {!manual && (
            <p className="mt-2">
              <IntlMessages id="app.signing.startSigning.emailNotice" cap />.
            </p>
          )}
        </Card>
        {addingAgentForContractPartyEntity && (
          <AddAgentModal
            referringEntity={addingAgentForContractPartyEntity}
            onCancel={clearAddingOrEditingAgent}
            onFinishCallback={onAddedAgentToEntity}
            searchForceType="RealPerson"
            defaultCapacities={defaultCapacities}
          />
        )}
        {editingAgentEntity && (
          <EditEntity
            id={editingAgentEntity ? editingAgentEntity.id : null}
            entity={editingAgentEntity ? editingAgentEntity : null}
            referringEntity={editingAgentForContractParty}
            relationType="Agent"
            disallowEditAgents={true}
            disallowEditPrincipals={true}
            onCancel={clearAddingOrEditingAgent}
            onFinishCallback={onAgentUpdate}
            additionalRequiredFields={['identificationNumber', 'email']}
            skipFields={[]}
            defaultNewValues={{
              jurisdiction: 'se',
            }}
          />
        )}
        <div>
          <Row gutter={gutter}>
            {parties.map((party) => {
              const agents = Entity.isRealPerson(party) ? [party] : party.Agents;
              return (
                <Col lg={12} md={12} sm={24} key={party.id}>
                  <Card
                    title={Entity.name(party)}
                    extra={
                      <span className="link" onClick={() => addRepresentativeForEntity(party)}>
                        <PlusCircleOutlined className="mr-1" />
                        <IntlMessages id={'app.signing.addSignatory'} />
                      </span>
                    }
                  >
                    {agents.length > 0 ? (
                      <div>
                        <div className="mb-2 border-bottom">
                          <IntlMessages id={'app.signing.signatories'} />
                        </div>
                        <div className="">
                          {agents.map((agentEntity) => {
                            const missingFields = agentMissingFields(party, agentEntity, manual);
                            return (
                              <div
                                key={party.id + agentEntity.id}
                                className="mb-1 d-flex justify-content-space-between align-items-center"
                              >
                                <Checkbox
                                  className="boxed-checkbox w-100"
                                  checked={signatories[party.id][agentEntity.id]}
                                  onChange={() => {
                                    toggleSignatory(party.id, agentEntity.id);
                                  }}
                                >
                                  <span>{Entity.name(agentEntity)}</span>
                                  {true ? (
                                    <span className="ml-auto">
                                      <span>
                                        {missingFields.length ? (
                                          <Tooltip
                                            placement="top"
                                            title={<MissingFieldsErrorList missingFields={missingFields} />}
                                          >
                                            <WarningOutlined className="mr-1 text-danger" />
                                          </Tooltip>
                                        ) : null}
                                      </span>
                                      <span
                                        className="link"
                                        onClick={(evt) => {
                                          editRepresentativeForParty(party, agentEntity);
                                          evt.stopPropagation();
                                          evt.preventDefault();
                                        }}
                                      >
                                        <IntlMessages id={'general.edit'} />
                                      </span>
                                    </span>
                                  ) : null}
                                </Checkbox>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <div>Party has no representatives</div>
                    )}
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
        {signatoriesErrors && (
          <div>
            {signatoriesErrors.map((err) => (
              <Alert
                type="error"
                key={err.entity.id}
                message={Entity.name(err.entity)}
                description={<MissingFieldsErrorList missingFields={err.missingFields} />}
              ></Alert>
            ))}
          </div>
        )}
      </>
    </Modal>
  );
}

function agentMissingFields(principalEntity, agentEntity, manual) {
  const relation = Entity.getRelation(principalEntity, agentEntity);
  const errors = [];

  if (Entity.isLegalPerson(principalEntity)) {
    if (!relation || !Array.isArray(relation.capacities)) {
      errors.push('Invalid relation between company and agent');
    }
    if (!relation.capacities.includes('signatory')) {
      errors.push(`${Entity.name(agentEntity)} is not a signatory for ${Entity.name(principalEntity)}`);
    }
  }
  if (!agentEntity.identificationNumber && !manual) {
    errors.push('Signatory has no identification number (social security number)');
  }
  const email = Entity.firstEmail(agentEntity);
  if (!email && !manual) {
    errors.push('Signatory has no email');
  }
  if (!agentEntity.firstName) {
    errors.push('Signatory has no first name');
  }
  if (!agentEntity.lastName) {
    errors.push('Signatory has no last name');
  }
  return errors;
}

function MissingFieldsErrorList({ missingFields }) {
  if (!Array.isArray(missingFields)) {
    return null;
  }
  return (
    <ul>
      {missingFields.map((err, index) => (
        <li key={index}>{err}</li>
      ))}
    </ul>
  );
}

const publishVersion = async (documentId, versionId) => {
  await api.post(`/documents/${documentId}/publish`, { finalVersionId: versionId });
};
