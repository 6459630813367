import { useDispatch } from 'react-redux';
import { useMountedState } from 'hooks';
import { bulkUpsertEntity } from 'appRedux/actions';
import { addOrUpdateRelation, removeRelation } from './updateRelations';
import api from 'utils/api';

function addRelatedEntity(relatedEntities, entity) {
  const exists = relatedEntities.find((relatedEntity) => relatedEntity.id === entity.id);
  if (exists) {
    relatedEntities.map((relatedEntity) => {
      if (relatedEntity.id !== entity.id) return relatedEntity;
      return entity;
    });
  }
  relatedEntities.push(entity);
}

function collectEntityAndRelated(currentEntity, relatedEntities) {
  return [currentEntity, ...relatedEntities];
}

async function executeEffects(entity, sideEffects) {
  const promises = [];
  let currentEntity = entity;
  let relatedEntities = [];

  for (const sideEffect of sideEffects) {
    const { action, targetEntity, capacities } = sideEffect;
    const targetEntityId = sideEffect.targetEntityId || targetEntity.id;
    if (!targetEntityId) {
      console.warn('Cannot find targetEntityId');
      continue;
    }

    if (action === 'UPDATE_AGENT_CAPACITIES') {
      promises.push(
        api.post(`/entities/${targetEntityId}/connectAgent`, {
          principalEntityId: entity.id,
          capacities,
        })
      );
      const { updatedPrincipalEntity, updatedAgentEntity } = addOrUpdateRelation(
        currentEntity,
        targetEntity,
        capacities
      );
      currentEntity = updatedPrincipalEntity;
      addRelatedEntity(relatedEntities, updatedAgentEntity);
    }
    if (action === 'UPDATE_PRINCIPAL_CAPACITIES') {
      promises.push(
        api.post(`/entities/${entity.id}/connectAgent`, {
          principalEntityId: targetEntityId,
          capacities,
        })
      );
      const { updatedPrincipalEntity, updatedAgentEntity } = addOrUpdateRelation(
        targetEntity,
        currentEntity,
        capacities
      );
      currentEntity = updatedAgentEntity;
      addRelatedEntity(relatedEntities, updatedPrincipalEntity);
    }
    if (action === 'CONNECT_AGENT') {
      promises.push(
        api.post(`/entities/${targetEntityId}/connectAgent`, {
          principalEntityId: entity.id,
          capacities,
        })
      );
      const { updatedPrincipalEntity, updatedAgentEntity } = addOrUpdateRelation(
        currentEntity,
        targetEntity,
        capacities
      );
      currentEntity = updatedPrincipalEntity;
      addRelatedEntity(relatedEntities, updatedAgentEntity);
    }
    if (action === 'CONNECT_PRINCIPAL') {
      promises.push(
        api.post(`/entities/${entity.id}/connectAgent`, {
          principalEntityId: targetEntityId,
          capacities,
        })
      );
      const { updatedPrincipalEntity, updatedAgentEntity } = addOrUpdateRelation(
        targetEntity,
        currentEntity,
        capacities
      );
      currentEntity = updatedAgentEntity;
      addRelatedEntity(relatedEntities, updatedPrincipalEntity);
    }
    if (action === 'DISCONNECT_AGENT') {
      promises.push(api.delete(`/entities/${targetEntityId}/disconnectAgent?principalEntityId=${entity.id}`));
      const { updatedPrincipalEntity, updatedAgentEntity } = removeRelation(currentEntity, targetEntity);
      currentEntity = updatedPrincipalEntity;
      addRelatedEntity(relatedEntities, updatedAgentEntity);
    }
    if (action === 'DISCONNECT_PRINCIPAL') {
      promises.push(api.delete(`/entities/${entity.id}/disconnectAgent?principalEntityId=${targetEntityId}`));
      const { updatedPrincipalEntity, updatedAgentEntity } = removeRelation(targetEntity, currentEntity);
      currentEntity = updatedAgentEntity;
      addRelatedEntity(relatedEntities, updatedPrincipalEntity);
    }
  }
  try {
    await Promise.all(promises);
  } catch (err) {
    console.log('Error executing entity side effects ', err);
  }
  return {
    currentEntity,
    relatedEntities,
    entityCollection: collectEntityAndRelated(currentEntity, relatedEntities),
  };
}

const OPPOSITE_ACTIONS = {
  CONNECT_AGENT: 'DISCONNECT_AGENT',
  DISCONNECT_AGENT: 'CONNECT_AGENT',
  CONNECT_PRINCIPAL: 'DISCONNECT_PRINCIPAL',
  DISCONNECT_PRINCIPAL: 'CONNECT_PRINCIPAL',
};

function matchingAction(action1, action2) {
  return action1 === action2 || OPPOSITE_ACTIONS[action1] === action2;
}

function cleanUpSideEffects(sideEffects) {
  return sideEffects
    .map((effect, index) => {
      const { action, targetEntity } = effect;
      const subsequentOverridingEffect = sideEffects.find(
        (anotherEffect, anotherIndex) =>
          matchingAction(anotherEffect.action, action) &&
          anotherEffect.targetEntity === targetEntity &&
          anotherIndex > index
      );
      if (subsequentOverridingEffect) return null;
      return effect;
    })
    .filter((e) => !!e);
}

export function useEntitySideEffects(options = {}) {
  const { redux = false } = options;

  const dispatch = useDispatch();
  const [sideEffects, setSideEffects] = useMountedState([]);

  const add = (effect) => {
    // console.log('Adding side effect ', effect);
    setSideEffects([...sideEffects, effect]);
    sideEffects.push(effect);
  };
  const remove = (index) => {
    setSideEffects(sideEffects.filter((_, i) => i !== index));
  };

  const execute = async (entity) => {
    // console.log('Execute ', { cuse: cleanUpSideEffects(sideEffects), sideEffects });
    const result = await executeEffects(entity, cleanUpSideEffects(sideEffects));

    if (redux) {
      dispatch(bulkUpsertEntity(result.entityCollection));
    }

    setSideEffects([]);

    return result;
  };

  return {
    add,
    remove,
    execute,
  };
}
