import React, { useState } from 'react';
import { Form } from 'antd';
import { useSelector } from 'react-redux';
import { getValue, useSiblingEntity } from './utils/values';
import { Entity } from 'core/interfaces';
import EditEntity from '../../../../../../../components/entity/EditEntity';
import { EntitySelect } from 'components/ui';

function EntityInput(props) {
  const { label, handleChange, language, field, isTemplate, card, cardUid } = props;
  const { target } = field;
  const value = getValue(props, {});
  const entities = useSelector(Entity.getBaseState);
  const targetEntity = useSiblingEntity(card, cardUid, target?.fromEntityField)
  const { id } = value;

  const entity = Entity.getById(entities, id);
  const name = Entity.name(entity);

  // console.log('entity entry in input ', { entity, id });

  const [isEditingEntity, setIsEditingEntity] = useState(false);

  const onClickEdit = () => {
    setIsEditingEntity(true);
  };

  const cancelEditingEntity = () => setIsEditingEntity(false);

  return (
    <>
      <Form.Item
        label={label}
        extra={field.info ? <small className="muted">{field.info[language]}</small> : null}
        style={{paddingLeft: 8, paddingRight: 8}}
      >
        <EntitySelect
          targetEntity={targetEntity}
          targetType={target?.targetRelative && `${target.targetRelative}s`}
          selectedEntity={entity}
          onChange={handleChange}
          onEdit={onClickEdit}
          disabled={isTemplate}
        />
      </Form.Item>
      {isEditingEntity && (
        <EditEntity
          entity={entity}
          onCancel={cancelEditingEntity}
          onFinishCallback={({entity}) =>  handleChange(entity)}
          redux={true}
        />
      )}
    </>
  )
}

export default EntityInput;
