const NODE_ENV = process.env.REACT_APP_NODE_ENV;
const IS_DEVELOPMENT = NODE_ENV === 'development';
const IS_PRODUCTION = NODE_ENV === 'production';
const IS_EXTERNAL = process.env.REACT_APP_IS_EXTERNAL;
const FEATURE_FLAGS = process.env.REACT_APP_FEATURE_FLAGS;
const FEATURE_FLAG_OVERRIDES = [];

export {
  NODE_ENV,
  IS_EXTERNAL,
  IS_PRODUCTION,
  IS_DEVELOPMENT,
  FEATURE_FLAGS,
  FEATURE_FLAG_OVERRIDES,
};
