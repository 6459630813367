import IntlMessages from 'util/IntlMessages';

export default function MenuHeader({ title = '', menu = false, header = false, intl }) {
  let label = intl ? <IntlMessages id={intl} /> : title;

  let className = '';
  if (header) {
    className += 'd-flex justify-content-center p-3 border-bottom';
  } else {
    className = 'ml-1' + (menu ? ' mt-3 mb-2' : '');
  }

  return (
    <div className={className}>
      <small>
        <strong className="ml-2 text-uppercase">{label}</strong>
      </small>
    </div>
  );
}
