import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';

import Main from './main/index';
import Search from './search/index';
import Projects from './projects/index';
import Project from './project/index';
import Folders from './folders/index';
import Companies from './companies/index';
import Persons from './persons/index';
import Entity from './entity/index';
import Tasks from './tasks/index';
import Help from './help/index';
import Templates from './templates/index';
import ClientSettings from './clientSettings/index';
import Organisation from './organisation/index';
import Notifications from './notifications/index';

import DocumentRedirect from './redirectors/documents';
import VersionsRedirect from './redirectors/versions';

const App = memo(() => {
  return (
    <div className="main-content-wrapper">
      <Switch>
        <Route path={`/main`} render={() => <Main />} />
        <Route path={`/search`} render={() => <Search />} />
        <Route path={`/projects`} render={() => <Projects />} />
        <Route path={`/documents/:id`} component={DocumentRedirect} />
        <Route path={`/versions/:id`} component={VersionsRedirect} />
        <Route path={`/folders`} render={() => <Folders />} />
        <Route
          path={`/project/:id`}
          render={({ match }) => <Project projectId={match.params.id} projectUrl={match.url} />}
        />
        <Route
          path={`/folder/:id`}
          render={({ match }) => <Project projectId={match.params.id} projectUrl={match.url} />}
        />
        <Route path={`/companies`} exact component={Companies} />
        <Route path={`/persons`} exact component={Persons} />
        <Route path={`/entity/:id`} component={Entity} />
        <Route path={`/organisation`} component={Organisation} />
        <Route path={`/tasks`} component={Tasks} />
        <Route path={`/templates`} component={Templates} />
        <Route path={`/clientSettings`} component={ClientSettings} />
        <Route path={`/help`} component={Help} />
        <Route path={`/notifications`} component={Notifications} />
      </Switch>
    </div>
  );
});
/* 
match:
isExact: false
params: {id: '8e9e3196-0894-482c-a375-56dc7aa49528'}
path: "/project/:id"
url: "/project/8e9e3196-0894-482c-a375-56dc7aa49528"
 */
export default App;
