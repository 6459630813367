import * as Y from 'yjs';
import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useContract, useYjsEditor, useEntityData } from 'hooks';
import { MarkupEntityContext } from './MarkupEntityContext';
import ViewEditor from '../../editor/views/ViewEditor';
import api from 'utils/api';
import { compare } from '../../../core/utils/compare';

const { TabPane } = Tabs;

export function Markup() {
  const contract = useContract();
  const { provider, initialSnapshot, permanentUserDataRef } = useYjsEditor();
  const [latestMarkup, setLatestMarkup] = useState(null);
  const [baseMarkup, setBaseMarkup] = useState(null);

  useEffect(() => {
    const { document } = provider;
    const sharedType = provider.document.get('content', Y.XmlText);
    provider.document.gc = false;
    const nextSnap = Y.snapshot(document);

    const latestM = compare(sharedType, initialSnapshot, nextSnap, permanentUserDataRef.current);
    setLatestMarkup(latestM);
    (async () => {
      const res = await api.get(`/versions/${contract.id}/markup?baseSnapshot=true`);
      if (!res.data?.buffer?.data) return console.log('Bad result', res);
      const uints = Uint8Array.from(res.data.buffer.data);
      const doc = new Y.Doc();
      doc.gc = false;
      Y.applyUpdate(doc, uints);
      const baseSnapshot = Y.snapshot(doc);
      const baseM = compare(sharedType, baseSnapshot, nextSnap, permanentUserDataRef.current);
      setBaseMarkup(baseM);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allEntityIds = [...new Set([...(latestMarkup?.entityIds || []), ...(baseMarkup?.entityIds || [])])];
  const entityData = useEntityData(allEntityIds);

  return (
    <div>
      <MarkupEntityContext.Provider value={entityData}>
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Since document was opened" key="1" disabled={!latestMarkup?.markup}>
            {latestMarkup && latestMarkup.markup ? (
              <ViewEditor
                contract={contract}
                content={latestMarkup.markup}
                onlineMode={false}
                mode="plain"
                markup
              />
            ) : (
              <div>Loading . . .</div>
            )}
          </TabPane>
          <TabPane tab="All changes" key="2" disabled={!baseMarkup?.markup}>
            {baseMarkup && baseMarkup.markup ? (
              <ViewEditor
                contract={contract}
                content={baseMarkup.markup}
                onlineMode={false}
                mode="plain"
                markup
              />
            ) : (
              <div>Loading . . .</div>
            )}
          </TabPane>
        </Tabs>
      </MarkupEntityContext.Provider>
    </div>
  );
}
