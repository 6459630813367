import React, { useState, useCallback } from 'react';
import { Card, Row, Col, Button, Modal } from 'antd';
import NewDocumentTemplateItem from './NewDocumentTemplateItem';
import IntlMessages from 'util/IntlMessages';

export default function NewDocumentTemplateButton({ coreTemplates, children, className, templateWasCreated }) {
  const [open, setOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const close = () => setOpen(false);

  const buttonClassName = className || 'm-0 ml-2';

  const toggleOpen = useCallback(() => {
    setOpen(!open);
    setSelectedTemplate(null);
  }, [open]);

  return (
    <>
      {children ? (
        <div className="clickable" onClick={toggleOpen}>
          {children}
        </div>
      ) : (
        <Button
          type="primary"
          icon={<i className="mdi mdi-settings-box mr-2" />}
          className={buttonClassName}
          onClick={toggleOpen}
        >
          <IntlMessages id="app.templates.newTemplate" />
        </Button>
      )}
      <Modal
        title={
          <div>
            {selectedTemplate !== null && (
              <Button
                type="secondary"
                size="small"
                className="creation-modal-back float-left ml-2"
                onClick={() => {
                  setSelectedTemplate(null);
                }}
              >
                <i className="mdi mdi-arrow-left mr-2" />
                <IntlMessages id="desc.Back" />
              </Button>
            )}
            <div>
              <IntlMessages id="app.templates.newTemplate" />
            </div>
          </div>
        }
        visible={open}
        backdrop={'static'}
        onOk={toggleOpen}
        onCancel={toggleOpen}
        footer={[
          <Button key="back" onClick={toggleOpen}>
            <IntlMessages id="desc.Cancel" />
          </Button>,
        ]}
      >
        {open && selectedTemplate === null && (
          <div>
            <div className="text-center">
              <div className="card-title mb-4">
                <h6>
                  <IntlMessages id="app.templates.chooseTemplate" />
                </h6>
              </div>
            </div>
            <Row>
              {coreTemplates &&
                coreTemplates.length > 0 &&
                coreTemplates.map((template, index) => (
                  <Col span={8} key={'doc_tmpl2_' + index}>
                    <Card
                      className="mb-1 pb-1 clickable liftable"
                      onClick={() => {
                        setSelectedTemplate(index);
                      }}
                    >
                      <div className="flex-column justify-content-center align-items-center">
                        <i className={'mdi mdi-settings-box'} style={{ fontSize: 48 }} />
                        <h5 align={'center'}>{template.name}</h5>
                        <small align={'center'} className="text-center">
                          {template.description}
                        </small>
                      </div>
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>
        )}
        {open && selectedTemplate !== null && (
          <div>
            <NewDocumentTemplateItem
              documentTemplateId={coreTemplates[selectedTemplate].id}
              templateWasCreated={templateWasCreated}
              closeModal={close}
            />
          </div>
        )}
      </Modal>
    </>
  );
}
