export * from './repaymentClause/facilitiesAgreement';
export * from './repaymentClause/offer';

const AFFECTED_FIELDS = [
  'facility/type',
  'facility/name',
  'facility/commitment',
  'facility/repayment',
  'facility/repaymentinterval',
  'facility/repaymentamount',
  'facility/deferredrepayment',
  'facility/compensaterepaymentdeferral',
  'facility/tenor',
  'facility/tenorformat',
  'facility/currency',
  'facility/termdate',
  'facility/offer_multiple_tenors',
];

function multiIncludes(array1, array2) {
  for (const item of array1) {
    if (array2.includes(item)) return true;
  }
  return false;
}

export const repaymentClauseModuleSlate = {
  id: 'repaymentClauseModuleSlate',
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true },
  match: ({ entry, node, api }) => {
    return api.utils.engine.matchModule(node, 'facilityAgreement', 'repaymentClause');
  },
  handler: function ({ state, handlerInvoked, entries, paths, api, matches }) {
    // Do not run unless necessary.
    const cardIds = entries.map((entry) => entry.cardId);
    const fieldNames = entries.map((entry) => entry.fieldName);
    if (!cardIds.includes('facility')) return;
    if (!multiIncludes(AFFECTED_FIELDS, fieldNames)) return;

    console.log('In repyament clause')

    for (const { node, actions } of matches.default) {
      let newChildren;

      if (this.contract.data.outline.id_name === 'offer_bank_loan')
        newChildren = this.buildRepaymentClauseOffer(node, state);
      else newChildren = this.buildRepaymentClauseSFA(node, state);

      // this.log('repayment... ', {node: JSON.parse(JSON.stringify(node)), nc: newChildren})

      if (!newChildren) return;

      // Fix item-joiner...
      // if (node.data.module.type !== 'inline') api.utils.engine.itemJoiner(node, { language: this.language });

      actions.replaceChildren(newChildren);
    }
  },
};
