import { useState, useRef, useEffect, useCallback } from 'react';
import { List } from 'antd';
import { useHistory } from 'react-router-dom';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import { DocumentTagItem, EntityItem, ProjectOrDocumentItem } from './Results';
import { LoadingOutlined } from '@ant-design/icons';

function getSearchUrl(searchString) {
  if (!searchString || searchString.length < 3) return;
  return `/main/search?str=${searchString}`;
}

export const SearchResults = ({ searchString }) => {
  const [status, setStatus] = useState('rest');
  const [results, setResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const isSearchingRef = useRef(false);
  const abortController = useRef(new AbortController());

  const clearSearch = useCallback(() => {
    if (abortController.current) {
      if (typeof abortController.current.abort === 'function') {
        abortController.current.abort();
      }
      abortController.current = null;
    }
    setIsSearching(false);
    setResults(null);
  }, []);

  useEffect(() => {
    return () => {
      if (typeof abortController.current?.abort === 'function') {
        abortController.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (!searchString) return clearSearch();
    const url = getSearchUrl(searchString);
    if (!url) {
      setStatus('improper-search');
      return clearSearch();
    }
    setIsSearching(true);

    if (isSearchingRef.current && typeof abortController.current.abort === 'function') {
      abortController.current.abort();
    }
    isSearchingRef.current = true;
    abortController.current = new AbortController();

    api
      .get(url, { signal: abortController.current.signal })
      .then((response) => {
        setResults(response.data);
        setStatus('results');
        setIsSearching(false);
        isSearchingRef.current = false;
      })
      .catch((err) => {
        if (err.message === 'canceled') return;
        console.log('err', err);
        isSearchingRef.current = false;
        setResults(null);
        setIsSearching(false);
        setStatus('error');
      });
  }, [clearSearch, searchString]);

  return <DisplayResults status={status} results={results} isSearching={isSearching} />;
};

const DispayWrapper = ({ children }) => {
  return <div className="mt-search-results">{children}</div>;
};

function ResultItem({ item, ...rest }) {
  switch (item._type) {
    case 'DocumentTag':
      return <DocumentTagItem item={item} {...rest} />;
    case 'Entity':
      return <EntityItem item={item} {...rest} />;
    case 'Project':
    case 'Document':
      return <ProjectOrDocumentItem item={item} {...rest} />;
    default:
      return null;
  }
}

export const DisplayResults = ({ status, results, isSearching }) => {
  const history = useHistory();

  if (isSearching) {
    return (
      <DispayWrapper>
        <div className="d-flex justify-content-center align-items-center p-4">
          <LoadingOutlined style={{ fontSize: 36 }} spin />
        </div>
      </DispayWrapper>
    );
  }

  if (status === 'improper-search') {
    return (
      <DispayWrapper>
        <div className="d-flex flex-column align-items-center mt-4 mb-4">
          <div className="mb-4">
            Type at least <code>3</code> characters.
          </div>
          <div>
            To search for a <strong>contract provision</strong>, use the format:{' '}
            <span className="ml-2 border p-2">
              <code>company</code>
            </span>
            <span className="ml-2 border p-2">
              <code>keyword</code>
            </span>
          </div>
        </div>
      </DispayWrapper>
    );
  }
  if (status === 'error') {
    return (
      <DispayWrapper>
        <div className="d-flex align-items-center mt-4">An error occurred. Please try again later.</div>
      </DispayWrapper>
    );
  }

  if (!results) return null;

  // console.log('results', results);

  return (
    <DispayWrapper>
      <List
        itemLayout="horizontal"
        dataSource={results}
        renderItem={(item) => {
          return <ResultItem history={history} item={item} />;
        }}
      />
    </DispayWrapper>
  );
};
