import { translateText } from '../../prototype/getters';
import { find } from '../index';
import { Transforms } from '../../../../import/slate';
import { isBlock, isInline, isText, isList } from '../../../types/elements';

export const itemJoinerSlate = (editor, opts = {}) => {
  const relevantLists = find(
    editor,
    (n) => isList(n) && n.data && n.data.item_join && n.data.item_join !== 'false',
    {
      tuple: true,
      parents: true,
    }
  );

  for (const tuple of relevantLists) {
    itemJoinFunction(tuple, editor, opts);
  }
};

const itemJoinFunction = (tuple, editor, opts = {}) => {
  let { inheritedFinalEnd } = opts;
  const { redo, debug, language } = opts;

  const [node, path, parents] = tuple;

  if (inheritedFinalEnd === 'false') inheritedFinalEnd = false;

  const itemJoinText =
    node.data &&
    (node.data.item_join === 'and'
      ? translateText('and', { language })
      : node.data.item_join === 'or'
      ? translateText('or', { language })
      : node.data.item_join);

  const delimiter = language === 'sv' ? ',' : ';';

  const activeItems = node.children.filter((n) => !n.data || !n.data._inActive);

  const listLength = activeItems.length;
  const lastItemHanging =
    (activeItems[listLength - 1] &&
      activeItems[listLength - 1].data &&
      activeItems[listLength - 1].data.hanging) ||
    false;

  const finalEnd = lastItemHanging ? ',' : inheritedFinalEnd || '.';

  const breakPoint = lastItemHanging ? listLength - 1 : listLength;

  let activeIndex = -1;

  const childrenLength = node.children.length;
  for (var i = 0; i < childrenLength; i++) {
    const item = node.children[i];
    const itemPath = [...path, i];

    if (item.data && item.data._inActive) continue;

    const noJoin =
      !node.data || !node.data.item_join || node.data.item_join === 'false' || node.data.item_join === '';

    if (!item || item.type !== 'list_item' || noJoin) continue;

    activeIndex += 1;

    // If next is a list, then do not add...
    if (node.children[activeIndex + 1] && node.children[activeIndex + 1].type === 'numbered_list') {
      // console.log('Skip ij for parent of sub list', item);
      continue;
    }

    let [lastText, lastTextIndex] = getLastTextItem(item.children);
    if (!lastText) {
      console.log('Found no lastTextItem for ', item);
      continue;
    }
    if (!lastText.hasOwnProperty('text')) continue;
    if (lastText.text[lastText.text.length - 1] === ':') {
      // console.log("Last char is colon, continue");
      continue;
    }
    let add = '';

    if (activeIndex === breakPoint - 1)
      // Last item
      add = finalEnd;
    else if (activeIndex === breakPoint - 2)
      // Second last item
      add = delimiter + ' ' + itemJoinText;
    else if (lastItemHanging && activeIndex === breakPoint) {
      // Do nothing really.
      // console.log('This is the hanging item:', item);
    } else add = delimiter;

    if (lastText.itemJoined) {
      if (lastText.text === add) {
        // No need to update.
        console.log('Already same.');
        continue;
      } else {
        const targetPath = [...itemPath, lastTextIndex];
        console.log('Set last text to ', {
          add,
          i,
          itemPath,
          activeIndex,
          lastText,
          lastTextIndex,
          targetPath,
        });
        Transforms.insertText(editor, add, { at: targetPath, voids: true });
      }
    } else {
      const targetPath = [...itemPath, lastTextIndex + 1];
      console.log('Add itemJoined text entry ', {
        add,
        i,
        itemPath,
        activeIndex,
        lastText,
        lastTextIndex,
        targetPath,
      });
      Transforms.insertNodes(editor, { text: add, itemJoined: true }, { at: targetPath, voids: true });
    }

    /* if (lastText.itemJoined) {
      lastText.text = add;
    } else {
      item.children.push({
        text: add,
        itemJoined: true,
      });
    } */
  }
};

function getLastTextItem(children) {
  const lastIndex = children.length - 1;
  let lastItem = children[lastIndex];
  if (!lastItem) {
    return [null];
  }
  if (lastItem.type === 'table') return [null];
  if (isText(lastItem)) return [lastItem, lastIndex];
  if (isInline(lastItem)) {
    const [lastTextOfInline, lastIndexOfInline] = getLastTextItem(lastIndex.children);
    if (lastTextOfInline) {
      console.log('Incorrect slate content....', children);
      return [null, lastIndex];
    }
  }
  /* if ((isBlock(lastItem) || isInline(lastItem)) && Array.isArray(lastItem.children))
    return getLastTextItem(lastItem.children); */
  return [null];
}

/*

4
0    (a) hejsan;
1    (b) svejsan;
2        (i) apa; and        <- sub list
        (ii) keff; and      <- main list
3    (c) mjau

*/
