import { USE_CALCULATED_AFFECTED_ACP_KEYS } from '../../../../config/draft';

export const activeConditionAnyUpdateSlate = {
  id: 'activeConditionAnyUpdateSlate',
  dependencies: {
    repeatableAdd: true,
    repeatableRemove: true,
    repeatableChange: true,
    ordinary: true,
    entityAny: true,
  },
  preMatch: ({ engine }) => {
    const keys = engine._tmpDraft.updatedACPkeys;
    if (!keys && USE_CALCULATED_AFFECTED_ACP_KEYS) {
      engine.log('No prepared activeCondition keys for acp onAnyUpdate!');
      return false;
    }
    return true;
  },
  match: ({ node }) => {
    return node.data && !!node.data.acp;
  },
  handler: function ({ state, api, matches }) {
    for (const { node, parents, actions } of matches.default) {
      const localState = api.utils.engine.getLocalState(node, parents, state, this.contract);

      if (
        api.logic.matchRule(node.data.acp, USE_CALCULATED_AFFECTED_ACP_KEYS && this._tmpDraft.updatedACPkeys)
      ) {
        
        const { changed, inActive } = this.checkNodeACPrule(node, localState);
        if (changed) {
          actions.setNodeData('_inActive', inActive);
        }
      }
    }
  },
};

export const checkNodeACPrule = function (node, localState) {
  const currentlyInActive = node.data._inActive;
  const passed = this.applyLogic(node.data.acp, {
    local: localState,
  });
  const shallBeInActive = !passed;
  const changed = currentlyInActive !== shallBeInActive;

  return { changed, inActive: shallBeInActive };
};
