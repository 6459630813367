import React from 'react';
import { Form, Checkbox, Tooltip } from 'antd';
import { getValue } from './utils/values';

function InputCheck(props) {
  const { label, handleChange, language, cardId, field, path, cardUid } = props;

  const value = getValue(props, false);

  const checkLabel = field.checkLabel && field.checkLabel[language] ? field.checkLabel[language] : '';
  const id = cardId + cardUid + path;

  const showLabel = (
    <>
      <span>{label}</span>
      {field.helper && field.helper[language] && (
        <Tooltip title={field.helper[language]}>
          <i className="ml-1 mdi mdi-help-circle" />
        </Tooltip>
      )}
    </>
  );

  return (
    <Form.Item
      label={showLabel}
      extra={field.info && <small className="muted">{field.info[language]}</small>}
    >
      {field.multiple ? (
        <MultipleCheckbox {...props} value={value} />
      ) : (
        <Checkbox
          onChange={(event) => {
            handleChange(event.target.checked, {
              formType: 'checkbox',
            });
          }}
          id={id}
          name={id}
          checked={value}
        >
          {checkLabel}
        </Checkbox>
      )}
    </Form.Item>
  );
}

function _transformContentToOptions(content, language) {
  return content.map((item) => ({
    label: item.label[language],
    value: item.id,
  }));
}

function MultipleCheckbox(props) {
  const { label, handleChange, language, cardId, field, path, cardUid, value } = props;
  const options = _transformContentToOptions(field.content, language);

  const onChange = (values) => {
    handleChange(values);
  };

  return <Checkbox.Group options={options} value={value || []} onChange={onChange} />;
}

export default InputCheck;
