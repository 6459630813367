import { useIsTemplateStudio, useEventState } from 'hooks';
import CreatePage from './renderDraftUI/CreatePage';
import EditorStructureView from './EditorStructureView';

export default function StudioInputFormContent(props) {
  const isTemplate = useIsTemplateStudio();
  const { activePagePaths, activeFlatUiPages, flatUiPages, activePaths, currentPage } = props;

  if (isTemplate) {
    return (
      <WithStructureView
        activeFlatUiPages={activeFlatUiPages}
        flatUiPages={flatUiPages}
        activePagePaths={activePagePaths}
        currentPage={currentPage}
        activePaths={activePaths}
      />
    );
  }
  return (
    <CreatePage
      page={currentPage}
      activeFlatUiPages={activeFlatUiPages}
      activePagePaths={activePagePaths}
      activePaths={activePaths}
    />
  );
}

function WithStructureView({ activeFlatUiPages, activePagePaths, currentPage, activePaths }) {
  const showEditorStructure = useEventState('ContractStructureModeSwitch');


  return (
    <>
      {/* <div className="contract-structure-toggle">
        <Tooltip title={`${open ? 'Close' : 'Open'} structure view`} placement="right">
          <Button
            type={open ? 'primary' : 'secondary'}
            icon={open ? <CloseOutlined /> : <BuildFilled />}
            onClick={() => setOpen(!open)}
          />
        </Tooltip>
      </div> */}
      {showEditorStructure ? (
        <EditorStructureView />
      ) : (
        <CreatePage
          activeFlatUiPages={activeFlatUiPages}
          activePagePaths={activePagePaths}
          page={currentPage}
          activePaths={activePaths}
        />
      )}
    </>
  );
}
