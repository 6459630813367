import React, { useState } from 'react';
import {
  Row,
  Col,
  Select,
  Checkbox,
  Button,
  Input,
  Form,
  Divider,
  Table,
  Typography,
  Collapse,
  Modal,
  notification,
} from 'antd';
import { standardConceptGrammar } from 'core/data/concepts/built-in-data';
import { Contract } from 'core/interfaces';
import { ofilter } from 'core/utils/general';
import { VALUE_INPUTS } from 'core/config/constants';
import { useModalContent } from 'components/ui/modals';
import { describeAllOfConcept } from 'core/interfaces/concept-helpers/grammar';
import uuid from 'uuid-random';

const { Panel } = Collapse;
const { Paragraph } = Typography;
const { Option } = Select;

export default function EditAddConcepts({
  contract,
  id,
  mode = 'add',
  children,
  contentType = 'span',
  buttonProps = {},
  onNewBox,
  conceptStates,
  setConceptStates,
}) {
  const title = mode === 'edit' ? 'Edit' : 'Add';
  const [Modal, toggleModal, , open] = useModalContent(title + ' Concept', {
    defaultOpen: false,
  });
  const allCards = Contract.getUiCards(contract);
  const repeatableCards = ofilter(allCards, (card) => Contract.getUiIsCardRepeatable(contract, card.id));
  const cardsWithState = Contract.getCardsWithState(contract);
  const contractConceptGrammars =
    (conceptStates && conceptStates.conceptGrammar) || Contract.getConceptGrammar(contract) || {};
  const concepts = (conceptStates && conceptStates.concepts) || Contract.getConcepts(contract);
  const concept = !id ? null : concepts.find((c) => c.id === id);

  // console.log('contractConceptGrammars', { contractConceptGrammars, standardConceptGrammar });

  let content;
  if (children && contentType === 'button') {
    content = (
      <Button onClick={toggleModal} {...buttonProps}>
        {children}
      </Button>
    );
  } else if (children && contentType === 'span') {
    content = (
      <span className="link" onClick={toggleModal}>
        {children}
      </span>
    );
  } else if (mode === 'edit') {
    content = (
      <span className="link" onClick={toggleModal}>
        Edit concept
      </span>
    );
  } else {
    content = null;
  }

  return (
    <>
      {open && (
        <Modal size="lg" /* className="modal-lg" */ width={1000}>
          <HandleConcept
            contract={contract}
            id={id}
            concepts={concepts}
            concept={concept}
            toggleModal={toggleModal}
            repeatableCards={repeatableCards}
            cardsWithState={cardsWithState}
            allConceptGrammarsAll={{
              ...standardConceptGrammar,
              ...contractConceptGrammars,
            }}
            onNewBox={onNewBox}
            mode={mode}
            conceptStates={conceptStates}
            setConceptStates={setConceptStates}
          />
        </Modal>
      )}
      {content}
    </>
  );
}

function HandleConcept({
  id: startId,
  concepts,
  concept: startConcept = {},
  contract,
  allConceptGrammarsAll,
  toggleModal,
  repeatableCards,
  cardsWithState,
  onNewBox,
  mode,
  conceptStates,
  setConceptStates,
}) {
  const language = Contract.getLanguage(contract);

  const {
    contractParty: defaultContractParty = false,
    definitionKey: defaultDefinitionKey = null,
    id: defaultId = startId || '',
    stateId: defaultStateId = startId || '',
    stateKey: defaultStateKey = '',
    state: defaultFixedState = null,
    type: defaultType = 'regular',
    inheritance: defaultInheritance = null,
  } = startConcept || {};
  let { label: defaultLabel } = startConcept || {};
  defaultLabel = (defaultLabel && typeof defaultLabel[language] === 'string' && defaultLabel[language]) || '';

  let defaultStateType;
  if (defaultType === 'reference' && defaultInheritance) {
    defaultStateType = 'inherit';
  } else if (!!defaultFixedState && typeof defaultFixedState === 'object') {
    defaultStateType = 'fixed';
  } else if (defaultStateId) {
    defaultStateType = 'card';
  }

  let defaultGender = 'r',
    defaultBase = 'name',
    defaultSingularPrefix = '',
    defaultSingularPrefixSpace = false,
    defaultSingularSuffix = '',
    defaultSingularSuffixSpace = false,
    defaultSingularFixedPrefix = 'the',
    defaultSingularFixedPrefixSpace = true,
    defaultSingularFixedSuffix = '',
    defaultSingularFixedSuffixSpace = false,
    defaultPluralPrefix = '',
    defaultPluralPrefixSpace = false,
    defaultPluralSuffix = 's',
    defaultPluralSuffixSpace = false,
    defaultPluralFixedPrefix = 'the',
    defaultPluralFixedPrefixSpace = true,
    defaultPluralFixedSuffix = 's',
    defaultPluralFixedSuffixSpace = false;

  if (
    defaultId &&
    allConceptGrammarsAll &&
    allConceptGrammarsAll[defaultId] &&
    allConceptGrammarsAll[defaultId].values &&
    allConceptGrammarsAll[defaultId].values[language]
  ) {
    const grammar = allConceptGrammarsAll[defaultId].values[language];
    defaultGender = grammar.gender;
    defaultBase = grammar.base;

    defaultSingularPrefix = grammar.singular.prefix || defaultSingularPrefix;
    defaultSingularPrefixSpace = grammar.singular.prefixSpace || defaultSingularPrefixSpace;
    defaultSingularSuffix = grammar.singular.suffix || defaultSingularSuffix;
    defaultSingularSuffixSpace = grammar.singular.suffixSpace || defaultSingularSuffixSpace;

    defaultSingularFixedPrefix = grammar.singularFixed.prefix || defaultSingularFixedPrefix;
    defaultSingularFixedPrefixSpace = grammar.singularFixed.prefixSpace || defaultSingularFixedPrefixSpace;
    defaultSingularFixedSuffix = grammar.singularFixed.suffix || defaultSingularFixedSuffix;
    defaultSingularFixedSuffixSpace = grammar.singularFixed.suffixSpace || defaultSingularFixedSuffixSpace;

    defaultPluralPrefix = grammar.plural.prefix || defaultPluralPrefix;
    defaultPluralPrefixSpace = grammar.plural.prefixSpace || defaultPluralPrefixSpace;
    defaultPluralSuffix = grammar.plural.suffix || defaultPluralSuffix;
    defaultPluralSuffixSpace = grammar.plural.suffixSpace || defaultPluralSuffixSpace;

    defaultPluralFixedPrefix = grammar.pluralFixed.prefix || defaultPluralFixedPrefix;
    defaultPluralFixedPrefixSpace = grammar.pluralFixed.prefixSpace || defaultPluralFixedPrefixSpace;
    defaultPluralFixedSuffix = grammar.pluralFixed.suffix || defaultPluralFixedSuffix;
    defaultPluralFixedSuffixSpace = grammar.pluralFixed.suffixSpace || defaultPluralFixedSuffixSpace;
  }

  const [templateConcept, setTemplateConcept] = useState(null);

  const [id, setId] = useState(defaultId);
  const [label, setLabel] = useState(defaultLabel);
  const [type, setType] = useState(defaultType);
  const [gender, setGender] = useState(defaultGender);

  const [base, setBase] = useState(defaultBase);

  const [stateId, setStateId] = useState(defaultStateId);
  const [stateKey, setStateKey] = useState(defaultStateKey);

  const [contractParty, setContractParty] = useState(defaultContractParty);
  const [definitionKey /* setDefinitionKey */] = useState(defaultDefinitionKey);

  const [stateType, setStateType] = useState(defaultStateType);

  const [inherit, setInherit] = useState(
    defaultInheritance && Array.isArray(defaultInheritance.inherit) ? defaultInheritance.inherit : []
  );
  const [filterDuplicates, setFilterDuplicates] = useState(
    defaultInheritance && defaultInheritance.hasOwnProperty('filterDuplicates')
      ? defaultInheritance.filterDuplicates
      : true
  );

  const [fixedState, setFixedState] = useState(defaultFixedState || {});

  const [singularPrefix, setSingularPrefix] = useState(defaultSingularPrefix);
  const [singularPrefixSpace, setSingularPrefixSpace] = useState(defaultSingularPrefixSpace);
  const [singularSuffix, setSingularSuffix] = useState(defaultSingularSuffix);
  const [singularSuffixSpace, setSingularSuffixSpace] = useState(defaultSingularSuffixSpace);

  const [singularFixedPrefix, setSingularFixedPrefix] = useState(defaultSingularFixedPrefix);
  const [singularFixedPrefixSpace, setSingularFixedPrefixSpace] = useState(defaultSingularFixedPrefixSpace);
  const [singularFixedSuffix, setSingularFixedSuffix] = useState(defaultSingularFixedSuffix);
  const [singularFixedSuffixSpace, setSingularFixedSuffixSpace] = useState(defaultSingularFixedSuffixSpace);

  const [pluralPrefix, setPluralPrefix] = useState(defaultPluralPrefix);
  const [pluralPrefixSpace, setPluralPrefixSpace] = useState(defaultPluralPrefixSpace);
  const [pluralSuffix, setPluralSuffix] = useState(defaultPluralSuffix);
  const [pluralSuffixSpace, setPluralSuffixSpace] = useState(defaultPluralSuffixSpace);

  const [pluralFixedPrefix, setPluralFixedPrefix] = useState(defaultPluralFixedPrefix);
  const [pluralFixedPrefixSpace, setPluralFixedPrefixSpace] = useState(defaultPluralFixedPrefixSpace);
  const [pluralFixedSuffix, setPluralFixedSuffix] = useState(defaultPluralFixedSuffix);
  const [pluralFixedSuffixSpace, setPluralFixedSuffixSpace] = useState(defaultPluralFixedSuffixSpace);

  const allConceptGrammars = Object.keys(allConceptGrammarsAll).reduce((acc, curr) => {
    if (allConceptGrammarsAll[curr] && allConceptGrammarsAll[curr].values)
      acc[curr] = allConceptGrammarsAll[curr];
    return acc;
  }, {});

  const handleStateTypeChange = (val) => {
    setStateType(val);
    if (val === 'card') {
      setType('regular');
      setStateKey('');
      setFixedState({});
    }
    if (val === 'inherit') {
      setType('reference');
      setStateKey('');
      setStateId(null);
      setFixedState({});
    }
    if (val === 'fixed') {
      setType('regular');
      setStateKey('');
      setStateId(null);
    }
  };

  const onFixedStateAdd = () => {
    const id = uuid();
    let longestId, longestCount;
    for (const [key, values] of Object.entries(fixedState)) {
      const valuesLength = Object.keys(values).length;
      if (!longestId || valuesLength > longestCount) {
        longestId = key;
        longestCount = valuesLength;
        continue;
      }
    }
    if (!longestId) {
      return setFixedState({ ...fixedState, [id]: { _uid: id, _meta: {} } });
    }
    const newValues = { ...fixedState[longestId] };
    newValues._uid = id;
    for (const [key, item] of Object.entries(newValues)) {
      if (typeof item === 'string' || typeof item === 'number') newValues[key] = '';
      else if (Array.isArray(item)) newValues[key] = [];
    }
    setFixedState({ ...fixedState, [id]: newValues });
  };
  const onFixedStateRemove = (id) => {
    const newFixedState = { ...fixedState };
    delete newFixedState[id];
    setFixedState(newFixedState);
  };
  const onFixedStateAddField = (id, name = 'name', value = 'value') => {
    const newFixedState = { ...fixedState };
    newFixedState[id][name] = value;
    setFixedState(newFixedState);
  };
  const onFixedStateFieldNameChange = (id, oldName, newName) => {
    const newFixedState = { ...fixedState };
    if (newFixedState[id][newName]) {
      return notification.error({
        message: 'Name occupied',
        description: 'Remove the existing entry (name) first',
      });
    }
    const value = newFixedState[id][oldName];
    delete newFixedState[id][oldName];
    newFixedState[id][newName] = value;
    setFixedState(newFixedState);
  };
  const onFixedStateFieldValueChange = (id, name, newValue) => {
    const newFixedState = { ...fixedState };
    newFixedState[id][name] = newValue;
    setFixedState(newFixedState);
  };
  const onFixedStateRemoveField = (id, name) => {
    const newFixedState = { ...fixedState };
    delete newFixedState[id][name];
    setFixedState(newFixedState);
  };
  const onFixedStateChangeType = (id, name, values) => {
    const newFixedState = { ...fixedState };
    const { type, value } = values;
    switch (type) {
      case 'boolean':
        newFixedState[id][name] = value === 'true' ? true : false;
        break;
      case 'array':
        try {
          const arr = JSON.parse(value.replace(/'/g, '"'));
          if (Array.isArray(arr)) newFixedState[id][name] = arr;
          else newFixedState[id][name] = [];
        } catch (err) {
          newFixedState[id][name] = [];
        }
        break;
      case 'object':
        try {
          const obj = JSON.parse(value.replace(/'/g, '"'));
          if (obj && typeof obj === 'object') newFixedState[id][name] = obj;
          else newFixedState[id][name] = {};
        } catch (err) {
          newFixedState[id][name] = {};
        }
        break;
      case 'text':
      default:
        newFixedState[id][name] = value || '';
        break;
    }
    setFixedState(newFixedState);
  };

  const selectModelFrom = (id) => {
    if (!id) copyFromTemplate();
    const templateValues =
      id &&
      allConceptGrammars[id] &&
      allConceptGrammars[id].values &&
      allConceptGrammars[id].values[language];
    if (templateValues) copyFromTemplate(id, templateValues);
  };

  const copyFromTemplate = (id, templateValues) => {
    // if (id) setId(id)
    setBase(templateValues?.base || '');
    // setType('regular');
    setGender(templateValues?.gender);
    setSingularPrefix(templateValues?.singular?.prefix || '');
    setSingularSuffix(templateValues?.singular?.suffix || '');
    setSingularPrefixSpace(templateValues?.singular?.prefixSpace || false);
    setSingularSuffixSpace(templateValues?.singular?.suffixSpace || false);

    setSingularFixedPrefix(templateValues?.singularFixed?.prefix || '');
    setSingularFixedSuffix(templateValues?.singularFixed?.suffix || '');
    setSingularFixedPrefixSpace(templateValues?.singularFixed?.prefixSpace || false);
    setSingularFixedSuffixSpace(templateValues?.singularFixed?.suffixSpace || false);

    setPluralPrefix(templateValues?.plural?.prefix || '');
    setPluralSuffix(templateValues?.plural?.suffix || '');
    setPluralPrefixSpace(templateValues?.plural?.prefixSpace || false);
    setPluralSuffixSpace(templateValues?.plural?.suffixSpace || false);

    setPluralFixedPrefix(templateValues?.pluralFixed?.prefix || '');
    setPluralFixedSuffix(templateValues?.pluralFixed?.suffix || '');
    setPluralFixedPrefixSpace(templateValues?.pluralFixed?.prefixSpace || false);
    setPluralFixedSuffixSpace(templateValues?.pluralFixed?.suffixSpace || false);
  };

  const buildConceptGrammar = () => {
    return {
      [language]: {
        base,
        gender,
        singular: {
          prefix: singularPrefix.trim(),
          suffix: singularSuffix.trim(),
          prefixSpace: singularPrefixSpace,
          suffixSpace: singularSuffixSpace,
        },
        singularFixed: {
          prefix: singularFixedPrefix.trim(),
          suffix: singularFixedSuffix.trim(),
          prefixSpace: singularFixedPrefixSpace,
          suffixSpace: singularFixedSuffixSpace,
        },
        plural: {
          prefix: pluralPrefix.trim(),
          suffix: pluralSuffix.trim(),
          prefixSpace: pluralPrefixSpace,
          suffixSpace: pluralSuffixSpace,
        },
        pluralFixed: {
          prefix: pluralFixedPrefix.trim(),
          suffix: pluralFixedSuffix.trim(),
          prefixSpace: pluralFixedPrefixSpace,
          suffixSpace: pluralFixedSuffixSpace,
        },
      },
    };
  };

  const useConcept = {
    id,
    label: { [language]: label },
    type,
    stateId,
    stateKey,
    definitionKey,
    contractParty,
    grammar: buildConceptGrammar(),
  };
  if (stateType === 'inherit') {
    useConcept.inheritance = {
      inherit,
      filterDuplicates,
    };
  }
  if (Object.keys(fixedState).length > 0) {
    useConcept.state = fixedState;
  }

  // console.log('Concept so far ', useConcept);

  const saveConcept = () => {
    if (!id) return;

    const { grammar, ...contractConcept } = useConcept;

    if (conceptStates && setConceptStates) {
      const conceptGrammar = { ...conceptStates.conceptGrammar, [id]: { values: grammar } };
      let newConcepts = [...conceptStates.concepts];
      const index = conceptStates.concepts.findIndex((x) => x.id === useConcept.id);
      if (index > -1) {
        newConcepts = conceptStates.concepts.map((c) => {
          if (c.id === id) {
            return {
              ...c,
              ...contractConcept,
            };
          }
          return c;
        });
      } else {
        newConcepts.push(contractConcept);
      }
      setConceptStates({ conceptGrammar, concepts: newConcepts });
      return;
    } else {
      if (!contract.data.create.build.conceptGrammar) contract.data.create.build.conceptGrammar = {};
      contract.data.create.build.conceptGrammar[id] = {
        values: grammar,
      };
      const index = contract.data.create.build.concepts.findIndex((x) => x.id === useConcept.id);
      if (index > -1) {
        contract.data.create.build.concepts[index] = {
          ...contract.data.create.build.concepts[index],
          ...contractConcept,
        };
      } else {
        contract.data.create.build.concepts.push(contractConcept);
      }
    }

    toggleModal();
  };

  return (
    <div className="px-2">
      {false && !defaultId && (
        <>
          <Divider orientation="left">Quick Start</Divider>
          <Row className="m-0">
            <Col span={24}>
              <Form layout="vertical">
                <Form.Item label="Copy Concept Settings">
                  <Select
                    onChange={(val) => {
                      selectModelFrom(val);
                      setTemplateConcept(val);
                    }}
                    value={templateConcept}
                    className="w-100"
                  >
                    <Option value={null}>
                      <em>None</em>
                    </Option>
                    {Object.keys(allConceptGrammars).map((concept) => {
                      return (
                        <Option key={concept} value={concept}>
                          {concept}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </>
      )}
      <Divider orientation="left">General</Divider>
      <Row className="m-0">
        <Col sm={24} md={12} lg={8} className="px-2">
          <Form layout="vertical">
            <Form.Item
              label="Id"
              rules={[
                {
                  required: true,
                  message: 'Please enter an Id',
                },
              ]}
              className="w-100"
            >
              <Input
                type="text"
                disabled={mode === 'edit'}
                name="id"
                value={id}
                onChange={(e) => setId(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Col>
        <Col sm={24} md={12} lg={8} className="px-2">
          <Form layout="vertical">
            <Form.Item
              label="Label"
              rules={[
                {
                  required: true,
                  message: 'Please enter a label',
                },
              ]}
              className="w-100"
            >
              <Input type="text" name="label" value={label} onChange={(e) => setLabel(e.target.value)} />
            </Form.Item>
          </Form>
        </Col>
        <Col sm={24} md={12} lg={8} className="px-2">
          <Form layout="vertical">
            <Form.Item
              label="Is Contract Party"
              className="w-100"
              valuePropName="checked"
              extra={'Each instance is automatically considered to be a contract party'}
            >
              <Checkbox
                name="contractParty"
                checked={contractParty}
                onChange={(e) => setContractParty(e.target.checked)}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Divider orientation="left">State / Input Connection</Divider>
      <Row className="m-0">
        <Col sm={24} md={8} lg={6} className="px-2">
          <Form layout="vertical">
            <Form.Item label="Derive concept state from" className="w-100">
              <Select onChange={handleStateTypeChange} value={stateType}>
                <Option value="card">Card</Option>
                <Option value="inherit">Inherit from other cards</Option>
                <Option value="fixed">Pre-defined state</Option>
              </Select>
            </Form.Item>
          </Form>
        </Col>
        {stateType === 'card' && (
          <>
            <Col sm={24} md={12} lg={8} className="px-2">
              <Form layout="vertical">
                <Form.Item
                  label="Card id"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a card',
                    },
                  ]}
                  className="w-100"
                >
                  <Select
                    onChange={(val) => {
                      setStateId(val);
                    }}
                    value={stateId}
                  >
                    {cardsWithState.map((card) => {
                      return (
                        <Option key={card.id} value={card.id}>
                          <div className="option-multi-line">
                            <div>{card.header[language]}</div>
                            {Array.isArray(card._pages) && (
                              <div>
                                <small>
                                  (in{' '}
                                  {card._pages.map((page, index) => {
                                    if (!page.header) console.log('no page header ', page);
                                    return (
                                      <span key={page.id}>
                                        {page.header[language]}{' '}
                                        {index + 1 !== card._pages.length && (
                                          <i className="ml-1 mr-1 mdi mdi-arrow-right" />
                                        )}
                                      </span>
                                    );
                                  })}
                                  )
                                </small>
                              </div>
                            )}
                          </div>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            <Col sm={24} md={8} lg={6} className="px-2">
              <Form layout="vertical">
                <Form.Item
                  label="Refer to by Input Field"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a field',
                    },
                  ]}
                  className="w-100"
                  extra={
                    (!stateId ||
                      !Contract.getUiCard(contract, stateId) ||
                      Contract.getUiCard(contract, stateId).inputs_order.length === 0) &&
                    'Once you have added input fields to this box, come back and select one'
                  }
                >
                  <Select
                    onChange={(val) => {
                      setStateKey(val);
                    }}
                    value={stateKey}
                    disabled={!stateId || !Contract.getUiCard(contract, stateId)}
                  >
                    {stateId &&
                      Contract.getUiCard(contract, stateId) &&
                      Contract.getUiCard(contract, stateId).inputs_order.map((fieldName) => {
                        const input = Contract.getUiInput(contract, fieldName);
                        if (!VALUE_INPUTS.includes(input.type)) return null;

                        return (
                          <Option key={fieldName} value={fieldName}>
                            {input.label[language]}
                            {input.type === 'party' && <small className="ml-1">(entity)</small>}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </>
        )}
        {stateType === 'inherit' && (
          <>
            <Col sm={24} md={10} lg={10} className="px-2">
              <Form layout="vertical">
                <Form.Item label={'Inherit from the following cards'} value={inherit}>
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={'Type card id'}
                    onChange={setInherit}
                    value={inherit}
                  >
                    {concepts
                      .filter((c) => c.id !== id && c.stateId)
                      .map((concept) => (
                        <Option key={concept.id} value={concept.id}>
                          {concept.id}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            <Col sm={12} md={6} lg={8} className="px-2">
              <Form layout="vertical">
                <Form.Item label="Filter out duplicate entries" className="w-100">
                  <Checkbox
                    onChange={(evt) => setFilterDuplicates(evt.target.checked)}
                    checked={filterDuplicates}
                  >
                    Yes
                  </Checkbox>
                </Form.Item>
              </Form>
            </Col>
          </>
        )}
        {stateType === 'fixed' && (
          <Col sm={24} md={12} lg={8} className="px-2">
            <Form layout="vertical">
              <Form.Item
                label="Refer to by Input xField"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a field',
                  },
                ]}
                className="w-100"
                extra={'Choose from the fields you create below'}
              >
                <Select
                  onChange={(val) => {
                    setStateKey(val);
                  }}
                  value={stateKey}
                  disabled={!fixedState || Object.keys(fixedState).length === 0}
                >
                  {fixedState &&
                    Object.keys(fixedState).length > 0 &&
                    [
                      ...new Set(
                        Object.entries(fixedState)
                          .map(([, values]) => Object.keys(values))
                          .flat()
                      ),
                    ]
                      .filter((x) => x.substr(0, 1) !== '_')
                      .map((fieldName) => {
                        return (
                          <Option key={fieldName} value={fieldName}>
                            {fieldName}
                          </Option>
                        );
                      })}
                </Select>
              </Form.Item>
            </Form>
          </Col>
        )}
      </Row>
      {stateType === 'fixed' && (
        <Row className="px-4">
          <Col sm={24} md={24} lg={24}>
            <div className="d-flex justify-content-space-between align-items-center">
              <div>
                <h4>State</h4>
              </div>
              <div>
                <Button type="primary" onClick={onFixedStateAdd} className="m-0">
                  Add State Entry
                </Button>
              </div>
            </div>
            <div>
              <Collapse bordered={false} defaultActiveKey={[]}>
                {Object.keys(fixedState).map((key, index) => {
                  const stateEntry = fixedState[key];
                  const arrayEntry = Object.entries(stateEntry)
                    .filter((e) => e[0].substr(0, 1) !== '_')
                    .map((e) => ({ name: e[0], value: e[1] }));
                  return (
                    <Panel
                      header={'Entry ' + (index + 1)}
                      key={key}
                      className="d-block"
                      extra={
                        <>
                          <Button
                            size="small"
                            className="m-0 mr-2"
                            onClick={(evt) => {
                              onFixedStateAddField(key, 'new-item', '');
                              evt.stopPropagation();
                            }}
                          >
                            Add field to entry
                          </Button>
                          <Button size="small" className="m-0" onClick={() => onFixedStateRemove(key)}>
                            Remove Entry
                          </Button>
                        </>
                      }
                    >
                      <Table
                        size="small"
                        pagination={false}
                        columns={[
                          {
                            title: 'Name',
                            dataIndex: 'name',
                            render: (text, record) => {
                              return (
                                <Paragraph
                                  editable={{
                                    onChange: (str) => onFixedStateFieldNameChange(key, record.name, str),
                                  }}
                                >
                                  {record.name}
                                </Paragraph>
                              );
                            },
                          },
                          {
                            title: 'Value',
                            dataIndex: 'value',
                            render: (text, record) => {
                              const { value } = record;
                              if (typeof value === 'string') {
                                return (
                                  <Paragraph
                                    editable={{
                                      onChange: (str) => onFixedStateFieldValueChange(key, record.name, str),
                                    }}
                                  >
                                    {record.value}
                                  </Paragraph>
                                );
                              } else if (Array.isArray(value)) {
                                return (
                                  <Select
                                    mode="tags"
                                    style={{ width: '100%' }}
                                    placeholder={'Enter values'}
                                    value={value}
                                    onChange={(strs) => onFixedStateFieldValueChange(key, record.name, strs)}
                                  >
                                    {value.map((val, index) => (
                                      <Option key={index} value={value}>
                                        {value}
                                      </Option>
                                    ))}
                                  </Select>
                                );
                              } else if (value && typeof value === 'object') {
                                return <em>object value</em>;
                              } else if (typeof value === 'boolean') {
                                return (
                                  <>
                                    <span
                                      className={
                                        'px-2 py-1 mr-2 ' + (value ? 'bg-blue text-white' : 'clickable')
                                      }
                                      style={{ borderRadius: '3px' }}
                                      onClick={
                                        value
                                          ? null
                                          : () => onFixedStateFieldValueChange(key, record.name, true)
                                      }
                                    >
                                      true
                                    </span>
                                    <span
                                      className={'px-2 py-1 ' + (!value ? 'bg-blue text-white' : 'clickable')}
                                      style={{ borderRadius: '3px' }}
                                      onClick={
                                        !value
                                          ? null
                                          : () => onFixedStateFieldValueChange(key, record.name, false)
                                      }
                                    >
                                      false
                                    </span>
                                  </>
                                );
                              } else if (!value) {
                                return (
                                  <em className="text-muted">
                                    <Paragraph
                                      editable={{
                                        onChange: (str) =>
                                          onFixedStateFieldValueChange(key, record.name, str),
                                      }}
                                    >
                                      empty
                                    </Paragraph>
                                  </em>
                                );
                              }
                            },
                          },
                          {
                            title: 'Action',
                            render: (text, record) => {
                              return (
                                <div className="d-flex justify-content-center">
                                  <Button
                                    size="small"
                                    className="mb-0"
                                    onClick={() => onFixedStateRemoveField(key, record.name)}
                                  >
                                    Remove
                                  </Button>
                                  <Button
                                    size="small"
                                    className="mb-0"
                                    onClick={() => {
                                      const changeTypeConfirm = { current: null };
                                      changeTypeConfirm.current = Modal.confirm({
                                        content: (
                                          <>
                                            <Row>
                                              <Col span={24}>
                                                <Form
                                                  layout="vertical"
                                                  onFinish={(values) => {
                                                    onFixedStateChangeType(key, record.name, values);
                                                    changeTypeConfirm.current.destroy();
                                                  }}
                                                >
                                                  <Form.Item
                                                    label="Type"
                                                    name="type"
                                                    rules={[{ required: true, message: 'Missing type' }]}
                                                  >
                                                    <Select>
                                                      <Option value={'text'}>Text</Option>
                                                      <Option value={'array'}>List (array)</Option>
                                                      <Option value={'object'}>Container (object)</Option>
                                                      <Option value={'boolean'}>
                                                        True / false (boolean)
                                                      </Option>
                                                    </Select>
                                                  </Form.Item>
                                                  <Form.Item label="New Value" name="value">
                                                    <Input />
                                                  </Form.Item>
                                                  <Input.Group compact>
                                                    <Form.Item className="mr-3">
                                                      <Button
                                                        onClick={() => changeTypeConfirm.current.destroy()}
                                                      >
                                                        Cancel
                                                      </Button>
                                                    </Form.Item>
                                                    <Form.Item>
                                                      <Button type="primary" htmlType="submit">
                                                        Save
                                                      </Button>
                                                    </Form.Item>
                                                  </Input.Group>
                                                </Form>
                                              </Col>
                                            </Row>
                                          </>
                                        ),
                                        okButtonProps: { style: { display: 'none ' } },
                                        cancelButtonProps: { style: { display: 'none ' } },
                                      });
                                    }}
                                  >
                                    Change value type
                                  </Button>
                                </div>
                              );
                            },
                          },
                        ]}
                        dataSource={arrayEntry}
                        locale={{
                          emptyText: (
                            <span>
                              No entries.{' '}
                              <span
                                className="link"
                                onClick={() => onFixedStateAddField(key, 'new-item', '')}
                              >
                                Add an entry
                              </span>
                            </span>
                          ),
                        }}
                        rowKey={(row) => {
                          return row.name;
                        }}
                        bordered
                        // title={() => 'Entry ' + (index + 1)}
                      />
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
          </Col>
        </Row>
      )}
      <Divider orientation="left">Grammar</Divider>
      <Row>
        <Col sm={24} md={8} lg={6} className="px-2">
          <Form layout="vertical">
            <Form.Item label="Grammar template">
              <Select
                onChange={(val) => {
                  selectModelFrom(val);
                  setTemplateConcept(val);
                }}
                value={templateConcept}
                className="w-100"
              >
                <Option value={null}>
                  <em>None</em>
                </Option>
                {Object.keys(allConceptGrammars).map((concept) => {
                  return (
                    <Option key={concept} value={concept}>
                      {concept}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Form>
        </Col>
        <Col sm={24} md={8} lg={6} className="px-2">
          <Form layout="vertical">
            <Form.Item label="Base description" className="w-100">
              <Input type="text" name="base" value={base} onChange={(e) => setBase(e.target.value)} />
            </Form.Item>
          </Form>
        </Col>
        <Col sm={24} md={8} lg={6} className="px-2 ml-2">
          <Form layout="vertical">
            <Form.Item label="Gender" className="w-100">
              <Select
                onChange={(val) => {
                  setGender(val);
                }}
                value={gender}
              >
                <Option value={'r'}>Reale</Option>
                <Option value={'n'}>Neutrum</Option>
              </Select>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col span={12} className="small-contents">
          <Row className="mt-1">
            <Col span={24}>
              <h5>
                <strong>Singular (not fixed)</strong>
              </h5>
            </Col>
            <Col span={12}>
              <Form layout="vertical">
                <Form.Item label="Prefix" className="mb-1">
                  <Input
                    type="text"
                    name="singularPrefix"
                    value={singularPrefix}
                    onChange={(e) => setSingularPrefix(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={singularPrefixSpace}
                    onChange={(e) => {
                      setSingularPrefixSpace(e.target.checked);
                    }}
                  >
                    Add space after prefix
                  </Checkbox>
                </Form.Item>
              </Form>
            </Col>
            <Col span={12}>
              <Form layout="vertical">
                <Form.Item label="Suffix" className="mb-1">
                  <Input
                    type="text"
                    name="singularSuffix"
                    value={singularSuffix}
                    onChange={(e) => setSingularSuffix(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={singularSuffixSpace}
                    onChange={(e) => {
                      setSingularSuffixSpace(e.target.checked);
                    }}
                  >
                    Add space before suffix
                  </Checkbox>
                </Form.Item>
              </Form>
            </Col> 
          </Row>
          <Row className="mt-1">
            <Col span={24}>
              <h5>
                <strong>Singular (fixed)</strong>
              </h5>
            </Col>
            <Col span={12}>
              <Form layout="vertical">
                <Form.Item label="Prefix" className="mb-1">
                  <Input
                    type="text"
                    name="singularFixedPrefix"
                    value={singularFixedPrefix}
                    onChange={(e) => setSingularFixedPrefix(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={singularFixedPrefixSpace}
                    onChange={(e) => {
                      setSingularFixedPrefixSpace(e.target.checked);
                    }}
                  >
                    Add space after prefix
                  </Checkbox>
                </Form.Item>
              </Form>
            </Col>
            <Col span={12}>
              <Form layout="vertical">
                <Form.Item label="Suffix" className="mb-1">
                  <Input
                    type="text"
                    name="singularFixedSuffix"
                    value={singularFixedSuffix}
                    onChange={(e) => setSingularFixedSuffix(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={singularFixedSuffixSpace}
                    onChange={(e) => {
                      setSingularFixedSuffixSpace(e.target.checked);
                    }}
                  >
                    Add space before suffix
                  </Checkbox>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col span={24}>
              <h5>
                <strong>Plural (not fixed)</strong>
              </h5>
            </Col>
            <Col span={12}>
              <Form layout="vertical">
                <Form.Item label="Prefix" className="mb-1">
                  <Input
                    type="text"
                    name="pluralPrefix"
                    value={pluralPrefix}
                    onChange={(e) => setPluralPrefix(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={pluralPrefixSpace}
                    onChange={(e) => {
                      setPluralPrefixSpace(e.target.checked);
                    }}
                  >
                    Add space after prefix
                  </Checkbox>
                </Form.Item>
              </Form>
            </Col>
            <Col span={12}>
              <Form layout="vertical">
                <Form.Item label="Suffix" className="mb-1">
                  <Input
                    type="text"
                    name="pluralSuffix"
                    value={pluralSuffix}
                    onChange={(e) => setPluralSuffix(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={pluralSuffixSpace}
                    onChange={(e) => {
                      setPluralSuffixSpace(e.target.checked);
                    }}
                  >
                    Add space before suffix
                  </Checkbox>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col span={24}>
              <h5>
                <strong>Plural (fixed)</strong>
              </h5>
            </Col>
            <Col span={12}>
              <Form layout="vertical" className="mb-0">
                <Form.Item label="Prefix" className="mb-1">
                  <Input
                    type="text"
                    name="pluralFixedPrefix"
                    value={pluralFixedPrefix}
                    onChange={(e) => setPluralFixedPrefix(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={pluralFixedPrefixSpace}
                    onChange={(e) => {
                      setPluralFixedPrefixSpace(e.target.checked);
                    }}
                  >
                    Add space after prefix
                  </Checkbox>
                </Form.Item>
              </Form>
            </Col>
            <Col span={12}>
              <Form layout="vertical">
                <Form.Item label="Suffix" className="mb-1">
                  <Input
                    type="text"
                    name="pluralFixedSuffix"
                    value={pluralFixedSuffix}
                    onChange={(e) => setPluralFixedSuffix(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox
                    checked={pluralFixedSuffixSpace}
                    onChange={(e) => {
                      setPluralFixedSuffixSpace(e.target.checked);
                    }}
                  >
                    Add space before suffix
                  </Checkbox>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <h5 className="mt-2">
            Grammar Preview <small>(of a few selected grammar variants)</small>
          </h5>
          <div className="px-3 mt-3 border">
            {id && <DescribeConcept contract={contract} concept={useConcept} id={id} language={language} />}
          </div>
        </Col>
      </Row>
      <Row className="mt-2 mb-2">
        <Button block type="primary" onClick={saveConcept} disabled={!id}>
          {mode === 'edit' ? 'Update Concept' : 'Create Concept'}
        </Button>
      </Row>
    </div>
  );
}

function DescribeConcept({ contract, concept, id, language }) {
  const values = describeAllOfConcept(id, {
    language,
    contract,
    useConcept: { id: concept.id, values: concept.grammar },
    relevantVariationNames: ['a', 'the', 'each', 'any', 'all'],
  });

  // console.log('Concept for describe ? ', concept);

  return (
    <table className="concept-variations-table mt-4" style={{ width: '100%', fontSize: '0.8em' }}>
      <tbody>
        <React.Fragment key={id}>
          <tr className="concept-row-x border-bottom">
            <td className="concept_id_cell-x">Key</td>
            <td>
              <span className="text">One instance</span>
            </td>
            <td>
              <span className="text">Many instances</span>
            </td>
          </tr>
          {values.map((conceptValue) => (
            <React.Fragment key={conceptValue.key}>
              <tr className="spacer">
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {Object.keys(conceptValue.languages).map((language) => {
                const value = conceptValue.languages[language];
                return (
                  <React.Fragment key={conceptValue.fullKey + '_' + language}>
                    <tr>
                      <td>{conceptValue.fullKey}</td>
                      <td className="text_cell">{value.one.text}</td>
                      <td className="text_cell">{value.many.text}</td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ))}
          <tr className="spacer">
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </React.Fragment>
      </tbody>
    </table>
  );
}
