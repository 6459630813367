import { useState } from 'react';
import { SearchBar } from './SearchBar';
import { SearchResults } from './SearchResults';
import './search.less';

export default function Search() {
  const [searchString, setSearchString] = useState('');
  const abort = () => setSearchString('');
  return (
    <div className="main-page-search">
      <div className={`mt-search ${searchString ? 'is-searching' : ''}`}>
        <SearchBar onChange={setSearchString} />
        <SearchResults searchString={searchString} />
      </div>
      <div className={`mt-search-wrapper ${searchString ? 'is-searching' : ''}`} onClick={abort}></div>
    </div>
  );
}
