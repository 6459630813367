import { Editor } from 'slate';
import { array_homogenous } from 'core/utils/general'

export const hasFontSizes = (editor) => {
    const [...sizes] = Editor.nodes(editor, {match: node => node.size, mode: 'lowest'})
    if(sizes.length === 0) return 'default'
    if(sizes.length > 1) {
        const allSame = array_homogenous(sizes.map(([node]) => node.size))
        if (allSame) return sizes[0][0].size
        return 'multiple'
    }
    return sizes[0][0].size
}
