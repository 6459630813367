import { cloneElement, useEffect, useState } from 'react';
import { AutoComplete } from 'antd';
import api, { generateDebouncer } from 'utils/api';

const debounce = generateDebouncer()
let apiSearchValue = null;

function valueToFieldKey(value) {
  const isStringSearch = isNaN(parseInt(value));
  if (isStringSearch) return 'name';
  return 'identificationNumber';
}

export const AutoCompleteEntity = ({
  field,
  autoDetectField = false,
  inactive = false,
  onSearchSelect = () => {},
  children,
  ...inheritedProps
}) => {
  const [options, setOptions] = useState([]);
  const [displayOptions, setDisplayOptions] = useState([]);
  const [oldOptions, setOldOptions] = useState({});
  const [search, setSearch] = useState(null);
  const [pendingRequest, setPendingRequest] = useState(false);

  if (!autoDetectField && !field) throw new Error('Missing required property "field"');

  useEffect(() => {
    const filteredOptions = getMergedOptions().filter(filterOption);
    setDisplayOptions(filteredOptions);
  }, [search, options, oldOptions]);

  const setSearchValues = (value) => {
    apiSearchValue = value;
    setSearch(value);
    setPendingRequest(true);
  };

  const handleSearch = async (value) => {
    if (value.length === 0) return setSearch(null);

    const fieldKey = autoDetectField ? valueToFieldKey(value) : field;

    setSearchValues(value);
    debounce(async () => {
      const body = { [fieldKey]: apiSearchValue };
      const result = await api.post('/entities/autocomplete', body);
      if (result.status === 200) {
        const options = parseResult(result.data, fieldKey);
        setOptions(options);
        setOldOptions({ ...oldOptions, [body[fieldKey]]: options });
      }
      setPendingRequest(false);
    });
  };

  const getMergedOptions = () => {
    if (!appendingOldOptions()) return options;
    const useOptions = Object.values(oldOptions).reduce((store, options) => {
      for (const option of options)
        if (!store.some(({ value }) => option.value === value)) store.push(option);
      return store;
    }, options);
    return useOptions;
  };

  const filterOption = (option) => {
    if (search === null) return false;
    if (pendingRequest || appendingOldOptions()) {
      const chunks = search
        .split(/[ -]/)
        .map((chunk) => `(?=.*${chunk})`)
        .join('');
      return !!option.value.match(new RegExp(chunks, 'i'));
    }
    return true;
  };

  const appendingOldOptions = () => {
    return options.length === 0;
  };

  const parseResult = (result, fieldKey = field) => {
    return result.map((data, i) => {
      const { identificationNumber, name } = data;
      return {
        key: `${identificationNumber}-${name}`,
        value: data[fieldKey],
        data: data,
        label: (
          <div>
            <p style={{ marginBottom: 4 }}>{name}</p>
            <div style={{ marginTop: -10, fontWeight: 700 }}>
              <small>{identificationNumber}</small>
            </div>
          </div>
        ),
      };
    });
  };

  if (inactive) return cloneElement(children, { ...inheritedProps });

  return (
    <AutoComplete
      options={displayOptions}
      onSelect={(value, { data }) => onSearchSelect(data)}
      onSearch={handleSearch}
      dropdownMatchSelectWidth={false}
      {...inheritedProps}
    >
      {children}
    </AutoComplete>
  );
};
