export const listPledgorsModuleSlate = {
  id: 'listPledgorsModuleSlate',
  time: 1,
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true },
  match: ({ entry, node, api }) => {
    return api.utils.engine.matchModule(node, 'securityAgreement', 'listPledgors');
  },
  handler: function ({ state, handlerInvoked, api, matches, editor }) {
    if (!this.contract.data.outline || this.contract.data.outline.id_name !== 'multi_pledge_shb') return;

    const affectedConcepts = handlerInvoked.repeatableChange
      ? this._tmpDraft.allAffectedACP.changesAffectingDetailedFieldMapping
      : this._tmpDraft.allAffectedACP.changesInConceptNumber;

    if (!affectedConcepts || !affectedConcepts.includes('pledgor')) return;

    const pledgors = getPledgors(this.contract, state, api);

    if (!pledgors || pledgors.length < 1) {
      // this.log('No pledgors found');
      return;
    }

    const newTable = this.makeTable(pledgors.map((item) => [item.name, item.identificationNumber || '[**]']));

    for (const { node, actions } of matches.default) {
      let newTableChildren;
      if (node.children[0]) {
        newTableChildren = [node.children[0], ...newTable.children];
      } else {
        newTableChildren = newTable.children;
      }

      actions.replaceChildren(newTableChildren);
    }
  },
};

function getPledgors(contract, state, api) {
  const concepts = api.interfaces.Contract.getConcepts(contract);
  if (!concepts) return [];
  const pledgorConcept = concepts.find((concept) => concept.id === 'pledgor');
  return api.interfaces.Concept.getConceptEntities(pledgorConcept, contract, state) || [];
}
