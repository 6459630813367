import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { setAuthToken } from "utils/auth";

import jwt_decode from "jwt-decode";
import api from "utils/api";

import { SIGNIN_USER, SIGNOUT_USER, SIGNUP_USER } from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
} from "../../appRedux/actions/Auth";

const DEBUG = process.env.REACT_APP_NODE_ENV === "development";

const createUserWithEmailPasswordRequest = async (email, password) => null;
/* await  auth.createUserWithEmailAndPassword(email, password)
    .then(user => user)
    .catch(error => error);
 */

const signInUserWithEmailPasswordRequest = async (email, password) => {
  const user = await api
    .post("/users/login2", { email, password })
    .then((result) =>
      result && result.data ? { success: result.data } : { message: "Unknown sign in error" }
    )
    .catch((error) =>
      error.response && error.response.data && error.response.data.errorMessage
        ? { message: error.response.data.errorMessage }
        : { message: "Unknown sign in error" }
    );

  return user;
};

/* const signOutRequest = async () => {
  await  auth.signOut()
    .then(user => user)
    .catch(error => error);

} */

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else if (signInUser.success && signInUser.success.token) {
      localStorage.setItem("jwtToken", signInUser.success.token);
      localStorage.setItem("refToken", signInUser.success.ref_token);
      setAuthToken(signInUser.success.token);
      const user = jwt_decode(signInUser.success.token);
      if (DEBUG) console.log("Successful Login 1. ", { user });
      yield put(userSignInSuccess(user));
    } else {
      localStorage.setItem("user_id", signInUser.user.id);
      yield put(userSignInSuccess(signInUser.user));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("refToken");
    setAuthToken(null);
    yield put(userSignOutSuccess());
    /*
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem('token');
      localStorage.removeItem('jwtToken');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
    */
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser), fork(createUserAccount), fork(signOutUser)]);
}
