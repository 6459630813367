import { InputPaths } from '../../../interfaces';
import { getByPath } from '../../../utils/general';

// Find out if this node/element is within an `each_repeatable` and/or each_multipleBlock.
// If so, add the each_repeatable's item state as localState.
export const getLocalState = (node, parents, state, contract, options = {}) => {
  if (!contract) {
    console.log('Contract required for getLocalState');
    return null;
  }
  const { debug } = options;

  try {
    const parentsAndNode = [node, ...parents.map(({ node }) => node)];

    for (const parent of parentsAndNode) {
      if (parent.data && parent.data._path) {
        if (debug) {
          console.log('Parent id for node... ', parent.data._path);
        }
        const paths = InputPaths.extractPathParts(contract, parent.data._path);
        const localStates = paths.reduce((acc, curr) => {
          acc = { ...acc, ...getByPath(state, curr) };
          return acc;
        }, {});
        return localStates;
      }
    }
  } catch (err) {
    console.log('Bugg i getLocalState ', { parents, node });
    throw new Error(err);
  }
  return null;
};
