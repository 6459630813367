import { useEffect, useRef } from 'react';
import { Form, Input } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Label, AutoCompleteEntity } from '.';

export const EntityEditFieldFirstname = ({
    entityType,
    autoSelectName,
    disabled,
    required = true,
    onSearchSelect = () => {},
    searchData = {},
    ...rest
  }) => {
  const formatMessage = useIntlMessage();
  const firstNameRef = useRef();

  useEffect(() => {
    if (autoSelectName && firstNameRef.current) {
      setTimeout(() => {
        firstNameRef.current.focus();
        firstNameRef.current.select();
      }, 10);
    }
  }, [autoSelectName, firstNameRef]);

  return (
    <Form.Item
      {...rest}
      label={
        <Label>
          {entityType === 'LegalPerson' ? (
            <IntlMessages id="app.general.Name" />
          ) : (
            <IntlMessages id="app.general.FirstName" />
          )}
        </Label>
      }
      name="firstName"
      rules={[
        {
          required,
          whitespace: true,
          message: formatMessage('app.persons.validation.firstName'),
        },
      ]}
    >

      <AutoCompleteEntity
        field='name'
        inactive={entityType !== 'LegalPerson'}
        onSearchSelect={onSearchSelect}
        disabled={disabled}
      >
        <Input ref={firstNameRef} />
      </AutoCompleteEntity>
    </Form.Item>
  );
};
