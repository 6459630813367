import React, { useCallback, useMemo, useRef } from 'react';
import { Editor, Element } from 'slate';
import { useSlate } from 'slate-react';
import { Breadcrumb } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { BlockMenuContent } from '../elements/render/blocks';

function getElements(editor, elementsRef) {
  if (!editor.selection || !editor.selection.anchor) {
    return elementsRef.current;
  }
  const elements = Array.from(Editor.nodes(editor)).filter(([element]) => Element.isElement(element));
  elementsRef.current = elements;
  return elements;
}

export default function BlockItems({ contract, ...rest }) {
  const editor = useSlate();
  const elementsRef = useRef([]);

  const elements = getElements(editor, elementsRef);

  const elementsPaths = elements.map((tuple) => tuple[1]).join('_');

  const handleMouseEnter = useCallback(
    (element) => {
      editor.highlightNode(element);
    },
    [editor]
  );
  const handleMouseLeave = useCallback(() => {
    editor.clearHighlights();
  }, [editor]);

  const items = useMemo(() => {
    const setIsHidingMenu = () => {};
    if (elements.length === 0) return [];
    return elements.map(([element, path]) => (
      <Breadcrumb.Item
        key={path.toString()}
        onMouseEnter={() => handleMouseEnter(element)}
        onMouseLeave={handleMouseLeave}
        overlay={
          <BlockMenuContent
            key={path.toString()}
            editor={editor}
            element={element}
            setIsHidingMenu={setIsHidingMenu}
          />
        }
      >
        <IntlMessages id={'contract.content.types.' + element.type} />
      </Breadcrumb.Item>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsPaths]);

  if (items.length > 0) {
    return (
      <Breadcrumb className="d-flex align-items-center" separator="-">
        {items}
      </Breadcrumb>
    );
  }

  return <div>No items</div>;
}
