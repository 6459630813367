import uuid from 'uuid-random';

const CARD_NAME = 'marginRatchet';
const TRIGGER_FIELD_NAME = 'marginRatchet//ratchet_levels';
const TARGET_FIELD_NAME = 'marginRatchet/ratchet';

const updateRatchetListValues = (data, state) => {
  const { value } = data;
  if (!Array.isArray(value) || value.length < 2) return;
  const sortedValues = value.sort().reverse();
  const lastIndex = value.length - 1;
  const secondLastIndex = value.length - 2;
  const lastItem = sortedValues[lastIndex];
  const secondLastItem = sortedValues[secondLastIndex];
  const newValues = sortedValues.map((value, index) => {
    if (index === 0) {
      return { id: uuid(), value: `X>${value}:1` };
    } else if (index === lastIndex) {
      return { id: uuid(), value: `X≤${value}:1` };
    } else {
      const prev = sortedValues[index - 1];
      return { id: uuid(), value: `${value}:1<X≤${prev}:1` };
    }
  });
  newValues.splice(lastIndex, 0, { id: uuid(), value: `${lastItem}:1<X≤${secondLastItem}:1` });

  return [
    {
      path: `input.${CARD_NAME}.${TARGET_FIELD_NAME}`,
      value: newValues,
    },
  ];
};

const inputs = [
  {
    trigger: `${CARD_NAME}->${TRIGGER_FIELD_NAME}`,
    mode: 'single',
    function: updateRatchetListValues,
    info: {
      name: 'Propose ratchet',
      category: 'legal',
    },
  },
];

export default inputs;
