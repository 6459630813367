import { useState, useMemo } from 'react';
import { Modal, Form, Button, Tooltip } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Entity } from 'core/interfaces';
import { AddOrSelectComponent, AgentCapacities, Label } from '.';

const VALID_RELATION_TYPES = ['Agent', 'Principal'];
const plural = (relationType) => `${relationType}s`;
const related = (entity, relationType) => (entity ? entity[plural(relationType)] : []);

export function ListRelations({
  entity,
  relationType,
  save,
  addAgent,
  removeAgent,
  setAgentCapacities,
  addPrincipal,
  removePrincipal,
  setPrincipalCapacities,
}) {
  const [open, setOpen] = useState(false);
  const [isAddningNewEntity, setIsAddingNewEntity] = useState(false);

  if (!VALID_RELATION_TYPES.includes(relationType))
    throw new Error('Invalid relation relationType: ' + relationType);
  const isAgent = relationType === 'Agent';

  const formatMessage = useIntlMessage();

  const toggleOpen = () => setOpen(!open);

  const closeModalSearch = () => {
    setIsAddingNewEntity(false);
  };

  const addFn = isAgent ? addAgent : addPrincipal;
  const removeFn = isAgent ? removeAgent : removePrincipal;
  const setCapacitiesFn = isAgent ? setAgentCapacities : setPrincipalCapacities;

  const openNewRelatedEntityModal = async () => {
    if (!entity) {
      const doSave = async () => {
        const saveResult = await save();
        if (saveResult?.status === 'success') {
          setIsAddingNewEntity(true);
        }
      };
      return Modal.confirm({
        title: formatMessage('desc.Save'),
        icon: <SaveOutlined />,
        content: formatMessage('app.entity.saveRequired'),
        okText: formatMessage('desc.Save'),
        onOk: doSave,
        cancelText: formatMessage('desc.Cancel'),
      });
    }
    setIsAddingNewEntity(true);
  };

  const onAddRelation = (data) => {
    addFn(data.entity, data.capacities);
    setIsAddingNewEntity(false);
  };
  const onRemoveRelation = (removedEntity) => {
    removeFn(removedEntity);
  };

  const updateCapacities = (relatedEntity, capacities) => {
    setCapacitiesFn(relatedEntity, capacities);
  };

  const excludedFromSearch = useMemo(() => {
    if (!entity || !Array.isArray(related(entity, relationType))) return [];
    return related(entity, relationType).map((e) => e.id);
  }, [entity, relationType]);

  const relatedEntities = related(entity, relationType) || [];

  return (
    <>
      <div className="border p-2">
        <div>
          {relatedEntities && relatedEntities.length ? (
            <small className="link" onClick={toggleOpen}>
              {open ? <IntlMessages id="app.general.Hide" /> : <IntlMessages id="app.general.Show" />}
              <span className="mx-1">{relatedEntities.length}</span>
              <IntlMessages id={`app.entity.${plural(relationType)}`} />
            </small>
          ) : (
            <em>
              <IntlMessages id={`app.entity.no${plural(relationType)}`} />
            </em>
          )}
        </div>

        {open && (
          <>
            <div>
              {relatedEntities &&
                relatedEntities.length > 0 &&
                relatedEntities.map((existingRelatedEntity) => {
                  const p = isAgent ? entity : existingRelatedEntity;
                  const a = isAgent ? existingRelatedEntity : entity;

                  const relation = Entity.getRelation(p, a);

                  if (!relation) {
                    console.log('No relation found for ', { existingRelatedEntity, entity });
                    return null;
                  }

                  const { capacities } = relation;
                  return (
                    <div
                      key={existingRelatedEntity.id}
                      className="flex-column m-2"
                      style={{ fontSize: '12px' }}
                    >
                      <div className="d-flex justify-content-space-between">
                        <strong>{Entity.name(existingRelatedEntity)}</strong>
                        <Tooltip
                          title={<IntlMessages id={`app.entity.remove${relationType}`} />}
                          placement="top"
                        >
                          <span className="ml-3 link" onClick={() => onRemoveRelation(existingRelatedEntity)}>
                            <MinusCircleOutlined />
                          </span>
                        </Tooltip>
                      </div>
                      <AgentCapacities
                        value={capacities}
                        onChange={(values) => {
                          updateCapacities(existingRelatedEntity, values);
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
      {isAddningNewEntity && (
        <AddOrSelectComponent
          mode="addRelation"
          relationType={relationType}
          onCancel={closeModalSearch}
          onFinishCallback={onAddRelation}
          excludedFromSearch={excludedFromSearch}
          referringEntity={entity}
        />
      )}

      <Button
        type="secondary"
        block
        className="mb-0"
        icon={<PlusCircleOutlined />}
        onClick={openNewRelatedEntityModal}
        style={{ margin: '-1px 0 0 0' }}
      >
        <IntlMessages id={`app.entity.add${relationType}`} />
      </Button>
    </>
  );
}
