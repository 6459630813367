import React from 'react';
import { Col, Row, Tabs } from 'antd';
import FileList from 'components/File/FileList';
import IntlMessages from 'util/IntlMessages';

const TabPane = Tabs.TabPane;

export default function FileUpload({ data }) {
  const { projectId, documentId, clientId, connectedFiles, onConnectFiles, nodeType } = data;

  return (
    <Tabs>
      {documentId && (
        <TabPane tab={<IntlMessages id={'app.file.documentFiles'} />} key="1">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <FileList
                resourceId={documentId}
                resourceType="Document"
                apiRoute="documents"
                hideCard={true}
                tableType="connectTable"
                connectedFiles={connectedFiles}
                onConnectFiles={onConnectFiles}
                nodeType={nodeType}
                keepModalAfterUpload={true}
              />
            </Col>
          </Row>
        </TabPane>
      )}
      {projectId && (
        <TabPane tab={<IntlMessages id={'app.file.projectFiles'} />} key="2">
          <div className="mb-2">
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FileList
                  resourceId={projectId}
                  resourceType="Project"
                  apiRoute="projects"
                  hideCard={true}
                  tableType="connectTable"
                  connectedFiles={connectedFiles}
                  onConnectFiles={onConnectFiles}
                  hideNewUpload={true}
                  nodeType={nodeType}
                />
              </Col>
            </Row>
          </div>
        </TabPane>
      )}
      {clientId && (
        <TabPane tab={<IntlMessages id={'app.file.clientFiles'} />} key="3">
          <div className="mb-2">
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <FileList
                  resourceId={clientId}
                  resourceType="Client"
                  apiRoute="clients"
                  hideCard={true}
                  tableType="connectTable"
                  connectedFiles={connectedFiles}
                  onConnectFiles={onConnectFiles}
                  hideNewUpload={true}
                  nodeType={nodeType}
                />
              </Col>
            </Row>
          </div>
        </TabPane>
      )}
    </Tabs>
  );
}
