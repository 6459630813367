import React, { useState } from 'react';
import { Row, Col, Spin, Card, Table, Button, Dropdown, Menu, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import DocumentTemplateWidget from 'components/Widgets/DocumentTemplateWidget';
import NewDocumentTemplateButton from 'components/project/NewDocumentTemplate/NewDocumentTemplateButton';
import { useMountFetch, useSearch } from 'hooks';
import WidgetHeader from 'components/WidgetHeader/index';
import { fixDate } from 'components/ui';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import { RemoveModal } from 'components/ui';
import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { gutter } from 'config';

const { Column } = Table;

const DocumentTemplates = (props) => {
  const [templates, setTemplates] = useState(null);
  const [templatesError, setTemplatesError] = useState('');
  const [viewMode, setViewMode] = useState('list');

  const templateWasCreated = (template) => {
    setTemplates([...templates, template]);
  };
  const templateWasRemoved = (id) => {
    setTemplates([...templates.filter((template) => template.id !== id)]);
  };

  const showList = () => setViewMode('list');
  const showGrid = () => setViewMode('grid');

  useMountFetch(
    '/documenttemplates?fields=id,name,description,createdAt,isCoreTemplate',
    setTemplates,
    setTemplatesError
  );

  const regularTemplates = templates && templates.filter((template) => !template.isCoreTemplate).reverse();
  const coreTemplates = templates && templates.filter((template) => template.isCoreTemplate);

  const [filteredRegularTemplates, searchElement] = useSearch(regularTemplates || [], {
    element: true,
    fields: ['name', 'description'],
  });

  if (!templates) {
    return (
      <Card title="" className="card">
        <Spin className="loader-container" />
      </Card>
    );
  }

  if (templatesError) {
    return (
      <Card title="" className="card">
        <IntlMessages id="app.templates.errorRetrieving" />
      </Card>
    );
  }

  let content;
  if (filteredRegularTemplates.length === 0) {
    content = (
      <div>
        <IntlMessages id="app.templates.noTemplates" />
      </div>
    );
  } else if (viewMode === 'list') {
    content = (
      <div className="full-table-view transparent-th">
        <DocumentTemplateTable templates={filteredRegularTemplates} templateWasRemoved={templateWasRemoved} />
      </div>
    );
  } else if (viewMode === 'grid') {
    content = (
      <Row gutter={gutter}>
        {regularTemplates.map((template) => (
          <Col key={template.id} xl={6} lg={12} md={12} sm={12} xs={24}>
            <DocumentTemplateWidget
              template={template}
              templateWasCreated={templateWasCreated}
              templateWasRemoved={templateWasRemoved}
            />
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <div className="padded-page">
      <WidgetHeader
        title={<IntlMessages id="app.templates.templates" />}
        styleName="mb-3"
        extra={
          <>
            <span className="mr-3">
              <Tooltip title={'Show templates as list'} placement="bottom">
                <Button
                  size="middle"
                  className={'m-0 ' + (viewMode === 'list' ? 'active' : '')}
                  type="ghost"
                  onClick={showList}
                >
                  <UnorderedListOutlined />
                </Button>
              </Tooltip>
              <Tooltip title={'Show templates as grid'} placement="bottom">
                <Button
                  size="middle"
                  className={'m-0 ml-3 ' + (viewMode === 'grid' ? 'active' : '')}
                  type="ghost"
                  onClick={showGrid}
                >
                  <AppstoreOutlined />
                </Button>
              </Tooltip>
            </span>
            {searchElement}
            <NewDocumentTemplateButton
              coreTemplates={coreTemplates}
              templateWasCreated={templateWasCreated}
            />
          </>
        }
      />

      {content}
    </div>
  );
};

const config = {
  bordered: false,
  loading: false,
  pagination: { position: 'bottom' },
  size: 'default',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  scroll: undefined,
};

export function DocumentTemplateTable({ templates, templateWasRemoved }) {
  const intTypeCap = 'Template';

  const rowSelection = null;

  const removeTemplate = (id) => {
    api
      .delete('/documenttemplates/' + id)
      .then((result) => {
        templateWasRemoved(id);
      })
      .catch((err) => {
        console.log('Error removing project ', id, err.response);
      });
  };

  return (
    <Table
      rowKey="id"
      className="table-responsive ml-3"
      {...config}
      // columns={columns}
      rowSelection={rowSelection}
      dataSource={templates}
    >
      <Column
        title={<IntlMessages id="app.general.Name" />}
        dataIndex="name"
        key="name"
        render={(text, record) => (
          <Link
            to={{
              pathname: '/studio/template/' + record.id + '/input',
            }}
          >
            {text}
          </Link>
        )}
      />
      <Column
        title={<IntlMessages id="app.general.Description" />}
        dataIndex="description"
        key="description"
      />
      <Column
        title={<IntlMessages id="app.general.Created" />}
        dataIndex="createdAt"
        key="createdAt"
        render={(text) => fixDate(text)}
      />
      <Column
        title={<IntlMessages id="app.general.Action" />}
        key="actions"
        render={(text, record) => (
          <Button type="ghost" className="m-0">
            <Link
              to={{
                pathname: '/studio/template/' + record.id + '/input',
              }}
            >
              <IntlMessages id={'general.edit'} /> <IntlMessages id={'app.templates.template'} />
            </Link>
          </Button>
        )}
      />
      <Column
        title={<IntlMessages id="app.general.More" />}
        dataIndex={null}
        key="menu"
        render={(text, record) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="remove">
                  <RemoveModal
                    key="remove-template"
                    onConfirm={() => removeTemplate(record.id)}
                    confirmText={
                      <>
                        <IntlMessages id="app.general.confirmRemoval" />{' '}
                        <IntlMessages id={'app.resources.' + intTypeCap} />?
                      </>
                    }
                  >
                    <span className="">
                      <IntlMessages id="desc.Remove" /> <IntlMessages id={'app.resources.' + intTypeCap} />
                    </span>
                  </RemoveModal>
                </Menu.Item>
              </Menu>
            }
          >
            <span className="link ant-dropdown-link">
              <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
            </span>
          </Dropdown>
        )}
      />
    </Table>
  );
}

export default DocumentTemplates;
