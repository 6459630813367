import React from 'react';
import { connect } from 'react-redux';
import Chart from 'components/orgchart/orgchart';

import { updateInputs, addRepeatable, removeRepeatable } from 'appRedux/actions';

import { ofindKey } from 'core/utils/general';

const CardBody = ({ children, ...rest }) => {
  return <div {...rest}>{children}</div>;
};

const topRightIcon = ['mdi mdi-minus-circle-outline', 'color:red'];
const topLeftIcon = ['mdi mdi-plus-circle-outline', 'color:green'];

const mapStateToProps = (state) => ({
  entities: state.entities,
  input: state.input,
  state: state,
});
const mapDispatchToProps = (dispatch) => ({
  updateInputs: (...args) => dispatch(updateInputs(...args)),
  addRepeatable: (path, cardData) => dispatch(addRepeatable(path, cardData)),
  removeRepeatable: (path) => dispatch(removeRepeatable(path)),
});

let layout = [
  [
    {
      class: 'nodename',
      align: 'center',
      dataField: 'firstName',
    },
  ],
];

class OrgChart extends React.Component {
  constructor(props) {
    super(props);
    const { args } = this.props;
    this.metaData = this.props.entities.map((entity) => ({
      ...entity,
      metaData: {},
    })); // copy with empty object content

    this.chartId = 'sec_' + args.type;

    // Default target is pledgor.
    this.securityTarget = (args && args.security && args.security.target) || 'pledgor';
    // console.log('Security Target ', this.securityTarget);
  }

  getOrgChartRef = (ref) => {
    this.orgchart = ref;
  };

  // Share
  /*
  security: {
    target: "sharesecurity/pledged",
    assist: {
      find: [
        {
          path: "nodes",
          property: "id",
          equals: {
            source: "targetNode",
            property: "parentId",
          },
          use: {
            property: "name",
            target: "sharesecurity/pledgor",
          },
        },
      ],
    },
    concepts: {
      pledged: "pledged",
      pledgor: "pledgor",
    },
  },
  */

  addSecurity = (nodeId) => {
    const { type, /* security */ } = this.props.args;
    const target = this.props.entities.find((entity) => entity.id === nodeId);

    // console.log('target is ? ', { target, nodeId });

    const cardData = {
      [this.securityTarget]: {
        id: nodeId,
        type: 'entity',
      },
    };

    /* if (
      security &&
      security.assist &&
      security.assist.find &&
      Array.isArray(security.assist.find)
    ) {
      for (let find of security.assist.find) {
        if (!find.path || !find.property || !find.equals || !find.use) {
          console.log("Invalid find entry", find);
          continue;
        }
        const haystack = getByPath(this.props.state, find.path);
        if (!haystack) {
          console.log("Could find haystack (find assist)", find);
          continue;
        }
        const findAssistBy =
          (find.equals.property &&
            find.equals.source === "targetNode" &&
            target[find.equals.property]) ||
          false;
        if (!findAssistBy) {
          console.log("No findAssistBy found (find assist", find);
          continue;
        }
        if (Array.isArray(haystack)) {
          const assistItem = haystack.find((item) => item[find.property] === findAssistBy);
          if (!assistItem) {
            console.log("Could not find assistItem ", find, findAssistBy);
            continue;
          }

          cardData[find.use.target] = assistItem[find.use.property];
        }
      }
    } */
    if (this.securityTarget === 'sharesecurityPledged') {
      const parent = this.props.entities.find((entity) => entity.id === target.parentId);
      if (parent && parent.id) {
        cardData.sharesecurityPledgor = {
          id: parent.id,
          type: 'entity',
        };
      }
      if (target.jurisdiction === 'se' && (target.form === 'HB' || target.form === 'KB')) {
        cardData['sharesecurity/type'] = 'participations';
      }
    }
    this.props.addRepeatable('input.' + type, cardData);
  };
  removeSecurity = (nodeId) => {
    const { type } = this.props.args;
    if (!this.props.input[type]) {
      return console.log('No input for ', type);
    }
    const target = this.props.entities.find((node) => node.id === nodeId);
    if (!target || !target.name) {
      return console.log('Could not find pledged company');
    }

    if (!this.props.input[type]) {
      return console.log('No state to remove item from.');
    }

    const uid = ofindKey(
      this.props.input[type],
      (item) => item[this.securityTarget] && item[this.securityTarget].id === target.id
    );

    if (!uid) {
      return console.log('Could not locate index for ' + target.name + ' of security ' + type, target);
    }
    this.props.removeRepeatable('input.' + type + '.' + uid);
  };

  render() {
    const { type } = this.props.args;
    let meta;

    if (this.props.input[type]) {
      let newMeta = this.metaData.map((node, index) => {
        if (
          ofindKey(
            this.props.input[type],
            (item) => item[this.securityTarget] && item[this.securityTarget].id === node.id
          )
        ) {
          if (Array.isArray(node.metaData.classNames)) {
            return {
              ...node,
              metaData: {
                ...node.metaData,
                classNames: node.metaData.classNames.concat(['node-security']),
              },
            };
          } else {
            return { ...node, metaData: { ...node.metaData, classNames: ['node-security'] } };
          }
        }
        return { ...node };
      });
      meta = newMeta;
    } else {
      meta = this.metaData;
    }

    // console.log('meta is ', meta);

    return (
      <CardBody>
        {this.props.entities && this.props.entities.length > 0 ? (
          <React.Fragment>
            <Chart
              chartId={this.chartId}
              entities={this.props.entities}
              layout={layout}
              metaData={meta}
              clickNodeEvent={this.clickNodeEvent}
              topRightAction={this.removeSecurity}
              topRightIcon={topRightIcon}
              topLeftAction={this.addSecurity}
              topLeftIcon={topLeftIcon}
              bottomRightAction={'none'}
              bottomRightIcon={'none'}
              bottomLeftAction={'none'}
              bottomLeftIcon={'none'}
              setOrgChartRef={this.getOrgChartRef}
              chartClass={'security'}
            />
          </React.Fragment>
        ) : null}
      </CardBody>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgChart);
