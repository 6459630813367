import api from 'utils/api';
import { Entity } from '../../../../core/interfaces';

export async function createNewEntityFn(values) {
  const updateEntityValues = Entity.create(values);
  let result;
  let updatedEntity;

  const createResult = await api.post('/entities', updateEntityValues);
  if (!createResult || !createResult.data) {
    result = { type: 'add', status: 'error', errorMessage: 'Could not create entity.' };
    console.log('Error creating new entity. Not data. ', { createResult, updateEntityValues });
  } else {
    updatedEntity = createResult.data;
    result = {
      type: 'add',
      status: 'success',
      entity: updatedEntity,
      capacities: values.capacities,
    };
  }

  return result;
}
