import { AddOrSelectComponent } from '.';
import { useEntitySideEffects } from '../utils';

export function AddAgentModal(props) {
  const { referringEntity, onFinishCallback, onCancel, redux } = props;
  const sideEffects = useEntitySideEffects({ redux });
  const onAgentAdded = async (data) => {
    sideEffects.add({ action: 'CONNECT_AGENT', targetEntity: data.entity, capacities: data.capacities });
    const { currentEntity } = await sideEffects.execute(referringEntity);
    if (typeof onFinishCallback === 'function') {
      onFinishCallback({ ...data, referringEntity: currentEntity || data.referringEntity });
    }
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  return (
    <AddOrSelectComponent
      {...props}
      mode="addRelation"
      relationType="Agent"
      onFinishCallback={onAgentAdded}
    />
  );
}
