import React, { useState } from 'react';
import { Row, Col, Card } from 'antd';
import { CardSkeleton } from '../Skeletons/Overview';
/* Components */
import Comments from './Comments';
import CommentEditor from './Editor';

/* Used to collect new comments/replies when editor is submitted */
import { MessageContext } from './MessageContext';

import IntlMessages from 'util/IntlMessages';

const Messages = ({ id, type }) => {
  const [update, setUpdate] = useState(false);
  const value = { update, setUpdate };

  const title = <IntlMessages id="app.general.notes" cap />;
  if (!id || !type) return <CardSkeleton title={title} />;

  return (
    <MessageContext.Provider value={value}>
      <Card title={title} className="light-container  widget messages-container">
        <Row className="mb-0">
          <Col span={24}>
            <Comments id={id} type={type} />
          </Col>
        </Row>
        <Row>
          <Col span={24} className="">
            <CommentEditor editor={'main'} id={id} type={type} />
          </Col>
        </Row>
      </Card>
    </MessageContext.Provider>
  );
};

export default Messages;
