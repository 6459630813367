export const listPropCosModuleSlate = {
  id: 'listPropCosModuleSlate',
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true },
  match: ({ entry, node, api }) => {
    return api.utils.engine.matchModule(node, 'facilityAgreement', 'listPropCos');
  },
  handler: function ({ state, handlerInvoked, entries, paths, api, matches }) {
    const affectedCardIds = entries.map((entry) => entry.cardId);
    // console.log('Slate list propcos are ', { affectedCardIds, matches });
    if (!affectedCardIds.includes('property') && !affectedCardIds.includes('borrower')) {
      // Neither property or borrower was affected. Abort.
      return;
    }

    let content;
    let stringedContent;
    if (!state.input.property || api.utils.general.ocount(state.input.property) === 0) {
      stringedContent = '[**]';
      content = [this.makeParagraph(stringedContent)];
    } else {
      const properties =
        state.input.property && Object.keys(state.input.property).map((key) => state.input.property[key]);
      const borrowers =
        state.input.borrower && Object.keys(state.input.borrower).map((key) => state.input.borrower[key]);

      const propcos = [
        ...new Set(
          properties
            .filter((property) => {
              if (!borrowers) return true;
              return (
                api.utils.engine.findIndex(borrowers, (borrower) => borrower.name === property.owner) === -1
              );
            })
            .map((prop) => prop.owner)
        ),
      ];

      if (propcos.length === 0) {
        stringedContent = '[**]';
        content = [this.makeParagraph(stringedContent)];
        return;
      } else {
        content = propcos.map((propco) => this.makeParagraph(propco || '[**]'));
      }
    }

    if (!content) return;

    for (const { actions } of matches.default) {
      actions.replaceChildren(content);
    }
  },
};
