import { useState, useEffect } from 'react';
import { Row, Col, Divider, Collapse } from 'antd';
import IntlMessages from 'util/IntlMessages';
import countries from 'core/config/countries.json';
import { DEFAULT_ENTITY_VALUES } from 'core/config/constants';
import { smallGutter } from 'config';
import {
  CollectEmails,
  ContactInfo,
  Capacities,
  EntityFieldAddressBlock,
  EntityEditFieldFirstname,
  EntityEditFieldJurisdiction,
  EntityEditFieldType,
  EntityEditFieldLastname,
  EntityEditFieldIdentificationNumber,
  ParentEntity,
  ListRelations,
} from '.';

const { Panel } = Collapse;

export const EntityEditFields = ({
  entity,
  small,
  additionalRequiredFields = [],
  skipFields = [],
  referringEntity,
  type,
  form,
  save,
  availableParents,
  autoSelectName,
  disallowEditAgents,
  disallowEditPrincipals,
  defaultNewValues,
  addAgent,
  removeAgent,
  addPrincipal,
  removePrincipal,
  setAgentCapacities,
  setPrincipalCapacities,
  relationType,
  ...rest
}) => {
  const initEntityType = form.getFieldValue('type');
  const [entityType, setEntityType] = useState(initEntityType);
  const [jurisdiction, setJurisdiction] = useState(
    entity ? entity.jurisdiction : defaultNewValues ? defaultNewValues.jurisdiction : ''
  );
  const [jurisdictionData, setJurisdictionData] = useState({});

  const emailRequired = additionalRequiredFields.includes('email');
  const jurisdictionRequired = additionalRequiredFields.includes('jurisdiction');
  const identificationRequired = additionalRequiredFields.includes('identificationNumber');

  useEffect(() => {
    const country = countries.find((c) => c.code === jurisdiction);
    if (country && country.data) {
      setJurisdictionData(country.data);
      const newFormValue = country.data.corporateForms?.defaultValue || '';
      form.setFieldsValue({ form: newFormValue });
    }
  }, [form, jurisdiction]);

  return (
    <>
      {referringEntity && !skipFields.includes('capacities') && (
        <Row gutter={smallGutter}>
          <Col lg={12} md={24}>
            <Capacities
              additionalRequiredFields={additionalRequiredFields}
              skipFields={skipFields}
              referringEntity={referringEntity}
              relationType={relationType}
              {...rest}
            />
          </Col>
        </Row>
      )}
      <Row gutter={smallGutter}>
        <Col lg={12} md={24}>
          <EntityEditFieldType onChange={setEntityType} />
        </Col>
        <Col lg={12} md={24}>
          <EntityEditFieldJurisdiction onChange={setJurisdiction} required={jurisdictionRequired} />
        </Col>
      </Row>
      <Row gutter={smallGutter}>
        <Col lg={12} md={24}>
          <EntityEditFieldFirstname entityType={entityType} autoSelectName={autoSelectName} />
        </Col>
        <Col lg={12} md={24}>
          <EntityEditFieldLastname entityType={entityType} jurisdictionData={jurisdictionData} />
        </Col>
      </Row>

      <Row gutter={smallGutter}>
        <Col lg={12} md={24}>
          {entityType === 'LegalPerson' && (
            <ParentEntity entity={entity} entityType={entityType} availableParents={availableParents} />
          )}
        </Col>
      </Row>

      <Row gutter={smallGutter}>
        <Col lg={12} md={24}>
          <EntityEditFieldIdentificationNumber entityType={entityType} required={identificationRequired} />
        </Col>
      </Row>

      <Row gutter={smallGutter}>
        <Col lg={12} md={24}>
          <Collapse defaultActiveKey={[]} ghost>
            <Panel header={<IntlMessages id="app.general.address" cap />} key="1">
              <EntityFieldAddressBlock withOther={false} compact={true} />
            </Panel>
          </Collapse>
        </Col>
        <Col lg={12} md={24}>
          <Collapse defaultActiveKey={[]} ghost>
            <Panel header={<IntlMessages id="app.persons.emails" cap />} key="1">
              <CollectEmails compact={true} />
            </Panel>
          </Collapse>
        </Col>
      </Row>

      <Row gutter={smallGutter}>
        <Col lg={12} md={24}>
          {!disallowEditAgents && (
            <Collapse defaultActiveKey={[]} ghost>
              <Panel header={<IntlMessages id={`app.entity.AgentLabel`} />} key="1">
                <ListRelations
                  relationType="Agent"
                  entity={entity}
                  save={save}
                  addAgent={addAgent}
                  removeAgent={removeAgent}
                  setAgentCapacities={setAgentCapacities}
                />
              </Panel>
            </Collapse>
          )}
        </Col>
        <Col lg={12} md={24}>
          {!disallowEditPrincipals && (
            <Collapse defaultActiveKey={[]} ghost>
              <Panel header={<IntlMessages id={`app.entity.PrincipalLabel`} />} key="1">
                <ListRelations
                  relationType="Principal"
                  entity={entity}
                  save={save}
                  addPrincipal={addPrincipal}
                  removePrincipal={removePrincipal}
                  setPrincipalCapacities={setPrincipalCapacities}
                />
              </Panel>
            </Collapse>
          )}
        </Col>
      </Row>
      {/* <ContactInfo small={small} form={form} {...rest} /> */}
    </>
  );
};
