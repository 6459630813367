import React, { useState } from 'react';
import { Typography, Select } from 'antd';
import { Link } from 'react-router-dom';
import { setProject } from 'hooks';
import api from 'utils/api';
import { EditOutlined } from '@ant-design/icons';
import StatusTag from '../../ui/StatusTag';
import IntlMessages from 'util/IntlMessages';
const { Paragraph } = Typography;

const AboutItem = ({ data }) => {
  const {
    title,
    icon,
    iconSize = 'md',
    itemKey,
    desc,
    color = 'orange',
    resource,
    resourceRoute,
    updateProjectContext,
    labelIntlPrefix,
    valueOptions,
    linkTo,
    extendedBorder,
  } = data;
  const [descValue, setDescValue] = useState(desc || '');

  const mayEdit = true;

  const onEditableChange = (value) => {
    if (!mayEdit) return;
    if (!value) return console.log('No value.');
    if (!itemKey) return console.log('No item key');
    if (!resource || !resourceRoute) return console.log('No resource or resourceRoute');

    api
      .put('/' + resourceRoute + '/' + resource.id, { [itemKey]: value })
      .then((res) => {
        console.log('Update is ', res);
      })
      .catch((err) => {
        console.log('Put err ', { err, response: err.response });
      });

    if (updateProjectContext) {
      setProject({
        ...resource,
        [itemKey]: value,
      });
    }
    setDescValue(value);
  };

  const editable = mayEdit && itemKey && resource && resourceRoute;
  const selectEditable = editable && Array.isArray(valueOptions) && valueOptions.length;
  const stringEditable = editable && !selectEditable;

  let item = '';
  if (selectEditable) {
    item = (
      <SelectEditable
        onEditableChange={onEditableChange}
        valueOptions={valueOptions}
        labelIntlPrefix={labelIntlPrefix}
        descValue={descValue}
      />
    );
  } else if (stringEditable) {
    item = (
      <Paragraph
        onClick={(evt) => evt.stopPropagation()}
        editable={{ onChange: onEditableChange, tooltip: 'Click to edit' }}
        className="mb-0"
      >
        {descValue}
      </Paragraph>
    );
  } else if (desc && linkTo) {
    item = (
      <Link to={linkTo} className="mb-0">
        {desc}
      </Link>
    );
  } else {
    item = <div className="mb-0">{desc || ''}</div>;
  }

  return (
    <>
      <div className="media flex-nowrap info-item">
        <div className="media-body">
          <h6 className={`${extendedBorder ? 'connect-next-title' : 'mr-3'}`}>
            <i className={`mdi ${icon} fs-${iconSize} text-${color} mr-1`} />
            {title}
          </h6>
          <div className='ml-1'>
            {item}
          </div>
        </div>
      </div>
    </>
  );
};

function SelectEditable({ valueOptions, descValue, labelIntlPrefix, onEditableChange }) {
  const [open, setOpen] = useState(false);

  const onChange = (value) => {
    onEditableChange(value);
    setOpen(false);
  };

  const toggleOpen = () => setOpen(!open);

  if (open) {
    return (
      <Select value={descValue} onChange={onChange} open={open}>
        {valueOptions.map((option) => (
          <Select.Option key={option} value={option}>
            {labelIntlPrefix ? <IntlMessages id={`${labelIntlPrefix}${option}`} /> : option}
          </Select.Option>
        ))}
      </Select>
    );
  }

  return (
    <div>
      <StatusTag status={descValue} />
      <EditOutlined className="link" onClick={toggleOpen} />
    </div>
  );
}

export default AboutItem;
