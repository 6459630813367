import React, { useEffect, useMemo, useRef, useState, memo } from 'react';
import { useSlate } from 'slate-react';
import { Editor, Element } from 'slate';
import { Button, Row, Tooltip } from 'antd';
import { Contract } from 'core/interfaces';
import { find } from 'core/engine/utils/content/find';
import { getComponents } from '../../../../core/utils/logic/applyLogic';
import CreateInput from '../../components/StudioInput/renderDraftUI/CreateInput';
import { CloseOutlined } from '@ant-design/icons';

const ruleOperators = [
  'input',
  'local',
  // 'setup',
  'card',
  'any_in_card',
  'all_in_card',
];

function getOpsAndTargets(elements, contract) {
  if (!elements.length || !elements[1]) {
    return null;
  }

  const acps = find(elements[1], (elem) => elem.data?.acp).map((elem) => elem.data.acp);
  const enums = find(elements[1], (elem) => elem.data?.enums)
    .map((elem) => elem.data.enums.filter((enu) => enu.rule).map((enu) => enu.rule))
    .flat();
  const ruleKeys = [...new Set([...acps, ...enums])];
  if (ruleKeys.length === 0) return null;

  const rules = ruleKeys.map((ruleKey) => contract.data.create.savedRules[ruleKey]);
  const ruleComponents = rules
    .map((r) => getComponents(r.data, ruleOperators))
    // .map((comps) => console.log('comps ', comps) || comps)
    .filter((comps) => comps && comps.length);
  if (ruleComponents.length === 0) return null;

  const opsAndTargets = ruleComponents.reduce((acc, curr) => {
    for (const [op, path] of curr) {
      if (!acc[op]) acc[op] = new Set();
      acc[op].add(path);
    }
    return acc;
  }, {});
  return opsAndTargets;
}

function getRepeatableItems(elements) {
  return elements
    .filter(([elem]) => elem.type === 'field' && elem.variant === 'item')
    .map(([elem]) => elem?.data?.each?.key);
}

function getVariItems(elements) {
  return elements
    .filter(([elem]) => elem.type === 'field' && elem.variant === 'vari')
    .map(([elem]) => elem?.data?.name);
}

function matchRepeatable(elem) {
  if (!elem || !elem.data) return false;
  return elem.data._each_repeatable_name && elem.data._each_uid && !!elem.data._path;
}

function getRepeatableData(editor) {
  const [repeatableTuple] = Array.from(Editor.nodes(editor, { match: matchRepeatable, mode: 'lowest' }));
  if (!repeatableTuple || !repeatableTuple[0]) return [];
  return [
    repeatableTuple[0].data._each_repeatable_name,
    repeatableTuple[0].data._each_uid,
    repeatableTuple[0].data._path,
  ];
}

function makeItem({ type, item, contract, repeatableData }) {
  if (type === 'item' && repeatableData) {
    const [cardId, cardUid, path] = repeatableData;
    const card = Contract.getUiCard(contract, cardId);
    if (!card) return null;
    const field = Contract.getUiInput(contract, item);
    if (!field) return null;
    const fullPath = path + '.' + item;
    const inputProps = {
      card,
      cardId,
      cardUid,
      field,
      fieldName: item,
      path: fullPath,
    };
    return inputProps;
  }
  if (type === 'input') {
    const [cardId, fieldName] = item.split('.');
    if (!cardId || !fieldName) return null;
    const card = Contract.getUiCard(contract, cardId);
    if (!card) return null;
    const field = Contract.getUiInput(contract, fieldName);
    if (!field) return null;
    const fullPath = 'input.' + item;
    const inputProps = {
      card,
      cardId,
      field,
      fieldName,
      path: fullPath,
    };
    return inputProps;
    /*
    card: {id: 'pricing', info: 'Pricing', label: 'Pricing', header: {…}, values: {…}, …}
    cardUid: undefined
    field: {card: 'payment_info', cols: {…}, name: 'upfront_payment_rule', type: 'radio', label: {…}, …}
    fieldName: "pricing/upfront_payment_rule"
    path: "input.pricing.pricing/upfront_payment_rule"
    */
  }
  return null;
}

function getElements(editor, elementsRef) {
  if (!editor.selection || !editor.selection.anchor) {
    return elementsRef.current;
  }
  const elements = Array.from(Editor.nodes(editor)).filter(([element]) => Element.isElement(element));
  elementsRef.current = elements;
  return elements;
}

function WrapInline({ setVisible, children }) {
  const [expandable, setExpandable] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const rowRef = useRef();

  useEffect(() => {
    if (!rowRef.current) return;
    setTimeout(() => {
      const rect = rowRef.current.getBoundingClientRect();
      if (rect.height > 120) {
        setExpandable(true);
      } else {
        setExpandable(false);
        setExpanded(false);
      }
    }, 100);
  }, [children]);

  return (
    <div
      className={`d-flex justify-content-space-between input-proposals-inline-main ${
        expanded ? 'expanded' : ''
      }`}
    >
      <div className="d-flex w-100">
        <div
          className={`clickable inline-proposal-expand-row-holder animated bounce ${
            expandable ? 'expandable' : ''
          }`}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? (
            <i className="mdi mdi-chevron-double-up fs-xxxl" />
          ) : (
            <Tooltip title={'Click to show all inputs'} placement="right">
              <i className="mdi mdi-chevron-double-down fs-xxxl" />
            </Tooltip>
          )}
        </div>
        <div className={`inline-proposal-row-holder`}>
          <Row className={`inline-proposal-row`} ref={rowRef}>
            {children}
          </Row>
        </div>
      </div>
      <div>
        <Button type="text" onClick={() => setVisible(false)}>
          <CloseOutlined />
        </Button>
      </div>
    </div>
  );
}

function WrapFixed({ setVisible, children }) {
  return (
    <div className="offer-input-proposal-holder">
      <div className="offer-input-proposal">
        <div className="offer-input-close" onClick={() => setVisible(false)}>
          <i className="mdi mdi-close" />
        </div>
        <Row>{children}</Row>
      </div>
    </div>
  );
}

function getInteractionItems(elements, editor, contract) {
  const opsAndTargets = getOpsAndTargets(elements, contract);
  const repeatableData = getRepeatableData(editor);
  const repeatableItems = getRepeatableItems(elements, editor);
  const variItems = getVariItems(elements, editor);
  const repeatableEntries = new Set([
    ...(opsAndTargets && opsAndTargets.local ? opsAndTargets.local : []),
    ...repeatableItems,
  ]);
  const inputEntries = new Set([
    ...(opsAndTargets && opsAndTargets.input ? opsAndTargets.input : []),
    ...variItems,
  ]);

  const items = [];
  if (repeatableEntries) {
    for (const item of repeatableEntries) {
      const inputProps = makeItem({ item, repeatableData, contract, type: 'item' });
      if (!inputProps) continue;
      items.push(<CreateInput key={'item' + item} {...inputProps} />);
    }
  }

  if (inputEntries) {
    for (const item of inputEntries) {
      const inputProps = makeItem({ item, repeatableData, contract, type: 'input' });
      if (!inputProps) continue;
      items.push(<CreateInput key={'input' + item} {...inputProps} />);
    }
  }
  return items;
}

const Wrapper = memo(function Wrapper({ mode, setVisible, items }) {
  if (mode === 'inline') {
    return <WrapInline setVisible={setVisible}>{items}</WrapInline>;
  }
  return <WrapFixed setVisible={setVisible}>{items}</WrapFixed>;
});

export default function OnContractInputProposals({ contract, mode = 'fixed', emptyContent = null, ...rest }) {
  const editor = useSlate();
  const [visible, setVisible] = useState(true);
  const elementsRef = useRef([]);

  const elements = getElements(editor, elementsRef);

  const elementsPaths = elements.map((tuple) => tuple[1]).join('_');

  useEffect(() => {
    setVisible(true);
  }, [elementsPaths]);

  const items = useMemo(() => {
    return getInteractionItems(elements, editor, contract);
    // Only update when elementsPaths change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementsPaths]);

  if (items.length === 0) return emptyContent;

  if (!visible) return null;

  return <Wrapper mode={mode} setVisible={setVisible} items={items} />;
}
