import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from 'appRedux/actions';
import { Modal } from 'antd';
import URLSearchParams from 'url-search-params';
import { PasswordCredential, EmailCredential } from './components/Credentials';
import { setAuthToken } from 'utils/auth';
import api from 'utils/api';

export default function AuthLayerWrapper({ location, children }) {
  return <AuthLayer location={location}>{children}</AuthLayer>;
}

function AuthLayer({ location, children }) {
  const dispatch = useDispatch();
  const [hasAuth, setHasAuth] = useState(false);

  const urlParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const invitationId = urlParams.get('id');
  const hash = urlParams.get('h');

  async function login(email, password) {
    if (hasAuth && !hash) return;
    try {
      const loginResponse = await api.post('/users/entityUserLogin', {
        invitationId,
        h: hash,
        email,
        password,
      });
      if (!loginResponse || !loginResponse.data) {
        return;
      }
      const { data } = loginResponse;
      const { token, user } = data;
      if (token) {
        localStorage.setItem('jwtToken', token);
        setAuthToken(token);
        setHasAuth(true);
      }
      if (user) {
        dispatch(setUser(user));
      }
    } catch (err) {
      if (err.response?.data?.require) {
        const { require } = err.response.data;
        if (require === 'password') return authWithPassword();
        if (require === 'email') return authWithEmail();
        return console.warn('Unsupported require ', require);
      }
    }
  }

  const authWithPassword = () => {
    const tmp = { modal: null };
    const onSubmit = (values) => {
      login(null, values.password);
      tmp.modal.destroy();
      delete tmp.modal;
    };
    tmp.modal = Modal.confirm({
      title: 'Password required',
      content: <PasswordCredential onSubmit={onSubmit} />,
      className: '',
      onOk: null,
      okButtonProps: { className: 'd-none' },
      cancelButtonProps: { className: 'd-none' },
      onCancel: null,
    });
  };
  const authWithEmail = () => {
    const tmp = { modal: null };
    const onSubmit = (values) => {
      login(values.email, null);
      tmp.modal.destroy();
      delete tmp.modal;
    };
    tmp.modal = Modal.confirm({
      title: 'Email required',
      content: <EmailCredential onSubmit={onSubmit} />,
      className: '',
      onOk: null,
      okButtonProps: { className: 'd-none' },
      cancelButtonProps: { className: 'd-none' },
      onCancel: null,
    });
  };

  useEffect(() => {
    if (invitationId && hash) {
      login();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return hasAuth ? children : (
    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
      Authenticating . . .
    </div>
  );
}
