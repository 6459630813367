import { useState, useEffect, useCallback } from 'react';
import { Card } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { useFetch } from '../../../hooks/api';
import { ActionButton, AutoModal } from 'components/ui';
import { SecondaryActionsSkeleton } from '../../../components/Skeletons/Overview';
import Checklists from '../../../components/project/Checklists';
import Files from '../../../components/project/Files';
import Members from '../../../components/project/Members';
import Subscriptions from '../../../components/project/Subscriptions';

const Actions = ({ project, documents }) => {
  if (!project) return <SecondaryActionsSkeleton title={<IntlMessages id="general.project" cap />} />;

  return (
    <Card className="widget bg-transparent" title={<IntlMessages id="general.project" cap />}>
      <div className="overview-actions">
        <ChecklistsButton project={project} />
        <FilesButton project={project} />
        <MembersButton project={project} />
        <SubscriptionsButton project={project} documents={documents} />
      </div>
    </Card>
  );
};

function getSubscriptionsUrl(project) {
  if (!project || !project.id) return '';
  return `/eventsubscribers/my?resourceType=Project&resourceId=${project.id}`;
}

function getFilesUrl(project) {
  if (!project || !project.id) return '';
  return `/superfiles?resourceType=Project&resourceId=${project.id}&fetchType=all&fetchLimit=0`;
}

function getMembersUrl(project) {
  if (!project || !project.id) return '';
  return `/permissions/entries?resourceType=Project&resourceId=${project.id}&accessorType=User&actions&byEntities`;
}

function getCountUrl(project, type) {
  if (!project || !project.id) return '';
  return `/projects/${project.id}/subResources?resourceType=${type}&mode=count`;
}

function ChecklistsButton({ project }) {
  const [number] = useFetch(getCountUrl(project, 'Checklist'));

  return (
    <AutoModal
      trigger={
        <ActionButton
          type="secondary"
          title={<IntlMessages id="app.project.menus.Checklists" cap />}
          icon={<i className="mdi mdi-checkbox-multiple-marked" />}
          count={number && number.count}
        />
      }
      title={<IntlMessages id="app.project.menus.Checklists" cap />}
      className="modal-lg"
    >
      <Checklists project={project} />
    </AutoModal>
  );
}

function FilesButton({ project }) {
  const [files] = useFetch(getFilesUrl(project));
  const [length, setLength] = useState(0);

  const updateLength = useCallback((num) => setLength(num), []);

  useEffect(() => updateLength(files?.length || 0), [files, updateLength]);

  return (
    <AutoModal
      trigger={
        <ActionButton
          type="secondary"
          title={<IntlMessages id="app.project.menus.Files" cap />}
          icon={<i className="mdi mdi-file" />}
          count={length}
        />
      }
      title={<IntlMessages id="app.file.projectFiles" cap />}
      className="modal-lg"
    >
      <Files project={project} initialFiles={files} updateLength={updateLength} />
    </AutoModal>
  );
}

function SubscriptionsButton({ project, documents }) {
  const [subscriptions] = useFetch(getSubscriptionsUrl(project));

  const [length, setLength] = useState(0);

  const updateLength = useCallback((num) => setLength(num), []);

  useEffect(() => updateLength(subscriptions?.length || 0), [subscriptions, updateLength]);

  return (
    <AutoModal
      trigger={
        <ActionButton
          type="secondary"
          title={<IntlMessages id="app.project.menus.Subscriptions" cap />}
          icon={<i className="mdi mdi-bell" />}
          count={length}
        />
      }
      title={<IntlMessages id="app.project.menus.Subscriptions" cap />}
      className="modal-lg"
    >
      <Subscriptions project={project} initialSubscriptions={subscriptions} updateLength={updateLength} />
    </AutoModal>
  );
}

function MembersButton({ project }) {
  const [members] = useFetch(getMembersUrl(project));

  const [length, setLength] = useState(0);

  const updateLength = useCallback((num) => setLength(num), []);

  useEffect(() => updateLength(members?.length || 0), [members, updateLength]);

  return (
    <AutoModal
      trigger={
        <ActionButton
          type="secondary"
          title={<IntlMessages id="app.project.menus.Members" cap />}
          icon={<i className="mdi mdi-account-multiple" />}
          count={length}
        />
      }
      title={<IntlMessages id="app.project.menus.Members" cap />}
      className="modal-lg"
    >
      <Members project={project} initialMembers={members} updateLength={updateLength} />
    </AutoModal>
  );
}

export default Actions;
