import { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Checkbox, Table, Dropdown, Menu } from 'antd';
import { setNumberPendingTasks } from 'appRedux/actions';
import { fixDate, ActionModal, RemoveModal } from 'components/ui';
import { useDownloadPdfBackend, useSearch } from 'hooks';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import SignDocument from '../SignDocument';
import {
  getSigingPartyFieldValue,
  userIsSignatory,
  filterSignatories,
  openSignWindow,
} from 'utils/services/scrive';

const { Column } = Table;

const parseOptionalTime = (timestamp) => {
  if (timestamp === undefined) return null;
  return timestamp ? fixDate(timestamp) : <IntlMessages id="desc.No" />;
};

const parseSignings = (signings, user) => {
  const lastCreated = signings.reduce((s, p) => Math.max(s, new Date(p.createdAt)), 0);
  return signings.reverse().reduce((store, process) => {
    const userPart = userIsSignatory(process, user);
    if (process.status === 'init') store.push({ id: process.id, status: process.status });
    for (const part of filterSignatories(process.data.parties))
      store.push({
        id: `${process.id}-${part.id}`,
        signingId: process.id,
        status: process.status,
        sent: fixDate(process.createdAt),
        seen: parseOptionalTime(part.seen_time),
        signed: parseOptionalTime(part.sign_time),
        versionId: process.versionId,
        name: getSigingPartyFieldValue(part, 'name'),
        company: getSigingPartyFieldValue(part, 'company'),
        scrivePath: part === userPart ? part.api_delivery_url : null,
        isManual: !process.externalReference,
        isLatest: +new Date(process.createdAt) === lastCreated,
      });
    return store;
  }, []);
};

export default function Signings({ doc, setDocument, currentVersion, initialSignings, updateLength }) {
  const hasInitialSignings = !!initialSignings;
  const { initDownloadPDF } = useDownloadPdfBackend({ documentId: doc.id });
  const { user } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const [showPrevious, setShowPrevious] = useState(false);
  const [anyVersion, setAnyVersion] = useState(false);
  const [signings, setSignings] = useState(initialSignings || []);

  const parsedSignings = useMemo(() => parseSignings(signings, user), [signings, user]);

  const [searchResult, searchElement] = useSearch(parsedSignings, {
    element: true,
    fields: ['name'],
  });

  const loadSignings = useCallback(async () => {
    const include = encodeURI(JSON.stringify([{ model: 'Superfile' }]));
    const query = `include=${include}&where=status_!->_deleted`;
    const response = await api.get(`/documents/${doc.id}/documentsignings?${query}`);
    setSignings(response.data);
    if (typeof updateLength === 'function') updateLength(response.data.length);
  }, [doc.id, updateLength]);

  window.loadSignings = loadSignings;

  useEffect(() => {
    const id = setTimeout(() => {
      if (signings.some(({ status }) => status === 'init')) loadSignings();
    }, 3000);
    return () => clearTimeout(id);
  }, [loadSignings, signings]);

  useEffect(() => {
    if (hasInitialSignings) return;
    loadSignings();
  }, [loadSignings, hasInitialSignings]);
  console.log('signings ', { signings, parsedSignings });
  const cancelSigning = async (signingId) => {
    await api.post(`/documentsignings/${signingId}/cancel`, {});
    await api.post(`/documents/${doc.id}/unpublish`);
    setDocument({ status: 'draft', finalVersionId: null });
    setSignings(signings.map((s) => (s.id === signingId ? { ...s, status: 'canceled' } : s)));
    dispatch(setNumberPendingTasks());
  };

  const deleteSigning = async (signingId) => {
    await api.post(`/documentsignings/${signingId}/delete`, {});
    if (typeof updateLength === 'function') updateLength(signings.length - 1);
    setSignings(signings.filter((s) => s.id !== signingId));
  };

  const manualSigning = async (signingId, index) => {
    const response = await api.post(`/documentsignings/${signingId}/marksigned`, { index });
    const { success, deployed } = response.data;
    if (success && deployed) {
      setDocument({ status: 'signed' });
      initDownloadPDF();
    }
  };

  const optionsFilter = ({ status, isLatest, versionId }) => {
    if (status === 'init') return true;
    const okStatus = showPrevious || isLatest;
    const okVersion = anyVersion || versionId === currentVersion.id;
    return okStatus && okVersion;
  };

  return (
    <Card
      title={
        <div className="d-flex align-items-center justify-content-end">
          <Checkbox className="mr-3" onChange={(e) => setShowPrevious(e.target.checked)}>
            Show previous
          </Checkbox>
          <Checkbox className="mr-3" onChange={(e) => setAnyVersion(e.target.checked)}>
            Any version
          </Checkbox>
          {searchElement}
          <SignDocument
            className="ml-3"
            document={doc}
            setDocument={setDocument}
            currentVersion={currentVersion}
            onSigningInitiated={loadSignings}
          />
        </div>
      }
    >
      <Table rowKey="id" dataSource={searchResult.filter(optionsFilter)}>
        <Column title={<IntlMessages id="app.general.Name" cap />} dataIndex="name" />
        <Column title={<IntlMessages id="desc.Sent" cap />} dataIndex="sent" />
        <Column title={<IntlMessages id="app.signing.list.seenDocument" cap />} dataIndex="seen" />
        <Column title={<IntlMessages id="app.signing.list.signedDocument" cap />} dataIndex="signed" />
        <Column
          title={<IntlMessages id="app.general.Action" cap />}
          dataIndex="id"
          render={(id, record, index) => {
            if (record.status === 'canceled') {
              return (
                <span>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item key="remove" onClick={() => deleteSigning(record.signingId)}>
                          <span>
                            <IntlMessages id="desc.remove" cap />
                          </span>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <span className="link ant-dropdown-link">
                      <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
                    </span>
                  </Dropdown>
                </span>
              );
            }
            if (!['pending', 'preparation'].includes(record.status))
              return <IntlMessages id={`app.signing.status.${record.status}`} cap />;
            return (
              <span>
                <Dropdown
                  overlay={
                    <Menu>
                      {record.isManual && (
                        <Menu.Item key="sign">
                          <ActionModal
                            okText={<IntlMessages id="app.general.Signed" cap />}
                            onConfirm={() => manualSigning(record.signingId, index)}
                            confirmTitle={
                              <>
                                <IntlMessages id="desc.confirm" />{' '}
                                <IntlMessages id="app.signing.list.confirmManualSign" lower />
                              </>
                            }
                            confirmText={<IntlMessages id="app.signing.list.confirmManualSign.desc" cap />}
                          >
                            <span>
                              <IntlMessages id="app.approval.markSigned" cap />
                            </span>
                          </ActionModal>
                        </Menu.Item>
                      )}
                      {record.scrivePath && (
                        <Menu.Item key="sign" onClick={() => openSignWindow(record.scrivePath)}>
                          <IntlMessages id="app.signing.sign" cap />
                        </Menu.Item>
                      )}
                      <Menu.Item key="remove">
                        <RemoveModal
                          okText={
                            <>
                              <IntlMessages id="desc.Abort" /> <IntlMessages id="app.signing.sign" lower />
                            </>
                          }
                          onConfirm={() => cancelSigning(record.signingId)}
                          confirmTitle={
                            <>
                              <IntlMessages id="desc.confirm" />{' '}
                              <IntlMessages id="app.signing.list.cancelProcess" lower />
                            </>
                          }
                          confirmText={<IntlMessages id="app.signing.list.cancelProcess.desc" cap />}
                        >
                          <span>
                            <IntlMessages id="desc.Abort" cap />
                          </span>
                        </RemoveModal>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <span className="link ant-dropdown-link">
                    <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
                  </span>
                </Dropdown>
              </span>
            );
          }}
        />
      </Table>
    </Card>
  );
}
