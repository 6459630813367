import React from 'react';
import { Table, Tag } from 'antd';
import Widget from 'components/Widget/index';
import { uuidColor } from 'core/utils/general';
import IntlMessages from 'util/IntlMessages';
import { NavLink } from 'react-router-dom';
import papers from 'assets/images/papers.png';
import { fixDate, ShowHide, StatusTag, Tablify } from 'components/ui';
import Summary from 'routes/project/Document/Overview/Summary';

const { Column } = Table;

const DocItem = ({ data, widget = false }) => {
  const {
    documentTemplateId,
    documentTemplateName,
    name,
    description,
    status,
    updatedAt,
    id,
    projectId,
    documentId,
    finalVersionId,
    versions,
  } = data;
  const { color } = documentTemplateId ? uuidColor(documentTemplateId, { mode: 'light' }) : '#ccc';

  const finalVersion = status !== 'draft' && finalVersionId && versions.find((v) => v.id === finalVersionId);
  // console.log('Final version?  ', finalVersion);

  const content = (
    <div className="flex-column featured-item">
      <div className="media">
        <div className="featured-thumb">
          <img className="rounded-lg" src={papers} style={{ width: '40px' }} alt="..." />
        </div>
        <div className="media-body featured-content">
          <div className="featured-content-left">
            <Tag className="rounded-xs text-uppercase" color={color}>
              {documentTemplateName}
            </Tag>
            <div className="flex-column">
              <h3 className="mr-3">{name}</h3>
              <small className="">{description}</small>
            </div>
          </div>
          <div className="featured-content-left pl-3">
            <Tablify
              data={[
                ['Status', <StatusTag status={status} />],
                ['Updated', <Tag>{fixDate(updatedAt)}</Tag>],
              ]}
            />
          </div>
          <div className="featured-content-right profile-content-right">
            <h2 className="mb-1 text-muted">
              <i className={`icon icon-files fs-lg mr-2 d-inline-flex vertical-align-middle`} />
              <span className="d-inline-flex fs-md vertical-align-middle">
                {versions ? versions.length : 0} matching version(s)
              </span>
            </h2>
            <p className="text-primary text-truncate mt-sm-auto mb-0 pointer">
              <NavLink to={`/project/${projectId}/document/${id}`}>
                <IntlMessages id={'app.main.goToDocument'} />
                <i
                  className={`icon icon-long-arrow-right fs-xxl ml-1 ml-sm-2 d-inline-flex vertical-align-middle`}
                />
              </NavLink>
            </p>
          </div>
        </div>
      </div>
      {/* <Divider /> */}
      <div>
        <ShowHide
          wrapperClassName="mt-2"
          title={{
            show: 'Show versions',
            hide: 'Hide versions',
          }}
        >
          {versions && <VersionsList results={versions} projectId={projectId} documentId={documentId} />}
        </ShowHide>
      </div>
      {finalVersion && (
        <ShowHide
          wrapperClassName="mt-2"
          title={{
            show: 'Show summary',
            hide: 'Hide summary',
          }}
        >
          <Summary contract={finalVersion} options={{ withHeader: false }} />
        </ShowHide>
      )}
    </div>
  );

  if (widget) {
    return (
      <Widget styleName="card-profile mt-4">
        <div className="ant-card-head"></div>
        {content}
      </Widget>
    );
  }

  return content;
};

function VersionsList({ results, projectId, documentId }) {
  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={results}
      pagination={false}
      bordered={false}
      size="middle"
      className="versions-list"
      style={{ margin: '5px 0 5px 0px' }}
    >
      <Column title={<IntlMessages id="app.general.Name" />} dataIndex="name" key="name" />
      <Column
        title={<IntlMessages id="app.general.LastModified" />}
        dataIndex="updatedAt"
        key="updatedAt"
        render={(text) => fixDate(text)}
      />
      <Column
        title={<IntlMessages id="app.general.Action" />}
        key="actions"
        render={(text, record) => (
          <span className="margin-0">
            <NavLink to={`/project/${projectId}/document/${documentId}/version/${record.id}`}>
              <IntlMessages id={'app.main.goToVersion'} />
            </NavLink>
          </span>
        )}
      />
    </Table>
  );
}

export default DocItem;
