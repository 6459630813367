import React, { useState, useEffect } from 'react';
import { Button, Tooltip, Dropdown, Menu } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { addRepeatable } from 'appRedux/actions';
import IntlMessages from 'util/IntlMessages';
import { useContract } from 'hooks';
import { Contract, Entity } from 'core/interfaces';
import api from 'utils/api';

function AddParty(props) {
  const { cardName } = props;
  const dispatch = useDispatch();

  const contract = useContract();
  const concepts = Contract.getConcepts(contract);
  const partyConcept = concepts.find((concept) => concept.stateId === cardName && concept.contractParty);

  const addBlock = (data) => {
    dispatch(addRepeatable(props.path, data));
  };

  return (
    <>
      <Tooltip title={<IntlMessages id="studio.inputs.addParty" />}>
        <AddPartyButton addBlock={addBlock} cardName={cardName} concept={partyConcept} />
      </Tooltip>
    </>
  );
}

function AddPartyButton({ addBlock, cardName, concept }) {
  // const [client, setClient] = useState(null);
  const { auth, entities } = useSelector(({ auth, entities }) => ({ auth, entities }));
  const { user } = auth;
  /* useEffect(() => {
    api
      .get('/clients/' + user.clientId)
      .then((res) => {
        res && res.data && setClient(res.data);
      })
      .catch((err) => {
        console.log('could not find my client', err);
      });
  }, [user]); */

  let menu = null;
  let menuItems = [];

  // FIX!
  /* if (false && client) {
    menuItems.push(
      <Menu.Item key="client">
        <span
          onClick={() => {
            addBlock({ 'party/name': client.name });
          }}
        >
          {client && client.name}
        </span>
      </Menu.Item>
    );
    menuItems.push(<Menu.Divider key="clientdivider" />);
  } */

  if (entities && entities.length > 0) {
    const { id: ownerId } = entities.find((entity) => !entity.parentId);
    const topCo = Entity.findTopCo(entities);
    const entityItems = topCo ? [
      <Menu.Item key={'entity' + topCo.id}>
        <span
          onClick={() => {
            addBlock({
              [cardName + 'Entity']: {
                type: 'entity',
                id: topCo.id,
              },
            });
          }}
        >
          {Entity.name(topCo)}
        </span>
      </Menu.Item>,
    ] : [];
    if (entities.length > 2) {
      const subsidiaries = [];
      for (const entity of entities) {
        if (entity === topCo || entity.id === ownerId) continue;
        subsidiaries.push(
          <Menu.Item key={'entity' + entity.id}>
            <span
              onClick={() => {
                addBlock({
                  [cardName + 'Entity']: {
                    type: 'entity',
                    id: entity.id,
                  },
                });
              }}
            >
              {Entity.name(entity)}
            </span>
          </Menu.Item>
        );
      }
      entityItems.push(
        <Menu.SubMenu title="subsidiaries" key={'children'}>
          {subsidiaries}
        </Menu.SubMenu>
      );
    }

    menuItems = menuItems.concat(entityItems);
  }

  if (menuItems.length > 0) menu = <Menu>{menuItems}</Menu>;

  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <Button shape="circle" icon={<UserAddOutlined />} size="small" style={{ fontSize: '14px' }} />
    </Dropdown>
  );
}

export default AddParty;
