import EntityAuthLayer from './EntityAuthLayer';
// import InvitedResourcesOverview from './InvitedResourcesOverview';
import InvitedBase from './InvitedBase';

export default function InvitedEntity({ location }) {
  return (
    <EntityAuthLayer location={location}>
      <InvitedBase location={location} />
    </EntityAuthLayer>
  );
}
