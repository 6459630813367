import { Avatar, Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { uuidColor } from 'core/utils/general';
import { Entity } from 'core/interfaces';
import userToName from './userToName';

export default function UserAvatar({
  user,
  className,
  style = {},
  withTooltip = false,
  tooltipExtra = null,
  withName = false,
  withNameClass = '',
}) {
  const { color } = user && user.id ? uuidColor(user.id, { mode: 'light' }) : '#ccc';
  const userName = userToName(user);
  const avatar = (
    <span>
      <Avatar className={className} style={{ ...style, backgroundColor: color }} icon={<UserOutlined />} />
      {withName && <span className={withNameClass}>{userName}</span>}
    </span>
  );
  if (withTooltip) {
    const tooltip = (
      <>
        <div>{userName}</div>
        {tooltipExtra && <div>{tooltipExtra}</div>}
      </>
    );
    return (
      <Tooltip title={tooltip} placement="top">
        {avatar}
      </Tooltip>
    );
  }
  return avatar;
}

export function InitialAvatar({ entity, ghost, mode = 'light' }) {
  if (!entity) return null
  const { color } = entity && entity.id ? uuidColor(entity.id, { mode }) : '#ccc';
  const style = {
    border: `1px solid ${color}`,
    backgroundColor: ghost ? 'unset' : color,
  }
  return (
    <Tooltip
      key={entity.id}
      title={Entity.name(entity)}
      placement="top"
      color={mode === 'dark' ? '#fff' : '#000'}
      overlayInnerStyle={{ color: mode === 'dark' ? '#000' : '#fff' }}
    >
      <Avatar style={style}>{Entity.initials(entity)}</Avatar>
    </Tooltip>
  );
}
