import React, { useState } from 'react';
import logo from 'assets/images/logo-256-inverse.png'
import logoSmall from 'assets/images/logo-small-white.png'


const SidebarLogo = ({ isMini }) => {

  return (
    <div className="layout-sider-header">
      <div className="w-100 d-flex justify-content-center">
        <div className="sider-header-logo">
          <img src={isMini ? logoSmall : logo} className="site-logo" />
        </div>
      </div>
    </div>
  );
};

export default SidebarLogo;
