import React, { useState, useEffect } from 'react';
import { Avatar, Tabs, Breadcrumb, Badge } from 'antd';
import { ofindKey, ofindValues, ucfirst } from 'core/utils/general';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { Entity } from 'core/interfaces';
import { useProject } from 'hooks';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import { userToName } from 'components/ui';

const { TabPane } = Tabs;

export default function TopHeader({
  items,
  back,
  name,
  description,
  isFolder,
  icon,
  toPathMatch,
  children,
  ...rest
}) {
  // return null
  const history = useHistory();
  const location = useLocation();

  const match = items && ofindKey(items, (item) => location.pathname.includes(item.activeMatch));

  const onChangeMenu = (key) => {
    history.push(items[key].path(toPathMatch));
  };

  let renderIcon = null;

  if (icon) {
    if (icon.icon) renderIcon = icon.icon;
    else {
      renderIcon = <i className={(icon.className || 'mdi mdi-asterisk') + ' ico-md'} />;
    }
  }

/*   let avatarClassName = 'size-60';
  let avatarStyle = {};
  if (icon?.bgClassName) avatarClassName += ' ' + icon.bgClassName;
  else {
    avatarStyle.backgroundColor = icon.bg || '#1890ff';
  }
  if (isFolder) avatarStyle.borderRadius = '20%'; */

  return (
    <div className="project-top-header">
      <div className="flex-column">
        <div className="flex-row align-items-center">
          <div
            className="d-flex"
            style={{
              padding: '10px',
              borderRadius: '4px',
              margin: '20px 20px 15px 15px',
            }}
          >
            <div className="">{renderIcon}</div>
            <div className="ml-3 flex-column justify-content-center">
              <h2 className="fs-xxl font-weight-light">{name || ''}</h2>
              {description && <h5 className="mt-2">{description || ''}</h5>}
            </div>
          </div>
          <div style={{ flexGrow: 1 }}>{children}</div>
        </div>
        <div className="ml-4">
          {items && (
            <Tabs activeKey={match} onChange={onChangeMenu}>
              {Object.keys(items).map((key) => {
                const item = items[key].hasOwnProperty('badge') ? (
                  <Badge
                    count={items[key].badge}
                    style={{
                      backgroundColor: items[key].badgeColor || '#1e91ff',
                      color: items[key].badgeTextColor || undefined,
                      minWidth: items[key].badgeSize ? items[key].badgeSize + 'px' : undefined,
                      height: items[key].badgeSize ? items[key].badgeSize + 'px' : undefined,
                      lineHeight: items[key].badgeSize ? items[key].badgeSize + 'px' : undefined,
                    }}
                    offset={[7, 0]}
                    size="small"
                    showZero={items[key].showZero}
                  >
                    {items[key].text}
                  </Badge>
                ) : (
                  items[key].text
                );
                return <TabPane tab={item} key={key}></TabPane>;
              })}
            </Tabs>
          )}

          {back && false && (
            <span className="profile-setting">
              <Link to={back.path}>
                <span className="link">
                  <i className="mdi mdi-arrow-left fs-lg mr-2 d-inline-flex vertical-align-middle" />
                  <span className="d-inline-flex vertical-align-middle ml-1 ml-sm-0">{back.name}</span>
                </span>
              </Link>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
