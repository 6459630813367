import React from "react";
import {cx} from "@emotion/css";
import { Button, Tooltip } from "antd";
// import { DeleteOutlined } from '@ant-design/icons';
import "./index.css";
import { Buttons } from "./buttons";

const exec = (func, ...args) => (e) => {
  e && e.preventDefault();
  return func(...args);
};

export const Cardbar = (props) => {
  return (
    <div className={cx("cardbar", props.className)}>
      <Button.Group>
        {props.children}
        {props.delete && (
          <Tooltip title={Buttons.Delete.tooltip || ""}>
          <Button onMouseDown={exec(props.delete)}>
            <Buttons.Delete.Icon />
          </Button>
          </Tooltip>
        )}
      </Button.Group>
    </div>
  );
};

export { Buttons } from "./buttons";
