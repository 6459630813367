import { getContractValues, useEntitySearch } from "hooks"
import { AutoComplete, Button, Input, Select } from "antd"
import { useEffect, useState } from "react"
import api, { generateDebouncer } from 'utils/api'
import { insertEntity } from "appRedux/actions"
import { useDispatch, useSelector } from "react-redux"
import { Entity } from "core/interfaces"
const debounce = generateDebouncer()

export default function({ targetEntity, targetType, selectedEntity, onChange, onEdit, disabled }) {
  const dispatch = useDispatch()
  const contract = getContractValues()
  const entities = useSelector(Entity.getBaseState)
  const [networkSearch, setNetworkSearch] = useState('')
  const [search, setSearch] = useState('')
  const [results, searchError] = useEntitySearch({ search: networkSearch })
  const suggestions = targetEntity?.[targetType]
  const source = !results ? suggestions ?? [
    ...entities,
    ...Entity.getAgents(entities).filter(({id}) => !entities.some(entity => entity.id === id))
  ] : results

  useEffect(() => {
    if (!selectedEntity) return
    const option = parseOption(selectedEntity)
    setSearch(option.search)
  }, [selectedEntity])

  const onSearchChange = (value) => {
    setSearch(value)
    debounce(() => setNetworkSearch(value))
  }

  const onCreate = () => {
    setSearch('')
    setNetworkSearch('')
    onChange(null)
    onEdit()
  }

  const onSelect = (id, option) => {
    const existingEntity = Entity.getById(entities, id)
    if (!existingEntity) {
      const newEntity = Entity.getById(source, id)
      dispatch(insertEntity(newEntity, { meta: { noApi: true, actionsToFollow: true } }))
      api.post(`/documententityrelations`, {
        documentId: contract.documentId,
        entityId: id,
      })
    }
    setSearch(option.search)
    onChange({id})
  }

  const parseOption = (entity) => ({
    id: entity.identificationNumber,
    value: entity.id,
    search: `${entity.firstName} ${entity.lastName}`,
    label: (
      <div>
        {`${entity.firstName} ${entity.lastName}`}
        {entity.identificationNumber && (
          <small>{` (${entity.identificationNumber})`}</small>
        )}
      </div>
    )
  })

  const searchSort = (a, b) => {
    return b.tags?.includes('isTopCo') ? 1 : -1
  }

  const searchFilter = (value, option) => {
    if (!search) return true
    const chunks = search
      .split(/[ -]/)
      .map((chunk) => `(?=.*${chunk})`)
      .join('')
    return !!`${option.search} ${option.id}`.match(new RegExp(chunks, 'i'))
  }

  const options = source.sort(searchSort).map(parseOption)
  const hasEdit = selectedEntity && parseOption(selectedEntity).search === search
  return (
    <div className="d-flex">
      <Select
        showSearch
        options={options}
        filterOption={searchFilter}
        notFoundContent="Create a new with + button"
        value={search ? search : undefined}
        onSearch={onSearchChange}
        onSelect={onSelect}
        disabled={disabled}
        dropdownMatchSelectWidth={false}
        defaultActiveFirstOption
        placeholder="search..."
        style={{width: 'calc(100% - 32px)'}}
      />
      <Button
        onClick={hasEdit ? onEdit : onCreate}
        icon={<i className={`mdi ${hasEdit ? 'mdi-pencil' : 'mdi-plus'}`} />}
      />
    </div>
  )
}