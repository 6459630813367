import React from 'react';
import { Badge, Tooltip } from 'antd';
import Widget from 'components/Widget/index';

const IconCard = ({
  color,
  icon,
  mdi = true,
  badge,
  bottomText,
  action = {},
  topAction = {},
  bottomAction = {},
  afterText,
  className = '',
}) => {
  const hasAction = action.onClick && action.text;
  const hasTopAction = topAction.onClick && topAction.text;
  const hasBottomAction = bottomAction.onClick && bottomAction.text;
  const hasActions = hasAction || hasTopAction || hasBottomAction;

  return (
    <>
      <Widget styleName={`card-full p-2 text-center position-relative text-primary ${color} ${className}`}>
        {badge && (
          <Badge
            status={badge}
            style={{ position: 'absolute', right: '0', marginRight: '0', top: '4px' }}
          ></Badge>
        )}
        <div>
          <i className={`mdi mdi-${icon} fs-iconcard`} />
        </div>
        <div>
          {bottomText ? bottomText : ''}
          {hasActions && (
            <div className="widget-item-overlay">
              {hasTopAction && (
                <div className="action action-top" onClick={topAction.onClick || null}>
                  <div className="action-content">
                    {topAction.tooltip ? (
                      <Tooltip title={topAction.tooltip} placement={topAction.tooltipPlacement || 'top'}>
                        {topAction.text}
                      </Tooltip>
                    ) : (
                      topAction.text
                    )}
                  </div>
                </div>
              )}
              {hasBottomAction && (
                <div className="action action-bottom" onClick={bottomAction.onClick}>
                  <div className="action-content">
                    {bottomAction.tooltip ? (
                      <Tooltip
                        title={bottomAction.tooltip}
                        placement={bottomAction.tooltipPlacement || 'top'}
                      >
                        {bottomAction.text}
                      </Tooltip>
                    ) : (
                      bottomAction.text
                    )}
                  </div>
                </div>
              )}
              {hasAction && (
                <div className="action" onClick={action.onClick}>
                  <div className="action-content">
                    {action.tooltip ? (
                      <Tooltip title={action.tooltip} placement={action.tooltipPlacement || 'top'}>
                        {action.text}
                      </Tooltip>
                    ) : (
                      action.text
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Widget>
      {afterText && (
        <div className="d-flex align-items-center justify-content-center">
          <small className="text-center break-word">{afterText}</small>
        </div>
      )}
    </>
  );
};

export default IconCard;
