import React, { useState, useEffect, memo } from 'react';
import { Card, Table, Spin, Dropdown, Menu, Button, Tooltip, Modal } from 'antd';
import { fixDate } from 'components/ui';
import api from 'utils/api';
import { useSearch } from 'hooks';
import NewDocumentButton from 'components/project/NewDocument/NewDocumentButton';
import { GotoDraftSpan, GotoDocumentViewSpan, GotoVersionViewSpan } from 'components/DocumentsList/';
import IntlMessages from 'util/IntlMessages';
// import { compareTest } from 'utils/markup/compareTest';
// import CompareDocs from './CompareDocs';
import QuickDocumentView from './QuickDocumentView';
import ListVersionsForPublication from './ListVersionsForPublication';
import { RemoveModal, StatusTag } from 'components/ui';
import { EyeOutlined } from '@ant-design/icons';
import { useTasks } from 'routes/tasks/';

const { Column } = Table;

const config = {
  bordered: false,
  loading: false,
  pagination: { position: 'bottom' },
  // size: 'default',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  // rowSelection: {},
  // scroll: undefined,
};

const Documents = memo(({ project, documents, setDocuments, documentWasCreated }) => {
  const docIds = documents ? documents.map((d) => d.id) : [];

  const { tasks, filteredTasks, setTaskOutcomeStatus } = useTasks({
    filter: (t) => {
      return t.status === 'pending' && t.resourceType === 'Document' && docIds.includes(t.resourceId);
    },
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  /* const [compareDocs, setCompareDocs] = useState(null); */
  const [quickViewDoc, setQuickViewDoc] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const [currentDocumentToPublish, setCurrentDocumentToPublish] = useState(null);

  const documentTemplatesProviders = useCompatibleDocumentTemplateProviders({
    ids: documents && documents.map((d) => d.documentTemplateId),
  });

  const onSelectChange = (selection) => {
    setSelectedRowKeys(selection);
  };

  const handleDropdownChange = (val) => setDropdownVisible(val);

  const duplicateDocument = (docId) => {
    api
      .post('/documents/' + docId + '/duplicate')
      .then((res) => {
        console.log('Duplicate is ', res.data);
        setDocuments([res.data, ...documents]);
      })
      .catch((err) => console.log(err.response));
  };

  const deleteDocument = (docId) => {
    api
      .delete('/documents/' + docId)
      .then((res) => {
        setDocuments([...documents.filter((doc) => doc.id !== docId)]);
      })
      .catch((err) => console.log(err.response));
  };

  const publishDocument = (docId) => {
    api
      .get(
        `/documents/${docId}/versions?fields=id,name,description,info,updatedAt&order=${encodeURI(
          'desc|createdAt'
        )}`
      )
      .then((result) => {
        const versionsLength = result.data?.length || 0;
        if (versionsLength === 0) {
          console.log('No versions for document, fix that...');
        } else if (versionsLength === 1) {
          api
            .post('/documents/' + docId + '/publish', { finalVersionId: result.data[0].id })
            .then((res) => {
              api.put('/versions/' + result.data[0].id, { status: 'published' }).finally(() => {
                setDocuments(
                  documents.map((doc) => {
                    if (doc.id !== docId) return doc;
                    return { ...doc, status: 'published' };
                  })
                );
              });
            })
            .catch((err) => console.log(err.response));
        } else {
          console.log('Ok....!');
          setCurrentDocumentToPublish(docId);
        }
      })
      .catch((err) => {
        console.log('Error fetching versions for document. Fix.', err.response);
      });
  };
  const unpublishDocument = (docId) => {
    api
      .post('/documents/' + docId + '/unpublish')
      .then((res) => {
        setDocuments(
          documents.map((doc) => {
            if (doc.id !== docId) return doc;
            return { ...doc, status: 'draft' };
          })
        );
      })
      .catch((err) => console.log(err.response));
  };

  const abortPublishDocument = () => {
    setCurrentDocumentToPublish(null);
  };

  const publishDocumentWithVersion = (versionId) => {
    api
      .post('/documents/' + currentDocumentToPublish + '/publish', { finalVersionId: versionId })
      .then((res) => {
        api.put('/versions/' + versionId, { status: 'published' }).finally(() => {
          setDocuments(
            documents.map((doc) => {
              if (doc.id !== currentDocumentToPublish) return doc;
              return { ...doc, status: 'published' };
            })
          );
          setCurrentDocumentToPublish(null);
        });
      })
      .catch((err) => console.log(err.response));
  };

  const publishableVersions = currentDocumentToPublish && (
    <div>
      <ListVersionsForPublication
        documentId={currentDocumentToPublish}
        onSelect={(versionId) => publishDocumentWithVersion(versionId)}
        selectText={'Select'}
      />
    </div>
  );

  /* const markupable = selectedRowKeys.length === 2;
  const markup = (id, type) => {
    if (!markupable) return;
    const baseId = type === 'base' ? id : selectedRowKeys.find((selected) => selected !== id);
    const newId = type === 'new' ? id : selectedRowKeys.find((selected) => selected !== id);
    handleDropdownChange(false);
    setTimeout(() => {
      setCompareDocs({
        base: {
          type: 'document',
          id: baseId,
        },
        new: {
          type: 'document',
          id: newId,
        },
      });
    }, 10);
  };

  const markupTemplate = (id) => {
    handleDropdownChange(false);
    setTimeout(() => {
      setCompareDocs({
        base: {
          type: 'template',
        },
        new: {
          type: 'document',
          id: id,
        },
      });
    }, 10);
  }; */

  const initQuickView = (id) => {
    handleDropdownChange(false);
    setTimeout(() => {
      setQuickViewDoc(id);
    }, 10);
  };

  const [docs, searchElement] = useSearch(documents, {
    element: true,
    fields: ['name', 'description'],
  });

  if (!documents) {
    return <Spin className="loader-container" />;
  }
  /* if (documentsError) {
    return (
      <div>
        <div>Error:</div>
        <pre>{JSON.stringify(documentsError, null, 2)}</pre>
      </div>
    );
  } */

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const approveDocumentsIds = filteredTasks.map((t) => t.resourceId);

  return (
    <div>
      {publishableVersions && (
        <Modal
          size="lg"
          className="modal-lg"
          title={'Select version'}
          visible={true}
          onCancel={abortPublishDocument}
          footer={<Button onClick={abortPublishDocument}>Cancel</Button>}
          backdrop={'static'}
        >
          {publishableVersions}
        </Modal>
      )}
      <Card
        title={
          <div className="d-flex justify-content-space-between">
            <div style={{ display: 'inline-block' }}>
              <IntlMessages id="general.Documents" />
            </div>
            <div className="d-flex">
              <div className="mr-3">{searchElement}</div>
              <div>
                <NewDocumentButton project={project} documentWasCreated={documentWasCreated} />
              </div>
            </div>

            {/* <div style={{ display: "inline-block" }} className="float-right mr-3">
            <MarkupButton contractIds={selectedRowKeys} />
          </div> */}
          </div>
        }
        className="project-documents"
      >
        {/* {compareDocs && <CompareDocs data={compareDocs} setCompareDocs={setCompareDocs} />} */}
        {quickViewDoc && <QuickDocumentView documentId={quickViewDoc} setQuickViewDoc={setQuickViewDoc} />}
        <Table
          rowKey="id"
          className="table-responsive documents-table"
          {...config}
          // columns={columns}
          // rowSelection={rowSelection}
          dataSource={docs}
        >
          <Column
            title={<IntlMessages id="app.general.Name" />}
            dataIndex="name"
            key="name"
            sorter={(a, b) => a.name < b.name}
            render={(text, record) => {
              /* if (markupable && selectedRowKeys.includes(record.id))
                  return (
                    <>
                      <div className="markup-document-btn">
                        <div className="mb-2">Compare using this as</div>
                        <div className="d-flex">
                          <Button shape="round" className="mb-0" onClick={() => markup(record.id, 'base')}>
                            Base document
                          </Button>
                          <Button shape="round" className="mb-0" onClick={() => markup(record.id, 'new')}>
                            New document
                          </Button>
                        </div>
                      </div>
                      {text}
                    </>
                  ); */
              return (
                <>
                  <GotoDocumentViewSpan document={record} className="link">
                    {text}
                  </GotoDocumentViewSpan>
                </>
              );
            }}
          />
          <Column
            title={<IntlMessages id="app.general.Description" />}
            dataIndex="description"
            // render={(text, record) => (
            //   <div>
            //     <small>{text}</small>
            //     {record && record.info && record.info.imported && (
            //       <div>
            //         <Tag icon={<ImportOutlined />} color="#68a994">
            //           Imported
            //         </Tag>
            //       </div>
            //     )}
            //   </div>
            // )}
          />
          {/* <Column
            title={<IntlMessages id="app.general.Created" />}
            dataIndex="createdAt"
            key="createdAt"
            render={(text) => <small>{fixDate(text, { breakDateAndTime: true })}</small>}
            sorter={(a, b) => a.createdAt < b.createdAt}
          /> */}
          <Column
            title={<IntlMessages id="app.general.LastModified" />}
            dataIndex="updatedAt"
            key="updatedAt"
            render={(text) => fixDate(text, { onlyDate: true })}
            sorter={(a, b) => a.updatedAt < b.updatedAt}
          />
          <Column
            title={<IntlMessages id="app.general.Status" />}
            dataIndex="status"
            key="status"
            render={(text) => {
              return <StatusTag ghost withColor status={text} />;
              /* return (
                  <Badge text={text.toLocaleUpperCase()} color={text === 'draft' ? 'orange' : 'green'} />
                ); */
            }}
            sorter={(a, b) => a.status < b.status}
          />
          <Column
            title={<IntlMessages id="app.general.More" />}
            key="extraActions"
            render={(text, record) => {
              const userAssignedApproveTask = approveDocumentsIds.includes(record.id);
              return (
                <div className="d-flex align-items-center">
                  <Dropdown
                    onVisibleChange={(val) => handleDropdownChange(val ? record.id : false)}
                    visible={dropdownVisible === record.id}
                    overlay={
                      <Menu>
                        <Menu.Item key="goto-doc">
                          <GotoDocumentViewSpan document={record} />
                        </Menu.Item>

                        <Menu.Item key="goto-version">
                          <GotoVersionViewSpan
                            document={record}
                            title={
                              <>
                                <IntlMessages id="app.general.GoTo" /> <IntlMessages id="desc.latest" />{' '}
                                <IntlMessages id="app.resources.Version" />
                              </>
                            }
                          />
                        </Menu.Item>

                        {record.status === 'draft' && (
                          <Menu.Item key="edit-version">
                            <GotoDraftSpan
                              document={record}
                              title={
                                <>
                                  <IntlMessages id="app.general.Edit" /> <IntlMessages id="desc.latest" />{' '}
                                  <IntlMessages id="app.resources.Version" />
                                </>
                              }
                            />
                          </Menu.Item>
                        )}
                        {record.status === 'signed' && (
                          <Menu.Item key="goto-view-version">
                            <GotoVersionViewSpan
                              document={record}
                              versionId={record.finalVersionId}
                              title={
                                <>
                                  <IntlMessages id="app.general.GoTo" />{' '}
                                  <IntlMessages id="app.general.Signed" />{' '}
                                  <IntlMessages id="app.resources.Version" />
                                </>
                              }
                            />
                          </Menu.Item>
                        )}
                        {userAssignedApproveTask && <Menu.Divider />}
                        {userAssignedApproveTask && (
                          <Menu.Item key="task-approve">
                            <span
                              onClick={() => {
                                const task = filteredTasks.find(
                                  (t) => t.resourceType === 'Document' && t.resourceId === record.id
                                );
                                if (task && task.id) {
                                  setTaskOutcomeStatus(task.id, 'approved');
                                }
                                setDropdownVisible(false);
                              }}
                            >
                              <>
                                <IntlMessages id="app.approval.approve" cap />{' '}
                                <IntlMessages id="app.resources.Document" />
                              </>
                            </span>
                          </Menu.Item>
                        )}
                        {userAssignedApproveTask && (
                          <Menu.Item key="task-reject">
                            <span
                              onClick={() => {
                                const task = filteredTasks.find(
                                  (t) => t.resourceType === 'Document' && t.resourceId === record.id
                                );
                                if (task && task.id) {
                                  setTaskOutcomeStatus(task.id, 'rejected');
                                }
                                setDropdownVisible(false);
                              }}
                            >
                              <>
                                <IntlMessages id="app.approval.reject" cap />{' '}
                                <IntlMessages id="app.resources.Document" />
                              </>
                            </span>
                          </Menu.Item>
                        )}

                        <Menu.Divider />
                        <Menu.Item key="duplicate">
                          <span onClick={() => duplicateDocument(record.id)}>
                            <>
                              <IntlMessages id="desc.Duplicate" />{' '}
                              <IntlMessages id="app.resources.Document" />
                            </>
                          </span>
                        </Menu.Item>
                        <Menu.Item key="remove">
                          <RemoveModal
                            onConfirm={() => deleteDocument(record.id)}
                            confirmText={
                              <>
                                <IntlMessages id="app.general.confirmRemoval" />{' '}
                                <IntlMessages id="app.resources.Document" />?
                              </>
                            }
                          >
                            <span onClick={() => setDropdownVisible(false)}>
                              <IntlMessages id="desc.Remove" /> <IntlMessages id="app.resources.Document" />
                            </span>
                          </RemoveModal>
                        </Menu.Item>
                        <Menu.Divider />
                        {record.status === 'draft' && (
                          <Menu.Item key="publish">
                            <span onClick={() => publishDocument(record.id)}>
                              <IntlMessages id="desc.Publish" /> <IntlMessages id="app.resources.Document" />
                            </span>
                          </Menu.Item>
                        )}
                        {record.status === 'published' && (
                          <Menu.Item key="unpubl">
                            <span onClick={() => unpublishDocument(record.id)}>
                              <IntlMessages id="desc.UnPublish" />{' '}
                              <IntlMessages id="app.resources.Document" />
                            </span>
                          </Menu.Item>
                        )}
                        <Menu.Divider />
                        {/* <Menu.Item key="compare-to-template">
                            <span onClick={() => markupTemplate(record.id)}>Compare to Template</span>
                          </Menu.Item> */}
                        <Menu.Item key="quick-view">
                          <span onClick={() => initQuickView(record.id)}>Quick View</span>
                        </Menu.Item>
                        {documentTemplatesProviders &&
                          documentTemplatesProviders[record.documentTemplateId] && <Menu.Divider />}
                        {documentTemplatesProviders && documentTemplatesProviders[record.documentTemplateId] && (
                          <Menu.SubMenu key="use-to-create" title={<IntlMessages id="desc.UseToCreate" />}>
                            {documentTemplatesProviders[record.documentTemplateId].map((entry) => (
                              <Menu.Item
                                key={record.id + '' + entry.targetId}
                                onClick={() => setDropdownVisible(false)}
                              >
                                <NewDocumentButton
                                  project={project}
                                  documentTemplateId={entry.targetId}
                                  importedStateDocumentId={record.id}
                                >
                                  <div>{entry.targetName || entry.targetId}</div>
                                  {entry.targetDescription && <small>{entry.targetDescription}</small>}
                                </NewDocumentButton>
                              </Menu.Item>
                            ))}
                          </Menu.SubMenu>
                        )}
                      </Menu>
                    }
                  >
                    <span className="link ant-dropdown-link">
                      <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
                    </span>
                  </Dropdown>
                  {userAssignedApproveTask && (
                    <Tooltip
                      placement="left"
                      title={
                        <>
                          <IntlMessages id="app.approval.approveThis" cap />{' '}
                          <IntlMessages id="general.document" />
                        </>
                      }
                    >
                      <EyeOutlined className="p-1 bg-dark-grey text-white border-radius" />
                    </Tooltip>
                  )}
                </div>
              );
            }}
          />
        </Table>
      </Card>
    </div>
  );
});

export function useCompatibleDocumentTemplateProviders({ ids, ignore = false }) {
  const [documentTemplatesProviders, setDocumentTemplatesProviders] = useState(null);

  // Stringify in order to avoid the useEffect to be invoked every time
  // as we accept arrays (often new objects) as arguments/dependency
  const stringifiedIds = Array.isArray(ids) ? JSON.stringify(ids) : '';

  useEffect(() => {
    if (!stringifiedIds || ignore) return;

    const ids = JSON.parse(stringifiedIds);

    const include = [{ model: 'DocumentTemplateCompatible', as: 'DocumentTemplateCompatibleProviders' }];
    const includeQuery = encodeURI(JSON.stringify(include));

    const fetchTemplates = async function fetchTemplates() {
      try {
        const templates = await api.get(
          '/documenttemplates?qIn=id/' +
            JSON.stringify(ids) +
            '&fields=id,name,description&where=isCoreTemplate_->_NULL-or-FALSE&include=' +
            includeQuery
        );

        if (!templates || !templates.data) return;
        let allTemplates = templates.data;
        const allTemplatesId = allTemplates.map((template) => template.id);

        const allTargetIds = allTemplates
          .map((template) => template.DocumentTemplateCompatibleProviders.map((comp) => comp.targetId))
          .flat();
        const uniqueTargetIds = [...new Set(allTargetIds)];
        const nonFetchedTemplateTargetIds = uniqueTargetIds.filter((id) => !allTemplatesId.includes(id));

        // Fetch templates which we do not already have, but are supported to
        // be created by
        if (nonFetchedTemplateTargetIds.length > 0) {
          try {
            const additionalTemplates = await api.get(
              '/documenttemplates?qIn=id/' +
                JSON.stringify(nonFetchedTemplateTargetIds) +
                '&fields=id,name,description&where=isCoreTemplate_->_NULL-or-FALSE'
            );
            if (additionalTemplates.data && additionalTemplates.data.length > 0)
              allTemplates = allTemplates.concat(additionalTemplates.data);
          } catch (err) {
            console.log('Cannot fetch additional supported templates ', { err, response: err.response });
          }
        }

        const templateProviders = templates.data.reduce((acc, curr) => {
          if (!ids.includes(curr.id)) return acc;
          if (
            !curr.DocumentTemplateCompatibleProviders ||
            curr.DocumentTemplateCompatibleProviders.length === 0
          )
            return acc;
          acc[curr.id] = curr.DocumentTemplateCompatibleProviders.map((provider) => {
            const targetTemplate = allTemplates.find((t) => t.id === provider.targetId);
            if (targetTemplate)
              return {
                ...provider,
                targetName: targetTemplate.name || provider.targetId,
                targetDescription: targetTemplate.description || '',
              };
            return provider;
          });
          return acc;
        }, {});

        setDocumentTemplatesProviders(templateProviders);
      } catch (err) {
        console.log('Could not get document templates', err);
      }
    };
    fetchTemplates();
  }, [stringifiedIds, ignore]);

  return documentTemplatesProviders;
}

export default Documents;
