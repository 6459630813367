import api from 'utils/api';

export const initSign = async (projectId, documentId, versionId, parties) => {
  const data = {
    projectId,
    documentId,
    versionId,
    parties,
  };
  // return {
  //   scriveId: '5'
  // };
  try {
    const initResponse = await api.post('/documentsignings/createsigning', data);
    console.log('Init response: ', initResponse);
    if (initResponse.status === 200) {
      return {
        success: true,
        scriveId: initResponse.data.id,
        signUrl: initResponse.data.signUrl,
      };
    }
  } catch (err) {
    console.log('Signing error is ', err);
  }
  return null;
};

export const getSigingPartyFieldValue = (party, type, fieldName = 'type') => {
  return party.fields
    .filter((f) => f[fieldName] === type)
    .sort((a, b) => a.order - b.order)
    .map((f) => f.value)
    .join(' ');
};

export const userIsSignatory = (signing, user) =>
  signing.status === 'pending' &&
  signing.data.parties.find(
    (party) =>
      getSigingPartyFieldValue(party, 'email') === user.email &&
      !party.sign_time &&
      party.signatory_role === 'signing_party'
  );

export const filterSignatories = (parties) => {
  return parties ? parties.filter((p) => p.is_signatory) : [];
};

export const openSignWindow = async (scrivePath) => {
  const resp = await api.get('/documentsignings/baseurl');
  const scriveBaseUrl = resp.data.url;
  const signUrl = `${scriveBaseUrl}${scrivePath}`;
  window.open(
    signUrl,
    '_blank',
    'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=768,height=1024'
  );
};
