import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Divider, Row, Col, Switch, Radio, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setDraft } from 'appRedux/actions';
import { useModalContent, ActionButton, AutoModal } from 'components/ui';
import { useContract, useIsTemplateStudio, forceContractUpdatesHighlight } from 'hooks';
import { Contract } from 'core/interfaces';
import IntlMessages from 'util/IntlMessages';
import { gutter } from 'config';
import contractDefaults from 'core/config/contractDefaults';
import { Markup } from '../../../../components/YjsEditor/Markup/Markup';

const { display } = contractDefaults;
const { classes: defaultClasses } = display;

export function StudioSettings() {
  const [Modal, toggleModal] = useModalContent('Studio Settings', {
    defaultOpen: false,
  });
  return (
    <>
      <Button
        type="text"
        size="large"
        icon={<i className="mdi mdi-cog-outline" />}
        className="m-0"
        onClick={toggleModal}
      ></Button>
      <Modal modalClassName="modal-lg" title={<IntlMessages id="studio.sidebar.settings" />}>
        <SettingsContent />
      </Modal>
    </>
  );
}

function Section({ title, children }) {
  return (
    <div className="mb-4">
      <Divider orientation="center" className="mt-0 mb-1">
        {title}
      </Divider>
      {children}
    </div>
  );
}

function SettingsItem({ title, setting }) {
  return (
    <div className="d-flex w-100 justify-content-space-between align-items-center mb-2">
      <div className="">{title}</div>
      <div className="">{setting}</div>
    </div>
  );
}

function ToggleDraftMode() {
  const contract = useContract();
  const supportsInlineMode = Contract.getSupportsInlineMode(contract);
  const studio_draftMode = useSelector(({ draft }) => draft.studio_draftMode);
  const dispatch = useDispatch();

  const setMode = (checked) => {
    const mode = checked ? 'side-by-side' : 'inline';
    dispatch(setDraft('studio_draftMode', mode));
  };

  const splitView = studio_draftMode !== 'inline';

  return (
    <div className="">
      <Switch checked={splitView} disabled={!supportsInlineMode} onChange={setMode} />
    </div>
  );
}

function ContractPlacementSetting() {
  const studio_draftMode = useSelector(({ draft }) => draft.studio_draftMode);
  const splitView = studio_draftMode !== 'inline';
  if (!splitView) return null;

  return <SettingsItem title={'Split view - contract placement'} setting={<ToggleContractPlacement />} />;
}

function ToggleContractPlacement() {
  const dispatch = useDispatch();
  const direction = useSelector(({ draft }) => draft.split_contract_placement);
  const onChange = (evt) => {
    const { value } = evt.target;
    localStorage.setItem('split_contract_placement', value);
    dispatch(setDraft('split_contract_placement', value));
  };

  useEffect(() => {
    const element = document.getElementById('draft-input-container');
    if (element) {
      if (direction === 'right') element.classList.add('right');
      else element.classList.remove('right');
    }
  }, [direction]);

  return (
    <Radio.Group onChange={onChange} value={direction}>
      <Radio.Button value="left">
        <IntlMessages id="desc.left" cap />
      </Radio.Button>
      <Radio.Button value="right">
        <IntlMessages id="desc.right" cap />
      </Radio.Button>
    </Radio.Group>
  );
}

function ToggleHighlighting() {
  const [type, setType] = useState(forceContractUpdatesHighlight.getType());

  const onChange = (evt) => {
    console.log('onchange ', evt.target.value);
    const { value } = evt.target;
    setType(value);
    forceContractUpdatesHighlight.setType(value);
    forceContractUpdatesHighlight.highlight();
  };

  return (
    <Radio.Group onChange={onChange} value={type}>
      <Radio.Button value="all">
        <IntlMessages id="studio.sidebar.general.changes.all" />
      </Radio.Button>
      <Radio.Button value="recent">
        <IntlMessages id="studio.sidebar.general.changes.recent" />
      </Radio.Button>
      <Radio.Button value="none">
        <IntlMessages id="studio.sidebar.general.changes.none" />
      </Radio.Button>
    </Radio.Group>
  );
}

function ToggleShowSelectedFields() {
  const showDocumentStructure = useSelector(({ draft }) => draft.editor_showDocumentStructure);
  const editorElem = useMemo(() => document.getElementById('editor-holder'), []);

  const [showCurrentFields, setShowCurrentFields] = useState(
    (editorElem && editorElem.classList.contains('show-current-fields')) ||
      defaultClasses['show-current-fields']
  );

  const onChange = useCallback(
    (val) => {
      setShowCurrentFields(val);
    },
    [setShowCurrentFields]
  );

  return (
    <div className="">
      <Switch
        checked={showCurrentFields || showDocumentStructure}
        disabled={showDocumentStructure}
        onChange={onChange}
      />
    </div>
  );
}
function ToggleShowAllFields() {
  const showDocumentStructure = useSelector(({ draft }) => draft.editor_showDocumentStructure);
  const editorElem = useMemo(() => document.getElementById('editor-holder'), []);
  const dispatch = useDispatch();

  const onChange = useCallback(
    (val) => {
      dispatch(setDraft('editor_showDocumentStructure', val));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!editorElem) return;
    if (showDocumentStructure) editorElem.classList.add('template-edit');
    else editorElem.classList.remove('template-edit');
  }, [showDocumentStructure, editorElem]);

  return (
    <div className="">
      <Switch checked={showDocumentStructure} onChange={onChange} />
    </div>
  );
}

const DEFAULT_CONTRACT_PREVIEW_SIZE = 50;

function SetContractSize() {
  const dispatch = useDispatch();
  const size = useSelector(({ draft }) => draft.split_contract_size);

  const resizeMade = useCallback((percentage) => {
    const resizeElement = document.getElementById('preview-column');
    const editorElement = document.getElementById('editor-holder');
    if (!editorElement || !resizeElement) return;

    resizeElement.style.width = percentage + '%';
    resizeElement.style.flex = ' 0 0 ' + percentage + '%';

    /* const newSize = (percentage / DEFAULT_CONTRACT_PREVIEW_SIZE).toFixed(2);
    const newFontSize = newSize * 0.88;
    editorElement.style.fontSize = newFontSize + 'em';
    editorElement.style.lineHeight =
      newFontSize * 1.5 - (percentage - 30) / DEFAULT_CONTRACT_PREVIEW_SIZE + 'em'; */
  }, []);

  const onChange = (value) => {
    localStorage.setItem('split_contract_size', value);
    dispatch(setDraft('split_contract_size', value));
    resizeMade(value);
  };

  return (
    <div className="w-50">
      <InputNumber value={size} onChange={onChange} step={5} />
    </div>
  );
}

function SettingsContent() {
  const isTemplate = useIsTemplateStudio();
  return (
    <Row gutter={gutter}>
      <Col sm={24} md={24}>
        <div className="d-flex justify-content-center mb-4">
          <AutoModal
            trigger={
              <ActionButton
                type="secondary"
                title={<IntlMessages id="app.projects.documents.compareDocuments" cap />}
                icon="mdi mdi-eye"
                size="large"
              />
            }
            title={<IntlMessages id="app.projects.documents.compareDocuments" cap />}
            className="modal-md"
          >
            <Markup />
          </AutoModal>
        </div>
      </Col>
      <Col sm={24} md={12}>
        <Section title="Draft Mode">
          <SettingsItem title={'Split view'} setting={<ToggleDraftMode />} />
          <ContractPlacementSetting />
          {/* <SettingsItem title={'Split view - contract size'} setting={<SetContractSize />} /> */}
        </Section>
        <Section title="Viewing Options">
          <SettingsItem title={'Show fields in selection'} setting={<ToggleShowSelectedFields />} />
          <SettingsItem title={'Show all fields'} setting={<ToggleShowAllFields />} />
        </Section>
      </Col>
      <Col sm={24} md={12}>
        {!isTemplate && (
          <>
            <Section title="Highlighing">
              <SettingsItem title={'Show highlights'} setting={<ToggleHighlighting />} />
            </Section>
          </>
        )}
      </Col>
    </Row>
  );
}
