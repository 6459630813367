import React, { memo } from 'react';
import { Button, Card } from 'antd';
import { useSelector } from 'react-redux';
import { useContract, useSetStudioPage, useYjsEditor } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import { getPageSteps, getStudioPageByPage } from '../../utils/pageMenu';
import { findPageByStudioPage } from '../../../../utils/draftPath';
import StudioInputFormContent from './StudioInputFormContent';
import InputTopbar from './InputTopbar';
import FinishedInputs from './FinishedInputs';

export default function StudioInputContainerHolder(props) {
  return <StudioInputContainer {...props} />;
}

const StudioInputContainer = memo((props) => {
  const { activePagePaths, activeFlatUiPages, flatUiPages } = props;

  return (
    <StudioInputForms
      activeFlatUiPages={activeFlatUiPages}
      flatUiPages={flatUiPages}
      activePagePaths={activePagePaths}
    />
  );
});

const StudioInputForms = (props) => {
  const { activePagePaths, activeFlatUiPages } = props;
  const studioPage = useSelector(({ draft }) => draft.studioPage);
  const contract = useContract();
  const { onlineMode, onlineLoaded } = useYjsEditor();
  const setStudioPage = useSetStudioPage();

  const currentPage = findPageByStudioPage(contract, studioPage);
  const { next, prev } = getPageSteps({ activeFlatUiPages, page: currentPage });
  const nextLink = getStudioPageByPage(next);
  const prevLink = getStudioPageByPage(prev);

  if (onlineMode && !onlineLoaded) return null;

  return (
    <>
      <div id="draft-input-forms" className="draft-input-forms">
        <Card
          bodyStyle={{ padding: 0 }}
          actions={[
            <Button disabled={prev === null} onClick={() => setStudioPage(prevLink)}>
              <IntlMessages id="desc.previous" cap />
            </Button>,
            next === null ? (
              <FinishedInputs contract={contract} />
            ) : (
              <Button type="primary" disabled={next === null} onClick={() => setStudioPage(nextLink)}>
                <IntlMessages id="desc.next" cap />
              </Button>
            ),
          ]}
        >
          <div>
            <InputTopbar activeFlatUiPages={activeFlatUiPages} activePagePaths={activePagePaths} />
          </div>
          <div className="draft-input-forms-scroll">
            <StudioInputFormContent {...props} currentPage={currentPage} />
          </div>
        </Card>
      </div>
    </>
  );
};
