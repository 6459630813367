const fieldNamesConcerned = ['party/definition'];
const entityPropertiesConcerned = ['name', 'firstName', 'lastName'];
export const signingPagePartiesModuleSlate = {
  id: 'signingPagePartiesModuleSlate',
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true, entityAny: true },
  match: ({ entry, node, api }) => {
    return api.utils.engine.matchModule(node, 'facilityAgreement', 'signingPageParties');
  },
  handler: function ({ state, handlerInvoked, entries, paths, api, matches }) {
    let shallReGenerate = false;
    const concepts = api.interfaces.Contract.getConcepts(this.contract);

    // entriesLoop:
    for (const entry of entries) {
      const { cardId, fieldName, path, pathInvoked } = entry;
      // this.log('entry..', entry);

      if (pathInvoked.repeatableChange) {
        if (path.endsWith('id') || fieldNamesConcerned.includes(fieldName)) {
          shallReGenerate = true;
          break;
        }
      } else if (pathInvoked.entityAny) {
        const propertyConcerned = api.interfaces.InputPaths.getLast(path);
        if (entityPropertiesConcerned.includes(propertyConcerned)) {
          shallReGenerate = true;
          break;
        }
      } else {
        const matchingConcepts = concepts.filter((c) => c.stateId === cardId);
        if (matchingConcepts.length > 0 && matchingConcepts.some((mc) => mc.contractParty)) {
          shallReGenerate = true;
          break;
        }
      }
    }

    if (!shallReGenerate) return;

    const nameDescription = api.utils.general.ucfirst(this.translateText('name')) + ':';

    const content = generateSignPageContent({
      engine: this,
      state,
      nameDescription,
    });

    for (const { actions } of matches.default) {
      // FIX: Now we recieve entirely new nodes. Write more granular function to figure
      // out if we shall add clause/paragraphs (on repeatableAdd), remove clause/paragraphs (on repeatableRemove)
      // or update a particular clause/paragraphs (on repeatableChange or entityAny).

      actions.replaceChildren(content);
    }
  },
};

function generateSignPageContent({ engine, state, nameDescription }) {
  if (!engine.getContractCreate().build || !engine.getContractCreate().build.concepts) return;
  const contractPartyConcepts = engine
    .getContractCreate()
    .build.concepts.filter((concept) => concept.contractParty);

  // console.log('contractPartyConcepts', contractPartyConcepts);
  const content = [];
  let i = 0;
  for (const contractPartyConcept of contractPartyConcepts) {
    const { id, stateKey } = contractPartyConcept;

    const conceptState = engine.api.interfaces.Concept.getConceptState(
      contractPartyConcept,
      engine.contract,
      state
    );
    if (engine.api.utils.general.ocount(conceptState) === 0) continue;

    const { text: conceptDescription } =
      engine.api.interfaces.Concept.describe('the_' + id, state.input, engine.language, {
        contract: engine.contract,
      }) || '[**]';

    if (i !== 0) content.push(engine.makeParagraph(''));
    content.push(
      engine.makeParagraph([
        engine.makeTextNode(engine.api.utils.general.ucfirst(conceptDescription), 'bold'),
      ])
    );

    for (const [, party] of Object.entries(conceptState)) {
      if (typeof party !== 'object' || party === null) continue;
      // const partyName = party[stateKey] || engine.emptyContractString();

      const stateKeyData = party[stateKey];
      let entity, partyName;

      if (!stateKeyData) partyName = engine.emptyContractString();
      else if (typeof stateKeyData === 'string') partyName = stateKeyData;
      else if (typeof stateKeyData === 'object') {
        entity = engine.api.interfaces.Entity.getFromStateById(state, stateKeyData.id, stateKeyData.type);
        if (!entity) {
          engine.log('Cannot locate party entity', { party });
          continue;
        }
        partyName = engine.api.interfaces.Entity.name(entity);
      }

      content.push(engine.makeParagraph([engine.makeTextNode(partyName, 'bold')]));

      // makeTable([['hej', 'happ']])
      const tabl = engine.makeTable(
        [
          ['_________________', '_________________'],
          [nameDescription, nameDescription],
        ],
        {
          _format: { border: 0 },
        }
      );
      content.push(tabl);
      // content.push(this.makeParagraph([this.makeTextNode('___________________')]));
      i++;
    }
  }
  return content;
}
