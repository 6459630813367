import React, { useCallback, useEffect, useState, useContext } from 'react';

import { Tag, Tooltip, List, Empty, Button, Avatar, Comment, Collapse } from 'antd';
import moment from 'moment';

import Actions from './Actions';
import api from 'utils/api';
import { userToName } from 'components/ui';
import SubEditor from './SubEditor';

/* Slate stuff */
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { RenderElements } from '../editor/legal/elements';
import { RenderLeaf } from '../editor/legal/marks';

/* Context */
import { MessageContext } from './MessageContext';

/* Message Helper Functions */
import { AuthorName, AuthorInitials, AvatarColor, RoleTagColor, isMessageEdited } from './helperFunctions';

import { useLocale } from 'hooks';

import IntlMessages from 'util/IntlMessages';

const { Panel } = Collapse;

const data = [{}];

/**
 * @description e.g., "5 replies Latest reply by Fredric Färholt 7 seconds ago". Only visible if replies exist
 * @param {*} props
 * @returns
 */
const PanelHeader = (props) => {
  const replySize = props.size;
  const replyLastAuthor = props.lastAuthor;
  const replyLastDate = props.lastDate;

  return (
    <div className="reply-container">
      <div>
        <span className="highlight-span">
          {replySize} {replySize > 1 ? 'replies' : 'reply'}
        </span>{' '}
      </div>
      <div className="last-reply">
        <IntlMessages id="app.messages.lastReplyBy" cap /> {replyLastAuthor}{' '}
        <span>{moment(replyLastDate).fromNow()}</span>
      </div>
    </div>
  );
};

/**
 * @description The reply comment. Also has the ability to load the reply editor as its parent component TheComment
 * @param {*} props
 * @returns
 */
const TheReply = (props) => {
  const [deleteThis, setDeleteThis] = useState(false);

  const { update, setUpdate } = useContext(MessageContext);

  const [editor] = useState(() => {
    const newEditor = withReact(createEditor());
    newEditor.tmp = {};
    newEditor.meta = { noContract: true };
    return newEditor;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElement = useCallback((props) => <RenderElements {...props} editor={editor} />, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderLeaf = useCallback((props) => <RenderLeaf {...props} editor={editor} />, []);

  /* Props/Reply stuff */
  const { authors, reply, id, type } = props;
  const replyMessage = reply.data.message;

  /* Inherits TheComment isReply function */
  const isReply = () => {
    props.isReply();
  };

  const deleteComment = () => {
    setDeleteThis(!deleteThis);
  };

  const [edit, setEdit] = useState(false);

  /* This triggers edit editor */
  const isEdit = () => {
    setEdit(!edit);
  };

  const finalDeleteComment = () => {
    api
      .delete(`/messages/${reply.id}`)
      .then((res) => {
        setUpdate(!update);
        setDeleteThis(false);
        // console.log('Delete comment', res);
      })
      .catch((err) => console.log('Failed to delete comment', err));
  };

  /* Author */
  const authorEntity = authors[reply.entityId];
  const name = AuthorName(authorEntity);
  const avatarMeta = {
    initials: AuthorInitials(name),
    backgroundColor: authorEntity ? authorEntity.avatar.color : '#ccc',
  };
  const role = 'Entity';

  return (
    <div style={{ position: 'relative' }}>
      <Comment
        className="custom-reply-comment"
        actions={
          edit
            ? []
            : [
                <Actions
                  setReply={isReply}
                  setEdit={isEdit}
                  deleteThis={deleteComment}
                  author={reply.entityId}
                />,
              ]
        }
        author={
          <span>
            {name}{' '}
            {type === 'Project' && (
              <Tag style={{ marginLeft: '4px' }} color={RoleTagColor(role)}>
                {role}
              </Tag>
            )}
          </span>
        }
        avatar={
          <Avatar style={{ color: '#fff', backgroundColor: avatarMeta.backgroundColor }}>
            {avatarMeta.initials}
          </Avatar>
        }
        content={
          !edit ? (
            <Slate editor={editor} value={replyMessage}>
              <Editable renderElement={renderElement} renderLeaf={renderLeaf} readOnly />
              {isMessageEdited(props.reply.createdAt, props.reply.updatedAt)}
            </Slate>
          ) : (
            <div style={{ marginTop: '45px' }}>
              <SubEditor
                editor="edit"
                show={edit}
                hide={() => setEdit(false)}
                parentId={reply.id}
                defaultValue={replyMessage}
                id={id}
                type={type}
              />
            </div>
          )
        }
        datetime={
          <Tooltip title={moment(reply.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
            <span>{moment(reply.createdAt).fromNow()}</span>
          </Tooltip>
        }
        style={edit ? { backgroundColor: '#f4f7fa' } : {}}
      />

      {/* Only visible if delete is pushed */}
      {deleteThis && (
        <div className="delete-fade">
          <div className="row-fade-out" style={{ width: 'auto' }}>
            <div className="row-item-fade-out" style={{ marginBottom: '15px' }}>
              <IntlMessages id="app.confirm.sure" cap />
            </div>
            <div className="row-item-fade-out">
              <Button type="danger" onClick={() => finalDeleteComment()}>
                <IntlMessages id="desc.Yes" cap />
              </Button>{' '}
              <Button onClick={() => setDeleteThis(false)}>
                <IntlMessages id="desc.No" cap />
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

/**
 * @description Main comment. Also loads replies through TheReply component
 * @param {*} props
 * @returns
 */
const TheComment = (props) => {
  const locale = useLocale();

  const [deleteThis, setDeleteThis] = useState(false);

  const { update, setUpdate } = useContext(MessageContext);

  const [editor] = useState(() => {
    const newEditor = withReact(createEditor());
    newEditor.tmp = {};
    newEditor.meta = { noContract: true };
    return newEditor;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElement = useCallback((props) => <RenderElements {...props} editor={editor} />, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderLeaf = useCallback((props) => <RenderLeaf {...props} editor={editor} />, []);

  /* Props/Comment stuff */
  const { authors, comment, id, type } = props;
  const message = comment.data.message;

  const [reply, setReply] = useState(false);
  const [edit, setEdit] = useState(false);

  /* This triggers reply editor */
  const isReply = () => {
    setReply(!reply);
  };

  /* This triggers edit editor */
  const isEdit = () => {
    setEdit(!edit);
  };

  const deleteComment = () => {
    setDeleteThis(!deleteThis);
  };

  const finalDeleteComment = () => {
    /* Delete replies */
    if (comment.children && comment.children.length) {
      comment.children.forEach((elem) => {
        api
          .delete(`/messages/${elem.id}`)
          .then((r) => {})
          .catch((e) => console.log('Failed to delete comment', e));
      });
    }

    api
      .delete(`/messages/${comment.id}`)
      .then((res) => {
        setUpdate(!update);
        setDeleteThis(false);
      })
      .catch((err) => console.log('Failed to delete comment', err));
  };

  /**
   * @description loading replies through TheReply component
   * @returns replies
   */
  const loadReplies = () => {
    return comment.children.map((reply, i) => {
      return <TheReply key={i} reply={reply} authors={authors} isReply={isReply} id={id} type={type} />;
    });
  };

  /* Declare latest reply meta */
  const replySize = comment.children.length;
  const lastAuthor =
    comment.children[comment.children.length - 1] &&
    userToName(authors[comment.children[comment.children.length - 1].entityId]);
  const lastDate =
    comment.children[comment.children.length - 1] && comment.children[comment.children.length - 1].createdAt;

  /* Author */
  const authorEntity = authors[comment.entityId];
  const name = AuthorName(authorEntity);
  const avatarMeta = {
    initials: AuthorInitials(name),
    backgroundColor: authorEntity ? authorEntity.avatar.color : '#ccc',
  };
  const role = 'owner';

  moment.locale(locale);

  return (
    <li>
      <Comment
        actions={[
          edit ? (
            []
          ) : (
            <Actions
              setReply={isReply}
              setEdit={isEdit}
              deleteThis={deleteComment}
              author={comment.entityId}
            />
          ),
        ]}
        author={<span>{name}</span>}
        content={
          !edit ? (
            <Slate editor={editor} value={message}>
              <Editable renderElement={renderElement} renderLeaf={renderLeaf} readOnly />
              {isMessageEdited(props.comment.createdAt, props.comment.updatedAt)}
            </Slate>
          ) : (
            <div style={{ marginTop: '45px' }}>
              <SubEditor
                editor="edit"
                show={edit}
                hide={() => setEdit(false)}
                parentId={comment.id}
                defaultValue={message}
                id={id}
                type={type}
              />
            </div>
          )
        }
        datetime={
          <Tooltip title={moment(props.comment.createdAt).format('YYYY-MM-DD HH:mm:ss')} placement="top">
            <span>{moment(props.comment.createdAt).fromNow()}</span>
          </Tooltip>
        }
      >
        {replySize > 0 && (
          <Collapse className="custom-collapse">
            <Panel
              className="custom-panel"
              header={<PanelHeader size={replySize} lastAuthor={lastAuthor} lastDate={lastDate} />}
            >
              {loadReplies()}
            </Panel>
          </Collapse>
        )}
      </Comment>
      <SubEditor
        editor="reply"
        show={reply}
        hide={() => setReply(false)}
        parentId={comment.id}
        id={id}
        type={type}
      />

      {/* Only visible if delete is pushed */}
      {deleteThis && (
        <div className="delete-fade">
          <div className="row-fade-out" style={{ width: 'auto' }}>
            <div className="row-item-fade-out" style={{ marginBottom: '15px' }}>
              <IntlMessages id="app.confirm.sure" cap />{' '}
              <IntlMessages id="app.messages.repliesAlsoDeleted" cap />
            </div>
            <div className="row-item-fade-out">
              <Button type="danger" onClick={() => finalDeleteComment()}>
                <IntlMessages id="desc.Yes" cap />
              </Button>{' '}
              <Button onClick={() => setDeleteThis(false)}>
                <IntlMessages id="desc.No" cap />
              </Button>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};

/**
 * @description this wraps comments/replies, main editor is found in ./index.js -> editor
 * @param {*} props
 * @returns
 */
const CommentsWrapper = ({ id, type }) => {
  /* Trigger refresh of fetchComments() */
  const { update /* setUpdate */ } = useContext(MessageContext);

  useEffect(() => {
    fetchComments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const [comments, setComments] = useState(null);
  const [authors, setAuthors] = useState(null);

  /* Fetching comments */
  const fetchComments = async () => {
    const include = [
      { model: 'MessageRelation', as: 'MessageRelationChildren' },
      { model: 'MessageRelation', as: 'MessageRelationParents' },
    ];
    const includeQuery = encodeURI(JSON.stringify(include));
    await api
      .get(`/messages?resourceType=${type}&resourceId=${id}&include=${includeQuery}`)
      .then((res) => setComments(res.data))
      .catch((err) => console.log('error fetching comments', { err }));
  };

  /* Accumulates all users ids into single array and loads user object based on ids in array. Helps match comment with correct user */
  const fetchEntities = async () => {
    const replyingEntityIds = [
      ...new Set(comments.map((comment, i) => comment.children.map((children) => children.entityId))),
    ];
    const entityIds = [
      ...new Set(
        comments.map((comment) => comment.entityId),
        replyingEntityIds
      ),
    ];

    if (entityIds.length === 0) {
      return;
    }

    const idsQuery = encodeURI(JSON.stringify(entityIds));

    try {
      const entities = await api.get(`/entities?ids=${idsQuery}`);
      if (entities && entities.data) {
        setAuthors(
          entities.data.reduce((acc, curr) => {
            /* curr['avatar'] = {
              color: AvatarColor(curr),
            }; */
            acc[curr.id] = {
              ...curr,
              avatar: {
                color: AvatarColor(curr),
              },
            };
            return acc;
          }, {})
        );
      }
    } catch (err) {
      console.log('Err fetching entities ', err);
    }
  };

  useEffect(() => {
    fetchComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!comments) return;
    fetchEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments]);

  const noComments = (
    <Empty
      description={
        <span>
          <IntlMessages id="app.messages.noMessages" cap />
        </span>
      }
    />
  );

  if (!comments || !authors) return null;

  const loadComments = () => {
    return comments.map((item, i) => {
      return <TheComment key={i} comment={item} authors={authors} id={id} type={type} />;
    });
  };

  return (
    <>
      <List
        className="comment-list"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={() => {
          return comments && comments.length ? loadComments().reverse() : null;
        }}
      />
    </>
  );
};

export default CommentsWrapper;
