import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Col, Row, Card, Spin } from 'antd';

import EntityHeader from '../../components/Headers/EntityHeader/index';
import { Entity } from 'core/interfaces';
import Overview from './Overview';
import OrgChart from './OrgChart';
import Subsidiaries from './Subsidiaries';
import Agents from './Agents';
import { fetchEntity, fetchEntityStructure } from '../../utils/entities';
import Info from '../../components/entity/Info';
import Projects from '../../components/entity/Projects';
import Comments from '../../components/Comments';
import { gutter } from 'config';

export default function Group(props) {
  const { match, location } = props;
  const { id } = match.params;

  const [entity, setEntity] = useState(null);
  const [entitiesStructure, setEntitiesStructure] = useState(null);

  const isRealPerson = entity?.type === 'RealPerson';

  useEffect(() => {
    if (!id) {
      return console.log('No entity id.');
    }
    if (entity && entity.id === id) {
      return
    }

    (async () => {
      const newEntity = await fetchEntity(id, { withParent: true });
      setEntity(newEntity);
    })();
    (async () => {
      const newEntity = await fetchEntityStructure(id);
      setEntitiesStructure(newEntity);
    })();
  }, [entity, id]);

  if (!entity || entity.id !== id) {
    return (
      <Card title="" className="card">
        <Spin className="loader-container" />
      </Card>
    );
  }

  const updateEntity = (updatedEntity) => {
    console.log('Shall update entity ', {
      updatedEntity,
      isNew: updatedEntity !== entity,
    });
    if (!Entity.isEntity(updatedEntity)) {
      console.log('Invalid entity ', updatedEntity);
      return;
    }
    setEntity(JSON.parse(JSON.stringify(updatedEntity)));
  };

  const structureLength = entitiesStructure ? entitiesStructure.length : 1;
  const hasStructure = structureLength > 1;
  return (
    <div>
      <EntityHeader
        entity={entity}
        location={location}
        hasStructure={hasStructure}
        structureLength={structureLength}
      />
      <div className="padded-page">
        <Row gutter={gutter}>
          <Col xl={16} lg={14} md={14} sm={24} xs={24}>
            <Info entity={entity} updateEntity={updateEntity} />
            {!isRealPerson && (
              <Agents entity={entity} updateEntity={updateEntity} />
            )}
            <Comments entity={entity} id={id} type="Entity" />
          </Col>
          <Col xl={8} lg={10} md={10} sm={24} xs={24}>
            <Projects entity={entity} updateEntity={updateEntity} />
            {!isRealPerson && entitiesStructure && (
              <div className="overview-actions">
                <OrgChart
                  id={id}
                  entity={entity}
                  entities={entitiesStructure}
                  updateEntity={updateEntity}
                />
                <Subsidiaries
                  id={id}
                  entity={entity}
                  entities={entitiesStructure}
                  updateEntity={updateEntity}
                />
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}
