import { setAutoFreeze } from 'immer';
import uuid from 'uuid-random';
import { Editor, Text, Node } from '../../../../import/slate';

// Ensure immer does not lock nodes.
setAutoFreeze(false);

function entryId(node) {
  return node.__draftId;
}
function _makeNodeId(node) {
  if (!node.__draftId) node.__draftId = uuid();
}

// Build NodeEntries. Run by engine at beginning of draft session.
export const buildNodeEntries = function () {
  const { editor, api } = this;
  if (this._nodeEntriesList) return;
  this._nodeEntriesList = new Map();
  const _boundFnAddActionsToNodeEntry = this._addActionsToNodeEntry.bind(this);

  [...api.slate.Node.elements(editor)].forEach((tuple) => {
    this._createNodeEntry(tuple, _boundFnAddActionsToNodeEntry);
  });

  console.log('Begin draft. Number of entries.', this._nodeEntriesList.size);
};

// Clear NodeEntries. Run by engine at end of draft session.
export const clearNodeEntries = function () {
  console.log('End draft. Number of entries.', this._nodeEntriesList.size);
  const duplSet = {};

  for (const { nodePathRef, node } of this._nodeEntriesList.values()) {
    if (duplSet[node.data.item_id]) {
      duplSet[node.data.item_id].push(node);
    } else duplSet[node.data.item_id] = [node];
    nodePathRef.unref();
  }
  const dupl = Object.entries(duplSet)
    .filter(([, nodes]) => nodes.length > 1)
    .reduce((acc, curr) => {
      acc[curr[0]] = curr[1];
      return acc;
    }, {});
  const allNodes = [...this.api.slate.Node.elements(this.editor)];

  const nodesLeftBehind = [...this._nodeEntriesList]
    .filter(([, { node }]) => !allNodes.find(([nodeOfAll]) => node.data.item_id === nodeOfAll.data.item_id))
    .map(([, entry]) => entry.node);
  console.log('Duplicated nodeEntries nodes (should be 0) ', dupl);
  console.log('Nodes left beheind (should be 0)', nodesLeftBehind);

  delete this._nodeEntriesList;
  [...this.api.slate.Node.elements(this.editor)].forEach(([node]) => delete node.__draftId);
};

/* Core nodeEntries' functions */
/*******************************/
export const _addActionsToNodeEntry = function (nodeEntry) {
  const setNodeData = this.setNodeData.bind(this);
  const replaceChildren = this.replaceChildren.bind(this);
  const insertChildren = this.insertChildren.bind(this);
  const removeChildren = this.removeChildren.bind(this);
  const replaceText = this.replaceText.bind(this);

  return {
    ...nodeEntry,
    actions: {
      setNodeData: (key, value) => setNodeData(nodeEntry, key, value),
      replaceChildren: (newChildren) => replaceChildren(nodeEntry, newChildren),
      insertChildren: (newChildren, index) => insertChildren(nodeEntry, newChildren, index),
      removeNode: () => removeChildren(nodeEntry),
      replaceText: (text, setValue) => replaceText(nodeEntry, text, setValue),
    },
  };
};
export const _getNodeEntryParents = function (nodePath, actionCreator) {
  const actionCreatorFn = actionCreator || this._addActionsToNodeEntry.bind(this);
  const parentsArray = Array.from(this.api.slate.Node.ancestors(this.editor, nodePath)).slice(1).reverse();
  return parentsArray.map((parentTuple) => this._createNodeEntry(parentTuple, actionCreatorFn));
};

export const _getNodeInEntriesList = function (node) {
  const id = entryId(node);
  return id && this._nodeEntriesList.get(id);
};

export const _createNodeEntry = function (tuple, actionCreator) {
  const [node, path] = tuple;

  const existingEntry = this._getNodeInEntriesList(node);
  if (existingEntry) {
    return existingEntry;
  }

  const actionCreatorFn = actionCreator || this._addActionsToNodeEntry.bind(this);
  let parents = [];
  if (path.length > 1) {
    parents = this._getNodeEntryParents(path, actionCreatorFn);
  }

  let nodePathRef;
  if (existingEntry) nodePathRef = existingEntry.nodePathRef;
  else nodePathRef = Editor.pathRef(this.editor, path);

  _makeNodeId(node);

  const nodeEntry = {
    node,
    nodePathRef,
    parents,
  };

  const actionableNodeEntry = actionCreatorFn(nodeEntry);
  this._nodeEntriesList.set(entryId(node), actionableNodeEntry);
  return actionableNodeEntry;
};

export const _destroyNodeEntry = function (node) {
  const existingEntry = this._getNodeInEntriesList(node);
  if (!existingEntry) {
    console.trace('Destroy: No existing nodeEntry', node);
    return;
  }
  existingEntry.nodePathRef.unref();
  this._nodeEntriesList.delete(entryId(node));
};

export const updateNodeEntry = function (node, nodePath) {
  const existingEntry = this._getNodeInEntriesList(node);
  if (!existingEntry) {
    console.log('Update: No existing nodeEntry', node);
    return;
  }
  const actionCreatorFn = this._addActionsToNodeEntry.bind(this);

  this._nodeEntriesList.delete(entryId(node));

  const updatedNode = this.api.slate.Node.get(this.editor, nodePath);
  _makeNodeId(updatedNode);
  const nodeEntry = {
    node: updatedNode,
    nodePathRef: existingEntry.nodePathRef,
    parents: existingEntry.parents,
  };

  const actionableNodeEntry = actionCreatorFn(nodeEntry);
  this._nodeEntriesList.set(entryId(updatedNode), actionableNodeEntry);
};

export const addNodeEntries = function (
  firstElementPath,
  elementOrElements,
  isReplacement = false,
  options = {}
) {
  // When node(s) are added, we update the list of nodeTupleList to ensure that functions and
  // handlers accessing `nodeTupleList` after removal, also receive added nodes.
  const { nested = false, debug = false } = options;
  try {
    const parentPath = isReplacement ? firstElementPath : firstElementPath.slice(0, -1);
    const insertIndex = isReplacement ? 0 : firstElementPath.slice(-1)[0]; // Where (among the elements' parents children) all elements are inserted.
    const elements = Array.isArray(elementOrElements) ? elementOrElements : [elementOrElements];
    const elementsLength = elements.length;

    for (let i = 0; i < elementsLength; i++) {
      const element = elements[i];
      if (Text.isText(element)) {
        continue;
      }
      // newPath is the path of the relevant element.
      // If the firstElementPath (where all elements are instructed to be inserted)
      // is [2, 5, 3] then for example two inserted `elements` should have paths
      // [2,5,3] (the first one) and [2,5,4] (the second one).
      const newPath = [...parentPath, insertIndex + i];
      this._createNodeEntry([element, newPath], null, true);
      if (debug) {
        console.log('Add replacement children to nodeEntriesList ', {
          isReplacement,
          element,
          newPath,
          nested,
          atLength: this._nodeEntriesList.size,
        });
      }

      // Add any children of element.
      if (element.children) {
        const newPathBase = [...newPath, 0];
        this.addNodeEntries(newPathBase, element.children, false, {
          debug,
          nested: true,
        });
      }
    }
  } catch (err) {
    console.log('Error in _addNodeEntries', err);
  }
};

export const removeFromNodeEntries = function (nodeOrNodes) {
  if (Node.isNodeList(nodeOrNodes)) {
    for (const element of nodeOrNodes) this.removeFromNodeEntries(element);
  } else if (Node.isNode(nodeOrNodes) && !Text.isText(nodeOrNodes)) {
    this._destroyNodeEntry(nodeOrNodes);
    if (Node.isNodeList(nodeOrNodes.children)) this.removeFromNodeEntries(nodeOrNodes.children);
  }
};
