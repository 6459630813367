import React, { createRef, useState, useEffect, useMemo, useCallback, useRef, memo } from 'react';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import HTML5Backend from 'react-dnd-html5-backend';
import CustomScrollbars from 'util/CustomScrollbars';
import DropZone from './DropZone';
import { combineNumber, makeRepeatableId, ocount } from 'core/utils/general';
import { useDrag } from 'react-dnd';
import { Card, Row, Col, Tooltip, notification, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  // Updates should only be made here, or otherwise updating the redux state (not internally within the chart)
  updateInputs,
  addRepeatable,
  setConnectedInputCards,
  setEntities,
  updateEntity,
} from 'appRedux/actions';
import Chart from 'components/orgchart/orgchart';
import { getContractValues, useRerender } from 'hooks';
import { Contract, Entity } from 'core/interfaces';
import defaultEntities from 'constants/state/Entities';
import EditEntity from 'components/entity/EditEntity';

const CardBody = ({ children, ...rest }) => {
  return <div {...rest}>{children}</div>;
};

const topLeftIcon = ['mdi mdi-table-edit', 'color: #2873ff'];
const bottomLeftIcon = ['mdi mdi-close-circle', 'color: #f35555'];
const bottomRightIcon = ['mdi mdi-plus-circle', 'color: #399039'];

function findInRepeatableObject(repeatableData, key, id) {
  if (!repeatableData) return;
  for (const [, value] of Object.entries(repeatableData)) {
    if (value[key] && value[key].id === id) return value;
  }
}

const bottomLeftPredicate = (entity, entities) => {
  return entities[entity.pid] && entities[entity.pid].pid;
}; // Only show if parent has a parent (i.e. always two nodes)

export default function OrgChart(props) {
  const dispatch = useDispatch();
  const [reR, setReR] = useState(0);

  const reduxEntities = useSelector((state) => state?.entities);
  const borrower = useSelector((state) => state?.input?.borrower);
  const guarantor = useSelector((state) => state?.input?.guarantor);
  const facility = useSelector((state) => state?.input?.facility);
  const __connectedCards = useSelector((state) => state?.input?.__connectedCards);

  const entities = props.entities || reduxEntities;

  /* console.log('entities here ', {
    entities,
    pE: props.entities,
    re: reduxEntities,
    __connectedCards,
    borrower,
  }); */

  const chartElem = useRef(null);
  const hasMounted = useRef(false);
  const droppableController = useRef();

  const [autoSelectNameOnEdit, setAutoSelectNameOnEdit] = useState(false);
  const [editingEntityId, setEditingEntityId] = useState(null);

  useEffect(() => {
    hasMounted.current = true;

    chartElem.current = document.getElementById('edit_ihd_orgchart');
    onDrawCallback(); // Call this first time after chart and entities have been set up
  }, []);

  const contract = getContractValues();
  const language = contract && Contract.getLanguage(contract);

  const setupDefaultEntities = () => {
    try {
      const newEntities = JSON.parse(JSON.stringify(defaultEntities));
      if (!Array.isArray(newEntities)) return console.log('Invalid DefaultEntities');
      dispatch(setEntities(newEntities));
    } catch (err) {}
  };

  const customLayout = useCallback(
    (entity) => {
      // console.log('Render custom layout', __connectedCards);
      let colorBlobs = '';

      const entityBorrower = borrower && findInRepeatableObject(borrower, 'borrowerEntity', entity.id);
      const facilities = facility;

      if (entityBorrower && facilities && ocount(facilities) > 0) {
        const uid = entityBorrower['_uid'];
        const availableFacs =
          __connectedCards &&
          __connectedCards.filter((cc) => {
            if (cc.key !== 'facilityAvailableToBorrower') return false;
            if (cc.cards?.borrower !== uid) return false;
            if (!cc.cards?.facility) return false;
            if (!cc.value) return false;
            return true;
          });
        if (availableFacs && availableFacs.length > 0) {
          colorBlobs += availableFacs
            .map((cc) => {
              const facility = facilities[cc.cards.facility];
              if (!facility?._meta?.color) return null;
              return (
                '<div class="drop-indicator" style="background-color:' + facility['_meta'].color + '"></div>'
              );
            })
            .join('');
        }
      }
      const entityGuarantor = guarantor && findInRepeatableObject(guarantor, 'guarantorEntity', entity.id);
      if (entityGuarantor)
        colorBlobs += '<div class="drop-indicator guarantor"><i class="mdi mdi-account-key"></i></div>';

      return colorBlobs;
    },
    [__connectedCards, borrower, facility, guarantor]
  );

  const layout = useMemo(() => {
    return [
      // each array item a row

      [
        // each item a column
        {
          class: 'nodename',
          align: 'center',
          dataField: 'firstName',
          id: 'orgchart_name',
        },
      ],
      [
        {
          func: customLayout,
        },
      ],
      /*
      [ 
        {
          class: '',
          align: 'center',
          metaDataField: 'classNames',
          id: 'orgchart_name'
        },
      ]
      */
    ];
  }, [customLayout]);

  const onEntityInsert = useCallback((entity) => {
    setAutoSelectNameOnEdit(true);
    setEditingEntityId(entity.id);
    if (props.onEntityInsert) {
      props.onEntityInsert(entity);
    }
  }, []);

  const onEntityRemove = useCallback((id) => {
    console.log('Entity removed ');
    if (props.onEntityRemove) {
      props.onEntityRemove(id);
    }
  }, []);

  const onEntityMove = useCallback((movedId, newParentId, oldParentId) => {
    console.log('Entity Moved ');
    if (props.onEntityMove) {
      props.onEntityMove(movedId, newParentId, oldParentId);
    }
  }, []);

  const clickEntityEvent = useCallback((sender, args) => {
    console.log('click entity ', args);
    /* if (args.node && args.node.id) {
      setEditingEntityId(args.node.id);
    } */
  }, []);

  const onSetEditEntity = useCallback((id) => {
    setEditingEntityId(id);
  }, []);

  const cancelEditEntity = () => {
    setAutoSelectNameOnEdit(false);
    setEditingEntityId(null);
  };
  const editEntityCallback = () => {
    cancelEditEntity(true);
  };

  const editEvent = useCallback((orgchart, event) => {
    if (event.action === 'insert') {
      orgchart.expandNode(event.parentId);
    }
  }, []);

  const dropFunc = useCallback(
    (targetId, droppedItem) => {
      // console.log('I dropped.');
      const entity = entities.find((item) => item.id === targetId);
      if (!entity) {
        console.log('No entity found for id ' + targetId);
        return;
      }

      if (entity.tags.includes('_dummyOwner')) {
        return notification.warn({
          message: 'The Owner shall not be a borrower or guarantor',
        });
      }

      const cardId = droppedItem.data.targetCard; // Added card, e.g. 'borrower'
      const targetCardId = droppedItem.data.type; // Dropped item card, e.g. 'facility'
      const targetCardUid = droppedItem.data.targetUid; // Dropped item index, e.g. 0

      let entityHasCapacity =
        cardId === 'borrower'
          ? findInRepeatableObject(borrower, 'borrowerEntity', entity.id)
          : findInRepeatableObject(guarantor, 'guarantorEntity', entity.id);

      // Off case for borrowers. If the entity is already a borrower - do not add it as borrower,
      // just make the dropped facility available.
      if (typeof entityHasCapacity !== 'undefined') {
        // If already a guarantor - nothing more to do.
        if (cardId === 'guarantor') return;

        // The entity is already a borrower, ensure it has access to the dropped facility.
        const companyUid = entityHasCapacity['_uid'];

        dispatch(
          setConnectedInputCards({
            key: 'facilityAvailableToBorrower',
            sourceCardId: cardId,
            sourceUid: companyUid,
            targetCardId: targetCardId,
            targetUid: targetCardUid,
            value: true,
          })
        );
      } else {
        // The entity on which the facility was dropped was not previously a Borrower - add it.

        const newBorrowerUid = makeRepeatableId(cardId);
        if (cardId === 'borrower') {
          dispatch(
            addRepeatable(
              'input.' + cardId,
              {
                borrowerEntity: {
                  id: targetId,
                  type: 'entity',
                },
              },
              newBorrowerUid,
              true
            )
          );
          dispatch(
            setConnectedInputCards({
              key: 'facilityAvailableToBorrower',
              sourceCardId: cardId,
              sourceUid: newBorrowerUid,
              targetCardId: 'facility',
              targetUid: targetCardUid,
              value: true,
            })
          );
        } else if (cardId === 'guarantor') {
          dispatch(
            addRepeatable(
              'input.' + cardId,
              {
                guarantorEntity: {
                  id: targetId,
                  type: 'entity',
                },
              },
              newBorrowerUid
            )
          );
        }
      }
      setTimeout(() => {
        setReR(Math.random());
      }, 300);
      setTimeout(() => {
        if (typeof droppableController.current === 'function') {
          droppableController.current();
        }
      }, 1000);
    },
    [borrower, dispatch, entities, guarantor]
  );

  const onDrawCallback = useCallback((orgchart, forced = false) => {
    if (!hasMounted.current) {
      return;
    }

    if (chartElem.current) {
      // Update the dom nodes of DroppableEntitiesDomNodes. Will cause a re-render so that we can add new dropZones to the nodes.
      // This will not cause the underlying chart itself to re-render.
      // Use set timeout as it will otherwise update the state during render (as the callback function in orgchart(chart2.js) was caused by our previous render)
      setTimeout(() => {
        if (typeof droppableController.current === 'function') {
          droppableController.current();
        }
      }, 100);
    }

    return;
  }, []);

  if (!entities || entities.length === 0) {
    return (
      <div className="p-4 text-center">
        <div>
          <IntlMessages id="app.orgchart.noEntities" />
        </div>
        <div>
          <Button className="mt-4" onClick={setupDefaultEntities}>
            <IntlMessages id="app.orgchart.createBasic" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      {editingEntityId && (
        <EditEntity
          container={'drawer'}
          autoSelectName={autoSelectNameOnEdit}
          id={editingEntityId}
          entity={Entity.getById(entities, editingEntityId)}
          onCancel={cancelEditEntity}
          onFinishCallback={editEntityCallback}
          redux={true}
          updateApi={true}
        />
      )}
      <div>
        {entities && entities.length > 0 ? (
          <DndProvider backend={HTML5Backend}>
            <DroppableEntitiesDomNodes
              chartElem={chartElem}
              dropFunc={dropFunc}
              controller={droppableController}
            />

            <Row>
              <Col span={20} className={props.onlyView ? '' : 'mt-5'}>
                {props.onlyView ? null : <DragBoxes language={language} />}

                <Chart
                  chartId={'edit_'}
                  entities={entities}
                  editable={true}
                  layout={layout}
                  editCallback={editEvent}
                  onEntityInsert={onEntityInsert}
                  onEntityRemove={onEntityRemove}
                  onEntityMove={onEntityMove}
                  clickEntityEvent={clickEntityEvent}
                  onDrawCallback={onDrawCallback}
                  topLeftAction={onSetEditEntity}
                  topLeftIcon={topLeftIcon}
                  topRightAction={'none'}
                  topRightIcon={'none'}
                  bottomLeftPredicate={bottomLeftPredicate}
                  bottomLeftIcon={bottomLeftIcon}
                  bottomRightIcon={bottomRightIcon}
                  topCoId={props.topCoId}
                  topCoEntity={props.topCoEntity}
                />
              </Col>

              <Col span={4} className="companies-list-holder">
                <h6>
                  <IntlMessages id="app.orgchart.Entities" />
                </h6>
                <CustomScrollbars className="companies-list">
                  {entities.map((entity) => {
                    if (entity.parentId === null) return null;
                    return (
                      <Card key={entity.id}>
                        <CardBody onClick={() => setEditingEntityId(entity.id)}>
                          <b>{Entity.name(entity)}</b>
                          <br />
                          {entity.identificationNumber}
                        </CardBody>
                      </Card>
                    );
                  })}
                </CustomScrollbars>
              </Col>
            </Row>
          </DndProvider>
        ) : null}
      </div>
    </>
  );
}

const DroppableEntitiesDomNodes = memo(({ controller, chartElem, dropFunc }) => {
  const rerender = useRerender();
  const [reR, setReR] = useState(0);

  useEffect(() => {
    controller.current = () => {
      setTimeout(() => {
        setReR(Math.random());
      }, 100);
      // rerender();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log('Actually re-render DroppableEntitiesDomNodes')

  if (!chartElem.current) return null;

  const domNodes = Array.from(chartElem.current.getElementsByClassName('achartnode'));
  // console.log('Re-render droppable domNodes ', domNodes);
  if (!domNodes) {
    return null;
  }
  // portals
  return domNodes.map((node) =>
    ReactDOM.createPortal(
      <DropZone
        targetNode={<div className="drop-zone"></div>}
        id={node.getAttribute('node-id')}
        dropFunc={dropFunc}
      />,
      node
    )
  );
});

const DragBoxes = memo((props) => {
  const { language } = props;
  const { facility } = useSelector(({ input, entities }) => ({
    facility: input.facility || {},
  }));

  const dragFacilityTooltip =
    language === 'sv'
      ? 'Lägg till facilitet till bolag genom att dra till rätt entitet'
      : 'Add facilty to company by dragging it onto the relevant company';

  const dragGuarantorTooltip =
    language === 'sv'
      ? 'Lägg till Borgensman genom att dra till rätt entitet'
      : 'Add a company as guarantor by dragging this icon onto the relevant company';

  let facilities;
  if (facility && ocount(facility) > 0) {
    facilities = Object.keys(facility).map((uid, index) => {
      const theFacility = facility[uid];
      let type =
        theFacility['facility/type'] === 'choose' || !theFacility['facility/type']
          ? 'X'
          : theFacility['facility/type'].toUpperCase();
      let amount =
        theFacility['facility/currency'] +
        ' ' +
        Math.round((combineNumber(theFacility['facility/commitment'], language) / 1000000) * 10) / 10;
      return (
        <DragBox
          key={index}
          type={'facility'}
          data={{
            type: 'facility',
            targetCard: 'borrower',
            targetUid: uid,
          }}
          style={{
            borderColor: theFacility['_meta'].color,
          }}
        >
          <Tooltip title={dragFacilityTooltip} key={index}>
            <p>{type}</p>
            <p>{amount}</p>
          </Tooltip>
        </DragBox>
      );
    });
  }

  return (
    <Row className="drag-row">
      {!!facilities && facilities}
      <DragBox
        type={'guarantor'}
        data={{
          type: 'guarantor',
          targetCard: 'guarantor',
        }}
      >
        <Tooltip title={dragGuarantorTooltip}>
          <i className="mdi mdi-account-star" />
        </Tooltip>
      </DragBox>
    </Row>
  );
});

const DragBox = ({ type, data, children, style = {} }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type, data },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <div ref={drag} className={'drop-box drop-box-' + type} style={{ ...style, opacity }}>
      {children}
    </div>
  );
};
