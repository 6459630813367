import { Form, Input } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Label, AutoCompleteEntity } from '.';

export const EntityEditFieldIdentificationNumber = ({
  entityType,
  required = false,
  rules = [],
  onSearchSelect = () => {},
  searchData = {},
  ...rest
}) => {
  const formatMessage = useIntlMessage();

  return (
    <Form.Item
      {...rest}
      label={
        <Label>
          {entityType === 'RealPerson' ? (
            <IntlMessages id="app.general.PersonIdNumber" />
          ) : (
            <IntlMessages id="app.general.LegalPersonIdNumber" />
          )}
        </Label>
      }
      name="identificationNumber"
      rules={[
        {
          required,
          whitespace: true,
          message: formatMessage('app.persons.validation.identificationNumber'),
        },
        ...rules,
      ]}
    >
      <AutoCompleteEntity
        field="identificationNumber"
        inactive={entityType !== 'LegalPerson'}
        onSearchSelect={onSearchSelect}
      >
        <Input />
      </AutoCompleteEntity>
    </Form.Item>
  );
};
