import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Col, Row, Card, Spin, Button, Dropdown, Menu, Avatar, List } from 'antd';
import { useHistory } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setNumber, setTasks, updateTask, setTaskOutcome } from 'appRedux/actions';
import WidgetHeader from 'components/WidgetHeader/index';
import { useMountFetch } from 'hooks';
import { fixDate, ListItem } from 'components/ui';
import IntlMessages from 'util/IntlMessages';
import QuickDocumentView from 'components/project/Documents/QuickDocumentView';
import api from 'utils/api';
import { gutter } from 'config';

const APPROVE_REJECT_TASKS = ['document-approval', 'version-approval'];

function isTaskApproveReject(task) {
  return task && APPROVE_REJECT_TASKS.includes(task.type);
}

function filterTasks(tasks, filter) {
  if (!filter) return tasks;
  if (typeof filter === 'function') {
    return tasks.filter(filter);
  }
  if (filter && typeof filter === 'object') {
    return tasks.filter((task) => {
      let fail = false;
      for (const [key, value] of Object.entries(filter)) {
        if (task[key] !== value) {
          fail = true;
          break;
        }
      }
      return !fail;
    });
  }
}

export const useTasks = (props = {}) => {
  const { filter = null } = props;
  const reduxNumberPending = useSelector(({ common }) => common.numbers.pendingTasks);
  const tasks = useSelector(({ tasks }) => tasks);
  const filteredTasks = useRef(filterTasks(tasks, filter));
  const dispatch = useDispatch();

  function rupdateTask(newTask) {
    dispatch(updateTask(newTask.id, newTask));
  }

  function setTaskOutcomeStatus(id, value, options) {
    dispatch(setTaskOutcome(id, value, options));
  }

  useEffect(() => {
    filteredTasks.current = filterTasks(tasks, filter);
  }, [tasks, filter]);

  useEffect(() => {
    async function setPendingTasks() {
      const pendingTasksLength = tasks.filter((t) => t.status === 'pending').length;
      if (reduxNumberPending !== pendingTasksLength) {
        // console.log('Need update nums', pendingTasksLength);
        await api
          .get('/tasks/my')
          .then((res) => {
            if (!res.data) return;
            dispatch(setTasks(res.data));
          })
          .catch((err) => {
            console.log('Err setting tasks ', err);
          });
        dispatch(setNumber('pendingTasks', pendingTasksLength));
      }
    }
    setPendingTasks();
  }, [reduxNumberPending, tasks, dispatch]);

  return { tasks, filteredTasks: filteredTasks.current, updateTask: rupdateTask, setTaskOutcomeStatus };
};

const Tasks = (props) => {
  const { tasks, taskError, updateTask, setTaskOutcomeStatus } = useTasks({
    filter: {
      // resourceType: 'Version',
    },
  });

  // console.log('rerender tasks...');

  if (taskError) {
    return (
      <Card title="" className="card">
        <div>Cannot retrieve tasks at this time.</div>
      </Card>
    );
  }

  if (!tasks) {
    return (
      <Card title="" className="card">
        <Spin className="loader-container" />
      </Card>
    );
  }

  const pending = tasks.filter((task) => task.status === 'pending');
  const completed = tasks.filter((task) => task.status === 'completed');

  return (
    <>
      <div className="padded-page pb-4">
        <Row className="mb-4">
          <Col sm={24}>
            <div className="light-container p-5">
              <WidgetHeader
                title={
                  <>
                    <IntlMessages id="app.general.Pending" className="mr-1" />{' '}
                    <IntlMessages id="app.general.tasks.tasks" />
                  </>
                }
              />
              <div className="m-3">
                {pending.length === 0 ? (
                  <div>
                    <IntlMessages id="app.general.tasks.noPendingTasks" />
                  </div>
                ) : (
                  <Row gutter={gutter}>
                    {pending.map((task) => (
                      <Task
                        task={task}
                        key={task.id}
                        updateTask={updateTask}
                        setTaskOutcomeStatus={setTaskOutcomeStatus}
                      />
                    ))}
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4 mb-4">
          <Col sm={24}>
            <div className="light-container p-5">
              <WidgetHeader
                title={
                  <>
                    <IntlMessages id="app.general.Completed" className="mr-1" />{' '}
                    <IntlMessages id="app.general.tasks.tasks" />
                  </>
                }
              />
              <div className="m-3">
                {completed.length === 0 ? (
                  <div>
                    <IntlMessages id="app.general.tasks.noCompletedTasks" />
                  </div>
                ) : (
                  <Row gutter={gutter}>
                    {completed.map((task) => (
                      <Task
                        task={task}
                        key={task.id}
                        updateTask={updateTask}
                        setTaskOutcomeStatus={setTaskOutcomeStatus}
                      />
                    ))}
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

/*
assignedEntityId: "4fc1403e-1bbb-46d2-b474-9930a37c1736"
assignedEntityType: "User"
assignedOrgId: "51672ad1-97c1-428a-8b12-8bb9726c8503"
clientId: "51672ad1-97c1-428a-8b12-8bb9726c8503"
createdAt: "2021-09-23T16:11:02.000Z"
data: null
description: "Test"
id: "ab672ad1-97c1-428a-8b12-8bb9726c89c4"
info: null
name: "Heh"
outcomeStatus: "completed"
   |_
    'pending',
    'failed',
    'completed',
resourceId: "7cb226c3-4f2d-4715-9a87-be543fafeffb"
resourceType: "Version"
status: "pending"
    |_
     'pending',
     'initiated',
     'aborted',
     'completed',
type: "version-approval"
updatedAt: "2021-09-23T16:11:02.000Z"
userId: "4fc1403e-1bbb-46d2-b474-9930a37c1736"
*/

const Task = ({ task, updateTask, setTaskOutcomeStatus }) => {
  const { id, status, outcomeStatus } = task;

  const setOutcomeStatus = (value) => {
    setTaskOutcomeStatus(id, value);
    /* api
      .post('/tasks/' + id + '/outcomeStatus', { value })
      .then((res) => updateTask(res.data))
      .catch((err) => console.log('err', err.response)); */
  };

  const approveTask = () => {
    setOutcomeStatus('approved');
  };

  const rejectTask = () => {
    setOutcomeStatus('rejected');
  };

  const undeterminedTask = () => {
    setOutcomeStatus('pending');
  };

  const { details, actions } = useTaskContent(task, approveTask, rejectTask);

  let textClass, iconClass;

  switch (outcomeStatus) {
    case 'completed':
    case 'approved':
      textClass = 'text-success';
      iconClass = 'mdi-check-circle';
      break;
    case 'failed':
    case 'rejected':
      textClass = 'text-danger';
      iconClass = 'mdi-close-circle';
      break;
    default:
      textClass = 'text-muted';
      iconClass = 'mdi-help-circle';
  }

  // console.log('task ', task);

  const isTaskStandardActionable = isTaskApproveReject(task);
  const isCompleted = status === 'completed';
  const actionableOrCompleted = isTaskStandardActionable || isCompleted;

  return (
    <Col xl={8} lg={12} md={12} sm={12} xs={24}>
      <Card className="light-container widget">
        <Card.Meta
          className="text-center mb-2"
          title={<span style={{ fontWeight: 400 }}>{task.name}</span>}
          description={task.description}
        />
        <div>
          <div style={{ margin: '40px 0' }}>
            <table className="w-100">
              <tbody>
                <tr>
                  <td className="fs-sm mb-0">Task created</td>
                  <td className="fs-sm mb-0">{fixDate(task.createdAt)}</td>
                </tr>
                <tr>
                  <td
                    className="fs-sm"
                    // style={{ verticalAlign: 'top' }}
                  >
                    Requested by
                  </td>
                  <td>
                    <RequestedByUser userId={task.userId} />
                  </td>
                </tr>
                {/* <tr>
                <td className="text-grey fs-sm mb-0">Task details</td>
                <td className="text-grey fs-sm mb-0">{details}</td>
              </tr> */}
              </tbody>
            </table>
          </div>
          <div>{actions}</div>

          {status === 'pending' && isTaskStandardActionable && (
            <div className="">
              <h5>
                <IntlMessages id="app.general.tasks.handleTask" />
              </h5>
              <List className="document-actions" loading={false} itemLayout="horizontal" split={false}>
                <ListItem
                  title={<IntlMessages id="app.approval.approve" cap />}
                  desc={
                    <>
                      <IntlMessages id="app.approval.approve" cap />{' '}
                      <IntlMessages id="app.general.tasks.task" />
                    </>
                  }
                  mdiIcon="mdi-check"
                  onClick={approveTask}
                  loading={false}
                />
                <ListItem
                  title={<IntlMessages id="app.approval.reject" cap />}
                  desc={
                    <>
                      <IntlMessages id="app.approval.reject" cap />{' '}
                      <IntlMessages id="app.general.tasks.task" />
                    </>
                  }
                  mdiIcon="mdi-close"
                  onClick={rejectTask}
                  loading={false}
                />
              </List>
            </div>
          )}
          {isCompleted && (
            <div>
              <div className="mb-2">
                <span>Task Outcome:</span>
              </div>
              <div className="d-flex justify-content-space-between">
                <div>
                  <span>
                    <b className={'ml-1 text-uppercase ' + textClass}>
                      <i className={'mr-1 mdi ' + iconClass} />
                      {outcomeStatus}
                    </b>
                  </span>
                </div>
                {isTaskStandardActionable && (
                  <div>
                    <span>
                      <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item
                              key="approve"
                              onClick={approveTask}
                              disabled={outcomeStatus === 'approved'}
                            >
                              Approve
                            </Menu.Item>
                            <Menu.Item
                              key="reject"
                              onClick={rejectTask}
                              disabled={outcomeStatus === 'rejected'}
                            >
                              Reject
                            </Menu.Item>
                            <Menu.Divider />
                            <Menu.Item
                              key="pending"
                              onClick={undeterminedTask}
                              disabled={outcomeStatus === 'pending'}
                            >
                              Mark as pending
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <span className="link ant-dropdown-link">
                          Change <DownOutlined />
                        </span>
                      </Dropdown>
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </Card>
    </Col>
  );
};

function useTaskContent(task, approveTask, rejectTask) {
  const { type, resourceType, resourceId } = task;

  let details = null;
  let actions = null;

  switch (type) {
    case 'version-approval':
      if (resourceType !== 'Version' || !resourceId) {
        details = <InvalidTaskDetails />;
      } else {
        details = <ApproveVersionDetails task={task} approveTask={approveTask} rejectTask={rejectTask} />;
        actions = <ApproveVersionActions task={task} approveTask={approveTask} rejectTask={rejectTask} />;
      }
      break;
    case 'document-approval':
      if (resourceType !== 'Document' || !resourceId) {
        details = <InvalidTaskDetails />;
      } else {
        details = (
          <>
            <ApproveDocumentDetails task={task} approveTask={approveTask} rejectTask={rejectTask} />
          </>
        );
        actions = <ApproveDocumentActions task={task} approveTask={approveTask} rejectTask={rejectTask} />;
      }
      break;
    case 'document-signing':
      actions = <SignDocumentActions task={task} approveTask={approveTask} rejectTask={rejectTask} />;
      break;
    default:
      details = null;
      actions = null;
      break;
  }

  return { details, actions };
}

function ApproveVersionDetails({ task, approveTask, rejectTask }) {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>...</td>
            <td>...</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function ApproveVersionActions({ task, approveTask, rejectTask }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const goToVersion = () => {
    history.push('/versions/' + task.resourceId);
  };
  return (
    <>
      <div>
        <div className="d-flex">
          <h5>Approve version</h5>
        </div>
        <div>
          <List className="document-actions" loading={false} itemLayout="horizontal">
            <ListItem
              title={<IntlMessages id="desc.Open" />}
              desc={
                <span className="text-white">
                  <IntlMessages id="desc.Open" /> <IntlMessages id="general.version" />
                </span>
              }
              mdiIcon="mdi-book-open-page-variant"
              onClick={() => setOpen(true)}
              loading={false}
            />
            <ListItem
              title={<IntlMessages id="app.general.GoTo" />}
              desc={
                <span className="text-white">
                  <IntlMessages id="app.general.GoTo" /> <IntlMessages id="general.version" />
                </span>
              }
              mdiIcon="mdi-arrow-right-bold-circle-outline"
              onClick={goToVersion}
              loading={false}
            />
          </List>
        </div>
      </div>

      {open && (
        <QuickDocumentView
          versionId={task.resourceId}
          setQuickViewDoc={setOpen}
          title={'Document version for review'}
          actionFields={{
            view: false,
            mode: false,
            export: true,
          }}
          footer={
            <>
              <Button
                type="success"
                onClick={() => {
                  approveTask();
                  setOpen(false);
                }}
              >
                Approve
              </Button>
              <Button
                type="danger"
                onClick={() => {
                  rejectTask();
                  setOpen(false);
                }}
              >
                Reject
              </Button>
            </>
          }
        />
      )}
    </>
  );
}

function ApproveDocumentDetails({ task, approveTask, rejectTask }) {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>...</td>
            <td>...</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function ApproveDocumentActions({ task, approveTask, rejectTask }) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const goToDocument = () => {
    history.push('/documents/' + task.resourceId);
  };
  return (
    <>
      <div>
        <div>
          <List className="document-actions" loading={false} itemLayout="horizontal" split={false}>
            <ListItem
              title={<IntlMessages id="desc.Open" />}
              desc={
                <span className="text-white">
                  <IntlMessages id="desc.Open" /> <IntlMessages id="general.document" />
                </span>
              }
              mdiIcon="mdi-book-open-page-variant"
              onClick={() => setOpen(true)}
              loading={false}
            />
            <ListItem
              title={<IntlMessages id="app.general.GoTo" />}
              desc={
                <span className="text-white">
                  <IntlMessages id="app.general.GoTo" /> <IntlMessages id="general.document" />
                </span>
              }
              mdiIcon="mdi-arrow-right-bold-circle-outline"
              onClick={goToDocument}
              loading={false}
            />
          </List>
        </div>
      </div>

      {open && (
        <QuickDocumentView
          documentId={task.resourceId}
          finalVersion={true}
          setQuickViewDoc={setOpen}
          title={'Document version for review'}
          actionFields={{
            view: false,
            mode: false,
            export: true,
          }}
          footer={
            <>
              <Button
                type="success"
                onClick={() => {
                  approveTask();
                  setOpen(false);
                }}
              >
                Approve
              </Button>
              <Button
                type="danger"
                onClick={() => {
                  rejectTask();
                  setOpen(false);
                }}
              >
                Reject
              </Button>
            </>
          }
        />
      )}
    </>
  );
}

function SignDocumentActions({ task }) {
  const [scriveBaseUrl, setScriveBaseUrl] = useState(null);
  useMountFetch('/documentsignings/baseurl', setScriveBaseUrl);
  const history = useHistory();
  const goToDocument = () => {
    history.push('/documents/' + task.data.documentId);
  };
  const [signing, setSigning] = useState(null);
  const [signingError, setSigningError] = useState(null);
  const { resourceId, assignedEntityId } = task;
  const include = [{ model: 'Superfile' }];
  const includeQuery = encodeURI(JSON.stringify(include));
  useMountFetch(`/documentsignings/${resourceId}?include=${includeQuery}`, setSigning, setSigningError);

  const meAsParty = signing?.data?.parties?.find((p) =>
    p.fields.some((f) => f.type === 'text' && f.name === 'agent_entity_id' && f.value === assignedEntityId)
  );

  const openSignWindow = useCallback(() => {
    if (!scriveBaseUrl || !scriveBaseUrl.url || !meAsParty) return console.log('Fix.');
    const signUrl = `${scriveBaseUrl.url}${meAsParty.api_delivery_url}`;
    window.open(
      signUrl,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=768,height=1024'
    );
  }, [scriveBaseUrl, meAsParty]);

  if (signingError) {
    console.log('signings error ', signingError);
    return <div>Error loading signing.</div>;
  }
  if (!signing) {
    return <div>Loading . . .</div>;
  }

  return (
    <div>
      <Button icon={<i className="mdi mdi-arrow-right-bold-circle-outline" />} onClick={goToDocument} block>
        <IntlMessages id="app.general.GoTo" className="mr-1" />
        <IntlMessages id="general.document" />
      </Button>
      <Button
        type="primary"
        className="mt-2"
        icon={<i className="mdi mdi-pencil-lock" />}
        onClick={openSignWindow}
        block
      >
        <IntlMessages id="app.signing.list.sign" className="mr-1" />
        <IntlMessages id="general.document" />
      </Button>
    </div>
  );
}

function ViewVersion({ versionId, documentId, actions = [] }) {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  return (
    <>
      <ListItem
        title={<IntlMessages id="desc.Open" />}
        desc={
          <>
            <IntlMessages id="desc.Open" /> <IntlMessages id="general.document" />
          </>
        }
        mdiIcon="mdi-book-open-page-variant"
        onClick={() => setOpen(true)}
        loading={false}
      />
      {open && (
        <QuickDocumentView
          documentId={documentId}
          versionId={versionId}
          setQuickViewDoc={setOpen}
          title={'Document version'}
          actionFields={{
            view: false,
            mode: false,
            export: true,
          }}
          footer={actions.map((action, index) => (
            <Button
              key={action.key || index}
              type={action.btnType || 'secondary'}
              onClick={() => {
                if (typeof action.onClick === 'function') action.onClick();
                setOpen(false);
              }}
            >
              {action.btnText || ''}
            </Button>
          ))}
        />
      )}
    </>
  );
}

function InvalidTaskDetails() {
  return <div>Invalid task information</div>;
}

function RequestedByUser({ userId }) {
  const [person, setPerson] = useState(null);
  useMountFetch(`/clients/userEntity?id=${userId}`, setPerson);
  if (!person) return null;
  return `${person.firstName ?? ''} ${person.lastName ?? ''}`;
}

export default Tasks;
