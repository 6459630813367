import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setYjsSyncData } from 'appRedux/remoteSync/syncFunctions';
import { useIsTemplateStudio, useStudioAccessLevel } from 'hooks';
import { createProvider } from '../common';
import { createAwarenessUserData } from '../utils';
import manager from 'core/engine/manager';

const DATA_FIELD = 'data';

export function useConnectedState({ versionId, withDefaultUserData = true }) {
  const isSyncedRef = useRef(false);
  const isMounted = useRef(false);

  const isTemplate = useIsTemplateStudio();
  const onlineMode = !isTemplate;

  const dispatch = useDispatch();
  const [connected, setConnectedState] = useState(false);
  const [providerAuthStatus, setProviderAuthStatusState] = useState('NOT_AUTHED');
  const [onlineLoaded, setOnlineLoaded] = useState(false);
  const accessLevel = useStudioAccessLevel();
  const user = useSelector((state) => state.auth?.user || {});

  const userData = useMemo(
    () => createAwarenessUserData(onlineMode, user, accessLevel),
    [onlineMode, user, accessLevel]
  );

  const setConnected = useCallback((value) => {
    if (!isMounted.current) return false;
    setConnectedState(value);
  }, []);

  const setProviderAuthStatus = useCallback((value) => {
    if (!isMounted.current) return false;
    setProviderAuthStatusState(value);
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const { yMap, provider } = useMemo(() => {
    if (isTemplate || !versionId) return {};
    const _provider = createProvider(`state_${versionId}`, null, setConnected, setProviderAuthStatus);
    const _yMap = _provider.document.getMap('state');
    return { yMap: _yMap, provider: _provider };
  }, [isTemplate, versionId, setConnected, setProviderAuthStatus]);

  useEffect(() => {
    isSyncedRef.current = false;
    setOnlineLoaded(false);
  }, [provider]);

  useEffect(() => {
    if (!onlineMode) return;
    if (!provider) return;
    provider.connect();

    provider.on('synced', (data) => {
      if (data?.state !== true) return;

      const _sharedType = provider.document.getMap('state');
      const value = _sharedType.toJSON();
      dispatch(setYjsSyncData(value));
      setOnlineLoaded(true);
    });

    return () => {
      provider.awareness.destroy();
      provider.disconnect();
    };
  }, [provider, onlineMode, dispatch]);

  // Re-connect when changing accessLevel
  useEffect(() => {
    if (!isSyncedRef.current) return;
    provider.disconnect();
    setTimeout(() => {
      provider.connect();
    }, 300);
  }, [accessLevel, provider]);

  // Exported funtionality
  const setUserData = useCallback(
    (data) => provider?.awareness && provider.awareness.setLocalStateField(DATA_FIELD, data),
    [provider]
  );
  const setAwarenessItem = useCallback(
    (key, value) => provider?.awareness && provider.awareness.setLocalStateField(key, value),
    [provider]
  );
  const toggleConnection = useCallback(() => {
    if (!provider) return;
    if (connected) {
      return provider.disconnect();
    }
    provider.connect();
  }, [provider, connected]);

  // Update awareness on websocket on userData change
  useEffect(() => {
    if (!onlineMode || !userData || !withDefaultUserData) return;
    setUserData(userData);
  }, [userData, onlineMode, setUserData, withDefaultUserData]);

  return {
    provider,
    yMap,
    connected,
    onlineLoaded,
    toggleConnection,
    providerAuthStatus,
    setUserData,
    setAwarenessItem,
  };
}
