import React, { useState, useEffect, useMemo, useRef, useReducer, useCallback } from 'react';
import {
  eligibleInputs,
  allowSameValue,
  defaultValue,
  getAvailableOperators,
  getAvailableOperatorText,
  numOfOperators,
  getSubRuleData,
  getSubRuleDataById,
  subRulesAreValid,
  rulesArray,
  subRuleReducer,
  conjunctions,
  firstKey,
  getAllCardInputFields,
} from '../../core/common';
import { GrayCheckbox } from '../ui';
import { Select, Input, InputNumber, Checkbox } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import uuid from 'uuid-random';

export default function NumOf({ contract, ui, language, ruleId, setters, rule, currentCard }) {
  let startOperator = '>=',
    startConjunction = 'and',
    startSubRules = {},
    startIsConditioned = false,
    startValue = 0,
    startCountAll = false;

  if (rule) {
    startOperator = firstKey(rule);
    const [firstNumOf, valueOrSecondNumof] = rule[startOperator];

    const numofContent = firstNumOf.numof;
    if (numofContent.condition) {
      startIsConditioned = true;
      startConjunction = firstKey(numofContent.condition);
      startSubRules = numofContent.condition[startConjunction].reduce((acc, curr) => {
        acc[uuid()] = curr;
        return acc;
      }, {});
    }

    if (typeof valueOrSecondNumof === 'object') startCountAll = true;
    else if (typeof valueOrSecondNumof === 'number') {
      startValue = valueOrSecondNumof;
    }
  }

  const [isConditioned, setIsConditioned] = useState(startIsConditioned);

  const [subRules, dispatchSubRules] = useReducer(subRuleReducer, startSubRules);

  const initialCard = useRef(currentCard);
  const hasMounted = useRef(false);

  const [countAll, setCountAll] = useState(startCountAll);
  // const [currentCard, setCurrentCard] = useState(startCurrentCard);
  const [masterOperator, setMasterOperator] = useState(startOperator);
  const [conjunction, setConjunction] = useState(startConjunction);
  const [value, setValue] = useState(startValue);

  const addSubRule = () => {
    dispatchSubRules({ type: 'add' });
  };

  const removeSubRule = (id) => {
    dispatchSubRules({ type: 'remove', id });
  };

  const onMasterOperatorChange = (value) => {
    setMasterOperator(value);
  };
  const onMasterValueChange = (value) => {
    if (typeof value !== 'number') return;
    setValue(value);
  };

  useEffect(() => {
    if (initialCard.current !== currentCard) {
      dispatchSubRules({ type: 'reset' });
    }
  }, [currentCard]);

  const onEnsureAllChange = (evt) => {
    if (evt.target.checked) {
      setCountAll(true);
      if (!isConditioned) setIsConditioned(true);
    } else setCountAll(false);
  };

  const onIsConditionChange = (evt) => {
    setIsConditioned(evt.target.checked);
  };

  const onConjunctionChange = (value) => {
    setConjunction(value);
  };

  const onInputFieldChange = (newInputName, id) => {
    const { operator, inputField: oldInputName, value } = getSubRuleDataById(subRules, id);

    const currentInputObject = (ui.inputs && ui.inputs[oldInputName]) || null;
    const newInput = (ui.inputs && ui.inputs[newInputName]) || null;
    if (!newInput) return console.log('Input [' + newInputName + '] not present amongst data.ui.inputs!');

    // Check if new input supports current operator. Otherwise, reset to ==
    const availableOperators = getAvailableOperators(newInput);

    if (!availableOperators.includes(operator)) {
      dispatchSubRules({
        type: 'set_operator',
        id,
        payload: '==',
      });
    }

    if (currentInputObject && !allowSameValue(currentInputObject, newInput)) {
      dispatchSubRules({
        type: 'set_value',
        id,
        payload: defaultValue(newInput),
      });
    } else if (value === null) {
      dispatchSubRules({
        type: 'set_value',
        id,
        payload: defaultValue(newInput),
      });
    }

    dispatchSubRules({
      type: 'set_input',
      id,
      payload: newInputName,
    });
  };
  const onOperatorChange = (value, id) => {
    dispatchSubRules({
      type: 'set_operator',
      id,
      payload: value,
    });
  };
  const onValueChange = (evt, id) => {
    if (evt === undefined || evt === null) return;
    let val;
    if (evt.target && evt.target.type === 'checkbox') {
      val = evt.target.checked;
    } else {
      val = typeof evt === 'string' || typeof evt === 'number' ? evt : evt.target.value;
    }

    dispatchSubRules({
      type: 'set_value',
      id,
      payload: val,
      ui,
    });
  };

  const subRulesLength = Object.keys(subRules).length;
  const numberValue = parseInt(value);

  const ruleIsValid = () => {
    if (!currentCard) return false;
    if (countAll) {
      return conjunction && subRulesLength > 0 && subRulesAreValid(subRules, ui);
    }
    if (!masterOperator || isNaN(numberValue) || typeof numberValue !== 'number') return false;
    if (isConditioned) return conjunction && subRulesLength > 0 && subRulesAreValid(subRules, ui);
    return true;
  };

  const validRule = ruleIsValid();

  const makeRule = useCallback(
    ({ countAll, masterOperator, currentCard, isConditioned, conjunction, subRules, numberValue }) => {
      let rule;

      let ruleOperator = countAll ? '==' : masterOperator;
      const content = {
        numof: { card: currentCard },
      };
      if (isConditioned) content.numof.condition = { [conjunction]: rulesArray(subRules) };

      const comparer = countAll ? { numof: { card: currentCard } } : numberValue;

      rule = { [ruleOperator]: [content, comparer] };
      return rule;
    },
    []
  );

  useEffect(() => {
    if (!hasMounted.current) return;
    if (!validRule) return;

    let rule = makeRule({
      countAll,
      masterOperator,
      currentCard,
      isConditioned,
      conjunction,
      subRules,
      numberValue,
    });

    // console.log('Rule is ', JSON.stringify(rule, null, 2));

    setters.current.setRule(ruleId, rule);
  }, [
    makeRule,
    validRule,
    setters,
    ruleId,
    currentCard,
    conjunction,
    masterOperator,
    countAll,
    numberValue,
    subRules,
    hasMounted,
    isConditioned,
  ]);

  useEffect(() => {
    setters.current.setValid(ruleId, validRule);
  }, [ruleId, validRule, setters]);

  useEffect(() => (hasMounted.current = true), []);

  // console.log('subRules ', { subRules, currentCard });

  return (
    <>
      <div style={{ marginTop: '0px' }} className="d-flex align-items-center">
        <GrayCheckbox
          checked={countAll}
          onChange={onEnsureAllChange}
          label={'ALL'}
          help={
            <div>
              <p>
                Check this option if you want to required <em>all</em>
              </p>
              <p>instances to comply with one or more conditions</p>
            </div>
          }
          helpType={'popover'}
        />
      </div>
      {!countAll && (
        <>
          <div style={{ margin: '7px 5px 5px 5px' }}>
            <small>HAS</small>
          </div>
          <div>
            <Select value={masterOperator} onChange={onMasterOperatorChange}>
              {numOfOperators.map((op) => {
                const label = getAvailableOperatorText(op, { numof: true });
                return (
                  <Select.Option key={op} value={op}>
                    {label}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <div>
            <InputNumber value={value} onChange={onMasterValueChange} min={0} />
          </div>
        </>
      )}

      <div style={{ marginTop: '2px', marginLeft: '5px' }} className="d-flex align-items-center">
        <small>INSTANCES</small>
        <GrayCheckbox
          checked={isConditioned}
          onChange={onIsConditionChange}
          label={'CONFORMING TO CONDITIONS:'}
          help={
            <div>
              <p>Check this option if you only want to count</p>
              <p>instances which matches certain condition(s)</p>
            </div>
          }
          helpType={'popover'}
        />
      </div>
      {isConditioned && (
        <>
          <div
            style={{
              flexBasis: '100%',
              width: '100%',
              minWidth: '100%',
              marginTop: '5px',
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '7px',
              }}
            >
              <div>
                <Select value={conjunction} onChange={onConjunctionChange}>
                  {conjunctions.map((conjunctionItem) => {
                    return (
                      <Select.Option key={conjunctionItem} value={conjunctionItem}>
                        {conjunctionItem.toUpperCase()}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div
                className="link"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '10px',
                  fontSize: '13px',
                }}
                onClick={addSubRule}
              >
                <PlusOutlined className="mr-2" />
                <span>Add Condition</span>
              </div>
            </div>

            {subRulesLength === 0 ? (
              <div style={{ width: '100%', border: '1px dashed #d9d9d9', padding: '10px' }}>
                Please add at least one condition
              </div>
            ) : (
              Object.keys(subRules).map((subRuleId) => {
                const subRule = subRules[subRuleId];
                const { operator, inputField: currentInputField, value } = getSubRuleData(subRule);
                // console.log('Sub rule data ? ', { operator, currentInputField, value })
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginLeft: '45px',
                    }}
                    key={subRuleId}
                  >
                    <div>
                      <CloseOutlined
                        className="clickable"
                        style={{ margin: '5px', marginRight: '10px', color: '#aaa' }}
                        onClick={() => removeSubRule(subRuleId)}
                      />
                    </div>
                    <InputField
                      contract={contract}
                      ui={ui}
                      currentCard={currentCard}
                      currentInputField={currentInputField}
                      onInputFieldChange={onInputFieldChange}
                      language={language}
                      subRuleId={subRuleId}
                    />
                    <Operators
                      ui={ui}
                      currentCard={currentCard}
                      currentInputField={currentInputField}
                      operator={operator}
                      onOperatorChange={onOperatorChange}
                      language={language}
                      subRuleId={subRuleId}
                    />
                    <Value
                      ui={ui}
                      currentCard={currentCard}
                      currentInputField={currentInputField}
                      operator={operator}
                      value={value}
                      onValueChange={onValueChange}
                      language={language}
                      subRuleId={subRuleId}
                    />
                  </div>
                );
              })
            )}
          </div>
        </>
      )}
    </>
  );
}

function InputField({
  contract,
  ui,
  currentCard,
  currentInputField,
  onInputFieldChange,
  language,
  subRuleId,
}) {
  const inputs_order = useMemo(
    () => getAllCardInputFields(currentCard, ui, contract),
    [currentCard, ui, contract]
  );

  // console.log('Sub rule ', { currentCard, inputs_order, currentInputField, subRuleId})

  if (!currentCard || !inputs_order) return null;

  if (!inputs_order) return null;
  return (
    <div>
      <Select
        onChange={(evt) => onInputFieldChange(evt, subRuleId)}
        value={currentInputField || undefined}
        placeholder={<span>Select input field</span>}
      >
        {inputs_order.map((inputFieldName) => {
          if (typeof inputFieldName !== 'string') {
            console.log('Input field name is not string ', { inputFieldName, inputs_order });
            return null;
          }
          const input = ui.inputs[inputFieldName];
          // console.log('Input xis ', { input, inputFieldName });
          if (!input) {
            console.log('Missing input data for ', { inputs_order, currentCard, ui, inputFieldName });
            return null;
          }
          if (!eligibleInputs.includes(input.type)) return null;
          const label = (input.label && input.label[language]) || inputFieldName;
          return (
            <Select.Option value={inputFieldName} key={inputFieldName}>
              {label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

function Operators({ ui, currentCard, currentInputField, operator, onOperatorChange, language, subRuleId }) {
  if (!currentCard || !currentInputField) return null;

  const input = (ui.inputs && ui.inputs[currentInputField]) || null;

  if (!input) return null;

  const availableOperators = getAvailableOperators(input);

  return (
    <div>
      <Select onChange={(evt) => onOperatorChange(evt, subRuleId)} value={operator}>
        {availableOperators.map((availableOperator) => {
          const label = getAvailableOperatorText(availableOperator);
          return (
            <Select.Option value={availableOperator} key={availableOperator}>
              {label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

function Value({ ui, currentCard, currentInputField, operator, value, onValueChange, language, subRuleId }) {
  const input = (ui.inputs && ui.inputs[currentInputField]) || null;

  if (!input) return null;

  if (input.type === 'select' || input.type === 'radio') {
    if (!input.content) {
      console.log('Invalid input.');
      return '-XXX-';
    }
    return (
      <div>
        <Select
          value={value || undefined}
          onChange={(evt) => onValueChange(evt, subRuleId)}
          placeholder={<span>Select input value</span>}
        >
          {input.content.map((item) => {
            if (item.disabled) return null;
            const label = (item.label && item.label[language]) || item;
            return (
              <Select.Option value={item.id} key={item.id}>
                {label}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  }

  if (input.type === 'checkbox') {
    return (
      <div>
        <Checkbox
          checked={value}
          onChange={(evt) => onValueChange(evt, subRuleId)}
          style={{
            margin: '5px',
          }}
        />
      </div>
    );
  }

  if (input.type === 'numeric' || input.type === 'numeric-steps') {
    return (
      <div>
        <InputNumber value={value} onChange={(evt) => onValueChange(evt, subRuleId)} />
      </div>
    );
  }

  return (
    <div>
      <Input type="text" value={value} onChange={(evt) => onValueChange(evt, subRuleId)} />
    </div>
  );
}
