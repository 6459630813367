import React from 'react';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import { Contract } from 'core/interfaces';
import { setStudioEditorInputContent } from 'hooks';

const { SubMenu } = Menu;

export function getStudioPageByPage(page) {
  if (!page || !page.paths) return null;
  return page.paths.toString();
}

export function getPageSteps({ activeFlatUiPages, page }) {
  const activeIndex = activeFlatUiPages.findIndex((p) => p === page);
  if (activeIndex === -1) {
    return {
      next: activeFlatUiPages[0],
      prev: null,
    };
  }
  return {
    next: menuStepFindNext(activeFlatUiPages, activeIndex),
    prev: menuStepFindPrev(activeFlatUiPages, activeIndex),
  };
}

export default function MenuBuilder({
  pages,
  pages_order,
  setStudioPage,
  activePagePaths,
  contract,
  ...rest
}) {
  if (!pages || !Array.isArray(pages_order)) {
    console.error('menuBuilder received no pages');
    return null;
  }

  const activePagesOrder = pages_order.filter((id) => activePagePaths[pages[id]?.paths]);

  return activePagesOrder
    .map((id) => (
      <InputMenuItem
        key={pages[id].paths.toString()}
        page={pages[id]}
        parentId={id}
        setStudioPage={setStudioPage}
        activePagePaths={activePagePaths}
        contract={contract}
        menuProps={rest}
      />
    ))
    .filter((item) => !!item);
}

export function getPageItemName(contract, page) {
  const language = Contract.getLanguage(contract);

  return (page && page.header && page.header[language]) || 'Unknown';
}

export function pageHasChildren(page) {
  return page.collapse && Array.isArray(page.pages_order) && page.pages_order.length > 0 && page.pages;
}

function InputMenuItem({ page, setStudioPage, activePagePaths, contract, menuProps }) {
  const studio_draftMode = useSelector(({ draft }) => draft.studio_draftMode);
  const header = getPageItemName(contract, page);
  const linkPath = getStudioPageByPage(page);

  function onClick() {
    if (studio_draftMode === 'inline') {
      setStudioEditorInputContent({ page });
    } else {
      setStudioPage(linkPath);
    }
  }

  const hasChildren = pageHasChildren(page);

  if (hasChildren) {
    return (
      <SubMenu title={header} {...menuProps} key={linkPath + 'submenu'} eventKey={linkPath + 'submenu'}>
        <MenuBuilder
          pages={page.pages}
          pages_order={page.pages_order}
          activePagePaths={activePagePaths}
          setStudioPage={setStudioPage}
          contract={contract}
          key={linkPath + 'submenu-content'}
        />
      </SubMenu>
    );
  } else {
    return (
      <Menu.Item onClick={onClick} {...menuProps} key={linkPath + 'mix'} eventKey={linkPath + 'mix'}>
        {header}
      </Menu.Item>
    );
  }
}

function menuStepFindNext(flatActiveAll, activeIndex) {
  // Find next starting from activeIndex
  for (let i = activeIndex + 1; i < flatActiveAll.length; i++) {
    if (flatActiveAll[i].collapse) continue;
    return flatActiveAll[i];
  }
  return null;
}
function menuStepFindPrev(flatActiveAll, activeIndex) {
  // Find previos starting from activeIndex
  if (activeIndex === 0) return null;
  for (let i = activeIndex - 1; i >= 0; i--) {
    if (flatActiveAll[i].collapse) continue;
    return flatActiveAll[i];
  }
  return null;
}
