export const initEachesSlate = {
  id: 'initEachesSlate',
  genesisHandler: true,
  reverseOrder: true,
  match: {
    repeatableTableCells: ({ entries, node, api }) => {
      if (!node.data || !node.data.each_repeatable || !node.data.each_repeatable.repeatable) return false;
      return node.type === 'table_cell';
    },
    repeatableTableRows: ({ entries, node, api }) => {
      if (!node.data || !node.data.each_repeatable || !node.data.each_repeatable.repeatable) return false;
      return node.type === 'table_row';
    },
    repeatableOther: ({ entries, node, api }) => {
      if (!node.data || !node.data.each_repeatable || !node.data.each_repeatable.repeatable) return false;
      return node.type !== 'table_row' && node.type !== 'table_cell';
    },
  },
  handler: function ({ matches, api }) {
    if (matches.repeatableOther) {
      for (const { node, actions } of matches.repeatableOther) {
        const template = JSON.stringify(node.children);
        const newChildren = this.generateEachPlaceholderChild(node);
        actions.setNodeData('template', template);
        actions.replaceChildren(newChildren);
      }
    }
    if (matches.repeatableTableRows) {
      for (const { node, actions, parents } of matches.repeatableTableRows) {
        const tableEntry = parents.find(({ node }) => node.type === 'table');
        if (!tableEntry || !tableEntry.node) continue;
        const { node: tableNode } = tableEntry;

        if (!tableNode.children || !Array.isArray(tableNode.children)) {
          console.log('Table node has invalid children', tableNode);
          continue;
        }
        // Find the node's index within the tableNode. For usage below.
        const nodeIndex = api.utils.engine.findIndex(tableNode.children, (item) => item === node);
        if (nodeIndex === -1) {
          this.log('Cannot find my nodeIndex (row within table)', { node, tableNode });
          continue;
        }

        const { each_repeatable } = node.data;
        const { repeatable } = each_repeatable;

        // Add row_repeatables to the parent table node's data.
        const row_repeatables = Array.isArray(tableNode.data.row_repeatables)
          ? tableNode.data.row_repeatables
          : [];

        row_repeatables.push({
          repeatable: repeatable,
          filter: node.data.each_repeatable.filter,
          filterComponents:
            node.data.each_repeatable.filter && api.logic.getComponents(node.data.each_repeatable.filter),
          content: JSON.stringify(nodeWithoutDataRepeatableEntry(node)),
        });

        tableEntry.actions.setNodeData({ row_repeatables: row_repeatables });

        // This relevant row (the `node`) was merely a template. It has
        // been stored to the table's data.row_repeatables and should
        // now be removed from the actual contract.
        // const newChildren = this.generateEachPlaceholderChild(node);
        // actions.replaceChildren(newChildren);
        actions.removeNode();
      }
    }
    if (matches.repeatableTableCells) {
      for (const { node, actions, parents } of matches.repeatableTableCells) {
        const tableEntry = parents.find(({ node }) => node.type === 'table');
        if (!tableEntry || !tableEntry.node) continue;
        const { node: tableNode } = tableEntry;

        const rowEntry = parents.find(({ node }) => node.type === 'table_row');
        if (!rowEntry || !rowEntry.node) {
          continue;
        }
        const { node: rowNode } = rowEntry;

        // Find the node's index within the tableNode. For usage below.
        const nodeIndex = api.utils.engine.findIndex(rowNode.children, (item) => item === node);

        if (nodeIndex === -1) {
          return this.log('Cannot find my nodeIndex (row within table)', { node, tableNode });
        }

        // Add column_repeatables to the parent table node's data.
        const column_repeatables = Array.isArray(tableNode.data.column_repeatables)
          ? tableNode.data.column_repeatables
          : [];

        column_repeatables.push({
          repeatable: node.data.each_repeatable.repeatable,
          filter: node.data.each_repeatable.filter,
          filterComponents:
            node.data.each_repeatable.filter && api.logic.getComponents(node.data.each_repeatable.filter),
          content: JSON.stringify(nodeWithoutDataRepeatableEntry(node)),
        });

        tableEntry.actions.setNodeData('column_repeatables', column_repeatables);

        // This relevant row (the `node`) was merely a template. It has
        // been stored to the table's data.row_repeatables and should
        // now be removed from the actual contract.
        actions.removeNode();
      }
    }
  },
};

// Fix tables!

// Handle table rows separately, as they need to be inserted into a multiple
// of other nodes. Remove it. Place it on the table node and handle it from there.
/* if (node.type === 'table_row') {
    const tableEntry = parents.find(({ node }) => node.type === 'table');
        if (!tableEntry) {
          return;
        }
    const { node: tableNode } = tableEntry;
    if (!tableNode) return this.log('No table node found for ', { node, parents });

    if (!tableNode.children || !Array.isArray(tableNode.children)) {
      return console.log('Table node has invalid children', tableNode);
    }
    // Find the node's index within the tableNode. For usage below.
    const nodeIndex = api.utils.engine.findIndex(tableNode.children, (item) => item === node);
    if (nodeIndex === -1) {
      return this.log('Cannot find my nodeIndex (row within table)', { node, tableNode });
    }

    // Add row_repeatables to the parent table node's data.
    if (!tableNode.data.row_repeatables) tableNode.data.row_repeatables = [];
    tableNode.data.row_repeatables.push({
      repeatable: node.data.each_repeatable.repeatable,
      filter: node.data.each_repeatable.filter,
      filterComponents:
        node.data.each_repeatable.filter && api.logic.getComponents(node.data.each_repeatable.filter),
      content: JSON.stringify(nodeWithoutDataRepeatableEntry(node)),
    });

    // This relevant row (the `node`) was merely a template. It has
    // been stored to the table's data.row_repeatables and should
    // now be removed from the actual contract.
    tableNode.children.splice(nodeIndex, 1);
    if (tableNode.children.length === 0) {
      tableNode.children = this.generateEachPlaceholderChild(node);
    }
  } */

// Handle table cells differently, as they need to be inserted into a multiple
// of other nodes. Remove it. Place it on the table node and handle it from there
/* else if (node.type === 'table_cell') {
  const tableEntry = parents.find(({ node }) => node.type === 'table');
        if (!tableEntry) {
          return;
        }
        const { node: tableNode } = tableEntry;
    
    if (!tableNode) return this.log('No table node found for ', { node, parents });

    const rowEntry = parents.find(({ node }) => node.type === 'table_row');
        if (!rowEntry) {
          return;
        }
        const { node: rowNode } = rowEntry;
    if (!rowNode) return this.log('No table_row node found for ', { node, parents });

    // Find the node's index within the tableNode. For usage below.
    const nodeIndex = api.utils.engine.findIndex(rowNode.children, (item) => item === node);

    if (nodeIndex === -1) {
      return this.log('Cannot find my nodeIndex (row within table)', { node, tableNode });
    }

    // Add column_repeatables to the parent table node's data.
    if (!tableNode.data.column_repeatables) tableNode.data.column_repeatables = [];
    tableNode.data.column_repeatables.push({
      repeatable: node.data.each_repeatable.repeatable,
      filter: node.data.each_repeatable.filter,
      content: JSON.stringify(nodeWithoutDataRepeatableEntry(node)),
    });

    // This relevant cell (the `node`) was merely a template. It has
    // been stored to the table's data.column_repeatables and should
    // now be removed from the actual contract.
    rowNode.children.splice(nodeIndex, 1);
  } */

// The template may have a table_row or a table_cell with
// the data property `each_repeatable: { repeatable: 'borrower' }`
// to indicate it as a repeatable template. When initialising the contract,
// that node content is stringified as into the table's data properties
// `row_repeatables` and/or `cell_repeatables` respectively. At the same
// time, the table_row or table_cell is stored within the table's data as
// a template. For that table_row/cell node, remove the `each_repeatable`
// property, as it is no longer needed.

function nodeWithoutDataRepeatableEntry(node) {
  const newNode = {
    ...node,
    data: {
      ...(node.data || {}),
    },
  };
  delete newNode.data.each_repeatable;
  return newNode;
}
