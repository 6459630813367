import api from 'utils/api';
import { Entity } from 'core/interfaces';
import { addOrUpdateRelation, getPrincipalAndAgent } from '.';

/* function updateReferringEntity(referringEntity, entity, relationType, capacities) {
  if (!referringEntity) return null;
  const key = relationType + 's';
  const p = relationType === 'Agent' ? referringEntity : entity;
  const a = relationType === 'Agent' ? entity : referringEntity;
  const relation = Entity.createRelation(p.id, a.id, capacities);
  const entityWithRelation = { ...entity, EntityRelation: relation };
  let relatedEntities = referringEntity[key] ? [...referringEntity[key]] : [];
  const entityInRelatedEntities = relatedEntities.find((e) => e.id === entity.id);
  if (!entityInRelatedEntities) relatedEntities.push(entity);
  else relatedEntities = relatedEntities.map((e) => (e.id === entity.id ? entityWithRelation : e));
  return { ...referringEntity, [key]: relatedEntities };
} */

export async function updateExistingEntityFn(entity, values, { referringEntity, relationType }) {
  const { id } = entity;
  const { capacities } = values;

  const updateResult = await api.put(`/entities/${id}`, values);
  if (!updateResult) {
    console.log('Unsatisfactory update result ', updateResult);
  }

  let newEntity = { ...entity, ...values };
  let newReferringEntity = null;

  if (referringEntity && capacities) {
    await api.post(`/entities/${id}/connectAgent`, {
      principalEntityId: referringEntity.id,
      capacities,
    });
    const [principal, agent] = getPrincipalAndAgent(referringEntity, newEntity, relationType);
    const { updatedPrincipalEntity, updatedAgentEntity } = addOrUpdateRelation(principal, agent, capacities);
    newReferringEntity = updatedPrincipalEntity;
    newEntity = updatedAgentEntity;
  }

  const result = {
    type: 'update',
    status: 'success',
    values,
    entity: newEntity,
    referringEntity: newReferringEntity,
    capacities,
  };

  return result;
}
