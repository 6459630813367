import api from '../api';
export async function fetchEntity(id, options = {}) {
  if (!id) return null;
  const include = [
    { model: 'Entity', as: 'Owners' },
    { model: 'Entity', as: 'Owned' },
    { model: 'Entity', as: 'Agents' },
    { model: 'Entity', as: 'Principals' },
  ];
  if (options.withParent) include.push({ model: 'Entity', as: 'Parent' });
  const includeQuery = encodeURI(JSON.stringify(include));
  try {
    const result = await api.get(`/entities/${id}?include=${includeQuery}`);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    console.log('Error fetching entity ', err);
    return null;
  }
}

export async function fetchEntityStructure(id) {
  if (!id) return null;

  try {
    const result = await api.get(`/entities/${id}/structure`);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    console.log('Error fetching entity ', err);
    return null;
  }
}

export async function fetchAllRealPersons() {
  const include = [
    { model: 'Entity', as: 'Agents' },
    { model: 'Entity', as: 'Principals' },
  ];
  const includeQuery = encodeURI(JSON.stringify(include));
  try {
    const result = await api.get(`/entities/realpersons?include=${includeQuery}`);
    if (result && result.data) {
      return result.data;
    }
  } catch (err) {
    console.log('Error fetching all real persons ', err);
    return null;
  }
}

