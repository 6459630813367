import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Card, Button, Spin, message, Select, Table, Tag, Divider, Collapse } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useProject, useSearch } from 'hooks';
import api from 'utils/api';
import { fixDate } from 'components/ui';
import IntlMessages from 'util/IntlMessages';
import { generateInitialValues, renderSetup } from './utils';

const { Column } = Table;

function NewDocumentItem(props) {
  const {
    id,
    importedStateDocumentId,
    importedStateVersionId,
    close,
    documentWasCreated,
    returnType,
    autostart = false,
  } = props;
  const usedProject = useProject();
  const project = props.project || usedProject;
  const [isSettingUp, setIsSettingUp] = useState(false);
  const [documentTemplate, setDocumentTemplate] = useState(null);
  const [newValues, setNewValues] = useState({});
  const extraSetup = documentTemplate?.data.create.setup
    ?.filter(({id}) => (!['_doc_name', '_doc_description'].includes(id)))
  const waitForAutostart = autostart && !extraSetup?.length

  useEffect(() => {
    async function fetchTemplate() {
      try {
        const template = await api.get(`/documenttemplates/${id}/base`);
        if (template && template.data) {
          setDocumentTemplate(template.data);
          setNewValues(generateInitialValues(template.data));
        }
      } catch (err) {
        console.log('Cannot fetch document template ', { id, err, response: err.response });
      }
    }
    fetchTemplate();
  }, [id, setDocumentTemplate, setNewValues]);

  useEffect(() => {
    if (waitForAutostart && Object.values(newValues).length)
      startDocumentStandAlone()
  }, [autostart, newValues])

  const setNewValue = useCallback(
    (key, val) => {
      setNewValues({ ...newValues, [key]: val });
    },
    [newValues]
  );

  /* const setSelectedEntities = useCallback(
    (values) => {
      setNewValue('__selectedEntities', values);
    },
    [setNewValue]
  ); */

  const startDocumentStandAlone = useCallback(
    async (opts = {}) => {
      setIsSettingUp(true);

      let basedOnVersionId;
      const withImportState =
        (typeof opts === 'object' && opts.versionId) || importedStateDocumentId || importedStateVersionId;

      if (withImportState) {
        if (typeof opts === 'object' && opts.versionId) {
          basedOnVersionId = opts.versionId;
        } else if (importedStateVersionId) {
          basedOnVersionId = importedStateVersionId;
        } else if (importedStateDocumentId) {
          const latestBasedOnVersion = await api.get(
            `/documents/${importedStateDocumentId}/versions?last&fields=id`
          );
          if (latestBasedOnVersion?.data?.id) {
            basedOnVersionId = latestBasedOnVersion.data.id;
          }
        }
      }

      const hasDocumentCreatedCallback = typeof documentWasCreated === 'function';

      try {
        const createResult = await api.post('/documents/new', {
          name: newValues._doc_name,
          description: newValues._doc_description,
          setup: newValues,
          projectId: project.id,
          documentTemplateId: id,
          basedOnVersionId,
          returnType: ['ids', ...(hasDocumentCreatedCallback ? ['document'] : [])],
        });
        if (!createResult?.data?.versionId) {
          return message.error('Could not create document');
        }
        window.open(`/studio/draft/${createResult.data.versionId}`, '_blank');
        close();
        if (typeof documentWasCreated === 'function') {
          documentWasCreated(createResult.data.document);
        }
      } catch (err) {
        console.log('Error creating new document 1', err);
        console.log('Error creating new document 2', err.response);
      }
    },
    [importedStateDocumentId, importedStateVersionId, newValues, project.id, id, close, documentWasCreated]
  );

  if (waitForAutostart || isSettingUp || !documentTemplate)
    return (
      <Card>
        <Spin className="loader-container" />
      </Card>
    );

  return (
    <div>
      <div className="w-50 m-auto">
        <div
          className="ml-auto d-none new-project-close-btn"
          style={{ marginTop: '-10px', marginRight: '-10px' }}
        >
          <button type="button" className="btn close" onClick={() => console.log('close...')}>
            <i className="mdi mdi-close"></i>
          </button>
        </div>
        <div align={'center'}>
          <i className={'mdi mdi-file-outline'} style={{ fontSize: 48 }} />
        </div>
        <div className="new-project-text">
          <IntlMessages id={'app.project.CreateNew'} />
        </div>
        <div align={'center'}>{documentTemplate.name}</div>
        <div align={'center'} className="mb-4">
          {documentTemplate.description}
        </div>
        <div className="mt-4 mb-4 border-top"></div>
        {renderSetup(documentTemplate, newValues, setNewValue)}
        {/* {project.isFolder && (
          <SelectEntities
            selectedEntities={newValues.__selectedEntities}
            setSelectedEntities={setSelectedEntities}
          />
        )} */}
        <div className="text-center">
          <Button onClick={startDocumentStandAlone} className="btn mt-4" type="primary">
            <IntlMessages id={'app.project.CreateDocument'} />
          </Button>
        </div>
      </div>
      <ChoseBaseDocumentOn
        documentTemplate={documentTemplate}
        importedStateDocumentId={importedStateDocumentId}
        importedStateVersionId={importedStateVersionId}
        project={project}
        startDocumentStandAlone={startDocumentStandAlone}
      />
    </div>
  );
}

export default NewDocumentItem;

const { Panel } = Collapse;
const config = {
  bordered: false,
  loading: false,
  pagination: false,
  size: 'default',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  rowSelection: false,
  scroll: undefined,
};

function ChoseBaseDocumentOn({
  documentTemplate,
  importedStateDocumentId,
  importedStateVersionId,
  project,
  startDocumentStandAlone,
}) {
  const [compatibleProjectItems, setCompatibleProjectItems] = useState(null);
  const [compatibleVersionItems, setCompatibleVersionItems] = useState(null);

  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const hasFetched = useRef(false);

  useEffect(() => {
    if (hasFetched.current) return console.log('Not fetch again...');
    if (!documentTemplate) return;
    if (importedStateDocumentId || importedStateVersionId) return setCompatibleProjectItems([]); // If already know which to import, do not fetch.
    async function fetchCompatibleData() {
      const compatibleInfo = await api.get(
        `/documenttemplates/${documentTemplate.id}/compatiblesInfo?mapVersions=true`
      );
      if (compatibleInfo.data) {
        setCompatibleProjectItems(compatibleInfo.data.projects);
        setCompatibleVersionItems(compatibleInfo.data.versions);
        hasFetched.current = true;
        setIsLoaded(true);
      } else {
        setCompatibleProjectItems([]);
        setError(true);
        setIsLoaded(true);
      }
    }
    fetchCompatibleData();
  }, [documentTemplate, project.id, importedStateDocumentId, importedStateVersionId]);

  const [filteredItems, searchElement] = useSearch(compatibleProjectItems, {
    element: true,
    fields: ['name'],
    subKey: 'documents',
    subFields: ['name', 'description'],
  });

  const sortedFilteredItems = filteredItems.sort((a, b) =>
    a.id === project.id ? -1 : b.id === project.id ? 1 : 0
  );

  if (error) {
    return <div>An error occurred while retrieving compatible documents. Please try again later.</div>;
  }

  if (!isLoaded) {
    return (
      <div>
        <Spin className="loader-container" />
      </div>
    );
  }

  if (
    !compatibleProjectItems ||
    !compatibleProjectItems.length ||
    !compatibleVersionItems ||
    !Object.keys(compatibleVersionItems).length
  ) {
    return null;
  }

  return (
    <div>
      <div>
        <div className="text-center mb-2">
          <IntlMessages id={'desc.or'} />
          <Divider>
            <IntlMessages id={'app.project.BaseDocumentOn'} cap />
          </Divider>
        </div>
        <div className="">
          <div>
            <div className="flex-column align-items-center justify-content-center">
              <div>{searchElement}</div>
            </div>
          </div>
          <div className="p-3">
            {sortedFilteredItems && sortedFilteredItems.length > 0 ? (
              <Collapse defaultActiveKey={[project.id]}>
                {sortedFilteredItems.map((project) => (
                  <Panel header={project.name} key={project.id}>
                    <div className="">
                      {project.documents && project.documents.length > 0 ? (
                        <Table
                          rowKey="id"
                          className="table-responsive documents-table"
                          {...config}
                          dataSource={project.documents}
                        >
                          <Column
                            title={<IntlMessages id="app.general.Name" />}
                            dataIndex="name"
                            key="name"
                            render={(text, record) => (
                              <div>
                                {text}
                                {record.info && record.info.imported && (
                                  <div>
                                    <Tag icon={<ImportOutlined />} color="#68a994">
                                      Imported
                                    </Tag>
                                  </div>
                                )}
                              </div>
                            )}
                          />
                          <Column
                            title={<IntlMessages id="app.general.Description" />}
                            dataIndex="description"
                            key="description"
                          />
                          <Column
                            title={<IntlMessages id="app.general.LastModified" />}
                            dataIndex="updatedAt"
                            key="updatedAt"
                            render={(text) => <small>{fixDate(text, { breakDateAndTime: true })}</small>}
                          />
                          <Column
                            title={<IntlMessages id="general.version" cap />}
                            key="select"
                            render={(text, record) => (
                              <EachCompatibleVersion
                                versions={compatibleVersionItems[record.id]}
                                startDocumentStandAlone={startDocumentStandAlone}
                              />
                            )}
                          />
                        </Table>
                      ) : (
                        <div>No documents in project.</div>
                      )}
                    </div>
                  </Panel>
                ))}
              </Collapse>
            ) : (
              <div>No projects/documents found.</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function EachCompatibleVersion({ versions, startDocumentStandAlone }) {
  const [selected, setSelected] = useState(versions && versions[0] && versions[0].id);

  if (!versions || versions.length === 0) return null;

  const handleOnChange = (val) => setSelected(val);

  const create = () => {
    startDocumentStandAlone({ versionId: selected });
  };

  return (
    <div className="d-flex justify-content-space-between align-items-center">
      <Select value={selected || undefined} onChange={handleOnChange} className="w-50">
        <Select.Option value={null}>
          <em>
            <IntlMessages id={'desc.None'} />
          </em>
        </Select.Option>
        {versions &&
          versions.map((version) => (
            <Select.Option key={version.id} value={version.id}>
              # {version.info && version.info.versionNumber}: {version.name}
            </Select.Option>
          ))}
      </Select>
      <Button className="mb-0" style={{ width: '40%' }} type="primary" onClick={create}>
        <IntlMessages id={'app.project.CreateUsingThis'} />
      </Button>
    </div>
  );
}
