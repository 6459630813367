import React, { memo } from 'react';
import { Col, Row } from 'antd';
import DocumentApprovals from '../../../components/project/DocumentApprovals/index';
import Comments from '../../../components/Comments/index';
import SharedVersions from '../../../components/project/SharedVersions/index';
import ProjectDocumentEvents from '../../../components/project/ProjectDocumentEvents';
import { gutter } from 'config';

import DocumentsHero from './DocumentsHero';
import ProjectActions from './ProjectActions';

export default memo(function ({ type, project, documents, setDocuments, addDocument }) {
  return (
    <div className="padded-page">
      <DocumentsHero {...{ project, documents, addDocument, setDocuments }} />
      <ProjectActions project={project} documents={documents} />
      <Row gutter={gutter}>
        <Col xl={14} lg={14} md={14} sm={24} xs={24}>
          {/* <DocumentApprovals documents={documents} project={project} /> */}
          <Comments project={project} id={project && project.id} type={type} />
        </Col>
        <Col xl={10} lg={10} md={10} sm={24} xs={24}>
          <ProjectDocumentEvents mode="Project" project={project} documents={documents} />
        </Col>
        <Col xl={10} lg={10} md={10} sm={24} xs={24}>
          {/* <SharedVersions project={project} /> */}
        </Col>
      </Row>
    </div>
  );
});
