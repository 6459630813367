import { useEffect, useState } from "react"
import { Card, Checkbox, Table } from "antd"
import api from "utils/api"
import IntlMessages from "util/IntlMessages"
import { useSearch } from "hooks"

const { Column } = Table

export default function Obligations({ doc }) {
  const [contractTasks, setContractTasks] = useState()
  const [onlyMine, setOnlyMine] = useState()

  const [searchResult, searchElement] = useSearch(contractTasks, {
    element: true,
    fields: ['name'],
  })

  useEffect(() => {
    loadContractTasks()
  }, [])

  const loadContractTasks = async () => {
    const { data } = await api.get(`/contracttasks/withSubscribers?resourceType=Document&resourceId=${doc.id}`)
    setContractTasks(data)
  }

  const optionsFilter = (data) => {
    return true
  }

  return (
    <Card
      title={
        <div className="d-flex align-items-center justify-content-end">
          <Checkbox className="mr-3" onChange={e => setOnlyMine(e.target.checked)}>
            Only mine
          </Checkbox>
          {searchElement}
        </div>
      }
    >
      <Table
        rowKey="id"
        dataSource={searchResult.filter(optionsFilter)}
        expandable={{
          expandedRowRender: record => <p className="m-4">{record.data.clause.children[0].text}</p>,
        }}
      >
        <Column
          title={'Regarding'}
          dataIndex="data"
          render={data => data.clause.data?.provision?.subject?.label?.value}
        />
        <Column
          title={'Applies'}
          dataIndex="taskType"
          render={type => <IntlMessages id={`contract.provision.types.${type}`} />}
        />
        <Column
          title={'Timing'}
          dataIndex="data"
          render={data => data.clause.data?.provision?.timing?.type}
        />
        <Column
          title={'Exemption'}
          dataIndex="data"
          render={data => {
            const clause = data.clause.data?.provision?.subject?.exemption ? 'clause' : ''
            const parties = data.obligatedInfo.ids.length ? 'parties' : ''
            return [clause, parties].filter(e => e).join(', ')
          }}
        />
      </Table>
    </Card>
  )
}