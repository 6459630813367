import { useCallback, useMemo } from 'react';
import { Card, Badge, Tag } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { HeroSkeleton } from '../../../components/Skeletons/Overview';
import NewDocumentButton from '../../../components/project/NewDocument/NewDocumentButton';
import Documents from '../../../components/project/Documents';
import { useGotoDocumentView, useFetch } from 'hooks';
import { ActionButton, fixDate, AutoModal } from 'components/ui';
import { STATUS_COLORS } from 'config';

function getFeedbackUrl(project) {
  if (!project || !project.id) return '';
  return `/versionfeedbacks?where=projectId_->_${project.id}&order=${encodeURI('desc|createdAt')}`;
}

const DocumentsHero = ({ project, documents, setDocuments }) => {
  const [projectFeedbacks] = useFetch(getFeedbackUrl(project));

  const documentFeedbacks = useMemo(() => {
    if (!projectFeedbacks) return {};
    return projectFeedbacks.reduce((acc, curr) => {
      if (!acc[curr.documentId]) acc[curr.documentId] = 1;
      else acc[curr.documentId] += 1;
      return acc;
    }, {});
  }, [projectFeedbacks]);
  const documentWasCreated = useCallback(
    (newDocument) => {
      if (!newDocument) return;
      setDocuments([newDocument, ...documents]);
    },
    [documents, setDocuments]
  );

  if (!project || !documents) return <HeroSkeleton title={<IntlMessages id="general.documents" cap />} />;

  const limtedDocuments = documents ? documents.slice(0, 5) : [];
  const hasDocuments = limtedDocuments.length > 0;

  return (
    <Card
      className="widget dark-container"
      title={<IntlMessages id="general.documents" cap />}
      extra={
        <AutoModal
          trigger={
            <ActionButton
              size="large"
              className="mt-2"
              title={<IntlMessages id="desc.listAll" cap />}
              icon={<i className="mdi mdi-format-list-bulleted" />}
            />
          }
          title={' '}
          className="modal-lg"
        >
          <Documents
            project={project}
            documents={documents}
            setDocuments={setDocuments}
            documentWasCreated={documentWasCreated}
          />
        </AutoModal>
      }
    >
      <div className="d-flex px-2">
        {hasDocuments && (
          <div className="d-flex pt-3" style={{ overflow: 'hidden', flexGrow: 1 }}>
            {(limtedDocuments || []).map((document, i) => (
              <Document key={i} i={i} document={document} feedbacks={documentFeedbacks[document.id]} />
            ))}
          </div>
        )}

        <div className="d-flex pt-3">
          <div
            className="d-flex bg-white  flex-column justify-content-center align-items-center xborder-dashed mx-3"
            style={{ width: '220px', height: 320, marginBottom: 22 }}
          >
            <NewDocumentButton project={project} documentWasCreated={documentWasCreated}>
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <i className="mdi mdi-plus" style={{ fontSize: 84, color: 'var(--link-color)' }} />
                <p style={{ color: 'var(--link-color)' }}>
                  <IntlMessages id="app.project.NewDocument" />
                </p>
              </div>
            </NewDocumentButton>
          </div>
        </div>
      </div>
    </Card>
  );
};

const Document = ({ i, document, feedbacks }) => {
  const formater = useIntlMessage();
  const gotoView = useGotoDocumentView({ documentId: document.id, projectId: document.projectId });
  const status = formater('status.' + (document.status || 'unknown')).toLocaleUpperCase();
  return (
    <div className="mx-3">
      <Badge.Ribbon
        text={<span className="text-uppercase">{status}</span>}
        color={STATUS_COLORS[document.status] || 'grey'}
      >
        <Card
          className={`dark-container document-card flex-column justify-content-between align-items-center`}
          onClick={gotoView}
          style={{ height: 320, width: 210 }}
          hoverable
        >
          <div className="h-100 flex-column justify-content-between align-items-center">
            <div className="text-center pb-2" style={{ marginTop: '60px' }}>
              <strong>{document.name}</strong>
            </div>
            <p className="m-2 fs-sm">{document.description}</p>
            <div className="mt-4x" style={{ marginTop: '60px' }}>
              <small className="fs-sm">{fixDate(document.updatedAt, { breakDateAndTime: false })}</small>
              {feedbacks && (
                <div>
                  <small>
                    <Tag color="geekblue">{feedbacks}</Tag> feedbacks
                  </small>
                </div>
              )}
            </div>
          </div>
        </Card>
      </Badge.Ribbon>
    </div>
  );
};

export default DocumentsHero;
