import React, { useState, memo } from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import CustomScrollbars from 'util/CustomScrollbars';
import SidebarLogo from './SidebarLogo';
import UserProfile from './UserProfile';
import IntlMessages from '../../util/IntlMessages';
import { useSelector } from 'react-redux';
import { useIsSidebarMini } from 'hooks';

import MainActions from 'containers/App/MainActions';
import { hasFeatureFlag } from 'utils/general';

const DEFAULT_SIDEBAR_CONTENT_ORDER = ['main', 'projects', 'tasks']; // List of nameIds
const FORCED_SIDEBAR_CONTENT = ['notifications'];

const entryData = {
  search: {
    key: 'search',
    path: '/search',
    nameId: 'search',
    icon: 'mdi mdi-magnify',
    activePathCatch: ['/search'],
  },
  main: {
    key: 'main',
    path: '/main',
    nameId: 'main',
    icon: 'mdi mdi-home-outline',
    activePathCatch: ['/main'],
  },
  projects: {
    path: '/projects',
    nameId: 'projects',
    icon: 'mdi mdi-cube-outline',
    activePathCatch: ['/project'],
    numberKey: 'projects',
  },
  folders: {
    path: '/folders',
    nameId: 'folders',
    icon: 'mdi mdi-folder-outline',
    activePathCatch: ['/folder'],
    numberKey: 'folders',
  },
  entities: {
    path: '/entities',
    nameId: 'entities',
    icon: 'mdi mdi-city',
    activePathCatch: ['/entities', '/entity'],
    numberKey: 'entities',
  },
  companies: {
    path: '/companies',
    nameId: 'companies',
    icon: 'mdi mdi-domain',
    activePathCatch: ['/companies'],
    numberKey: 'companies',
  },
  persons: {
    path: '/persons',
    nameId: 'persons',
    icon: 'mdi mdi-account-multiple',
    activePathCatch: ['/persons'],
    numberKey: 'persons',
  },
  tasks: {
    path: '/tasks',
    nameId: 'tasks',
    activePathCatch: ['/tasks'],
    numberKey: 'pendingTasks',
    numberHighlight: true,
  },
  templates: {
    path: '/templates',
    nameId: 'templates',
    icon: 'mdi mdi-playlist-edit',
    activePathCatch: ['/templates'],
    numberKey: 'templates',
  },
  organisation: {
    path: '/organisation',
    nameId: 'organisation',
    icon: 'mdi mdi-office-building-cog-outline',
    activePathCatch: ['/organisation'],
  },
  clientSettings: {
    path: '/clientSettings',
    nameId: 'clientSettings',
    icon: 'mdi mdi-settings',
    activePathCatch: ['/clientSettings'],
    requireAdmin: true,
  },
  help: {
    path: '/help',
    nameId: 'help',
    icon: 'mdi mdi-help-circle-outline',
    activePathCatch: ['/help'],
    space: true,
  },
  notifications: {
    path: '/notifications',
    nameId: 'notifications',
    icon: 'mdi mdi-bell',
    numberKey: 'newNotifications',
    numberHighlight: true,
    activePathCatch: ['/notifications'],
  },
};

const SidebarContent = () => {
  const numbers = useSelector(({ common }) => common.numbers);
  const user = useSelector(({ auth }) => auth.user);
  const client = useSelector(({ auth }) => auth.client);
  const { pathname } = useLocation();
  const isMini = useIsSidebarMini();

  const sidebarContentOrder = [
    ...new Set([
      ...(client?.clientSettings?.data?.uiSettings?.main?.sidebarContentOrder ||
        DEFAULT_SIDEBAR_CONTENT_ORDER),
      ...FORCED_SIDEBAR_CONTENT,
    ]),
  ];
  const currentMainPath = '/' + pathname.split('/')[1];
  const selectedKey = sidebarContentOrder.find((id) => {
    const entry = entryData[id];
    if (entry.path && entry.path === currentMainPath) return true;
    if (entry.activePathCatch && entry.activePathCatch.includes(currentMainPath)) return true;
    return false;
  });

  return (
    <>
      <SidebarLogo isMini={isMini} />
      <div className="sidebar-content dark-container">
        <UserProfile />
        <CustomScrollbars className="layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={['main']}
            selectedKeys={[selectedKey ? selectedKey.nameId : '']}
            theme={'lite'}
            mode="inline"
          >
            {sidebarContentOrder.map((id) => {
              const entry = entryData[id];
              if (!entry) {
                console.warn('Cannot find menu entry for id: ', id);
                return null;
              }
              if (entry.requireAdmin && !user.isAdmin) return null;
              return (
                <Menu.Item key={entry.nameId} className={entry.space ? 'mt-3 pt-3' : ''}>
                  <Link to={entry.path} className="d-flex align-items-center">
                    {entry.icon ? (
                      <i className={'icon ' + entry.icon} />
                    ) : (
                      <div className="sidebar-icon-number">
                        <span
                          className={
                            'sidebar-number ' +
                            (numbers[entry.numberKey] && entry.numberHighlight ? 'highlighted' : '')
                          }
                        >
                          {numbers[entry.numberKey]}
                        </span>
                      </div>
                    )}
                    <span>
                      <IntlMessages id={'sidebar.' + entry.nameId} />
                    </span>
                  </Link>
                </Menu.Item>
              );
            })}
          </Menu>
        </CustomScrollbars>
        <MainActions />
      </div>
    </>
  );
};

export default memo(SidebarContent);
