export const smartDefinitionOnRepeatableAddSlate = {
  id: 'smartDefinitionOnRepeatableAddSlate',
  time: -3,
  dependencies: { repeatableAdd: true },
  match: ({ entries, node }) => {
    return node.type === 'definitions' && node.data && node.data.definitions_id === 'mainDefinitions';
  },
  handler: function ({ state, api, entries, matches }) {
    if (!this.getContractCreate().build.smartDefinitions) return;

    for (const { actions } of matches.default) {
      for (const entry of entries) {
        const { value, path, cardId: repeatable } = entry;
        if (!repeatable) continue;
        const { _uid } = value;
        const definitions = this.getContractCreate().build.smartDefinitions.filter(
          (item) =>
            item.xcontent &&
            item.xcontent.data &&
            item.xcontent.data.each_repeatable &&
            item.xcontent.data.each_repeatable.repeatable === repeatable
        );
        for (const definition of definitions) {
          // console.log('Add definition ', definition, repeatable);
          const content = api.utils.engine.uniqueItemIds(
            api.utils.engine.initEachItems(JSON.parse(JSON.stringify(definition.xcontent)), _uid)
          );

          if (content && content.data && content.data.acp) {
            if (!this.applyLogic(definition.rule, { state })) content.data._inActive = true;
          }

          const definitionKey = getDefinitionKey(content, api.utils.engine.find);

          if (typeof window !== 'undefined' && window.debug) this.log('On repeatable add def ', { path });

          content.data = {
            ...content.data,
            _each_uid: _uid,
            _each_repeatable_name: repeatable,
            _each_label_id: 'el_smartdef_' + definitionKey + '_' + _uid,
            _path: path,
            key: definitionKey,
            isSmartDefinition: true,
            isDefKey: true,
          };

          this.populateEachContent(repeatable, content, value, state, entry);
          // this.log('Added smart definition.')
          delete content.data.each_repeatable;

          actions.insertChildren(content);
          // SLATE_ENGINE_FIX
          // Fix sorting???
          continue;
        }
      }
    }
  },
};

function getDefinitionKey(content, find) {
  const items = find(content, (n) => n.variant === 'item' && n.data && n.data.each && n.data.each.key);
  if (items.length === 0) return '__unknown__';
  return items[0].data.each.key;
}
