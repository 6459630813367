import { uuidColor } from 'core/utils/general';

export function awarenessStatesToEntitiesInfo(states) {
  return Array.from(states).reduce((acc, [id, state]) => {
    if (!state || !state.data) return acc;
    const {
      data: { color, firstName, lastName, versionFeedbacks = [], entityId, accessLevel },
    } = state;
    acc[entityId] = { clientId: id, color, firstName, lastName, versionFeedbacks, accessLevel };
    return acc;
  }, {});
}

export function createAwarenessUserData(onlineMode, user, accessLevel) {
  if (!onlineMode || !user) return null;
  return {
    color: uuidColor(user.id, { mode: 'light' }).color,
    firstName: user.firstName,
    lastName: user.lastName,
    entityId: user.entityId,
    accessLevel,
  };
}

/* export function awarenessGetVersionFeedbacks(entitiesInfo, currentVersionFeedbacks) {
  const awarenessFeedbacks = Object.values(entitiesInfo)
    .map((data) => data?.versionFeedbacks || [])
    .flat();
  console.log('awarenessFeedbacks', awarenessFeedbacks);
  const newResults = [];
  for (const feedback of awarenessFeedbacks) {
    const exists = currentVersionFeedbacks.find((cvf) => cvf.id === feedback.id);
    if (!exists) newResults.push(feedback);
  }
  return [
    [...currentVersionFeedbacks, ...newResults],
    {
      number: newResults.length,
      info: newResults.map((feedback) => ({
        id: feedback.id,
        name: Entity.name(entitiesInfo[feedback.entityId]),
        status: feedback.status,
      })),
    },
  ];
}
 */
