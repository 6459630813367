export const initValuesSlate = {
  id: 'initValuesSlate',
  genesisHandler: true,
  match: ({ node }) => {
    return node.variant === 'initvalue' && node.data && node.data.name;
  },
  handler: function ({ matches }) {
    if (matches.default) {
      for (const { node, actions } of matches.default) {
        const value = getInitValue(node.data.name, this.language) || this.emptyContractString();

        actions.replaceText(value);
      }
    }
  },
};

function getInitValue(name, language) {
  switch (name) {
    case 'date':
      const date = new Date();
      const dateFormatter = new Intl.DateTimeFormat(language, {
        month: 'long',
      });
      let d = date.getDate();

      // if (d < 10) d = "0" + d;
      return d + ' ' + dateFormatter.format(date) + ' ' + date.getFullYear();
    default:
      return '[**]';
  }
}
