import { createDraft, finishDraft, isDraft } from 'immer';
import { Editor, Transforms, Node, Range, Text, Path, Point } from 'slate';
import { ReactEditor } from 'slate-react';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

Text.stdEquals = Text.equals;
const NodeGetLeaf = Node.leaf;
const originalPointEquals = Point.equals;
const originalToDOMPoint = ReactEditor.toDOMPoint;
const originalToDOMRange = ReactEditor.toDOMRange;
const originalToSlateNode = ReactEditor.toSlateNode;

const applyToDraft = (editor, selection, op) => {
  switch (op.type) {
    case 'insert_node': {
      const { path, node } = op;
      const parent = Node.parent(editor, path);
      const index = path[path.length - 1];

      if (index > parent.children.length) {
        throw new Error(
          `Cannot apply an "insert_node" operation at path [${path}] because the destination is past the end of the node.`
        );
      }

      parent.children.splice(index, 0, node);

      if (selection) {
        for (const [point, key] of Range.points(selection)) {
          selection[key] = Point.transform(point, op);
        }
      }

      break;
    }

    case 'insert_text': {
      const { path, offset, text } = op;
      if (text.length === 0) break;
      const node = Node.leaf(editor, path);
      const before = node.text.slice(0, offset);
      const after = node.text.slice(offset);
      node.text = before + text + after;

      if (selection) {
        for (const [point, key] of Range.points(selection)) {
          selection[key] = Point.transform(point, op);
        }
      }

      break;
    }

    case 'merge_node': {
      const { path } = op;
      const node = Node.get(editor, path);
      const prevPath = Path.previous(path);
      const prev = Node.get(editor, prevPath);
      const parent = Node.parent(editor, path);
      const index = path[path.length - 1];

      if (Text.isText(node) && Text.isText(prev)) {
        prev.text += node.text;
      } else if (!Text.isText(node) && !Text.isText(prev)) {
        prev.children.push(...node.children);
      } else {
        throw new Error(
          `Cannot apply a "merge_node" operation at path [${path}] to nodes of different interfaces: ${node} ${prev}`
        );
      }

      parent.children.splice(index, 1);

      if (selection) {
        for (const [point, key] of Range.points(selection)) {
          selection[key] = Point.transform(point, op);
        }
      }

      break;
    }

    case 'move_node': {
      const { path, newPath } = op;

      if (Path.isAncestor(path, newPath)) {
        throw new Error(
          `Cannot move a path [${path}] to new path [${newPath}] because the destination is inside itself.`
        );
      }

      const node = Node.get(editor, path);
      const parent = Node.parent(editor, path);
      const index = path[path.length - 1];

      // This is tricky, but since the `path` and `newPath` both refer to
      // the same snapshot in time, there's a mismatch. After either
      // removing the original position, the second step's path can be out
      // of date. So instead of using the `op.newPath` directly, we
      // transform `op.path` to ascertain what the `newPath` would be after
      // the operation was applied.
      parent.children.splice(index, 1);
      const truePath = Path.transform(path, op);
      const newParent = Node.get(editor, Path.parent(truePath));
      const newIndex = truePath[truePath.length - 1];

      newParent.children.splice(newIndex, 0, node);

      if (selection) {
        for (const [point, key] of Range.points(selection)) {
          selection[key] = Point.transform(point, op);
        }
      }

      break;
    }

    case 'remove_node': {
      const { path } = op;
      const index = path[path.length - 1];
      const parent = Node.parent(editor, path);
      parent.children.splice(index, 1);

      // Transform all of the points in the value, but if the point was in the
      // node that was removed we need to update the range or remove it.
      if (selection) {
        for (const [point, key] of Range.points(selection)) {
          const result = Point.transform(point, op);

          if (selection != null && result != null) {
            selection[key] = result;
          } else {
            let prev;
            let next;

            for (const [n, p] of Node.texts(editor)) {
              if (Path.compare(p, path) === -1) {
                prev = [n, p];
              } else {
                next = [n, p];
                break;
              }
            }

            let preferNext = false;
            if (prev && next) {
              if (Path.equals(next[1], path)) {
                preferNext = !Path.hasPrevious(next[1]);
              } else {
                preferNext = Path.common(prev[1], path).length < Path.common(next[1], path).length;
              }
            }

            if (prev && !preferNext) {
              point.path = prev[1];
              point.offset = prev[0].text.length;
            } else if (next) {
              point.path = next[1];
              point.offset = 0;
            } else {
              selection = null;
            }
          }
        }
      }

      break;
    }

    case 'remove_text': {
      const { path, offset, text } = op;
      if (text.length === 0) break;
      const node = Node.leaf(editor, path);
      const before = node.text.slice(0, offset);
      const after = node.text.slice(offset + text.length);
      node.text = before + after;

      if (selection) {
        for (const [point, key] of Range.points(selection)) {
          selection[key] = Point.transform(point, op);
        }
      }

      break;
    }

    case 'set_node': {
      const { path, properties, newProperties } = op;

      if (path.length === 0) {
        throw new Error(`Cannot set properties on the root node!`);
      }

      const node = Node.get(editor, path);

      for (const key in newProperties) {
        if (key === 'children' || key === 'text') {
          throw new Error(`Cannot set the "${key}" property of nodes!`);
        }

        const value = newProperties[key];

        if (value == null) {
          delete node[key];
        } else {
          node[key] = value;
        }
      }

      // properties that were previously defined, but are now missing, must be deleted
      for (const key in properties) {
        if (!newProperties.hasOwnProperty(key)) {
          delete node[key];
        }
      }

      break;
    }

    case 'set_selection': {
      const { newProperties } = op;

      if (newProperties == null) {
        selection = newProperties;
      } else {
        if (selection == null) {
          if (!Range.isRange(newProperties)) {
            throw new Error(
              `Cannot apply an incomplete "set_selection" operation properties ${JSON.stringify(
                newProperties
              )} when there is no current selection.`
            );
          }

          selection = { ...newProperties };
        }

        for (const key in newProperties) {
          const value = newProperties[key];

          if (value == null) {
            if (key === 'anchor' || key === 'focus') {
              throw new Error(`Cannot remove the "${key}" selection property`);
            }

            delete selection[key];
          } else {
            selection[key] = value;
          }
        }
      }

      break;
    }

    case 'split_node': {
      const { path, position, properties } = op;

      if (path.length === 0) {
        throw new Error(
          `Cannot apply a "split_node" operation at path [${path}] because the root node cannot be split.`
        );
      }

      const node = Node.get(editor, path);
      const parent = Node.parent(editor, path);
      const index = path[path.length - 1];
      let newNode;

      if (Text.isText(node)) {
        const before = node.text.slice(0, position);
        const after = node.text.slice(position);
        node.text = before;
        newNode = {
          ...properties,
          text: after,
        };
      } else {
        const before = node.children.slice(0, position);
        const after = node.children.slice(position);
        node.children = before;

        newNode = {
          ...properties,
          children: after,
        };
      }

      parent.children.splice(index + 1, 0, newNode);

      if (selection) {
        for (const [point, key] of Range.points(selection)) {
          selection[key] = Point.transform(point, op);
        }
      }

      break;
    }
    default:
      break;
  }
  return selection;
};

export default function adjustInterfaces() {
  if (!Transforms.forceDeselect) Transforms.forceDeselect = Transforms.deselect;
  Transforms.deselect = () => {};
  Transforms.select = (editor, target) => {
    if (target === null || target.anchor === null || target.focus === null) return;
    const { selection } = editor;
    target = Editor.range(editor, target);
    if (selection) {
      Transforms.setSelection(editor, target);
      return;
    }

    if (!Range.isRange(target)) {
      throw new Error(
        `When setting the selection and the current selection is \`null\` you must provide at least an \`anchor\` and \`focus\`, but you passed: ${JSON.stringify(
          target
        )}`
      );
    }

    editor.apply({
      type: 'set_selection',
      properties: selection,
      newProperties: target,
    });
  };

  // Make setNodes accept a function as `props` argument.
  Transforms.setNodes = function (editor, props, options = {}) {
    Editor.withoutNormalizing(editor, () => {
      let { match, at = editor.selection, compare } = options;
      const { hanging = false, mode = 'lowest', split = false, voids = false } = options;

      if (!at) {
        return;
      }

      if (match == null) {
        match = Path.isPath(at) ? matchPath(editor, at) : (n) => Editor.isBlock(editor, n);
      }

      if (!hanging && Range.isRange(at)) {
        at = Editor.unhangRange(editor, at);
      }

      if (split && Range.isRange(at)) {
        if (Range.isCollapsed(at) && Editor.leaf(editor, at.anchor)[0].text.length > 0) {
          // If the range is collapsed in a non-empty node and 'split' is true, there's nothing to
          // set that won't get normalized away
          return;
        }
        const rangeRef = Editor.rangeRef(editor, at, { affinity: 'inward' });
        const [start, end] = Range.edges(at);
        const splitMode = mode === 'lowest' ? 'lowest' : 'highest';
        const endAtEndOfNode = Editor.isEnd(editor, end, end.path);
        Transforms.splitNodes(editor, {
          at: end,
          match,
          mode: splitMode,
          voids,
          always: !endAtEndOfNode,
        });
        const startAtStartOfNode = Editor.isStart(editor, start, start.path);
        Transforms.splitNodes(editor, {
          at: start,
          match,
          mode: splitMode,
          voids,
          always: !startAtStartOfNode,
        });
        at = rangeRef.unref();

        if (options.at == null) {
          Transforms.select(editor, at);
        }
      }

      if (!compare) {
        compare = (prop, nodeProp) => prop !== nodeProp;
      }

      for (const [node, path] of Editor.nodes(editor, {
        at,
        match,
        mode,
        voids,
      })) {
        const properties = {};
        const newProperties = {};

        // You can't set properties on the editor node.
        if (path.length === 0) {
          continue;
        }

        let hasChanges = false;

        // Make `setNodes` alternatively accept a function as
        // second argument. Function receives node and path, and
        // returns an object with the updated node properties.
        let propsToSet;
        if (typeof props === 'function') {
          propsToSet = props(node, path);
          if (!propsToSet || typeof propsToSet !== 'object') continue;
        } else if (typeof props === 'object') {
          propsToSet = props;
        } else {
          throw new Error('Second argument to setNodes shall be function or object of properties');
        }

        for (const k in propsToSet) {
          if (k === 'children' || k === 'text') {
            continue;
          }

          if (compare(propsToSet[k], node[k])) {
            hasChanges = true;
            // Omit new properties from the old properties list
            if (node.hasOwnProperty(k)) properties[k] = node[k];
            // Omit properties that have been removed from the new properties list
            if (propsToSet[k] != null) newProperties[k] = propsToSet[k];
          }
        }

        if (hasChanges) {
          editor.apply({
            type: 'set_node',
            path,
            properties,
            newProperties,
          });
        }
      }
    });
  };

  Node.leaf = (...args) => {
    try {
      const leaf = NodeGetLeaf(...args);
      return leaf;
    } catch (err) {
      console.log('Err getting node leaf', { err });
    }
  };

  Node.isTextInline = (editor, node) => editor.isInline(node) && node.children.every(Text.isText);

  Text.equals = (text, another, options = {}) => {
    const { loose = false } = options;

    return isEqual(loose ? omit(text, 'text') : text, loose ? omit(another, 'text') : another);
  };

  Point.equals = (point, another) => {
    if (!point || !another) {
      console.log('Invalid arguments to Points.equals');
      return false;
    }
    return point && another && originalPointEquals(point, another);
  };

  ReactEditor.toDOMPoint = (...args) => {
    try {
      const result = originalToDOMPoint(...args);
      return result;
    } catch (err) {
      console.log('Cannot resolve DOM point', err);
      return null;
    }
  };

  ReactEditor.toDOMRange = (...args) => {
    try {
      const result = originalToDOMRange(...args);
      return result;
    } catch (err) {
      console.log('Cannot resolve DOM range', err);
      return null;
    }
  };

  ReactEditor.toSlateNode = (...args) => {
    try {
      const result = originalToSlateNode(...args);
      return result;
    } catch (err) {
      console.log('Cannot resolve slate node from dom node ', args);
      return null;
    }
  };

  /*   const originalTransform = Transforms.transform;

  Transforms.transform = (editor, op) => {
    if (!editor.collectOps) {
      originalTransform(editor, op);
      editor.collectedOps = [];
      return;
    }
    if (!editor.collectedOps) editor.collectedOps = [];
    editor.collectedOps.push(op);
  }; */
}

// Helpers
const matchPath = (editor, path) => {
  const [node] = Editor.node(editor, path);
  return (n) => n === node;
};
