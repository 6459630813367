import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import Chart from 'components/orgchart/orgchart';
import { Entity } from 'core/interfaces';
import { updateInputs } from 'appRedux/actions';
import { Button } from 'antd';

const topRightIcon = ['mdi mdi-arrow-right-drop-circle-outline', 'color:orange'];
const topLeftIcon = ['mdi mdi-arrow-right-drop-circle-outline', 'color:green'];
const bottomRightIcon = ['mdi mdi-arrow-down-drop-circle-outline', 'color:orange'];
const bottomLeftIcon = ['mdi mdi-arrow-down-drop-circle-outline', 'color:green'];

function Undertaking(props) {
  const dispatch = useDispatch();
  const type = props.args && props.args.type;
  const entities = useSelector((state) => state?.entities);
  const input = useSelector((state) => state?.input);
  const restrictedEntityIds = useSelector((state) => state?.input?._undertakings?.[type], (left, right) => {
    console.log('left vs rights', { left, right })
      return false;
  });
  const undertaking = useSelector((state) => state?.input && state.input['undertaking_' + type]);

  const stringedAtTop = JSON.stringify(restrictedEntityIds);
  console.log('restrictedEntityIds', { restrictedEntityIds, stringedAtTop });

  const chartId = useMemo(() => {
    return 'undert_' + type;
  }, [type]);

  const setRestricted = useCallback(
    (restricted) => {
      console.log('Set new restrictions ', restricted);
      dispatch(
        updateInputs([
          {
            path: 'input._undertakings.' + type,
            value: restricted,
          },
        ])
      );
      return;
    },
    [type, dispatch]
  );

  const clearAllRestricted = () => {
    const { type } = props.args;
    dispatch(
      updateInputs([
        {
          path: 'input._undertakings.' + type,
          value: [],
        },
      ])
    );
  };

  const restrictSingle = (nodeId) => {
    const stringedRestricted = JSON.stringify(restrictedEntityIds);

    console.log('existing restrictions ', {
      restrictedEntityIds,
      stringedRestricted,
    });
    if (restrictedEntityIds.includes(nodeId)) return console.log('already restricted');
    const newRestrictions = [...restrictedEntityIds, nodeId];
    console.log('Add restriction ', { nodeId, newRestrictions });
    setRestricted(newRestrictions);
  };
  /* const restrictSingle = useCallback(
    (nodeId) => {
      const restricted = restrictedEntityIds || [];
      const stringedRestricted = JSON.stringify(restrictedEntityIds);
      const stringedCopy = JSON.stringify(restricted);
      console.log('existing restrictions ', {
        restricted,
        restrictedEntityIds,
        stringedRestricted,
        stringedCopy,
      });
      if (restricted.includes(nodeId)) return console.log('already restricted');
      const newRestrictions = [...restricted, nodeId];
      console.log('Add restriction ', { nodeId, newRestrictions });
      setRestricted(newRestrictions);
    },
    [restrictedEntityIds, setRestricted]
  ); */
  const restrictAll = (nodeId) => {
    let restricted = [...(restrictedEntityIds || [])];

    restrictChildren(restricted, nodeId);

    restricted.push(nodeId);

    // Avoid duplicates
    restricted = [...new Set(restricted)];

    setRestricted(restricted);
  };

  const restrictChildren = (restricted, parentId) => {
    const allChildren = Entity.collectChildren(entities, parentId);

    for (let child of allChildren) {
      let childIndex = restricted.indexOf(child.id);
      if (childIndex === -1) restricted.push(child.id);
      // if (child.children && child.children.length > 0) this.restrictChildren(restricted, child.id);
    }
  };

  const unRestrictSingle = (nodeId) => {
    const { type } = props.args;
    if (!restrictedEntityIds) return;
    const restricted = restrictedEntityIds;
    const newRestricted = restricted.filter((entity) => entity.id !== nodeId);

    console.log('restricted first ', { restricted, newRestricted });

    setRestricted(newRestricted);
  };
  const unRestrictAll = (nodeId) => {
    console.log('Fix');
    /* const { type } = props.args;
    if (!restrictedEntityIds) return;
    const restricted = [...input_undertakings[type]];
    const nodeIndex = restricted.indexOf(nodeId);
    if (nodeIndex !== -1) restricted.splice(nodeIndex, 1);

    unRestrictChildren(restricted, nodeId);

    setRestricted(restricted); */
  };

  const unRestrictChildren = (restricted, parentId) => {
    const allChildren = Entity.collectChildren(entities, parentId);

    for (let child of allChildren) {
      let childIndex = restricted.indexOf(child.id);
      if (childIndex !== -1) restricted.splice(childIndex, 1);
    }
  };

  const customRestriction = (memberOfStateId, entityFieldId, clearExisting) => {
    const memberState = input && input[memberOfStateId];

    if (!memberState || typeof memberState !== 'object') {
      return console.log('Invalid memberstate ', {
        input,
        memberOfStateId,
        memberState,
      });
    }

    const currentlyRestricted = clearExisting ? [] : restrictedEntityIds ? restrictedEntityIds : [];

    const newlyRestricted = [];

    for (const stateItem of Object.values(memberState)) {
      const entityField = stateItem && stateItem[entityFieldId];
      if (!entityField || !entityField.id) {
        console.log('No entity field', stateItem);
        continue;
      }
      newlyRestricted.push(entityField.id);
    }

    // Avoid duplicates
    const restricted = [...new Set([...currentlyRestricted, ...newlyRestricted])];

    setRestricted(restricted);
  };

  const renderWarnings = () => {
    const warnings = undertaking && undertaking.warnings;

    if (!warnings || !Array.isArray(warnings) || warnings.length === 0) return null;

    return warnings.map((warning, index) => <div key={'und-warn' + index}>{warning}</div>);
  };

  /* const initialMeta = useMemo(() => {
    return entities.map((entity) => ({
      ...entity,
      metaData: {},
    }));
  }, [entities]);
  const newMeta = useMemo(() => {
    if (!restrictedEntityIds) return initialMeta;
    console.log('Restricted in newMeta ', restrictedEntityIds);
    return initialMeta.map((node, index) => {
      if (restrictedEntityIds.indexOf(node.id) !== -1) {
        if (Array.isArray(node.metaData.classNames)) {
          return {
            ...node,
            metaData: {
              ...node.metaData,
              classNames: node.metaData.classNames.concat(['node-restricted']),
            },
          };
        } else {
          return { ...node, metaData: { ...node.metaData, classNames: ['node-restricted'] } };
        }
      }
      return { ...node };
    });
  }, [restrictedEntityIds, initialMeta]); */

  if (!entities || entities.length === 0) {
    return <div>No companies</div>;
  }

  console.log('newMeta ', { newMeta: true, restricted: restrictedEntityIds });

  return (
    <>
      <Chart
        chartId={chartId}
        entities={entities}
        layout={layout}
        // metaData={newMeta}
        // clickNodeEvent={clickNodeEvent}
        topRightAction={restrictSingle}
        topRightIcon={topRightIcon}
        topLeftAction={unRestrictSingle}
        topLeftIcon={topLeftIcon}
        bottomRightAction={restrictAll}
        bottomRightIcon={bottomRightIcon}
        bottomLeftAction={unRestrictAll}
        bottomLeftIcon={bottomLeftIcon}
        chartClass={'undertakings'}
      />
      <div className="mt-1">
        <Button className="mb-0" onClick={clearAllRestricted}>
          Clear all restrictions
        </Button>
        <Button
          className="mb-0 ml-1"
          onClick={() => {
            customRestriction('property', 'propertyOwner', true);
          }}
        >
          Restrict Property Owners
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  entities: state.entities,
  input: state.input,
});
const mapDispatchToProps = (dispatch) => ({
  updateInputs: (...args) => dispatch(updateInputs(...args)),
});

let layout = [
  // each array item a row

  [
    // each item a column
    {
      class: 'nodename',
      align: 'center',
      dataField: 'firstName',
    },
  ],
  /*
  [ 
    {
      class: '',
      align: 'center',
      metaDataField: 'classNames',
      id: 'orgchart_name'
    },
  ]
  */
];

class OrgChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analysis: [],
    };
    this.metaData = this.props.entities.map((node) => ({
      ...node,
      metaData: {},
    })); // copy with empty object content

    this.chartId = 'undert_' + this.props.args.type;
  }

  getOrgChartRef = (ref) => {
    this.orgchart = ref;
  };

  setRestricted = (restricted) => {
    const { type } = this.props.args;
    this.props.updateInputs([
      {
        path: 'input._undertakings.' + type,
        value: restricted,
      },
    ]);
    // this.props.setInput("_undertakings", -1, type, restricted);
    return;
  };

  clearAllRestricted = () => {
    const { type } = this.props.args;
    this.props.updateInputs([
      {
        path: 'input._undertakings.' + type,
        value: [],
      },
    ]);
  };

  restrictSingle = (nodeId) => {
    const { type } = this.props.args;
    const restricted =
      this.props.input['_undertakings'] && this.props.input['_undertakings'][type]
        ? [...this.props.input['_undertakings'][type]]
        : [];

    if (restricted.includes(nodeId)) return console.log('already restricted');
    restricted.push(nodeId);
    this.setRestricted(restricted);
  };
  restrictAll = (nodeId) => {
    const { type } = this.props.args;
    let restricted =
      this.props.input['_undertakings'] && this.props.input['_undertakings'][type]
        ? [...this.props.input['_undertakings'][type]]
        : [];

    this.restrictChildren(restricted, nodeId);

    restricted.push(nodeId);

    // Avoid duplicates
    restricted = [...new Set(restricted)];

    this.setRestricted(restricted);
  };

  restrictChildren = (restricted, parentId) => {
    // Each node of orgchart.nodes has its own children array. Use that for simplicity.
    if (!this.orgchart || !this.orgchart.nodes || !this.orgchart.nodes[parentId]) return;
    for (let child of this.orgchart.nodes[parentId].children) {
      let childIndex = restricted.indexOf(child.id);
      if (childIndex === -1) restricted.push(child.id);
      if (child.children && child.children.length > 0) this.restrictChildren(restricted, child.id);
    }
  };

  unRestrictSingle = (nodeId) => {
    const { type } = this.props.args;
    if (!this.props.input['_undertakings'] || !this.props.input['_undertakings'][type]) return;
    const restricted = [...this.props.input['_undertakings'][type]];

    console.log('restricted first ', restricted);

    const nodeIndex = restricted.indexOf(nodeId);
    if (nodeIndex !== -1) restricted.splice(nodeIndex, 1);
    console.log('new restricted ', { restricted, nodeIndex });
    this.setRestricted(restricted);
  };
  unRestrictAll = (nodeId) => {
    const { type } = this.props.args;
    if (!this.props.input['_undertakings'] || !this.props.input['_undertakings'][type]) return;
    const restricted = [...this.props.input['_undertakings'][type]];
    const nodeIndex = restricted.indexOf(nodeId);
    if (nodeIndex !== -1) restricted.splice(nodeIndex, 1);

    this.unRestrictChildren(restricted, nodeId);

    this.setRestricted(restricted);
  };

  unRestrictChildren = (restricted, parentId) => {
    if (!this.orgchart || !this.orgchart.nodes || !this.orgchart.nodes[parentId]) return;
    for (let child of this.orgchart.nodes[parentId].children) {
      let childIndex = restricted.indexOf(child.id);
      if (childIndex !== -1) restricted.splice(childIndex, 1);
      if (child.children && child.children.length > 0) this.unRestrictChildren(restricted, child.id);
    }
  };

  customRestriction = (memberOfStateId, entityFieldId, clearExisting) => {
    const { input, entities } = this.props;
    const memberState = input && input[memberOfStateId];

    if (!memberState || typeof memberState !== 'object') {
      return console.log('Invalid memberstate ', {
        input,
        memberOfStateId,
        memberState,
      });
    }

    const { type } = this.props.args;
    const currentlyRestricted = clearExisting
      ? []
      : this.props.input['_undertakings'] && this.props.input['_undertakings'][type]
      ? [...this.props.input['_undertakings'][type]]
      : [];

    const newlyRestricted = [];

    for (const stateItem of Object.values(memberState)) {
      const entityField = stateItem && stateItem[entityFieldId];
      if (!entityField || !entityField.id) {
        console.log('No entity field', stateItem);
        continue;
      }
      newlyRestricted.push(entityField.id);
    }

    // Avoid duplicates
    const restricted = [...new Set([...currentlyRestricted, ...newlyRestricted])];

    this.setRestricted(restricted);
  };

  renderWarnings = () => {
    const { type } = this.props.args;
    const warnings =
      this.props.input['undertaking_' + type] && this.props.input['undertaking_' + type].warnings;

    if (!warnings || !Array.isArray(warnings) || warnings.length === 0) return null;

    return warnings.map((warning, index) => <div key={'und-warn' + index}>{warning}</div>);
  };

  render() {
    const { type } = this.props.args;
    let meta;

    if (this.props.input['_undertakings'] && this.props.input['_undertakings'][type]) {
      let newMeta = this.metaData.map((node, index) => {
        if (this.props.input['_undertakings'][type].indexOf(node.id) !== -1) {
          if (Array.isArray(node.metaData.classNames)) {
            return {
              ...node,
              metaData: {
                ...node.metaData,
                classNames: node.metaData.classNames.concat(['node-restricted']),
              },
            };
          } else {
            return { ...node, metaData: { ...node.metaData, classNames: ['node-restricted'] } };
          }
        }
        return { ...node };
      });
      meta = newMeta;
    } else {
      meta = this.metaData;
    }

    return (
      <>
        {/* <div className="undertaking-warnings">{this.renderWarnings()}</div> */}
        {this.props.entities && this.props.entities.length > 0 ? (
          <Chart
            chartId={this.chartId}
            entities={this.props.entities}
            layout={layout}
            metaData={meta}
            // clickNodeEvent={this.clickNodeEvent}
            topRightAction={this.restrictSingle}
            topRightIcon={topRightIcon}
            topLeftAction={this.unRestrictSingle}
            topLeftIcon={topLeftIcon}
            bottomRightAction={this.restrictAll}
            bottomRightIcon={bottomRightIcon}
            bottomLeftAction={this.unRestrictAll}
            bottomLeftIcon={bottomLeftIcon}
            setOrgChartRef={this.getOrgChartRef}
            chartClass={'undertakings'}
          />
        ) : null}
        <div className="mt-1">
          <Button className="mb-0" onClick={this.clearAllRestricted}>
            Clear all restrictions
          </Button>
          <Button
            className="mb-0 ml-1"
            onClick={() => {
              this.customRestriction('property', 'propertyOwner', true);
            }}
          >
            Restrict Property Owners
          </Button>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgChart);
