import React, { memo, useState } from 'react';
import { Menu, Dropdown, notification } from 'antd';
import { cx } from '@emotion/css';
import { useSlate, useFocused } from 'slate-react';
import { Editor, Range, Transforms, Path, Node } from 'slate';
import { useContract, setContract, setEventState, useEventState } from 'hooks';
import { Contract, InputPaths } from 'core/interfaces';
import { useDispatch } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import uuid from 'uuid-random';
import RemoveModal from './RemoveModal';
import { removeInput } from '../../../../../containers/TemplateInputSections/utils';
import { defaultClause } from 'components/editor/legal/toolbar/BlocksInlines';
import { insertBlockByPath } from 'components/editor/legal/helpers/blocks';

export default memo((props) => {
  const dispatch = useDispatch();
  const contract = useContract();
  const [showRemove, setShowRemove] = useState(false);
  const { card, field, fieldName } = props;
  const cardId = card.id;
  const isInputField = field.type !== 'newline' && field.type !== 'hr' && !field.type !== 'vr';

  const language = Contract.getLanguage(contract);
  const currentCard = contract.data.ui.cards[cardId];

  const setTemplateEditCard = (type, method) => {
    setEventState('template_edit_input_sections', {
      type,
      method,
      selected: { card: cardId, input: fieldName },
    });
  };

  const onRemove = (removeCompletely) => {
    // Delete the input from the card, both from input_orders and the values prop

    const action = removeInput(contract, currentCard, fieldName, props.path, removeCompletely);
    dispatch(action);

    setContract({ ...contract });
    setShowRemove(false);
  };

  const inputsLength = card.inputs_order.length;
  const fieldIndex = card.inputs_order.findIndex((x) => x === fieldName);
  const isFirst = fieldIndex === 0;
  const isLast = fieldIndex + 1 === inputsLength;
  const updateCard = (prop, value) => {
    contract.data.ui.cards[card.id][prop] = value;
    setContract({
      ...contract,
    });
  };
  const moveUp = () => {
    if (isFirst) return;
    const newOrder = [...card.inputs_order];
    const otherValue = newOrder[fieldIndex - 1];
    newOrder[fieldIndex - 1] = newOrder[fieldIndex];
    newOrder[fieldIndex] = otherValue;
    updateCard('inputs_order', newOrder);
  };
  const moveDown = () => {
    if (isLast) return;
    const newOrder = [...card.inputs_order];
    const otherValue = newOrder[fieldIndex + 1];
    newOrder[fieldIndex + 1] = newOrder[fieldIndex];
    newOrder[fieldIndex] = otherValue;
    updateCard('inputs_order', newOrder);
  };

  const style = {};
  if (!isInputField) {
    style.height = '14px';
  }

  return (
    <div className="editing-input-item" style={style}>
      {showRemove && <RemoveModal type="input" onRemove={onRemove} setOpen={(open) => setShowRemove(open)} />}
      <div
        className={cx('template-input-field-wrapper', 'click-edit', isInputField && 'clickable')}
        onClick={() => setTemplateEditCard('input', 'edit')}
      >
        &nbsp;
      </div>
      {/*
        
        */}
      <div className="actions-container">
        {!isFirst && (
          <span className="link mr-1" onClick={moveUp}>
            <i className="mdi mdi-arrow-up" />
          </span>
        )}
        {!isLast && (
          <span className="link mr-1" onClick={moveDown}>
            <i className="mdi mdi-arrow-down" />
          </span>
        )}
        <Dropdown
          className={'xx-' + fieldName}
          overlay={
            <Menu>
              <Menu.Item key="edit" onClick={() => setTemplateEditCard('input', 'edit')}>
                <span>
                  <IntlMessages id={'studio.template.modal.input.edit'} />
                </span>
              </Menu.Item>
              <Menu.Item key="remove" onClick={() => setShowRemove(true)}>
                <span>
                  <IntlMessages id={'studio.template.modal.input.remove'} />
                </span>
              </Menu.Item>
            </Menu>
          }
        >
          <span className="link">
            <i className="mdi mdi-cog" />
          </span>
        </Dropdown>
      </div>
      {isInputField && <FieldClickWrapper {...props} contract={contract} language={language} />}
    </div>
  );
});

const fieldClickActionTypes = [
  'text',
  'party',
  'entity',
  'date',
  'numeric',
  'numeric-steps',
  'multipleText',
  'select',
  'radio',
  'QA',
];
const entityInputFieldTypes = ['party', 'entity'];

function FieldClickWrapper(props) {
  if (!fieldClickActionTypes.includes(props.field?.type) && !props.field.multiple) return null;
  return <FieldClickAction {...props} />;
}

function FieldClickAction({ field, card, cardUid, fieldName, contract, path, language, ...rest }) {
  const editor = useSlate();
  const focused = useFocused();
  const editorBlurred = useEventState('editorBlur', true);
  let isQA = false;
  // let QAtarget = null;
  let showClickAction =
    focused &&
    editor.selection &&
    Range.isExpanded(editor.selection) &&
    Path.equals(editor.selection.anchor.path, editor.selection.focus.path);
  // It's important to not check for data properties for all inputs if there is no selection
  if (showClickAction) {
    const [dataNodes] = Array.from(
      Editor.nodes(editor, { mode: 'lowest', match: (n) => n.data && n.type === 'field' })
    );
    if (dataNodes && dataNodes[0].variant !== 'opt') showClickAction = false;
  }

  if (!showClickAction && !editorBlurred) {
    isQA =
      field.type === 'QA' &&
      focused &&
      editor.selection &&
      Range.isCollapsed(editor.selection) &&
      editor.selection.anchor.offset === 0;
    if (isQA) {
      try {
        const parentNode = Node.get(editor, Path.parent(editor.selection.anchor.path));
        if (!['paragraph', 'clause'].includes(parentNode.type) || Node.string(parentNode) !== '') {
          isQA = false;
        }
        // QAtarget = parentNode.type;
      } catch (err) {}
    }
    if (isQA) showClickAction = true;
  }
  if (!showClickAction) return null;

  const getRepeatableParent = () => {
    return Array.from(
      Editor.nodes(editor, {
        at: editor.selection,
        mode: 'lowest',
        match: (n) => n.data?.each_repeatable?.repeatable,
      })
    );
  };

  if (!isQA && cardUid && getRepeatableParent().length === 0) return null;

  const clickFieldAction = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (!showClickAction) return;

    // Special case. Is QuestionsAndAnswer inputField.
    if (field.type === 'QA') {
      const label = field.label[language];
      const newClause = defaultClause(label, { marks: { bold: true } });
      if (cardUid) {
        newClause.data.qa = {
          fieldPathData: InputPaths.getCardAndField(contract, path),
        };
      } else {
        newClause.data.qa = {
          fieldPath: path,
        };
      }

      const insertAt = Path.parent(editor.selection.anchor.path);
      insertBlockByPath(editor, insertAt, newClause, {
        mode: 'sibling',
        direction: 'below',
      });
      return;
    }

    // The field is within a repeatable card
    if (cardUid) {
      // First, find any repeatable containers above the editor selected node.
      const [repeatableParent] = getRepeatableParent();

      if (!repeatableParent) {
        notification.warning({
          message: 'Not allowed',
          description: `You can only connect repeatable fields to text inside blocks that have been connected to the repeatable box first`,
          duration: 10,
        });
      } else {
        // Only make the input clickable if the current input card is the same type as the selected repeatable in the document
        if (repeatableParent && repeatableParent[0].data.each_repeatable.repeatable === card.id) {
          Transforms.wrapNodes(
            editor,
            {
              type: 'field',
              variant: 'item',
              data: {
                item_id: uuid(),
                template_id: 'default_item',
                each: {
                  key: fieldName,
                  level: 0,
                },
              },
            },
            { at: editor.selection, split: true }
          );
          // Transforms.forceDeselect(editor);
          Transforms.setSelection(editor, {
            anchor: editor.selection.anchor,
            focus: JSON.parse(JSON.stringify(editor.selection.anchor)),
          });
          console.log('Editor selection...1', editor.selection);
        } else {
          const contractLanguauge = contract.data.settings.language;
          const selectedCardId = repeatableParent[0].data.each_repeatable.repeatable;
          const cardHeader = contract.data.ui.cards[selectedCardId].header[contractLanguauge];
          notification.warning({
            message: 'Not allowed',
            description: `You can only connect text inside a repeatable block to a input of the same type: ${
              cardHeader || selectedCardId
            }`,
            duration: 10,
          });
        }
      }
    }
    // The field is within an ordinary card
    else {
      let wrapperElement;
      if (entityInputFieldTypes.includes(field.type)) {
        wrapperElement = {
          type: 'field',
          variant: 'entity',
          data: {
            key: 'name',
            item_id: uuid(),
            template_id: 'default_vari',
            fieldPath: `${card.id}.${fieldName}`,
          },
        };
      } else {
        // Ordinary, typical inputField. Make to variant `vari`.
        wrapperElement = {
          type: 'field',
          variant: 'vari',
          data: {
            item_id: uuid(),
            template_id: 'default_vari',
            name: `${card.id}.${fieldName}`,
          },
        };
      }
      Transforms.wrapNodes(editor, wrapperElement, { at: editor.selection, split: true });
      // Transforms.forceDeselect(editor);
      Transforms.setSelection(editor, {
        anchor: editor.selection.anchor,
        focus: JSON.parse(JSON.stringify(editor.selection.anchor)),
      });
    }
  };
  if (!showClickAction) return null;
  return <div onMouseDown={clickFieldAction} className="template-input-field-editor-action"></div>;
}
