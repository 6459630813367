import React from 'react';
import { Menu, Dropdown, Button, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { switchLanguage, userSignOut } from 'appRedux/actions';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { supportedLanguages } from 'lngProvider/';
import { useHistory } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { useIsSidebarMini } from 'hooks';
import devGlobals from 'utils/devGlobals';

const DEV = process.env.REACT_APP_NODE_ENV === 'development';

export default function MainActions({ extra, onSignOut }) {
  const isMini = useIsSidebarMini();

  let type;
  if (extra || isMini) type = 'nested';

  return (
    <div
      className={`d-flex justify-content-center`}
      style={{ paddingTop: '10px' }}
    >
      {extra || null}
      {type === 'nested' ? (
        <Dropdown
          overlay={
            <Menu>
              <Actions {...{ DEV, isMini, type, onSignOut }} />
            </Menu>
          }
        >
          <Button ghost>
            <i className={'mdi mdi-dots-horizontal '} />
          </Button>
        </Dropdown>
      ) : (
        <Button.Group>
          <Actions {...{ DEV, isMini, type, onSignOut }} />
        </Button.Group>
      )}
    </div>
  );
}

const Actions = ({ DEV, isMini, type, onSignOut }) => {

  return (
    <>
      <LanguageButton key="select-language" type={type} />
      <LogoutButton key="logout" type={type} onSignOut={onSignOut} />
      {DEV && <EnableGlobals key="enable-globals" type={type} />}
      <ExpandButton type={type} isMini={isMini} />
    </>
  )
}

const ExpandButton = ({ type, isMini, ...rest }) => {
  const collapsible = false;

  const toggleMini = () => {
    window.dispatchEvent(new CustomEvent('menu_mini', { detail: {} }));
  };

  if (!collapsible) return null
  if (type === 'nested') {
    return (
      <Menu.Item {...rest}>
        <span onClick={toggleMini}>
        {isMini ? (
          <>
            <MenuUnfoldOutlined className="mr-2" />
            <IntlMessages id="sidebar.expand" />
          </>
        ) : (
          <>
            <MenuFoldOutlined className="mr-2" />
            <IntlMessages id="sidebar.collaps" />
          </>
        )}
        </span>
      </Menu.Item>
    );
  }
  return (
    <Button ghost onClick={toggleMini}>
      {isMini ? (
        <MenuUnfoldOutlined />
      ) : (
        <MenuFoldOutlined />
      )}
    </Button>
  )
}

function LanguageButton({ type, ...rest }) {
  const locale = useSelector(({ settings }) => settings.locale);
  const dispatch = useDispatch();

  const pickLanguage = (value) => dispatch(switchLanguage(supportedLanguages[value]));
  const languageMenu = Object.entries(supportedLanguages).map(([id, lang]) => (
    <Menu.Item key={id}>
      <div onClick={() => pickLanguage(id)} className="language-option d-flex">
        <div className="border px-1 mr-1">{lang.locale.toUpperCase()}</div>
        <span>{lang.name}</span>
      </div>
    </Menu.Item>
  ));

  if (type === 'nested') {
    return (
      <Menu.SubMenu
        {...rest}
        key={'nested-main-actions'}
        ghost
        title={
          <span className="flag-cascader">
            <div className="border px-1 mr-1">{locale.locale.toUpperCase()}</div>
            <span>{locale.name}</span>
          </span>
        }
      >
        {languageMenu}
      </Menu.SubMenu>
    );
  }

  return (
    <Dropdown overlay={<Menu>{languageMenu}</Menu>} placement="topLeft">
      <Button ghost className="sidebar-language-btn">{locale.locale.toUpperCase()}</Button>
    </Dropdown>
  );
}

function LogoutButton({ type, onSignOut, ...rest }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const signOut = () => {
    if (typeof onSignOut === 'function') {
      onSignOut(() => dispatch(userSignOut()));
      return;
    }

    dispatch(userSignOut());
    setTimeout(() => {
      history.push('/signin');
    }, [200]);
  };
  if (type === 'nested') {
    return (
      <Menu.Item {...rest}>
        <span onClick={signOut}>
          <i className="mdi mdi-logout mr-2" />
          <IntlMessages id="app.userAuth.signOut" />
        </span>
      </Menu.Item>
    );
  }
  return (
    <Tooltip title={<IntlMessages id="app.userAuth.signOut" />}>
      <Button ghost onClick={signOut}>
        <i className="mdi mdi-logout" />
      </Button>
    </Tooltip>
  );
}

function EnableGlobals({ type, ...rest }) {
  if (type === 'nested') {
    return (
      <Menu.Item {...rest}>
        <span onClick={devGlobals}>
          <i className="mdi mdi-console mr-2" />
          Enable Globals
        </span>
      </Menu.Item>
    );
  }
  return (
    <Button ghost onClick={devGlobals}>
      <i className="mdi mdi-console" />
    </Button>
  );
}
