import { List, Avatar, Tag } from 'antd';
import { Tablify } from 'components/ui';
import { Entity } from 'core/interfaces';
import IntlMessages from 'util/IntlMessages';

export function EntityItem({ item, history }) {
  const onClickItem = () => {
    history.push({
      pathname: `/entity/${item.id}`,
    });
  };

  const jurisdictionString = Entity.getStringValue(item, 'jurisdiction');

  const infoTable = [
    [
      <span>
        <IntlMessages id="app.general.Jurisdiction" cap />
      </span>,
      <span>{jurisdictionString ? <Tag color="#0a232e">{jurisdictionString}</Tag> : 'N/A'}</span>,
    ],
    [
      <span>
        <IntlMessages id="app.general.address" cap />
      </span>,
      <span>{Entity.getStringValue(item, 'address') || 'N/A'}</span>,
    ],
    [
      <span>
        <IntlMessages id="app.general.IdentificationNumber" cap />
      </span>,
      <span>{item.identificationNumber || 'N/A'}</span>,
    ],
    [
      <span>
        <IntlMessages id="app.general.Type" cap />
      </span>,
      <span>
        <Tag color="#0a232e">
          <IntlMessages id={`app.persons.${item.type}`} cap />
        </Tag>
      </span>,
    ],
  ];

  return (
    <List.Item key={item.id} className="mt-search-result-item highlight-search-inlines" onClick={onClickItem}>
      <List.Item.Meta
        avatar={
          <Avatar
            src={<i className={`mdi ${Entity.isRealPerson(item) ? 'mdi-account' : 'mdi-city'} fs-xl mr-2`} />}
          />
        }
        title={
          <div>
            <div className="d-flex justify-content-space-between mb-1">
              <div className="mt-search-result-item-title">
                <div className="header">{Entity.name(item)}</div>
              </div>
              <div className="mt-search-result-item-info">
                <Tag>Entity</Tag>
              </div>
            </div>
            <Tablify data={infoTable} />
          </div>
        }
      />
    </List.Item>
  );
}
