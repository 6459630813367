// import { Editor, Path, Transforms } from 'slate';
import { Transforms } from 'slate';
import { withTable, options } from './table';

export const withTableSchema = (editor) => {
  // const { normalizeNode } = editor;

/*   editor.normalizeNode = entry => {
    if (maybePreserveSpace(editor, entry)) return;

    normalizeNode(entry);
  }; */

  return withTable(editor);
};

export const PreserveSpaceAfter = new Set([options.tableType]);
export const PreserveSpaceBefore = new Set([options.tableType]);

export const insertParagraph = (
  editor,
  at,
  text = '',
) => {
  console.log('Insert table paragraph')
  Transforms.insertNodes(
    editor,
    {
      type: 'paragraph',
      children: [{ text }],
    },
    {
      at,
    },
  );
};
/* 
const maybePreserveSpace = (
  editor,
  entry,
) => {
  const [node, path] = entry;
  const { type } = node;
  let preserved = false;

  console.log('Maybe preserving space??', {node})

  if (PreserveSpaceAfter.has(type)) {
    const next = Editor.next(editor, { at: path });
    if (!next || PreserveSpaceBefore.has(next[0].type)) {
      insertParagraph(editor, Path.next(path));
      preserved = true;
    }
  }

  if (PreserveSpaceBefore.has(type)) {
    if (path[path.length - 1] === 0) {
      insertParagraph(editor, path);
      preserved = true;
    } else {
      const prev = Editor.previous(editor, { at: path });
      if (!prev || PreserveSpaceAfter.has(prev[0].type)) {
        insertParagraph(editor, path);
        preserved = true;
      }
    }
  }

  return preserved;
};
 */