import React, { useEffect, useState, useCallback, useRef, useMemo, memo } from 'react';
import uuid from 'uuid-random';
import { useSelector, useDispatch } from 'react-redux';
import { Editor, Transforms, Node, Text } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { useIsStudioWriter } from 'hooks';
import {
  setDraft,
  addConversation,
  deleteConversation,
  addConversationPost,
  deleteConversationPost,
  setCompleted,
} from 'appRedux/actions';
import { Button, List, Input, Comment as AntComment, Avatar, Tooltip, Divider, Drawer, Tag } from 'antd';
import { UserOutlined, CloseOutlined } from '@ant-design/icons';
import { dayAndMonth, uuidColor } from 'core/utils/general';
import { fixDate } from 'components/ui';
import IntlMessages from 'util/IntlMessages';
import { RemoveModal } from 'components/ui';
import { Entity } from '../../../../core/interfaces';

const { TextArea } = Input;

function useTryGetEditor() {
  let editor;
  try {
    const sEditor = useSlateStatic();
    if (sEditor) editor = sEditor;
  } catch (err) {
    editor = null;
  }
  return editor;
}

function isContractConversation(conversation) {
  const { id, type } = conversation;
  return id === '_contract' || type === 'CON';
}

function sortByDate(a, b) {
  if (!a.created || !b.created) return 0;

  return a.created < b.created ? -1 : 1;
}

const ConversationsContent = memo(({ viewingConversationId, entityDataRef }) => {
  const conversations = useSelector(({ conversations }) => conversations);
  const dispatch = useDispatch();

  const setViewingConversationId = useCallback(
    (id) => {
      dispatch(setDraft('viewingConversationId', id));
    },
    [dispatch]
  );

  const entityData = entityDataRef.current;

  return (
    <>
      {viewingConversationId && (
        <ViewConversation
          id={viewingConversationId}
          entityData={entityData}
          setViewingConversationId={setViewingConversationId}
          conversation={conversations[viewingConversationId]}
        />
      )}
      <ViewAllConversations
        viewingConversationId={viewingConversationId}
        setViewingConversationId={setViewingConversationId}
        entityData={entityData}
      />
    </>
  );
});

function ViewAllConversations({ viewingConversationId, setViewingConversationId, entityData }) {
  const conversations = useSelector(({ conversations }) => conversations);

  const data = useMemo(() => {
    if (!conversations) return [];

    return Object.entries(conversations)
      .reduce((acc, [id, values]) => {
        acc.push({ id, ...values });
        return acc;
      }, [])
      .sort(sortByDate);
  }, [conversations]);

  return (
    <div className="conversations">
      <div className="conversations-list">
        <List
          className=""
          loading={false}
          locale={{ emptyText: 'No conversations created' }}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(conversation) => {
            return (
              <ConversationListView
                id={conversation.id}
                active={viewingConversationId === conversation.id}
                setViewingConversationId={setViewingConversationId}
                conversation={conversation}
                entityData={entityData}
              />
            );
          }}
        />
      </div>
      <div className="conversations-bottom">
        <NewContractConversation />
      </div>
    </div>
  );
}

function NewContractConversation() {
  const { entityId } = useSelector(({ auth }) => auth.user);
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const onChange = (e) => setText(e.target.value);

  const add = () => {
    const id = uuid();
    const post = {
      by: { entityId },
      content: { text },
      time: new Date(Date.now()).toISOString(),
    };
    const data = {
      type: 'CON',
      eId: entityId,
      posts: [post],
    };

    dispatch(addConversation(data, id));
    dispatch(setDraft('viewingConversationId', id));
  };

  return (
    <>
      <TextArea rows={4} value={text} onChange={onChange} placeholder="New contract conversation ..." />
      <Button icon={<i className="mdi mdi-comment" />} onClick={add} block>
        Add Contract Conversation
      </Button>
    </>
  );
}

function getConversationDetails(id, conversation) {
  if (!id || !conversation) return {};
  const { posts = [] } = conversation;

  const uniquePosters = [...new Set(posts.map((p) => p.by.entityId))];
  const sortedDates = posts.map((p) => p.time).sort();

  const [firstPostTime] = sortedDates;
  const [lastPostTime] = sortedDates.slice(-1);
  let timeText = '',
    timeTextFull = '';
  if (sortedDates.length === 1) {
    timeText = dayAndMonth(firstPostTime);
    timeTextFull = fixDate(firstPostTime);
  } else if (sortedDates.length > 1) {
    const ddFirst = dayAndMonth(firstPostTime);
    const ddLast = dayAndMonth(lastPostTime);
    if (ddFirst !== ddLast) timeText = ddFirst + ' - ' + ddLast;
    else timeText = ddFirst;
    timeTextFull = (
      <>
        <div>First: {fixDate(firstPostTime)}</div>
        <div>Last: {fixDate(lastPostTime)}</div>
      </>
    );
  }

  return {
    uniquePosters,
    timeText,
    timeTextFull,
  };
}

function ConversationAvatar({ id, conversation }) {
  const isCC = isContractConversation(conversation);
  let type;
  let icon;
  if (isCC) {
    type = 'document';
    icon = 'mdi-file';
  } else if (conversation.type === 'TC') {
    type = 'change';
    icon = 'mdi-marker';
  } else if (conversation.type === 'FB') {
    type = 'feedback';
    icon = 'mdi-account-alert';
  } else {
    type = 'inline';
    icon = 'mdi-comment-text';
  }

  if (isContractConversation(conversation)) {
  }
  return (
    <Tooltip title={<IntlMessages id={`studio.conversations.type.${type}`} />} placement={'right'}>
      <div className="conversation-icon ml-2">
        <i className={`mdi ${icon}`} />
      </div>
    </Tooltip>
  );
}

const ConversationListView = memo(({ id, setViewingConversationId, active, conversation, entityData }) => {
  const viewingConversationId = useSelector(({ draft }) => draft.viewingConversationId);
  const { uniquePosters, timeTextFull } = useMemo(
    () => getConversationDetails(id, conversation),
    [conversation, id]
  );
  const { posts = [] } = conversation;

  const view = () => {
    if (viewingConversationId === id) return setViewingConversationId('');
    setViewingConversationId(id);
  };

  const content = getConversationTopic(conversation, { isConversationOpen: false, entityData });

  return (
    <Tooltip
      title={
        <div>
          <div>{timeTextFull}</div>
          <div>
            {posts.length} <IntlMessages id="desc.posts" className="pr-1" />
            {uniquePosters.length} <IntlMessages id="desc.authors" />
          </div>
        </div>
      }
      placement="right"
    >
      <div className="border-bottom clickable" onClick={view}>
        <div className={`list-item ${active ? 'active' : ''} bg-${active ? 'amber-light' : 'light-grey'}`}>
          <div className="ml-1">
            <i
              className={`mdi mdi-checkbox-${
                conversation.completed ? 'marked-circle-outline' : 'blank-circle-outline'
              }`}
            />
          </div>
          <div>
            <ConversationAvatar id={id} conversation={conversation} />
          </div>
          <div className="count">{posts.length}</div>
          <div className="content">{content}</div>
          <div>
            <i className="goto mdi mdi-arrow-right" />
          </div>
        </div>
      </div>
    </Tooltip>
  );
});

export const getConversationTopic = (conversation, options = {}) => {
  const { isConversationOpen, entityData } = options;
  const { type, status, topic, eId } = conversation;
  if (isContractConversation(conversation)) {
    return <IntlMessages id="studio.conversations.generalConversation" />;
  }
  if (type === 'TC')
    return (
      <em>
        <IntlMessages id="studio.conversations.type.changeShort" />
      </em>
    );
  if (type === 'FB') {
    const color = status === 'approved' ? 'green' : 'red';
    if (isConversationOpen) {
      return (
        <div className="d-flex">
          <em>
            <IntlMessages id="studio.conversations.type.feedbackShort" />
          </em>
          <small className={'ml-2 text-uppercase ' + color}>
            <Tag color={color}>
              <IntlMessages id={`app.approval.${status}`} />
            </Tag>
          </small>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column">
          <Tag color={color} className="text-uppercase">
            <IntlMessages id={`app.approval.${status}`} />
          </Tag>
          <small>
            <IntlMessages id="desc.by" /> <b>{Entity.name(entityData[eId], '[Unknown]')}</b>
          </small>
        </div>
      );
    }
  }
  if (topic)
    return (
      <>
        <div className="flex-column">
          <IntlMessages id="studio.conversations.type.inlineShort" />
          <small className="mr-2">
            <i className="mdi mdi-format-quote" />
            {topic}
            <i className="mdi mdi-format-quote" />
          </small>
        </div>
      </>
    );
  return <em>No topic</em>;
};

const FeedbackInfo = ({ conversation, entityData }) => {
  const { eId, status, created } = conversation;
  const color = status === 'approved' ? 'green' : 'red';

  return (
    <div className="border-bottom">
      <AntComment
        author={Entity.name(entityData[eId], '[Unknown user]')}
        avatar={
          <Avatar
            className="ml-2"
            style={{ backgroundColor: uuidColor(eId, { mode: 'light' }).color }}
            icon={<UserOutlined />}
          />
        }
        content={
          <p>
            <Tag color={color}>
              <IntlMessages className="text-uppercase" id={`app.approval.${status}`} />
            </Tag>
          </p>
        }
        datetime={
          <span>
            <Tooltip title={fixDate(created)}>
              <span>{fixDate(created, { onlyDate: true })}</span>
            </Tooltip>
          </span>
        }
      />
    </div>
  );

  /* return (
    <div className={`m-2 p-2`}>
      <div>
        {Entity.name(entityData[eId], '[Unknown user]')}{' '}
        <IntlMessages className={statusColor} id={`app.approval.madeOutcome.${status}`} />{' '}
        <IntlMessages id="general.thisVersion" />
      </div>
      <div>
        <small>{fixDate(created)}</small>
      </div>
    </div>
  ); */
};

const ViewConversation = memo(({ conversation, id, entityData, setViewingConversationId }) => {
  const { user } = useSelector((state) => state.auth);
  const showAllConversations = () => setViewingConversationId('');
  if (!conversation) {
    console.log('Invalid conversation ', { id, conversation });
    return null;
  }

  const conversationTopic = getConversationTopic(conversation, { isConversationOpen: true });

  const { posts = [] } = conversation;

  return (
    <Drawer
      title={
        <div className="">
          {conversationTopic}
          <div className="top-btns">
            <Tooltip title="Close" placement="bottom">
              <Button className="border-0" onClick={showAllConversations}>
                <CloseOutlined />
              </Button>
            </Tooltip>
          </div>
        </div>
      }
      className="specific-conversation-drawer"
      width={400}
      closable={false}
      placement={'left'}
      mask={false}
      maskClosable={true}
      onClose={showAllConversations}
      visible={true}
    >
      <div className="conversation h-100">
        <Posts
          posts={posts}
          conversation={conversation}
          id={id}
          entityData={entityData}
          setViewingConversationId={setViewingConversationId}
          user={user}
        />
        <NewPost id={id} user={user} />
      </div>
    </Drawer>
  );
});

const NewPost = memo(({ id, user }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState('');
  const inputRef = useRef();

  const onChange = (e) => setText(e.target.value);
  const afterNewPostArea = () => {
    setText('');
  };
  const addPost = () => {
    const post = {
      by: { entityId: user.entityId },
      content: { text },
      time: new Date(Date.now()).toISOString(),
    };
    dispatch(addConversationPost(id, post));
    afterNewPostArea();
  };

  useEffect(() => {
    if (!inputRef.current) return;
    if (!id) return;
    setTimeout(() => {
      inputRef.current.focus();
    }, 20);
  }, [id]);

  return (
    <div className="p-2">
      <div className="editor-comment-box">
        <TextArea
          rows={4}
          value={text}
          onChange={onChange}
          ref={inputRef}
          placeholder="Post something in the conversation ..."
        />
        <Button type="primary" onMouseDown={addPost} block className="mt-2">
          <IntlMessages id="studio.conversations.addPost" />
        </Button>
      </div>
    </div>
  );
});

const Posts = memo(({ posts, id, conversation, entityData, setViewingConversationId, user }) => {
  const isStudioWriter = useIsStudioWriter();
  const onlyUserPosts = useMemo(() => {
    if (!posts || posts.length === 0) return true;
    return posts.every((post) => post.by.entityId === user.entityId);
  }, [user, posts]);

  const madeByMe = conversation.eId && user.entityId === conversation.eId;
  const isTrackedChange = conversation.type === 'TC';
  const isFeedback = conversation.type === 'FB';
  const isInlineComment = conversation.type === 'C';

  const allowManage = isStudioWriter || onlyUserPosts || madeByMe;

  let actions = [];
  if (allowManage)
    actions.push({
      k: 'dx',
      c: <HandleCompleted id={id} conversation={conversation} />,
    });
  if (isInlineComment || isTrackedChange)
    actions.push({ k: 'scroll', c: <ScrollToItem id={id} conversation={conversation} /> });
  if (allowManage)
    actions.push({
      k: 'd',
      c: <DeleteConversation id={id} setViewingConversationId={setViewingConversationId} user={user} />,
    });

  return (
    <>
      {!isFeedback && (
        <div className="conversation-top border-bottom text-center p-3">
          {actions.map(({ k, c }, index) => (
            <span key={k}>
              {index !== 0 ? <Divider type="vertical" /> : null}

              {c}
            </span>
          ))}
        </div>
      )}
      {isTrackedChange && <ViewChangeProposal id={id} conversation={conversation} entityData={entityData} />}
      {isFeedback && <FeedbackInfo conversation={conversation} entityData={entityData} />}
      {posts && posts.length ? (
        posts.map((post, index) => (
          <Post
            key={post.time}
            conversationId={id}
            entityData={entityData}
            post={post}
            index={index}
            user={user}
          />
        ))
      ) : (
        <div className="no-posts p-2 fs-sm">
          <div className="mb-0">
            <IntlMessages id="studio.conversations.noPosts" />
          </div>
        </div>
      )}
    </>
  );
});

function HandleCompleted({ id, conversation }) {
  const dispatch = useDispatch();
  const { completed = false } = conversation;

  const toggle = () => {
    const newValue = !completed;
    dispatch(setCompleted(id, newValue));
    if (newValue) dispatch(setDraft('viewingConversationId', ''));
  };

  return (
    <span className="link" onClick={toggle}>
      Mark {completed ? 'un' : ''}Complete
    </span>
  );
}

function ViewChangeProposal({ id, conversation, entityData }) {
  const editor = useTryGetEditor();
  const { eId, created } = conversation;
  const [conversationTuple] = editor
    ? Array.from(
        Editor.nodes(editor, {
          match: (n) => Text.isText(n) && (n._insertedBy?.id === id || n._deletedBy?.id === id),
        })
      )
    : [];
  const [node] = conversationTuple || [];
  // console.log('conv tuple ', id, conversationTuple);
  const byEntity = entityData[eId];
  return (
    <AntComment
      author={byEntity ? Entity.name(byEntity) : <em>Unknown</em>}
      avatar={
        <Avatar
          className="ml-2"
          style={{ backgroundColor: uuidColor(eId, { mode: 'light' }).color }}
          icon={<UserOutlined />}
        />
      }
      content={
        <div>
          <p>
            <small>
              <em>Change proposal</em>
            </small>
          </p>
          {node && node._insertedBy && (
            <p>
              Insert: <span className="inserted">{Node.string(node)}</span>
            </p>
          )}
          {node && node._deletedBy && (
            <p>
              Delete: <span className="deleted">{Node.string(node)}</span>
            </p>
          )}
        </div>
      }
      datetime={
        <span>
          <Tooltip title={fixDate(created)}>
            <span>{fixDate(created, { onlyDate: true })}</span>
          </Tooltip>
        </span>
      }
    />
  );
}

const HIGHLIGHT_TIME = 2500;

function ScrollToItem({ id, conversation }) {
  const editor = useTryGetEditor();
  if (!editor) return null;
  const scroll = () => {
    let domNode;

    if (conversation.type === 'TC') {
      try {
        const [conversationTuple] = Editor.nodes(editor, {
          match: (n) => Text.isText(n) && (n._insertedBy?.id === id || n._deletedBy?.id === id),
        });
        if (!conversationTuple || !conversationTuple[0]) return console.log('No tuple');
        domNode = ReactEditor.toDOMNode(editor, conversationTuple[0]);
      } catch (err) {
        console.log('err getting leaf node ', err);
      }
    } else {
      domNode = document.getElementsByClassName('conv_' + id)[0];
    }

    if (domNode) {
      domNode.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      if (!domNode.classList.contains('highlighted-node')) {
        domNode.classList.add('highlight-node');
        setTimeout(() => {
          domNode.classList.remove('highlight-node');
          domNode.classList.add('highlight-node-out');
        }, HIGHLIGHT_TIME);
        setTimeout(() => {
          domNode.classList.remove('highlight-node-out');
        }, HIGHLIGHT_TIME * 2);
      }
    }
  };
  return (
    <span className="link" onMouseDown={scroll}>
      <IntlMessages id="studio.conversations.scrollTo" />
    </span>
  );
}

const Post = memo(({ conversationId, post, user, entityData }) => {
  const dispatch = useDispatch();

  if (!post) {
    console.log('Invalid post');
    return null;
  }

  const deletePost = () => dispatch(deleteConversationPost(conversationId, post.id));

  const action =
    post.by?.entityId === user.entityId && deletePost ? (
      <span className="ml-3 link" onMouseDown={deletePost}>
        <IntlMessages id="desc.Delete" />
      </span>
    ) : null;

  const byEntity = entityData[post.by?.entityId];

  return (
    <div className="border-bottom">
      <AntComment
        author={byEntity ? Entity.name(byEntity) : <em>Unknown</em>}
        avatar={
          <Avatar
            className="ml-2"
            style={{ backgroundColor: uuidColor(post.by.entityId, { mode: 'light' }).color }}
            icon={<UserOutlined />}
          />
        }
        content={<p>{post.content ? Node.string(post.content) : post.text ? post.text : ''}</p>}
        datetime={
          <span>
            <Tooltip title={fixDate(post.time)}>
              <span>{fixDate(post.time, { onlyDate: true })}</span>
            </Tooltip>
            {action}
          </span>
        }
      />
    </div>
  );
});

function deleteEditorConversationMark(editor, id) {
  try {
    Transforms.setNodes(
      editor,
      (node) => {
        const { _conv } = node;
        if (_conv.length === 1) return { _conv: null };
        return { _conv: node._conv.filter((c) => c !== id) };
      },
      {
        match: (n) => Text.isText(n) && n._conv && n._conv.includes(id),
        at: [],
      }
    );
  } catch (err) {
    console.log('Error removing conversation', err);
  }
}

function DeleteConversation({ id, setViewingConversationId, user }) {
  const editor = useTryGetEditor();
  const dispatch = useDispatch();
  const deleteConv = () => {
    setViewingConversationId('');
    dispatch(deleteConversation(id));
    if (editor) deleteEditorConversationMark(editor, id);
  };

  return (
    <RemoveModal
      onConfirm={deleteConv}
      confirmText={
        <>
          <IntlMessages id="app.general.confirmRemoval" />{' '}
          <IntlMessages id="studio.conversations.conversation" />?
        </>
      }
    >
      <span className="link">
        <IntlMessages id="studio.conversations.deleteEntire" />
      </span>
    </RemoveModal>
  );
}

export default ConversationsContent;
