import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Modal, Radio, Form, Select, Input, Button, notification, Skeleton, AutoComplete, Col, Row } from 'antd';
import api, { generateDebouncer } from 'utils/api';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { useEntitySearch, useMountFetch } from 'hooks';
import { AutoCompleteEntity } from 'components/entity/EditEntity/components';
import { Entity } from 'core/interfaces';
import NewProjectItem from '../../components/project/NewProject/NewProjectItem';
import NewProjectButton from '../../components/project/NewProject/NewProjectButton';
import NewDocumentButton from '../../components/project/NewDocument/NewDocumentButton';
import moment from 'moment';
import { ActionButton } from '../../components/ui/ActionButton';

const debounce = generateDebouncer();
const { Option, OptGroup } = Select;

export default function NewDocument({ projectWasCreated, documentWasCreated, buttonProps }) {
  const [selectedDocumentTemplateId, setSelectedDocumentTemplateId] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedNewEntity, setSelectedNewEntity] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [newProjectModalOpen, setNewProjectModalOpen] = useState(false);
  const [newDocumentModalOpen, setNewDocumentModalOpen] = useState(false);
  const [projects, setProjects] = useState(null);

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [optionalName, setOptionalName] = useState(null)
  const LIMIT = 10
  const [entities = [], searchError] = useEntitySearch({type: 'LegalPerson', search}, {
    minSearch: 1,
    limit: LIMIT + 1,
    fields: ['firstName', 'identificationNumber']
  })
  const [newEntities, setNewEntities] = useState([])
  const [documentTemplates, setDocumentTemplates] = useState(null);
  const [projectTemplates, setProjectTemplates] = useState(null);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const close = () => setOpen(false);

  const onFinish = useCallback(
    ({ project, document }) => {
      if (project && typeof projectWasCreated) projectWasCreated(project);
      if (document && typeof documentWasCreated) documentWasCreated(document);
      close();
    },
    [documentWasCreated, projectWasCreated]
  );

  useMountFetch(`/projects?fields=id,name,projectTemplateId`, setProjects);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/documenttemplates?fields=id,name,description`);
      if (response.data) setDocumentTemplates(response.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/projecttemplates?fields=id,name,data,description`);
      if (response.data) setProjectTemplates(response.data);
    })();
  }, []);

  useEffect(() => {
    const project = projects?.find(({id}) => id === selectedId);
    setOptionalName(project?.name ?? '');
    setSelectedProject(project);
    const entity = entities.find(({id}) => id === selectedId);
    setSelectedEntity(entity)
  }, [selectedId]);

  useEffect(() => {
    if (search.length > 2)
      remoteSearch()
  }, [search])

  const debounceSearch = (value) => {
    setLoading(true)
    debounce(() => setSearch(value))
  }

  const remoteSearch = async () => {
    const searchKey = Number(search.replace('-', '')) ? 'identificationNumber' : 'name'
    const body = { [searchKey]: search, limit: LIMIT }
    const result = await api.post('/entities/autocomplete', body)
    setLoading(false)
    if (result.status === 200) {
      setNewEntities(result.data)
    }
  }

  const onSelect = (value, record) => {
    setSelectedId(record.id)
    const newEntity = record.id ? null : newEntities.find(entity => entity.identificationNumber === record.identificationnumber)
    setSelectedNewEntity(newEntity)
  }

  const onCreate = async () => {
    if (selectedNewEntity && !selectedEntity) return createNewEntity()
    if (selectedProject)
      setNewDocumentModalOpen(true);
    else
      setNewProjectModalOpen(true);
  };

  useEffect(() => {
    if (selectedNewEntity && selectedEntity)
      onCreate()
  }, [selectedNewEntity, selectedEntity])

  const createNewEntity = async () => {
    const result = await api.post('/entities/make', {
      addAddress: false,
      addEmails: false,
      fetchGroup: true,
      firstName: selectedNewEntity.name,
      form: 'AB',
      identificationNumber: selectedNewEntity.identificationNumber,
      jurisdiction: 'se',
      name: selectedNewEntity.name,
      type: 'LegalPerson',
    })
    setSelectedEntity(result.data.newEntities[0])
  };
    

  const onNewProjectCreated = useCallback((data) => {
    setSelectedProject(data);
    setNewDocumentModalOpen(true);
  }, []);

  const onNewDocumentCreated = useCallback(
    (document) => {
      onFinish({ document });
    },
    [onFinish]
  );

  if (!projects) return null

  const documentTemplateOptions = documentTemplates?.reduce((store, document) => {
    const project = projectTemplates
      ?.find(project => (project.data.documentTemplatesIds.standalone.includes(document.id)))
    if (project)
      store.push({
        ...document,
        value: `${document.name} ${project.name}`,
        label: (<Row>
          <Col xs={16}>{document.name}</Col>
          <Col xs={8}>({project.name})</Col>
        </Row>)
      })
    return store
  }, [])

  const projectTemplate = projectTemplates?.find(({data}) => (
    data.documentTemplatesIds.standalone.includes(selectedDocumentTemplateId)
  ))
  const documentTemplate = documentTemplates?.find(({id}) => (
    id === selectedDocumentTemplateId
  ))

  const options = (
    <>
      <OptGroup label="Your projects">
        {
          projects
            .filter(project => projectTemplate?.id === project.projectTemplateId)
            .map(({id, name}, i) => (
              <Option key={id} id={id} value={name}>
                {name}
              </Option>
            ))
        }
      </OptGroup>
      <OptGroup label="Your companies">
        {
          !selectedDocumentTemplateId ? [] : entities
            .map(({id, firstName, identificationNumber}, i) => (
              i < LIMIT ? (
              <Option key={id} id={id} value={`${firstName} (${identificationNumber})`}>
                {`${firstName} (${identificationNumber})`}
              </Option>
              ) : <Option key="has-more2" disabled>...</Option>
            ))
        }
      </OptGroup>
      <OptGroup label="New Companies">
        {
          !selectedDocumentTemplateId ? [] : newEntities
            .filter(({identificationNumber}) => entities
              .every(entity => entity.identificationNumber.replace('-', '') !== identificationNumber))
            .map(({name, identificationNumber}, i) => (
              <Option
                key={i}
                identificationnumber={identificationNumber}
                value={`${name} (${identificationNumber})`}
              >
                {`${name} (${identificationNumber})`}
              </Option>
            ))
        }
      </OptGroup>
    </>
  )

  return (
    <div className="main-page-new-document">
      <ActionButton {...buttonProps} icon="mdi mdi-file-plus-outline" onClick={toggle}>
        <IntlMessages id="app.project.NewDocument" cap />
      </ActionButton>
      <Modal
        title={<IntlMessages id="app.project.NewDocument" cap />}
        okButtonProps={{ disabled: !selectedProject && !selectedEntity && !selectedNewEntity }}
        onOk={onCreate}
        onCancel={() => setOpen(false)}
        visible={open}
        className="modal-md"
      >
        <div className="d-flex justify-content-center">
          <Form
            layout="vertical"
            style={{ width: 500, maxWidth: '100%' }}
          >
            <Form.Item
              label="Document"
            >
              <Select
                options={documentTemplateOptions}
                value={documentTemplate?.name}
                showSearch
                placeholder="search..."
                onSelect={(value, {id}) => setSelectedDocumentTemplateId(id)}
              />
            </Form.Item>
            <Form.Item
              label="Company & project"
            >
              <Row>
                <Col lg={12} xs={24} style={{ marginRight: -1 }}>
                  <Select
                    placeholder="search..."
                    dropdownMatchSelectWidth={500}
                    showSearch
                    loading={loading}
                    onSearch={debounceSearch}
                    onSelect={onSelect}
                  >
                    {options}
                  </Select>
                </Col>
                <Col lg={12} xs={24}>
                  <Input
                    value={optionalName}
                    onChange={e => setOptionalName(e.target.value)}
                    placeholder={selectedEntity ? "new project name" : "project name"}
                    disabled={selectedProject}
                  />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      {newProjectModalOpen && selectedEntity && (
        <NewProjectButton
          hideButton
          open={newProjectModalOpen}
          onClose={() => setNewProjectModalOpen(false)}
          selectedTemplateId={projectTemplate?.id}
          projectWasCreated={onNewProjectCreated}
          fixedValues={{
            __name: optionalName || selectedEntity.name || selectedEntity.firstName,
            __description: projectTemplate?.description,
            __selectedEntities: [{ entity: selectedEntity, entityId: selectedEntity.id, withSubsidiaries: false }],
          }}
        />
      )}
      {selectedProject && (
        <NewDocumentButton
          hideButton
          open={newDocumentModalOpen}
          autostart={true}
          onClose={() => setNewDocumentModalOpen(false)}
          project={selectedProject}
          documentTemplateId={selectedDocumentTemplateId}
          documentWasCreated={onNewDocumentCreated}
        />
      )}
    </div>
  )
}
