export const getByPath = (collection, path) => {
	if (!collection) return;
	let ref = collection;
	const paths = typeof path === "string" ? path.split(".") : path;
	for (const pointer of paths) {
		if (!ref || !ref.hasOwnProperty(pointer)) return;
		ref = ref[pointer];
	}
	return ref;
};
