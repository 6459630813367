import RadioSelect from '../../../newInputs/RadioSelect';
import TextSelect from '../../../newInputs/TextSelect';
import SelectSelect from '../../../newInputs/SelectSelect';
import { DocumentTemplate } from 'core/interfaces';

export function renderSetup(template, settersValue, settersChange) {
  const language = DocumentTemplate.getLanguage(template);

  if (!template.data.create.setup) {
    template.data.create.setup = [
      {
        type: 'text',
        id: '_doc_name',
        label: {
          sv: 'Namn på kontrakt',
          en: 'Contract Name',
        },
        value: template.name || '',
        required: true,
      },
      {
        type: 'text',
        id: '_doc_description',
        label: {
          sv: 'Kort beskrivning',
          en: 'Short Description',
        },
        value: template.description || '',
        required: true,
      },
      ...(template.data.create.setup || []),
    ];
  } else {
    const extraItems = [];
    if (!template.data.create.setup.find((setupItem) => setupItem.id === '_doc_name')) {
      extraItems.push({
        type: 'text',
        id: '_doc_name',
        label: {
          sv: 'Namn på kontrakt',
          en: 'Contract Name',
        },
        value: template.name || '',
        required: true,
      });
    }
    if (!template.data.create.setup.find((setupItem) => setupItem.id === '_doc_description')) {
      extraItems.push({
        type: 'text',
        id: '_doc_description',
        label: {
          sv: 'Kort beskrivning',
          en: 'Short Description',
        },
        value: template.description || '',
        required: true,
      });
    }
    template.data.create.setup = [...extraItems, ...(template.data.create.setup || [])];
  }

  return template.data.create.setup.map((setup) => {
    if (setup.type === 'radio') {
      return (
        <RadioSelect
          key={setup.id}
          full={true}
          language={language}
          radio={setup}
          settersValue={settersValue}
          settersChange={settersChange}
        />
      );
    }
    if (setup.type === 'text') {
      return (
        <TextSelect
          key={setup.id}
          full={true}
          language={language}
          text={setup}
          settersValue={settersValue}
          settersChange={settersChange}
        />
      );
    }
    if (setup.type === 'select') {
      return (
        <SelectSelect
          key={setup.id}
          full={true}
          language={language}
          select={setup}
          settersValue={settersValue}
          settersChange={settersChange}
        />
      );
    }
    return null;
  });
}
