/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, memo } from 'react';
import { Row } from 'antd';
import { Element, Node, Text } from 'slate';
import { useSelector } from 'react-redux';
import { Table as EditorTable } from '../../plugins/slate-table/elements/table';
import { BLOCK_RENDER_OPTIONS } from 'core/types/elements';
import { Indicator, BlockMenu, OfferItems } from './blocks/';
import { useAttributes, getImageSrc, getImageStyle } from 'core/utils/formatting';
import { cx, css } from '@emotion/css';
import FileList from 'components/File/FileList';
import IntlMessages from 'util/IntlMessages';
import { useHover, useIsStudioWriter } from 'hooks';
import config from 'config/config';
import { IS_DEVELOPMENT } from 'config/env';

const { demo } = config;

export const Img = memo((props) => {
  if (props.element && props.element.data && props.element.data._inActive) return null;

  const { attributes, element, children } = props;
  const src = getImageSrc(element);
  const style = getImageStyle(element);

  style.userSelect = 'none';

  if (!src) {
    return (
      <div {...attributes} style={style} className="editor-image-not-found">
        {children}
      </div>
    );
  }

  if (
    IS_DEVELOPMENT &&
    demo &&
    props.element &&
    props.element.data &&
    props.element.data.image_id &&
    props.element.data.image_id.startsWith('shb_')
  ) {
    return (
      <div {...attributes}>
        <div contentEditable={false}></div>
      </div>
    );
  }

  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <img
          className="editor-image"
          alt="Contract Media"
          src={src}
          style={style}
          contentEditable={false}
          // {...attributes}
          onClick={(e) => {
            console.log('Click Img');
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      </div>
      {children}
    </div>
  );
});

export const Attachment = memo(({ attributes, element, children, editor }) => {
  const onDisconnectFile = () => {
    console.log('File was disconnected.');
  };
  return (
    <div className="block-content">
      <div className="contract-attachment-header">
        <IntlMessages id="contract.content.types.attachment" cap />
      </div>

      {element.data.files && (
        <div className="contract-attachment-body">
          <Row>
            <FileList
              tableType="listConnected"
              buttonContent={true}
              hideCard={true}
              connectedFiles={element.data.files}
              onDisconnectFile={onDisconnectFile}
              hideNewUpload={true}
            />
          </Row>
        </div>
      )}
    </div>
  );
});

// For general comments submitted by an external party.
export const notes_list_item = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content notes_list_item">{children}</div>;
});
// For general comments submitted by an external party.

export const Section = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});
export const Section_header = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});
export const Clause = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});
export const Container = memo(({ attributes, element, children, editor }) => {
  return (
    <div {...attributes} {...useAttributes(editor, element)}>
      <div className="block-content">{children}</div>
    </div>
  );
});
export const Schedule = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});
export const Definitions = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});

export const Paragraph = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});

export const Textbox = memo(({ attributes, element, children, editor }) => {
  return <div className="block-content">{children}</div>;
});

export const Numbered_list = memo(({ attributes, element, children, editor }) => {
  let className = 'block-content';
  if (element.data && element.data._format && element.data._format.listType) {
    const { listType, indicatorPrefix = '', indicatorSuffix = '' } = element.data._format;
    let listTypeClass = `
     > div > div > div:not(.block-inactive):not(.list-item-hanging) > li:before {
      content: "${indicatorPrefix}" counter(list-level-1, ${listType}) "${indicatorSuffix}" !important;
    }
    `;
    className = cx([
      className,
      css`
        ${listTypeClass}
      `,
    ]);
  }
  return <ol className={className}>{children}</ol>;
});

export const Bulleted_list = memo(({ attributes, element, children, editor }) => {
  return <ul className="block-content">{children}</ul>;
});

export const Heading_one = memo(({ attributes, element, children, editor }) => {
  return <h1 className="block-content">{children}</h1>;
});

export const Heading_two = memo(({ attributes, element, children, editor }) => {
  return <h2 className="block-content">{children}</h2>;
});

export const Heading_three = memo(({ attributes, element, children, editor }) => {
  return <h3 className="block-content">{children}</h3>;
});

export const Schedule_one = memo(({ attributes, element, children, editor }) => {
  return <div>{children}</div>;
});

export const Schedule_two = memo(({ attributes, element, children, editor }) => {
  return <div>{children}</div>;
});

export const List_item = memo(({ attributes, element, children, editor }) => {
  return <li className={'block-content'}>{children}</li>;
});

const CONVERSATION_PROPAGATE_AT = ['table'];

function getConversationTuples(element) {
  const { children } = element;
  const containsElements = Element.isElementList(children);
  if (containsElements && !CONVERSATION_PROPAGATE_AT.includes(element.type)) return null;

  const conversationsTuples = Array.from(Node.nodes(element)).filter(
    ([n]) => Text.isText(n) && Array.isArray(n._conv) && n._conv.length > 0
  );

  return conversationsTuples.length ? conversationsTuples : null;
}
function getConversationsItems(tuples) {
  const done = [];
  const uniqueConversationTuples = tuples.filter(([n]) => {
    if (done.includes(n._conv)) return false, done.push(n._conv);
    return true;
  });

  const uniqueIds = [...new Set(tuples.map(([n]) => n._conv).flat())];

  const length = uniqueIds.length;

  return [uniqueConversationTuples, uniqueIds, length];
}

function ConversationIndication({ nodes, ids, length, isActive, toggleActive, setIsHighlighted }) {
  const onMouseEnter = () => {
    setIsHighlighted(true);
  };
  const onMouseLeave = () => {
    setIsHighlighted(false);
  };

  return (
    <div
      className={`conversation-inline-comment ${isActive ? 'active' : ''}`}
      contentEditable={false}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={toggleActive}
    >
      {length}
    </div>
  );
}

function ConversationsWrapper({ children, conversationTuples, element }) {
  const [isActive, setIsActive] = useState(false);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const toggleActive = () => setIsActive(!isActive);
  const [uniqueConversationTuples, uniqueIds, length] = getConversationsItems(conversationTuples);
  return (
    <div
      className={`element-with-conversation-items ${isActive ? 'active' : ''} ${
        isHighlighted ? 'is-highlighted' : ''
      }`}
    >
      <ConversationIndication
        nodes={uniqueConversationTuples}
        ids={uniqueIds}
        length={length}
        isActive={isActive}
        toggleActive={toggleActive}
        setIsHighlighted={setIsHighlighted}
      />
      {children}
    </div>
  );
}

function ConversationCheckWrapper({ children, element, editor }) {
  const conversationTuples = getConversationTuples(element);
  if (!conversationTuples) return children;
  if (editor.meta?.markup) return children;
  return (
    <ConversationsWrapper conversationTuples={conversationTuples} children={children} element={element} />
  );
}

export const BlockWrapper = memo(({ attributes, element, children, editor, documentDraftMode, ...rest }) => {
  const studio_draftMode = useSelector(({ draft }) => draft.studio_draftMode);
  const isStudioWriter = useIsStudioWriter();
  const styleAttributes = useAttributes(editor, element);

  if (editor.meta && editor.meta.noContract) {
    return (
      <div {...attributes} {...styleAttributes}>
        {children}
      </div>
    );
  }

  let content;

  if (BLOCK_RENDER_OPTIONS.customRender.includes(element.type)) {
    return children;
  }

  if (studio_draftMode === 'inline' && isStudioWriter) {
    content = (
      <ElementOfferDocument
        attributes={attributes}
        children={children}
        editor={editor}
        element={element}
        documentDraftMode={documentDraftMode}
        {...rest}
      />
    );
  } else {
    content = (
      <div {...attributes} {...styleAttributes}>
        {children}
      </div>
    );
  }

  return (
    <div className={'block-container type-' + element.type}>
      <ConversationCheckWrapper element={element} editor={editor}>
        {content}
      </ConversationCheckWrapper>
    </div>
  );
});

const ElementOfferDocument = memo(({ attributes, element, children, editor, ...rest }) => {
  const styleAttributes = useAttributes(editor, element);
  return (
    <>
      {<OfferItems editor={editor} element={element} {...rest} />}
      <div {...attributes} {...styleAttributes}>
        {children}
      </div>
    </>
  );
});

const BlockHoverItem = memo(({ attributes, element, children, editor }) => {
  const styleAttributes = useAttributes(editor, element);
  const [hoverRef, isHovered, controller] = useHover();

  return (
    <div ref={hoverRef} className={'block-container type-' + element.type}>
      {isHovered && <HoverContent editor={editor} element={element} controller={controller} />}

      <div {...attributes} {...styleAttributes}>
        {children}
      </div>
    </div>
  );
});

const HoverContent = memo(({ element, editor, controller }) => {
  return (
    <>
      {!BLOCK_RENDER_OPTIONS.withoutIndicator.includes(element.type) && (
        <Indicator element={element} editor={editor} />
      )}
      {BLOCK_RENDER_OPTIONS.withBlockMenu.includes(element.type) && !element._isInActiveRepresentation && (
        <BlockMenu editor={editor} element={element} controller={controller} />
      )}
    </>
  );
});

export const Table = memo((props) => {
  if (props.element && props.element.data && props.element.data._inActive) return null;
  return <EditorTable {...props} />;
});
export const Table_row = memo((props) => {
  if (props.element && props.element.data && props.element.data._inActive) return null;
  return <EditorTable {...props} />;
});
export const Table_cell = memo((props) => {
  if (props.element && props.element.data && props.element.data._inActive) return null;
  return <EditorTable {...props} />;
});
export const Table_content = memo((props) => {
  if (props.element && props.element.data && props.element.data._inActive) return null;
  return <EditorTable {...props} />;
});

export const block_quote = memo(({ attributes, element, children, editor }) => {
  return <blockquote {...attributes}>{children}</blockquote>;
});
