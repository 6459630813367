import {
  SET_ENTITIES,
  UPDATE_ENTITY,
  INSERT_ENTITY,
  REMOVE_ENTITY,
  BULK_UPSERT_ENTITIES,
} from 'constants/ActionTypes';
import { Entity } from 'core/interfaces';
import uuid from 'uuid-random';

function entityExistIndex(entities, id) {
  return entities.findIndex((data) => id === data.id);
}

export const setEntities = (data) => {
  if (!Array.isArray(data)) {
    console.warn('Tried to set non array as entities', { data });
    return {
      type: '__NOP',
    };
  }
  return {
    type: SET_ENTITIES,
    payload: data,
  };
};

export const bulkUpsertEntity = (data) => {
  if (!Array.isArray(data))
    return {
      type: '__NOP',
    };
  return (dispatch, getState) => {
    dispatch({
      type: BULK_UPSERT_ENTITIES,
      payload: data,
    });
  };
};

export const upsertEntity = (data, options = {}) => {
  return (dispatch, getState) => {
    const updatingIndex = entityExistIndex(getState().entities, data.id);
    if (updatingIndex === -1) {
      const create = insertEntity(data, options);
      create(dispatch, getState);
      return;
    }

    dispatch({
      ...options,
      type: UPDATE_ENTITY,
      payload: { index: updatingIndex, data, id: data.id },
    });
  };
};

export const updateEntity = (id, data, options = {}) => {
  return (dispatch, getState) => {
    const updatingIndex = entityExistIndex(getState().entities, id);
    if (updatingIndex === -1) {
      console.log('Could not find entity with id ' + id);
      return;
    }

    dispatch({
      ...options,
      type: UPDATE_ENTITY,
      payload: { index: updatingIndex, data, id },
    });
  };
};

export const insertEntity = (data = {}, options = {}) => {
  return (dispatch, getState) => {
    let { id, parentId } = data;
    if (!parentId) {
      const entities = [...getState().entities];
      const topCo = Entity.findTopCo(entities);
      if (topCo) {
        parentId = topCo.id;
      } else {
        parentId = null;
      }
    }
    if (!id) id = uuid();

    const newEntity = { ...data, id, parentId };
    dispatch({
      ...options,
      type: INSERT_ENTITY,
      payload: newEntity,
    });
  };
};
export const addEntity = (id, pid, data) => {
  return {
    type: INSERT_ENTITY,
    payload: Object.assign({ id, parentId: pid }, data),
  };
};

export const removeEntity = (id) => {
  const removeIds = [id];
  return (dispatch, getState) => {
    const entities = [...getState().entities];
    const childrenIds = collectChildren(entities, id);
    return dispatch({
      type: REMOVE_ENTITY,
      payload: removeIds.concat(childrenIds),
    });
  };
};

const collectChildren = (entities, parentId) => {
  let childrenIds = [];
  for (let entity of entities) {
    if (entity.parentId !== parentId) continue;
    childrenIds.push(entity.id);
    const subChildren = collectChildren(entities, entity.id);
    childrenIds = childrenIds.concat(subChildren);
  }
  return childrenIds;
};
