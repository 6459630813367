export const generateInitialValues = (documentTemplate) => {
  if (!documentTemplate) return {};
  const val = {};

  if (documentTemplate.data.create.setup) {
    documentTemplate.data.create.setup.reduce((acc, curr) => {
      acc[curr.id] = curr.value || (Array.isArray(curr.options) ? curr.options[0].id : '') || '';
      return acc;
    }, val);
  }

  val._doc_name = documentTemplate.name || '';
  val._doc_description = documentTemplate.description || '';
  val.__selectedEntities = [];

  return val;
};
