import { Entity } from 'core/interfaces';
import { plural, hasRelation, getPrincipalAndAgent } from '.';

function getOrCreateRelation(principalEntity, agentEntity, capacities) {
  return {
    ...(Entity.getRelation(principalEntity, agentEntity) ||
      Entity.createRelation(principalEntity.id, agentEntity.id, capacities)),
    capacities,
  };
}
function updateAgentRecord(principalEntity, agentEntity, relation) {
  return principalEntity.Agents.map((a) => {
    if (a.id !== agentEntity.id) return a;
    return {
      ...agentEntity,
      EntityRelation: relation,
    };
  });
}
function createAgentRecord(principalEntity, agentEntity, relation) {
  const agents = principalEntity.Agents || [];
  return agents.concat({ ...agentEntity, EntityRelation: relation });
}
function updateOrCreateAgentRecords(principalEntity, agentEntity, relation) {
  const hasAgent = hasRelation(principalEntity, agentEntity, 'Agent');
  if (hasAgent) {
    return updateAgentRecord(principalEntity, agentEntity, relation);
  }
  return createAgentRecord(principalEntity, agentEntity, relation);
}

function updatePrincipalRecord(principalEntity, agentEntity, relation) {
  return agentEntity.Principals.map((p) => {
    if (p.id !== principalEntity.id) return p;
    return {
      ...principalEntity,
      EntityRelation: relation,
    };
  });
}
function createPrincipalRecord(principalEntity, agentEntity, relation) {
  const principals = agentEntity.Principals || [];
  return principals.concat({ ...principalEntity, EntityRelation: relation });
}
function updateOrCreatePrincipalRecords(principalEntity, agentEntity, relation) {
  const hasPrincipal = hasRelation(agentEntity, principalEntity, 'Principal');
  if (hasPrincipal) {
    return updatePrincipalRecord(principalEntity, agentEntity, relation);
  }
  return createPrincipalRecord(principalEntity, agentEntity, relation);
}

function addOrUpdateAgentForPrincipal(principalEntity, agentEntity, capacities) {
  const relation = getOrCreateRelation(principalEntity, agentEntity, capacities);
  const principalAgents = updateOrCreateAgentRecords(principalEntity, agentEntity, relation);
  return { ...principalEntity, Agents: principalAgents };
}
function addOrUpdatePrincipalForAgent(principalEntity, agentEntity, capacities) {
  const relation = getOrCreateRelation(principalEntity, agentEntity, capacities);
  const agentPrincipals = updateOrCreatePrincipalRecords(principalEntity, agentEntity, relation);
  return { ...agentEntity, Principals: agentPrincipals };
}

export function addOrUpdateRelation(principalEntity, agentEntity, capacities) {
  const updatedPrincipalEntity = addOrUpdateAgentForPrincipal(principalEntity, agentEntity, capacities);
  const updatedAgentEntity = addOrUpdatePrincipalForAgent(principalEntity, agentEntity, capacities);

  return {
    updatedPrincipalEntity,
    updatedAgentEntity,
  };
}

function removeRelated(e1, e2, relationType) {
  const key = plural(relationType);
  return {
    ...e1,
    [key]: (e1[key] || []).filter((a) => a.id !== e2.id),
  };
}

export function removeRelation(e1, e2, relationType) {
  const [principal, agent] = getPrincipalAndAgent(e1, e2, relationType);

  const updatedPrincipalEntity = removeRelated(principal, agent, 'Agent');
  const updatedAgentEntity = removeRelated(agent, principal, 'Principal');

  return {
    updatedPrincipalEntity,
    updatedAgentEntity,
  };
}
