import React from 'react';
import { isBlock, isInline } from 'core/types/elements';
import * as elements from './elements/elementsRender';
import { BlockWrapper } from './elements/render/blocksRender';
import * as logics from './elements/elementsLogic';

export const RenderElements = (props) => {
  const { attributes, children, element } = props;
  if ((!isBlock(element) && !isInline(element)) || !element.type) {
    console.log('Unknown element:', element);
    return <div {...attributes}>{children}</div>;
  }
  let content;
  if (elements[element.type]) {
    const Element = elements[element.type];
    content = <Element {...props} />;
  } else {
    const ucType = element.type[0].toUpperCase() + element.type.substr(1);
    if (elements[ucType]) {
      const Element = elements[ucType];
      content = <Element {...props} />;
    } else {
      console.log('Unknown type', element.type, ucType);
      return <div {...attributes}>{children}</div>;
    }
  }

  if (isBlock(element)) {
    return <BlockWrapper {...props}>{content}</BlockWrapper>;
  }
  return content;
};

export const withElementLogic = (editor) => {
  for (let logic in logics) {
    if (typeof logics[logic] !== 'function') continue;
    const logicEditor = logics[logic](editor);
    if (logicEditor !== editor) continue; // Invalid logic
    editor = logicEditor;
  }
  return editor;
};
