import React, { useCallback, useEffect, useState, useRef } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { Editor, Path, Range, Transforms } from 'slate';
import { Button } from 'antd';
import { useEventState } from 'hooks';
import { inlineHasMenu, inlineMenuComponent, TextSelect } from '../InlineFieldMenus/';
import Portal from '../Portal';

const FULL_MENU_TO_RIGHT = false;

export const HoveringField = ({ isEditingTemplate, onEscapeFnRef, externalScrollControlY }) => {
  const hoveringToolbar = useEventState('hoveringToolbar', true);
  if (!hoveringToolbar || !isEditingTemplate) return null;
  return (
    <HoveringFieldEditor externalScrollControlY={externalScrollControlY} onEscapeFnRef={onEscapeFnRef} />
  );
};

const HoveringFieldEditor = ({ externalScrollControlY, onEscapeFnRef }) => {
  const ref = useRef();
  const lastSelection = useRef(null);
  const entry = useRef({ node: null, path: null });
  const recentlyClosed = useRef(false);

  const [inlineMenuData, setInlineMenuData] = useState(null);

  let menuPlacement = 'side';
  /* if (inlineMenuData) {
    if (inlineMenuData[0].placement) menuPlacement = inlineMenuData[0].placement
    else if(inlineMenuData[0] === 'text') menuPlacement = 'top'
  } */
  const [recalculatePosition, setRecalculatePosition] = useState(false);
  const editor = useSlate();
  const componentScrollCallback = useRef({ fn: null });

  const collapseSelection = useCallback(() => {
    const newSelection = {
      anchor: editor.selection.anchor,
      focus: JSON.parse(JSON.stringify(editor.selection.anchor)),
    };
    setTimeout(() => {
      Transforms.setSelection(editor, newSelection);
    }, 10);

    return newSelection;
  }, [editor]);

  const close = useCallback(
    (options = {}) => {
      const { reselect = false, collapse = false } = options;
      const el = ref.current;
      el.removeAttribute('style');
      recentlyClosed.current = !reselect;
      setInlineMenuData(null);
      ReactEditor.focus(editor);

      if (collapse) {
        lastSelection.current = collapseSelection();
      } else {
        setTimeout(() => {
          const lastSel = lastSelection.current;
          lastSelection.current = editor.selection;
          Transforms.select(editor, lastSel);
        }, 10);
      }
    },
    [ref, setInlineMenuData, editor, collapseSelection]
  );

  onEscapeFnRef.current = close;

  // Used to cause re-positioning upon scroll.
  useEffect(() => {
    externalScrollControlY.fn = (evt) => {
      setRecalculatePosition(evt.target.scrollTop);
      if (typeof componentScrollCallback.current.fn === 'function') componentScrollCallback.current.fn(evt);
    };
    return () => {
      externalScrollControlY.fn = null;
    };
  }, [externalScrollControlY]);

  useEffect(() => {
    const el = ref.current;
    if (!editor.meta.isEditingTemplate) {
      setInlineMenuData(null);
      return el?.removeAttribute('style');
    }
    const { selection } = editor;
    if (!el || !selection) {
      setInlineMenuData(null);
      return el?.removeAttribute('style');
    }

    // Prevent re-opening a recently closed menu.
    if (recentlyClosed.current && Path.equals(selection.anchor.path, lastSelection.current.anchor.path)) {
      return;
    } else {
      recentlyClosed.current = false;
    }

    if (
      !lastSelection.current ||
      !selection ||
      !Path.equals(selection.anchor.path, lastSelection.current.anchor.path)
    ) {
      lastSelection.current = selection;
    }

    let rect;
    // let type;
    if (Range.isCollapsed(selection)) {
      const newEntry = editor.currentInlineEntry();
      if (!newEntry[0]) {
        el.removeAttribute('style');
        setInlineMenuData(null);
        return;
      }
      const [newNode] = newEntry;
      const hasMenu = inlineHasMenu(newNode);
      if (!hasMenu) {
        el.removeAttribute('style');
        setInlineMenuData(null);
        return;
      }
      let domElem;
      try {
        domElem = ReactEditor.toDOMNode(editor, newNode);
      } catch (e) {
        return;
      }
      rect = domElem.getBoundingClientRect();
      // type = 'inline-menu';

      entry.current.node = newNode;

      // Only set entry if currently empty (entry[1] does not exist), or
      // the newEntry is of a different path (ie Path.equals is false)
      if (!entry.current.path || !Path.equals(newEntry[1], entry.current.path)) {
        entry.current.path = newEntry[1];
        setInlineMenuData([inlineMenuComponent(newNode), newEntry]);
      } else if (!inlineMenuData) {
        setInlineMenuData([inlineMenuComponent(newNode), newEntry]);
      }
    } else {
      const domSelection = window.getSelection();
      let domRange;
      try {
        domRange = domSelection.getRangeAt(0);
      } catch (err) {
        return;
      }
      rect = domRange.getBoundingClientRect();

      const entry = Editor.leaf(editor, editor.selection);
      setInlineMenuData(['text', entry]);
      // type = 'text';
    }

    if (rect.top < 103) {
      el.removeAttribute('style');
      setInlineMenuData(null);
      return;
    }
    if (menuPlacement === 'top' /* || type === 'text' */) {
      el.style.top = `${rect.top + window.pageYOffset - 14 - el.offsetHeight}px`;
      el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;

      el.classList.add('placement-top');
    } else {
      let top = rect.top + window.pageYOffset - 13;
      const windowHeight = window.innerHeight;
      if (top + 250 > windowHeight) {
        const elHeight = el.offsetHeight;
        top = top - elHeight + 10;

        // If the menu element height is very low, it is likely
        // that it has just spawned and has not yet fully loaded
        // to its full height. Then adjust the height properly after
        // a short while
        if (elHeight < 20) {
          setTimeout(() => {
            el.style.top = top + elHeight - el.offsetHeight + 'px';
          }, 10);
        }

        el.classList.add('bottom-up');
      } else {
        el.classList.remove('bottom-up');
      }

      let left;
      el.style.top = `${top}px`;
      if (FULL_MENU_TO_RIGHT) {
        const editorElem = document.getElementById('editing-editor');
        if (editorElem) {
          const editorElemRect = editorElem.getBoundingClientRect();
          left = editorElemRect.right - 20;
        }
      }
      if (!left) left = rect.left + window.pageXOffset + rect.width + 15;
      el.style.left = left + 'px';
    }

    el.style.opacity = 1;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor.selection, recalculatePosition, editor, menuPlacement]);

  let componentContent;

  if (inlineMenuData && inlineMenuData[0] === 'text') {
    const [, newEntry] = inlineMenuData || [];
    const [node, path] = newEntry || [];
    componentContent = (
      <TextSelect
        editor={editor}
        node={node}
        path={path}
        componentScrollCallback={componentScrollCallback}
        close={close}
      />
    );
  } else {
    const [inlineMenu, newEntry] = inlineMenuData || [];
    const [node, path] = newEntry || [];
    componentContent =
      node && inlineMenu && inlineMenu.component ? (
        <inlineMenu.component
          editor={editor}
          node={node}
          path={path}
          componentScrollCallback={componentScrollCallback}
          close={close}
        />
      ) : null;
  }

  return (
    <Portal>
      <div ref={ref} className={'inline-editor-menu ' + (menuPlacement === 'top' ? 'top' : 'full')}>
        {componentContent}
        <Button
          type="link"
          className="close"
          size="small"
          icon={<i className="mdi mdi-close" />}
          onClick={close}
        />
      </div>
    </Portal>
  );
};
