import { useMemo } from 'react';
import { Select } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { PERSON_RELATION_CAPACITIES } from 'core/config/constants';

const { Option } = Select;

export function AgentCapacities({ ...rest }) {
  const formatMessage = useIntlMessage();
  const availableCapacityEntries = useMemo(
    () =>
      PERSON_RELATION_CAPACITIES.map((capacity) => {
        return {
          id: capacity,
          value: formatMessage(`app.persons.capacities.${capacity}`),
        };
      }),
    [formatMessage]
  );
  return (
    <div className="ml-2">
      <h6>
        <IntlMessages id="app.general.capacities" />
      </h6>
      <Select
        mode="multiple"
        size="small"
        name="capacities"
        style={{ width: '100%' }}
        placeholder={formatMessage('app.general.capacities')}
        {...rest}
      >
        {availableCapacityEntries.map((cap) => (
          <Option key={cap.id} value={cap.id}>
            {cap.value}
          </Option>
        ))}
      </Select>
    </div>
  );
}
