function properEmailContacts(contact, emails) {
  const contactEmails =
    emails &&
    emails.map((email, index) => ({
      type: 'EMAIL',
      label: index === 0 ? 'mainEmail' : 'email_' + (index + 1),
      address: email,
    }));

  const newContact = (Array.isArray(contact) ? contact.filter((c) => c.type !== 'EMAIL') : []).concat(
    contactEmails
  );
  return newContact;
}

export function entityFromValues(values) {
  const { emails, contact, ...rest } = values;

  return {
    ...rest,
    contact: properEmailContacts(contact, emails),
  };
}
