import {
  Editor,
  Element,
  Node,
  //Path,
  Transforms,
  // Range
} from '../../import/slate';

export const Mod = {
  // setTime: (time) => Mod.time = time,
  replaceChildren: (editor, children, at) => {
    Editor.withoutNormalizing(editor, () => {
      Transforms.removeNodes(editor, {
        at: { anchor: Editor.start(editor, at), focus: Editor.end(editor, at) },
      });
      Transforms.insertNodes(editor, children, { at: [...at, 0] });
      markUpdated(editor, at);
    });
  },
  replaceText: (editor, text, at, marks) => {
    Editor.withoutNormalizing(editor, () => {
      Transforms.insertText(editor, text, { at });
      if (typeof marks === 'object' && marks !== null) {
        Transforms.setNodes(editor, marks, {
          match: Text.isText,
          split: true,
          at: Editor.range(editor, at),
        });
      }
      markUpdated(editor, at);
    });
  },
  checkActive: (editor, at = []) => {
    Editor.withoutNormalizing(editor, () => {
      Transforms.setNodes(
        editor,
        (node) => {
          console.log('set ode..', node);
          return {
            data: { ...node.data, _inActive: true },
          };
        },
        {
          match: (n) => {
            // if(!Element.isElement(n)) return
            if (!n.data || !n.data.acp) return;
          },
          at,
        }
      );

      // markUpdated(editor, at)
    });
  },
};

const markUpdated = (editor, at) => {
  const node = Node.get(editor, at);
  if (!Element.isElement(node)) return;
  Transforms.setNodes(
    editor,
    {
      data: {
        ...(node.data || {}),
        _updateTime: Mod.time,
      },
    },
    { at }
  );
};

export const createModifyer = (options = {}) => {
  const { time = Date.now() } = options;
  Mod.time = time;
  return Mod;
};
