import React, { useState, useEffect } from 'react';
import { Form, Checkbox } from 'antd';
import IntlMessages from 'util/IntlMessages';

function CheckboxContainer({ onChildUpdate, currentInput }) {
  const [defaultValue, setDefaultValue] = useState(currentInput ? currentInput.value : false);

  useEffect(() => {
    onChildUpdate({ defaultValue });
  }, []);

  const onChangeDefaultValue = () => {
    const newValue = !defaultValue;
    setDefaultValue(newValue);
    onChildUpdate({ defaultValue: newValue });
  };

  return (
    <>
      <Form layout="vertical">
        <Form.Item
          label={<IntlMessages id="studio.template.inputs.defaultValue" />}
          extra={
            <div>
              <small>Whether or not the checkbox should be default selected</small>
            </div>
          }
        >
          <Checkbox checked={defaultValue} onChange={onChangeDefaultValue} />
        </Form.Item>
      </Form>
    </>
  );
}

export default CheckboxContainer;
