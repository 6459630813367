import React, { useMemo } from 'react';
import { Editor, Transforms, Text, Range, Point } from 'slate';
import { useSlate } from 'slate-react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip, notification, Button } from 'antd';
import uuid from 'uuid-random';
import IntlMessages from 'util/IntlMessages';
import { addConversation, setDraft } from 'appRedux/actions';

export function useAddConversation({ button = {} }) {
  const editor = useSlate();
  const dispatch = useDispatch();
  const { entityId } = useSelector(({ auth }) => auth.user);
  const { selection } = editor;
  const isExpanded = selection && Range.isExpanded(selection);

  const saveConversation = () => {
    if (!editor.selection)
      return notification.warn({
        message: 'Select contract text to create a conversation',
      });

    const id = uuid();

    const existingMarks = Editor.marks(editor);

    if (existingMarks?._conv) {
      Transforms.setNodes(
        editor,
        (node) => {
          return {
            _conv: (node._conv || []).concat(id),
          };
        },
        { match: Text.isText, split: true }
      );
    } else {
      Editor.addMark(editor, '_conv', [id]);
    }

    const { anchor, focus } = editor.selection;
    const focusIsBeforeAnchor = Point.isBefore(focus, anchor);

    const currentLocation = {
      anchor: focusIsBeforeAnchor ? focus : anchor,
      focus: focusIsBeforeAnchor ? anchor : focus,
    };

    const [closestElementTuple] = Array.from(
      Editor.nodes(editor, { at: editor.selection, match: editor.isBlock, mode: 'lowest' })
    );
    const closestElementId = closestElementTuple && closestElementTuple[0]?.data?.item_id;
    let topic = null;
    try {
      const selectedString = Editor.string(editor, currentLocation);
      if (selectedString) topic = selectedString;
    } catch (err) {}
    const data = {
      type: 'C',
      eId: entityId,
      topic,
      closestElementId,
      posts: [],
    };

    dispatch(addConversation(data, id));
    dispatch(setDraft('viewingConversationId', id));
  };

  const tooltip = useMemo(() => {
    return editor.selection && isExpanded ? (
      <IntlMessages id="studio.conversations.addConversation" />
    ) : (
      <IntlMessages id="studio.conversations.selectToConversate" />
    );
  }, [editor.selection, isExpanded]);

  const disabled = !selection || !isExpanded;

  const btnProps = { ...button };

  const btnComponent = (
    <Tooltip title={tooltip} placement="left">
      <Button {...btnProps} onMouseDown={saveConversation} disabled={disabled} />
    </Tooltip>
  );

  return [btnComponent];
}
