import React from 'react';
import { Menu, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { HeaderSkeleton } from '../../Skeletons/Overview';
import { Entity } from '../../../core/interfaces';
import { useProjectMainEntities } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import { OverviewHeader } from '../../Headers/EntityHeader/OverviewHeader';

const routesWithoutHeader = [['/version/'], ['/document/']];

function EntitiesDropdown({ entities }) {
  const menu = (
    <Menu>
      {entities.map((entity) => (
        <Menu.Item key={entity.id}>
          <Link to={`/entity/${entity.id}`} className="mb-0">
            {Entity.name(entity)}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <small>
        <Space>
          <span>
            {entities.length} <IntlMessages id="general.entities" />
          </span>
          <DownOutlined className="fs-xs" />
        </Space>
      </small>
    </Dropdown>
  );
}

function ProjectEntities({ project }) {
  const mainEntities = useProjectMainEntities(project, { omitClient: true }) ?? [];

  if (!mainEntities || mainEntities.length === 0) return null;
  if (mainEntities.length === 1) {
    const [entity] = mainEntities;
    return (
      <Link to={`/entity/${entity.id}`} className="mb-0 fs-md link">
        {Entity.name(entity)}
      </Link>
    );
  } else {
    return <EntitiesDropdown entities={mainEntities} />;
  }
}

const ProjectHeader = ({ project }) => {
  const location = useLocation();

  if (!project) return <HeaderSkeleton />;

  const includeHeader =
    routesWithoutHeader.findIndex((condition) =>
      condition.every((part) => location.pathname.includes(part))
    ) === -1;

  if (!includeHeader) return null;

  return (
    <OverviewHeader
      title={project.name}
      description={project.description}
      extra={<ProjectEntities project={project} />}
    />
  );
};

export default ProjectHeader;
