import {SWITCH_LANGUAGE} from "constants/ActionTypes";

export function switchLanguage(locale) {
  return (dispatch) => {
    localStorage.setItem('settings.locale', JSON.stringify(locale))
    dispatch({
      type: SWITCH_LANGUAGE,
      payload: locale
    });
  }
}
