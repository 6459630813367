import moment from 'moment';

export const dateGenesis = {
  id: 'dateGenesis',
  genesisHandler: true,
  match: ({ node, api }) => {
    return node.type === 'field' && node.variant === 'date' && node.data && node.data.instructions;
  },
  handler: function ({ matches, api }) {
    moment.locale(api.interfaces.Contract.getLanguage(this.contract));

    if (matches.default) {
      for (const { node, actions } of matches.default) {
        const { instructions } = node.data;
        const { format, prefix, suffix, add, subtract } = instructions;

        if (!format) {
          console.log('No format for dateAgreement.');
          return;
        }

        let date = moment();

        if (add) {
          const { value, type } = add;
          date.add(value, type);
        } else if (subtract) {
          const { value, type } = add;
          date.subtract(value, type);
        }

        let value = date.format(format);

        if (prefix) {
          value = prefix + value;
        }
        if (suffix) {
          value = value + suffix;
        }
        actions.replaceText(value);
      }
    }
  },
};
