import { Row, Col, Card } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { gutter } from 'config';

export default function DownloadFiles({ data = null }) {
  return (
    <Row gutter={gutter} style={{ flex: '1 1' }}>
      {data.map(({ id, url }) => (
        <Col key={url} span={5}>
          <Card className={`card-full py-4 px-2`}>
            <div className="flex-row justify-content-center mb-3 mb-sm-4 clickable">
              <a href={url}>
                <span
                  className={`size-80 border border-primary text-primary flex-row justify-content-center align-items-center rounded-circle position-relative`}
                >
                  <i className={`mdi mdi-home-modern fs-xlxl`} />
                  <i
                    className="mdi mdi-file-excel fs-xl position-absolute top-0"
                    style={{ right: '-20px' }}
                  />
                </span>
              </a>
            </div>
            <div className="text-center">
              <small className={`mb-0 mb-sm-3`}>
                <a href={url}>
                  <IntlMessages id={id} />
                </a>
              </small>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
