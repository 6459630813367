import { Node, Path, Transforms } from 'slate';
import { ReactEditor } from 'slate-react'
import deepmerge from 'deepmerge';

export const addData = editor => ((props, nodeOrPath) => {
    const isNode = Node.isNode(nodeOrPath);
    if(!isNode && !Path.isPath(nodeOrPath)) return;
    const node = isNode ? nodeOrPath : Node.get(editor, nodeOrPath);
    const path = !isNode ? nodeOrPath : ReactEditor.findPath(editor, node);
    
    const data = deepmerge(node.data, props)

    Transforms.setNodes(editor, {data}, {at: path})
  }
)
