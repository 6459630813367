function isMultiValueField(api, contract, path) {
  const inputField = api.interfaces.Contract.getUiInputFieldDataByPath(contract, path);
  if (!inputField) return false;
  return inputField.type === 'multipleText' || inputField.multiple;
}

export const ordinaryUpdateHandlerSlate = {
  id: 'ordinaryUpdateSlate',
  dependencies: { ordinary: true },
  match: ({ entries, node, api, contract }) => {
    if (node.variant !== 'vari') return false;
    let itemPath = node.data.name;
    if (!itemPath) return false;
    if (itemPath.substr(0, 6) !== 'input.') itemPath = 'input.' + itemPath;
    for (const { path } of entries) {
      if (path === itemPath) return true;
      if (isMultiValueField(api, contract, path)) {
        const altPath = api.interfaces.InputPaths.removeLast(path);
        if (altPath === itemPath) return true;
      }
    }
  },
  handler: function ({ state, entries, api, contract, matches }) {
    const { language } = this;
    const adjustedValues = {};
    for (const entry of entries) {
      let { path, value } = entry;
      // Special treatment if the inputField behind the update
      // is of type `multipleText` as that will only give the
      // updated index, whereas we need the entiry array value
      if (isMultiValueField(api, contract, path)) {
        path = api.interfaces.InputPaths.removeLast(path);
        value = api.utils.general.getByPath(this.states.current, path);
      }

      // If the current entry is referring to an entity, the state looks something like:
      // { id: 'uuid', type: 'entity' }
      // and in such case typically the `id` would get updated. Check for this.
      let valueSet = false;

      if (api.interfaces.InputPaths.getLast(path) === 'id') {
        const valueEntity = api.interfaces.Entity.getFromStateById(state, value);
        if (valueEntity) {
          path = api.interfaces.InputPaths.removeLast(path);
          adjustedValues[path] = { isEntity: true, value: valueEntity };
          valueSet = true;
        }
      }
      if (!valueSet) {
        adjustedValues[path] = this.getGeneralValues(path, value, language);
      }
    }

    for (const { node, actions } of matches.default) {
      let itemPath = node.data.name;
      if (itemPath.substr(0, 6) !== 'input.') itemPath = 'input.' + itemPath;
      const nodeValues = this.getNodeValues(node, adjustedValues[itemPath]);
      const value = this.getFinalValue(nodeValues);
      actions.replaceText(value, { setValue: true });
    }
  },
};
