export const tableOnRepeatableAddRowsSlate = {
  id: 'tableOnRepeatableAddRowsSlate',
  dependencies: { repeatableAdd: true },
  time: -2,
  match: ({ entries, node }) => {
    return isRelevantTable(node);
  },
  handler: function ({ state, entries, matches }) {
    for (const entry of entries) {
      for (const { node, actions } of matches.default) {
        if (!node.data.row_repeatables.find((rowRep) => rowRep.repeatable === entry.cardId)) continue;

        this.insertTableRowForNewRepeatable({ node, entry, state, actions });
      }
    }
  },
};

function isRelevantTable(node) {
  if (node.type !== 'table') return false;
  if (!node.data) return false;
  if (!Array.isArray(node.data.row_repeatables)) return false;
  return true;
}

export const insertTableRowForNewRepeatable = function ({ node, entry, state, actions }) {
  const { cardId: repeatable, value, path } = entry;
  const templateRows = node.data.row_repeatables.filter((row) => row.repeatable === repeatable);

  // this.log('Shall insert the following rows: ', {t: JSON.parse(JSON.stringify(templateRows)), node: JSON.parse(JSON.stringify(node)) })

  if (!Array.isArray(templateRows) || templateRows.length === 0) return;

  for (const templateRow of templateRows) {
    const content = JSON.parse(templateRow.content);

    // Set custom data
    content.key = 'row_' + this.api.utils.general.uuid();
    content.data.item_id = this.api.utils.general.uuid();
    content.data._path = path;
    content.data._each_uid = value._uid;
    content.data._each_repeatable_name = repeatable;

    if (templateRow.filter) {
      content.data.acp = templateRow.filter;
      this.log('Added filter to acp!', { d: JSON.parse(JSON.stringify(content)) });
    }

    // Ensure that each cell is assigned a unique cell key (used by the slate table plugin)
    content.children.forEach((cell) => {
      cell.key = 'cell_' + this.api.utils.general.uuid();
    });

    // If the table also has column_repeatables, ensure it is included
    // in this new row as well.
    this.populateEachContent(repeatable, content, value, state, entry);

    if (node.data && node.data.column_repeatables) {
      const parentNode = node;
      for (const column_repeatable of parentNode.data.column_repeatables) {
        if (!column_repeatable.repeatable || !state.input[column_repeatable.repeatable]) continue;

        for (const [, repeatableState] of Object.entries(state.input[column_repeatable.repeatable])) {
          const column = JSON.parse(column_repeatable.content);
          column.key = 'cell_' + this.api.utils.general.uuid();
          column.data.item_id = this.api.utils.general.uuid();
          column.data.template_id = this.api.utils.general.uuid();
          column.data._each_uid = repeatableState._uid;
          column.data._each_repeatable_name = column_repeatable.repeatable;
          column.data._path = path;
          this.populateEachContent(column_repeatable.repeatable, column, repeatableState, state, entry);

          // this.log('Added column to new row ', column)
          content.children.push(column);
        }
      }
    }
    console.log('Added to table row')
    actions.insertChildren(content);
  }
};
