import React, { memo } from 'react';

import { Form, Col, Divider } from 'antd';
import { updateInputs, setConnectedInputCards } from 'appRedux/actions';
import { useSelector, useDispatch } from 'react-redux';

import { useContractLanguage, useIsTemplateStudio } from 'hooks';
import { InputPaths } from 'core/interfaces';
import { splitNumber, combineNumber, getByPath } from 'core/utils/general';

import Entity from './components/input/Entity';
import InputText from './components/input/InputText';
import InputDate from './components/input/InputDate';
import InputNumeric from './components/input/InputNumeric';
import InputNumericSteps from './components/input/InputNumericSteps';
import InputHeader from './components/input/InputHeader';
import InputSelect from './components/input/InputSelect';
import InputRadio from './components/input/InputRadio';
import InputCheck from './components/input/InputCheck';
import InputHR from './components/input/InputHR';
import InputVR from './components/input/InputVR';
import InputInfo from './components/input/InputInfo';
import InputGrid from './components/input/InputGrid';
import InputMultipleText from './components/input/InputMultipleText';
import GotoProvision from './components/input/GotoProvision';
import GotoRoute from './components/input/GotoRoute';
import QandA from './components/input/QandA';

import TemplateEdit from './components/templateEdit/';

const debounceTypes = ['text', 'numeric', 'numeric-steps'];

function shallUseDebouncer(field) {
  // if (field.suggest && field.suggest.key) return false;
  if (debounceTypes.includes(field.type)) return true;
  return false;
}

function isValidEvent(evt) {
  return evt && evt.target && (evt.target.hasOwnProperty('value') || evt.target.hasOwnProperty('checked'));
}

function getFieldValue({ state, path, origin, card, cardUid, field }) {
  if (field.function === 'connect' && field.linked) {
    /* 
    state.input.__connectedCards = [
      {
        cards: {
          facility: "201fb670-256a-47cf-acee-1f8c399fea4b",
          borrower: "b1301600288567173" 
        },
        value: true,
        key: 'facilityAvailableToBorrower'
      }
    ]
    */
    const sourceCardId = origin.card.id;
    const sourceUid = origin.cardUid;
    const targetCardId = card.id;

    const targetUid = cardUid;
    /* console.log("Conn ", {
      origin,
      card,
      sourceCardId,
      sourceUid,
      targetCardId,
      targetUid,
      key: field.name,
      concards: state.input?.__connectedCards
    }); */
    const connectedData =
      state.input?.__connectedCards &&
      state.input?.__connectedCards.find(
        (item) =>
          item.key === field.name &&
          item.cards?.[sourceCardId] === sourceUid &&
          item.cards?.[targetCardId] === targetUid
      );
    // console.log("conn Data", connectedData);
    if (connectedData) return connectedData.value;
    return false;
  }
  return getByPath(state, path);
}

export default memo((props) => {
  const { card, origin, cardUid, field, /* fieldName, */ path } = props;
  const isTemplate = useIsTemplateStudio();
  const value = useSelector((state) => getFieldValue({ state, path, origin, card, cardUid, field }));
  const referenceLabel = useSelector((state) => {
    if (field.label?.type !== 'ref' || !field.label?.target) return;
    return (
      getByPath(state, InputPaths.replaceLast(path, field.label.target)) ||
      field.label?.default ||
      '-Unknown label-'
    );
  });

  const dispatch = useDispatch();
  const language = useContractLanguage();
  const label = (field.label && field.label[language]) || referenceLabel;

  // Calculate the column size of the input field.
  const col = field.cols ? field.cols : { md: '6' };
  const colClass = colToClass(col) + ' input-field-item';

  const handleChange = (evt, instructions) => {
    if (isTemplate) return;
    let newValue;
    if (isValidEvent(evt)) newValue = evt.target.value;
    else newValue = evt;

    if (instructions?.format === 'amount') {
      if (instructions?.stateFormat) newValue = splitNumber(combineNumber(newValue, language), language);
      else newValue = combineNumber(newValue, language);
    }

    if (field.type === 'numeric' || field.type === 'numeric-steps')
      newValue = parseFloat(newValue) || newValue;

    if (field.function === 'connect' && field.linked) {
      console.log('Connect', {
        sourceCardId: origin.card.id,
        sourceUid: origin.cardUid,
        targetCardId: card.id,
        targetUid: cardUid,
        key: field.name,
        value: newValue,
      });
      dispatch(
        setConnectedInputCards({
          sourceCardId: origin.card.id,
          sourceUid: origin.cardUid,
          targetCardId: card.id,
          targetUid: cardUid,
          key: field.name,
          value: newValue,
        })
      );
      return;
    }

    const updateEntries = [{ path, value: newValue }];

    if (instructions?.nodeConnected && field.nodeConnection) {
      // Add all the company node's data to the relevant state,
      // however exclude the fieldName which was the connected
      // one (e.g. `name`) as that is the one which should have
      // been set as the main value (the received newValue)
      for (const nodeKey of Object.keys(instructions.nodeConnected)) {
        if (nodeKey === field.nodeConnection) continue; // Skip the connected field (already added above)
        updateEntries.push({
          path: InputPaths.replaceLast(path, nodeKey),
          value: instructions.nodeConnected[nodeKey],
        });
      }
    }

    dispatch(updateInputs(updateEntries, { debounce: shallUseDebouncer(field) }));
  };

  const inputFieldComponentProps = {
    ...props,
    handleChange,
    label,
    value,
    field,
    card,
    cardUid,
    language,
    path,
    isTemplate,
  };

  let component;
  // Return different component depending on type.
  switch (field.type) {
    case 'spacer':
      component = <div className={colClass}>{isTemplate && <TemplateEdit {...props} />}</div>;
      break;
    case 'party':
    case 'entity':
      component = (
        <div className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <Form layout="vertical">
            <Entity {...inputFieldComponentProps} />
          </Form>
        </div>
      );
      break;
    case 'text':
      component = (
        <div className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <Form layout="vertical">
            <InputText {...inputFieldComponentProps} />
          </Form>
        </div>
      );
      break;
    case 'grid':
      component = (
        <div className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <Form layout="vertical">
            <InputGrid {...inputFieldComponentProps} />
          </Form>
        </div>
      );
      break;
    case 'date':
      component = (
        <div className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <Form layout="vertical">
            <InputDate {...inputFieldComponentProps} />
          </Form>
        </div>
      );
      break;
    case 'numeric':
      component = (
        <Col className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <Form layout="vertical">
            <InputNumeric {...inputFieldComponentProps} />
          </Form>
        </Col>
      );
      break;

    case 'numeric-steps':
      component = (
        <Col className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <Form layout="vertical">
            <InputNumericSteps {...inputFieldComponentProps} />
          </Form>
        </Col>
      );
      break;
    case 'header':
      component = (
        <Col className="position-relative ant-col ant-col-sm-24">
          {isTemplate && <TemplateEdit {...props} />}
          <InputHeader {...inputFieldComponentProps} />
        </Col>
      );
      break;
    case 'multipleText':
      component = (
        <Col className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <Form layout="vertical">
            <InputMultipleText {...inputFieldComponentProps} />
          </Form>
        </Col>
      );
      break;
    case 'select':
      component = (
        <Col className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <Form layout="vertical">
            <InputSelect {...inputFieldComponentProps} />
          </Form>
        </Col>
      );
      break;
    case 'checkbox':
      component = (
        <Col className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <Form layout="vertical">
            <InputCheck {...inputFieldComponentProps} />
          </Form>
        </Col>
      );
      break;
    case 'radio':
      component = (
        <Col className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <Form layout="vertical">
            <InputRadio {...inputFieldComponentProps} />
          </Form>
        </Col>
      );
      break;
    case 'QA':
      component = (
        <Col className="ant-col-24 position-relative input-field-item">
          {isTemplate && <TemplateEdit {...props} />}
          <QandA {...inputFieldComponentProps} />
        </Col>
      );
      break;
    case 'hr':
      component = (
        <>
          {/* {isTemplate && <TemplateEdit {...props} />} */}
          {!isTemplate && <InputHR />}
          {isTemplate && (
            <>
              <Col className="position-relative ant-col ant-col-sm-24">
                <Divider className="m-0 mb-2">
                  <small>Divider</small>
                </Divider>
                <TemplateEdit {...props} />
              </Col>
            </>
          )}
        </>
      );
      break;
    case 'vr':
      component = (
        <>
          {/* {isTemplate && <TemplateEdit {...props} />} */}
          <InputVR />
        </>
      );
      break;
    case 'info':
      component = (
        <Col className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <InputInfo {...inputFieldComponentProps} />
        </Col>
      );
      break;
    case 'newline':
      component = (
        <Col className="position-relative ant-col ant-col-sm-24">
          {isTemplate && (
            <>
              <Divider className="m-0" dashed>
                <small>Line break</small>
              </Divider>
              <TemplateEdit {...props} />
            </>
          )}
        </Col>
      );
      break;
    case 'goto_provision':
      component = (
        <Col className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <GotoProvision {...inputFieldComponentProps} />
        </Col>
      );
      break;
    case 'goto_route':
      component = (
        <Col className={colClass}>
          {isTemplate && <TemplateEdit {...props} />}
          <GotoRoute {...inputFieldComponentProps} />
        </Col>
      );
      break;
    default:
      component = null;
      break;
  }

  if (field.newlineAfter) {
    return (
      <>
        {component}
        <Col span={24} />
      </>
    );
  }

  return component;
});

function colToClass(cols) {
  return (
    'position-relative ant-col ' +
    Object.keys(cols).reduce((acc, curr) => {
      return (acc += 'ant-col-' + curr + '-' + cols[curr] * 2 + ' ');
    }, '')
  );
}
