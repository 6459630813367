import React, { useCallback, useEffect, memo, useState } from 'react';
import { Slate } from 'slate-react';
import { useSelector } from 'react-redux';
import manager from 'core/engine/manager';
import { Contract } from 'core/interfaces';
import { useContractForWrapper, useStateUpdates } from './hooks';
import adjustInterfaces from '../editor/legal/adjustInterfaces';
import { setupWindowVariables, createUserEditor } from '../../yjs/utils';
import ConnectedEditor from '../../yjs/Editor/ConnectedEditor';

adjustInterfaces();

function Loading({ children }) {
  return (
    <div className="m-4 pt-4 d-flex justify-content-center">
      <div>{children}</div>
    </div>
  );
}

function getInitialValue(onlineMode, contract, propsContent) {
  let content;
  if (onlineMode) {
    content = [];
  } else {
    if (propsContent) content = propsContent;
    else if (contract && Contract.getContent(contract)) content = Contract.getContent(contract);
    else content = [{ text: '' }];
  }
  return content;
}

export const EditorWrapper = memo(
  ({
    children,
    onChangeOperations,
    editor: providedEditor,
    contract: providedContract,
    onlineMode = true,
    ...props
  }) => {
    const contract = useContractForWrapper(providedContract);

    const [value, setValue] = useState(getInitialValue(onlineMode, contract, props.content));

    const user = useSelector((state) => state.auth?.user);

    const [editor] = useState(() => (providedEditor ? providedEditor : createUserEditor()));

    const updateState = useStateUpdates(editor, contract);
    const onChange = useCallback(
      (changeValue) => {
        // If content is the same, abort.
        if (changeValue === value) return;

        if (editor) {
          updateState(editor.operations);
          if (typeof onChangeOperations === 'function') onChangeOperations(editor.operations);
        }

        setValue(changeValue);

        if (contract && !onlineMode) {
          Contract.setContent(contract, changeValue);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [contract, value, user, onlineMode]
    );

    useEffect(() => {
      if (!editor) return;
      setupWindowVariables(editor);
      editor.handleChange = onChange;
    }, [editor, onChange]);

    // Connect editor with the manager.
    useEffect(() => {
      if (!editor) return;
      if (props.view) return;
      editor.contract = contract;
      manager.setEditorOnChange(Contract.getId(contract), onChange);
      return () => manager.setEditorOnChange(Contract.getId(contract), null);
    }, [contract, props.view, onChange, editor]);

    // Render
    if (!editor) {
      return <Loading>Loading editor . . .</Loading>;
    }

    // console.log('editor value ', { value, editor, cId: contract.id });

    if (!onlineMode) {
      return (
        <Slate editor={editor} value={value} onChange={onChange}>
          {children}
        </Slate>
      );
    }

    return (
      <ConnectedEditor editor={editor} setValue={setValue} onlineMode={onlineMode} versionId={contract.id}>
        <Slate editor={editor} value={value} onChange={onChange}>
          {children}
        </Slate>
      </ConnectedEditor>
    );
  }
);
