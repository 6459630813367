import uuid from 'uuid-random';
import findIndex from 'find-index';
import { v4 as isUuidV4 } from '../../import/is-uuid';
import { Intl } from './Intl';
import { userToName } from '../utils/general';

const Entity = {};

Entity.getBaseState = function (state) {
  return state && state.entities;
};

Entity.getType = function (entity) {
  return entity && entity.type;
};

Entity.isEntity = function (entity) {
  return (
    entity &&
    typeof entity === 'object' &&
    entity.hasOwnProperty('type') &&
    entity.hasOwnProperty('id') &&
    entity.hasOwnProperty('identificationNumber') &&
    entity.hasOwnProperty('jurisdiction')
  );
};

Entity.isEntitiesList = function (entities) {
  if (!Array.isArray(entities)) return false;
  return entities.every(Entity.isEntity);
};

Entity.getById = function (entities, id) {
  if (!isUuidV4(id)) {
    return null;
  }
  return entities.find((x) => x.id === id);
};

Entity.getFromStateById = function (state, id) {
  return Entity.getById(Entity.getBaseState(state), id);
};

// General helpers
Entity.isKeysConnected = function (nodeKey, keyChain) {
  const firstNodeKey = Array.isArray(nodeKey) ? nodeKey[0] : nodeKey;
  const firstUpdatedKey = keyChain[0];
  const hasMasterKey = firstNodeKey === firstUpdatedKey;
  const isName =
    firstNodeKey === 'name' && (firstUpdatedKey === 'firstName' || firstUpdatedKey === 'lastName');
  // add emails, phone, and address preferably by comparing chain
  return hasMasterKey || isName;
};

// Values

Entity.getStringValue = function (entity, key, options = {}) {
  const { fallback = '', language = 'en' } = options;
  if (!Entity.isEntity(entity)) {
    console.warn('Invalid entity provided to `Entity.getValue`', { entity, key, fallback });
    return fallback;
  }
  if (!key) {
    console.warn('Invalid key provided to `Entity.getValue`', { entity, key, fallback });
    return fallback;
  }

  const isKey = (a, b) => b === a || JSON.stringify(a) === JSON.stringify([b]);

  if (isKey(key, 'name')) return Entity.name(entity, fallback);
  if (isKey(key, 'email')) return Entity.firstEmail(entity, fallback);
  if (isKey(key, 'emails')) {
    const emails = Entity.emails(entity);
    return emails ? emails.join(', ') : fallback;
  }
  if (isKey(key, 'phone')) return Entity.firstPhone(entity, fallback);
  if (isKey(key, 'address')) {
    const { street, zipCode = '', city = '' } = Entity.address(entity);
    if (!zipCode || !city) return fallback;
    return street ? `${street}, ${zipCode} ${city}` : `${zipCode} ${city}`;
  }

  // Fulhack
  if (key === 'jurisdiction') {
    const value = entity[key];
    if (!value) return fallback;
    return Intl.Country.getName(value, { fallback, language });
  }

  if (Array.isArray(key)) {
    const result = key.reduce((store, key) => store && store[key], entity);
    if (result === undefined || result === null) return fallback;
    return result;
  }

  const value = entity[key];
  if (!value) {
    if (value === '') return fallback;
    console.warn(`No key '${key}' found on entity `, { entity, key, fallback });
    return fallback;
  }
  if (typeof value !== 'string') {
    let stringified;
    try {
      stringified = JSON.stringify(value);
      if (stringified) return stringified;
    } catch (err) {
      console.log('Failed to stringify entity key value ', { entity, key, value });
      return fallback;
    }
  }
  return value;
};

Entity.name = function (entity, fallback = '') {
  if (!entity) return fallback;
  return userToName(entity) || fallback;
};
Entity.initials = function (entity, fallback = 'N.N') {
  if (!entity) return fallback;
  const { firstName, lastName, name } = entity;
  if (firstName && lastName) {
    return `${firstName.charAt(0).toLocaleUpperCase()}.${lastName.charAt(0).toLocaleUpperCase()}`;
  } else if (name) {
    return name.substr(0, 2).toLocaleUpperCase();
  }
  return fallback;
};
Entity.emails = function (entity, fallback = []) {
  if (!entity || !entity.contact) return fallback;
  const emails = entity.contact
    .filter((c) => c.type === 'EMAIL')
    .map((c) => c.address)
    .filter((c) => !!c);
  return emails || fallback;
};
Entity.firstEmail = function (entity, fallback = '') {
  if (!entity || !entity.contact) return fallback;
  const emails = entity.contact.filter((c) => c && c.type === 'EMAIL');
  const mainEmail = emails.find((e) => e.type === 'mainEmail');
  if (mainEmail && mainEmail.address) return mainEmail.address;
  if (emails[0] && emails[0].address) return emails[0].address;
  return fallback;
};
Entity.firstPhone = function (entity, fallback = '') {
  if (!entity || !entity.contact) return fallback;
  const phones = entity.contact.filter((c) => c.type === 'PHONE');
  const phone = phones[0];
  return phone ? `${phone.countryCode || ''}${phone.number || ''}` : fallback;
};
Entity.address = function (entity, fallback = {}) {
  function getFallback() {
    return fallback || Entity.create().address;
  }
  if (!entity || !entity.address) return getFallback();
  return entity.address;
};

// Finding methods.
Entity.findTopCo = function (entities) {
  return entities && entities.find((e) => e.tags && e.tags.includes('isTopCo'));
};

Entity.isRealPerson = function (entity) {
  return Entity.getType(entity) === 'RealPerson';
};

Entity.isLegalPerson = function (entity) {
  return Entity.getType(entity) === 'LegalPerson';
};

Entity.getRealPersons = function (entities) {
  if (!Array.isArray(entities)) return [];
  return entities.filter(this.isRealPerson);
};

Entity.getLegalPersons = function (entities) {
  if (!Array.isArray(entities)) return [];
  return entities.filter(this.isLegalPerson);
};

// Create new entity.
Entity.create = function (data = {}, type = 'LegalPerson') {
  let entityType;
  if (type === 'legalPerson' || type === 'LegalPerson' || data.type === 'LegalPerson') {
    entityType = 'LegalPerson';
  }
  if (type === 'realPerson' || type === 'RealPerson' || data.type === 'RealPerson') {
    entityType = 'RealPerson';
  }

  const address =
    data.address && typeof data.address === 'object'
      ? {
          street: data.address.street || '',
          city: data.address.city || '',
          zipCode: data.address.zipCode || '',
          country: data.address.country || '',
        }
      : {
          street: '',
          city: '',
          zipCode: '',
          country: '',
        };
  let contact;
  if (data.contact && Array.isArray(data.contact)) {
    contact = data.contact;
  } else {
    contact = [
      {
        type: 'ADDRESS',
        label: 'mainAddress',
        ...address,
      },
    ];
  }

  if (!address.other) {
    address.other = '';
  }

  const person = {
    id: data.id || uuid(),
    parentId: data.parentId || null,
    type: entityType,
    tags: data.tags && Array.isArray(data.tags) ? data.tags : [],
    firstName: data.firstName || data.name || '',
    lastName: data.lastName || '',
    data:
      data.data && typeof data.data === 'object'
        ? data.data
        : {
            _meta: {
              underTransfer: '',
            },
          },
    jurisdiction: data.jurisdiction || '',
    identificationNumber: data.identificationNumber || '',
    form: data.form || 'Ltd',
    address,
    contact,
    createdAt: data.createdAt || new Date().toISOString(),
    updatedAt: data.updatedAt || new Date().toISOString(),
    deletedAt: data.deletedAt || null,
  };
  return person;
};

Entity.createRelation = function (principalEntityId, agentEntityId, capacities) {
  return {
    principalEntityId,
    agentEntityId,
    capacities,
    deletedAt: null,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
};

Entity.mergeEntities = function () {
  const fullSet = [];
  for (const entities of arguments) {
    for (const entity of entities) {
      const { id } = entity;
      if (!fullSet.some((existingEntity) => existingEntity.id === id)) {
        fullSet.push(entity);
      }
    }
  }
  return fullSet;
};

Entity.filterDuplicates = function (set1, set2) {
  const set1IsEntitiesList = Array.isArray(set1);
  const set2IsEntitiesList = Array.isArray(set2);
  if (!set1IsEntitiesList || !set2IsEntitiesList) {
    if (set1IsEntitiesList) return set1;
    if (set2IsEntitiesList) return set2;
    return [];
  }

  return set1.filter((e) => !set2.find((entity) => entity.id === e.id));
};

Entity.getAgents = function (entities) {
  const agents = [];
  for (const entity of entities) {
    for (const agent of entity.Agents || []) {
      const alreadyAddedAgent = agents.find((a) => a.id === agent.id);
      if (alreadyAddedAgent) {
        alreadyAddedAgent.EntityRelations.push(agent.EntityRelation);
      } else {
        agents.push({
          ...agent,
          EntityRelations: [agent.EntityRelation],
          EntityRelation: null,
        });
      }
    }
  }
  return agents;
};

// Improve further on...
Entity.copy = function (entity, options = {}) {
  const newEntity = JSON.parse(JSON.stringify(entity));
  if (options.plain) {
    delete newEntity.Principals;
    delete newEntity.Agents;
    delete newEntity.Owners;
    delete newEntity.Owned;
  }
  return newEntity;
};

/**
 *
 * Todo: Fix so only the relevant objects gets update, react/redux way.
 */

Entity.makePrincipalAgentCouple = function (modelPrincipalEntity, modelAgentEntity, capacities) {
  if (!Entity.isEntity(modelPrincipalEntity)) {
    console.warn('Principal is no entity');
    return [];
  }
  if (!Entity.isEntity(modelAgentEntity)) {
    console.warn('Agent is no entity');
    return [];
  }
  const newPrincipalEntity = Entity.copy(modelPrincipalEntity);
  const newAgentEntity = Entity.copy(modelAgentEntity);

  const relation =
    Entity.getRelation(newPrincipalEntity, newAgentEntity) ||
    Entity.createRelation(newPrincipalEntity.id, newAgentEntity.id, capacities);

  relation.capacities = capacities;

  if (!Array.isArray(newPrincipalEntity.Agents)) newPrincipalEntity.Agents = [];
  if (!Array.isArray(newAgentEntity.Principals)) newAgentEntity.Principals = [];

  // Add or update agent on principal.
  const agentIndexOnPrincipal = findIndex(newPrincipalEntity.Agents, (a) => a.id === newAgentEntity.id);
  const plainAgentEntity = Entity.copy(modelAgentEntity, { plain: true });
  plainAgentEntity.EntityRelation = relation;
  if (agentIndexOnPrincipal === -1) {
    newPrincipalEntity.Agents.push(plainAgentEntity);
  } else {
    newPrincipalEntity.Agents[agentIndexOnPrincipal] = plainAgentEntity;
  }

  // Add or update agent on principal.
  const principalIndexOnAgent = findIndex(newAgentEntity.Principals, (a) => a.id === newPrincipalEntity.id);
  const plainPrincipalEntity = Entity.copy(modelPrincipalEntity, { plain: true });
  plainPrincipalEntity.EntityRelation = relation;
  if (principalIndexOnAgent === -1) {
    newAgentEntity.Principals.push(plainPrincipalEntity);
  } else {
    newAgentEntity.Principals[principalIndexOnAgent] = plainPrincipalEntity;
  }

  return [newPrincipalEntity, newAgentEntity, relation];
};

Entity.getEntityRelationByPrincipal = function (principalEntity, agentEntity, opts = {}) {
  if (!opts.ignoreEntityCheck) {
    if (!Entity.isEntity(principalEntity)) return null;
    if (!Entity.isEntity(agentEntity)) return null;
  }
  if (!principalEntity.Agents) return null;
  const agent = principalEntity.Agents.find((a) => a.id === agentEntity.id);
  if (!agent) return null;
  return agent.EntityRelation || null;
};

Entity.getEntityRelationByAgent = function (agentEntity, principalEntity, opts = {}) {
  if (!opts.ignoreEntityCheck) {
    if (!Entity.isEntity(principalEntity)) return null;
    if (!Entity.isEntity(agentEntity)) return null;
  }
  if (!agentEntity.Principals) return null;
  const principal = agentEntity.Principals.find((p) => p.id === principalEntity.id);
  if (!principal) return null;
  return principal.EntityRelation || null;
};

Entity.getRelation = function (principalEntity, agentEntity) {
  if (!Entity.isEntity(principalEntity)) return false;
  if (!Entity.isEntity(agentEntity)) return false;
  if (principalEntity.Agents) {
    const relation = Entity.getEntityRelationByPrincipal(principalEntity, agentEntity, {
      ignoreEntityCheck: true,
    });
    if (relation) return relation;
  }
  if (agentEntity.Principals) {
    return Entity.getEntityRelationByAgent(agentEntity, principalEntity, { ignoreEntityCheck: true });
  }
  return null;
};

Entity.collectChildren = function (entities, parentId, options = {}) {
  const { mode = 'id' } = options;
  let childrenIds = [];
  for (let entity of entities) {
    if (entity.parentId !== parentId) continue;
    childrenIds.push(mode === 'full' ? entity : entity.id);
    const subChildren = Entity.collectChildren(entities, entity.id, options);
    childrenIds = childrenIds.concat(subChildren);
  }
  return childrenIds;
};

export { Entity };
