import { useState, useEffect, useRef, useCallback } from 'react';

export function useMountedState(initValue) {
  const [state, setState] = useState(initValue);
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  }, []);

  const setter = useCallback((value) => {
    if (isMountedRef.current) setState(value);
  }, []);

  return [state, setter];
}
