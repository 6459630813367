import React from 'react';
import { Form, InputNumber } from 'antd';
import { getValue } from './utils/values';
import { isNumeric } from 'core/utils/general';

function InputNumericSteps(props) {
  const { label, handleChange, cardId, language, field, inputIndex, cardUid } = props;

  const value = getValue(props, 0);

  const handleNumericChange = (val) => {
    if (!isNumeric(val)) return;
    handleChange(val, { format: field.format });
  };

  let formatter;
  let parser;
  if (language === 'sv') {
    formatter = (value) => value.replace('.', ',');
    parser = (value) => value.replace(',', '.');
  }

  return (
    <Form.Item
      label={label}
      extra={
        field.info && field.info[language] ? <small className="muted">{field.info[language]}</small> : null
      }
    >
      <InputNumber
        autoComplete="off"
        value={value}
        step={field.steps || 1}
        id={field.name + inputIndex + cardId + cardUid}
        name={field.name}
        min={field.min}
        max={field.max}
        onChange={handleNumericChange}
        formatter={formatter}
        parser={parser}
        addonBefore={field.prefix}
        addonAfter={field.suffix}
        /* onBlur={(evt) => {
          console.log('blurred', evt);
          evt.preventDefault();
          evt.stopPropagation();
        }} */
      />
    </Form.Item>
  );
}

export default InputNumericSteps;
