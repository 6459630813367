const ignoreFields = ['list of', 'förteckning', 'lista över'];

const searchFields = {
  name: [
    'fastighet',
    'fastigheter',
    'fastigheten',
    'fastighetsbeteckning',
    'property',
    'properties',
    'the properties',
  ],
  municipal: [
    'kommun',
    'kommuner',
    'kommunen',
    'läge',
    'stad',
    'ort',
    'municipal',
    'city',
    'location',
    'the city',
    'the location',
    'the municipal',
  ],
  owner: [
    'bolag',
    'bolaget',
    'bolagen',
    'fastighetsbolag',
    'fastighetsbolaget',
    'fastighetsbolagen',
    'propco',
    'fastighetsägande bolag',
    'fastighetsägare',
    'fastighetsägande bolagen',
    'ägare',
    'ägarna',
    'koncernbolag',
    'koncernbolaget',
    'owner',
    'owners',
    'company',
    'companies',
    'the company',
    'the companies',
    'the propco',
    'propcos',
    'the propcos',
    'the owner',
  ],
  _regno: [
    'organisationsnummer',
    'organisations-nummer',
    'organisations- nummer',
    'org nr',
    'org.nr',
    'org.nr.',
    'registration number',
    'reg no',
    'reg. no.',
  ],
  existingMN: [
    'pantbrev',
    'pantbrevsbelopp',
    'pb',
    'befintliga pantbrev',
    'existerande pantbrev',
    'inteckning',
    'inteckningar',
    'befintlig inteckning',
    'existerande inteckning',
    'befintliga inteckningar',
    'existerande inteckningar',
    'mortgage',
    'mortgages',
    'existing mortgage',
    'existing mortgages',
    'mortgage amount',
    'mortgage amounts',
    'mortgages amount',
    'mortgages amounts',
    'existing mortgage amount',
    'existing mortgage amounts',
    'existing mortgages amount',
    'existing mortgages amounts',
  ],
  newMN: [
    'nya pantbrev',
    'nya pantbrevsbelopp',
    'nya pb',
    'uttag pantbrev',
    'uttag pb',
    'ytterligare pantbrev',
    'ytterligare pb',
    'ny inteckning',
    'nya inteckningar',
    'ytterligare inteckningar',
    'new mortgage',
    'new mortgages',
    'new mortgage amount',
    'new mortgage amounts',
    'new mortgages amount',
    'new mortgages amounts',
  ],
  ALA: [
    'ala',
    'allokerat lånebelopp',
    'allokerade lånebelopp',
    'initialt allokera',
    'alor',
    'alan',
    'alas',
    'allocated loan amount',
    'allocated loan amounts',
  ],
  icloan: [
    'internlån',
    'internrevers',
    'internreverser',
    'internskuld',
    'vidareutlåning',
    'IGL',
    'intra-group loan',
    'intra-group loans',
  ],
};

function getTrimmedCellName(str) {
  return str.toString().replace(/\n|\r/g, ' ').replace(/ {2,}/g, ' ').trim();
}
function getLowerCellName(str) {
  return str.toString().toLocaleLowerCase();
}

export function rowResults(rows) {
  const rowsLength = rows.length;

  console.log('Rows are ', rows);

  let firstMatchingRowIndex = -1;
  let lastMatchingRowIndex = -1;
  let firstMatchingCellIndex = -1;
  let lastMatchingCellIndex = -1;

  const cellIndicesToFields = {};

  for (let i = 0; i < rowsLength; i++) {
    const row = rows[i];

    // If we already found matching headers, check if we can break early due to data fields having run out,
    // i.e. we've hit an empty line.
    if (firstMatchingRowIndex !== -1 && row.every((c) => c === null)) {
      if (lastMatchingRowIndex === -1) {
        lastMatchingRowIndex = i - 1;
      }
      break;
    }

    const cellsLength = row.length;
    let cellHeaderMatches = 0;
    for (let j = 0; j < cellsLength; j++) {
      const cell = row[j];
      if (!cell) {
        continue;
      }
      const cellName = getTrimmedCellName(cell);
      const lowerCellName = getLowerCellName(cellName);

      for (const fieldName in searchFields) {
        const stringsToMatch = searchFields[fieldName];
        const isMatch = stringsToMatch.some((str) => lowerCellName.startsWith(str));
        if (isMatch) {
          cellIndicesToFields[j] = fieldName;
          cellHeaderMatches++;
          if (firstMatchingCellIndex === -1) {
            firstMatchingCellIndex = j;
          }
          lastMatchingCellIndex = j;
          // console.log('Match on ', lowerCellName);
        } else {
          // console.log('No match on ', lowerCellName);
        }
      }
    }
    if (cellHeaderMatches > 2) {
      if (firstMatchingRowIndex === -1) {
        // If there are no rows below, there is no data in the file.
        if (!rows[i + 1]) {
          console.log('No data in file.');
          return [];
        }
        firstMatchingRowIndex = i + 1;
      }
    }
    // console.log('cellHeaderMatches above ', cellHeaderMatches);
  }

  const minRowsLength =
    lastMatchingRowIndex === -1 ? rows.length : Math.min(rows.length, lastMatchingRowIndex);
  console.log('First and last. ', {
    rows,
    firstMatchingRowIndex,
    lastMatchingRowIndex,
    firstMatchingCellIndex,
    lastMatchingCellIndex,
    cellIndicesToFields,
    minRowsLength,
  });

  if (firstMatchingRowIndex === -1 /* || lastMatchingRowIndex === -1 */) return [];
  if (firstMatchingCellIndex === -1 || lastMatchingCellIndex === -1) return [];
  if (!Object.keys(cellIndicesToFields).length) return [];

  let results = [];

  for (let i = firstMatchingRowIndex; i <= minRowsLength; i++) {
    const row = rows[i];
    if (!row || row.every((c) => c === null)) {
      continue;
    }

    const item = {};
    for (const [index, fieldName] of Object.entries(cellIndicesToFields)) {
      item[fieldName] = row[index];
    }
    results.push(item);
  }
  const hasMunicipal = Object.values(cellIndicesToFields).some((v) => v === 'municipal');
  if (!hasMunicipal) {
    results = results.map((item) => {
      if (!item.name) return item;
      const nameParts = item.name.split(' ');
      if (nameParts.length > 2) {
        return {
          ...item,
          name: nameParts.slice(1).join(' '),
          municipal: nameParts[0],
        };
      }
      return item;
    });
  }

  // console.log('Results x are ', results);

  // Need to return an array of arrays.
  return [[JSON.stringify(results)]];
}

export function handleExcel(rows) {
  const firstSearchField = Object.keys(searchFields)[0];
  // Identify fields
  // Set to corresponding index in cells
  const fields = Object.keys(searchFields).reduce((acc, curr) => {
    acc[curr] = null;
    return acc;
  }, {});
  const data = [];
  const fieldsReverse = {};
  const fieldsFound = [];
  let dataStartRow = null;

  rowsloop: for (let i = 0; i < rows.length; i++) {
    const row = rows[i];

    for (let j = 0; j < row.length; j++) {
      const cell = row[j];
      if (fieldsFound[firstSearchField] && typeof cell !== 'string') {
        // console.log("break due to empty line");
        break rowsloop;
      }
      for (const field in searchFields) {
        if (typeof cell !== 'string') continue;
        if (fieldsFound.includes(field)) continue;
        // const cellName = cell.trim().toLocaleLowerCase();
        const cellName = getTrimmedCellName(cell);
        if (!searchFields[field].includes(cellName)) continue;
        fields[field] = j;
        fieldsReverse[j] = field;
        fieldsFound[field] = true;
        dataStartRow = i + 1;
      }
    }
  }
  // console.log("rows are ", rows);
  if (dataStartRow === null || !rows[dataStartRow]) {
    console.log('Did not find property instruction column fields');
    return [];
  }

  for (let i = dataStartRow; i < rows.length; i++) {
    // console.log("Reading data row ", i, rows[i]);
    const itemData = {};
    const row = rows[i];
    for (const index in fieldsReverse) {
      if (row[index]) itemData[fieldsReverse[index]] = row[index];
    }
    data.push(itemData);
  }

  // console.log("excel data is ", data);
  return data;
}
