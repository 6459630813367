import React from 'react';
import { Editor } from 'slate';
import { Button } from 'antd';
import { unwrapNodes, conditionalizeField } from '../../legal/helpers';

export default function ConnectInputMenu({ editor, node, path, close }) {
  const disconnectInput = () => {
    unwrapNodes(editor, path.slice(0, -1), node.variant);
  };
  const onClickMakeConditional = (evt) => {
    conditionalizeField(editor, { at: path, up: 1 });
    evt.preventDefault();
    evt.stopPropagation();
    close({ reselect: true, collapse: true });
  };

  const fieldParent = Editor.parent(editor, path);
  const hasOptParent = fieldParent && fieldParent[0].type === 'field' && fieldParent[0].variant === 'opt';

  console.log('hmm ', { fieldParent, hasOptParent });

  return (
    <>
      <Button
        type="link"
        className="m-0 border-none"
        onMouseDown={() => {
          disconnectInput();
        }}
      >
        Clear Field
      </Button>
      {!hasOptParent && (
        <Button type="link" className="m-0 border-none" onMouseDown={onClickMakeConditional}>
          Make Conditional
        </Button>
      )}
    </>
  );
}
