import React, { useState, useCallback, useMemo } from 'react';
import { Row, Col, Button, Tag, Divider, Select } from 'antd';
import { Entity } from 'core/interfaces';
import { EntitySearch, useModalContent } from 'components/ui';
import NewMainEntityButton from './NewMainEntity/NewMainEntityButton';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

export default function SelectEntities({ selectedEntities = [], setSelectedEntities, disabled }) {
  const formatMessage = useIntlMessage();
  const [ModalElement, toggleModal, setModalOpen] = useModalContent(formatMessage('app.entity.select.label'));
  const deSelect = (id) => {
    setSelectedEntities(selectedEntities.filter((s) => s.entityId !== id));
  };
  const selectEntity = useCallback(
    (data) => {
      setModalOpen(false);
      setSelectedEntities([...selectedEntities, data]);
    },
    [selectedEntities, setModalOpen, setSelectedEntities]
  );

  return (
    <div>
      <Row className="mt-2">
        <Col span={10}>
          <div>
            <span style={{ fontSize: '12px' }}>
              <IntlMessages id="app.entity.select.selectedEntities" />
              {'*'}
            </span>
          </div>
        </Col>
        <Col span={14}>
          <div className="mb-1">
            {selectedEntities &&
              selectedEntities.length > 0 &&
              selectedEntities.map((selected) => (
                <Tag
                  key={selected.entity.id}
                  closable={!disabled}
                  onClose={() => deSelect(selected.entity.id)}
                  style={{ whiteSpace: 'break-spaces' }}
                >
                  {Entity.name(selected.entity)}
                  {selected.withSubsidiaries && (
                    <small>
                      {' '}
                      (<IntlMessages id="app.entity.select.withSubsidiaries" />)
                    </small>
                  )}
                </Tag>
              ))}
            {(!selectedEntities || selectedEntities.length === 0) && (
              <div>
                <em>
                  <IntlMessages id="app.entity.select.none" />
                </em>
              </div>
            )}
          </div>
          {!disabled && (
            <div>
              <Button type="primary" className="m-0" onClick={toggleModal}>
                <IntlMessages id="app.entity.select.label" />
              </Button>
            </div>
          )}
        </Col>
      </Row>
      <ModalElement>
        <PickEntitiesModal selectedEntities={selectedEntities} selectEntity={selectEntity} />
      </ModalElement>
    </div>
  );
}

function PickEntitiesModal({ selectedEntities, selectEntity, close }) {
  const [results, setResults] = useState(null);
  const defaultSearchParams = useMemo(
    () => ({
      isTopCo: true,
      name: '',
      type: '',
    }),
    []
  );

  const entityWasCreated = useCallback(
    (data) => {
      if (!data.newEntities || data.newEntities.length === 0) {
        return console.log('Failed creating new entity.');
      }
      let relevantEntity;
      relevantEntity = Entity.findTopCo(data.newEntities);
      if (!relevantEntity) relevantEntity = data.newEntities[0];

      selectEntity({
        entity: relevantEntity,
        entityId: relevantEntity.id,
        withSubsidiaries: true,
      });
    },
    [selectEntity]
  );

  return (
    <div>
      <div className="d-flex justify-content-center">
        <NewMainEntityButton entityWasCreated={entityWasCreated} />
      </div>
      <Divider>
        <IntlMessages id="desc.or" cap />
      </Divider>
      <div className="d-flex justify-content-center">
        <h5>
          <IntlMessages id="app.entity.select.existing" />
        </h5>
      </div>
      <EntitySearch setResults={setResults} defaults={defaultSearchParams} small />
      <ListEntityPickableResults
        results={results}
        selectedEntities={selectedEntities}
        selectEntity={selectEntity}
      />
    </div>
  );
}

function ListEntityPickableResults({ selectedEntities, selectEntity, results }) {
  const [withoutSubsidiaries, setWithoutSubsidiaries] = useState([]);
  const onChangeWithoutSubsidiaries = (entityId, value) => {
    if (!value) {
      setWithoutSubsidiaries(withoutSubsidiaries.filter((ws) => ws !== entityId));
    } else {
      setWithoutSubsidiaries([...withoutSubsidiaries, entityId]);
    }
  };

  const select = (entity) => {
    selectEntity({
      entity,
      entityId: entity.id,
      withSubsidiaries: !withoutSubsidiaries.includes(entity.id),
    });
  };

  if (!results || results.length === 0) return null;
  return (
    <div className="border-top mt-3 pt-3">
      <Row className="mb-2">
        <Col span={10}>
          <h5>
            <IntlMessages id="app.general.Name" />
          </h5>
        </Col>
        <Col span={8}>
          <h5>
            <IntlMessages id="app.entity.select.withSubsidiaries" />
          </h5>
        </Col>
        <Col span={6}></Col>
      </Row>
      {results.map((entity) => (
        <div key={entity.id}>
          <Row className="mb-2">
            <Col span={10}>{Entity.name(entity)}</Col>
            <Col span={7} offset={1}>
              <Select
                onChange={(value) => onChangeWithoutSubsidiaries(entity.id, value)}
                value={withoutSubsidiaries.includes(entity.id)}
              >
                <Select.Option value={false}>
                  <IntlMessages id="desc.Yes" />
                </Select.Option>
                <Select.Option value={true}>
                  <IntlMessages id="desc.No" />
                </Select.Option>
              </Select>
            </Col>
            <Col span={6}>
              <Button type="primary" className="m-0" onClick={() => select(entity)}>
                <IntlMessages id="desc.Select" />
              </Button>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
}
