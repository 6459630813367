import React, { useCallback, useRef, useEffect } from 'react';
import { Button, Tooltip, notification, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addRepeatable, insertEntity, releaseEngineSession } from 'appRedux/actions';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Entity } from 'core/interfaces';

import readXlsxFile from 'read-excel-file';
import { rowResults } from './readExcel';

function createProperty(data, ownerId, ownerType) {
  return {
    'property/ALA': data.ALA || '',
    'property/existingMN': data.hasOwnProperty('existingMN') ? data.existingMN : '',
    'property/municipal': data.municipal || '',
    'property/name': data.name || '',
    'property/newMN': data.hasOwnProperty('newMN') ? data.newMN : '',
    propertyOwner: { id: ownerId, type: ownerType || 'entity' },
  };
}
/*
ALA: 1653760
existingMN: 1741000
icloan: 1653760
name: "KommunFelaktigt Stallet 180"
newMN: 0
owner: "Fastighetsägare AB"
_regno: "554433-1233"
*/

function createIntraGroupLoan(creditorId, debtorId, amount = '', propertyName = '') {
  return {
    'intragrouploan/amount': amount,
    'intragrouploan/propertyName': propertyName,
    intragrouploanCreditor: { id: creditorId, type: 'entity' },
    intragrouploanDebtor: { id: debtorId, type: 'entity' },
  };
}
/*
intragrouploan/amount: ""
intragrouploanCreditor: {id: '394adb98-aa2c-47e0-a501-77fdbdc11374', type: 'entity'}
intragrouploanDebtor: {id: '27f3788d-17e8-40f7-9b9f-1e43184f8930', type: 'entity'}
*/

function UploadExcel(props) {
  const dispatch = useDispatch();
  const entities = useSelector(Entity.getBaseState);
  const borrowers = useSelector((state) => state.input.borrower);
  const uploadExcelInput = useRef();
  const modalRef = useRef();
  const formatMessage = useIntlMessage();

  const addNewPropertyCall = useCallback(
    (data = {}) => {
      // console.log("Data for new property ", data);
      dispatch(addRepeatable('input.property', data, undefined, true));
    },
    [dispatch]
  );

  const addNewIntraGroupLoanCall = useCallback(
    (data = {}) => {
      // console.log("Data for new property ", data);
      dispatch(addRepeatable('input.intragrouploan', data, undefined, true));
    },
    [dispatch]
  );

  const addNewEntityCall = useCallback(
    (data) => {
      dispatch(insertEntity(data, { meta: { actionsToFollow: true } }));
    },
    [dispatch]
  );

  const uploadExcel = () => {
    uploadExcelInput.current.click();
  };

  const onUploadExcel = useCallback(() => {
    // console.log('on upload ', uploadExcelInput.current.files[0]);
    modalRef.current = Modal.info({
      title: 'Importing property data',
      content: <div>Uploading and analyzing excel</div>,
    });
    window.modalRef = modalRef.current;
    readXlsxFile(uploadExcelInput.current.files[0], {
      // transformData: (rows) => console.log('raw rows ', rows) || rows
      transformData: rowResults,
    })
      .then((results) => {
        // As this lib will not work unless the transformData function returns
        // an array of arrays, we stored the all results as stringified json
        // in the first cell of the first row (see return of `rowResults`)
        if (!Array.isArray(results)) {
          return console.log('No array');
        } else if (results.length !== 1) {
          return console.log('Expected rowResults top level to be of length 1');
        } else if (!Array.isArray(results[0])) {
          return console.log('Results[0] is not an array');
        } else if (results[0].length !== 1) {
          return console.log('Expected rowResults second level to be of length 1');
        }
        let data;
        try {
          data = JSON.parse(results[0][0]);
        } catch (err) {
          console.log('Error parsing json ', err);
          return;
        }

        // const results = rowResults(rows);

        const borrowersKeys = borrowers && Object.keys(borrowers);
        const borrowerId =
          borrowersKeys &&
          borrowersKeys.length === 1 &&
          borrowers[borrowersKeys[0]] &&
          borrowers[borrowersKeys[0]].borrowerEntity?.id;

        const newEntities = [];
        const newProperties = [];
        const newIntraGroupLoans = [];
        for (const item of data) {
          let propertyOwnerId;
          if (item.owner) {
            const ownerPerson = entities.find(
              (entity) =>
                Entity.name(entity) === item.owner ||
                (item._regno && entity.identificationNumber === item._regno)
            );
            if (ownerPerson) {
              propertyOwnerId = ownerPerson.id;
            } else {
              const ownerNewEntity = newEntities.find(
                (e) =>
                  Entity.name(e) === item.owner || (item._regno && e.identificationNumber === item._regno)
              );
              if (ownerNewEntity) {
                propertyOwnerId = ownerNewEntity.id;
              } else {
                const newEntity = Entity.create(
                  {
                    firstName: item.owner,
                    identificationNumber: item._regno || '',
                  },
                  'LegalPerson'
                );
                propertyOwnerId = newEntity.id;
                newEntities.push(newEntity);
              }
            }
          }

          const newProperty = createProperty(item, propertyOwnerId);
          newProperties.push(newProperty);

          if (borrowerId && item.icloan) {
            const newIntraGroupLoan = createIntraGroupLoan(
              borrowerId,
              propertyOwnerId,
              item.icloan,
              item.name
            );
            newIntraGroupLoans.push(newIntraGroupLoan);
          }
        }

        /* console.log('Ok end result ? ', {
          newEntities,
          newProperties,
          newIntraGroupLoans,
        }); */

        const newPropertiesNum = newProperties.length;
        const newEntitiesNum = newEntities.length;
        const newIGLnum = newIntraGroupLoans.length;
        const anyNew = newPropertiesNum || newEntitiesNum || newIGLnum;

        for (const newEntity of newEntities) {
          addNewEntityCall(newEntity);
        }
        for (const newIntraGroupLoan of newIntraGroupLoans) {
          addNewIntraGroupLoanCall(newIntraGroupLoan);
        }
        for (const newProperty of newProperties) {
          addNewPropertyCall(newProperty);
        }

        setTimeout(() => {
          modalRef.current && typeof modalRef.current.destroy === 'function' && modalRef.current.destroy();

          const newPropertiesNum = newProperties.length;
          const newEntitiesNum = newEntities.length;
          const newIGLnum = newIntraGroupLoans.length;
          if (anyNew) {
            let message = (
              <div>
                <div>{formatMessage('desc.Added')}:</div>
                <ul>
                  {newEntitiesNum ? (
                    <li key="added-entities">
                      {newEntitiesNum} {formatMessage('app.general.concepts.companies')}
                    </li>
                  ) : null}
                  {newPropertiesNum ? (
                    <li key="added-props">
                      {newPropertiesNum} {formatMessage('app.general.concepts.realProperties')}
                    </li>
                  ) : null}
                  {newIGLnum ? (
                    <li key="added-igl">
                      {newIGLnum} {formatMessage('app.general.concepts.intraGroupLoans')}
                    </li>
                  ) : null}
                </ul>
                {newEntitiesNum.length ? (
                  <div>{formatMessage('studio.inputs.companiesAddedMessage')}</div>
                ) : null}
              </div>
            );
            notification.info({
              message,
              duration: 5,
            });
          }

          if (anyNew) {
            dispatch(releaseEngineSession());
          }
        }, 10);

        return;
      })
      .catch((e) => {
        console.log('onuploaderror ', e);
      });
  }, [
    borrowers,
    entities,
    addNewEntityCall,
    addNewIntraGroupLoanCall,
    addNewPropertyCall,
    formatMessage,
    dispatch,
  ]);

  useEffect(() => {
    const inputUploadRef = uploadExcelInput.current;
    inputUploadRef.addEventListener('change', onUploadExcel);
    return () => inputUploadRef.removeEventListener('change', onUploadExcel);
  }, [uploadExcelInput, onUploadExcel]);

  return (
    <>
      <Tooltip title={<IntlMessages id="studio.inputs.uploadExcelInfo" />}>
        <Button onClick={uploadExcel}>
          <i className="mdi mdi-file-excel"></i>
        </Button>
      </Tooltip>
      <input type="file" hidden id="upload-excel-input" ref={uploadExcelInput} />
    </>
  );
}

export default UploadExcel;
