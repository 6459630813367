import deepmerge from 'deepmerge';
import {
  isBlock as isElementBlock,
  isInline as isElementInline,
  isText as isElementText,
} from '../../../types/elements';
import { uniqueItemIds } from '../../utils/content/node';
import uuid from 'uuid-random';

/**
 * makeNode: Creates a node for the SlateJS format.
 *
 * @param {object}      children           SlateJS node objects (see functions below on how to create).
 *
 *
 * @param {object}      inputData       Custom data to be included in the node data section.
 * @return {object}                     A SlateJS node.
 */
export const makeNode = function (children, inputData, nodeTemplate, options = {}) {
  // _engine.makeNode(tmpThis, item.data, item)
  if (!Array.isArray(children)) {
    this.log('makeNode: children are not an array');
    return null;
  }
  if (children.length === 1 && isElementBlock(children[0])) return children[0]; // TBD
  const node = {};
  if (isElementText(nodeTemplate)) {
    Object.assign(node, nodeTemplate);
  } else {
    node.type = nodeTemplate.type;
    node.children = children;
  }
  if (options.keepCoreData) {
    node.data = JSON.parse(JSON.stringify(nodeTemplate.data));
    if (!node.data.item_id) node.data.item_id = '_' + nodeTemplate.type + '_' + this.uuid();
  } else {
    node.data = {
      template_id: '_none_' + this.uuid(),
      item_id: '_' + nodeTemplate.type + '_' + this.uuid(),
    };
    if (typeof inputData === 'object') {
      delete inputData.template_id;
      delete inputData.item_id;
      node.data = deepmerge(node.data, inputData);
    }
    if (options.template_id_add && inputData && inputData.template_id)
      node.data.template_id = inputData.template_id + options.template_id_add;
  }
  return node;
};

/**
 * makeClause: Creates a paragraph for the SlateJS format.
 *
 * @param {object}      children           SlateJS node objects (see functions below on how to create).
 *                                          children to be 'blocks'
 *
 * @param {object}      inputData       Custom data to be included in the paragraph data section.
 * @return {object}                     A SlateJS paragraph.
 */
export const makeClause = function (children = [], inputData, options = {}) {
  const p = {
    type: 'clause',
    data: {
      template_id: 'std_clause',
    },
  };
  if (typeof inputData === 'object') p.data = deepmerge(p.data, inputData);

  if (options.template_id_add && inputData && inputData.template_id)
    p.data.template_id = inputData.template_id + options.template_id_add;

  p.data.item_id = this.uuid();
  // Insert/create children.
  if (typeof children !== 'object' || children === null) {
    throw new Error('makeClause expects children to be array or object, got: ' + JSON.stringify(children));
  } else if (Array.isArray(children)) p.children = children;
  else if (typeof children === 'object') p.children = [children];

  return p;
};

/**
 * makeParagraph: Creates a paragraph for the SlateJS format.
 *
 * @param {object}      children           SlateJS node objects (see functions below on how to create).
 *                                      children to be 'text' or 'inline'
 *
 * @param {object}      inputData       Custom data to be included in the paragraph data section.
 * @return {object}                     A SlateJS paragraph.
 */
export const makeParagraph = function (children = [], inputData, options = {}) {
  const p = {
    type: 'paragraph',
    data: {
      template_id: 'std_paragraph',
    },
  };
  if (typeof inputData === 'object') p.data = deepmerge(p.data, inputData);

  if (options.template_id_add && inputData && inputData.template_id)
    p.data.template_id = inputData.template_id + options.template_id_add;

  p.data.item_id = this.uuid();
  // Insert/create children.
  if (typeof children === 'string' || typeof children === 'number') {
    if (typeof children === 'number') children = children.toString();
    p.children = this.makeTextNodes(children);
  } else if (Array.isArray(children)) p.children = children;
  else if (typeof children === 'object') p.children = [children];

  return p;
};

/**
 * makeList: Creates a list for the SlateJS format.
 *
 * @param {object}      children           SlateJS node objects (see functions below on how to create).
 *                                      children to be 'list_item' another 'numbered_list'
 * @param {object}      inputData       Custom data to be included in the list data section.
 * @return {object}                     A SlateJS list.
 */
export const makeList = function (children, inputData, options = {}) {
  if (!Array.isArray(children)) {
    this.warn('No children to makeList');
    children = [];
  }
  const list = {
    type: 'numbered_list',
    data: {
      item_join: 'and',
      template_id: 'std_list',
      // "template_id": "_none_"+this.uuid(),
      item_id: this.uuid(),
    },
    children: children,
  };
  if (typeof inputData === 'object') list.data = deepmerge(list.data, inputData);
  if (options.template_id_add && inputData && inputData.template_id)
    list.data.template_id = inputData.template_id + options.template_id_add;
  return list;
};

/**
 * makeBulletList: Creates a list for the SlateJS format.
 *
 * @param {object}      children           SlateJS node objects (see functions below on how to create).
 *                                      children to be 'list_item' another 'numbered_list'
 * @param {object}      inputData       Custom data to be included in the list data section.
 * @return {object}                     A SlateJS list.
 */
export const makeBulletList = function (children, inputData, options = {}) {
  if (!Array.isArray(children)) {
    this.warn('No children to makeList');
    children = [];
  }
  const list = {
    type: 'bulleted_list',
    data: {
      item_join: 'and',
      template_id: 'std_list',
      item_id: this.uuid(),
    },
    children: children,
  };
  if (typeof inputData === 'object') list.data = deepmerge(list.data, inputData);
  if (options.template_id_add && inputData && inputData.template_id)
    list.data.template_id = inputData.template_id + options.template_id_add;
  return list;
};

/**
 * makeListItem: Creates a list item for the SlateJS format.
 *
 * @param {object}      children           SlateJS node objects (see functions below on how to create).
 *                                      children to be 'text' or 'inline'
 * @param {object}      inputData       Custom data to be included in the list item data section.
 * @return {object}                     A SlateJS list item.
 */
export const makeListItem = function (children, inputData, options = {}) {
  if (!Array.isArray(children)) {
    // this.log('makeListItem: children are not array');
    if (typeof children === 'object' && !!children) children = [children];
    else if (typeof children === 'string') children = [{ text: children }];
    else children = [{ text: '' }];
  }
  const listItem = {
    type: 'list_item',
    data: {
      template_id: 'std_list_item',
      item_id: this.uuid(),
    },
    children: children,
  };
  if (typeof inputData === 'object') listItem.data = deepmerge(listItem.data, inputData);
  if (options.template_id_add && inputData && inputData.template_id)
    listItem.data.template_id = inputData.template_id + options.template_id_add;
  return listItem;
};

/**
 * makeListItemOneText: Creates a list item for the SlateJS format, with one text node included.
 *
 * @param {string}      content         Text string for the first/only text node in the list item.
 * @param {object}      inputData       Custom data to be included in the list item data section.
 * @return {object}                     A SlateJS list item.
 */
export const makeListItemOneText = function (content, inputData, marks = [], options = {}) {
  return this.makeListItem([this.makeTextNode(content, marks)], inputData, options);
};

var TAG_TO_MARK = {
  b: 'bold',
  i: 'italic',
  u: 'underlined',
};
var MARK_TAGS = Object.keys(TAG_TO_MARK);

export const makeTextNodes = function (string) {
  let result = [];
  const type = typeof string;

  if (type === 'number') {
    return {
      text: string.toString(),
    };
  } else if (type !== 'string') {
    throw new Error('makeTextNodes expected a string, got ' + type);
  }

  let first,
    hit,
    next = string,
    tag;
  while (({ first, hit, next, tag } = lowestTag(next, MARK_TAGS))) {
    result.push({ text: first });
    if (next === null) {
      break;
    }

    result.push({ text: hit, [TAG_TO_MARK[tag]]: true });
  }

  if (result.length === 0) {
    return {
      text: string,
    };
  }

  return result;
};

function lowestTag(string, tags) {
  let currentFullTag, currentTagName, currentIndex;
  for (let tagName of tags) {
    const fullTag = '<' + tagName + '>';
    let index = string.indexOf(fullTag);
    if (index > -1 && (currentIndex === undefined || index < currentIndex)) {
      currentFullTag = fullTag;
      currentTagName = tagName;
      currentIndex = index;
    }
  }
  if (currentIndex === undefined || currentIndex === -1) {
    return {
      first: string,
      hit: null,
      next: null,
      tag: null,
    };
  }

  // Find the end
  const hitStart = currentIndex + currentFullTag.length;
  const fullEndTag = '</' + currentTagName + '>';
  const fullEndTagLength = fullEndTag.length;

  // "start <b>bold</b> last"
  const firstText = string.substr(0, currentIndex); // 'start '

  const endIndex = string.indexOf(fullEndTag);
  const hitText = string.substr(hitStart, endIndex - hitStart); // "bold"

  const next = string.substr(endIndex + fullEndTagLength); // " last"
  return {
    first: firstText,
    hit: hitText,
    next,
    tag: currentTagName,
  };
}

/**
 * makeTextNode: Creates a list item for the SlateJS format.
 *
 * @param {string}      content         Text string for the first/only text node.
 * @param {array}       marks           Marks for the text node, i.e. 'bold', 'italic' and/or 'underline'.
 * @return {object}                     A SlateJS text node.
 */
export const makeTextNode = function (content, marks = [], modelLeaf) {
  if (typeof content === 'number') content = content.toString();
  if (typeof content !== 'string') {
    this.log('Got content: ', { content });
    throw new Error('makeTextNode expects content to be string');
  }

  if (modelLeaf) {
    let actualLeaf = modelLeaf;
    while (
      (isElementBlock(actualLeaf) || isElementInline(actualLeaf)) &&
      Array.isArray(actualLeaf.children) &&
      actualLeaf.children.length > 0
    ) {
      actualLeaf = actualLeaf.children[0];
    }
    if (isElementText(actualLeaf)) {
      const leafNode = JSON.parse(JSON.stringify(actualLeaf));
      leafNode.text = content;
      return leafNode;
    }
  }

  const text = { text: content };

  if (typeof marks === 'string') text[marks] = true;
  else if (Array.isArray(marks)) {
    for (const mark of marks) text[mark] = true;
  } else if (typeof marks === 'object' && marks !== null) {
    return {
      ...text,
      ...marks,
    };
  }
  return text;
};

/**
 * makeInlineNode: Creates a list item for the SlateJS format, with one text node included.
 *
 * @param {string}      type            Type of inline, i.e. 'vari', 'ref', 'opt', 'each', 'item'.
 * @param {string}      text            Text string for the first/only text node in the inline item.
 * @param {object}      inputData       Custom data to be included in the inline item data section.
 * @return {object}                     A SlateJS inline item.
 */
export const makeInlineNode = function (type, variant, text, inputData, options = {}) {
  const inline = {
    type,
    variant,
    data: {
      template_id: 'std_inline',
      item_id: this.uuid(),
    },
    children: Array.isArray(text) ? text : [this.makeTextNode(text)],
  };
  if (options.variant) inline.variant = options.variant;

  if (options.debug) this.log('New inline OLD data is ', inline.data, inputData);
  if (typeof inputData === 'object') inline.data = deepmerge(inline.data, inputData);
  if (options.debug) this.log('New inline data is ', inline.data);
  if (options.template_id_add && inputData && inputData.template_id)
    inline.data.template_id = inputData.template_id + options.template_id_add;
  return inline;
};

export const makeField = function (...args) {
  return this.makeInlineNode('field', ...args);
};

/**
 * makeTable:       Creates a slateJS format table
 *
 * @param {array}   content     Content for the table. Nested array. Top level entries are rows,
 *                              containing arrays with columns.
 */
export const makeTable = function (content, data = {}) {
  if (!Array.isArray(content)) content = [['Column 1', 'Column 2']]; // Defaults to on row with two columns.
  const tablechildren = [];
  for (const row of content) {
    const rowchildren = []; // children for the row, i.e. columns.
    const inputRowChildren = Array.isArray(row && row.children) ? row.children : row;
    for (const column of inputRowChildren) {
      const columnchildren = [];
      if (isElementBlock(column)) {
        columnchildren.push(uniqueItemIds(column));
      } else if (typeof column === 'string' || isElementText(column))
        columnchildren.push(this.makeParagraph(column));
      else {
        this.log('Unknown column type', column);
        columnchildren.push(this.makeParagraph('[**]'));
      }
      rowchildren.push({
        type: 'table_cell',
        data: {
          item_id: this.uuid(),
          template_id: 'std_table_cell',
        },
        key: 'cell_' + this.uuid(),
        children: [
          {
            type: 'table_content',
            data: {
              item_id: this.uuid(),
              template_id: 'std_table_content',
            },
            children: columnchildren,
          },
        ],
      });
    }
    tablechildren.push({
      type: 'table_row',
      data: {
        item_id: this.uuid(),
        template_id: 'std_table_row',
      },
      key: 'row_' + this.uuid(),
      children: rowchildren,
    });
  }
  return {
    type: 'table',
    data: {
      item_id: this.uuid(),
      template_id: 'std_table',
      ...data,
    },
    children: tablechildren,
  };
};

export const makeTableRow = function (children) {
  const row = {
    type: 'table_row',
    data: stdData('row'),
    key: 'row_' + this.uuid(),
    children: [],
  };

  if (!Array.isArray(children)) return { ...row, children: [this.makeTableCell()] };

  if (children[0] && children[0].type) {
    return { ...row, children };
  } else {
    for (const child of children) {
      row.children.push(this.makeTableCell(child));
    }
  }
  return row;
};

export const makeTableCell = function (child) {
  let cellChild;
  if (Array.isArray(child)) cellChild = child;
  else if (typeof child === 'string') cellChild = [{ text: child }];
  else if (child && typeof child === 'object') cellChild = [child];
  else cellChild = this.makeParagraph('');
  const cell = {
    type: 'table_cell',
    data: stdData('table_cell'),
    key: 'cell_' + this.uuid(),
    children: [
      {
        type: 'table_content',
        data: stdData('table_content'),
        children: cellChild,
      },
    ],
  };
  return cell;
};

function stdData(type) {
  return {
    template_id: 'std_' + (type || 'item'),
    item_id: uuid(),
  };
}

export const isNode = function (node) {
  return (
    typeof node === 'object' &&
    !Array.isArray(node) &&
    (isElementBlock(node) || isElementInline(node) || isElementText(node))
  );
};
export const isBlock = function (node) {
  return isElementBlock(node);
};
export const isInline = function (node) {
  return isElementInline(node);
};
export const isParagraph = function (node) {
  return this.isElementBlock(node) && node.type === 'paragraph';
};
export const isList = function (node) {
  return this.isElementBlock(node) && node.type === 'numbered_list';
};
export const isListItem = function (node) {
  return this.isElementBlock(node) && node.type === 'list_item';
};
export const isTable = function (node) {
  return this.isElementBlock(node) && node.type === 'table';
};
export const isText = function (node) {
  return isElementText(node);
};
