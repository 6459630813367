export const eachesHandleItemVariantsSlate = {
  id: 'eachesHandleItemVariantsSlate',
  dependencies: { repeatableChange: true },
  match: ({ node }) => {
    return (
      node.variant === 'item' && node.data && (node.data.each_item_path || node.data.each_item_extra_paths)
    );
  },
  handler: function ({ state, entries, api, matches }) {
    const { language } = this;
    // Collect general values for each path, assigned to an
    // object (generalValues) with paths as keys.
    const generalValues = entries.reduce((acc, { path, value }) => {
      acc[path] = this.getGeneralValues(path, value, language);
      return acc;
    }, {});

    // console.log('General values are ', generalValues);

    for (const { path } of entries) {
      for (const { node, actions } of matches.default) {
        const nodeMatchesPath = node.data && node.data.each_item_path === path;
        const nodeMatchesExtraPath =
          Array.isArray(node.data.each_item_extra_paths) && node.data.each_item_extra_paths.includes(path);

        if (!nodeMatchesPath && !nodeMatchesExtraPath) continue;

        const values = generalValues[path];
        const nodeValues = this.getNodeValues(node, values);
        let value = this.getFinalValue(nodeValues);

        const nodeHasTransforms =
          Array.isArray(node.data.each && node.data.each.transforms) && node.data.each.transforms.length > 0;
        if (nodeHasTransforms) {
          const parentData = api.utils.general.getByPath(state, api.interfaces.InputPaths.parentPath(path));
          if (parentData) {
            for (const transform of node.data.each.transforms) {
              value = transformValue(value, transform, state, parentData, this);
            }
          }
        }
        if (nodeMatchesExtraPath) {
          if (!nodeHasTransforms || !node.data.each_item_path) return this.log('Matching error.');
          value = api.utils.general.getByPath(state, node.data.each_item_path);
          const parentData = api.utils.general.getByPath(state, api.interfaces.InputPaths.parentPath(path));
          if (!parentData) return;
          for (const transform of node.data.each.transforms) {
            const transformedValue = transformValue(value, transform, state, parentData, this);
            if (typeof transformedValue === 'number' && isNaN(transformedValue)) continue;
            value = transformedValue;
          }
        }
        actions.replaceText(value, { setValue: true });
      }
    }
  },
};

function transformValue(value, transform, state, parentData, engine, providedOtherValue) {
  const { key, where, op } = transform;
  let otherValue = providedOtherValue;
  if (otherValue === undefined) {
    if (where === '$_local_$') {
      otherValue = parentData[key];
    } else {
      otherValue = engine.api.utils.general.getByPath(state, key);
    }
  }
  if (!otherValue) {
    // this.log('Could not find other value for transform.')
    return value;
  }

  if (!isNaN(parseInt(value)) && !isNaN(parseInt(otherValue))) {
    value = parseInt(engine.combineNumber(value)) || 0;
    otherValue = parseInt(engine.combineNumber(otherValue)) || 0;
    /* console.log('values to ', {
      value,
      otherValue,
      op,
      key,
    }); */
    switch (op) {
      case 'plus':
        return engine.splitNumber(value + otherValue);
      case 'minus':
        return engine.splitNumber(value - otherValue);
      case 'minusReverse':
        return engine.splitNumber(otherValue - value);
      case 'divide':
        return engine.splitNumber(value / otherValue);
      case 'divideReverse':
        return engine.splitNumber(otherValue / value);
      case 'multiply':
        return engine.splitNumber(value * otherValue);
      default:
        return engine.splitNumber(value);
    }
  } else if (op === 'plus') {
    return (value + otherValue).toString();
  } else if (op === 'plusReverse') {
    return (otherValue + value).toString();
  }
  return value;
  // console.log('Transform with ', { value, transform, state, parentData });
}
