import { Form, Select } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { Entity } from 'core/interfaces';
import { Label } from '.';

const { Option } = Select;

export function ParentEntity({ entity, entityType, availableParents, ...rest }) {
  if (!Array.isArray(availableParents) || availableParents.length === 0) {
    return null;
  }
  if (entityType !== 'LegalPerson') {
    return null;
  }

  return (
    <Form.Item
      {...rest}
      label={
        <Label>
          <IntlMessages id="app.legalPersons.parentCompany" />
        </Label>
      }
      name="parentId"
    >
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        <Option value={null}>
          <i>
            <IntlMessages id="desc.None" />
          </i>
        </Option>
        {availableParents.map((parent) => (
          <Option key={parent.id} value={parent.id}>
            {Entity.name(parent)}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}
