import { createReducer } from "@reduxjs/toolkit";
import {
  DRAFT_EDITOR_ALLOW_EDITING,
  FIX_DRAFT,
  SET_DRAFT,
  SET_DRAFT_VALUES,
  RESET_DRAFT
} from "constants/ActionTypes";
import config from 'config/config';
const { CONTRACT_PREVIEW_PLACEMENT } = config?.ui?.studio || {};

const INIT_STATE = {
  editor_allowEditing: false,
  editor_showDocumentStructure: false,
  editor_showInActive: false,
  studio_draftMode: 'side-by-side',
  split_contract_placement: localStorage.getItem("split_contract_placement") || CONTRACT_PREVIEW_PLACEMENT,
  split_contract_size: Number(localStorage.getItem("split_contract_size") || "50")
};

export default createReducer(JSON.parse(JSON.stringify(INIT_STATE)), {
  [DRAFT_EDITOR_ALLOW_EDITING]: (state, action) => {
    state.editor_allowEditing = action.payload
    return state
  },
  [FIX_DRAFT]: (state, action) => {
    return action.payload || {}
    
  },
  [RESET_DRAFT]: (state) => {
    return INIT_STATE
  },
  [SET_DRAFT]: (state, action) => {
    state[action.payload.key] = action.payload.value
    return state
  },
  [SET_DRAFT_VALUES]: (state, action) => {
    const {payload} = action
    for(const [key, value] of payload) {
      state[key] = value
    }
  }
});
