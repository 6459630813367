import { Button } from 'antd';
import { useDownloadPdfBackend, useDownloadDOCX } from 'hooks';
import IntlMessages from 'util/IntlMessages';

const ExportDocument = ({ versionId }) => {
  const { initDownloadPDF, loadingPDF } = useDownloadPdfBackend({ versionId });
  const { initDownloadDOCX, loadingDOCX } = useDownloadDOCX({ versionId });
  return (
    <>
      <p>
        <IntlMessages id="app.general.Export.format" />
      </p>
      <div className="d-flex justify-content-around">
        <div className="link mt-2" onClick={initDownloadPDF}>
          <Button
            shape="circle"
            className="mr-2"
            loading={loadingPDF}
            icon={<i className={`mdi mdi-file-pdf-box`} />}
          />
          <IntlMessages id="app.general.Export.pdf" />
        </div>
        <div className="link mt-2" onClick={initDownloadDOCX}>
          <Button
            shape="circle"
            className="mr-2"
            loading={loadingDOCX}
            icon={<i className={`mdi mdi-file-word-box`} />}
          />
          <IntlMessages id="app.general.Export.docx" />
        </div>
      </div>
    </>
  );
};

export default ExportDocument;
