import React, { useCallback } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { ucfirst } from 'core/utils/general';

const InjectMassage = (props) => {
  const formater = useIntlMessage();
  const { id, className, values, ...rest } = props;
  let text = formater(props.id, { ...rest }, values);

  if (className) return <span className={className}>{text}</span>;
  return <span>{text}</span>;
};
export default injectIntl(InjectMassage, {
  withRef: false,
});

export const useIntlMessage = () => {
  const intl = useIntl();
  return useCallback(
    (id, formatting, values = {}) => {
      let text = intl.formatMessage({ id }, values);
      if (!formatting) return text;
      if (typeof formatting === 'string') {
        switch (formatting) {
          case 'cap':
          case 'capitalise':
            return ucfirst(text);
          case 'upper':
          case 'uppercase':
            return text.toLocaleUpperCase();
          case 'lower':
          case 'lowercase':
            return text.toLocaleLowerCase();
          default:
            return text;
        }
      } else if (formatting && typeof formatting === 'object') {
        if (formatting.cap) text = ucfirst(text);
        if (formatting.lower) text = text.toLocaleLowerCase();
        if (formatting.upper) text = text.toLocaleUpperCase();
        if (formatting.spaceBefore) text = ' ' + text;
        if (formatting.spaceAfter) text += ' ';
      }
      return text;
    },
    [intl]
  );
};
