import { YjsStateContext } from 'contexts/contexts';
import { SyncYJson } from '@sanalabs/y-redux';
import { setYjsSyncData, selectYjsSyncData } from 'appRedux/remoteSync/';
import { useConnectedState } from './useConnectedState';

export default function ConnectedState({ versionId, children }) {
  const { provider, yMap, connected, onlineLoaded, toggleConnection, providerAuthStatus } = useConnectedState(
    { versionId }
  );

  return (
    <>
      {yMap && connected && onlineLoaded && (
        <SyncYJson
          yJson={yMap} // YMap to be observed for remote changes by yMap.observeDeep
          setData={setYjsSyncData} // Action creator to be called as dispatch(setData(data))
          selectData={selectYjsSyncData} // Selector to be used as useSelector(selectData)
        />
      )}

      <YjsStateContext.Provider value={{ provider, connected, toggleConnection, providerAuthStatus }}>
        {children}
      </YjsStateContext.Provider>
    </>
  );
}
