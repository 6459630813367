import { Editor } from '../../../../import/slate';

function advanceFindNodes(node, opts = {}, path = []) {
  if (!node) return [];
  let matches = { all: [] };
  for (const col in opts.collect) {
    matches[col] = [];
  }
  if (!Array.isArray(node)) {
    matches.all.push([node, path]);
    if (opts.collect) {
      for (const col in opts.collect) {
        if (opts.collect[col](node)) {
          matches[col].push([node, path]);
        }
      }
    }
  }
  const iteratable = Array.isArray(node) ? node : Array.isArray(node.children) ? node.children : [];
  if (iteratable) {
    for (let i = 0; i < iteratable.length; i++) {
      const nextPath = path.length === 0 ? path.concat(i) : path.concat('children').concat(i);
      const potential = advanceFindNodes(iteratable[i], opts, nextPath);
      for (const section in potential) {
        if (!matches[section]) matches[section] = [];
        matches[section] = matches[section].concat(potential[section]);
      }
    }
  }

  return matches;
}

function find(node, predicate, options = {}) {
  const result = [];
  const { startPath = [], tuple = false, onlyActive = false, mode = 'all', parents = false } = options;
  function finder(item, path = [], tupleParents = []) {
    if (onlyActive && item.data && item.data._inActive) return;
    if (predicate(item)) {
      if (tuple) {
        if (mode === 'one') {
          if (parents) return [item, path, tupleParents];
          return [item, path];
        }
        if (parents) {
          result.push([item, path, tupleParents]);
        } else {
          result.push([item, path]);
        }
      } else {
        if (mode === 'one') return item;
        result.push(item);
      }
    }
    if (item.children) {
      for (let i = 0; i < item.children.length; i++) {
        const parentsEntries = Editor.isEditor(item) ? [] : [[item, path], ...tupleParents];
        const foundItem = finder(item.children[i], [...path, i], parentsEntries);
        if (mode === 'one' && foundItem) return foundItem;
      }
    }
  }

  if (Array.isArray(node)) {
    for (let i = 0; i < node.length; i++) {
      const foundItem = finder(node[i], [...startPath, i], [node[i], i]);
      if (mode === 'one' && foundItem) return foundItem;
    }
  } else if (typeof node === 'object') {
    const foundItem = finder(node, startPath);
    if (mode === 'one' && foundItem) return foundItem;
  }
  return result;
}

function getNode(children, path, options = {}) {
  const pathIndex = path.slice(0, 1);
  if (!pathIndex) return;

  const remainingPath = path.slice(1);
  const node = children[pathIndex];
  if (!node) return;
  if (remainingPath.length === 0) return node;

  if (!node || !node.children) return;

  return getNode(node.children, remainingPath);
}

export { find, getNode, advanceFindNodes };
