import React, { useState, useEffect, useRef } from 'react';
import {
  defaultValue,
  getAvailableOperators,
  getAvailableOperatorText,
  allowSameValue,
  typeAccepts,
  firstKey,
} from '../../core/common';
import { Select, Input, InputNumber, Checkbox } from 'antd';

export default function AnyAllInCard({ ui, language, setters, ruleId, rule, mode = 'any', currentCard }) {
  let startOperator = '==',
    startValueType = 'text',
    startValue = '';

  if (rule) {
    startOperator = firstKey(rule);

    const [tmpVars] = rule[startOperator];
    if (tmpVars[mode + '_in_card']) {
      startValue = tmpVars[mode + '_in_card'][1];
      if (typeof startValue === 'string') startValueType = 'text';
      if (typeof startValue === 'number') startValueType = 'numeric';
      if (typeof startValue === 'boolean') startValueType = 'checkbox';
    }
  }

  const initialCard = useRef(currentCard);
  const hasMounted = useRef(false);
  const [operator, setOperator] = useState(startOperator);
  const [valueType, setValueType] = useState(startValueType);
  const [value, setValue] = useState(startValue);

  useEffect(() => {
    if (initialCard.current !== currentCard) {
      setValue(null);
    }
  }, [currentCard]);

  const onOperatorChange = (value) => {
    setOperator(value);
  };
  const onValueTypeChange = (value) => {
    setValueType(value);
    // Check if new input supports current operator. Otherwise, reset to ==
    const availableOperators = getAvailableOperators({ type: value });
    if (!availableOperators.includes(operator)) {
      setOperator('==');
    }

    if (!allowSameValue({ type: valueType }, { type: value })) {
      setValue(defaultValue({ type: value }));
    }

    setValue(defaultValue({ type: value }));
  };
  const onValueChange = (evt) => {
    if (evt === undefined || evt === null) return;
    let val;
    if (valueType === 'checkbox' && evt.target) {
      val = evt.target.checked;
    } else {
      val = typeof evt === 'string' || typeof evt === 'number' ? evt : evt.target.value;
    }

    if (!typeAccepts(valueType, val)) return;

    setValue(val);
  };

  const validRule = (operator && currentCard && typeof value !== 'undefined' && value !== null) || false;

  useEffect(() => {
    if (!hasMounted.current) return;
    if (!validRule) return;

    const rule = { [operator]: [{ [mode + '_in_card']: [currentCard, value] }, true] };

    setters.current.setRule(ruleId, rule);
  }, [validRule, setters, ruleId, operator, currentCard, value, hasMounted, mode]);

  useEffect(() => {
    setters.current.setValid(ruleId, validRule);
  }, [ruleId, validRule, setters]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  return (
    <div className="d-flex align-items-center">
      <ValueType
        ui={ui}
        currentCard={currentCard}
        operator={operator}
        valueType={valueType}
        onValueTypeChange={onValueTypeChange}
        language={language}
      />
      <Operators
        ui={ui}
        currentCard={currentCard}
        operator={operator}
        valueType={valueType}
        onOperatorChange={onOperatorChange}
        language={language}
      />
      <Value
        ui={ui}
        currentCard={currentCard}
        operator={operator}
        value={value}
        valueType={valueType}
        onValueChange={onValueChange}
        language={language}
      />
    </div>
  );
}

function Operators({ ui, currentCard, operator, onOperatorChange, valueType, language }) {
  if (!currentCard) return null;

  const availableOperators = getAvailableOperators({ type: valueType });

  return (
    <div>
      <Select onChange={onOperatorChange} value={operator}>
        {availableOperators.map((availableOperator) => {
          const label = getAvailableOperatorText(availableOperator);
          return (
            <Select.Option value={availableOperator} key={availableOperator}>
              {label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

function ValueType({ ui, currentCard, operator, valueType, onValueTypeChange, language }) {
  if (!currentCard) return null;

  const options = [
    {
      type: 'text',
      label: 'Text',
    },
    {
      type: 'numeric',
      label: 'Number',
    },
    {
      type: 'checkbox',
      label: 'True / False',
    },
  ];

  return (
    <div>
      <Select onChange={onValueTypeChange} value={valueType}>
        {options.map(({ type, label }) => {
          return (
            <Select.Option value={type} key={type}>
              {label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

function Value({ ui, currentCard, operator, value, valueType, onValueChange, language }) {
  if (!currentCard) return null;
  if (valueType === 'checkbox') {
    return (
      <div>
        <Checkbox
          checked={value}
          onChange={onValueChange}
          style={{
            margin: '5px',
          }}
        />
      </div>
    );
  }

  if (valueType === 'numeric') {
    return (
      <div>
        <InputNumber value={value} onChange={onValueChange} />
      </div>
    );
  }

  return (
    <div>
      <Input type="text" value={value} onChange={onValueChange} />
    </div>
  );
}
