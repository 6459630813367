import React, { useState, useEffect } from 'react';
import { Card, Table, Button, Divider } from 'antd';
import IntlMessages from 'util/IntlMessages';
import api from 'utils/api';
import { Entity } from 'core/interfaces';
import { GotoDocumentViewSpan } from 'components/DocumentsList/';
import QuickDocumentView from 'components/project/Documents/QuickDocumentView';
import { useTasks } from 'routes/tasks/';

const { Column } = Table;

const config = {
  bordered: false,
  loading: false,
  pagination: false,
  size: 'medium',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  scroll: undefined,
};

export default function DocumentApprovals({ project, documents }) {
  // const [docsById, setDocsById] = useState(null);
  const [entitiesById, setEntitiesById] = useState(null);
  const [viewingIds, setViewingIds] = useState(null);

  const docsById = documents
    ? documents.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {})
    : {};

  const docIds = documents ? documents.map((d) => d.id) : [];

  const { tasks, filteredTasks, setTaskOutcomeStatus } = useTasks({
    filter: (t) => {
      return t.status === 'pending' && t.resourceType === 'Document' && docIds.includes(t.resourceId);
    },
  });

  const taskRequestinUserIds = tasks && JSON.stringify(tasks.map((t) => t.userId));

  useEffect(() => {
    const ids = JSON.parse(taskRequestinUserIds);

    if (ids && ids.length > 0) {
      const queryIds = encodeURI(JSON.stringify(ids));
      api
        .get('/clients/userEntities?userIds=' + queryIds + '&fields=id,firstName,lastName,contact')
        .then((res) => {
          if (!res.data) return;
          setEntitiesById(
            res.data.reduce((acc, curr) => {
              acc[curr.id] = curr;
              return acc;
            }, {})
          );
        })
        .catch((err) => {
          console.log('Users name err', err);
        });
    }
  }, [tasks, taskRequestinUserIds]);

  if (!tasks || tasks.length === 0 || !filteredTasks || filteredTasks.length === 0) {
    return null;
    // return <div>No tasks for you to approve</div>;
  }

  return (
    <Card
      title={
        <>
          <IntlMessages id="app.approval.approvals" />
        </>
      }
      className="card-tabs"
    >
      <div className="ml-2 mb-2">
        <span>
          <IntlMessages id="app.approval.taskDescription" cap /> <IntlMessages id="general.documents" />
        </span>
      </div>
      {viewingIds && (
        <QuickDocumentView
          versionId={viewingIds.versionId}
          setQuickViewDoc={() => setViewingIds(null)}
          title={'Document version for review'}
          actionFields={{
            view: false,
            mode: false,
            export: true,
          }}
          footer={
            <>
              <Button
                type="success"
                onClick={() => {
                  setTaskOutcomeStatus(viewingIds.taskId, 'approved');
                  setViewingIds(null);
                }}
              >
                <IntlMessages id="app.approval.approve" cap />
              </Button>
              <Button
                type="danger"
                onClick={() => {
                  setTaskOutcomeStatus(viewingIds.taskId, 'rejected');
                  setViewingIds(null);
                }}
              >
                <IntlMessages id="app.approval.reject" cap />
              </Button>
            </>
          }
        />
      )}
      <Table
        rowKey="id"
        className="table-responsive document-versions-view white-th transparent-td no-lines full-inbedded-table offset-card-pb"
        {...config}
        dataSource={filteredTasks}
      >
        <Column
          title={
            <>
              <IntlMessages id="app.general.Name" />
            </>
          }
          dataIndex="id"
          key="id"
          render={(text, record) => {
            if (!docsById || !docsById[record.resourceId]) return '**';
            /* console.log('Find ... ', {
                docsById,
                record,
              }); */
            return docsById[record.resourceId].name;
          }}
        />
        <Column
          title={<IntlMessages id="app.approval.requestedBy" cap />}
          dataIndex="resourceId"
          key="resourceId"
          render={(text, record) => {
            if (!entitiesById) return '**';
            return Entity.name(entitiesById[record.assignedEntityId]);
          }}
        />
        <Column
          title={<IntlMessages id="general.view" />}
          key="action"
          render={(text, record) => (
            <div>
              <GotoDocumentViewSpan
                document={{ projectId: project.id, id: record.resourceId }}
                className="link"
              >
                Go to
              </GotoDocumentViewSpan>
              <Divider type="vertical" />
              <span
                className="link"
                onClick={() => {
                  if (!docsById[record.resourceId] || !docsById[record.resourceId].finalVersionId) {
                    console.log('Document has improper properties.');
                    return;
                  }
                  setViewingIds({
                    documentId: record.resourceId,
                    versionId: docsById[record.resourceId].finalVersionId,
                    taskId: record.id,
                  });
                }}
              >
                Open
              </span>
            </div>
          )}
        />
        <Column
          title={<IntlMessages id="app.general.Actions" />}
          key="action"
          render={(text, record) => (
            <div>
              <span className="link" onClick={() => setTaskOutcomeStatus(record.id, 'approved')}>
                <IntlMessages id="app.approval.approve" cap />
              </span>
              <Divider type="vertical" />
              <span className="link" onClick={() => setTaskOutcomeStatus(record.id, 'rejected')}>
                <IntlMessages id="app.approval.reject" cap />
              </span>
            </div>
          )}
        />
        {/*  <Column
            title={<IntlMessages id="app.general.More" />}
            key="extra-actions"
            render={(text, record) => {
              return (
                <div className="d-flex align-items-center">
                  <Dropdown
                    onVisibleChange={(val) => handleDropdownChange(val ? record.id : false)}
                    visible={dropdownVisible === record.id}
                    overlay={
                      <Menu>
                        <Menu.Item key="xx">XX</Menu.Item>
                      </Menu>
                    }
                  >
                    <span className="link ant-dropdown-link">
                      <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
                    </span>
                  </Dropdown>
                  <Tooltip placement="top" title="You have been asked to approve this version">
                    <EyeOutlined className="p-1 bg-dark-grey text-white border-radius" />
                  </Tooltip>
                </div>
              );
            }}
          /> */}
      </Table>
    </Card>
  );
}
