import { Transforms } from 'slate';
import { options } from "../options"

export function removeTable(table, editor) {
  if (editor && table) {
    Transforms.removeNodes(editor, {
      match: n => n.type === options.tableType,
      at: table[1],
    });
  }
}
