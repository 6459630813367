import { List, Avatar, Tag } from 'antd';
import PresentElements from 'components/editor/legal/PresentElements';
import papers from 'assets/images/papers.png';
import { Tablify } from 'components/ui';
import IntlMessages from 'util/IntlMessages';

function ShowContent({ content }) {
  return (
    <div>
      <PresentElements content={content} />
    </div>
  );
}

export function DocumentTagItem({ item, history }) {
  const onClickItem = () => {
    history.push({
      pathname: `/project/${item.Document.projectId}/document/${item.documentId}/content`,
      state: { item_id: item.data.clause?.data?.item_id },
    });
  };
  const { provision } = item.data.clause?.data || {};
  const infoTable = [
    [
      <span>Regarding</span>,
      <span>
        {item.entityTags.map((tag, i) => (
          <Tag key={tag + i} color="blue" className="text-uppercase">
            {tag}
          </Tag>
        ))}
      </span>,
    ],
    [
      <span>Type</span>,
      <span>
        <Tag color="#0a232e">
          <IntlMessages id={`contract.provision.types.${provision.type}`} cap />
        </Tag>
      </span>,
    ],
  ];
  if (item.data?.obligated?.names) {
    infoTable.push([
      <span>Obligated</span>,
      item.data.obligated.names.length > 0 ? (
        <span>
          {item.data.obligated.names.map((name, i) => (
            <Tag key={name + i} color="purple">
              {name}
            </Tag>
          ))}
        </span>
      ) : (
        <span>
          <Tag>
            <em>General obligation</em>
          </Tag>
        </span>
      ),
    ]);
    infoTable.push([
      <span>Keywords</span>,
      <div className="keywords">
        {item.keywords.map((word, i) => (
          <Tag key={word + i} color="orange" className="mb-1">
            {word}
          </Tag>
        ))}
      </div>,
    ]);
  }
  return (
    <List.Item key={item.id} className="mt-search-result-item highlight-search-inlines" onClick={onClickItem}>
      <List.Item.Meta
        avatar={<Avatar src={papers} />}
        title={
          <div>
            <div className="d-flex justify-content-space-between mb-1">
              <div className="mt-search-result-item-title">
                <div className="header">{item.Document.name}</div>
              </div>
              <div className="mt-search-result-item-info">
                <Tag>Contract Provision</Tag>
              </div>
            </div>
            <Tablify data={infoTable} />
          </div>
        }
        description={<ShowContent content={item.data.clause} />}
      />
    </List.Item>
  );
}
