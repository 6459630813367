import { Editor, Node, Path } from 'slate';
// import { nodeIsBlock } from '../../helpers';
import { isBlock as elementsIsBlock, isActive } from 'core/types/elements';

export const isCell = (editor) => () => {
  if (!editor.selection) return false;
  const [match] = Editor.nodes(editor, {
    match: (node) => node.type === 'table_cell',
    mode: 'all',
  });
  return !!match;
  // return !!Editor.match(editor, editor.selection.anchor.path, (node) => node.type === 'table_cell');
};

export const getLowestType = (editor) => (types, path) => {
  if (!path) {
    if (!editor.selection) return [];
    path = editor.selection.anchor.path;
  }
  if (typeof types === 'string') types = [types];
  let match;
  const matches = Editor.nodes(editor, { at: path, match: (node) => types.includes(node.type) });
  for (let aMatch of matches) match = aMatch;
  return match;
};

export const getClosestBlock = (editor) => () => {
  const { selection } = editor;
  if (!selection) return {};
  const { path } = editor.selection.anchor;
  const [closestBlock] = Editor.nodes(editor, { at: path, match: elementsIsBlock, mode: 'lowest' });
  if (!closestBlock) return { node: null, path: null }
  return { node: closestBlock[0], path: closestBlock[1] };
};

export const getClosestBlockNode = (editor) => () => {
  return editor.getClosestBlock().node;
};

export const getClosestBlockPath = (editor) => () => {
  return editor.getClosestBlock().path;
};

export const isBlock = (editor) => (element) => {
  // return element && nodeIsBlock(element);
  return element && elementsIsBlock(element);
};

export const getParentNode = (editor) => (elementPath) => {
  const path = elementPath ? elementPath : (editor.selection && editor.selection.anchor.path) || null;
  if (!path) return;
  const parentNode = Node.parent(editor, path);
  return parentNode;
};

export const getSiblings = (editor) => (elementPath) => {
  return getSibling(editor, elementPath);
};

export const getPreviousSibling = (editor) => (elementPath) => {
  return getSibling(editor, elementPath, -1);
};

export const getNextSibling = (editor) => (elementPath) => {
  return getSibling(editor, elementPath, +1);
};

export const currentInline = (editor) => (path, includeInActive = false) => {
  if (!path) path = editor.selection && editor.selection.anchor.path;
  if (!path) return null;
  const match = includeInActive
    ? editor.isInline
    : (element) => editor.isInline(element) && isActive(element);
  try {
    var [[inlineNode]] = Editor.nodes(editor, { match, at: path, mode: 'lowest' });
    return inlineNode;
  } catch (err) {
    return null;
  }
};
export const currentInlineEntry = (editor) => (path, includeInActive = false) => {
  if (!path) path = editor.selection && editor.selection.anchor.path;
  if (!path) return [];
  const match = includeInActive
    ? editor.isInline
    : (element) => editor.isInline(element) && isActive(element);
  try {
    var [entry] = Editor.nodes(editor, { match, at: path, mode: 'lowest' });
    return entry || [];
  } catch (err) {
    return [];
  }
};
export const currentInlinePath = (editor) => (path, includeInActive = false) => {
  if (!path) path = editor.selection && editor.selection.anchor.path;
  if (!path) return null;
  const match = includeInActive
    ? editor.isInline
    : (element) => editor.isInline(element) && isActive(element);
  try {
    var [[, inlinePath]] = Editor.nodes(editor, {
      match,
      at: path,
      mode: 'lowest',
    });
    return inlinePath;
  } catch (err) {
    return null;
  }
};

export const getSelectedBlocks = (editor) => (extraPredicate) => {
  if (!editor.selection) return [];
  const predicate = (n) =>
    typeof extraPredicate !== 'function' ? editor.isBlock(n) : editor.isBlock(n) && extraPredicate(n);

  const parent = Path.common(editor.selection.anchor.path, editor.selection.focus.path);
  return Array.from(Editor.nodes(editor, { match: predicate })).filter(([, path]) =>
    Path.isChild(path, parent)
  );
};

export const xmethod = (editor) => (arg) => {};

const getSibling = (editor, elementPath, index) => {
  if (!Array.isArray(elementPath)) return;
  const parent = editor.getParentNode(elementPath);
  if (!parent.children) return;
  const elementIndex = elementPath[elementPath.length - 1];
  if (typeof index === 'undefined') return parent.children;
  return parent.children[elementIndex + index];
};
