import React, { useState } from 'react';
import { useMountedState } from 'hooks';
import { Entity } from '../../../core/interfaces';
import uuid from 'uuid-random';
import { useIntlMessage } from 'util/IntlMessages';
import Wrapper from './Wrapper';
import Edit from './Edit';
import { AddOrSelectComponent } from './components';
export { AddAgentModal } from './components';

export function AddOrSelectEntity(props) {
  return <AddOrSelectComponent mode="select" {...props} />;
}

function useTitle(id, entity, referringEntity, relationType) {
  const formatMessage = useIntlMessage();
  const exists = !!entity || !!id;
  const action = exists ? 'general.edit' : 'desc.Add';

  let title = formatMessage(action, 'cap');
  if (referringEntity && relationType) {
    title += ` ${formatMessage(`app.entity.${relationType}`)} (${formatMessage(
      `app.entity.as${relationType}For`
    )} ${Entity.name(referringEntity)})`;
  } else {
    title += ` ${formatMessage('general.entity')}`;
  }
  return title;
}

export default function EditEntity({
  container = 'modal',
  placement = 'left',
  onCancel,
  saveIntlId = 'desc.Save',
  ...restProps
}) {
  const { id, entity, referringEntity, relationType } = restProps;
  const [editId, setEditId] = useMountedState(id);
  const [formId] = useState(uuid());
  const title = useTitle(id, entity, referringEntity, relationType);

  const cancel = () => {
    setEditId(null);
    if (typeof onCancel === 'function') onCancel();
  };

  if (id && !editId) return null;

  return (
    <Wrapper
      container={container}
      onCancel={cancel}
      placement={placement}
      title={title}
      formId={formId}
      saveIntlId={saveIntlId}
    >
      <Edit
        id={editId}
        formId={formId}
        onCancel={cancel}
        withButtons={container === 'drawer'}
        placement={placement}
        saveIntlId={saveIntlId}
        {...restProps}
      />
    </Wrapper>
  );
}
