import React, { useCallback, useMemo, useState, useContext, useEffect } from 'react';
import isHotkey from 'is-hotkey';
import { Button as AntButton } from 'antd'
import { Editable, withReact, useSlateStatic, Slate } from 'slate-react';
import isUrl from 'is-url';
import { Editor, Transforms, createEditor, Range, Element as SlateElement, Node } from 'slate';
import { withHistory } from 'slate-history';

import { RenderElements, withElementLogic } from '../editor/legal/elements';
import { RenderLeaf } from '../editor/legal/marks';
import { withPlugins } from '../editor/legal/plugins';
import { MarkButton, BlockButton } from '../editor/legal/toolbar/TopToolbar';

import { DOCUMENT_STATUS_COLORS } from 'core/config/constants';

/* antd */
import { Modal, Button as Btn, notification, Empty, Button } from 'antd';

/* Context */
import { MessageContext } from './MessageContext';

import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

/* api */
import api from 'utils/api';

const openNotificationWithIcon = (type) => {
  notification[type]({
    message: 'Error',
    description: 'Cannot post empty message. Write something first.',
  });
};

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

/**
 *
 * @param {*} props
 * @returns main component
 */
const CommentEditor = (props) => {
  const { parentId, id, type } = props;
  const formatMessage = useIntlMessage();

  const { update, setUpdate } = useContext(MessageContext);

  const [editor] = useState(() => {
    const newEditor = withPlugins(withLinks(withElementLogic(withHistory(withReact(createEditor())))));
    newEditor.tmp = {};
    newEditor.meta = { noContract: true };
    return newEditor;
  });

  // props.currentMsg || initialValue
  const [value, setValue] = useState(props.defaultValue || initialValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElement = useCallback((props) => <RenderElements {...props} editor={editor} />, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderLeaf = useCallback((props) => <RenderLeaf {...props} editor={editor} />, []);

  /* const editor = useMemo(
    () => withPlugins(withLinks(withElementLogic(withHistory(withReact(createEditor()))))),
    []
  ); */

  const resetValue = () => {
    // Transforms.setSelection(editor, { anchor: { path: [0], offset: 0 }, focus: { path: [0], offset: 0 }});

    Transforms.forceDeselect(editor);

    setTimeout(() => {
      console.log('selection', editor.selection);
    }, 1000);

    setValue(initialValue);
  };

  const submitComment = () => {
    const data = {
      message: value,
      meta: [],
    };

    /* Check whether message contains anything... */
    const contentAsString = Node.string({ type: 'dummyBlock', children: value });
    if (contentAsString === '') {
      openNotificationWithIcon('error');
      return;
    }

    if (props.editor === 'main') {
      api
        .post('/messages', {
          resourceType: type,
          resourceId: id,
          data: data,
        })
        .then((res) => {
          setUpdate(!update);
        })
        .catch((err) => console.log('err', err));
    } else if (props.editor === 'reply') {
      api
        .post('/messages', {
          resourceType: type,
          resourceId: id,
          data: data,
        })
        .then((res) => {
          console.log(res);
          const idFromMessage = res.data.id;
          props.hide();
          setUpdate(!update);

          /* Create relation between main comment and reply */
          api
            .post('/messagerelations', {
              parentId: parentId,
              childId: idFromMessage,
              data: {},
            })
            .then(() => {})
            .catch((err) => console.log({ err }));
        })
        .catch((err) => console.log('err', err));
    } else if (props.editor === 'edit') {
      api
        .put(`/messages/${parentId}`, {
          data: data,
        })
        .then((res) => {
          props.hide();
          setUpdate(!update);
        })
        .catch((err) => console.log({ err }));
    }

    resetValue();
  };

  const placeholderText =
    (props.editor === 'main'
      ? formatMessage('app.messages.writeMessage', 'cap')
      : formatMessage('app.messages.replyThisMessage', 'cap')) + '...';

  return (
    <>
      <Slate editor={editor} value={value} onChange={(value) => setValue(value)}>
        <div className="message-editor-box">
          <div className="toolbar-wrapper">
            <AntButton.Group>
            <MarkButton format="bold" icon="mdi-format-bold" className="mb-1" />
            <MarkButton format="italic" icon="mdi-format-italic" className="mb-1" />
            <MarkButton format="underlined" icon="mdi-format-underline" className="mb-1" />
            <LinkButton type={type} id={id} />
            <BlockButton format="numbered_list" icon="mdi-format-list-numbered" className="mb-1" />
            <BlockButton format="bulleted_list" icon="mdi-format-list-bulleted" className="mb-1" />
            </AntButton.Group>
          </div>
          <div className="d-flex">
            <Editable
              style={{
                minHeight: '100px',
                marginRight: '12px',
                flex: 1,
                fontSize: '1rem',
                background: props.editor !== 'main' ? '#f4f4f4' : '#0000',
                // border: '1px solid #d9d9d9',
                padding: '1em',
              }}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholderText}
              spellCheck
              autoFocus={false}
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }}
            />
            {props.editor === 'main' && (
              <Btn
                className="mt-4 align-self-end"
                type="primary"
                onClick={() => submitComment()}
                icon={<i className="mdi mdi-send" />}
              >
                {/* <IntlMessages id="app.messages.addMessage" cap /> */}
              </Btn>
            )}
          </div>
        </div>
        {props.editor !== 'main' && (
          <div style={{ marginLeft: 12 }}>
            <Btn className="mt-4" onClick={() => props.hide()}>
              Cancel
            </Btn>
            <Btn className="mt-4" type="primary" onClick={() => submitComment()}>
              {props.editor === 'reply' && (
                <span>
                  ↵ <IntlMessages id="app.messages.addReply" cap />
                </span>
              )}
              {props.editor === 'edit' && (
                <span>
                  ↵ <IntlMessages id="app.messages.saveChanges" cap />
                </span>
              )}
            </Btn>
          </div>
        )}
      </Slate>
    </>
  );
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === 'internallink' ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertInternalLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
  return !!link;
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
  });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;

  const isCollapsed = selection && Range.isCollapsed(selection);

  const link = {
    type: 'internallink',
    url: url.url,
    meta: url.meta,
    resourceLinkType: url.resourceLinkType,
    children: isCollapsed ? [{ text: url.name }] : [],
  };

  if (isCollapsed) {
    console.log('insertnodes');
    Transforms.insertNodes(editor, link);
    Transforms.collapse(editor, { edge: 'focus' });
  } else {
    console.log('wrapnodes');
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const LinkButton = ({ type, id }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [url, setUrl] = useState(null);
  const [resources, setResources] = useState(null);
  const editor = useSlateStatic();

  const fetchResources = async () => {
    switch (type) {
      case 'Entity':
        await api
          .get(`/entities/${id}/projects`)
          .then((res) => {
            if (res.data && res.data.EntityProjects) {
              setResources(res.data.EntityProjects);
            }
          })
          .catch((err) => console.log(err));
        break;
      case 'Project':
        await api
          .get(`/projects/${id}/documents?fields=id,name,status,description`)
          .then((res) => setResources(res.data))
          .catch((err) => console.log(err));
        break;
      default:
        return;
    }
  };

  const resourceLinkType = useMemo(() => {
    switch (type) {
      case 'Entity':
        return 'Project';
      case 'Project':
        return 'Document';
      default:
        return '';
    }
  }, [type]);

  const showModal = () => {
    fetchResources();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!url) return;
    setIsModalVisible(false);
    insertInternalLink(editor, url);
  }, [url, editor]);

  /* Defining url for internallink */
  const defineUrl = (documentId) => {
    switch (resourceLinkType) {
      case 'Project':
        return `/project/${id}/overview`;
      case 'Document':
        return `/project/${id}/document/${documentId}/overview`;
      default:
        return '';
    }
  };

  const loadUrls = () => {
    //  DOCUMENT_STATUS_COLORS
    // console.log('load url resources ', resources);
    return resources.map((doc, i) => {
      let iconClass;
      let iconColor;
      if (type === 'Project') {
        iconClass = 'mdi mdi-file';
        iconColor = DOCUMENT_STATUS_COLORS[doc.status] || '#aaaaaa';
      } else {
        iconClass = 'mdi mdi-cube-outline';
        iconColor = '#aaaaaa';
      }

      return (
        <Btn
          key={i}
          shape="round"
          type="secondary"
          icon={<i className={iconClass} style={{ color: iconColor }} />}
          onClick={() => setUrl({ url: defineUrl(doc.id), name: doc.name, meta: doc, resourceLinkType })}
        >
          <span style={{ paddingLeft: '5px' }}>{doc.name}</span>
        </Btn>
      );
    });
  };

  return (
    <>
      <Btn onClick={showModal} className="mb-1">
        <i className="mdi mdi-file-hidden" />
      </Btn>
      <Modal
        title={
          <span>
            <IntlMessages id={'app.messages.link'} /> <IntlMessages id={`general.${resourceLinkType}s`} />
          </span>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Btn key="back" onClick={handleCancel}>
            Cancel
          </Btn>,
        ]}
      >
        {resources && resources.length > 0 ? (
          loadUrls()
        ) : (
          <Empty
            description={
              <span>
                <IntlMessages id={'app.messages.noInternalLinks'} />{' '}
                <IntlMessages id={`general.${resourceLinkType}s`} />
              </span>
            }
          />
        )}
      </Modal>
    </>
  );
};

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export default CommentEditor;
