import { notification } from 'antd';
import { Transforms, Editor, Path, Node } from 'slate';
import { generateItemIds } from './node';
import { accepting, filterCopyAttributes } from 'core/types/elements';
import { safePath } from 'utils/editor';
import uuid from 'uuid-random';

function findTargetModeWithin(content, editor, direction, path) {
  if (direction === 'above') return [...path, 0];
  const node = Node.get(editor, path);
  return [...path, node.children.length];
}

function findTargetModeSibling(content, editor, direction, path) {
  return direction === 'below' ? Path.next(path) : path;
}

export function findTargetPath(content, editor, options = {}) {
  const { mode = 'sibling', direction = 'below' } = options;
  const path = options.path || editor.selection?.anchor?.path;
  if (!path) return;

  switch (mode) {
    case 'sibling':
      return findTargetModeSibling(content, editor, direction, path);
    case 'within':
      return findTargetModeWithin(content, editor, direction, path);
    default:
      return;
  }
}

export function toggleConditionBlockByPath(editor, path) {
  console.log('set acp... ', path);
  const node = Node.get(editor, path);

  const data = node.data || {};

  const hasAcp = !!node.data.acp;
  if (hasAcp) {
    Transforms.setNodes(editor, { data: { ...data, acp: null } }, { at: path });
  } else {
    console.log('FIX SELECT RULE!!!');
    // Transforms.setNodes(editor, { data: { ...data, acp: null } }, { at: path });
    /* Transforms.wrapNodes(editor, {
      type: 'field',
      data: { item_id: uuid(), template_id: 'default_opt', acp: null },
      children: [],
      variant: 'opt',
    }); */
    // Transforms.setNodes(editor, { data: { ...data, acp: { and: ["FIX!"] } } }, { at: path });
  }
}

export function connectFile(editor, path, newFile) {
  const node = Node.get(editor, path);

  const data = node.data || {};

  const existingFiles = data.files || [];
  // Check if file is already connected
  if (
    existingFiles.find(
      (file) =>
        file.fileId === newFile.fileId &&
        file.resourceId === newFile.resourceId &&
        file.resourceType === newFile.resourceType
    )
  ) {
    return;
  }

  Transforms.setNodes(editor, { data: { ...data, files: [...existingFiles, newFile] } }, { at: path });
}

export function disconnectFile(editor, path, removeFile) {
  const node = Node.get(editor, path);
  const data = node.data || {};

  // Exclude disconnected file from files on node
  const files = (data.files || []).filter(
    (file) =>
      !(
        file.fileId === removeFile.fileId &&
        file.resourceId === removeFile.resourceId &&
        file.resourceType === removeFile.resourceType
      )
  );

  Transforms.setNodes(editor, { data: { ...data, files: files } }, { at: path });
}

export function setItemJoin(editor, path, value) {
  const node = Node.get(editor, path);
  const data = node.data || {};

  Transforms.setNodes(editor, { data: { ...data, item_join: value } }, { at: path });
}

export function removeBlock(editor, element) {
  if (!changeAllowed(editor)) return;
  const path = safePath(editor, element);
  if (!path) return;
  Transforms.removeNodes(editor, { at: path });
}
export function removeBlockByPath(editor, path) {
  if (!changeAllowed(editor)) return;
  Transforms.removeNodes(editor, { at: path });
}

export function duplicateBlock(editor, element) {
  if (!changeAllowed(editor)) return;
  let path = safePath(editor, element);
  if (!path) return;

  const newElement = JSON.parse(JSON.stringify(element));
  if (!newElement.data) newElement.data = {};
  filterCopyAttributes(newElement);
  generateItemIds(newElement);
  path = Path.next(path);

  Transforms.insertNodes(editor, newElement, { at: path });
}
export function duplicateBlockByPath(editor, path) {
  if (!changeAllowed(editor)) return;
  const element = Node.get(editor, path);

  const newElement = JSON.parse(JSON.stringify(element));
  if (!newElement.data) newElement.data = {};
  filterCopyAttributes(newElement);
  generateItemIds(newElement);
  path = Path.next(path);

  Transforms.insertNodes(editor, newElement, { at: path });
}

export function liftClauseByPath(editor, path) {
  if (!changeAllowed(editor)) return;

  const clauses = Array.from(Editor.nodes(editor, { match: (n) => n.type === 'clause', at: path })).reverse();
  let parentClausePath;
  for (const [, clausePath] of clauses) {
    if (Path.equals(clausePath, path)) continue;
    else {
      parentClausePath = clausePath;
    }
  }
  if (!parentClausePath) return;
  Transforms.moveNodes(editor, { at: path, to: Path.next(parentClausePath) });
}

export function liftClause(editor, element) {
  if (!changeAllowed(editor)) return;
  let path = safePath(editor, element);
  if (!path) return;
  const clauses = Array.from(Editor.nodes(editor, { match: (n) => n.type === 'clause', at: path })).reverse();
  let parentClausePath;
  for (const [clause, clausePath] of clauses) {
    if (clause === element) continue;
    else {
      parentClausePath = clausePath;
    }
  }
  if (!parentClausePath) return;
  Transforms.moveNodes(editor, { at: path, to: Path.next(parentClausePath) });
}

export function createElement(typeOrElement, template) {
  if (!typeOrElement && !template) return null;
  let newElement;
  if (!typeOrElement && template) {
    newElement = emptyBlock(template.type);
  } else if (typeof typeOrElement === 'string') {
    newElement = emptyBlock(typeOrElement);
  } else if (typeof typeOrElement === 'object') {
    newElement = typeOrElement;
  } else return;

  if (!newElement.data) {
    newElement.data = {};
    generateItemIds(newElement);
  }
  return newElement;
}
export const emptyBlock = (type = 'paragraph', children = null) => {
  if (type === 'text') return { text: '' };

  if (!children) {
    const defaultChildren = accepting[type].filter((accepted) => accepted.default);
    if (defaultChildren.length === 0) {
      children = [emptyBlock(accepting[type][0].type)];
    } else {
      children = defaultChildren.reduce((acc, curr) => {
        if (curr.number > 0) {
          for (let i = 0; i < curr.number; i++) acc.push(emptyBlock(curr.type));
        } else {
          acc.push(emptyBlock(curr.type));
        }
        return acc;
      }, []);
    }
  }

  return {
    type: type,
    data: {
      item_id: uuid(),
      template_id: 'default_' + type + '_1',
    },
    children,
  };
};

export function insertBlockByPath(editor, path, typeOrElement, options = {}) {
  if (!changeAllowed(editor)) return;

  let newElement;
  if (!typeOrElement) {
    const element = Node.get(editor, path);
    newElement = emptyBlock(element.type);
  } else if (typeof typeOrElement === 'string') {
    newElement = emptyBlock(typeOrElement);
  } else if (typeof typeOrElement === 'object') {
    newElement = typeOrElement;
  } else return;

  if (!newElement.data) {
    newElement.data = {};
    generateItemIds(newElement);
  }

  const targetPath = findTargetPath(newElement, editor, {
    ...options,
    path,
  });
  if (!targetPath) return;
  // console.log('Insert new element at ... ', {targetPath, xcad: xac(editor, newElement, path) })
  Transforms.insertNodes(editor, newElement, { at: targetPath });
}

export function insertBlock(editor, element, typeOrElement, options = {}) {
  if (!changeAllowed(editor)) return;
  let path = safePath(editor, element);
  if (!path) return;

  const newElement = createElement(typeOrElement, element);

  const targetPath = findTargetPath(newElement, editor, {
    ...options,
    path,
  });
  if (!targetPath) return;

  Transforms.insertNodes(editor, newElement, { at: targetPath });
}

export function removeRepeatable(editor, path) {
  if (!changeAllowed(editor)) return;
  const node = Node.get(editor, path);
  const data = node.data || {};
  delete data.each_repeatable;
  // data.type = data.type || 'text';
  Transforms.setNodes(editor, { data }, { at: path });

  // Unwrap all child nodes that are connected to the repeatable
  unwrapNodes(editor, path, 'item');
}

export function unwrapNodes(editor, path, variant) {
  Transforms.unwrapNodes(editor, {
    match: variant ? (n) => n.variant === variant : undefined,
    at: path,
    split: true,
  });
}

export function conditionalizeField(editor, options = {}) {
  const { at, up = 0 } = options;
  let selection = at || editor.selection;
  if (!selection) return;
  for (let i; i < up; i++) {
    console.log('up', i);
    selection.anchor = Editor.before(editor, selection);
    selection.facous = Editor.after(editor, selection);
  }
  Transforms.wrapNodes(
    editor,
    {
      type: 'field',
      data: { item_id: uuid(), template_id: 'default_opt', acp: null },
      children: [],
      variant: 'opt',
    },
    {
      at: selection,
      split: true,
    }
  );
}

function changeAllowed(editor, notify = true) {
  if (!editor) return false;
  return true;
}
