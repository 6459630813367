import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Card, Row, Col, Button, Modal } from 'antd';
import api from 'utils/api';
import NewProjectItem from './NewProjectItem';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { gutter } from 'config';

export default function NewProjectButton(props) {
  const { children, className } = props;
  const [open, setOpen] = useState(props.open || false);
  const [templates, setTemplates] = useState([]);
  const [templatesFetchedMessage, setTemplatesFetchedMessage] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(props.selectedTemplateId);

  const formatMessage = useIntlMessage();

  const close = () => {
    if (typeof props.onClose === 'function') {
      props.onClose();
    }
    setOpen(false);
  };

  const buttonClassName = className || 'm-0 ml-2';

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    (async () => {
      api
        .get(`/projecttemplates${props.selectedTemplateId ? `/${props.selectedTemplateId}` : ''}`)
        .then(function (res) {
          if (props.selectedTemplateId) {
            if (res.data) {
              setTemplates([res.data]);
              setSelectedTemplateId(props.selectedTemplateId);
              setTemplatesFetchedMessage(null);
            } else {
              setTemplatesFetchedMessage(formatMessage('app.projects.NoTemplatesFound'));
              console.log('Error in fetching template, ', props.selectedTemplateId, res);
            }
          } else {
            if (res.data && Array.isArray(res.data)) {
              if (res.data.length > 0) {
                setTemplates(res.data);
                setTemplatesFetchedMessage(null);
              } else setTemplatesFetchedMessage(formatMessage('app.projects.NoTemplatesAvailable'));
            } else {
              setTemplatesFetchedMessage(formatMessage('app.projects.NoTemplatesFound'));
              console.log('Error in fetching templates, ', res);
            }
          }
        })
        .catch((err) => {
          console.log('get err', err, err.response);
          setTemplatesFetchedMessage(formatMessage('app.projects.NoTemplatesFound'));
        });
    })();
  }, [formatMessage, props.selectedTemplateId]);

  const selectedTemplate = useMemo(
    () => templates.find((t) => t.id === selectedTemplateId) || null,
    [templates, selectedTemplateId]
  );

  const toggleOpen = useCallback(() => {
    setOpen(!open);
    if (open && typeof props.onClose === 'function') {
      props.onClose();
    }
    setSelectedTemplateId(null);
  }, [open]);

  let buttonContent = null;
  if (children) {
    buttonContent = (
      <div className="clickable" onClick={toggleOpen}>
        {children}
      </div>
    );
  } else if (!props.hideButton) {
    buttonContent = (
      <Button
        type="primary"
        icon={<i className="mdi mdi-cube-outline mr-2" />}
        className={buttonClassName}
        onClick={toggleOpen}
      >
        <IntlMessages id="app.projects.NewProject" />
      </Button>
    );
  }

  return (
    <>
      {buttonContent}
      <Modal
        title={
          <div className="d-flex">
            {!props.selectedTemplateId && selectedTemplate !== null && (
              <Button
                type="secondary"
                size="small"
                className="creation-modal-back float-left ml-2"
                onClick={() => {
                  setSelectedTemplateId(null);
                }}
              >
                <i className="mdi mdi-arrow-left mr-2" />
                <IntlMessages id="desc.Back" />
              </Button>
            )}
            <div>
              <IntlMessages id="app.projects.NewProject" />
            </div>
          </div>
        }
        visible={open}
        backdrop={'static'}
        onOk={toggleOpen}
        onCancel={toggleOpen}
        footer={[
          <Button key="back" onClick={toggleOpen}>
            <IntlMessages id="desc.Cancel" />
          </Button>,
        ]}
      >
        {open && !selectedTemplate && (
          <div>
            <div className="text-center">
              <div className="card-title mb-4">
                <h6>
                  <IntlMessages id="app.projects.ChooseTemplate" />
                </h6>
              </div>
            </div>
            <Row gutter={gutter}>
              {templates &&
                templates.length > 0 &&
                templates.map((template, index) => (
                  <Col span={8} key={template.id}>
                    <Card
                      className="mb-1 pb-1 clickable liftable"
                      onClick={() => {
                        setSelectedTemplateId(template.id);
                      }}
                    >
                      <div className="flex-column justify-content-center align-items-center">
                        <i className={'mdi mdi-cube-outline'} style={{ fontSize: 48 }} />
                        <h5 align={'center'}>{template.name}</h5>
                        <small align={'center'} className="text-center">
                          {template.description}
                        </small>
                      </div>
                    </Card>
                  </Col>
                ))}
            </Row>
          </div>
        )}
        {templatesFetchedMessage && (
          <div className="card ml-3 mr-3">
            <div className="card-body">
              <p>{templatesFetchedMessage}</p>
              <p>
                <IntlMessages id="app.projects.ContactAdmin" />.
              </p>
            </div>
          </div>
        )}
        {open && !!selectedTemplate && (
          <div>
            <NewProjectItem
              selectedEntityData={props.selectedEntityData}
              projectTemplate={selectedTemplate}
              projectWasCreated={props.projectWasCreated}
              fixedValues={props.fixedValues}
              close={close}
            />
          </div>
        )}
      </Modal>
    </>
  );
}
