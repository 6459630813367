import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dropdown, Menu, notification } from 'antd';
import { Editor, Path, Node, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import {
  removeBlockByPath,
  duplicateBlockByPath,
  insertBlockByPath,
  liftClauseByPath,
  setItemJoin,
  connectFile,
  removeRepeatable,
} from '../../../../helpers';
import { getContractValues, useIsTemplateStudio, setModalContext, setAlertContext, useContract } from 'hooks';
import { isList, blockMenuIcons } from 'core/types/elements';
import IntlMessages from 'util/IntlMessages';
import { setDraft } from 'appRedux/actions';
import MenuHeader from 'components/ui/MenuHeader';
import { ConditionActions, PrintCondition } from 'components/Rules/NodeConditions';
import EditContractBlock from './BlockMenuModals/EditContractBlock';
import api from 'utils/api';

export const BlockMenu = ({ element, editor, controller }) => {
  const { type } = element;
  const [visible, setVisible] = useState(false);

  const onVisibleChange = (val) => {
    if (val === true) {
      controller.current.forceOpen(true);
      setVisible(val);
    } else {
      controller.current.forceOpen(false);
      controller.current.close();
    }
  };

  const setIsHidingMenu = (closeBlockMenu) => {
    if (closeBlockMenu) {
      editor.clearHighlights();
      controller.current.close();
    } else {
      setVisible(false);
    }
  };

  const handleMouseEnter = useCallback(() => {
    editor.highlightNode(element);
  }, [editor, element]);
  const handleMouseLeave = useCallback(() => {
    editor.clearHighlights();
  }, [editor]);

  const propsToPass = { element, editor, setIsHidingMenu };

  const blockMenuClass = element.data && element.data.acp ? 'indi indi-acp' : '';

  return (
    <>
      <div className={`block-menu-holder new ${blockMenuClass}`}>
        <div
          className={`block-menu new ${blockMenuClass}`}
          contentEditable={false}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Dropdown
            overlay={<BlockMenuContent {...propsToPass} />}
            trigger={['hover']}
            overlayClassName="editor-clause-menu-overlay"
            onVisibleChange={onVisibleChange}
            visible={visible}
          >
            <i className={'mdi ' + blockMenuIcons[type]} />
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export function BlockMenuContent(props) {
  const { editor, element, setIsHidingMenu } = props;
  const { type } = element;
  const stringPathRef = useRef();
  const isTemplate = useIsTemplateStudio();

  if (!stringPathRef.current) {
    try {
    stringPathRef.current = ReactEditor.findPath(editor, element).join('.');
    } catch (err) {
      stringPathRef.current = null;
    }
  }
  const path = useMemo(() => {
    if (!stringPathRef.current) return null;
    return stringPathRef.current.split('.').map((item) => parseInt(item));
  }, [stringPathRef]);

  if (!path) {
    console.log('No path to elem.');
    return null;
  }

  let itemName = <IntlMessages id={'contract.content.types.' + type} /> || 'item';
  return (
    <>
      <Menu className="block-menu-content">
        <MenuHeader
          menu
          header
          title={
            <>
              <IntlMessages id={'contract.content.types.' + type} />{' '}
              <span>
                <IntlMessages id="general.xMeny" />
              </span>
            </>
          }
        />
        <Menu.ItemGroup key="actions" title={<IntlMessages id={'studio.blockMenu.groups.actions'} />}>
          <Insertions
            key="insertions"
            path={path}
            setIsHidingMenu={setIsHidingMenu}
            type={type}
            itemName={itemName}
          />
          <Duplicate key="duplicate" path={path} itemName={itemName} />
          {type === 'list_item' && <ListItemMerge key="list-merge" path={path} />}
        </Menu.ItemGroup>

        <Menu.ItemGroup key="marks" title={<IntlMessages id={'studio.blockMenu.groups.marks'} />}>
          <BlockMarks path={path} key="block_marks" />
        </Menu.ItemGroup>
        <Menu.ItemGroup key="other" title={<IntlMessages id={'studio.blockMenu.groups.other'} />}>
          {type === 'clause' && <LiftClause key="lift-clause" path={path} itemName={itemName} />}
          <EditBlock path={path} itemName={itemName} setIsHidingMenu={setIsHidingMenu} key="edit-block" />
          <RemoveBlock path={path} itemName={itemName} key="remove-block" />
          <ItemJoin path={path} itemName={itemName} key="item-join" />
          <NewFile path={path} itemName={itemName} setIsHidingMenu={setIsHidingMenu} key="new-file" />
          <BlockACP path={path} itemName={itemName} key="block-acp" />
        </Menu.ItemGroup>

        {isTemplate && (
          <Menu.ItemGroup key="template" title={<IntlMessages id={'studio.blockMenu.groups.template'} />}>
            <ConnectRepeatable
              path={path}
              type={type}
              itemName={itemName}
              setIsHidingMenu={setIsHidingMenu}
              key="connect-repeatable"
            />
          </Menu.ItemGroup>
        )}
      </Menu>
    </>
  );
}

const itemProduces = {
  section: [
    { type: 'section', above: true, below: true },
    { type: 'clause', top: true, bottom: true },
    { type: 'paragraph', top: true, bottom: true },
    { type: 'numbered_list', top: true, bottom: true },
    { type: 'bulleted_list', top: true, bottom: true },
  ],
  paragraph: [{ type: 'paragraph', above: true, below: true }],
  clause: [
    { type: 'clause', above: true, below: true, top: true, bottom: true },
    { type: 'paragraph', above: true, below: true, top: true, bottom: true },
    { type: 'numbered_list', above: true, below: true, top: true, bottom: true },
    { type: 'bulleted_list', above: true, below: true, top: true, bottom: true },
  ],
  heading_one: [
    { type: 'heading_two', above: false, below: true },
    { type: 'paragraph', above: true, below: true },
    { type: 'numbered_list', above: false, below: true },
    { type: 'bulleted_list', above: false, below: true },
  ],
  heading_two: [
    { type: 'heading_three', above: false, below: true },
    { type: 'paragraph', above: true, below: true },
    { type: 'numbered_list', above: false, below: true },
    { type: 'bulleted_list', above: false, below: true },
  ],
  heading_three: [
    { type: 'paragraph', above: true, below: true },
    { type: 'numbered_list', above: false, below: true },
    { type: 'bulleted_list', above: false, below: true },
  ],
  list_item: [
    { type: 'list_item', above: true, below: true },
    { type: 'paragraph', above: true, below: true },
  ],
  img: [
    { type: 'paragraph', above: true, below: true },
    { type: 'numbered_list', above: false, below: true },
    { type: 'bulleted_list', above: false, below: true },
  ],
  table: [{ type: 'paragraph', above: true, below: true }],
  numbered_list: [
    { type: 'list_item', top: true, bottom: true },
    { type: 'paragraph', above: true, below: true },
  ],
  bulleted_list: [
    { type: 'list_item', top: true, bottom: true },
    { type: 'paragraph', above: true, below: true },
  ],
};

function LiftClause(props) {
  const editor = useSlateStatic();
  const { itemName, path, ...rest } = props;
  const parentElement = Node.get(editor, Path.parent(path));
  if (parentElement.type === 'section') return null;
  return (
    <Menu.Item
      key="liftclause"
      {...rest}
      onClick={() => {
        liftClauseByPath(editor, path);
      }}
    >
      <i className="mdi mdi-arrow-up-bold mr-2" /> Move up one Level
    </Menu.Item>
  );
}

function ItemJoin(props) {
  const editor = useSlateStatic();
  const { itemName, path, ...rest } = props;
  const node = Node.get(editor, path);

  if (!isList(node)) return null;

  const _setItemJoin = (value) => setItemJoin(editor, path, value);

  if (!node.data) {
    console.log('List Node has no data...');
    return null;
  }

  const currentValue = node.data?.item_join || false;

  return (
    <Menu.SubMenu
      title={
        <span>
          <i className="mdi mdi-plus mr-2" />
          Item Join
        </span>
      }
      key={'item join'}
      {...rest}
    >
      <Menu.Item key="ij-none" onMouseDown={() => _setItemJoin(false)}>
        {currentValue === false && <i className="mdi mdi-check mr-2" />} None
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="ij-and" onMouseDown={() => _setItemJoin('and')}>
        {currentValue === 'and' && <i className="mdi mdi-check mr-2" />} and
      </Menu.Item>
      <Menu.Item key="ij-or" onMouseDown={() => _setItemJoin('or')}>
        {currentValue === 'or' && <i className="mdi mdi-check mr-2" />} or
      </Menu.Item>
    </Menu.SubMenu>
  );
}

function BlockACP(props) {
  const editor = useSlateStatic();
  const { itemName, path, ...rest } = props;
  const node = Node.get(editor, path);
  const stringPath = JSON.stringify(path);
  const hasAcp = !!node.data?.acp;
  // const actionDescription = hasAcp ? 'unconditioned' : 'conditioned';

  const yesNo = hasAcp ? 'yes' : 'no';
  /* const rerender = useRerender()
  const onChangeRule = (id) => {
    console.log('change')
    rerender()
  }; */

  const removeCondition = () => {
    Transforms.setNodes(
      editor,
      {
        data: {
          ...node.data,
          acp: null,
        },
      },
      { at: path }
    );
  };

  return (
    <Menu.SubMenu
      title={
        <span>
          <i className={'mdi mdi-crosshairs' + (hasAcp ? '-gps' : '') + ' mr-2'} />
          Conditioned ({yesNo})
        </span>
      }
      {...rest}
    >
      <Menu.Item key="print-condition">
        <PrintCondition node={node} />
      </Menu.Item>
      <Menu.Item key="condition-actions">
        <ConditionActions node={node} stringPath={stringPath} linksInline />
      </Menu.Item>
      {hasAcp && (
        <Menu.Item key="remove-condition">
          <span onClick={removeCondition}>Remove Condition</span>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  );
}

function EditBlock({ itemName, path, setIsHidingMenu, ...rest }) {
  const editor = useSlateStatic();
  const contract = getContractValues();
  const node = Node.get(editor, path);

  const tmpData = useRef(JSON.parse(JSON.stringify(node.data || {})));

  const onOk = () => {
    Transforms.setNodes(
      editor,
      {
        data: tmpData.current.data,
        type: tmpData.current.type,
        variant: tmpData.current.variant,
      },
      {
        at: path,
      }
    );
  };

  return (
    <Menu.Item
      onMouseDown={() => {
        // setIsHidingMenu(true);
        setModalContext({
          type: 'editContractBlock',
          component: EditContractBlock,
          className: 'edit-contract-block',
          node,
          path,
          contract,
          onOk,
          tmpData,
          size: 'lg',
          onClose: () => setIsHidingMenu(false),
        });
        setIsHidingMenu(true);
      }}
      {...rest}
    >
      <span>
        <i className="mdi mdi-book-open-page-variant mr-2" />
        <IntlMessages id="general.edit" cap /> {itemName}
      </span>
    </Menu.Item>
  );
}

function NewFile({ itemName, path, setIsHidingMenu, ...rest }) {
  const editor = useSlateStatic();
  const contract = getContractValues();
  const node = Node.get(editor, path);

  const onConnectFiles = async (files) => {
    files.forEach((file) => {
      connectFile(editor, path, file);
    });
  };

  return (
    <Menu.Item
      onMouseDown={() => {
        setIsHidingMenu(true);
        setModalContext({
          type: 'file-upload',
          projectId: contract.projectId,
          documentId: contract.documentId,
          nodeType: node.type,
          connectedFiles: node.data.files || [],
          onConnectFiles,
          onClose: () => setIsHidingMenu(false),
        });
      }}
      {...rest}
    >
      <span>
        <i className="mdi mdi-file-multiple mr-2" />
        <IntlMessages id="app.file.connectFile" />
      </span>
    </Menu.Item>
  );
}

function ConnectRepeatable(props) {
  const dispatch = useDispatch();
  const editor = useSlateStatic();

  const { itemName, path, setIsHidingMenu, type, ...rest } = props;
  const node = Node.get(editor, path);

  if (!['clause', 'paragraph', 'numbered_list', 'bulleted_list'].includes(type)) return null;

  if (!node.data.each_repeatable) {
    return (
      <Menu.Item
        key="connectRepeatable"
        onMouseDown={() => {
          setIsHidingMenu(true);
          setAlertContext({
            type: 'info',
            message: <IntlMessages id="studio.blockMenu.repeatable.connect.message" />,
            description: <IntlMessages id="studio.blockMenu.repeatable.connect.description" />,
            onClose: () => {
              setIsHidingMenu(false);
              dispatch(setDraft('template_connect_repeatable', null));
            },
          });
          dispatch(setDraft('template_connect_repeatable', { path }));
        }}
        {...rest}
      >
        <span>
          <i className="mdi mdi-file-multiple mr-2" />
          <IntlMessages id="studio.blockMenu.repeatable.connect.button" />
        </span>
      </Menu.Item>
    );
  } else {
    return (
      <Menu.Item
        key="disconnectRepeatable"
        onMouseDown={() => {
          removeRepeatable(editor, path);
          Transforms.forceDeselect(editor);
        }}
        {...rest}
      >
        <span>
          <i className="mdi mdi-file-multiple mr-2" />
          <IntlMessages id="studio.blockMenu.repeatable.disconnect.button" />
        </span>
      </Menu.Item>
    );
  }
}

function RemoveBlock({ itemName, path, ...rest }) {
  const editor = useSlateStatic();
  return (
    <Menu.Item
      // key="removeBlock"
      onMouseDown={() => {
        removeBlockByPath(editor, path);
      }}
      {...rest}
    >
      <span>
        <i className="mdi mdi-close-box-outline mr-2" />
        <IntlMessages id="desc.Remove" /> {itemName}
      </span>
    </Menu.Item>
  );
}

function Insertions(props) {
  const { itemName, path, type, setIsHidingMenu, ...rest } = props;
  const inserts = itemProduces[type];
  if (!inserts) return null;
  const types = Object.keys(inserts);
  if (!types.length) return null;

  const capItemName = itemName;

  return (
    <Menu.SubMenu
      title={
        <span>
          <i className="mdi mdi-plus-circle-outline mr-2" />
          <IntlMessages id="desc.Insert" />
        </span>
      }
      {...rest}
      key={'inserts'}
    >
      {inserts.map((insert) => (
        <InsertChoice
          key={insert.type}
          path={path}
          capItemName={capItemName}
          type={insert.type}
          data={insert}
          setIsHidingMenu={setIsHidingMenu}
        />
      ))}
    </Menu.SubMenu>
  );
}

function InsertChoice({ path, type, data, capItemName, setIsHidingMenu, ...rest }) {
  const editor = useSlateStatic();
  const { above, below, top, bottom } = data;
  const showDivider = (above || below) && (top || bottom);

  return (
    <Menu.SubMenu
      title={<IntlMessages id={'contract.content.types.' + data.type} />}
      {...rest}
      key={'insert_' + type}
    >
      {top && (
        <Menu.Item
          key={type + '_within_top'}
          onClick={() => {
            insertBlockByPath(editor, path, type, {
              mode: 'within',
              direction: 'above',
            });
            setIsHidingMenu(true);
          }}
        >
          <span>
            <i className="mdi mdi-arrow-top-right mr-2" />
            <IntlMessages id="studio.blockMenu.Within" /> {capItemName} (
            <IntlMessages id="studio.blockMenu.atTop" />)
          </span>
        </Menu.Item>
      )}
      {bottom && (
        <Menu.Item
          key={type + '_within_bottom'}
          onClick={() => {
            insertBlockByPath(editor, path, type, {
              mode: 'within',
              direction: 'below',
            });
            setIsHidingMenu(true);
          }}
        >
          <span>
            <i className="mdi mdi-arrow-bottom-right mr-2" />
            <IntlMessages id="studio.blockMenu.Within" /> {capItemName} (
            <IntlMessages id="studio.blockMenu.atBottom" />)
          </span>
        </Menu.Item>
      )}
      {showDivider && <Menu.Divider />}
      {above && (
        <Menu.Item
          key={type + '_sibling_top'}
          onClick={() => {
            insertBlockByPath(editor, path, type, {
              mode: 'sibling',
              direction: 'above',
            });
            setIsHidingMenu(true);
          }}
        >
          <span>
            <i className="mdi mdi-arrow-up mr-2" />
            <IntlMessages id="studio.blockMenu.BeforeThis" /> {capItemName}
          </span>
        </Menu.Item>
      )}
      {below && (
        <Menu.Item
          key={type + '_sibling_bottom'}
          onClick={() => {
            insertBlockByPath(editor, path, type, {
              mode: 'sibling',
              direction: 'below',
            });
            setIsHidingMenu(true);
          }}
        >
          <span>
            <i className="mdi mdi-arrow-down mr-2" />
            <IntlMessages id="studio.blockMenu.AfterThis" /> {capItemName}
          </span>
        </Menu.Item>
      )}
    </Menu.SubMenu>
  );
}

function Duplicate({ path, itemName, ...rest }) {
  const editor = useSlateStatic();
  return (
    <Menu.Item {...rest} key="duplicate" onMouseDown={() => duplicateBlockByPath(editor, path)}>
      <span>
        <i className="mdi mdi-content-duplicate mr-2" />
        <IntlMessages id="studio.blockMenu.Duplicate" /> {itemName}
      </span>
    </Menu.Item>
  );
}

function ListItemMerge({ path, ...rest }) {
  const editor = useSlateStatic();

  if (path.slice(-1)[0] !== 0) {
    return null;
  }

  const parentPath = Path.parent(path);
  const parentNode = Node.get(editor, parentPath);

  // if(parentNode.children.length > 1) { return null }

  const previousActiveEntry = Editor.previous(editor, {
    at: parentPath,
    match: (n) => {
      const [parent] = Editor.parent(editor, parentPath);
      return parent.children.includes(n) && !n.data?._inActive;
    },
    mode: 'highest',
  });

  if (!previousActiveEntry) return null;
  const [previousActiveNode, previousActivePath] = previousActiveEntry;

  if (!isList(previousActiveNode)) return null;

  return (
    <>
      <Menu.Divider {...rest} />
      <Menu.Item
        {...rest}
        key="moveIntoAboveList"
        onClick={() => {
          Editor.withoutNormalizing(editor, () => {
            const parentLength = parentNode.children.length;
            const to = [...previousActivePath, previousActiveNode.children.length];
            // Move into above list
            Transforms.moveNodes(editor, {
              at: path,
              to,
            });
            Transforms.setSelection(editor, {
              anchor: Editor.start(editor, to),
              focus: Editor.end(editor, to),
            });

            if (parentLength === 1) {
              console.log('Delete curren list');
              Transforms.delete(editor, { at: parentPath });
            }
          });
        }}
      >
        <span>
          <i className="mdi mdi-call-missed mr-2 flipped" />
          <IntlMessages id="studio.blockMenu.MoveIntoListAbove" />
        </span>
      </Menu.Item>
    </>
  );
}

function BlockMarks({ path, ...rest }) {
  const editor = useSlateStatic();
  const contract = useContract();
  const element = Node.get(editor, path);

  const [marks, setMarks] = useState((element.data && element.data.marks) || {});

  const toggleMark = (key) => {
    const currentlyHasKey = marks[key];

    if (key === 'update-template') {
      if (currentlyHasKey) {
        api
          .delete('/clauselibs/' + currentlyHasKey)
          .then((res) => {
            console.log('Removed from clause lib ', res);
          })
          .catch((err) => {
            console.log('Error remove from clause lib ', err);
          })
          .finally(() => {
            const newMarks = {
              ...marks,
              [key]: null,
            };
            Transforms.setNodes(
              editor,
              {
                data: {
                  ...(element.data || {}),
                  marks: newMarks,
                },
              },
              {
                at: path,
              }
            );
            setMarks(newMarks);
          });

        console.log('Remove key... update-template');
        return;
      }
      //

      const { id: versionId, documentId, documentTemplateId } = contract;
      const { item_id, template_id } = element.data || {};

      if (versionId && documentId && documentTemplateId && item_id && template_id) {
        api
          .post('/clauselibs', {
            data: {},
            itemId: item_id,
            templateId: template_id,
            versionId,
            documentId,
            documentTemplateId,
          })
          .then((res) => {
            if (!res.data) {
              notification.error({
                message: 'Cannot create template update',
                description: 'Please try again later',
              });
            }
            const { id } = res.data;
            const newMarks = {
              ...marks,
              [key]: id,
            };
            Transforms.setNodes(
              editor,
              {
                data: {
                  ...(element.data || {}),
                  marks: newMarks,
                },
              },
              {
                at: path,
              }
            );
            setMarks(newMarks);
            notification.success({
              message: 'Template update',
              description: 'Marked as proposal for update of template',
            });
          })
          .catch((err) => {
            notification.error({
              message: 'Cannot create template update',
              description: 'Please try again later',
            });
          });
      } else {
        console.log('Missing data to add new ClauseLib item');
      }
    } else {
      const newMarks = {
        ...marks,
        [key]: !marks[key],
      };
      Transforms.setNodes(
        editor,
        {
          data: {
            ...(element.data || {}),
            marks: newMarks,
          },
        },
        {
          at: path,
        }
      );
      setMarks(newMarks);
    }
  };
  return (
    <Menu.SubMenu
      title={
        <>
          <i className="mdi mdi-tag-outline mr-2" />
          <IntlMessages id="studio.blockMenu.MarkItem" />
        </>
      }
      {...rest}
      key="block_marks"
    >
      <MarkItem
        tag={'new'}
        label={<IntlMessages id="studio.blockMenu.NewItem" />}
        toggleMark={toggleMark}
        marks={marks}
        key="new-item"
      />
      <MarkItem
        tag={'required'}
        label={<IntlMessages id="studio.blockMenu.Required" />}
        toggleMark={toggleMark}
        marks={marks}
        key="required"
      />
      <Menu.Divider />
      <MarkItem
        tag={'update-template'}
        label={<IntlMessages id="studio.blockMenu.UpdateTemplate" />}
        toggleMark={toggleMark}
        marks={marks}
        key="update-template"
      />
    </Menu.SubMenu>
  );
}

function MarkItem({ tag, label, toggleMark, marks, ...rest }) {
  const active = !!marks[tag];
  const icon = active ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline';
  return (
    <Menu.Item
      {...rest}
      onClick={() => {
        toggleMark(tag);
      }}
    >
      <span>
        <i className={'mdi mr-2 ' + icon} />
        {label}
      </span>
    </Menu.Item>
  );
}
