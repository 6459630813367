import React, { useState, createRef, useEffect } from 'react';
import { Editor, Transforms } from 'slate';
import CustomScrollbars from 'util/CustomScrollbars';

import { useSelector } from 'react-redux';
import { Concept, Contract } from 'core/interfaces';
import { useContract } from 'hooks';
import { describeAllOfConcept, _getNumerus } from 'core/interfaces/concept-helpers/grammar';

export default function ConceptMenu({ editor, node, path, close }) {
  const input = useSelector(({ input }) => input);
  const contract = useContract();
  const language = Contract.getLanguage(contract);
  const inputRef = createRef();
  const [initialPrefix, id] = Concept.handleKey(node.data.key);
  const [prefix, setPrefix] = useState(initialPrefix);

  const numerus = _getNumerus(id, input, { contract });
  const variations = describeAllOfConcept(id, { contract });

  const [selected, setSelected] = useState(
    variations.findIndex((variation) => variation.fullKey === node.data.key)
  );

  const onChangeVariation = (newPrefix) => {
    const newKey = newPrefix + '_' + id;
    const newValueData = variations.find((variation) => variation.key === newPrefix);
    let value;
    if (numerus === 'singular') {
      value = newValueData.languages[language].one.value;
    } else if (numerus === 'plural') {
      value = newValueData.languages[language].many.value;
    } else {
      value =
        '[' +
        newValueData.languages[language].one.value +
        ' / ' +
        newValueData.languages[language].many.value +
        ']';
    }
    Editor.withoutNormalizing(editor, () => {
      const newData = { ...node.data, key: newKey };
      Transforms.setNodes(editor, { data: { ...newData } }, { at: path });
      Transforms.insertText(editor, value, { at: path });
    });
    setPrefix(newPrefix);
  };

  const onKeyDown = (evt) => {
    if (evt.keyCode === 40) {
      // down
      if (selected === variations.length - 1) return;
      setSelected(selected + 1);
      evt.preventDefault();
      evt.stopPropagation();
    } else if (evt.keyCode === 38) {
      // up
      if (selected === 0) return;
      setSelected(selected - 1);
      evt.preventDefault();
      evt.stopPropagation();
    } else if (evt.keyCode === 13) {
      // enter
      onChangeVariation(variations[selected].key);
      close();
      evt.preventDefault();
      evt.stopPropagation();
    } else if (evt.keyCode === 27) {
      close();
      evt.preventDefault();
      evt.stopPropagation();
    }

    // Make sure the editor's standard onKeyDown
    // is called is we have no match. This due to
    // we having stolen focus from the editor with
    // our invisible input (thus the editor's onKeyDown)
    // will not otherwise be called as the editor is
    // not focused.
    editor.onKeyDown(evt, editor);
  };

  useEffect(() => {
    if (inputRef) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div
      style={
        {
          /* height: "250px",
        width: "300px", */
        }
      }
    >
      <input
        ref={inputRef}
        onKeyDown={onKeyDown}
        style={{ opacity: 0, position: 'absolute', width: '1px' }}
      />
      <CustomScrollbars autoHeight>
        <table className="inline-menu-table">
          <tbody>
            {variations.map((variation, index) => (
              <Variations
                key={variation.key}
                variationKey={variation.key}
                prefix={prefix}
                data={variation.languages[language]}
                numerus={numerus}
                onChangeVariation={onChangeVariation}
                isSelected={selected === index}
              />
            ))}
          </tbody>
        </table>
      </CustomScrollbars>
    </div>
  );
}

function Variations({ variationKey, data, prefix, numerus, onChangeVariation, isSelected }) {
  let active = variationKey === prefix;

  // console.log('Hm: ',{ variationKey, data, prefix, numerus, onChangeVariation, isSelected })

  return (
    <tr
      className={
        'inline-select-concept-variation-row' + (active ? ' active' : '') + (isSelected ? ' selected' : '')
      }
      onMouseDown={() => {
        onChangeVariation(variationKey);
      }}
    >
      <td>
        <small
          className={
            'pr-3 inline-select-concept-variation-cell' + (active && numerus === 'singular' ? ' active' : '')
          }
        >
          {data.one.value}
        </small>
      </td>
      <td>
        <small
          className={
            'pl-3 inline-select-concept-variation-cell' + (active && numerus === 'plural' ? ' active' : '')
          }
        >
          {data.many.value}
        </small>
      </td>
    </tr>
  );

  /* 
  <small className="pr-2">{variation.languages[language].one.value}</small>
  <small className="pr-2 pl-2">{variation.languages[language].one_genitive.value}</small>
  <small className="pr-2 pl-2">{variation.languages[language].many.value}</small>
  <small className="pl-2">{variation.languages[language].many_genitive.value}</small>
   */
}
