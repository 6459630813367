import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import rootSaga from '../sagas/index';
import createRootReducer from '../reducers';

import { inputModule } from '../inputModule/';
import { engineMiddleware } from './middleware/engineMiddleware';

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger();

const middlewares = [thunk, sagaMiddleware, logger, inputModule, engineMiddleware];

let sagaRuns = 0;

let store;

export default function configureStore(preloadedState) {
  const composeEnhancers =
    (process.env.REACT_APP_NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  store = createStore(
    createRootReducer(), // root reducer with router state
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  if (sagaRuns === 0) sagaMiddleware.run(rootSaga);
  sagaRuns++;
  return store;
}

export function getStore() {
  return store;
}
