import api from 'utils/api';
import { getStore } from 'appRedux/store';
import { getContractValues, getIsTemplateStudio } from 'hooks';
import { Contract } from 'core/interfaces';

function finalTemplateInputState(input, contract) {
  const newInputState = JSON.parse(JSON.stringify(input));

  for (const cardId in newInputState) {
    const repeatableInfo = Contract.getRepeatableInfo(contract, cardId);

    let cardDefaultState;
    if (repeatableInfo) {
      cardDefaultState = newInputState[cardId][Object.keys(newInputState[cardId])[0]];
    } else {
      cardDefaultState = newInputState[cardId];
    }
    if (!contract.data.ui.cards[cardId]) continue;

    contract.data.ui.cards[cardId].values = cardDefaultState;

    if (!repeatableInfo) continue;
    const { defaultNumber } = repeatableInfo;
    const repeatableState = newInputState[cardId];
    const repeatableStateUid = Object.keys(repeatableState);
    const stateLength = repeatableStateUid.length;

    if (stateLength > defaultNumber) {
      for (let i = stateLength - 1; i > defaultNumber - 1; i--) {
        delete repeatableState[repeatableStateUid[i]];
      }
    }
  }

  return newInputState;
}

const save = function (draft, opts = {}) {
  return new Promise(function (resolve, reject) {
    const { input, entities } = getStore().getState();
    const contract = getContractValues();
    const isTemplate = getIsTemplateStudio();

    // This would fix a bug caused in dev mode where
    // the state is sometimes empty at save. But
    // TBD; what it
    // if (Object.keys(input).length === 0) return;

    if (isTemplate) {
      // In template mode, the input state has been setup to cater for
      // the documentTemplate being in template mode (set in loadDraft).
      // Now, ensure that repeatable cards are dealt with properly.
      // const templateInputState = finalTemplateInputState(input, contract);
      const templateInputState = input;

      const updatedData = Contract.getData(contract);
      const updatedInfo = Contract.getInfo(contract);

      updatedData.input = templateInputState;

      api
        .put('/documenttemplates/' + contract.id, { data: updatedData, info: updatedInfo })
        .then(function (res) {
          resolve(true);
        })
        .catch((err) => {
          console.log('Cannot update version: ', err, err.response);
          reject(err);
        });
      console.log('Updating creation....', { updatedData, updatedInfo });
    }
  });
};

export default save;
