import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Drawer, Layout } from 'antd';
import debounce from 'lodash/debounce';

import { COLLAPSE_SIZE } from 'config';

const { Sider } = Layout;

const Sidebar = ({children}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isMini, setIsMini] = useState(false);
  const [drawerVisible, setDrawerVisisble] = useState(true);

  const widthRef = useRef(window.innerWidth);

  const narrowView = width < COLLAPSE_SIZE;

  const updateWindowW = useCallback(
    debounce(() => {
      const windowInnerWidth = window.innerWidth;
      if (windowInnerWidth === widthRef.current) return; // console.log('Same widths')
      widthRef.current = windowInnerWidth;
      setWidth(windowInnerWidth);
      // dispatch(updateWindowWidth(window.innerWidth));
    }, 500),
    []
  );

  const toggleMini = useCallback(() => {
    setIsMini(!isMini);
  }, [isMini, setIsMini]);

  useEffect(() => {
    window.addEventListener('menu_mini', toggleMini);
    return () => {
      window.removeEventListener('menu_mini', toggleMini);
    };
  }, [toggleMini]);

  const toggleDrawer = useCallback(() => {
    setDrawerVisisble(!drawerVisible);
  }, [drawerVisible, setDrawerVisisble]);

  useEffect(() => {
    window.addEventListener('toggle_drawer', toggleDrawer);
    return () => {
      window.removeEventListener('toggle_drawer', toggleDrawer);
    };
  }, [toggleDrawer, updateWindowW]);

  useEffect(() => {
    window.addEventListener('resize', updateWindowW);
    return () => {
      window.removeEventListener('resize', updateWindowW);
    };
  }, [updateWindowW]);

  let drawerStyle;
  if (narrowView) {
    if (isMini) drawerStyle = 'mini-sidebar mini-custom-sidebar';
    else drawerStyle = 'collapsed-sidebar';
  } else {
    if (isMini) drawerStyle = 'mini-sidebar mini-custom-sidebar';
    else drawerStyle = 'custom-sidebar';
  }

  // console.log('autoMini ', { narrowView, isMini, drawerStyle });

  /*
  Normal: {narrowView: false, drawerStyle: "custom-sidebar"}
  Manually collapsed sidebar: {narrowView: false, drawerStyle: "mini-sidebar mini-custom-sidebar"}

  Tight: 
  Normal: {narrowView: true, drawerStyle: "collapsed-sidebar"}
  Manually collapsed: {narrowView: true, drawerStyle: "mini-sidebar mini-custom-sidebar"}
  */

  return (
    <Sider
      className={`app-sidebar ${drawerStyle} ${isMini?'mini':'full'}-sidebar`}
      trigger={null}
      collapsed={narrowView ? false : isMini}
      theme="lite"
    >
      {narrowView ? (
        <Drawer
          className={`drawer-sidebar`}
          placement="left"
          closable={false}
          onClose={toggleDrawer}
          visible={drawerVisible}
        >
          {children}
        </Drawer>
      ) : children}
    </Sider>
  );
};
export default Sidebar;
