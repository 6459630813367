import { useContext } from 'react';
import { Tooltip, Popconfirm } from 'antd';
import { ReactEditor } from 'slate-react';
import contractDefaults from 'core/config/contractDefaults';
import { ptToEm } from 'core/utils/general';
import { Conversations } from './Conversations/ConversationMarks';
import { MarkupEntityContext } from '../../YjsEditor/Markup';

const { formatting } = contractDefaults;
const { fontSize } = formatting;

export const RenderLeaf = ({ attributes, children, leaf, editor, ...rest }) => {
  const extraAttributes = { style: {} };

  if (editor.meta.markup) {
    if (leaf.ychange)
      return <YChange attributes={attributes} children={children} leaf={leaf} editor={editor} {...rest} />;
  }

  if (leaf.color) {
    extraAttributes.style.color = leaf.color;
  }

  if (leaf.size) {
    extraAttributes.style.fontSize = leaf.size + 'em';
  } else {
    extraAttributes.style.fontSize = ptToEm(fontSize) + 'em';
  }

  if (leaf.bold) {
    extraAttributes.style.fontWeight = '600';
    // children = <strong {...attributes}>{children}</strong>
  }

  if (leaf.code) {
    children = <code {...attributes}>{children}</code>;
  }

  if (leaf.italic) {
    extraAttributes.style.fontStyle = 'italic';
  }

  if (leaf.underlined) {
    extraAttributes.style.textDecoration = 'underline';
  }

  if (leaf.highlight) {
    extraAttributes.style.backgroundColor = '#ffeeba';
  }

  if (leaf.tab) {
    extraAttributes.tab = 'true';
  }

  if (leaf._deletedBy) {
    if (!extraAttributes.className) extraAttributes.className = 'deleted';
    else extraAttributes.className += ' deleted';
    // delete attributes.style.color;
    /* children = (
      <del style={{ color: 'red', position: 'relative' }}>
        {children}
        <ChangeX leaf={text} />
      </del>
    ); */
  }
  if (leaf._insertedBy) {
    if (!extraAttributes.className) extraAttributes.className = 'inserted';
    else extraAttributes.className += ' inserted';
    // delete attributes.style.color;
    /* children = (
      <span style={{ color: 'green', position: 'relative' }}>
        {children}
        <ChangeX leaf={text} />
      </span>
    ); */
  }

  if (leaf._acceptedBy) {
    if (!extraAttributes.className) extraAttributes.className = 'accepted';
    else extraAttributes.className += ' accepted';
  }

  if (leaf._conv && leaf._conv.length > 0) {
    return (
      <Conversations leaf={leaf} attributes={attributes} editor={editor} extraAttributes={extraAttributes}>
        {children}
      </Conversations>
    );
  }

  /* if(leaf.text === "") {
      extraAttributes['data-empty'] = true
    } */

  return (
    <span {...attributes} {...extraAttributes}>
      {children}
    </span>
  );
};

function YChange({ leaf, attributes, children, editor, text }) {
  // const entities = useContext(MarkupEntityContext);
  const style = {};
  if (leaf.size) {
    style.fontSize = leaf.size + 'em';
  } else {
    style.fontSize = ptToEm(fontSize) + 'em';
  }

  const { type } = leaf.ychange;

  const className = type === 'added' ? 'markup_mark_inserted' : 'markup_mark_deleted';

  return (
    <span {...attributes} style={style} className={className}>
      {children}
    </span>
  );
}

// WIP YChange with ability to accept / reject changes somehow.
/*
function YChange({ leaf, attributes, children, editor, text }) {
  const entities = useContext(MarkupEntityContext);
  const style = {};
  if (leaf.size) {
    style.fontSize = leaf.size + 'em';
  } else {
    style.fontSize = ptToEm(fontSize) + 'em';
  }

  const { eId, type } = leaf.ychange;

  const accept = () => {
    console.log('leaf is ', { leaf, text });
    console.log('editor is ', editor);
    try {
      const path = ReactEditor.findPath(editor, text);
      console.log('Path is ', type, path);
    } catch (err) {
      console.log('err', err);
    }
  };

  const name = eId && entities?.[eId] ? `${entities[eId].firstName} ${entities[eId].lastName}` : '[Unknown]';

  const className = type === 'added' ? 'markup_mark_inserted' : 'markup_mark_deleted';
  const action = type === 'dded' ? 'Insertion' : 'Deletion';

  return (
    <Popconfirm
      title={
        <div>
          <div>
            {action} by <strong>{name}</strong>
          </div>
          <div>Handle suggestion</div>
        </div>
      }
      trigger={['hover']}
      cancelText="Discard"
      okText="Accept"
      onConfirm={accept}
      onCancel={accept}
      icon={null}
    >
      <span {...attributes} style={style} className={className}>
        {children}
      </span>
    </Popconfirm>
  );
}
*/
