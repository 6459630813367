import { Concept, Entity } from '../../../interfaces';
import { ucfirst, ocount } from '../../../utils/general';

export const listTheParties = function (node, state) {
  if (!this.getContractCreate().build || !this.getContractCreate().build.concepts) return;

  const contractPartyConcepts = this.getContractCreate().build.concepts.filter(
    (concept) => concept.contractParty
  );

  const partyList = [];
  const partyCategoryJointDefinition = [];
  let pCount = 0;

  for (const contractPartyConcept of contractPartyConcepts) {
    const { id, stateKey } = contractPartyConcept;

    const conceptState = Concept.getConceptState(contractPartyConcept, this.contract, state);
    const conceptLen = ocount(conceptState);
    if (conceptLen === 0) continue;

    let catPstart = pCount + 1;
    let categoryNicknames = 0;

    const { text: definitionName } =
      Concept.describe('the_' + id, state.input, this.language, { contract: this.contract }) || '[*x*]';
    const definitionDescribeData = Concept.getDescribeDataByType('borrower', 'pluralFixed', this.language);

    const describeConceptSingularAs = Concept.grammarOrBlob(id, 'singular', this.language, {
      contract: this.contract,
    });

    for (const [, party] of Object.entries(conceptState)) {
      if (typeof party !== 'object' || party === null) continue;
      const stateKeyData = party[stateKey];
      let entity, partyName;

      if (!stateKeyData) partyName = this.emptyContractString();
      else if (typeof stateKeyData === 'string') {
        partyName = stateKeyData;
        entity = party;
      } else if (typeof stateKeyData === 'object') {
        entity = Entity.getFromStateById(state, stateKeyData.id, stateKeyData.type);
        if (!entity) {
          this.log('Cannot locate party entity', { party });
          continue;
        }
        partyName = this.api.utils.general.userToName(entity);
      }

      const partyTextNodes = [this.makeTextNode(partyName, 'bold')];

      let initText = '';

      const entityOrParty = entity || party;
      if (
        entityOrParty &&
        (!entityOrParty._settings || entityOrParty._settings.identificationNumberInPartyList !== false)
      ) {
        if (entityOrParty.identificationNumber)
          initText += ' (' + this.translateText('regno') + ' ' + entityOrParty.identificationNumber + ')';
        else if (entityOrParty.regno)
          initText += ' (' + this.translateText('regno') + ' ' + entityOrParty.regno + ')';
      }

      initText +=
        ' ' +
        this.translateText('as') +
        ' ' +
        (describeConceptSingularAs && describeConceptSingularAs.toLowerCase());

      if (party['party/definition'] && party['party/definition'] !== '') {
        let prefixFixed = this.translateText('prefixFixed');
        var prefixLength = prefixFixed.length;
        if (
          prefixFixed &&
          prefixFixed !== '[**]' &&
          party['party/definition'].substr(0, prefixLength) === prefixFixed
        ) {
          initText += ' (' + prefixFixed + '"';
          partyTextNodes.push(this.makeTextNode(initText));

          partyTextNodes.push(this.makeTextNode(party['party/definition'].substr(prefixLength), 'bold')); // Get rid of 'the' or similar..
        } else {
          initText += ' ("';
          partyTextNodes.push(this.makeTextNode(initText));

          partyTextNodes.push(this.makeTextNode(party['party/definition'], 'bold'));
        }
        partyTextNodes.push(this.makeTextNode('")'));
      } else if (conceptLen === 1) {
        const { prefix } = Concept.getDescribeDataByType(id, 'singularFixed', this.language, { contract: this.contract });
        initText += ' (' + (prefix ? prefix + ' ' : '') + '"';
        partyTextNodes.push(this.makeTextNode(initText));

        partyTextNodes.push(this.makeTextNode(definitionName, 'bold'));
        partyTextNodes.push(this.makeTextNode('")'));
        categoryNicknames++;
      } else {
        partyTextNodes.push(this.makeTextNode(initText));
      }

      partyList.push(
        this.makeListItem(partyTextNodes, {
          template_id: 'listParty_name_',
          item_id: this.uuid(),
        })
      );
      pCount++;
    }

    if (conceptLen > categoryNicknames || conceptLen > 1) {
      let endText;
      // let nameOfCat;
      if (conceptLen > 1) {
        // nameOfCat = Concept.grammarOrBlob(id, "pluralFixed", this.language, {contract: this.contract});
        endText =
          this.translateText('theParties') +
          ' ' +
          this.translateText('setOut') +
          ' ' +
          this.translateText('theItems') +
          ' ' +
          this.formatListItem(catPstart, 0) +
          '-' +
          this.formatListItem(pCount, 0) +
          ' ' +
          this.translateText('above') +
          ' ' +
          this.translateText('are');
      } else {
        // nameOfCat = Concept.grammarOrBlob(id, "singularFixed", this.language, {contract: this.contract});
        endText =
          this.translateText('theParty') +
          ' ' +
          this.translateText('setOut') +
          ' ' +
          this.translateText('item') +
          ' ' +
          this.formatListItem(pCount, 0) +
          ' ' +
          this.translateText('above') +
          ' ' +
          this.translateText('is');
      }

      endText += ' ' + this.translateText('belowDefined') + ' ';
      if (definitionDescribeData.prefix) {
        endText += definitionDescribeData.prefix + (definitionDescribeData.prefixSpace ? ' ' : '');
      }
      endText += '"<b>' + definitionName + '</b>"';

      partyCategoryJointDefinition.push(endText);

      /* partyCategoryJointDefinition.push(
        endText +
          " " +
          this.translateText("belowDefined") +
          " " +
          (definitionDescribeData.prefix
            ? definitionDescribeData.prefix + (definitionDescribeData.prefixSpace ? " " : "")
            : "") +
          ' "' +
          definitionName +
          '"'
      ); */
    }
  }
  if (partyList.length > 0) {
    var returnBlock = [
      this.makeList(partyList, {
        item_join: 'and',
        item_id: 'list-the-parties_list_x87de',
      }),
    ];
    if (partyCategoryJointDefinition.length > 0) {
      returnBlock.push(
        this.makeParagraph(ucfirst(this.imp_and(partyCategoryJointDefinition)) + '.', {
          item_id: 'list_the_parties_x87de',
        })
      );
    }
    // this.log('parties returnBlock', returnBlock, partyList);
    return returnBlock;
  }
  return [];
};
