import jsonLogic from 'json-logic-js';
import { Contract } from '../../interfaces';
import {
  input,
  setup,
  local,
  numof,
  numof_state,
  card,
  collection,
  any_in_card,
  all_in_card,
  length,
  // varValues,
  // objectValues,
  // firstSet,
  // firstSetItem,
  // like,
  // qAndA,
} from './jsonLogicOperations';

import { USE_CALCULATED_AFFECTED_ACP_KEYS } from '../../config/draft';

jsonLogic.add_operation('input', input);
jsonLogic.add_operation('setup', setup);
jsonLogic.add_operation('local', local);

jsonLogic.add_operation('numof', numof);
jsonLogic.add_operation('card', card); // Used by 'numof'.
jsonLogic.add_operation('numof_state', numof_state);
jsonLogic.add_operation('collection', collection); // Used by 'numof_state'

jsonLogic.add_operation('any_in_card', any_in_card);
jsonLogic.add_operation('all_in_card', all_in_card);

jsonLogic.add_operation('length', length);

// Currently not used by any template. Might be useful.
// jsonLogic.add_operation('varValues', varValues);
// jsonLogic.add_operation('objVar', varValues);
// jsonLogic.add_operation('objectValues', objectValues);
// jsonLogic.add_operation('firstSet', firstSet);
// jsonLogic.add_operation('firstSetItem', firstSetItem);

// Work in progress. For Q and A fields.
// jsonLogic.add_operation('like', like);
// jsonLogic.add_operation('qAndA', qAndA);

const ruleOperators = [
  'input',
  'local',
  'setup',
  'numof',
  'card',
  'any_in_card',
  'all_in_card',
  'numof_state',
];

export function getComponents(rule, useRuleOperators = ruleOperators) {
  let operators = [];
  if (!rule) return;
  const ops = Object.keys(rule);
  if (ops.length !== 1) return; // Invalid json rule
  const operator = ops[0];
  if (useRuleOperators.includes(operator)) {
    operators.push([operator, rule[operator]]);
  }

  if (Array.isArray(rule[operator])) {
    for (const ruleItem of rule[operator]) {
      operators = operators.concat(getComponents(ruleItem, useRuleOperators));
    }
  }
  return operators.filter((item) => !!item);
}

export function getConditionVariables(condition) {
  let variables = [];

  if (!condition) return;
  const ops = Object.keys(condition);
  if (ops.length !== 1) return; // Invalid json rule
  const operator = ops[0];

  if (operator === 'var') {
    variables.push(condition[operator]);
  }

  if (Array.isArray(condition[operator])) {
    for (const conditionItem of condition[operator]) {
      variables = variables.concat(getConditionVariables(conditionItem));
    }
  }
  return variables.filter((item) => !!item);
}

var ruleTypeMatches = {
  numof_repeatable: (parameters, keys) => {
    let repeatable = Array.isArray(parameters) ? parameters[0] : parameters;
    return keys.includes(repeatable);
  },
  count_repeatable: (parameters, keys) => {
    const [repeatable, fieldName] = parameters;
    return keys.includes(repeatable + '.' + fieldName);
  },
  any_repeatable: (parameters, keys) => {
    const [repeatable, fieldName] = parameters;
    return keys.includes(repeatable + '.' + fieldName);
  },
  all_repeatable: (parameters, keys) => {
    const [repeatable, fieldName] = parameters;
    return keys.includes(repeatable + '.' + fieldName);
  },
  all_repeatables_same: (parameters, keys) => {
    const [repeatable, fieldName] = parameters;
    return keys.includes(repeatable + '.' + fieldName);
  },
  numof: (parameters, keys) => {
    const { card, condition } = parameters;
    if (!card) return false;
    // If no condition and the entire card was updated.
    if (!condition && keys.includes(card)) return true;
    const conditionFields = getConditionVariables(parameters.condition);
    for (const conditionField of conditionFields) {
      const fieldPath = `${card}.${conditionField}`;
      if (keys.includes(fieldPath)) return true;
    }
    return false;
  },
  any_item: (parameters, keys) => {
    const [cardId] = parameters;
    return keys.includes(cardId);
  },
  all_items: (parameters, keys) => {
    const [cardId] = parameters;
    return keys.includes(cardId);
  },

  numof_nodes: (parameter, keys) => {
    return parameter === 'nodes' && keys.includes(parameter);
  },

  input: (parameter, keys) => {
    return keys.includes(parameter);
  },
  setup: (parameter, keys, options = {}) => {
    if (options.alwaysMatchSetup) {
      return true;
    }
    return keys.includes(parameter);
  },
  shortcut: (parameter, keys, options = {}) => {
    return keys.includes('$_shortcut_$' + parameter);
  },
  local: (parameter, keys) => {
    return keys.includes('$_local_$' + parameter); // to avoid uncessary matches.
  },
};

export function matchRule(rule, keys) {
  if (USE_CALCULATED_AFFECTED_ACP_KEYS) {
    return xmatchRule(rule, keys);
  }
  return true;
}
export function xmatchRule(rule, keys, options = {}) {
  if (!rule) return;
  const ops = Object.keys(rule);
  if (ops.length !== 1) return; // Invalid json rule
  const operator = ops[0];
  if (ruleOperators.includes(operator)) {
    if (!ruleTypeMatches[operator]) {
      console.log('No ruleTypeMatch for operator ', operator);
      return;
    } else if (typeof ruleTypeMatches[operator] !== 'function') {
      console.log('ruleTypeMatch for operator ', operator, ' is not a function');
    }
    return ruleTypeMatches[operator](rule[operator], keys, options);
  }

  if (Array.isArray(rule[operator])) {
    for (const ruleItem of rule[operator]) {
      const gotMatch = xmatchRule(ruleItem, keys, options);
      if (gotMatch) return true;
    }
  }
}

export default function applyJsonLogic(rule, state, options = {}) {
  if (typeof rule === 'string') {
    return validateRule({
      id: rule,
      state,
      contract: options._meta.contract,
      options,
    });
  }

  const { _meta = {}, data = {} } = options;
  if (!_meta.create) _meta.create = {};
  if (!_meta.contract) _meta.contract = {};
  if (!_meta.data) _meta.data = data;
  if (!_meta.ruleValues) _meta.ruleValues = {};

  try {
    const result = jsonLogic.apply(rule, {
      ...state,
      _meta,
    });
    // console.log('Logic result ', {result, rule, state, _meta})
    return result;
  } catch (err) {
    console.log('JSON LOGIC BUG: ', { rule, data, _meta, err });
    /* console.log(err)
    console.trace('json logic bug trace') */
    return false;
  }
}

export function validateRule({ id, state, contract, options = {} }) {
  if (!id || !state || !contract) {
    console.warn('Expected arguments id, state and contract', { id, state, contract });
    return;
  }

  if (options._meta && options._meta.ruleValues && options._meta.ruleValues.hasOwnProperty(id)) {
    return options._meta.ruleValues[id];
  }
  if (state.contractData && state.contractData.rules && state.contractData.rules.hasOwnProperty(id)) {
    return state.contractData.rules[id];
  }

  const rule = Contract.getRule(contract, id);
  if (!rule) {
    console.warn('No rule for rule with id ', id);
    return false;
  }

  try {
    const result = jsonLogic.apply(rule.data, {
      ...state,
      _meta: {
        contract,
        data: options.data || {},
      },
    });
    return result;
  } catch (err) {
    console.log('LOGIC BUG: ', { ruleData: rule.data, state, contract, options, err });
    return;
  }
}
