import React from "react";
import ReactDOM from "react-dom";
/* import dotenv from 'dotenv'; */
import 'antd/dist/antd.css';
import NextApp from './NextApp';
/* dotenv.config(); */

const render = Component => {
  ReactDOM.render(
    
      <Component/>
    ,
    document.getElementById('root')
  );
};

// Render once
render(NextApp);
