import React, { useEffect, useState, useCallback } from 'react';
import { Card, Row, Col, Button, Modal, Spin } from 'antd';
import api from 'utils/api';
import NewDocumentItem from './NewDocumentItem';
// import NewDocumentItem from './OldDocumentItem';
import IntlMessages from 'util/IntlMessages';
import AddTemplatesToProject from '../common/AddTemplatesToProject';
import { gutter } from 'config';

export default function NewDocumentButton(props) {
  const {
    project,
    children,
    importedStateDocumentId,
    importedStateVersionId,
    documentTemplateId,
    documentWasCreated,
    autostart,
  } = props;
  const { isFolder } = project;

  const [open, setOpen] = useState(false);
  const [templateDocuments, setTemplateDocuments] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(documentTemplateId || null);

  const close = useCallback(() => {
    setOpen(false);
    if (typeof props.onClose === 'function')
      props.onClose();
  }, []);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const fetchAllClientTemplateDocuments = useCallback(() => {
    if (documentTemplateId) {
      api
        .get('/documenttemplates/' + documentTemplateId)
        .then(function (res) {
          if (res.data) {
            setTemplateDocuments([res.data]);
          } else {
            console.log('Error in fetching templates, ', res);
          }
        })
        .catch((err) => console.log('get err', err, err.response));
      return;
    }

    api
      .get('/documenttemplates?fields=id,name,description,createdAt,updatedAt')
      .then(function (res) {
        if (res.data && Array.isArray(res.data)) {
          setTemplateDocuments(res.data.filter((template) => template.data.ui));
        } else {
          console.log('Error in fetching templates, ', res);
        }
      })
      .catch((err) => console.log('get err', err, err.response));
  }, [documentTemplateId]);

  const fetchStandAloneTemplateDocuments = useCallback(() => {
    if (documentTemplateId) {
      api
        .get('/documenttemplates/' + documentTemplateId + '?fields=id,name,description,createdAt,updatedAt')
        .then(function (res) {
          if (res.data) {
            setTemplateDocuments([res.data]);
          } else {
            console.log('Error in fetching templates, ', res);
          }
        })
        .catch((err) => console.log('get err', err, err.response));
      return;
    }

    const docTemplates = project.data.documentTemplatesIds.standalone;

    api
      .get(
        '/documenttemplates/multiple?id=' +
          JSON.stringify(docTemplates) +
          '&fields=id,name,description,createdAt,updatedAt'
      )
      .then(function (res) {
        if (res.data) {
          setTemplateDocuments(res.data);
        } else {
          console.log('Error in fetching template documents, ', res);
        }
      })
      .catch((err) => console.log('get templ docs err', err, err.response));
  }, [project, documentTemplateId]);

  useEffect(() => {
    if (!open || templateDocuments) return;
    if (project.isPersonal || documentTemplateId) {
      fetchAllClientTemplateDocuments();
    } else if (
      project &&
      project.data &&
      project.data.documentTemplatesIds &&
      project.data.documentTemplatesIds.standalone
    ) {
      fetchStandAloneTemplateDocuments();
    } else {
      setTemplateDocuments([]);
    }
  }, [
    project,
    project.id,
    open,
    templateDocuments,
    documentTemplateId,
    fetchAllClientTemplateDocuments,
    fetchStandAloneTemplateDocuments,
  ]);

  const toggleOpen = useCallback(() => {
    setOpen(!open);
    if (!documentTemplateId) setSelectedTemplate(null);

    if (open && typeof props.onClose === 'function') {
      props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, documentTemplateId, props.onClose]);

  if (!project) {
    return <div>No Project information</div>;
  }

  const templatesLoading = !Array.isArray(templateDocuments);
  const templatesEmpty = !templatesLoading && templateDocuments.length === 0;
  const templatesOK = !templatesLoading && templateDocuments.length > 0;
  const aTemplateSelected = selectedTemplate !== null;

  let buttonContent = null;
  if (children) {
    buttonContent = (
      <div className="clickable w-100 h-100" onClick={toggleOpen}>
        {children}
      </div>
    );
  } else if (!props.hideButton) {
    buttonContent = (
      <Button type="primary" onClick={toggleOpen}>
        <IntlMessages id={'app.project.NewDocument'} />
      </Button>
    );
  }

  return (
    <>
      {buttonContent}
      <Modal
        title={
          <div className="d-flex">
            {aTemplateSelected && !documentTemplateId && (
              <Button
                type="secondary"
                size="small"
                className="creation-modal-back float-left ml-2"
                onClick={() => {
                  setSelectedTemplate(null);
                }}
              >
                <i className="mdi mdi-arrow-left mr-2" />
                <IntlMessages id={'desc.Back'} />
              </Button>
            )}
            <div>
              <IntlMessages id={'app.project.NewDocument'} />
            </div>
          </div>
        }
        className="modal-lg"
        visible={open}
        backdrop={'static'}
        onOk={toggleOpen}
        onCancel={toggleOpen}
        footer={[
          <Button key="back" onClick={toggleOpen}>
            <IntlMessages id={'desc.Close'} />
          </Button>,
        ]}
      >
        {open && templatesEmpty && isFolder && <NoTemplateAvailableForFolder project={project} />}
        {open && templatesEmpty && !isFolder && <NoTemplatesAvailable />}
        {open && templatesOK > 0 && !aTemplateSelected && (
          <div>
            <div className="text-center">
              <div className="card-title mb-4">
                <h6>
                  <IntlMessages id={'app.project.ChooseDocumentTemplate'} />
                </h6>
              </div>
            </div>
            <Row gutter={gutter}>
              {templateDocuments.map((documentTemplate) => (
                <Col span={8} key={'doc_tmpl2_' + documentTemplate.id}>
                  <Card
                    className="mb-1 pb-1 clickable liftable"
                    onClick={() => {
                      setSelectedTemplate(documentTemplate.id);
                    }}
                  >
                    {/* <div className="new-project-text">Create new</div> */}
                    <div className="flex-column justify-content-center align-items-center">
                      <i className={'mdi mdi-file-outline'} style={{ fontSize: 48 }} />
                      <h5 align={'center'}>{documentTemplate.name}</h5>
                      <small align={'center'} className="text-center">
                        {documentTemplate.description}
                      </small>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        )}
        {open && templatesOK && aTemplateSelected && (
          <div>
            <NewDocumentItem
              // documentTemplate={templateDocuments.find((templ) => templ.id === selectedTemplate)}
              id={selectedTemplate}
              importedStateDocumentId={importedStateDocumentId}
              importedStateVersionId={importedStateVersionId}
              project={project}
              close={close}
              documentWasCreated={documentWasCreated}
              autostart={autostart}
            />
          </div>
        )}
        {open && templatesLoading && (
          <>
            <div className="text-center">
              <Spin className="loader-container" />
              Loading templates...
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

function NoTemplateAvailableForFolder({ project }) {
  return (
    <div>
      <div className="mb-4">Your folder does not currently have any specified templates available to it.</div>
      <AddTemplatesToProject project={project} />
    </div>
  );
}

function NoTemplatesAvailable() {
  return <div>No templates available.</div>;
}
