import {
  AUTH_OVERLAY,
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  SET_USER,
  AUTH_SET_CLIENT,
} from "constants/ActionTypes";
import jwt_decode from "jwt-decode";
import { IS_EXTERNAL } from 'config'

const DEBUG = process.env.REACT_APP_NODE_ENV === "development";

const initToken = localStorage.getItem("jwtToken");

const FALLBACK_USER = IS_EXTERNAL ? {
  id: '_external_',
  firstName: '_external_'
} : null

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  user: initToken ? jwt_decode(initToken) : FALLBACK_USER,
  authOverlay: false,
};

const authReduer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTH_OVERLAY: {
      return {
        ...state,
        authOverlay: action.payload,
      };
    }
    case AUTH_SET_CLIENT: {
      return {
        ...state,
        client: action.payload
      };
    }
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        user: action.payload,
      };
    }
    case SIGNIN_USER_SUCCESS: {
      if (DEBUG)
        console.log("Successful Login Reducer", {
          payload: action.payload,
          lastState: JSON.parse(JSON.stringify(state)),
        });
      return {
        ...state,
        initURL:
          state.initURL === "/verify" || state.initURL === "/signup" ? "/main" : state.initURL,
        loader: false,
        authOverlay: false,
        user: action.payload,
      };
    }
    case SET_USER: {
      return {
        ...state,
        user: action.payload
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        user: null,
        initURL: "/",
        loader: false,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};

export default authReduer;
