import produce from 'immer';

export default function reducer(previousState, action) {
  const { type, key, id, payload } = action;

  return produce(previousState, (state) => {
    switch (type) {
      case '_all_':
        return payload;
      case '_merge_':
        return { ...state, ...payload };
      case 'SET_POWER_USER_ENABLED':
        state.core.features.externalPowerUser.enabled = payload;
        break;
      case 'SET_POWER_USER_WHITELIST':
        state.core.features.externalPowerUser.whitelistedIPs = payload;
        break;
        case 'SET_ALL_PROJECTS_ACCESSIBLE':
        state.core.projects.allProjectsAccessible = payload;
        break;
        case 'SET_PASSWORD_POLICY_ITEM':
          if(!key) return state
          state.core.passwordPolicy[key] = payload
        break;
        case 'ADD_HOOK':
          if (!state.webHooks[key]) return state
          state.webHooks[key].hooks.push(payload)
          break;
        case 'REMOVE_HOOK':
          if (!state.webHooks[key]) return state
          const index = state.webHooks[key].hooks.findIndex(x => x.id === payload)
          if (index > -1) {
            state.webHooks[key].hooks.splice(index, 1)
          }
          break;
      default:
        return state;
    }
  });
}

const example = {
  core: {
    features: {
      externalPowerUser: {
        enabled: true,
        whitelistedIPs: ['123.123.123.999/32'],
      },
    },
    projects: {
      allProjectsAccessible: false,
    },
    passwordPolicy: {
      withNumber: false,
      withUppercase: false,
      passwordLength: 10,
      withSpecialCharacter: false,
    },
  },
  webHooks: {
    core: {
      hooks: [],
    },
    task: {
      hooks: [],
    },
    events: {
      hooks: [],
    },
    contractTask: {
      hooks: [],
    },
    contractClauseStatus: {
      hooks: [],
    },
    contractTaskSchedule: {
      hooks: [],
    },
  },
};
