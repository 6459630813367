/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { Editor, Transforms, Text } from 'slate';
import { useSlate, useSlateStatic } from 'slate-react';
import { useSelector } from 'react-redux';
import { useTrackChanges, useIsStudioWriter } from 'hooks';
import { Checkbox } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { HelpItem } from 'components/ui';
import IntlMessages from 'util/IntlMessages';
import { Button } from './components';

function matchChangeText(element, key, isStudioWriter, entityId) {
  if (!Text.isText(element)) return false;
  if (isStudioWriter) return !!element[key];
  return element[key]?.eId === entityId;
}

export default function ControlTrackChanges() {
  const editor = useSlate();
  const isStudioWriter = useIsStudioWriter();
  const entityId = useSelector((state) => state.auth?.user?.entityId);
  const { trackingEnabled, toggleTrackingEnabled } = useTrackChanges();

  const elements = Array.from(Editor.nodes(editor)).filter(
    ([element]) =>
      matchChangeText(element, '_insertedBy', isStudioWriter, entityId) ||
      matchChangeText(element, '_deletedBy', isStudioWriter, entityId)
  );
  const numberOfChanges = elements.length;

  return (
    <>
      {/* <i className="mdi mdi-format-text" />
      <i className="mdi mdi-marker" /> */}
      <div className="btns-section">
        <HelpItem
          icon={<QuestionCircleOutlined className="fs-lg" />}
          infoHeader={<IntlMessages id="studio.trackChanges.help.label" />}
          infoContent={<IntlMessages id="studio.trackChanges.help.text" />}
          className="mr-1"
        />

        <Checkbox
          className="ml-4 mr-2"
          disabled={!isStudioWriter}
          checked={trackingEnabled}
          onChange={toggleTrackingEnabled}
        >
          <IntlMessages id="studio.trackChanges.label" />
        </Checkbox>

        <AcceptReject numberOfChanges={numberOfChanges} isStudioWriter={isStudioWriter} entityId={entityId} />
      </div>
    </>
  );
}

function copySelectionToNew(point) {
  return {
    anchor: { path: point.path, offset: point.offset },
    focus: { path: point.path, offset: point.offset },
  };
}

function AcceptReject({ numberOfChanges, isStudioWriter, entityId }) {
  const editor = useSlateStatic();

  const selectionAtFocus = (value) => Transforms.collapse(editor);
  /* setTimeout(() => {
      Transforms.select(editor, value);
      Transforms.setSelection(editor, value);
    }, 1000); */

  const acceptChanges = () => {
    if (!isStudioWriter) return;
    const { anchor } = editor.selection || {};
    const afterSelection = copySelectionToNew(Editor.before(editor, anchor));
    console.log('after sel ', afterSelection);
    Editor.withoutNormalizing(editor, () => {
      editor.unrestrictedApply(() => {
        Transforms.unsetNodes(editor, '_insertedBy', {
          match: (element) => matchChangeText(element, '_insertedBy', isStudioWriter, entityId),
          split: true,
        });

        Transforms.removeNodes(editor, {
          match: (element) => matchChangeText(element, '_deletedBy', isStudioWriter, entityId),
        });
        /* Transforms.setNodes(
          editor,
          {
            _acceptedBy: { eId: entityId, at: Date.now() },
          },
          {
            match: (element) => matchChangeText(element, '_deletedBy', isStudioWriter, entityId),
            split: true,
          }
        ); */
        selectionAtFocus(afterSelection);
      });
    });
  };
  const rejectChanges = () => {
    const { anchor } = editor.selection || {};
    const afterSelection = copySelectionToNew(Editor.before(editor, anchor));
    Editor.withoutNormalizing(editor, () => {
      editor.unrestrictedApply(() => {
        Transforms.removeNodes(editor, {
          match: (element) => matchChangeText(element, '_insertedBy', isStudioWriter, entityId),
        });
        Transforms.unsetNodes(editor, '_deletedBy', {
          match: (element) => matchChangeText(element, '_deletedBy', isStudioWriter, entityId),
          split: true,
        });
        selectionAtFocus(afterSelection);
      });
    });
  };
  return (
    <>
      <Button disabled={numberOfChanges === 0} onClick={rejectChanges}>
        <i className="mdi mdi-close mr-1" />
        <small>
          <IntlMessages id={`studio.trackChanges.${isStudioWriter ? 'reject' : 'undo'}`} className="mr-1" />
          <IntlMessages id={`studio.trackChanges.change${numberOfChanges !== 1 ? 's' : ''}`} />
        </small>
      </Button>
      {isStudioWriter && (
        <>
          <Button disabled={numberOfChanges === 0} onClick={acceptChanges} className="ml-2">
            <i className="mdi mdi-check mr-1" />
            <small>
              <IntlMessages id="studio.trackChanges.accept" className="mr-1" />{' '}
              <IntlMessages id={`studio.trackChanges.change${numberOfChanges !== 1 ? 's' : ''}`} />
            </small>
          </Button>
        </>
      )}
    </>
  );
}
