export const obligorsAgentModuleSlate = {
  id: 'obligorsAgentModuleSlate',
  match: true,
  dependencies: { state: ['borrower.party/obligorsagent', 'guarantor.party/obligorsagent'] },
  handler: function ({ state, api }) {
    let obligorsAgentEntity;
    let obligorsAgentItem =
      state.input.borrower &&
      api.utils.general.ofindValues(state.input.borrower, (item) => item['party/obligorsagent'] === true);
    if (obligorsAgentItem) {
      obligorsAgentEntity = this.api.interfaces.Entity.getFromStateById(
        state,
        obligorsAgentItem.borrowerEntity.id
      );
    }
    if (!obligorsAgentItem) {
      obligorsAgentItem =
        state.input.guarantor &&
        api.utils.general.ofindValues(state.input.guarantor, (item) => item['party/obligorsagent'] === true);
      if (obligorsAgentItem) {
        obligorsAgentEntity = this.api.interfaces.Entity.getFromStateById(
          state,
          obligorsAgentItem.guarantorEntity.id
        );
      }
    }

    if (!obligorsAgentItem || !obligorsAgentEntity) {
      this.setVariable('obligorsAgent', undefined);
      this.setVariable('obligorsAgentRealName', undefined);
      this.setVariable('ObligorsAgent', undefined);
      this.setVariable('ObligorsAgentRealName', undefined);
      return;
    }

    let agentName;
    const agentMapped = this.mapCompanies([obligorsAgentEntity.id], state);
    if (agentMapped.match && agentMapped.fullText) agentName = agentMapped.fullText;
    else agentName = obligorsAgentItem['party/definition'] || obligorsAgentEntity.name;

    this.setVariable('obligorsAgent', agentName);
    this.setVariable('obligorsAgentRealName', obligorsAgentEntity.name);
    this.setVariable('ObligorsAgent', agentName);
    this.setVariable('ObligorsAgentRealName', obligorsAgentEntity.name);
  },
};
