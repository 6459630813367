import {
  describeConcept,
  getConceptValue,
  getDescribeData,
  _ensureConceptGrammar,
  handleKey,
} from './concept-helpers/grammar';
import { ocount, omap } from '../utils/general';
import { getComponents, getConditionVariables } from '../utils/logic/applyLogic';
import { Entity } from './Entity';
import { Contract } from './Contract';
const Concept = {};

Concept.get = (contract, id) => {
  const concepts =
    contract &&
    contract.data &&
    contract.data.create &&
    contract.data.create.build &&
    contract.data.create.build.concepts;
  if (!concepts || concepts.length === 0) return null;
  return concepts.find((item) => item.id === id) || null;
};

Concept.getByStateId = (contract, stateId) => {
  const concepts =
    contract &&
    contract.data &&
    contract.data.create &&
    contract.data.create.build &&
    contract.data.create.build.concepts;
  if (!concepts || concepts.length === 0) return null;
  return concepts.find((item) => item.stateId === stateId) || null;
};

Concept.describe = (key, input, language, options) => describeConcept(key, input, language, options);
Concept.grammar = (conceptId, type, language, options) => getConceptValue(conceptId, type, language, options);

Concept.grammarOrBlob = (conceptId, type, language, options) => {
  const { value, match } = getConceptValue(conceptId, type, language, options);
  // console.log('From grammarOrBlob: ', {conceptId, type, language, value, match})
  return match ? value : '[**]';
};
Concept.getDescribeData = (conceptId, language, options) => getDescribeData(conceptId, language, options);
Concept.getDescribeDataByType = (conceptId, type, language, options) => {
  const data = getDescribeData(conceptId, language, options);
  return (data && data[type]) || null;
};

Concept.isInheritanceConcept = (concept) => concept.type === 'reference' && concept.inheritance;

/* Various helper methods */

function gatherStateItems(state, concept, contract) {
  if (!state || !concept) return {};
  const items = {};
  let localStateItems;
  if (concept.state) localStateItems = concept.state;
  else if (concept.stateId && state.input[concept.stateId]) localStateItems = state.input[concept.stateId];
  if (!localStateItems) return items;
  if (concept.state || Contract.getUiIsCardRepeatable(contract, concept.stateId)) {
    for (const [uid, stateItem] of Object.entries(localStateItems)) {
      items[uid] = { ...stateItem, _conceptId: concept.id };
    }
  } else {
    items.std = { ...localStateItems, _conceptId: concept.id };
  }
  return items;
}

Concept.getConceptState = (concept, contract, state, options = {}) => {
  const { input } = state;
  const { ignoreInheritanceFilter = false } = options;

  if (!concept || typeof concept !== 'object' || typeof input !== 'object') return null;

  if (concept.stateId || concept.state) {
    return gatherStateItems(state, concept, contract);
  }
  let conceptState = {};
  if (concept.inheritance && concept.inheritance.inherit) {
    // Prepare for filtering out of duplicates
    const tmpDupl = [];

    for (const inhertiedConceptId of concept.inheritance.inherit) {
      const inheritedConcept = Concept.get(contract, inhertiedConceptId);
      let inheritedConceptState = {};
      const inheritedConceptStateId = inheritedConcept.stateId;
      if (!inheritedConceptStateId) {
        if (inheritedConcept.state && typeof inheritedConcept.state === 'object') {
          conceptState = {
            ...conceptState,
            ...stateEntriesWithConceptId(inheritedConcept.state, inhertiedConceptId),
          };
        }
        continue;
      }
      if (!input[inheritedConceptStateId] || ocount(input[inheritedConceptStateId]) === 0) continue;
      if (concept.inheritance.filterDuplicated && !ignoreInheritanceFilter) {
        const inheritedConceptStateKey = inheritedConcept.stateKey;
        const tmpState = inheritedConcept.state || input[inheritedConceptStateId] || {};
        for (const [uid, stateItem] of Object.entries(tmpState)) {
          const stateKeyData = stateItem[inheritedConceptStateKey];
          const stateKeyDataType = typeof stateKeyData;
          if (!stateKeyData || (stateKeyDataType !== 'string' && stateKeyDataType !== 'object')) continue;
          const uniqueField = stateKeyDataType === 'string' ? stateKeyData : stateKeyData.id;
          if (tmpDupl.indexOf(uniqueField) > -1) continue;
          tmpDupl.push(uniqueField);
          inheritedConceptState[uid] = {
            ...stateItem,
            _conceptId: inhertiedConceptId,
          };
        }
      } else {
        inheritedConceptState = stateEntriesWithConceptId(
          inheritedConcept.state || input[inhertiedConceptId] || {},
          inhertiedConceptId
        );
      }

      // Add to the main concept state
      conceptState = { ...conceptState, ...inheritedConceptState };
    }
    if (concept.inheritance.acp) {
    }
  }
  return conceptState;
};

function stateEntriesWithConceptId(stateEntries, conceptId) {
  if (!conceptId) return stateEntries;
  const newEntries = {};
  for (const [uid, entry] of Object.entries(stateEntries)) {
    newEntries[uid] = { ...entry, _conceptId: conceptId };
  }
  return newEntries;
}

function gatherEntities(state, localState, contract) {
  if (!state || !localState || !contract) return [];

  const entities = [];

  for (const [uid, stateItem] of Object.entries(localState)) {
    const { _conceptId } = stateItem;
    if (!_conceptId) {
      console.log('Invalid localState (gatherEntities), no _conceptId ?', stateItem);
      continue;
    }
    const stateItemConcept = Concept.get(contract, _conceptId);
    const { stateKey, stateId: stateItemConceptStateId, state: stateItemConceptState } = stateItemConcept;

    // If a concept has a fixated given state.
    if (stateItemConceptState && stateItemConceptStateId === false) {
      entities.push(stateItem);
      continue;
    }

    if (!stateKey) {
      // console.trace('gatherEntities: No stateKey for stateItem ', { stateItem, stateItemConcept });
      continue;
    }
    const entity = Entity.getFromStateById(state, stateItem[stateKey] && stateItem[stateKey].id);
    if (entity) entities.push({ ...entity, _conceptId });
  }
  return entities;
}

Concept.getConceptEntities = (concept, contract, state, options = {}) => {
  const conceptState = Concept.getConceptState(concept, contract, state, (options = {}));
  if (!conceptState) return null;

  return gatherEntities(state, conceptState, contract);
};

function gatherEntryNames(state, localState, contract) {
  if (!state || !localState || !contract) return [];

  const entities = [];

  for (const [uid, stateItem] of Object.entries(localState)) {
    const { _conceptId } = stateItem;
    if (!_conceptId) {
      console.log('Invalid localState (gatherEntryNames), no _conceptId ?', stateItem);
      continue;
    }
    const stateItemConcept = Concept.get(contract, _conceptId);
    const { stateKey } = stateItemConcept;
    if (!stateKey) {
      // console.trace('gatherEntryNames: No stateKey for stateItem ', { stateItem, stateItemConcept });
      continue;
    }
    let entity;
    const id = stateItem[stateKey] && stateItem[stateKey].id;
    if (id) {
      entity = Entity.getFromStateById(state, stateItem[stateKey] && stateItem[stateKey].id);
    }
    if (entity) entities.push({ id: entity.id, name: Entity.name(entity), state: stateItem, _conceptId });
    else if (stateItemConcept.contractParty) {
      entities.push({ ...stateItem, _conceptId });
    }
  }
  return entities;
}

Concept.getConceptEntryNames = (concept, contract, state, options = {}) => {
  const conceptState = Concept.getConceptState(concept, contract, state, (options = {}));
  if (!conceptState) return null;

  return gatherEntryNames(state, conceptState, contract);
};

Concept.injectConceptInfo = (concept, repeatableStates) => {
  const states = repeatableStates;

  if (!states) return [];
  return omap(states, (item) => ({
    ...item,
    __id: concept.id,
    __stateId: concept.stateId,
    __stateKey: concept.stateKey,
  }));
};

/**
 * Based on a particular update, figure out which concepts are affected and
 * would need to be re-calculated.
 *
 * Arguments:
 * @param {array} contract            contract object
 * @param {string} updatedStateId   Id of the concept having been updated
 * @param {object} options            Options
 *    @param {string} key             A particular key/name of the updated concept state,
 *                                    e.g. `type` if payload path is `property.type`
 *    @param {bool} changedConceptNumber  If the update has caused the number of concept items
 *                                        to have changed (increase or decrease)
 */

Concept.getHeirs = (contract, conceptIds) => {
  const concepts = Contract.getConcepts(contract);
  if (!concepts) return [];
  const heirs = concepts.filter((concept) => {
    if (!concept.inheritance || !concept.inheritance.inherit) return false;
    if (
      concept.inheritance.inherit.some((inheritId) => {
        return conceptIds.includes(inheritId);
      })
    ) {
      return true;
    }
    return false;
  });
  return heirs || [];
};

function affectedConceptsMatch(concepts, changedConceptNumber, key, allowDefinition) {
  if (changedConceptNumber) return concepts;
  if (!key) return [];

  return concepts.filter((concept) => {
    if (concept.stateKey && concept.stateKey === key) {
      // console.log('Add due to affected stateKey');
      return true;
    } else if (allowDefinition && concept.definitionKey && concept.definitionKey === key) {
      // console.log('Add due to affected definitionKey');
      return true;
    }
    return false;
  });
}

function getConceptsForStateId(contract, stateId) {
  const concepts = Contract.getConcepts(contract) || [];
  return concepts.filter((concept) => concept.stateId === stateId);
}

Concept.affectedConcepts = (contract, updatedStateId, options = {}) => {
  const { key, changedConceptNumber, allowDefinition } = options;
  const { completeConceptGrammar: conceptsGrammar } = _ensureConceptGrammar({ contract });

  const updatedConceptsAll = getConceptsForStateId(contract, updatedStateId);
  const updatedConcepts = affectedConceptsMatch(
    updatedConceptsAll,
    changedConceptNumber,
    key,
    allowDefinition
  );
  const updatedConceptsIds = updatedConcepts.map((c) => c.id);

  const affected = [
    ...updatedConceptsIds,
    ...Concept.getHeirs(contract, updatedConceptsIds).map((concept) => concept.id),
  ];

  // If grammar is dependent on the updatedStateId, include depending concept
  for (const conceptName in conceptsGrammar) {
    let isAffected = false;
    if (conceptsGrammar[conceptName].transforms) {
      for (const transform of conceptsGrammar[conceptName].transforms) {
        if (!transform.rule) continue;

        const components = getComponents(transform.rule);
        if (acpRuleComponentMatch(components, updatedStateId, changedConceptNumber, key)) {
          affected.push(conceptName);
          isAffected = true;
          break;
        }
      }
    }
    if (!isAffected && conceptsGrammar[conceptName].countRule) {
      const components = getComponents(conceptsGrammar[conceptName].countRule);
      if (acpRuleComponentMatch(components, updatedStateId, changedConceptNumber, key)) {
        affected.push(conceptName);
      }
    }
  }

  return [...new Set(affected)];
};

function acpRuleComponentMatch(components, updatedStateId, changedConceptNumber, key) {
  for (const [operator, content] of components) {
    if (operator !== 'numof' || !content) continue;

    if (content.card !== updatedStateId) continue;

    if (!content.condition) {
      return !!changedConceptNumber;
    }

    if (changedConceptNumber && !key) {
      return true;
    }

    // Only match if our key (name of inputField) is included within the condition(s)
    if (getConditionVariables(content.condition).includes(key)) {
      return true;
    }
  }
  return false;
}

Concept.handleKey = handleKey;
Concept.getId = (key) => handleKey(key)[1];

export { Concept };
