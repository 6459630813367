function makeFieldData(repeatableName, repeatable, nameKey) {
  return {
    each: { key: nameKey, level: 0 },
    each_item_id: `${repeatable._uid}.${nameKey}`,
    each_item_path: `input.${repeatableName}.${repeatable._uid}.${nameKey}`,
    repeatable_uid: repeatable._uid,
  };
}
function makeValueFieldData(repeatableName, repeatable, gridId, id) {
  return {
    each: { key: `${gridId}.${id}`, level: 0 },
    each_item_id: `${repeatable._uid}.${gridId}.${id}`,
    each_item_path: `input.${repeatableName}.${repeatable._uid}.${gridId}.${id}`,
    repeatable_uid: repeatable._uid,
  };
}

function getRepeatableValue(repeatable, gridId, id) {
  const values = repeatable[gridId];
  if (!values) return '[**]';
  return values[id] || '[**]';
}

export const addGridAction = {
  id: 'addGridAction',
  dependencies: { ordinary: true },
  time: -2,
  match: ({ entries, node }) => {
    if (!node.data || !node.data.grid) return false;
    for (const { field } of entries) {
      if (!field || field.type !== 'grid') continue;
      return node.data.grid.name === field.name;
    }
  },
  handler: function ({ state, entries, matches, api }) {
    for (const entry of entries) {
      const { path, field, isAdded, isRemoved } = entry;
      if (field.type !== 'grid') continue;
      for (const { node, actions } of matches.default) {
        const [child] = node.children;
        // console.log('Matching node ', { node, entry });
        if (isAdded || isRemoved) {
          if (true || child.type === 'paragraph') {
            const lastPath = api.interfaces.InputPaths.getLast(path);
            if (isNaN(parseInt(lastPath))) {
              continue;
            }
            const siblingsPath = api.interfaces.InputPaths.removeLast(path);
            const values = api.utils.engine.getByPath(state, siblingsPath);
            const { settings, name } = field;
            if (!settings) {
              continue;
            }
            const { repeatable, nameKey } = settings;
            const gridId = `grid_${name}`;
            const repeatables = api.utils.engine.getByPath(state, `input.${repeatable}`);
            /* console.log('Test ', {
              path,
              values,
              siblingsPath,
              settings,
              name,
              repeatable,
              repeatables,
              gridId,
            }); */
            if (!repeatables) continue;
            const topRow = this.makeTableRow([
              this.makeTableCell(settings.label),
              ...Object.values(repeatables).map((rep) =>
                this.makeTableCell(
                  this.makeField('item', rep[nameKey], makeFieldData(repeatable, rep, nameKey))
                )
              ),
            ]);
            const dataRows = values.map(({ id, value }) =>
              this.makeTableRow(
                [
                  this.makeTableCell(
                    this.makeField('vari', value || '[**]', { name: `${siblingsPath}.${id}` })
                  ),
                  ...Object.values(repeatables).map((rep) =>
                    this.makeTableCell(
                      this.makeField(
                        'item',
                        getRepeatableValue(rep, gridId, id),
                        makeValueFieldData(repeatable, rep, gridId, id)
                      )
                    )
                  ),
                ],
                true
              )
            );
            const rows = [topRow, ...dataRows];
            const table = {
              type: 'table',
              data: {
                item_id: this.uuid(),
                template_id: 'std_table',
              },
              children: rows,
            };
            /* console.log('Test table ', {
              table,
            }); */
            actions.replaceChildren(table);
          }
        } else if (isRemoved) {
        }
      }
    }
  },
};

export const modifyGridValue = {
  id: 'modifyGridValue',
  dependencies: { ordinary: true },
  time: -1,
  match: ({ state, entries, node, api }) => {
    if (node.variant !== 'vari' || !node.data || !node.data.name) return false;
    for (const { field, path } of entries) {
      const siblingsPath = api.interfaces.InputPaths.removeLast(path);
      const rootPath = api.interfaces.InputPaths.removeLast(siblingsPath);
      const values = api.utils.engine.getByPath(state, siblingsPath);
      if (!values) return false;

      const targetItemPath = `${rootPath}.${values.id}`;
      let itemPath = node.data.name;
      if (itemPath.substr(0, 6) !== 'input.') itemPath = 'input.' + itemPath;
      return targetItemPath === itemPath;
    }
  },
  handler: function ({ state, entries, matches, api }) {
    for (const entry of entries) {
      const { path } = entry;
      for (const { node, actions } of matches.default) {
        const { language } = this;
        const siblingsPath = api.interfaces.InputPaths.removeLast(path);
        const rootPath = api.interfaces.InputPaths.removeLast(siblingsPath);
        const values = api.utils.engine.getByPath(state, siblingsPath);
        if (!values) continue;
        const targetItemPath = `${rootPath}.${values.id}`;
        let itemPath = node.data.name;

        if (itemPath.substr(0, 6) !== 'input.') itemPath = 'input.' + itemPath;
        if (targetItemPath !== itemPath) continue;
        const adjustedValues = this.getGeneralValues(path, values.value, language);
        const nodeValues = this.getNodeValues(node, adjustedValues);
        const value = this.getFinalValue(nodeValues);
        actions.replaceText(value, { setValue: true });
      }
    }
  },
};
