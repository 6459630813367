import { Element, Node, Transforms } from 'slate';

import { accepting, isText } from 'core/types/elements';
import { emptyBlock } from '../helpers';

/* const elementAccept = {
  numbered_list: (editor, node, path) => {
		// Ensure that single child is a listItem
		if(node.children.length === 1 && !isListItem(node.children[0])) {
			Transforms.wrapNodes(editor, createElement('list_item'), {at: [...path, 0]})
		}
	}
} */

function isAccepted(child, elementAccepts = []) {
  return (
    elementAccepts.findIndex(
      (item) => item.type === child.type || (item.type === 'text' && isText(child))
    ) !== -1
  );
}

export const normalizing = (editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElement(node)) {
      const nodeAccepts = accepting[node.type];
      if (nodeAccepts) {
        for (const [child, childPath] of Node.children(editor, path)) {
          if (!isAccepted(child, nodeAccepts)) {
            const childType = isText(child) ? 'text' : child.type;
            console.log(node, 'does not accept', child);

            // Loop through node's accepted types and their - in turn - accepted types
            for (const { type } of nodeAccepts) {
              if (!accepting[type]) {
                console.log('No accepting entries for type', { nodeAccepts, node, child });
                continue;
              }
              if (accepting[type].filter((candidateChild) => candidateChild.type === childType).length > 0) {
                const container = emptyBlock(type, []);
                console.log('Normalize by wrapping ', child, 'in', container);
                return Transforms.wrapNodes(editor, container, { at: childPath });
              }
            }

            // Check to see if any intermediary node would fix it.
          }
        }
      } else {
        console.log('No accepting entry for ', node);
      }
    }
    /* const [node, path] = entry;

		// If the element is a paragraph, ensure it's children are valid.
		if (Element.isElement(node)) {
			if (node.type === "paragraphX") {
				for (const [child, childPath] of Node.children(editor, path)) {
					if (Element.isElement(child) && !editor.isInline(child)) {
						Editor.unwrapNodes(editor, { at: childPath });
						return;
					}
				}
			} else if(Array.isArray(forcedElementStructure[node.type])) {
                for (const [child, childPath] of Node.children(editor, path)) {
                    if(!forcedElementStructure[node.type].includes(child.type)) {
                        Editor.wrapNodes(editor, { type: forcedElementStructure[node.type][0], children: []}, { at: childPath })
                        return;
                    }
                    
				}
            }
		} */

    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };

  return editor;
};
