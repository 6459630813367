import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import IntlMessages from 'util/IntlMessages';

export default function HelpItem({ icon, text, infoHeader, infoContent, className }) {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);

  let textContent = text || null;
  let iconContent = null;

  if (typeof icon === 'string') icon = <i className={`mdi ${icon}`} />;
  else if (icon) iconContent = icon;

  return (
    <>
      <span className={'link' + (className ? ' ' + className : '')} onClick={() => setOpen(true)}>
        {iconContent}
        {textContent}
      </span>
      <Modal
        title={infoHeader}
        visible={open}
        onCancel={close}
        backdrop={'static'}
        size={'large'}
        // className="modal-full"
        footer={
          <>
            <Button key="closebtn" type="secondary" onClick={close}>
              <IntlMessages id="desc.Close" />
            </Button>
          </>
        }
      >
        {infoContent}
      </Modal>
    </>
  );
}
