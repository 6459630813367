import contractDefaults from '../config/contractDefaults';

const DocumentTemplate = {};
// Main
DocumentTemplate.getInfo = (template) => (template && template.info) || {};
DocumentTemplate.getName = (template) => template && template.name;
DocumentTemplate.getDescription = (template) => template && template.description;
DocumentTemplate.getData = (template) => (template && template.data) || {};

// Main data
DocumentTemplate.getContent = (template) => DocumentTemplate.getData(template).content || [];
DocumentTemplate.getCreate = (template) => DocumentTemplate.getData(template).create || {};
DocumentTemplate.getInputs = (template) => DocumentTemplate.getData(template).input || {};
DocumentTemplate.getOutline = (template) => DocumentTemplate.getData(template).outline || {};
DocumentTemplate.getSettings = (template) => DocumentTemplate.getData(template).settings || {};
DocumentTemplate.setSetting = (template, key, value) => {
  if (!template.data.settings) template.data.settings = {};
  template.data.settings[key] = value;
};
DocumentTemplate.getUi = (template) => DocumentTemplate.getData(template).ui || {};

// Outline Data
DocumentTemplate.getType = (template) => DocumentTemplate.getOutline(template).type;
DocumentTemplate.getDocType = (template) => DocumentTemplate.getOutline(template).doc_type;
DocumentTemplate.getIdName = (template) => DocumentTemplate.getOutline(template).id_name;
DocumentTemplate.getLabels = (template) => DocumentTemplate.getOutline(template).labels;
DocumentTemplate.getShortName = (template) => DocumentTemplate.getOutline(template).shortName;

// Settings data
DocumentTemplate.getFormat = (template) => DocumentTemplate.getSettings(template).format || {};
DocumentTemplate.setFormat = (template, key, value) => {
  if (!template.data.settings) template.data.settings = {};
  if (!template.data.settings.format) template.data.settings.format = {};
  template.data.settings.format[key] = value;
};
DocumentTemplate.getFormatScheduleHeaders = (template) => {
  const format = DocumentTemplate.getFormat(template);
  if (!format.headers) return null;
  return format.headers.scheduleHeaders;
};

DocumentTemplate.getDocumentSettings = (template) => DocumentTemplate.getSettings(template).document || {};
DocumentTemplate.getDocumentPageSettings = (template) =>
  DocumentTemplate.getDocumentSettings(template).pages || {};
DocumentTemplate.getDocumentColumnSettings = (template) =>
  DocumentTemplate.getDocumentSettings(template).columns || {};

DocumentTemplate.getPageSize = (template) => DocumentTemplate.getDocumentPageSettings(template).size || null;
DocumentTemplate.getPageOrientation = (template) =>
  DocumentTemplate.getDocumentPageSettings(template).orientation || null;

DocumentTemplate.getDocumentColumnsNumber = (template) =>
  DocumentTemplate.getDocumentColumnSettings(template).number || 1;

DocumentTemplate.getDraftSettings = (template) => DocumentTemplate.getSettings(template).draft || {};

// 'inline' or 'side-by-side'
DocumentTemplate.getDraftMode = (template) => DocumentTemplate.getDraftSettings(template).mode || null;
DocumentTemplate.getSupportsInlineMode = (template) =>
  DocumentTemplate.getDraftMode(template) === 'inline' ||
  DocumentTemplate.getDraftSettings(template).supportsInline ||
  false;

DocumentTemplate.getDraftEditingAllowed = (template) =>
  DocumentTemplate.getDraftSettings(template).defaultAllowEditing || null;

DocumentTemplate.getLanguage = (template) =>
  DocumentTemplate.getSettings(template).language || DocumentTemplate.getDefaultLanguage(template);
DocumentTemplate.getDefaultLanguage = (template) =>
  DocumentTemplate.getSettings(template).defaultLanguage || contractDefaults.language;

// Create data
DocumentTemplate.getBuild = (template) => DocumentTemplate.getCreate(template).build || {};
DocumentTemplate.getOldModules = (template) => DocumentTemplate.getCreate(template).modules || {};
DocumentTemplate.getModules = (template) => DocumentTemplate.getCreate(template).contractModules || {};

DocumentTemplate.getSetup = (template) => DocumentTemplate.getCreate(template).setup || {};
// Build data
DocumentTemplate.getConcepts = (template) => DocumentTemplate.getBuild(template).concepts || [];
DocumentTemplate.getConcept = (template, conceptId) => {
  const concepts = DocumentTemplate.getConcepts(template);
  if (concepts.length === 0) return null;
  return concepts.find((item) => item.id === conceptId) || null;
};
DocumentTemplate.getConceptGrammar = (template) => DocumentTemplate.getBuild(template).conceptGrammar || {};
DocumentTemplate.getConceptVariations = (template) =>
  DocumentTemplate.getBuild(template).conceptVariations || {};
DocumentTemplate.getMapRepeatablesByConcepts = (template) =>
  DocumentTemplate.getBuild(template).mapRepeatablesByConcepts || [];
DocumentTemplate.getRepeatableActivation = (template) =>
  DocumentTemplate.getBuild(template).repeatableActivation || [];
DocumentTemplate.getRepeatablesNumberBindings = (template) =>
  DocumentTemplate.getBuild(template).repeatablesNumberBindings || {};
DocumentTemplate.getInputBindings = (template) => DocumentTemplate.getBuild(template).inputBindings || [];
DocumentTemplate.getSmartDefinitions = (template) =>
  DocumentTemplate.getBuild(template).smartDefinitions || [];
DocumentTemplate.getSmartReplacements = (template) =>
  DocumentTemplate.getBuild(template).smartReplacements || {};

DocumentTemplate.getAnalysisInstructions = (template) => DocumentTemplate.getBuild(template).analysis || {};

DocumentTemplate.getAnalysisModules = (template) => DocumentTemplate.getModules(template).analysis || [];

/* Actions on document template */
// Set content
DocumentTemplate.setContent = (template, value) => (template.data.content = value);
// Generate a document from document template. Also takes setupValues and the relevant project object
DocumentTemplate.generateEmptyDocument = (
  template,
  setupValues = {},
  project = { id: '0', projectTemplateId: '0' }
) => ({
  name: setupValues._doc_name || template.name || 'Untitled',
  description: setupValues._doc_description || template.description || 'No description',
  projectTemplateId: project.projectTemplateId || '0',
  documentTemplateId: template.id,
  data: {
    setup: setupValues,
  },
  info: {
    ...(template.info || {}),
    language: DocumentTemplate.getLanguage(template),
    stats: {}, // Currently not in use
  },
  isSecret: typeof setupValues._doc_isSecret === 'boolean' ? setupValues._doc_isSecret : false,
  projectId: project.id || '0',
  customerId: null,
});

// Used to create a `contract` of a template,
// e.g. for input phase (input->draft contract)
DocumentTemplate.getStandardContractDraftInfo = (values = {}) => ({
  data: {},
  status: {},
  importContractId: null,
  smartReplacementIndices: {},
  smartReplacements: {},
  variables: {},
  _firstTimeDraft: 0,
  ...values,
});

DocumentTemplate.toContract = (template, version, language = contractDefaults.language, setup = {}) => {
  const { input, ...templateData } = template.data;
  return {
    ...version,
    data: {
      ...templateData,
      // _state: {},
      // _draftInfo: DocumentTemplate.getStandardContractDraftInfo({ setup, language }),
    },
  };
};

DocumentTemplate.emptyVersionFromDocument = (document, newValues = {}) => {
  return {
    ...document,
    ...newValues,
    // id: '',
    info: {
      ...(document.info || {}),
      versionNumber: 1,
      comment: 'Draft',
    },
    documentId: document.id,
    seals: {},
    data: {},
  };
};

function _addPathToPages(pages, parents = []) {
  const result = {};
  for (const [id, page] of Object.entries(pages)) {
    result[id] = _addPathToPage(page, id, parents);
  }
  return result;
}
function _addPathToPage(page, id, parents) {
  const newPage = { ...page, paths: [...parents, id], id };
  if (page.pages) {
    newPage.pages = _addPathToPages(page.pages, [...parents, id]);
  }
  return newPage;
}

DocumentTemplate.ensurePagePaths = (template) => {
  const { pages } = template.data.ui;
  const updatedPages = _addPathToPages(pages);
  template.data.ui.pages = updatedPages;
};

DocumentTemplate.toTemplateContract = (template, language = contractDefaults.language) => {
  const { input, ui, ...templateData } = template.data;
  return {
    contract: {
      data: { ...templateData },
      versionInherited: {
        ...template,
        data: null,
      },
    },
  };
};

export { DocumentTemplate };
