import countries from '../config/countries.json';

export const Intl = {
  Country: {
    getName: (code, { fallback = '[**]', language = 'en' }) => {
      const country = countries.find((c) => c.code === code);
      if (!country) return fallback || '[**]';
      if (!country.intl || !country.intl[language]) {
        if (language === 'en') return country.name;
        return fallback;
      }
      return country.intl[language] || fallback;
    },
  },
};
