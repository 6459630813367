import React from 'react';
import { Avatar, Dropdown, Menu, Popconfirm, Tooltip, Button } from 'antd';
import { Link } from 'react-router-dom';
import Widget from 'components/Widget/index';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';

const ProjectWidget = ({ project, projectWasRemoved, type }) => {
  const removeProject = () => {
    api
      .delete('/projects/' + project.id)
      .then((result) => {
        projectWasRemoved(project.id);
      })
      .catch((err) => {
        console.log('Error removing project ', project.id, err.response);
      });
  };

  const intTypeCap = type === 'folder' ? 'Folder' : 'Project';

  const title = <IntlMessages id={'app.resources.' + intTypeCap} />;
  const icon = type === 'folder' ? 'mdi-folder-outline' : 'mdi-cube-outline';

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Link
          to={{
            pathname: '/project/' + (project && project.id ? project.id : '_null'),
          }}
        >
          <IntlMessages id={'app.main.goTo' + intTypeCap} />
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <Popconfirm
          title={'Are you sure delete this ' + type + '?'}
          onConfirm={removeProject}
          okText="Yes"
          cancelText="No"
        >
          <span className="">Remove {title}</span>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <Widget
      styleName="ch-capitalize card-sm-px"
      extra={
        project &&
        !project.isPersonal && (
          <ul className="list-inline ml-auto mb-0 text-grey">
            <li>
              <Dropdown overlay={menu} trigger={['click']}>
                <span className="link ant-dropdown-link text-grey">
                  <i className="mdi mdi-chevron-down" />
                </span>
              </Dropdown>
            </li>
          </ul>
        )
      }
      title={!project || !project.isPersonal ? title : 'Personal Workshop'}
    >
      <div className="text-center">
        {!project || !project.isPersonal ? (
          <i className={'size-60 mb-3 mdi ' + icon + ' ico-lg'} alt="projectlogo" />
        ) : (
          <i className="size-60 mb-3 mdi mdi-home ico-lg" alt="projectlogo" />
        )}

        <h2 className="mb-3 mb-sm-4">{project && project.name}</h2>
        <h5 className="mb-3 mb-sm-4">{project && project.description}</h5>

        <ul className="list-inline mb-3 mb-lg-4 tags">
          <li>
            <Tooltip title="Project">
              <Avatar className="bg-secondary text-white">{title}</Avatar>
            </Tooltip>
          </li>
          {project.isPersonal ? (
            <li>
              <Tooltip title="Your personal project">
                <Avatar className="bg-orange text-white">P</Avatar>
              </Tooltip>
            </li>
          ) : null}
          {project.can_admin && (
            <li>
              <Tooltip title="You are admin">
                <Avatar className="bg-primary text-white">A</Avatar>
              </Tooltip>
            </li>
          )}
        </ul>
        <Link
          to={{
            pathname: '/'+type+'/' + (project && project.id ? project.id : '_null'),
          }}
        >
          <Button type="primary" className="mb-1">
            <IntlMessages id={'app.main.goTo' + intTypeCap} />
          </Button>
        </Link>
      </div>
    </Widget>
  );
};

export default ProjectWidget;
