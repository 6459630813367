import {
  ProjectContext,
  ContractContext,
  DraftContext,
  ContractUpdateContext,
  StudioEditorInputContent,
  ModalContext,
  AlertContext,
  WarningsContext,
  YjsEditorContext,
  YjsStateContext,
  ContractContentContext,
  ContractStateContext,
} from 'contexts/contexts';
import { useUser } from '../auth';
import api from 'utils/api';

const isFetching = {
  project: false,
};

export function useProject(id, opts = {}) {
  const user = useUser();
  const projectContent = ProjectContext.useContext();
  const { force = false, ignoreForEntityUser = false } = opts;
  const currentId = ProjectContext.get('id');
  const goFetch = !currentId || (id && force && currentId !== id);

  if (goFetch && id && !isFetching.project) {
    if (ignoreForEntityUser && user?.isEntityUser) return;
    isFetching.project = true;
    const include = [{ model: 'Event' }];
    const includeQuery = encodeURI(JSON.stringify(include));
    api
      .get(`/projects/${id}?include=${includeQuery}`)
      .then((res) => {
        if (!res || !res.data) return;
        setProject(res.data);
      })
      .catch((err) => {})
      .finally(() => (isFetching.project = false));
  }
  return projectContent;
}
export function setProject(...args) {
  return ProjectContext.setContext(...args);
}
export function getProjectValue(arg) {
  return ProjectContext.get(arg);
}
export function getProjectValues() {
  return ProjectContext.getGlobal();
}

export function useDraft() {
  return DraftContext.useContext();
}
export function setDraft(...args) {
  return DraftContext.setContext(...args);
}
export function getDraftValue(arg) {
  return DraftContext.get(arg);
}
export function getDraftValues() {
  return DraftContext.getGlobal();
}

export function useEngine() {
  const draft = useDraft();
  return (draft && draft.instance) || null;
}

export function useContract() {
  return ContractContext.useContext();
}
export function useContractData() {
  const contract = useContract();
  if (!contract) return null;
  return contract.data || null;
}
export function setContract(...args) {
  return ContractContext.setContext(...args);
}
export function setContractContent(value) {
  // Get the existing contract
  const contract = getContractValues();
  if (!contract) {
    console.log('Has no contract...');
    return;
  }

  // Create a new contract
  const newContract = {
    ...contract,
    data: { ...contract.data, content: value },
  };

  // Also update the content in the current draft
  const draft = getDraftValues();
  draft.contract = newContract;

  // Set the new contract context
  ContractContext.setContext(newContract);
  return;
}
export function getContractUi() {
  return ContractContext.getGlobal()?.data?.ui;
}
export function getContractValue(arg) {
  return ContractContext.get(arg);
}
export function getContractValues() {
  return ContractContext.getGlobal();
}

export function useContractUpdates() {
  return ContractUpdateContext.useContext();
}
export function setContractUpdates(...args) {
  return ContractUpdateContext.setContext(...args);
}
export function addContractUpdates(...args) {
  return ContractUpdateContext.addContext(...args);
}
export function getContractUpdatesValue(arg) {
  return ContractUpdateContext.get(arg);
}
export function getContractUpdatesValues() {
  return ContractUpdateContext.getGlobal();
}

export function useStudioEditorInputContent() {
  return StudioEditorInputContent.useContext();
}
export function setStudioEditorInputContent(...args) {
  return StudioEditorInputContent.setContext(...args);
}
export function addStudioEditorInputContent(...args) {
  return StudioEditorInputContent.addContext(...args);
}
export function getStudioEditorInputContentValue(arg) {
  return StudioEditorInputContent.get(arg);
}
export function getStudioEditorInputContentValues() {
  return StudioEditorInputContent.getGlobal();
}

export function useModalContext() {
  return ModalContext.useContext();
}
export function setModalContext(...args) {
  return ModalContext.setContext(...args);
}
export function addModalContext(...args) {
  return ModalContext.addContext(...args);
}
export function getModalContextValue(arg) {
  return ModalContext.get(arg);
}
export function getModalContextValues() {
  return ModalContext.getGlobal();
}

export function useAlertContext() {
  return AlertContext.useContext();
}
export function setAlertContext(...args) {
  return AlertContext.setContext(...args);
}
export function addAlertContext(...args) {
  return AlertContext.addContext(...args);
}
export function getAlertContextValue(arg) {
  return AlertContext.get(arg);
}
export function getAlertContextValues() {
  return ModalContext.getGlobal();
}

export function useWarnings() {
  return WarningsContext.useContext();
}
export function setWarnings(...args) {
  return WarningsContext.setContext(...args);
}
export function addWarnings(...args) {
  return WarningsContext.addContext(...args);
}
export function getWarningsValue(arg) {
  return WarningsContext.get(arg);
}
export function getWarningsValues() {
  return WarningsContext.getGlobal();
}

export function useYjsEditor() {
  return YjsEditorContext.useContext();
}

export function useYjsState() {
  return YjsStateContext.useContext();
}

export function useContractContent() {
  return ContractContentContext.useContext();
}

export function useContractState() {
  return ContractStateContext.useContext();
}
