import { Node } from '../../../../import/slate';

export const getDefinitionName = (para, options = {}) => {
  if (!para || para.type !== 'paragraph' || !para.children) return null;

  const { lowercase = false } = options;

  const str = Node.string(para);
  if (!str.startsWith('"')) return;
  const parts = str.split('"');
  if (parts.length === 1) return null;
  return lowercase ? parts[1].toLocaleLowerCase() : parts[1];
};
