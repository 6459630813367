// Customizer const
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Common Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SET_NUMBER = 'COMMON_SET_NUMBER';
export const SET_NUMBERS = 'COMMON_SET_NUMBERS';

// Draft cnsts
export const RELEASE_ENGINE_SESSION = 'RELEASE_ENGINE_SESSION';
export const FIX_DRAFT = 'FIX_DRAFT';
export const RESET_DRAFT = 'RESET_DRAFT';
export const SET_DRAFT = 'SET_DRAFT';
export const SET_DRAFT_VALUES = 'SET_DRAFT_VALUES';
export const DRAFT_EDITOR_ALLOW_EDITING = 'DRAFT_EDITOR_ALLOW_EDITING';

//Auth const
export const AUTH_OVERLAY = 'AUTH_OVERLAY';
export const AUTH_SET_CLIENT = 'AUTH_SET_CLIENT';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const SET_USER = 'SET_USER';
export const INIT_URL = 'INIT_URL';

// ENTITIES CONSTANTS
export const BULK_UPSERT_ENTITIES = 'ENTITIES_BULK_UPSERT_ENTITIES';
export const UPDATE_ENTITY = 'ENTITIES_UPDATE_ENTITY';
export const INSERT_ENTITY = 'ENTITIES_INSERT_ENTITY';
export const REMOVE_ENTITY = 'ENTITIES_REMOVE_ENTITY';
export const SET_ENTITIES = 'ENTITIES_SET_ENTITIES';

// INPUT CONSTANTS
export const ADD_REPEATABLE = 'INPUT_ADD_REPEATABLE';
export const REMOVE_REPEATABLE = 'INPUT_REMOVE_REPEATABLE';
export const ADD_ORDINARY_CARD = 'INPUT_ADD_ORDINARY_CARD';
export const REMOVE_ORDINARY_CARD = 'INPUT_REMOVE_ORDINARY_CARD';
export const UPDATE_INPUT = 'INPUT_UPDATE_INPUT';
export const UPDATE_INPUTS = 'INPUT_UPDATE_INPUTS';
export const SET_CONNECT_INPUT_CARDS = 'INPUT_SET_CONNECT_INPUT_CARDS';
export const REMOVE_INPUT = 'INPUT_REMOVE_INPUT';

export const FIX_INPUT = 'INPUT_FIX_INPUT';
export const SET_INPUT = 'INPUT_SET_INPUT';
export const INSERT_NESTED_ITEM = 'INPUT_INSERT_NESTED_ITEM';
export const REMOVE_NESTED_ITEM = 'INPUT_REMOVE_NESTED_ITEM';
export const REMOVE_INPUT_REPEATABLE = 'REMOVE_INPUT_REPEATABLE';
export const MERGE_INPUTS = 'INPUT_MERGE_INPUTS';
export const NEW_CARD = 'INPUT_NEW_CARD';
export const SET_INPUT_DATA = 'INPUT_SET_INPUT_DATA';
export const SET_INPUT_ITEM_DATA = 'INPUT_SET_INPUT_ITEM_DATA';
export const ADD_MULTIPLEBLOCK_ITEM = 'INPUT_ADD_MULTIPLEBLOCK_ITEM';
export const REMOVE_MULTIPLEBLOCK_ITEM = 'INPUT_REMOVE_MULTIPLEBLOCK_ITEM';
export const ADD_QA = 'INPUT_ADD_QA';
export const REMOVE_QA = 'INPUT_REMOVE_QA';
export const UPDATE_QA = 'INPUT_UPDATE_QA';
export const ADD_GRID_ROW = 'INPUT_ADD_GRID_ROW';
export const REMOVE_GRID_ROW = 'INPUT_ADD_GRID_ROW';

// TASKS CONSTANTS
export const SET_TASKS = 'TASKS_SET_TASKS';
export const UPDATE_TASK = 'TASKS_UPDATE_TASK';
export const DELETE_TASK = 'TASKS_DELETE_TASK';

// CONTRACT CONVERSATION CONSTANS
export const SET_CONVERSATIONS = 'CONVERSATIONS_SET_CONVERSATIONS';
export const ADD_CONVERSATION = 'CONVERSATIONS_ADD_CONVERSATION';
export const DELETE_CONVERSATION = 'CONVERSATIONS_DELETE_CONVERSATION';

export const ADD_CONVERSATION_POST = 'CONVERSATION_POSTS_ADD_CONVERSATION_POST';
export const DELETE_CONVERSATION_POST = 'CONVERSATION_POSTS_DELETE_CONVERSATION_POST';
export const UPDATE_CONVERSATION_POST = 'CONVERSATION_POSTS_UPDATE_CONVERSATION_POST';
export const SET_CONVERSATION_COMPLETED = 'CONVERSATION_SET_CONVERSATION_COMPLETED';

// CONTRACT DATA CONSTANS
export const SET_CONTRACT_DATA_RULES = 'SET_CONTRACT_DATA_RULES';
export const SET_CONTRACT_DATA_RULE = 'SET_CONTRACT_DATA_RULE';
export const REMOVE_CONTRACT_DATA_RULE = 'REMOVE_CONTRACT_DATA_RULE';
export const SET_CONTRACT_DATA_VARIABLES = 'SET_CONTRACT_DATA_VARIABLES';
export const SET_CONTRACT_DATA_VARIABLE = 'SET_CONTRACT_DATA_VARIABLE';
export const REMOVE_CONTRACT_DATA_VARIABLE = 'REMOVE_CONTRACT_DATA_VARIABLES';

// REMOTE UPDATE STATE CONSTANTS
export const REMOTE_APPLY_STATE = 'REMOTE_REMOTE_APPLY_STATE';
