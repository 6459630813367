import { Contract } from '../../../interfaces';

export const getAffectedACPkeys = function (entries) {
  let keys = [];

  // Get the relevant ACP keys depending on the dependency that invoked this flow

  const concepts = Contract.getConcepts(this.contract);

  for (const entry of entries) {
    const { cardId, fieldName, value, pathInvoked } = entry;
    if (!cardId) continue;
    if (pathInvoked.repeatableAdd || pathInvoked.repeatableRemove) {
      const repeatable = cardId;
      // Add the relevant repeatable and it's corresponding input fields
      const repeatableInputs = getRepeatableInputs(this.contract, repeatable, value);

      keys.push(repeatable);

      // Add any concept which is dependent on the added/removed repeatable
      for (const concept of concepts) {
        if (
          concept.id &&
          concept.inheritance &&
          concept.inheritance.inherit &&
          concept.inheritance.inherit.includes(repeatable)
        ) {
          keys.push(concept.id);
          if (pathInvoked.repeatableChange && fieldName)
            keys = keys.concat([concept.id + '.' + fieldName, concept.id + './.' + fieldName]);
          if (pathInvoked.repeatableAdd || pathInvoked.repeatableRemove)
            keys = keys.concat(repeatableInputs.map((inputName) => concept.id + '.' + inputName));
        }
      }
    }
    if (pathInvoked.repeatableChange) {
      if (!fieldName) {
        console.log('No field name for entry ', entry);
      }
      keys.push(cardId + '.' + fieldName, cardId + './.' + fieldName, '$_local_$' + fieldName);
      for (const concept of concepts) {
        if (
          concept.id &&
          concept.inheritance &&
          concept.inheritance.inherit &&
          concept.inheritance.inherit.includes(cardId)
        ) {
          keys.push(concept.id + '.' + fieldName, concept.id + './.' + fieldName);
        }
      }
    }
    if (pathInvoked.ordinary) {
      keys = [cardId];
      keys.push(cardId + '.' + fieldName);
    }

    if (pathInvoked.entityAny) {
      keys.push('entities');
    }
  }

  return [...new Set(keys)];
};

function getRepeatableInputs(contract, repeatable, repeatableValues) {
  const inputCard = Contract.getUiCard(contract, repeatable);
  if (inputCard) return Object.keys(inputCard.values);
  if (!repeatableValues) return [];
  return Object.keys(repeatableValues).filter((field) => field.substr(0, 1) !== '_');
}
