import React from 'react';
import { useSelector } from "react-redux"

function InputInfo(props) {
    const { field, language } = props;
	
		const { text } = field

    const infoFields = text[language] ? text[language].split("$").filter((item,index) => index %2) : null
		const infoValues = useSelector(state => {
			if (!infoFields) return null
			if (!state.input.__info_fields) return null
			return infoFields.reduce((acc, infoField) => {
				acc[infoField] = state.input.__info_fields[infoField]
				return acc
			}, {})
		})

    let valueFieldsArr = field.text[language].split("$");
    const valueFields = valueFieldsArr.map((item, inputIndex) => {
      if (inputIndex % 2) {
        // If value within $$
        return infoValues && infoValues[item]
          ? infoValues[item]
          : "[**]";
      }
      return item;
    });
    const values = valueFields.join("");
    return (
      <div className="m-2 p-1 border">
        <small className="">{values}</small>
      </div>
    );
  }
  
export default InputInfo;