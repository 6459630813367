import { find } from '../../utils';

/*************************** SHARED REPEATABLE EFFECTS ******************************/
/************************************************************************************/

export const populateEachContent = function (repeatableName, content, values, state, entry, opts = {}) {
  // Mutations OK. They are made to the newly parse'd content obj.

  if (!repeatableName) return this.warn('No repeatable Name');

  if (typeof repeatableName === 'object') {
    this.trace('Repeatable name is object?');
  }

  const { _uid } = values;

  for (const item of find(content, (n) => n && n.variant === 'item' && n.data && n.data.each && n.data.each.key)) {
    const key = item.data.each.key;
    const itemPath = entry.path + '.' + key;

    item.data.repeatable_uid = _uid;
    item.data.each_item_id = _uid + '.' + key;
    item.data.each_item_path = itemPath;

    if (Array.isArray(item.data.each.transforms)) {
      for (const transform of item.data.each.transforms) {
        this.log('Transform ', { transform });
        if (!transform.key || !transform.where) continue;
        /*
        {
          "op": "plus",
          "key": "property/existingMN",
          "where": "$_local_$"
        }
        */
        if (transform.where === '$_local_$') {
          if (Array.isArray(item.data.each_item_extra_paths))
            item.data.each_item_extra_paths.push(entry.path + '.' + transform.key);
          else item.data.each_item_extra_paths = [entry.path + '.' + transform.key];
        } else {
          if (Array.isArray(item.data.each_item_extra_paths))
            item.data.each_item_extra_paths.push(transform.key);
          else item.data.each_item_extra_paths = [transform.key];
        }
      }
    }
  }

  const groups = find(content, (n) => n && n.data && n.variant === 'group');
  for (const group of groups) {
    group.data.parent_repeatable = repeatableName;
    group.data.parent_repeatable_uid = values._uid;
    group.data.parent_repeatable_path = entry.path;
    // this.log('Set up group.', {group: JSON.parse(JSON.stringify(group))})
  }

  const entities = find(content, (n) => n && n.data && n.variant === 'entity');
  for (const entity of entities) {
    entity.data.repeatable_uid = _uid;
  }

  const questionsAndAnswers = find(content, (n) => n && n.data && n.data.qa && typeof n.data.qa === 'object' && n.data.qa.fieldPathData);
  for (const qaItem of questionsAndAnswers) {
    const { cardId, fieldName } = qaItem.data.qa.fieldPathData
    if (!cardId || !fieldName) {
      console.log('No cardId or fieldName for qa')
      continue
    }
    qaItem.data.qa.fieldPath = `input.${cardId}.${_uid}.${fieldName}`
    // entity.data.repeatable_uid = _uid;
  }

};
