import { Form, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Label } from '.';
import { validateEmail } from '../../../../utils/validation';

export function CollectEmails({ required = true, compact, small, ...rest }) {
  const formatMessage = useIntlMessage();
  const wrapperCol = compact ? { span: 24 } : undefined;

  return (
    <Form.Item
      {...rest}
      wrapperCol={wrapperCol}
      label={
        !compact && (
          <Label>
            <IntlMessages id="app.persons.emails" cap />
          </Label>
        )
      }
      extra={
        compact && (
          <Label>
            <IntlMessages id="app.persons.emails" cap />
          </Label>
        )
      }
      name="emails"
      tooltip={{
        title: <IntlMessages id="app.persons.emailsTooltip" />,
        icon: <InfoCircleOutlined />,
      }}
      rules={[
        {
          required,
          message: <IntlMessages id="app.persons.validation.emailRequired" />,
        },
        {
          validator: async (_, values) => {
            if (!required && !values) return Promise.resolve();
            if (!values) throw new Error(formatMessage('app.persons.validation.emailFormat'));
            values.forEach((email) => {
              if (!validateEmail(email)) throw new Error(formatMessage('app.persons.validation.emailFormat'));
            });
          },
        },
      ]}
    >
      <Select mode="tags" />
    </Form.Item>
  );
}
