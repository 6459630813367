import { Skeleton, Card, Space } from 'antd';
import IntlMessages from 'util/IntlMessages';

const LoadButton = () => (
  <div className="mb-2">
    <Skeleton.Button active size="large" shape="round" block />
  </div>
);

export const HeaderSkeleton = () => (
  <div className="w-25 p-3 mb-4">
    <Skeleton active avatar paragraph={{ rows: 2 }} size="sm" />
  </div>
);

export const HeroSkeleton = ({ title }) => (
  <Card className="widget dark-bg" title={title}>
    <div className="bg-white d-flex pt-3">
      <div
        className="d-flex flex-column justify-content-center align-items-center border mx-3"
        style={{ width: '220px', height: 320, marginBottom: 22 }}
      >
        <ImageSkeleton />
      </div>
    </div>
  </Card>
);

export const CardSkeleton = ({ title }) => (
  <Card className="widget light-container" title={title}>
    <Skeleton active paragraph={{ rows: 5 }} />
  </Card>
);

export const PrimaryActionsSkeleton = () => (
  <Card title={<IntlMessages id="app.general.Actions" cap />} className="widget">
    <LoadButton />
    <LoadButton />
    <LoadButton />
  </Card>
);

export const ImageSkeleton = () => <Skeleton.Image active />;

export const PrimaryActionsSkeletonX = () => (
  <div className="mb-2">
    <Skeleton.Button active size="large" shape="round" block />
  </div>
);

export const SecondaryActionsSkeleton = ({ title }) => (
  <Card className="widget bg-transparent" title={title}>
    <Space size="large" className='flex-column'>
      <Skeleton.Button active size="large" shape="round" />
      <Skeleton.Button active size="large" shape="round" />
      <Skeleton.Button active size="large" shape="round" />
      <Skeleton.Button active size="large" shape="round" />
    </Space>
  </Card>
);

export const DarkContainerListSkeleton = ({ title }) => (
  <Card className="dark-container widget" title={title}>
    <BasicSkeleton />
  </Card>
);

export const BasicSkeleton = () => <Skeleton active avatar />;

export const InputSkeleton = ({ size = 'default' }) => <Skeleton.Input size={size} active />;
