import { Tag } from 'antd';
import { useIntlMessage } from 'util/IntlMessages';
import { STATUS_COLORS } from 'config';

export default function StatusTag({ status, translate = true, style = {}, icon = null, withColor, ghost }) {
  const formater = useIntlMessage();

  const text = formater('status.' + (status || 'unknown')).toLocaleUpperCase();

  let color = withColor ? STATUS_COLORS[status] : ghost ? '' : 'gray';

  const className = ghost ? 'ghost' : ''

  return (
    <Tag className={className} color={color} style={style} icon={icon}>
      {text}
    </Tag>
  );
}
