import React from 'react';
import { useDispatch } from 'react-redux';
import { Table, Button, Row, Col, notification } from 'antd';
import { setEntities } from 'appRedux/actions';
import updateStudioDrafts from 'utils/saving/updateStudioDrafts';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

const { Column } = Table;

const OutdatedStructure = ({ data, onClose }) => {
  const dispatch = useDispatch();
  const formatMessage = useIntlMessage();

  if (!data || !Array.isArray(data.diffList)) return null;

  // Need id for rowKey
  const diffList = data.diffList.map((diff, idx) => {
    return {
      ...diff,
      id: idx,
    };
  });

  const tableConfig = {
    bordered: false,
    loading: false,
    pagination: false,
    size: 'default',
    expandedRowRender: false,
    title: undefined,
    showHeader: true,
    footer: false,
    scroll: undefined,
  };

  const updateContract = () => {
    dispatch(setEntities(data.latestStructure));
    data.clearWarning();
    updateStudioDrafts();

    notification.info({
      message: formatMessage('studio.warnings.structure.saved.header'),
      description: formatMessage('studio.warnings.structure.saved.description'),
      duration: 5,
    });

    onClose();
  };

  return (
    <div>
      <span>
        <IntlMessages id={'studio.warnings.structure.description'} />
      </span>
      <br />
      <br />
      <Table rowKey="id" className="table-responsive" {...tableConfig} dataSource={diffList}>
        <Column
          title={<IntlMessages id={'studio.warnings.structure.typeOfConflict'} />}
          key="type"
          render={({ type }) => <IntlMessages id={`studio.warnings.structure.conflictTypes.${type}`} />}
        />
        <Column
          title={<IntlMessages id={'studio.warnings.structure.companyName'} />}
          key="name"
          render={({ currentNode, latestNode }) => {
            return (currentNode && currentNode.name) || (latestNode && latestNode.name);
          }}
        />
        <Column
          title={<IntlMessages id={'studio.warnings.structure.changes'} />}
          key="change"
          render={({ type, difference }) => {
            if (type !== 'changed') return null;
            // console.log('DIFF: ', difference);
            const rows = [];
            Object.entries(difference).forEach(([key, value]) => {
              let oldValue = '-';
              let newValue = '-';
              if (!['Owners', 'Entities'].includes(key)) {
                oldValue = value.__old;
                newValue = value.__new;
              }

              rows.push(
                <Row key={key}>
                  <Col xl={8} lg={8} md={8} sm={8} xs={8} className="audi-col">
                    <IntlMessages id={`studio.warnings.structure.types.${key}`} />
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={8} xs={8} className="audi-col">
                    {oldValue}
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={8} xs={8} className="audi-col">
                    {newValue}
                  </Col>
                </Row>
              );
            });

            return (
              <>
                <Row key="changedHeader">
                  <Col xl={8} lg={8} md={8} sm={8} xs={8} className="audi-col">
                    <strong>
                      <IntlMessages id={'studio.warnings.structure.attribute'} />
                    </strong>
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={8} xs={8} className="audi-col">
                    <strong>
                      <IntlMessages id={'studio.warnings.structure.currentValue'} />
                    </strong>
                  </Col>
                  <Col xl={8} lg={8} md={8} sm={8} xs={8} className="audi-col">
                    <strong>
                      <IntlMessages id={'studio.warnings.structure.latestValue'} />
                    </strong>
                  </Col>
                </Row>
                {rows}
              </>
            );
          }}
        />
      </Table>
      <br />
      <span>
        <IntlMessages id={'studio.warnings.structure.updateText'} />
      </span>
      <br />
      <br />
      <Button type="primary" onClick={updateContract}>
        <IntlMessages id={'studio.warnings.structure.buttonText'} />
      </Button>
    </div>
  );
};

export default OutdatedStructure;
