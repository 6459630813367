export const engineVariablesHandlerSlate = {
  id: 'engineVariablesSlate',
  dependencies: { state: ['*'] },
  time: 1,
  preMatch: ({ engine }) => {
    return !!engine._tmpDraft.changedVariables.size;
  },
  match: ({ engine, node }) => {
    if (node.variant !== 'vari' || !node.data) return;
    return engine._tmpDraft.changedVariables.has(node.data.name);
  },
  handler: function ({ matches }) {
    for (const { node, actions } of matches.default) {
      const value = this.getVariable(node.data.name);
      actions.replaceText(value, { setValue: true });
    }
  },
};
