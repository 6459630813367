import {
  SET_CONTRACT_DATA_RULES,
  SET_CONTRACT_DATA_RULE,
  REMOVE_CONTRACT_DATA_RULE,
  SET_CONTRACT_DATA_VARIABLES,
  SET_CONTRACT_DATA_VARIABLE,
  REMOVE_CONTRACT_DATA_VARIABLE,
} from 'constants/ActionTypes';

export const setContractDataRules = (data) => {
  if (!data || typeof data !== 'object') {
    console.warn('Invalid arguments to setContractDataRules', { data });
    return { type: '__NOP' };
  }
  return {
    type: SET_CONTRACT_DATA_RULES,
    payload: data,
  };
};

export const setContractDataRule = (key, value) => {
  if (typeof key !== 'string' || typeof value !== 'boolean') {
    console.warn('Invalid arguments to setContractDataRule', { key, value });
    return { type: '__NOP' };
  }
  return {
    type: SET_CONTRACT_DATA_RULE,
    payload: { key, value },
  };
};

export const removeContractDataRule = (key) => {
  if (typeof key !== 'string') {
    console.warn('Invalid argument to removeContractDataRule', { key });
    return { type: '__NOP' };
  }
  return {
    type: REMOVE_CONTRACT_DATA_RULE,
    payload: key,
  };
};

export const setContractDataVariables = (data) => {
  if (!data || typeof data !== 'object') {
    console.warn('Invalid arguments to setContractDataVariables', { data });
    return { type: '__NOP' };
  }
  return {
    type: SET_CONTRACT_DATA_VARIABLES,
    payload: data,
  };
};

export const setContractDataVariable = (key, value) => {
  if (typeof key !== 'string' || typeof value !== 'boolean') {
    console.warn('Invalid arguments to setContractDataVariable', { key, value });
    return { type: '__NOP' };
  }
  return {
    type: SET_CONTRACT_DATA_VARIABLE,
    payload: { key, value },
  };
};

export const removeContractDataVariable = (key) => {
  if (typeof key !== 'string') {
    console.warn('Invalid argument to removeContractDataVariable', { key });
    return { type: '__NOP' };
  }
  return {
    type: REMOVE_CONTRACT_DATA_VARIABLE,
    payload: key,
  };
};
