import { imp_and, imp_or, arrayUnique, ocount } from '../../../utils/general';
import { translateText } from '../../../utils/language';
import { Concept, Entity, Contract } from '../../../interfaces';
import { v4 as isUuidV4 } from '../../../../import/is-uuid';

export const mapCompaniesToConcepts = ({
  companies,
  contract,
  state,
  /* concepts,
  languageAnd,
  languageOr,
  language, */
}) => {
  /* 
    We need the following:
    - companies, 
    - contract
    - state
    */
  const self = this;

  const concepts = Contract.getConcepts(contract);
  const language = Contract.getLanguage(contract);
  const languageAnd = translateText('and', language, true) || 'and';
  const languageOr = translateText('or', language, true) || 'or';

  if (!companies || !state || !concepts) {
    return {};
  }

  if (typeof companies === 'string') companies = [companies];
  if (!Array.isArray(companies) || !Array.isArray(concepts)) {
    console.log('Invalid arguments to mapCompaniesToConcepts; expects companies and concepts of type array');
    return {};
  }

  let companiesFlat;
  const matches = {};

  // Flatten companies, and set the length
  companiesFlat = companies
    .map((company) => {
      if (!company) return null;
      if (typeof company === 'string') return company;
      if (typeof company === 'object' && company.id) return company.id;
    })
    .filter((company) => !!company);
  if (companiesFlat.length < companies.length) {
    console.trace('mapCompaniesToConcept could not resolve all companies from arguments', {
      companies,
      companiesFlat,
    });
  }

  if (companiesFlat[0] && typeof companiesFlat[0] === 'string') companiesFlat = arrayUnique(companiesFlat);
  else {
    console.trace('Invalid companies argument to mapCompaniesToConcepts', companiesFlat);
    return {};
  }
  const uniqueCompaniesLength = companiesFlat.length;
  const companyData = {};

  for (const concept of concepts) {
    const conceptEntries = Concept.getConceptEntryNames(concept, contract, state);
    // console.log('Concept entry names ', {conceptEntries, id: concept.id})
    let matchingConceptItems = 0;
    const conceptEntriesLength = Object.keys(conceptEntries).length;
    for (const entry of conceptEntries) {
      // console.log('Check ', {entry, companiesFlat})
      for (const company of companiesFlat) {
        if (company === entry.name || company === entry.id) {
          if (!companyData[company]) companyData[company] = [];
          companyData[company].push({ concept, entry: entry, person: !!entry.id });
        }
      }

      if (companiesFlat.indexOf(entry.name) === -1 && companiesFlat.indexOf(entry.id) === -1) {
        // console.log('No match for entry ', {entry})
        // If a company does not match the concept, we cannot refer to that concept.
        continue;
      }

      matchingConceptItems++;
    }

    // console.log('Concept state ', concept.id, {conceptEntries, matchingConceptItems, companyData});

    if (matchingConceptItems === 0) continue;
    // console.log('Matching items ', {matchingConceptItems, id: concept.id, conceptEntriesLength, uniqueCompaniesLength})
    // If conceptEntries is empty or contains more than the provided
    // companies, they cannot be referred to that concept.
    if (
      conceptEntriesLength === 0 ||
      conceptEntriesLength > matchingConceptItems /* uniqueCompaniesLength */
    ) {
      continue;
    }

    const numeris = matchingConceptItems > 1 ? 'pluralFixed' : 'singularFixed';
    const { value, match: grammarMatch } = Concept.grammar(concept.id, numeris, language, { contract });
    const matchDescriptionText = grammarMatch ? value : '[**]';

    // We are going towards a match.
    const match = {
      concept,
      text: matchDescriptionText,
    };
    // All companies are within the relevant state entry, but there
    // could still be more companies than state items, in which case
    // we have extra, and shall not yet return a positive match.
    // Let's check.
    if (conceptEntriesLength < uniqueCompaniesLength) {
      matches[concept.stateId] = { match, additionalCompanies: [] };
      // console.log('Check additional ' , concept.id , { conceptEntriesLength, uniqueCompaniesLength })
      for (const company of companiesFlat) {
        if (
          !stateItemMatchesCompany({
            state,
            conceptEntries,
            company,
          })
        ) {
          let additionalCompanyName;
          if (isUuidV4(company)) {
            const additionalEntity = Entity.getFromStateById(state, company);
            if (!additionalEntity) additionalCompanyName = '[unknown entity]';
            else additionalCompanyName = additionalEntity.name;
          } else {
            additionalCompanyName = company;
          }
          matches[concept.stateId].additionalCompanies.push(additionalCompanyName);
        }
      }
      // No matches at all for this concept
      if (matches[concept.stateId].additionalCompanies.length === uniqueCompaniesLength) {
        delete matches[concept.stateId];
      }
    } else {
      // Return the perfect match.
      return {
        ...match,
        perfectMatch: true,
        match: true,
        result: [match.text],
        fullText: match.text,
        fullText_and: match.text,
        fullText_or: match.text,
        names: companiesFlat,
        meta: { returnLevel: 1 },
      };
    }
  }
  // Return null if no matches even close.
  if (ocount(matches) === 0) {
    const descriptiveCompanies = getDescriptiveCompanies(companiesFlat, companyData);
    // console.log('No matches? ', {descriptiveCompanies, companyData, companiesFlat})
    return {
      concept: null,
      perfectMatch: false,
      match: false,
      fullText: descriptiveCompanies.join(', '),
      fullText_and: imp_and(descriptiveCompanies, languageAnd),
      fullText_or: imp_or(descriptiveCompanies, languageOr),
      result: descriptiveCompanies,
      names: companiesFlat,
      text: false,
      additionalCompanies: companiesFlat,
      meta: { returnLevel: 2 },
    };
  }

  // Find the stateId with fewest additional companies
  // This would typically be the best match.
  let closestMatchingStateId;
  let tmpNumberOfAdditionalCompanies;
  // console.log('matches are ? ', matches)
  for (const stateId in matches) {
    if (typeof tmpNumberOfAdditionalCompanies === 'undefined') {
      closestMatchingStateId = stateId;
      tmpNumberOfAdditionalCompanies = matches[stateId].additionalCompanies.length;
      continue;
    }
    if (matches[stateId].additionalCompanies.length < tmpNumberOfAdditionalCompanies)
      closestMatchingStateId = stateId;
  }
  if (typeof closestMatchingStateId === 'undefined') {
    return {};
  }
  const finalAdditionalCompanies = matches[closestMatchingStateId].additionalCompanies;
  const text = matches[closestMatchingStateId].match.text;
  const result = [text].concat(finalAdditionalCompanies);

  const descriptiveCompanies = getDescriptiveCompanies(result, companyData);

  return {
    ...matches[closestMatchingStateId].match,
    perfectMatch: false,
    match: true,
    result: descriptiveCompanies,
    fullText: descriptiveCompanies.join(', '),
    fullText_and: imp_and(descriptiveCompanies, languageAnd),
    fullText_or: imp_or(descriptiveCompanies, languageOr),
    names: companiesFlat,
    additionalCompanies: finalAdditionalCompanies,
    meta: { returnLevel: 3 },
  };
};

function getDescriptiveCompanies(names, companyData) {
  // console.log('Get Descr ', { names, companyData });
  return names.map((company) => {
    if (!Array.isArray(companyData[company])) return company;
    for (const { concept, entry } of companyData[company]) {
      if (!entry || !entry.state || !concept) continue;
      if (
        concept.definitionKey &&
        typeof entry.state[concept.definitionKey] === 'string' &&
        entry.state[concept.definitionKey].length > 0
      )
        return entry.state[concept.definitionKey];
      else {
        return entry.name;
      }
    }
    return company;
  });
}

function stateItemMatchesCompany({ state, conceptEntries, company }) {
  for (const entry of conceptEntries) {
    if (entry.name === company || entry.id === company) return true;
  }
  return false;
}
