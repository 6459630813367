import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Input } from 'antd';
import { useIntlMessage } from 'util/IntlMessages';
import { SearchOutlined } from '@ant-design/icons';

function searchFilter(item, fields, searchValue, startsWith) {
  for (const field of fields) {
    if (!item[field]) continue;
    if (startsWith) {
      if (item[field].toLocaleLowerCase().indexOf(searchValue) === 0) return true;
    } else {
      if (item[field].toLocaleLowerCase().indexOf(searchValue) !== -1) return true;
    }
  }
}

/**
 * Use Seach
 * Option for returning either [ (updated collection matching search), (element with search bar) ]
 * OR [ (updated collection matching search), search, onSearch, resetSearch ]
 * @param {array} collection
 * @param {obj} opts
 */

export function useSearch(collection, opts = {}) {
  const {
    element = false,
    mapper,
    fields = ['name'],
    subKey,
    subFields = ['name'],
    startsWith = false,
  } = opts;
  const searchRef = useRef(null);
  const [search, setSearch] = useState('');
  const onSearch = useCallback((evt) => setSearch(evt.target.value), []);

  useEffect(() => {
    if (!searchRef.current) return;
    searchRef.current.focus();
  }, [search]);

  const resetSearch = useCallback(() => {
    setSearch('');
  }, []);

  const searchValue = search.toLocaleLowerCase();
  const displayCollection = useMemo(() => {
    if (!collection) return;
    if (searchValue === '') return collection;
    let result;

    if (!subKey) {
      result = collection.filter((item) => searchFilter(item, fields, searchValue, startsWith));
    } else {
      const matchingTopItems = collection.filter((item) =>
        searchFilter(item, fields, searchValue, startsWith)
      );
      result = collection
        .map((topLevel) => {
          if (matchingTopItems.includes(topLevel)) {
            return topLevel;
          }
          const subItems = topLevel[subKey];
          if (!subItems) return null;
          return {
            ...topLevel,
            [subKey]: subItems.filter((subItem) => searchFilter(subItem, subFields, searchValue, startsWith)),
          };
        })
        .filter((item) => !!item && item[subKey].length > 0);
    }

    if (mapper) return result.map((item) => (mapper ? mapper(item) : item));
    return result;
  }, [collection, searchValue, startsWith, mapper, fields, subKey, subFields]);

  const formatMessage = useIntlMessage();

  if (!displayCollection) return [[], search, onSearch, resetSearch];

  if (!element) return [displayCollection, search, onSearch, resetSearch];

  const reactElement = (
    <div className="position-relative">
      <Input
        ref={searchRef}
        prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        placeholder={formatMessage('desc.Search') + '...'}
        value={searchValue}
        onChange={onSearch}
        allowClear
      />
    </div>
  );

  return [displayCollection, reactElement];
}

/* result = collection

    if (subKey) {
      result = result
        .map((topLevel) => {
          const subItems = topLevel[subKey];
          if (!subItems) return null;
          return {
            ...topLevel,
            [subKey]: subItems.filter((subItem) => searchFilter(subItem, subFields, searchValue, startsWith)),
          };
        })
        .filter((item) => !!item && item[subKey].length > 0);

      // result = result = collection.filter((item) => searchFilter(item, fields, searchValue, startsWith));
    } */
