import { useState, useEffect, useCallback } from 'react';

export const useIsSidebarMini = () => {
  const [isMini, setIsMini] = useState(false);
  const toggleMini = useCallback(() => {
    setIsMini(!isMini);
  }, [isMini, setIsMini]);

  useEffect(() => {
    window.addEventListener('menu_mini', toggleMini);
    return () => {
      window.removeEventListener('menu_mini', toggleMini);
    };
  }, [toggleMini]);

  return isMini;
};
