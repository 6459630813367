import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Row, Col, Divider, Checkbox, Spin, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { useMountFetch, useFetch } from '../../../hooks/api';
import api from 'utils/api';
import { smallGutter } from 'config';
import { LoadingOutlined } from '@ant-design/icons';

function getUrl(resourceType, resourceId) {
  if (!resourceType || !resourceId) return '';
  return `/eventsubscribers/my?resourceType=${resourceType}&resourceId=${resourceId}`;
}

function getAvailableEventKeys(subscriptionEventInfo, resourceType) {
  return [
    ...new Set([
      ...(subscriptionEventInfo?.GENERAL_EVENT_KEYS || []),
      ...(subscriptionEventInfo?.RESOURCE_EVENT_KEYS?.[resourceType] || []),
    ]),
  ];
}

export default function EventSubscriptions({
  resourceType,
  resourceId,
  ownerResourcesData,
  extraResource,
  extraResourceIntlId,
  resourceIntlId,
  isExtra,
  sameViewSubscriptions,
  initialSubscriptions,
  updateLength,
}) {
  const hasInitialSubscriptions = !!initialSubscriptions;
  const [subscriptions, setSubscriptions] = useState(initialSubscriptions || null);
  const [pendingAdding, setPendingAdding] = useState([]);
  const subscriptionsRef = useRef(initialSubscriptions || null);
  const pendingAddingRef = useRef([]);
  const user = useSelector(({ auth }) => auth.user);

  const updateSubscriptions = useCallback(
    (upd) => {
      if (!upd) return;
      setSubscriptions(upd);
      subscriptionsRef.current = upd;
      updateLength(upd.length);
    },
    [updateLength]
  );
  const updatePending = useCallback((upd) => {
    setPendingAdding(upd);
    pendingAddingRef.current = upd;
  }, []);

  useFetch(hasInitialSubscriptions ? '' : getUrl(resourceType, resourceId), updateSubscriptions);
  const [subscriptionEventInfo] = useMountFetch('/eventsubscribers/types');

  const getSubscription = (targetResourceType, eventKey) => {
    return (
      subscriptions &&
      subscriptions.find((sub) => sub.targetResourceType === targetResourceType && sub.eventKey === eventKey)
    );
  };

  const isAdding = (targetResourceType, eventKey) => {
    const comboString = `${targetResourceType}_${eventKey}`;
    return pendingAdding.includes(comboString);
  };

  const onAddSubscription = useCallback(
    async (values) => {
      const entityType = 'Entity';
      const { entityId } = user;

      const comboString = Object.values(values).join('_');
      updatePending([...pendingAddingRef.current, comboString]);

      const data = {
        ...values,
        resourceType,
        resourceId,
        entityType,
        entityId,
      };
      try {
        const newSub = await api.post('/eventsubscribers', data);
        if (newSub && newSub.data) {
          updatePending(pendingAddingRef.current.filter((str) => str !== comboString));
          updateSubscriptions([...subscriptionsRef.current, newSub.data]);
        } else {
          console.log('No subscription response...');
        }
      } catch (err) {
        console.log('Could not add subscription ', err);
      }
    },
    [resourceId, resourceType, updatePending, updateSubscriptions, user]
  );

  const onRemoveSubscription = useCallback(
    async (id) => {
      try {
        const removal = await api.delete('/eventsubscribers/' + id);
        if (removal && removal.data && removal.data.status === 'OK') {
          updateSubscriptions(subscriptionsRef.current.filter((sub) => sub.id !== id));
        }
      } catch (err) {
        console.log('Error removing subscription ', err);
      }
    },
    [updateSubscriptions]
  );

  const availableTargetResourceTypes = useMemo(
    () => [
      resourceType,
      ...(Array.isArray(subscriptionEventInfo?.RESOURCE_KEYS_COVER?.[resourceType])
        ? subscriptionEventInfo?.RESOURCE_KEYS_COVER?.[resourceType].map((covered) => covered.resourceType)
        : []),
    ],
    [resourceType, subscriptionEventInfo?.RESOURCE_KEYS_COVER]
  );

  const hasMultipleSections = availableTargetResourceTypes.length > 1;

  if (!subscriptionEventInfo) return null;

  return (
    <>
      <div className="flex-column flex-column">
        {availableTargetResourceTypes.map((availableTargetType) => (
          <ResourceSection
            key={availableTargetType}
            ownerResourceType={resourceType}
            sectionTargetType={availableTargetType}
            showHeader={hasMultipleSections || isExtra}
            isAdding={isAdding}
            getSubscription={getSubscription}
            subscriptionEventInfo={subscriptionEventInfo}
            onAddSubscription={onAddSubscription}
            onRemoveSubscription={onRemoveSubscription}
            ownerResourcesData={ownerResourcesData}
            sameViewSubscriptions={sameViewSubscriptions}
            isExtra={isExtra}
            resourceIntlId={resourceIntlId}
          />
        ))}
        {extraResource && (
          <EventSubscriptions
            {...extraResource}
            resourceIntlId={extraResourceIntlId}
            ownerResourcesData={ownerResourcesData}
            sameViewSubscriptions={subscriptions}
            isExtra
          />
        )}
      </div>
    </>
  );
}

function getParentSubscriptions(ownerResourceType, ownerResourceId, sectionTargetType) {
  if (!ownerResourceType || !ownerResourceId || !sectionTargetType) return '';
  return `/eventsubscribers/my?resourceType=${ownerResourceType}&resourceId=${ownerResourceId}&targetResourceType=${sectionTargetType}`;
}

function Title({ isSubResource, resourceIntlId, sectionTargetType, ownerResourceType, extraResourceIntlId }) {
  if (resourceIntlId) {
    return <IntlMessages id={resourceIntlId} cap />;
  }
  if (!isSubResource) {
    return <IntlMessages id={`general.this${sectionTargetType}`} cap />;
  }
  return (
    <>
      <IntlMessages id="desc.all" cap /> <IntlMessages id={`general.${sectionTargetType}s`} />{' '}
      <IntlMessages id="desc.in" /> <IntlMessages id={`general.this${ownerResourceType}`} />
    </>
  );
}

function ResourceSection({
  ownerResourceType,
  sectionTargetType,
  isExtra,
  ownerResourcesData,
  resourceIntlId,
  showHeader,
  isAdding,
  getSubscription,
  subscriptionEventInfo,
  onAddSubscription,
  onRemoveSubscription,
  sameViewSubscriptions,
}) {
  const [parentSubscribers, setParentSubscribers] = useState(null);
  const [fixedParentSubscribers, setFixedParentSubscribers] = useState(null);
  const isSubResource = sectionTargetType !== ownerResourceType;

  useEffect(() => {
    (async () => {
      if (!ownerResourcesData) return;
      if (!subscriptionEventInfo?.RESOURCE_KEYS_COVER_REVERSE?.[sectionTargetType]) return;
      const parentTypes = Object.keys(subscriptionEventInfo.RESOURCE_KEYS_COVER_REVERSE[sectionTargetType]);
      const urls = parentTypes
        .map((t) => getParentSubscriptions(t, ownerResourcesData[t], sectionTargetType))
        .filter((url) => !!url);
      const promises = urls.map((url) => api.get(url));
      const responses = await Promise.allSettled(promises);
      setFixedParentSubscribers(
        responses
          .map((r) => r?.value?.data)
          .filter((d) => !!d)
          .flat()
      );
    })();
  }, [ownerResourcesData, sectionTargetType, subscriptionEventInfo.RESOURCE_KEYS_COVER_REVERSE]);

  useEffect(() => {
    setParentSubscribers([
      ...(Array.isArray(fixedParentSubscribers) ? fixedParentSubscribers : []),
      ...(Array.isArray(sameViewSubscriptions) ? sameViewSubscriptions : []),
    ]);
  }, [sameViewSubscriptions, fixedParentSubscribers]);

  const getParentSubscriber = (targetResourceType, eventKey) => {
    if (!parentSubscribers) return null;
    const ps = parentSubscribers.find(
      (ps) => ps.targetResourceType === targetResourceType && ps.eventKey === eventKey
    );
    if (!ps) return null;
    return ps.resourceType;
  };

  return (
    <div key={sectionTargetType} className="mb-3">
      {showHeader && (
        <Divider orientation="left">
          <Title
            isSubResource={isSubResource}
            isExtra={isExtra}
            sectionTargetType={sectionTargetType}
            ownerResourceType={ownerResourceType}
            resourceIntlId={resourceIntlId}
          />
        </Divider>
      )}
      <Row gutter={smallGutter}>
        {getAvailableEventKeys(subscriptionEventInfo, sectionTargetType).map((eventKey) => {
          const subscription = getSubscription(sectionTargetType, eventKey);
          const adding = isAdding(sectionTargetType, eventKey);
          const parentSubscriber = getParentSubscriber(sectionTargetType, eventKey);

          let content = null;
          if (adding) {
            content = (
              <div className="d-flex">
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />}
                  style={{
                    margin: '0 10px 0 0',
                  }}
                />
                <IntlMessages id={`app.events.types.${eventKey}`} cap />
              </div>
            );
          } else {
            content = (
              <Checkbox
                checked={!!subscription}
                disabled={!!parentSubscriber}
                onChange={(evt) =>
                  !!subscription
                    ? onRemoveSubscription(subscription.id)
                    : onAddSubscription({ targetResourceType: sectionTargetType, eventKey })
                }
              >
                <IntlMessages id={`app.events.types.${eventKey}`} cap />
              </Checkbox>
            );
          }
          if (!adding && !!parentSubscriber)
            content = (
              <Tooltip
                title={
                  <>
                    Already subscibed by <IntlMessages id={`app.resources.${parentSubscriber}`} />
                  </>
                }
              >
                {content}
              </Tooltip>
            );

          return (
            <Col key={eventKey} md={4} sm={8}>
              {content}
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
