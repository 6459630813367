import { default as uuidFn } from 'uuid-random';
import { Contract } from '../../interfaces';

/**
 * uuid: Generates a unique id.
 *
 * @return {string}                     Returns a (somewhat) unique id.
 */
export function uuid() {
  let id = uuidFn();
  if (this._nodeEntriesList) {
    while (this._nodeEntriesList.has(id)) id = uuidFn();
  }
  return id;
}

/**
 * num2str: Converts a number to string using the 'numberText' array.
 *
 * @param {number}      num             Number to be transformed, e.g. '5'.
 * @return {string}                     Returns a string e.g. 'five'.
 */
export const num2str = function (num) {
  const { numberText } = Contract.getFormat(this.contract);
  if (!numberText || !numberText[this.language] || !numberText[this.language][num]) return num;
  return numberText[this.language][num];
};

/**
 * num2strnum: Converts a number to string and append the number using the 'numberText' array.
 *
 * @param {number}      num             Number to be transformed, e.g. '5'.
 * @return {string}                     Returns a string e.g. 'five (5)'.
 */
export const num2strnum = function (num) {
  const { numberText } = Contract.getFormat(this.contract);
  if (!numberText || !numberText[this.language] || !numberText[this.language][num]) return num;
  return numberText[this.language][num] + ' (' + num + ')';
};

/**
 * getRepeatableByUid: Find the block item for a specific uid. Regarding uids, see system state.
 *
 * @param {string}      uid             Unique uid for a block.
 * @return {object}                     Returns the object containing the item info.
 */
export const getRepeatableByUid = function (uid) {
  if (!this.repeatables || !this.repeatables.data) return false;
  for (const repeatable in this.repeatables.data) {
    if (!this.repeatables.data[repeatable].data) continue;
    for (const repeatableItem of this.repeatables.data[repeatable].data) {
      if (repeatableItem._uid === uid) return repeatableItem;
    }
  }
  return false;
};
