import React, { useRef, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIsTemplateStudio, useIsStudioWriter, useContract, forceContractUpdatesHighlight } from 'hooks';
import StudioInput from '../../components/StudioInput';
import StudioEditor from '../../components/StudioEditor';
import ConnectedState from '../../../../yjs/State/ConnectedState';
import BottomRightActions from '../../components/BottomRightActions';

export default memo(function StudioContainer(props) {
  const {
    activeFlatUiPages,
    flatUiPages,
    activePagePaths,
    studio_draftMode,
    documentPageSize,
    documentPageOrientation,
    documentColumnSettings,
  } = props;
  const contract = useContract();
  const contractFocus = useSelector(({ draft }) => draft.contractFocus);
  const contract_placement = useSelector(({ draft }) => draft.split_contract_placement);
  const contractPreviewContainer = useRef(null);
  const studioContainerRef = useRef(null);
  const isStudioWriter = useIsStudioWriter();
  const isTemplate = useIsTemplateStudio();
  const regularContractType = studio_draftMode !== 'inline';
  const { number: columnsNumber = 1, ignoreFrontPage: columnsIgnoreFrontPage = false } =
    documentColumnSettings;

  const showInputForms = isTemplate || (regularContractType && isStudioWriter);

  useEffect(() => {
    const elem = document;

    function onScroll() {
      forceContractUpdatesHighlight.highlight();
    }

    elem.addEventListener('scroll', onScroll);
    return () => {
      elem.removeEventListener('scroll', onScroll);
    };
  }, [studioContainerRef]);

  return (
    <>
      <div
        id="studio-container"
        className={`studio-container position-relative ${
          isTemplate ? ' template-studio' : ''
        } ${contract_placement} ${contractFocus ? 'contract-focus' : ''}`}
        ref={studioContainerRef}
      >
        <ConnectedState versionId={contract.id}>
          <div
            id="preview-column"
            className={`studio-contract-live input-mode page-size-${documentPageSize} page-orientation-${documentPageOrientation}
          doc-columns-${columnsNumber}
          doc-columns-${columnsNumber > 1 ? 'multiple' : 'none'}
          doc-columns-firstpage-${!columnsIgnoreFrontPage}
          `}
            ref={contractPreviewContainer}
          >
            <BottomRightActions />
            <StudioEditor />
          </div>
          <div style={{ width: '15px' }}></div>
          {showInputForms && (
            <StudioInput
              activeFlatUiPages={activeFlatUiPages}
              flatUiPages={flatUiPages}
              activePagePaths={activePagePaths}
            />
          )}
        </ConnectedState>
      </div>
    </>
  );
});
