import { useState, useEffect, useCallback } from 'react';
import api from 'utils/api';

export function useDownloadPdfBackend({ versionId, documentId }) {
  const [loadingPDF, setLoading] = useState(false);
  const [downloadName, setDownloadName] = useState('Untitled');

  useEffect(() => {
    if (!versionId && !documentId) return;
    async function fetchDownloadName() {
      try {
        let path = '/files/downloadName?';
        if (versionId) path += `versionId=${versionId}`;
        else if (documentId) path += `documentId=${documentId}`;
        else return;
        const fetchedName = await api.get(path);
        if (fetchedName.data && fetchedName.data.name) setDownloadName(fetchedName.data.name);
      } catch (err) {
        console.log('Failed fetching version download name', err.response);
      }
    }
    fetchDownloadName();
  }, [documentId, versionId]);

  const exporter = useCallback(() => {
    api({
      url: '/files/pdf',
      method: 'POST',
      data: {
        versionId,
        documentId,
      },
      responseType: 'blob', // important
    })
      .then((response) => {
        if (response.data.size === 0) {
          console.log('File not found', response);
          return;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', downloadName + '.pdf');
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((err) => {
        console.log('Download PDF err', err, err.response);
      });
  }, [versionId, documentId, downloadName]);

  const initDownloadPDF = useCallback(() => {
    setLoading(true);
    setTimeout(() => {
      exporter();
    }, 0);
  }, [exporter]);

  return { initDownloadPDF, loadingPDF };
}
