import { Button, Tooltip } from 'antd';
import { useYjsEditor } from 'hooks';
import { CloudOutlined } from '@ant-design/icons';

export function ConnectionToggle({ allowDisconnect }) {
  const { onlineMode, connected, toggleConnection } = useYjsEditor();
  if (!onlineMode) return null;

  const allowToggle = !connected ? true : allowDisconnect;
  const activeColor = allowToggle ? 'var(--link-color)' : 'var(--container-bg-color)'
  const style = {
    pointerEvents: allowToggle ? undefined : 'none',
    color: connected ? activeColor : 'var(--danger)'
  }

  return (
    <Tooltip
      position="bottom"
      title={
        connected ? (
          'Connected'
        ) : (
          <div>
            <div>Disconnected</div>
            <div>Click to reconnect</div>
          </div>
        )
      }
    >
      <div className="d-flex align-items-center" style={style}>
        <Button
          size="large"
          onClick={(e) => allowToggle && toggleConnection(e)}
          className={`position-relative d-flex justify-content-center align-items-center m-0`}
          icon={<CloudOutlined style={style} className="fs-xl" />}
        >
          <small className="ml-2" style={style}>{connected ? 'Online' : 'Offline'}</small>
        </Button>
      </div>
    </Tooltip>
  );
}
