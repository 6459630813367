import { Editor, Transforms } from '../../../../../import/slate';
import { getReferenceUpdates } from '../../../utils';

export function updateReferences() {
  const { editor, contract } = this;
  const referenceData = getReferenceUpdates(editor, contract);
  if (!referenceData) {
    return console.log('No reference data');
  }

  const { updateRefPaths, tocs } = referenceData;

  Editor.withoutNormalizing(editor, () => {
    for (const [path, text] of updateRefPaths) {
      Transforms.insertText(editor, text, { at: path, voids: true });
    }
    for (const [path, node, data] of tocs) {
      if (data.tocContent) {
        this.api.slate.extra.replaceChildren(editor, path, data.tocContent);
        if (node.data._inActive) {
          Transforms.setNodes(
            editor,
            { data: { ...node.data, _inActive: false } },
            { at: path, voids: true }
          );
        }
      } else if (data.inActive && !node.data._inActive) {
        Transforms.setNodes(editor, { data: { ...node.data, _inActive: true } }, { at: path, voids: true });
      }
    }
  });
}
