import { useState } from 'react';
import { Row, Col, Form, Select, Button, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import { ContactEntryAddress, ContactEntryPhone, Label } from '.';

const { Option } = Select;

export function ContactEntry({
  entryName,
  entryFieldKey,
  type: initialType,
  small = false,
  remove,
  ...rest
}) {
  const [type, setType] = useState(initialType);
  const [open, setOpen] = useState(true);

  const toggleOpen = () => setOpen(!open);

  // Email is handled seprately.
  if (type === 'EMAIL') {
    return null;
  }

  let content;
  switch (type) {
    case 'ADDRESS':
      content = (
        <ContactEntryAddress entryName={entryName} entryFieldKey={entryFieldKey} small={small} {...rest} />
      );
      break;
    case 'PHONE':
      content = (
        <ContactEntryPhone entryName={entryName} entryFieldKey={entryFieldKey} small={small} {...rest} />
      );
      break;
    default:
      content = null;
  }

  if (type === 'EMAIL') return null;
  return (
    <div style={{ display: 'flex', width: '100%' }} className={`${entryName !== 0 ? 'mt-2' : ''}`}>
      <div style={{ flexGrow: 1 }}>
        <div className="border">
          <div className="d-flex justify-content-space-between p-1">
            <div>
              <h6 className="d-inline-block">{type}</h6>
              <small className="link ml-2" onClick={toggleOpen}>
                <IntlMessages id={`app.general.${open ? 'Hide' : 'Show'}`} />
              </small>
            </div>
            <div>
              <Tooltip title={'Remove'} placement="top">
                <Button
                  className="m-0 mx-1"
                  type="danger"
                  onClick={() => remove(entryName)}
                  size="small"
                  icon={<CloseOutlined />}
                />
              </Tooltip>
            </div>
          </div>
          {open ? (
            <>
              <Row>
                <Col sm={12} lg={8}>
                  <Form.Item
                    {...rest}
                    label={
                      <Label small={small}>
                        <IntlMessages id="app.general.Type" />
                      </Label>
                    }
                    name={[entryName, 'type']}
                    fieldKey={[entryFieldKey, 'type']}
                    rules={[{ required: true, message: 'Missing type' }]}
                  >
                    <Select onChange={setType}>
                      <Option value="ADDRESS">
                        <IntlMessages id="app.general.address" cap />
                      </Option>
                      <Option value="PHONE">
                        <IntlMessages id="app.general.phone" cap />
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                {content}
              </Row>
            </>
          ) : (
            <div className="pl-3">. . .</div>
          )}
        </div>
      </div>
    </div>
  );
}
