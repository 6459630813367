import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMountFetch, useGotoDraft } from 'hooks';
// Forward to relevant place.

function useTargetLink(invitation) {
  const history = useHistory();
  const versionId = invitation?.versionId;
  const gotoDraft = useGotoDraft(versionId, { target: 'version', replace: true });

  if (!invitation) return () => {};

  const { data } = invitation;
  const { target, projectId, documentId } = data;
  switch (target) {
    case 'Project':
      return () => history.replace(`/project/${projectId}`);
    case 'Document':
      return () => history.replace(`/project/${projectId}/document/${documentId}`);
    case 'Version':
      console.log('yes vers ', gotoDraft);
      return gotoDraft;
    default:
      return () => {};
  }
}

export default function InvitedBase({ location }) {
  const urlParams = new URLSearchParams(location.search);
  const invitationId = urlParams.get('id');

  const [invitation] = useMountFetch(`/entityinvitations/${invitationId}`);
  const goto = useTargetLink(invitation);

  useEffect(() => {
    if (typeof goto === 'function') goto();
  }, [goto]);

  return <div className="h-100 w-100 d-flex align-items-center justify-content-center">Loading . . .</div>;
}
