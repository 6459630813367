export const genesis = async function (states, opts = {}) {
  // During genesis and first run on the engine, we
  // may mutate values directly as no updates need to
  // be detected.
  this.transformBeforeMutate = false;

  if (!this.editor.unrestrictedApply) this.editor.unrestrictedApply = async (fn) => fn();

  const stateChanges = this.handleNewStates(states);
  await this.invokeHandlers(states, stateChanges, { isGenesis: true });

  console.log(' === GENESIS COMPLETE === ');

  // Run the entire engine with the new state.
  await this.finishDraft(states);

  // Inactivate mutation, allowing immutable updates from here on.
  this.transformBeforeMutate = true;
};
