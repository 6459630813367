import React, { useEffect, useState, memo, useCallback } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ProjectHeader from '../../components/project/ProjectHeader/index';
import { useProject } from 'hooks';
import api from 'utils/api';
import Document from './Document';
import Overview from './Overview/index';

const Project = memo(({ projectId, projectUrl }) => {
  const project = useProject(projectId, { force: true });
  const type = 'Project';

  const [documents, setDocuments] = useState(null);

  const addDocument = useCallback(
    (doc, direction = 'first') => {
      if (direction !== 'first') {
        setDocuments([...documents, doc]);
      } else {
        setDocuments([doc, ...documents]);
      }
    },
    [documents, setDocuments]
  );

  const generateSetDocumentFn = useCallback(
    (id) => (data) => {
      setDocuments(
        documents.map((doc) => {
          if (doc.id !== id) return doc;
          return { ...doc, ...data };
        })
      );
    },
    [documents]
  );

  useEffect(() => {
    if (!projectId) return;
    const include = [{ model: 'Event' }];
    const includeQuery = encodeURI(JSON.stringify(include));
    api
      .get(
        `/projects/${projectId}/documents?fields=id,name,description,projectId,status,activeVersionId,finalVersionId,createdAt,updatedAt&order=${encodeURI(
          'desc|createdAt'
        )}&include=${includeQuery}`
      )
      .then((res) => {
        if (!res.data) return;
        setDocuments(res.data);
      })
      .catch((err) => {
        console.log('Error fetching project documents ', err);
      });
  }, [projectId]);

  return (
    <>
      <ProjectHeader project={project} />
      <div className="project-main-holder">
        <Switch>
          <Route
            path={`${projectUrl}/document/:documentId/version/:versionId`}
            render={({
              match: {
                url,
                params: { documentId, versionId },
              },
            }) => (
              <Document
                project={project}
                documentId={documentId}
                documentUrl={url}
                document={documents && documents.find((d) => d.id === documentId)}
                setDocument={generateSetDocumentFn(documentId)}
                defaultSelectedVersionId={versionId}
              />
            )}
          />
          <Route
            path={`${projectUrl}/document/:documentId`}
            render={({
              match: {
                url,
                params: { documentId },
              },
            }) => (
              <Document
                project={project}
                documentId={documentId}
                documentUrl={url}
                document={documents && documents.find((d) => d.id === documentId)}
                setDocument={generateSetDocumentFn(documentId)}
              />
            )}
          />
          <Route path={`${projectUrl}`} exact={true}>
            <Redirect to={`${projectUrl}/overview`} />
          </Route>
          <Route
            path={`${projectUrl}`}
            render={() => (
              <Overview
                project={project}
                type={type}
                documents={documents}
                setDocuments={setDocuments}
                addDocument={addDocument}
              />
            )}
          />
        </Switch>
      </div>
    </>
  );
});

export default Project;
