import React from 'react';
import { List, Avatar, Skeleton } from 'antd';
import Icon, { QuestionCircleFilled } from '@ant-design/icons';
import { HelpItem } from 'components/ui';

export default function ListItem({
  title,
  desc,
  mdiIcon,
  icon,
  onClick,
  loading = false,
  loadingActions = [],
  bgColor = 'primary',
  iconColor = 'white',
  infoHeader = null,
  infoContent = null,
}) {
  let itemIcon;
  if (mdiIcon) itemIcon = <Icon component={() => <i className={`mdi ${mdiIcon}`} />} />;
  else if (icon) itemIcon = icon;
  else itemIcon = null;

  const content = (
    <>
      <List.Item.Meta
        className="clickable"
        onClick={onClick}
        avatar={
          <Avatar
            className={`d-flex align-items-center justify-content-center bg-${bgColor} icon-${iconColor}`}
            icon={itemIcon}
          />
        }
        title={<span>{title}</span>}
      />
      <small>{desc}</small>
    </>
  );

  if (loading) {
    return (
      <List.Item actions={loadingActions}>
        <Skeleton avatar title={1} loading={true} active paragraph={0}>
          {content}
        </Skeleton>
      </List.Item>
    );
  } else {
    const actions =
      infoHeader && infoContent
        ? [<HelpItem icon={<QuestionCircleFilled />} infoHeader={infoHeader} infoContent={infoContent} />]
        : null;
    return (
      <>
        <List.Item actions={actions} className="highlightable info-actions">
          {content}
        </List.Item>
      </>
    );
  }
}
