import findIndex from 'find-index/findIndex';
import { Contract, InputPaths } from '../../../interfaces';
import { mergeObj } from '../../../utils/general';
import { isText } from '../../../types/elements';
import { Element } from '../../../../import/slate';
import { v4 as isUuidV4 } from '../../../../import/is-uuid';

export const validStates = (states) => states && states.current && states.previous;

export const checkElementValid = function (node, handler, parent) {
  if (isText(node)) return true;
  if (!Element.isElement(node)) {
    console.log('Node is not an element', {
      node: JSON.parse(JSON.stringify(node)),
      parent: JSON.parse(JSON.stringify(parent)),
      handler,
    });
    return false;
  }
  return true;
};

export const uniqueStateChanges = function (changeData, contract) {
  let paths = [];

  for (const path of changeData.touched) {
    // We could receive an empty repeatable path, e.g. "input.borrower"
    // where the state `input.borrower` is empty, i.e.: {}.
    // If so, do not include it as that would otherwise be interpreted
    // as an 'repeatableAdd' event.
    if (InputPaths.getRepeatable(contract, path) && !InputPaths.getRepeatablePath(contract, path)) {
      // console.log('Skip ', path)
      continue;
    }

    if (path.includes('._meta')) continue;

    const closestRepeatable = InputPaths.getRepeatablePath(contract, path);
    // If a repeatable was added or removed, we only record the path of that repeatable.
    // Any sub paths of the repeatable (such as the `type` of "input.repeatable.2.type"),
    // are handled by the relevant handlers and do not need to be included in the unique paths.
    /* if (closestRepeatable && changeData.info[closestRepeatable] === "added") {
      if (!paths.includes(closestRepeatable)) paths.push(closestRepeatable);
    } else if (closestRepeatable && changeData.info[closestRepeatable] === "removed") {
      if (!paths.includes(closestRepeatable)) paths.push(closestRepeatable);
    } else {
      paths.push(path);
    } */

    if (closestRepeatable && changeData.info[closestRepeatable] === 'removed') {
      if (!paths.includes(closestRepeatable)) paths.push(closestRepeatable);
    } else {
      paths.push(path);
    }
  }
  return clearPreceedingItems(paths, changeData);
};

function clearPreceedingItems(paths, changeData) {
  const newPaths = [...paths];

  for (const path of paths) {
    clearPreceedingOfItem(path, newPaths, { changeData });
  }

  return newPaths;
}

function clearPreceedingOfItem(path, paths, options = {}) {
  const { changeData } = options;
  const parts = path.split('.');
  let pathEntries = '';
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];

    // Remove any preceeding item which is only modified.
    if (changeData && changeData.info && changeData.info[pathEntries] === 'modified') {
      const indexPathEntryInPaths = findIndex(paths, (item) => item === pathEntries);
      if (indexPathEntryInPaths > -1) {
        paths.splice(indexPathEntryInPaths, 1);
      }
    }
    if (i === 0) pathEntries += part;
    else pathEntries += '.' + part;
  }

  if (options.clearCurrent) {
    const indexInPaths = findIndex(paths, (item) => item === path);
    if (indexInPaths > -1) {
      paths.splice(indexInPaths, 1);
    }
  }

  return paths;
}

/**
 * Summarise event data for handlers. Provides an object containing the path and the entry.
 *
 * @param {string} path
 * @param {object} pathInvoked
 * @param {object} changesData
 * @param {object} contract
 * @param {object} options
 */
function getPathActionData(path, pathInvoked, changesData, contract, handler, options = {}) {
  const actionData = {
    entry: {
      path,
      pathInvoked,
      ...InputPaths.getCardAndField(contract, path),
      field: Contract.getUiInputFieldDataByPath(contract, path),
      value: changesData.values[path],
      isAdded: changesData.added.includes(path),
      isModified: changesData.modified.includes(path),
      isRemoved: changesData.removed.includes(path),
    },
    path,
  };

  if (options.includePreviousValue) {
    actionData.entry.previousValue = changesData.previousValues[path];
  }

  return actionData;
}

function createGenesisActions(handlers, uniqueChanges) {
  return handlers
    .filter((handler) => handler.genesisHandler)
    .map((handler) => ({
      handler,
      handlerInvoked: { onGenesis: true },
      entries: [],
      paths: [...uniqueChanges],
    }));
}

export function generateHandlersActions(handlers, stateChanges, contract, options = {}) {
  const { changesData, uniqueChanges } = stateChanges;
  const { filter, isGenesis } = options;
  let actions = [];

  if (isGenesis) return createGenesisActions(handlers, uniqueChanges);

  function addSingleEntry(path, actions, handler, invoked, changesData) {
    const pathActionData = getPathActionData(path, invoked, changesData, contract, handler, options);
    if (!pathActionData) return;
    actions.push({
      handler,
      handlerInvoked: invoked,
      ...pathActionData,
    });
  }

  for (const handler of handlers) {
    if (filter) {
      if (!filter(handler)) continue;
    }

    const { dependencies, mode = 'multiple' } = handler;

    if (!dependencies || typeof dependencies !== 'object') continue;

    let invoked = { state: {} };
    let entries = [],
      paths = [];

    // If subscribed to entire state, always invoke.
    if (dependencies.state && dependencies.state.includes('*')) {
      invoked.state.all = true;
      if (mode === 'single') {
        for (const path of uniqueChanges) {
          addSingleEntry(path, actions, handler, invoked, changesData);
        }
      } else {
        const entries = [];
        for (const path of uniqueChanges) {
          const pathActionData = getPathActionData(path, invoked, changesData, contract, handler, options);
          if (!pathActionData) continue;
          const { entry } = pathActionData;
          entries.push(entry);
        }
        actions.push({
          handler,
          handlerInvoked: invoked,
          entries,
          paths: [...uniqueChanges],
        });
      }
      // If a handler has subscribed to all of state, no need to check
      // individual dependencies. As the handler was just added (see above),
      // just continue to the next handler.
      continue;
    }

    if (mode === 'single') {
      for (const path of uniqueChanges) {
        const invoked = pathMatchesDependencies(path, changesData, dependencies, contract, options);
        if (!invoked) continue; // No dependency matched. Continue.
        addSingleEntry(path, actions, handler, invoked, changesData);
      }
    } else if (mode === 'multiple') {
      for (const path of uniqueChanges) {
        const pathInvoked = pathMatchesDependencies(path, changesData, dependencies, contract, options);
        if (!pathInvoked) continue;

        invoked = mergeObj(invoked, pathInvoked);
        const pathActionData = getPathActionData(path, pathInvoked, changesData, contract, handler, options);
        if (!pathActionData) continue;
        const { entry } = pathActionData;
        if (invoked.repeatableRemove) entry.previousValue = changesData.previousValues[path];
        entries.push(entry);
        paths.push(path);
      }

      if (Object.keys(invoked).length > 1 || Object.keys(invoked.state).length > 0) {
        actions.push({
          handler,
          handlerInvoked: invoked,
          entries,
          paths,
        });
      }
    }
  }
  return actions;
}

function pathMatchesDependencies(path, changesData, dependencies, contract, options) {
  if (isRepeatableAddedQA(path, changesData, contract, options) && dependencies.repeatableQuestionAnswerAdd) {
    return { repeatableQuestionAnswerAdd: true };
  }
  if (
    isRepeatableRemovedQA(path, changesData, contract, options) &&
    dependencies.repeatableQuestionAnswerRemove
  ) {
    return { repeatableQuestionAnswerRemove: true };
  }
  if (
    isRepeatableUpdatedQA(path, changesData, contract, options) &&
    dependencies.repeatableQuestionAnswerChange
  ) {
    return { repeatableQuestionAnswerChange: true };
  }
  if (isOrdinaryAddedQA(path, changesData, contract, options) && dependencies.ordinaryQuestionAnswerAdd) {
    return { ordinaryQuestionAnswerAdd: true };
  }
  if (
    isOrdinaryRemovedQA(path, changesData, contract, options) &&
    dependencies.ordinaryQuestionAnswerRemove
  ) {
    return { ordinaryQuestionAnswerRemove: true };
  }
  if (
    isOrdinaryUpdatedQA(path, changesData, contract, options) &&
    dependencies.ordinaryQuestionAnswerChange
  ) {
    return { ordinaryQuestionAnswerChange: true };
  }
  if (isAddedRepeatable(path, changesData, contract, options) && dependencies.repeatableAdd) {
    return { repeatableAdd: true };
  }
  if (isRemovedRepeatable(path, changesData, contract, options) && dependencies.repeatableRemove) {
    return { repeatableRemove: true };
  }
  if (isRepeatableChange(path, changesData, contract, options) && dependencies.repeatableChange) {
    return { repeatableChange: true };
  }
  if (isOrdinaryChange(path, changesData, contract, options) && dependencies.ordinary) {
    return { ordinary: true };
  }
  if (isEntityChange(path) && dependencies.entityAny) return { entityAny: true };

  const { cardId, fieldName } = InputPaths.getCardAndField(contract, path);
  let slashedPath;
  if (cardId && fieldName) {
    slashedPath = cardId + '.' + fieldName;
  } else if (cardId) {
    slashedPath = cardId + '.*';
  }
  // console.log('Slashed path... ', slashedPath)
  if (slashedPath && dependencies.state && dependencies.state.includes(slashedPath)) {
    return { state: { [slashedPath]: true } };
  }
}

function isQA(path, contract) {
  const { cardId, fieldName } = InputPaths.getCardAndField(contract, path);
  if (!cardId || !fieldName) return false;
  const input = Contract.getUiInput(contract, fieldName);
  if (!input) return false;
  return input.type === 'QA';
}
function isBeginingRepeatable(path, contract) {
  const repeatablesPath = InputPaths.extractRepeatablePathParts(contract, path);
  if (repeatablesPath.length !== 1 || path.indexOf(repeatablesPath[0]) !== 0) {
    return false;
  }
  return true;
}

function isAddedQA(path, changesData, contract, options) {
  if (!isQA(path, contract)) return false;
  const lastPath = InputPaths.getLast(path);
  return isUuidV4(lastPath) && changesData.info[path] === 'added';
}
function isRemovedQA(path, changesData, contract, options) {
  if (!isQA(path, contract)) return false;
  const lastPath = InputPaths.getLast(path);
  return isUuidV4(lastPath) && changesData.info[path] === 'removed';
}
function isUpdatedQA(path, changesData, contract, options) {
  if (!isQA(path, contract)) return false;
  const secondLastPath = InputPaths.getSecondLast(path);
  return isUuidV4(secondLastPath);
}

function isRepeatableAddedQA(path, changesData, contract, options) {
  return isBeginingRepeatable(path, contract) && isAddedQA(path, changesData, contract, options);
}
function isRepeatableRemovedQA(path, changesData, contract, options) {
  return isBeginingRepeatable(path, contract) && isRemovedQA(path, changesData, contract, options);
}
function isRepeatableUpdatedQA(path, changesData, contract, options) {
  return isBeginingRepeatable(path, contract) && isUpdatedQA(path, changesData, contract, options);
}

function isOrdinaryAddedQA(path, changesData, contract, options) {
  return !isBeginingRepeatable(path, contract) && isAddedQA(path, changesData, contract, options);
}
function isOrdinaryRemovedQA(path, changesData, contract, options) {
  return !isBeginingRepeatable(path, contract) && isRemovedQA(path, changesData, contract, options);
}
function isOrdinaryUpdatedQA(path, changesData, contract, options) {
  return !isBeginingRepeatable(path, contract) && isUpdatedQA(path, changesData, contract, options);
}

function isAddedRepeatable(path, changesData, contract, options) {
  if (!isMainRepeatableAction(path, contract, options)) return false;
  if (changesData.info[path] === 'added') return true;
  return false;
}

function isRemovedRepeatable(path, changesData, contract, options) {
  if (!isMainRepeatableAction(path, contract, options)) return false;
  if (changesData.info[path] === 'removed') return true;
  return false;
}

function isMainRepeatableAction(path, contract) {
  const { cardId, fieldName } = InputPaths.getCardAndField(contract, path);
  if (!Contract.getUiIsCardRepeatable(contract, cardId)) {
    return false;
  }
  if (fieldName) return false;
  if (InputPaths.getCardUid(contract, path) !== InputPaths.getLast(path)) return false;
  return true;
}

function isEntityChange(path) {
  return path.split('.')[0] === 'entities';
}

function isRepeatableChange(path, changesData, contract, options = {}) {
  if (isMainRepeatableAction(path, contract, options)) return false;
  const { cardId, fieldName } = InputPaths.getCardAndField(contract, path);
  if (!Contract.getUiIsCardRepeatable(contract, cardId) || !fieldName) {
    return false;
  }
  /* if (changesData.info[path] === "modified") return true;
  return false; */
  return true;
  // Particular items/inputFields may be added/removed which
  // should be classified as a repeatableChange.
}
function isOrdinaryChange(path, changesData, contract, options = {}) {
  return (
    !isAddedRepeatable(path, changesData, contract, options) &&
    !isRemovedRepeatable(path, changesData, contract, options) &&
    !isRepeatableChange(path, changesData, contract, options) &&
    !isEntityChange(path)
  );
}
