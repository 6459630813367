import {
  SET_CONVERSATIONS,
  ADD_CONVERSATION,
  DELETE_CONVERSATION,
  ADD_CONVERSATION_POST,
  DELETE_CONVERSATION_POST,
  UPDATE_CONVERSATION_POST,
  SET_CONVERSATION_COMPLETED,
} from 'constants/ActionTypes';
import uuid from 'uuid-random';

export const setConversations = (data) => {
  if (!data || typeof data !== 'object') {
    console.warn('Tried to set non object as conversations', { data });
    return { type: '__NOP' };
  }
  return {
    type: SET_CONVERSATIONS,
    payload: data,
  };
};

export const addConversation = (conversationData, id) => {
  return (dispatch, getState) => {
    const prevState = getState();
    const { conversations } = prevState;
    if (id && conversations?.[id]) return;
    if (!conversationData || typeof conversationData !== 'object') {
      console.warn('Tried to add invalid conversation conversationData', { conversationData, id });
      return;
    }

    const conversationId = id || uuid();
    const data = {
      ...conversationData,
      created: new Date(Date.now()).toISOString(),
    };
    if (!data.posts) data.posts = [];
    else {
      data.posts = data.posts.map((p) => ({ ...p, id: p.id || uuid() }));
    }

    return dispatch({
      type: ADD_CONVERSATION,
      payload: { conversationId, data },
    });
  };
};

export const deleteConversation = (conversationId) => {
  if (typeof conversationId !== 'string') {
    console.warn('Tried to del invalid conversation', { conversationId });
    return { type: '__NOP' };
  }

  return {
    type: DELETE_CONVERSATION,
    payload: { conversationId },
  };
};

export const addConversationPost = (conversationId, post) => {
  if (typeof conversationId !== 'string' || !post || typeof post !== 'object') {
    console.warn('Tried to add invalid conversation post', { conversationId, post });
    return { type: '__NOP' };
  }

  return {
    type: ADD_CONVERSATION_POST,
    payload: {
      conversationId,
      post: {
        ...post,
        id: post.id || uuid(),
      },
    },
  };
};

export const deleteConversationPost = (conversationId, postId) => {
  if (typeof conversationId !== 'string' || typeof postId !== 'string') {
    console.warn('Tried to del invalid conversation post', { conversationId, postId });
    return { type: '__NOP' };
  }

  return {
    type: DELETE_CONVERSATION_POST,
    payload: { conversationId, postId },
  };
};

export const updateConversationPost = (conversationId, postId, post) => {
  if (typeof conversationId !== 'string' || typeof postId !== 'string' || !post || typeof post !== 'object') {
    console.warn('Tried to del invalid conversation post', { conversationId, postId, post });
    return { type: '__NOP' };
  }

  return {
    type: UPDATE_CONVERSATION_POST,
    payload: { conversationId, postId, post },
  };
};

export const setCompleted = (conversationId, value) => {
  if (typeof conversationId !== 'string' || typeof value === 'undefined') {
    console.warn('Tried to add invalid conversation post', { conversationId, value });
    return { type: '__NOP' };
  }

  return {
    type: SET_CONVERSATION_COMPLETED,
    payload: {
      conversationId,
      value,
    },
  };
};
