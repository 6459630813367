import { SET_TASKS, UPDATE_TASK } from 'constants/ActionTypes';

const INIT_STATE = [];

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case SET_TASKS: {
      return action.payload;
    }
    case UPDATE_TASK: {
      const { payload: newTask } = action;
      const newTasks = state.map((task) => {
        if (task.id === newTask.id) {
          return { ...task, ...newTask }
        }
        return task;
      });

      return newTasks;
    }

    default:
      return state;
  }
}
