import React, { useState, useEffect, useMemo } from 'react';
import isEmail from 'is-email';
import { Row, Col, Form, Input, Avatar, Button, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import api from 'utils/api';
import ContainerHeader from 'components/ContainerHeader/index';

function validatePassword(pwd1, pwd2) {
  if (pwd1.length < 8) {
    return 'Password must be at least 8 characters';
  }
  if (pwd1 !== pwd2) {
    return 'Passwords must match';
  }
  return '';
}

export default function Verify(props) {
  const history = useHistory();
  const { location } = props;

  const urlParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const emailAsBase64 = urlParams.get('e');
  const email = atob(emailAsBase64);
  const expiryAsBase64 = urlParams.get('exp');
  const expiry = atob(expiryAsBase64);
  const userId = urlParams.get('u');
  const hash = urlParams.get('h');

  const [verified, setVerified] = useState(false);
  const [verificationError, setVerificationError] = useState('');
  const [pwd1, setPwd1] = useState('');
  const [pwd2, setPwd2] = useState('');
  const [createUserError, setCreateUserError] = useState(false);

  useEffect(() => {
    if (!isEmail(email)) {
      console.log('Invalid email');
      return;
    }
    async function verify() {
      try {
        const verified = await api.post('/users/verifyForgotPwd', { email, userId, expiry, hash });
        if (verified?.data?.success === true) setVerified(true);
        console.log('verif data ', verified.data);
      } catch (err) {
        console.log('verification error ', err.response);
        setVerificationError('Incorrect reset password link');
      }
    }
    verify();
  }, [email, userId, expiry, hash]);

  const setPassword = () => {
    api
      .post('/users/setForgotPwd', { email, userId, expiry, hash, password: pwd1 })
      .then((res) => {
        if (res && res.data && res.data.success) {
          console.log('All good - shall redirect to signin page');
          return history.replace('/signin');
        }
        setCreateUserError(res.data.error || 'Could not create user');
      })
      .catch((err) => {
        console.log('set password error ', err.response);
        setCreateUserError('Could not create user');
      });
  };

  const passwordError = validatePassword(pwd1, pwd2);

  return (
    <div
      style={{
        padding: '40px',
      }}
    >
      <Row>
        <Col span={24}>
          <ContainerHeader title="Reset password" />
        </Col>
        <Col span={24}>
          <div className="timeline-section">
            <div className={`timeline-item`}>
              <div className="timeline-badge timeline-img">
                <Avatar
                  size="large"
                  className={'size-60 rounded-circle bg-' + (verified ? 'success' : 'white')}
                  icon={
                    <i
                      className={`mdi mdi-lock text-${verified ? 'white' : 'primary'} fs-xxxl d-block mt-1`}
                    />
                  }
                />
              </div>

              <div className="timeline-panel">
                <div className="timeline-panel-header">
                  <div className="timeline-heading">
                    <h5>Step 1</h5>
                    <h3 className="timeline-title">Verification Credentials</h3>
                  </div>
                </div>
                <div className="timeline-body">
                  {verified ? (
                    <Alert
                      message="Verification successful"
                      description="Your reset credentials have been verified. Please proceed to reset password below."
                      type="success"
                      showIcon
                    />
                  ) : (
                    <Alert
                      message="Verification failed"
                      description={
                        <div>
                          <div>Your reset credentials could not be verified.</div>
                          {verificationError}
                        </div>
                      }
                      type="error"
                      showIcon
                    />
                  )}
                </div>
              </div>
            </div>
            {verified && (
              <div className={`timeline-item`}>
                <div className="timeline-badge timeline-img">
                  <Avatar
                    size="large"
                    className={'size-60 rounded-circle bg-white'}
                    icon={<i className={`mdi mdi-account text-primary fs-xxxl d-block mt-1`} />}
                  />
                </div>

                <div className="timeline-panel">
                  <div className="timeline-panel-header">
                    <div className="timeline-heading">
                      <h5>Step 2</h5>
                      <h3 className="timeline-title">Reset password</h3>
                    </div>
                  </div>
                  <div className="timeline-body">
                    <>
                      <Form layout="vertical">
                        <Form.Item label="Enter new password">
                          <Input
                            type="password"
                            name="password1"
                            id="password1"
                            placeholder="password"
                            value={pwd1}
                            onChange={(e) => setPwd1(e.target.value)}
                            required
                          />
                        </Form.Item>
                        <Form.Item label="Confirm new password">
                          <Input
                            type="password"
                            name="password2"
                            id="password2"
                            placeholder="password"
                            value={pwd2}
                            onChange={(e) => setPwd2(e.target.value)}
                            required
                          />
                        </Form.Item>
                      </Form>

                      <Row className="mb-3 mt-4">
                        <Col span={12} className="m-auto">
                          <Button
                            type={!passwordError ? 'primary' : ''}
                            size="large"
                            block
                            disabled={passwordError ? true : false}
                            onClick={setPassword}
                          >
                            Set new password
                          </Button>
                          <div className="text-danger mt-3">
                            {(passwordError && pwd1 !== '' && passwordError) || <span>&nbsp;</span>}
                            {createUserError && createUserError}
                          </div>
                        </Col>
                      </Row>
                    </>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
