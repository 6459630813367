import { useSelector, useDispatch } from 'react-redux';
import { setDraft } from '../../appRedux/actions/Draft';

export const useStudioAccessLevel = () => {
  const accessLevel = useSelector((state) => state.draft.accessLevel);
  return accessLevel;
};

export const useIsStudioReader = () => {
  const accessLevel = useSelector((state) => state.draft.accessLevel);
  return accessLevel === 'read';
};

export const useIsStudioCommentor = () => {
  const accessLevel = useSelector((state) => state.draft.accessLevel);
  return accessLevel === 'comment';
};

export const useIsStudioWriter = () => {
  const accessLevel = useSelector((state) => state.draft.accessLevel);
  return accessLevel === 'write';
};

export const useStudioMayEdit = () => {
  const accessLevel = useSelector((state) => state.draft.accessLevel);
  return accessLevel === 'write' || accessLevel === 'comment';
};

export const useTrackChanges = () => {
  const dispatch = useDispatch();
  const isStudioWriter = useIsStudioWriter();
  const trackingEnabled = useSelector((state) => state.draft.trackChanges);
  const setTrackingEnabled = (value) => {
    if (!isStudioWriter && value !== true) return;
    dispatch(setDraft('trackChanges', value));
  };
  const toggleTrackingEnabled = () => setTrackingEnabled(!trackingEnabled);
  return {
    trackingEnabled,
    setTrackingEnabled,
    toggleTrackingEnabled,
  };
};
