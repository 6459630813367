import { useContract } from 'hooks';

export function useContractForWrapper(providedContract) {
  let contract;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    contract = useContract();
  } catch (err) {
    if (providedContract) contract = providedContract;
    else {
      throw new Error('No contract context.');
    }
  }
  return contract || providedContract;
}
