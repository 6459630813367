import { useState, useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Checkbox, Table, Dropdown, Menu } from 'antd';
import { fixDate, RemoveModal } from 'components/ui';
import { useSearch } from 'hooks';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import InviteEntity from '../InviteEntity';

const { Column } = Table;

const parseInvitations = (invitations) => {
  return invitations.map((data) => ({
    id: data.id,
    name: `${data.Entity.firstName} ${data.Entity.lastName}`,
    entityId: data.entityId,
    versionId: data.versionId,
    created: fixDate(data.createdAt),
    expired: fixDate(data.expireAt),
  }));
};

export default function SharedDocument({
  documentId,
  projectId,
  currentVersion,
  initialInvitations,
  updateLength,
}) {
  const hasInitialInvitations = !!initialInvitations;
  const history = useHistory();
  const [anyVersion, setAnyVersion] = useState(false);
  const [invitations, setInvitations] = useState(initialInvitations || []);

  const parsedInvitations = useMemo(() => parseInvitations(invitations), [invitations]);

  const [searchResult, searchElement] = useSearch(parsedInvitations, {
    element: true,
    fields: ['name'],
  });

  const loadInvitations = useCallback(async () => {
    const include = encodeURI(JSON.stringify([{ model: 'Entity', as: 'Entity' }]));
    const query = `where=documentId_->_${documentId}&include=${include}`;
    const response = await api.get(`/entityinvitations?${query}`);
    setInvitations(response.data);
    updateLength(response.data.length);
  }, [documentId, updateLength]);

  useEffect(() => {
    if (hasInitialInvitations) return;
    loadInvitations();
  }, [loadInvitations, hasInitialInvitations]);

  const onInvitation = useCallback(() => {
    loadInvitations();
    if (typeof updateLength === 'function') updateLength(invitations.length + 1);
  }, [invitations.length, loadInvitations, updateLength]);

  const revokeInvitation = async (id) => {
    await api.delete(`/entityinvitations/${id}`);
    setInvitations(invitations.filter((inv) => inv.id !== id));
    if (typeof updateLength === 'function') updateLength(invitations.length - 1);
  };

  const openUser = (id) => {
    history.push(`/entity/${id}/overview`);
  };

  return (
    <Card
      title={
        <div className="d-flex align-items-center justify-content-end">
          <Checkbox className="mr-3" onChange={(e) => setAnyVersion(e.target.checked)}>
            Any version
          </Checkbox>
          {searchElement}
          <InviteEntity
            className="ml-3"
            documentId={documentId}
            projectId={projectId}
            versionId={currentVersion.id}
            onInvitation={onInvitation}
          />
        </div>
      }
    >
      <Table
        rowKey="id"
        dataSource={searchResult.filter(({ versionId }) =>
          anyVersion ? true : versionId === currentVersion.id
        )}
      >
        <Column
          title={<IntlMessages id="app.general.Name" cap />}
          dataIndex="name"
          render={(name, { entityId }) => (
            <a className="link" onClick={() => openUser(entityId)}>
              {name}
            </a>
          )}
        />
        <Column title={<IntlMessages id="app.external.info.shared" cap />} dataIndex="created" />
        <Column
          title={
            <>
              <IntlMessages id="app.general.Link" cap /> <IntlMessages id="app.external.info.expiry" lower />
            </>
          }
          dataIndex="expired"
        />
        <Column
          title={
            <>
              <IntlMessages id="desc.access" cap /> <IntlMessages id="app.external.info.expiry" lower />
            </>
          }
          key="expired-never"
        />
        <Column
          title={<IntlMessages id="app.general.Action" cap />}
          dataIndex="id"
          render={(id, record) => {
            return (
              <span>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="remove">
                        <RemoveModal
                          onConfirm={() => revokeInvitation(id)}
                          confirmText={
                            <>
                              <IntlMessages id="app.general.confirmRemoval" />
                              {` ${record.name} `}
                              <IntlMessages id="desc.from" /> <IntlMessages id="desc.this" />{' '}
                              <IntlMessages id="app.resources.Document" lower />?
                            </>
                          }
                        >
                          <span>
                            <IntlMessages id="desc.revoke" cap />
                          </span>
                        </RemoveModal>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <span className="link ant-dropdown-link">
                    <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
                  </span>
                </Dropdown>
              </span>
            );
          }}
        />
      </Table>
    </Card>
  );
}
