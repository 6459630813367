import uuid from "uuid-random";
import { options } from "./options";

export function createTable(columns, rows) {
  const rowNodes = [...new Array(rows)].map(() => createRow(columns));

  return {
    type: options.tableType,
    children: rowNodes,
    data: {
      template_id: 'std_table_1',
      item_id: uuid()
    },
  };
}

export function createRow(columns) {
  const cellNodes = [...new Array(columns)].map(() => createCell());

  return {
    type: options.rowType,
    key: `row_${uuid()}`,
    data: {
      template_id: 'std_table_row_1',
      item_id: uuid()
    },
    children: cellNodes,
  };
}

export function createCell({ elements, colspan, rowspan, height, width } = {}) {
  const content = createContent(elements);

  return {
    type: options.cellType,
    key: `cell_${uuid()}`,
    data: {
      template_id: 'std_table_cell_1',
      item_id: uuid()
    },
    children: [content],
    width: width,
    height: height,
    colspan,
    rowspan,
  };
}

export function createContent(elements) {
  return {
    type: options.tableContentType,
    data: { template_id: 'std_table_content_1', item_id: uuid()},
    children: elements || [{ type: "paragraph", data: { template_id: 'std_paragraph_1', item_id: uuid()}, children: [{ text: "" }] }],
  };
}
