import React, { memo, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row, Card } from 'antd';
import { Entity } from '../../../core/interfaces/Entity';
import { ActionButton, AutoModal } from 'components/ui';
import IntlMessages from 'util/IntlMessages';

function collectChildren(entities, entity) {
  const children = [];
  for (const child of entities) {
    if (child.parentId !== entity.id) continue;
    children.push(collectChildren(entities, child));
  }
  return {
    ...entity,
    children,
  };
}

export default memo(function ({ id, entity, entities, updateEntity }) {
  const withChildren = useMemo(() => collectChildren(entities, entity), [entities, entity]);

  return (
    <AutoModal
      trigger={
        <ActionButton
          type="secondary"
          title={<IntlMessages id="app.project.menus.Subsidiaries" cap />}
          icon="mdi mdi-file-tree"
        />
      }
      title={<IntlMessages id="app.project.menus.Subsidiaries" cap />}
      className="modal-md"
    >
      {!entities || !entities.length || entities.length === 1 ? (
        <Card>{Entity.name(entity)} has no subsidiaries</Card>
      ) : (
        <RenderEntity entity={withChildren} level={0} />
      )}
    </AutoModal>
  );
});

function RenderEntity({ entity, level }) {
  const colStyle = {
    width: `${100 - level * 3}%`,
  };

  return (
    <div className="pl-5">
      <Row justify="end">
        <Col style={colStyle}>
          <Card>
            <div className="flex-column justify-content-center">
              <div>
                <NavLink to={'/entity/' + entity.id}>{Entity.name(entity)}</NavLink>
              </div>
              <div>{entity.identificationNumber}</div>
            </div>
          </Card>
        </Col>
      </Row>
      {entity.children &&
        entity.children.length > 0 &&
        entity.children.map((child) => <RenderEntity key={child.id} entity={child} level={level + 1} />)}
    </div>
  );
}
