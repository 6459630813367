import { Modal, Button, Collapse } from 'antd';
const { Panel } = Collapse;

const text = `
  Text to come.
`;
export default function LogicsHelp({ showHelp, toggleHelp }) {
  return (
    <Modal
      visible={showHelp}
      onOk={toggleHelp}
      onCancel={toggleHelp}
      title="Rules Information"
      footer={<Button onClick={toggleHelp}>OK</Button>}
      width={'80vw'}
    >
      <Collapse defaultActiveKey={['1']} ghost>
        <Panel header={<h5 className="mb-0">General</h5>} key="1">
          <p>Bla bla bla</p>
        </Panel>
        <Panel header={<h5 className="mb-0">How do I...?</h5>} key="2">
          <div style={{ paddingLeft: '2em' }}>
            <Collapse defaultActiveKey={[]} ghost>
              <Panel header={<h6 className="mb-0">This is panel header 1</h6>} key="1">
                <p>{text}</p>
              </Panel>
              <Panel header={<h6 className="mb-0">This is panel header 2</h6>} key="2">
                <p>{text}</p>
              </Panel>
              <Panel header={<h6 className="mb-0">This is panel header 3</h6>} key="3">
                <p>{text}</p>
              </Panel>
            </Collapse>
          </div>
        </Panel>
        <Panel header={<h5 className="mb-0">Specific Rule Types</h5>} key="3">
          <div style={{ paddingLeft: '2em' }}>
            <Collapse defaultActiveKey={[]} ghost>
              <Panel header={<h6 className="mb-0">This is panel header 1</h6>} key="1">
                <p>{text}</p>
              </Panel>
              <Panel header={<h6 className="mb-0">This is panel header 2</h6>} key="2">
                <p>{text}</p>
              </Panel>
              <Panel header={<h6 className="mb-0">This is panel header 3</h6>} key="3">
                <p>{text}</p>
              </Panel>
            </Collapse>
          </div>
        </Panel>
      </Collapse>
    </Modal>
  );
}
