import React, { useState, useCallback, memo } from 'react';
import { Row, Col, Spin, Table, Button, Dropdown, Menu, Tooltip, Card } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import NewProjectButton from 'components/project/NewProject/NewProjectButton';
import { useDispatch } from 'react-redux';
import { setNumber } from 'appRedux/actions';
import { useMountFetch, useSearch } from 'hooks';
import { fixDate } from 'components/ui';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import ProjectWidget from 'components/Widgets/ProjectWidget';
import { RemoveModal } from 'components/ui';
import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { gutter } from 'config';
import WidgetHeader from 'components/WidgetHeader';

const { Column } = Table;

function sortPersonalFirst(a, b) {
  if (a.isPersonal) {
    return -1;
  }
  if (b.isPersonal) return 1;
  return 0;
}

const Projects = memo((props) => {
  const [projects, setProjects] = useState(null);
  const [projectsError, setProjectsError] = useState('');
  const dispatch = useDispatch();

  const setFetchedProjects = (fetchedProjects) => {
    if (!Array.isArray(fetchedProjects)) return;
    if (projects) return;
    setProjects(fetchedProjects.sort(sortPersonalFirst));
  };

  const projectWasCreated = useCallback(
    (project) => {
      setProjects([project, ...projects]);
      dispatch(setNumber('projects', projects.length + 1));
    },
    [dispatch, projects]
  );

  const projectWasRemoved = (id) => {
    setProjects([...projects.filter((project) => project.id !== id)]);
    dispatch(setNumber('projects', projects.length - 1));
  };

  useMountFetch(
    '/projects?includePersonal&fields=id,name,description,isSecret,isPersonal,isFolder,createdAt&order=desc&where=isFolder_->_NULL-or-FALSE',
    setFetchedProjects,
    setProjectsError
  );

  const [filteredProjects, searchElement] = useSearch(projects, {
    element: true,
    fields: ['name', 'description'],
  });

  return (
    <div className="m-5 padded-page light-container">
      <WidgetHeader
        title={<IntlMessages id="app.projects.Projects" />}
        styleName="mb-3"
      />
    <Card
      title={
        <div className="d-flex align-items-center justify-content-end">
          {searchElement}
          <NewProjectButton projectWasCreated={projectWasCreated} />
        </div>
      }
    >
    <div>
      {/* <h2 className="entry-title ant-card-head-wrapper mb-3">
        <IntlMessages id="app.projects.Projects" />
        <span className="text-primary fs-md ml-auto pl-4 d-flex">
          <>
            {searchElement}
            <NewProjectButton projectWasCreated={projectWasCreated} />
          </>
        </span>
      </h2> */}

      {!projects && (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Spin size="large" />
        </div>
      )}

      {projectsError && (
        <div className="d-flex align-items-center justify-content-center h-100">{projectsError}</div>
      )}
      {projects && !projectsError && (
        <div>
          <ProjectsTable projects={filteredProjects} projectWasRemoved={projectWasRemoved} type="project" />
        </div>
      )}
    </div>
    </Card>
    </div>
  );
});

const config = {
  bordered: false,
  loading: false,
  pagination: { position: 'bottom' },
  size: 'default',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  scroll: undefined,
};

export function ProjectsTable({ projects, projectWasRemoved, type = 'project' }) {
  const history = useHistory()
  const intTypeCap = type === 'folder' ? 'Folder' : 'Project';

  const removeProject = (id) => {
    api
      .delete('/projects/' + id)
      .then((result) => {
        projectWasRemoved(id);
      })
      .catch((err) => {
        console.log('Error removing project ', id, err.response);
      });
  };

  return (
    <Table
      rowKey="id"
      className="table-responsive ml-3"
      {...config}
      dataSource={projects}
    >
      <Column
        title={<IntlMessages id="app.general.Name" />}
        dataIndex="name"
        key="name"
        render={(text, record) => (
          <a className="link" onClick={() => history.push(`/project/${record.id}`)}>{text}</a>
        )}
      />
      <Column
        title={<IntlMessages id="app.general.Description" />}
        dataIndex="description"
        key="description"
        ellipsis
      />
      <Column
        title={<IntlMessages id="app.general.Created" />}
        dataIndex="createdAt"
        key="createdAt"
        width={200}
        render={(text) => fixDate(text)}
      />
      <Column
        title={<IntlMessages id="app.general.More" />}
        dataIndex={null}
        key="menu"
        width={60}
        render={(text, record) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="remove">
                  <RemoveModal
                    onConfirm={() => removeProject(record.id)}
                    confirmText={
                      <>
                        <IntlMessages id="app.general.confirmRemoval" />{' '}
                        <IntlMessages id={'app.resources.' + intTypeCap} />?
                      </>
                    }
                  >
                    <span className="">
                      <IntlMessages id="desc.Remove" /> <IntlMessages id={'app.resources.' + intTypeCap} />
                    </span>
                  </RemoveModal>
                </Menu.Item>
              </Menu>
            }
          >
            <span className="link ant-dropdown-link">
              <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
            </span>
          </Dropdown>
        )}
      />
    </Table>
  );
}

export default Projects;
