export function generateEachPlaceholderChild(node) {
  if (node.data && node.data.fill && node.data.fill.placeholder)
    return JSON.parse(node.data.fill.placeholder);
  switch (node.type) {
    case 'numbered_list':
    case 'bulleted_list':
      return [
        {
          type: 'list_item',
          data: {
            is_each_placeholder: true,
            template_id: 'placeholder-ol',
            item_id: this.uuid(),
          },
          children: [{ text: '' }],
        },
      ];
    case 'clause':
    case 'each':
      return [
        {
          type: 'paragraph',
          data: {
            is_each_placeholder: true,
            template_id: 'placeholder-pr',
            item_id: this.uuid(),
          },
          children: [{ text: '' }],
        },
      ];
    case 'paragraph':
      return [{ text: '' }];
    case 'field':
      return [{ text: '' }];
    case 'table_row':
      return [
        {
          type: 'table_cell',
          data: {
            is_each_placeholder: true,
            template_id: 'placeholder-pr',
            item_id: this.uuid(),
          },
          children: [
            {
              type: 'table_content',
              data: {
                is_each_placeholder: true,
                template_id: 'placeholder-pr',
                item_id: this.uuid(),
              },
              children: [{ text: '[Placeholder]', italic: true }],
            },
          ],
        },
      ];
    default:
      console.log('No default node specified for parent type ', node.type);
      return [
        {
          type: 'paragraph',
          data: {
            is_each_placeholder: true,
            template_id: 'placeholder-default',
            item_id: this.uuid(),
          },
          children: [{ text: '' }],
        },
      ];
  }
}
