import React, { useState, createRef, useRef } from "react";
import { Transforms } from "slate";
import { DatePicker } from "antd";
import { useContract } from "hooks";
import { Contract } from "core/interfaces";
import { getNodeAllText } from "core/engine/utils";

import moment from "moment";
import "moment/locale/sv";
import svSE from "antd/lib/locale-provider/sv_SE";
import enGB from "antd/lib/locale-provider/en_GB";

const datePickerLocales = {
  sv: svSE,
  en: enGB,
};

// const dateFormat = 'll'
const dateFormat = "D MMMM YYYY";

export default function DateMenu({ editor, node, path }) {
  const contract = useContract();
  const language = Contract.getLanguage(contract);
  const ref = createRef(null);
  const picker = useRef(null);

  const hasFullTimeValue = node?.data?.value;

  const [value, setValue] = useState(node?.data?.value || getNodeAllText(node) || undefined);

  // const allowed = allowModify(node);

  const handleDateChange = (val) => {
    val.locale(language);
    const dateText = val.format(dateFormat);
    if (path) {
      Transforms.insertText(editor, dateText, { at: [...path, 0] });
      node.data.value = val.toISOString();
      node.data.valueText = dateText;
      setValue(dateText);
    }
  };

  moment.locale(language);
  const datePickerLocale = language === "en" ? undefined : datePickerLocales[language];

  let pickerValue;
  if (hasFullTimeValue) pickerValue = moment(value);
  else if (value) pickerValue = moment(value, dateFormat);

  return (
    <div
      style={{
        width: "280px",
        height: "338px",
      }}
    >
      <DatePicker
        getCalendarContainer={(container) => (picker.current = container)}
        open={true}
        ref={ref}
        locale={datePickerLocale}
        name={node.data.name}
        format={dateFormat}
        onChange={handleDateChange}
        value={!value ? undefined : moment(pickerValue, dateFormat)}
      />
    </div>
  );
}

/* function allowModify(node) {
  return node.data.info.modify?.allowed;
}
 */