import React, { useState, memo } from 'react';
import { Tooltip } from 'antd';
import { Contract } from 'core/interfaces';
import { PrintRule, RuleModal } from 'components/RuleEditor/';
import { useContract } from 'hooks';
import { Transforms } from 'slate'

const indicatorWithData = ['each_repeatable', 'isSmartDefinition', 'fill', 'acp', 'module'];
const indicatorInfo = {
  acp: 'Block conditional by rule',
  each_repeatable: 'Repeating block for each entry of type: ',
  isSmartDefinition: 'Automatic smart definition',
  fill: 'Intelligent content generation',
  module: 'Contract Module',
};

export const ElementsIndicator = memo((props) => {
  const { editor, elementData, stringPath, tooltipPlacement = 'top' } = props;

  if (!elementData) return null;
  if (!elementData.acp && !elementData.each_repeatable && !elementData.isSmartDefinition && !elementData.fill && !elementData.module)
    return null;

  return (
    <div className="elements-indicator" contentEditable={false}>
      {indicatorWithData
        .filter((x) => elementData.hasOwnProperty(x))
        .map((indicator) => (
          <ElementIndicatorItem
            editor={editor}
            elementData={elementData}
            indicator={indicator}
            key={indicator}
            stringPath={stringPath}
            tooltipPlacement={tooltipPlacement}
          />
        ))}
    </div>
  );
});
const ElementIndicatorItem = memo(({ editor, elementData, indicator, stringPath, tooltipPlacement }) => {
  if (indicator === 'acp') return <IndicatorItemACP editor={editor} elementData={elementData} stringPath={stringPath} tooltipPlacement={tooltipPlacement} />;

  let extra = '';
  if (elementData && elementData.each_repeatable) extra += elementData.each_repeatable.repeatable;

  return (
    <Tooltip title={indicatorInfo[indicator] + ' ' + extra} placement={tooltipPlacement}>
      <div className={'element-indicator ' + indicator}></div>
    </Tooltip>
  );
});
function IndicatorItemACP({ editor, elementData, stringPath, tooltipPlacement }) {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false)


  const contract = useContract();

  const toggleShow = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setShow(!show);
  };

  const toggleEdit = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setEdit(!edit);
  }

  const onSave = (rule, ruleId) => {
    return;
  };

  const onSaveAs = (rule, ruleId) => {
    const path = JSON.parse(stringPath)
    Transforms.setNodes(
      editor,
      (node) => {
        return {
          data: {
            ...node.data,
            acp: ruleId,
          },
        };
      },
      { at: path }
    );
  };
  const onOk = (rule) => {
    setEdit(false);
  };

  const currentRule = Contract.getRule(contract, elementData.acp);

  /* 
  const draft = useDraft();
  const state = useSelector((state) => state);
 */
  /* const handleSave = async (rule) => {
    const { isActive, changed } = await draft.invokeMethod(
      "nodeACPwasUpdated",
      elementData,
      rule,
      state
    );

    if (changed) {
      const notificationType = isActive ? "success" : "error";
      notification[notificationType]({
        message: isActive ? "Activated" : "Inactivated",
        description:
          "Due to change of rule, the relevant item is now " + (isActive ? "active" : "inactive"),
        duration: 7,
      });
    }
  }; */

  return (
    <>
      <div
        className={'element-indicator acp'}
        onMouseOver={toggleShow}
        onMouseOut={toggleShow}
        onClick={toggleEdit}
      >
        {show && (
          <div className={"acp-on-document placement-"+tooltipPlacement}>
            <small className="mb-3 pb-3">
              Block conditioned by rule {currentRule.label ? <strong>{currentRule.label}</strong> : <em>No label</em>}
              <div className="d-inline-block float-right">
                (Click <div className="element-indicator acp"></div> to edit rule)
              </div>
            </small>
            <div className="mt-3">
              {!currentRule || !currentRule.data ? (
                <span>No rule data</span>
              ) : (
                <PrintRule rule={currentRule.data} />
              )}
            </div>
          </div>
        )}
      </div>
      {edit && (
        <RuleModal
          contract={contract}
          id={elementData.acp}
          onSave={onSave}
          onSaveAs={onSaveAs}
          onOk={onOk}
          onCancel={toggleEdit}
        />
      )}
    </>
  );
}
