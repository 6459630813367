import { Contract } from 'core/interfaces';

export const GET_ELEMENT_TAGS = (contract, currentNode, level) => ({
  BLOCKQUOTE: () => ({ type: 'quote' }),
  H1: () => ({ type: 'heading_one' }),
  H2: () => ({ type: 'heading_two' }),
  H3: () => ({ type: 'heading_three' }),
  H4: () => ({ type: 'heading_four' }),
  H5: () => ({ type: 'heading_five' }),
  H6: () => ({ type: 'heading_six' }),
  IMG: (el) => ({ type: 'image', url: el.getAttribute('src') }),
  LI: () => ({ type: 'list_item' }),
  OL: () => ({ type: 'numbered_list' }),
  P: () => {
    if (Contract.isHeaderContractText(contract, currentNode.type) && level < 3) {
      return { type: currentNode.type };
    }
    return { type: 'paragraph' };
  },
  // PRE: () => ({ type: "code" }),
  UL: () => ({ type: 'bulleted_list' }),
  TABLE: () => ({ type: 'table' }),
  TR: () => ({ type: 'table_row' }),
  TD: () => ({ type: 'table_cell' }),
});

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
export const TEXT_TAGS = {
  SPAN: () => ({}),
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};
