import React, { useState, useEffect } from 'react';

import { hasAuthToken, setAuthToken } from 'utils/auth';

import { ContractContext } from 'contexts/contexts';
import ViewEditor from 'components/editor/views/ViewEditor';
import api from 'utils/api';

const PrintDocument = ({ match }) => {
  const [contract, setContract] = useState(null);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function load() {
      if (!hasAuthToken()) setAuthToken(prompt('Optional insert authorization'));
      Promise.all([
        api.get(`/versions/${match.params.version}`),
        api.get(`/versions/${match.params.version}/content`),
      ])
        .then((res) => {
          setContract(res[0].data);
          setContent(res[1].data);
        })
        .catch((err) => {
          console.log('Print load error ', err);
          setError(err.toString());
        });
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.version]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return contract && content ? (
    <ContractContext.Provider initialValue={contract}>
      {/* <EditorWrapper view onlineMode={false} content={content}> */}
        <ViewEditor
          contract={contract}
          content={content}
          mode="plain"
          print
          actionFields={{
            view: false,
            mode: false,
            export: false,
          }}
        />
      {/* </EditorWrapper> */}
    </ContractContext.Provider>
  ) : (
    <div></div>
  );
};

export default PrintDocument;
