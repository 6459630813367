import { translateText } from '../../prototype/getters';
import { isBlock, isInline, isText } from '../../../types/elements';

export const itemJoiner = (children, opts = {}) => {
  if (!children) {
    if (opts.debug) console.log('item joiner died, invalid contr or children ', children);
    return;
  }
  // console.log('Opts are ', opts)
  if (Array.isArray(children)) {
    for (const node of children) itemJoinFunction(node, opts);
  } else if (typeof children === 'object') itemJoinFunction(children, opts);

  return children;
};

const itemJoinFunction = (node, opts = {}) => {
  let { inheritedFinalEnd } = opts;
  const { redo, debug, language } = opts;

  if (node.type !== 'numbered_list') {
    itemJoiner(node.children, opts);
    return;
  }
  const noJoin =
    !node.data || !node.data.item_join || node.data.item_join === 'false' || node.data.item_join === '';

  if (inheritedFinalEnd === 'false') inheritedFinalEnd = false;

  const itemJoin =
    node.data &&
    (node.data.item_join === 'and'
      ? translateText('and', { language })
      : node.data.item_join === 'or'
      ? translateText('or', { language })
      : node.data.item_join);

  const delimiter = language === 'sv' ? ',' : ';';

  const activeItems = node.children.filter((n) => !n.data || !n.data._inActive);

  const listLength = activeItems.length;
  const lastItemHanging =
    (activeItems[listLength - 1] &&
      activeItems[listLength - 1].data &&
      activeItems[listLength - 1].data.hanging) ||
    false;

  const finalEnd = lastItemHanging ? ',' : inheritedFinalEnd || '.';

  const breakPoint = lastItemHanging ? listLength - 1 : listLength;

  let activeIndex = -1;
  for (var i = 0; i < node.children.length; i++) {
    const item = node.children[i];

    if (item.data && item.data._inActive) continue;

    activeIndex += 1;

    if (!item || !item.type) continue;
    if (item.type === 'numbered_list') {
      itemJoinFunction(item, {
        ...opts,
        parentIsList: true,
        inheritedFinalEnd: !noJoin && activeIndex === breakPoint - 2 ? '; ' + itemJoin : false,
      });
      continue;
    }
    if (item.type !== 'list_item' || noJoin) continue;

    // If next is a list, then do not add...
    if (node.children[activeIndex + 1] && node.children[activeIndex + 1].type === 'numbered_list') {
      // console.log('Skip ij for parent of sub list', item);
      continue;
    }

    let lastText = getLastTextItem(item.children);
    if (typeof lastText === 'string') {
      console.log('No items for item children', item);
      continue;
    }
    if (!lastText) {
      console.log('Found no lastTextItem for ', item);
      continue;
    }
    if (!lastText.hasOwnProperty('text')) continue;
    if (lastText.text[lastText.text.length - 1] === ':') {
      // console.log("Last char is colon, continue");
      continue;
    }
    let add = '';

    if (activeIndex === breakPoint - 1)
      // Last item
      add = finalEnd;
    else if (activeIndex === breakPoint - 2)
      // Second last item
      add = delimiter + ' ' + itemJoin;
    else if (lastItemHanging && activeIndex === breakPoint) {
      // Do nothing really.
      // console.log('This is the hanging item:', item);
    } else add = delimiter;

    // console.log('add to ', {add, i, activeIndex, lastText})

    if (lastText.itemJoined) {
      lastText.text = add;
    } else {
      item.children.push({
        text: add,
        itemJoined: true,
      });
    }
  }
};

function getLastTextItem(children) {
  let lastItem = children[children.length - 1];
  if (!lastItem) {
    return '[**]';
  }
  if (lastItem.type === 'table') return null;
  if (isText(lastItem)) return lastItem;
  if ((isBlock(lastItem) || isInline(lastItem)) && Array.isArray(lastItem.children))
    return getLastTextItem(lastItem.children);
  return false;
}

/*

4
0    (a) hejsan;
1    (b) svejsan;
2        (i) apa; and        <- sub list
        (ii) keff; and      <- main list
3    (c) mjau

*/
