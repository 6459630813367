import * as generalUtils from '../../../utils/general';
import * as nodeUtils from '../../../utils/nodes';
import * as engineUtils from '../../utils';
import * as interfaces from '../../../interfaces';
import * as logic from '../../../utils/logic/applyLogic';
import * as slateApi from '../../../../import/slate';
import * as slateExtra from './slateExtraApi';

export const setupApi = function () {
  this.api = {
    utils: {
      general: {},
      engine: {},
      entities: {},
    },
    interfaces: {},
    logic: {},
    core: {},
    slate: {
      extra: {},
    },
  };
  for (const [utilName, util] of Object.entries(generalUtils)) {
    this.api.utils.general[utilName] = util;
  }
  for (const [utilName, util] of Object.entries(engineUtils)) {
    this.api.utils.engine[utilName] = util;
  }
  for (const [utilName, util] of Object.entries(nodeUtils)) {
    this.api.utils.entities[utilName] = util;
  }
  for (const [utilName, util] of Object.entries(interfaces)) {
    this.api.interfaces[utilName] = util;
  }
  for (const [utilName, util] of Object.entries(logic)) {
    if (utilName === 'default') this.api.logic.apply = util;
    else this.api.logic[utilName] = util;
  }
  for (const [utilName, util] of Object.entries(slateApi)) {
    this.api.slate[utilName] = util;
  }
  for (const [utilName, util] of Object.entries(slateApi)) {
    this.api.slate[utilName] = util;
  }
  for (const [utilName, util] of Object.entries(slateExtra)) {
    this.api.slate.extra[utilName] = util;
  }
};
