import { createEditor as slateCreateEditor } from '../../import/slate';
import { isInline as elementIsInline } from '../types/elements';

const withInlines = (editor) => {
  const { isInline } = editor;

  editor.isInline = (element) => (element && elementIsInline(element) ? true : isInline(element));

  return editor;
};

export const createBaseEditor = () => {
  const editor = withInlines(slateCreateEditor());
  if (!editor.meta) editor.meta = {};
  if (!editor.settings) editor.settings = {};
  if (!editor.tmp) editor.tmp = {};
  editor._convFirstLeafs = {};
  return editor;
};
