import enLang from "./entries/en-GB";
import svLang from "./entries/sv-SE";

const AppLocale = {
  en: enLang,
  sv: svLang
};

export default AppLocale;

export const supportedLanguages = {
  en: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'gb'
  },
  sv: {
    languageId: 'swedish',
    locale: 'sv',
    name: 'Swedish',
    icon: 'se'
  },
}