import {SWITCH_LANGUAGE} from "constants/ActionTypes";

const initialSettings = {
  /* navCollapsed: true,
  // navStyle: NAV_STYLE_MINI_SIDEBAR,
  // navStyle: NAV_STYLE_FIXED,
  navStyle: NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_LITE,
  themeColor: 'dark_blue',
  width: window.innerWidth,
  isDirectionRTL: false,
  pathname: '/', */
  
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'gb'
  }
};

const storedLocale = localStorage.getItem('settings.locale')
if (storedLocale) {
  try {
    const locale = JSON.parse(storedLocale)
    initialSettings.locale = locale
  } catch {}
}

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,

      };
    default:
      return state;
  }
};

export default settings;
