import React, { useState, useEffect, useMemo } from 'react';
import { Menu, Dropdown, Card, Spin } from 'antd';
import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { EditorWrapper, EditorEditable } from 'components/YjsEditor';
import { useDownloadPdfBackend, useDownloadDOCX, setContract } from 'hooks';
import { Contract } from 'core/interfaces';
import api from 'utils/api';

export default function ViewEditor({
  contract: inputContract,
  content,
  mode,
  print,
  userView,
  actionFields = {},
  locationState,
  ...rest
}) {
  const [view, setView] = useState('default');
  const [fullscreen, setFullscreen] = useState(false);
  const [contractData, setContractData] = useState(inputContract.data);
  const [pdfData, setPdfData] = useState('');

  const toggleFullscreen = () => setFullscreen(!fullscreen);

  const showViewActions = actionFields.view === false ? false : true;
  const showModeActions = actionFields.mode === false ? false : true;
  const showExportActions = actionFields.export === false ? false : true;

  const targetItemId = locationState?.item_id;

  const contract = useMemo(() => {
    if (!contractData) return inputContract;
    return { ...inputContract, data: contractData };
  }, [inputContract, contractData]);

  useEffect(() => {
    if (inputContract.data) return;
    api
      .get('/versions/' + inputContract.id + '?fields=data')
      .then((result) => {
        if (result && result.data) {
          setContract({ ...inputContract, data: result.data.data });
          setContractData(result.data.data);
          return;
        }
        console.log('Bad version result: ', result);
      })
      .catch((err) => {
        console.log('Err: ', err);
      });
  }, [inputContract]);

  useEffect(() => {
    if (!targetItemId) return;
    setTimeout(() => {
      const time = 2500;
      const elem = document.querySelector(`[iid="${targetItemId}"]`);
      if (!elem) return console.log('No elem');
      elem.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      if (!elem.classList.contains('highlighted-node')) {
        elem.classList.add('highlight-node');
        setTimeout(() => {
          elem.classList.remove('highlight-node');
          elem.classList.add('highlight-node-out');
        }, time);
        setTimeout(() => {
          elem.classList.remove('highlight-node-out');
        }, time * 2);
      }
    }, 400);
  }, [targetItemId]);

  useEffect(() => {
    if (print && !userView) return;
    async function getPDF() {
      const response = await api({
        url: '/files/pdf',
        method: 'POST',
        data: {
          versionId: contract.id,
          documentId: contract.documentId,
        },
        responseType: 'blob',
      });
      const data = await blobAsDataUrl(response.data);
      setPdfData(data);
    }
    getPDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const blobAsDataUrl = (blob) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => {
        reader.abort();
        reject(error);
      };
      reader.readAsDataURL(blob);
    });
  };

  if (!contract) {
    return (
      <Card title="" className="card">
        Invalid contract
      </Card>
    );
  }

  if (!contractData) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  const documentPageSize = Contract.getPageSize(contract);
  const documentPageOrientation = Contract.getPageOrientation(contract);
  const documentColumnSettings = Contract.getDocumentColumnSettings(contract);
  const { number: columnsNumber = 1, ignoreFrontPage: columnsIgnoreFrontPage = false } =
    documentColumnSettings;
  const settings = Contract.getSettings(contract);

  const editorContents =
    view === 'pdf' ? (
      !pdfData ? (
        'Loading PDF…'
      ) : (
        <object
          data={`${pdfData}#toolbar=0&navpanes=0&scrollbar=0`}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{ height: 'calc(100vh - 330px)' }}
        >
          <iframe
            title="Contract Preview"
            data={`${pdfData}#toolbar=0&navpanes=0&scrollbar=0`}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
          >
            <p>
              {`Your browser does not support PDFs - `}
              <a href={pdfData} download={contract.name}>
                download the PDF
              </a>
              .
            </p>
          </iframe>
        </object>
      )
    ) : (
      <div
        className={`studio-contract-live input-mode page-size-${documentPageSize} page-orientation-${documentPageOrientation}
          doc-columns-${columnsNumber}
          doc-columns-${columnsNumber > 1 ? 'multiple' : 'none'}
          doc-columns-firstpage-${!columnsIgnoreFrontPage}
          `}
      >
        <EditorWrapper onlineMode={false} contract={contract} content={content} {...rest}>
          <EditorEditable
            noHeaderCounting={settings && settings.format && settings.format.noHeaderCounting}
            print={print}
            isEditingTemplate={false}
            view
            readOnly
            onlineMode={false}
            contract={contract}
            content={content}
            {...rest}
          />
        </EditorWrapper>
       {/*  <Editor
          noHeaderCounting={settings && settings.format && settings.format.noHeaderCounting}
          print={print}
          isEditingTemplate={false}
          view
          onlineMode={false}
          contract={contract}
          content={content}
          {...rest}
        /> */}
      </div>
    );

  if (mode === 'plain') {
    return (
      <div className={fullscreen ? 'fullscreen-card bg-white' : ''}>
        {userView && (
          <div className="w-100 d-flex justify-content-end align-items-center py-3 pr-2 bg-default">
            {showViewActions && <View contract={contract} />}
            {showModeActions && <Mode contract={contract} view={view} setView={setView} />}
            {showExportActions && <Exports contract={contract} />}
            {/* <i
              className={'link ico-sm ml-4 mdi mdi-fullscreen' + (fullscreen ? '-exit' : '')}
              onClick={toggleFullscreen}
            /> */}
          </div>
        )}
        <div className="viewing-contract">{editorContents}</div>
      </div>
    );
  }

  return (
    <Card
      title="Contract Content"
      className={fullscreen ? 'fullscreen-card' : ''}
      // style={{ zIndex: '1' }}
      extra={
        <>
          {showViewActions && <View contract={contract} />}
          {showModeActions && <Mode contract={contract} view={view} setView={setView} />}
          {showExportActions && <Exports contract={contract} />}
          <i
            className={'link ico-sm ml-4 mdi mdi-fullscreen' + (fullscreen ? '-exit' : '')}
            onClick={toggleFullscreen}
          />
        </>
      }
    >
      <div className="viewing-contract">{editorContents}</div>
    </Card>
  );
}

function View({ contract }) {
  let editorElement = document.getElementById('editor-holder');
  const [viewingFields, setViewingFields] = useState(false);
  const [viewingProvisions, setViewingProvisions] = useState(false);

  const toggleViewFields = () => {
    if (!editorElement) {
      editorElement = document.getElementById('editor-holder');
    }
    if (!editorElement) return;
    setViewingFields(!viewingFields);
    editorElement.classList.toggle('template-edit');
  };
  const toggleViewProvisionState = () => {
    if (!editorElement) {
      editorElement = document.getElementById('editor-holder');
    }
    if (!editorElement) return;
    setViewingProvisions(!viewingProvisions);
    editorElement.classList.toggle('provision-state');
  };
  return (
    <Dropdown
      className="mr-5"
      overlay={
        <Menu>
          <Menu.Item key="show-fields">
            {viewingFields && <CheckOutlined />}
            <span onClick={toggleViewFields}>Show Fields</span>
          </Menu.Item>
          <Menu.Item key="show-provisions">
            {viewingProvisions && <CheckOutlined />}
            <span onClick={toggleViewProvisionState}>Show Provisions</span>
          </Menu.Item>
        </Menu>
      }
    >
      <span className="ant-dropdown-link link" onClick={(e) => e.preventDefault()}>
        View <DownOutlined />
      </span>
    </Dropdown>
  );
}

function Mode({ contract, view, setView }) {
  return (
    <Dropdown
      className="mr-5"
      overlay={
        <Menu>
          <Menu.Item
            key="view-default"
            onClick={() => {
              setView('default');
            }}
          >
            {view === 'default' && <CheckOutlined />}
            <span>Regular</span>
          </Menu.Item>
          <Menu.Item
            key="view-pdf"
            onClick={() => {
              setView('pdf');
            }}
          >
            {view === 'pdf' && <CheckOutlined />}
            <span>PDF</span>
          </Menu.Item>
        </Menu>
      }
    >
      <span className="ant-dropdown-link link" onClick={(e) => e.preventDefault()}>
        Mode <DownOutlined />
      </span>
    </Dropdown>
  );
}

function Exports({ contract }) {
  const { initDownloadPDF, downloadResultPDF, loadingPDF /* errorLoadingPDF */ } =
    useDownloadPdfBackend(contract);
  const { initDownloadDOCX, loadingDOCX } = useDownloadDOCX(contract);

  return (
    <>
      {loadingPDF && <Spin className="loader-version-exports" />}
      {loadingDOCX && <Spin className="loader-version-exports" />}
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key="dl-pdf">
              <span onClick={initDownloadPDF}>Download PDF</span>
            </Menu.Item>
            <Menu.Item key="dl-docx">
              <span onClick={initDownloadDOCX}>Download DOCX</span>
            </Menu.Item>
          </Menu>
        }
      >
        <span className="ant-dropdown-link link" onClick={(e) => e.preventDefault()}>
          Export <DownOutlined />
        </span>
      </Dropdown>
    </>
  );
}
