import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Spin } from 'antd';
import { DraftContext, ContractContext } from 'contexts/contexts';
import { useStudioSetup, useHandleActiveDraft } from './utils/studioHooks';
import { getStudioBackLocation } from './utils/various';
import { EditorWrapper } from 'components/YjsEditor/EditorWrapper';
import StudioWrapper from './containers/StudioWrapper/';
import StudioContractInputEdit from './containers/ContractInputEdit/';
import IntlMessages from 'util/IntlMessages';

const StudioHolder = (props) => {
  return (
    <MainStudio
      id={props.match.params.id}
      subject={props.match.params.subject}
      search={props.location.search}
    />
  );
};

const StudioError = ({ contract }) => {
  const history = useHistory();
  return (
    <Card title="" className="card m-3">
      <p>
        <IntlMessages id="errors.loadFail" />
      </p>
      <p>
        <IntlMessages id="studio.errors.load" />
      </p>
      <p>
        <span className="link" onClick={() => history.push(getStudioBackLocation(contract))}>
          <IntlMessages id="desc.Back" />
        </span>
      </p>
      <p>
        <span className="link" onClick={() => history.push('/projects')}>
          <IntlMessages id="general.goto" /> <IntlMessages id="general.projects" />
        </span>
      </p>
    </Card>
  );
};

const MainStudio = memo(function MainStudioX({ id, subject, search }) {
  const isTemplate = subject === 'template';

  const { contract, editor, isLoaded, loadError } = useStudioSetup({
    id,
    subject,
    search,
  });

  const { activeDraft, doneHandlingDraft } = useHandleActiveDraft(contract, editor, subject);

  if (loadError) return <StudioError contract={contract} />;

  if (!checkLoadedStudio(doneHandlingDraft, contract, isLoaded, editor)) {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        <Spin size="large" />
      </div>
    );
  }
  return (
    <>
      <DraftContext.Provider initialValue={activeDraft}>
        <ContractContext.Provider initialValue={contract} value={contract}>
          <EditorWrapper editor={editor} onlineMode={!isTemplate}>
            <StudioWrapper subject={subject}></StudioWrapper>
          </EditorWrapper>
          <StudioContractInputEdit />
        </ContractContext.Provider>
      </DraftContext.Provider>
    </>
  );
});

function checkLoadedStudio() {
  for (const arg of arguments) if (!arg) return false;
  return true;
}

export default StudioHolder;
