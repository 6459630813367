import { allOperators, conjunctions, firstKey, ruleToType, hasValue } from './common';

function subRulesHaveValidFormat(subRules, ui, errorOptions = {}) {
  const subRulesLength = Object.keys(subRules).length;

  if (subRulesLength < 1)
    return {
      message: 'Add at least one condition',
      highlight: 'facility/commitment',
    };

  for (const subRule of subRules) {
    const subValid = subRuleValidFormat(subRule, ui, errorOptions);
    if (typeof subValid === 'object') return subValid;
    if (!subValid) return false;
  }

  return true;
}
// { '>': [{ var: 'facility/commitment' }, 210] },
// { '==': [{ var: 'facility/type' }, 'term'] },
function subRuleValidFormat(subRule, ui, errorOptions = {}) {
  const operator = firstKey(subRule);
  if (!allOperators.includes(operator))
    return {
      message: 'Expected condition to begin with an operator',
      info: ['Use either of ', allOperators],
      currentString: operator,
      highlight: '>',
      ...errorOptions,
    };
  const [var1, value] = subRule[operator];
  const varFirstKey = firstKey(var1);
  if (varFirstKey !== 'var')
    return {
      message: 'Expected condition to have key `var`',
      currentString: varFirstKey,
      highlight: 'var',
      ...errorOptions,
    };
  if (typeof var1.var !== 'string')
    return {
      message: 'Expected key `var` to be of type `string`',
      currentString:
        var1.var === null ? 'null' : var1.var.hasOwnProperty('toString') ? var1.var.toString() : null,
      highlight: '"facility/amount"',
      ...errorOptions,
    };
  if (var1.var === '')
    return {
      message: 'Expected key `var` to NOT be empty string',
      highlight: '"facility/amount"',
      ...errorOptions,
    };
  if (!hasValue(value))
    return {
      message: 'Expected a value to be set',
      highlight: `110`,
      ...errorOptions,
    };
  return true;
}

// { '==': [{ all_in_card: ['activeCovenants', true] }, true] },
// { '==': [{ any_in_card: ['activeCovenants', true] }, true] },
function any_all_in_card(rule, mode) {
  try {
    const operator = firstKey(rule);
    if (!allOperators.includes(operator))
      return {
        message: 'Expected operator',
        info: ['Use either of ', allOperators],
        currentString: operator,
        highlight: '"=="',
      };

    const [obj1, mainValue] = rule[operator];

    if (typeof mainValue !== 'boolean') {
      return {
        message: 'Expected last array item to be of type boolean',
        currentString: typeof mainValue === 'string' || typeof mainValue === 'number' ? mainValue : null,
        currentStringAfter: '},',
        highlight: 'true',
        highlightAfter: '},',
      };
    }

    const ruleMode = firstKey(obj1);
    if (ruleMode !== mode + '_in_card')
      return {
        message: 'Expected initial key to be `' + mode + '_in_card`',
        currentString: ruleMode,
        highlight: mode + '_in_card',
      };

    const [path, value] = obj1[ruleMode];
    if (typeof path !== 'string')
      return {
        message: 'Expected first ' + mode + '_in_card array item to be of type `string`',
        highlight: '"activeCovenants"',
      };

    if (path === '') {
      return {
        message: 'Expected first ' + mode + '_in_card array item to to not be empty',
        currentString: '"",',
        highlight: '"activeCovenants"',
      };
    }

    if (!hasValue(value))
      return {
        message: 'Expected a value to be set',
        highlight: `true`,
      };
    return true;
  } catch (err) {
    console.log('failed validate any_all_items err ', err);
    return false;
  }
}

function any_in_card(rule) {
  return any_all_in_card(rule, 'any');
}
function all_in_card(rule) {
  return any_all_in_card(rule, 'all');
}

const any_in_card_example = { '==': [{ any_in_card: ['activeCovenants', true] }, true] };
const all_in_card_example = { '==': [{ all_in_card: ['activeCovenants', true] }, true] };

function setup(rule, ui) {
  try {
    const operator = firstKey(rule);

    if (!allOperators.includes(operator))
      return {
        message: 'Expected operator',
        info: ['Use either of ', allOperators],
        currentString: operator,
        highlight: '"=="',
      };

    const [var1, value] = rule[operator];

    const var1first = firstKey(var1);
    if (var1first !== 'setup') {
      return {
        message: 'Expected key `setup`',
        currentString: var1first,
        highlight: 'setup',
      };
    }

    if (typeof var1.setup !== 'string') {
      return {
        message: 'Expected key `setup` to be of type `string`',
        highlight: '"setupFieldName"',
      };
    }

    if (var1.local === '') {
      return {
        message: 'Expected fieldName of `setup` to to not be empty',
        highlight: '"setupFieldName"',
      };
    }

    if (!hasValue(value))
      return {
        message: 'Expected a value to be set',
        highlight: `"value"`,
      };

    return true;
  } catch (err) {
    console.log('failed validate setup err ', err);
    return false;
  }
}

const setup_example = {
  '==': [
    {
      setup: 'setupFieldName',
    },
    'value',
  ],
};

function local(rule, ui) {
  try {
    const operator = firstKey(rule);
    if (!allOperators.includes(operator))
      return {
        message: 'Expected operator',
        info: ['Use either of ', allOperators],
        currentString: operator,
        highlight: '"=="',
      };
    const [var1, value] = rule[operator];

    const var1first = firstKey(var1);
    if (var1first !== 'local') {
      return {
        message: 'Expected key `local`',
        currentString: var1first,
        highlight: 'local',
      };
    }

    if (typeof var1.local !== 'string') {
      return {
        message: 'Expected key `local` to be of type `string`',
        highlight: '"fieldName"',
      };
    }

    if (var1.local === '') {
      return {
        message: 'Expected fieldName of `local` to to not be empty',
        highlight: '"fieldName"',
      };
    }

    if (!hasValue(value))
      return {
        message: 'Expected a value to be set',
        highlight: `"value"`,
      };
    return true;
  } catch (err) {
    console.log('failed validate local err ', err);
    return false;
  }
}

const local_example = {
  '==': [
    {
      local: 'fieldName',
    },
    'value',
  ],
};

function input(rule, ui) {
  try {
    let operator = firstKey(rule);
    if (!allOperators.includes(operator))
      return {
        message: 'Expected operator',
        info: ['Use either of ', allOperators],
        currentString: operator,
        highlight: '"=="',
      };

    if (operator === '!') {
      rule = rule['!'];
      operator = firstKey(rule);
    }

    let [instructions, value] = rule[operator];
    if (operator === 'in') {
      // Switch places.
      [value, instructions] = rule[operator];
    }

    const instructionsfirst = firstKey(instructions);

    if (instructionsfirst !== 'input') {
      return {
        message: 'Expected key `input`',
        currentString: instructionsfirst,
        highlight: 'input',
      };
    }

    if (typeof instructions.input !== 'string') {
      return {
        message: 'Expected key `input` to be of type `string`',
        highlight: '"cardName.fieldName"',
      };
    }

    if (instructions.input === '') {
      return {
        message: 'Expected fieldName of `input` to to not be empty',
        highlight: '"cardName.fieldName"',
      };
    }

    if (instructions.input.split('.').length !== 2) {
      return {
        message: 'Expected fieldName to be of format `cardName.fieldName`',
        currentString: instructions.input,
        highlight: '"cardName.fieldName"',
      };
    }

    if (!hasValue(value))
      return {
        message: 'Expected a value to be set',
        highlight: `"value"`,
      };

    return true;
  } catch (err) {
    console.log('failed validate input err ', err);
    return false;
  }
}

const input_example = {
  '==': [
    {
      input: 'cardName.fieldName',
    },
    'value',
  ],
};

function numof_state(rule, ui) {
  try {
    const operator = firstKey(rule);

    if (!allOperators.includes(operator))
      return {
        message: 'Expected operator',
        info: ['Use either of ', allOperators],
        currentString: operator,
        highlight: '"=="',
      };

    const [var1, value] = rule[operator];

    const var1first = firstKey(var1);
    if (var1first !== 'numof_state') {
      return {
        message: 'Expected key `numof_state`',
        currentString: var1first,
        highlight: 'numof_state',
      };
    }

    if (typeof var1.numof_state !== 'string') {
      return {
        message: 'Expected key `numof_state` to be of type `string`',
        highlight: '"entities"',
      };
    }

    if (var1.local === '') {
      return {
        message: 'Expected fieldName of `numof_state` to to not be empty',
        highlight: '"entities"',
      };
    }

    if (typeof value !== 'number')
      return {
        message: 'Expected a number value to be set',
        highlight: `"2"`,
      };

    return true;
  } catch (err) {
    console.log('failed validate setup err ', err);
    return false;
  }
}

const numof_state_example = {
  '>': [
    {
      numof_state: 'entities',
    },
    2,
  ],
};

const numof_example = {
  '==': [
    { numof: { card: 'facility', condition: { or: [{ '>': [{ var: 'facility/name' }, 'value'] }] } } },
    1,
  ],
};

function numof(rule, ui) {
  try {
    const operator = firstKey(rule);
    if (!allOperators.includes(operator))
      return {
        message: 'Expected operator',
        info: ['Use either of ', allOperators],
        currentString: operator,
        highlight: '">="',
      };

    const [var1, value] = rule[operator];

    if (typeof var1 !== 'object')
      return {
        message: 'Expected first array item to be of type `number` or `object`',
        currentString: '',
        highlight: '1',
      };
    const var1first = firstKey(var1);
    if (var1first !== 'numof')
      return {
        message: 'Expected first array item to be of type `number` or an object with key `numof`',
        currentString: var1first,
        highlight: 'numof',
      };
    var numofFirst = firstKey(var1.numof);
    if (numofFirst !== 'card')
      return {
        message:
          'Expected first array item to be of type `number` or an object with key `numof` with key `card`',
        currentString: numofFirst,
        highlight: 'card',
      };
    if (typeof var1.numof.card !== 'string')
      return {
        message:
          'Expected first array item to be of type `number` or that the `card` property is of type string',
        currentString: '',
        highlight: 'card',
      };

    if (var1.numof.card === '')
      return {
        message: 'Expected card name of `card` to not be empty',
        currentString: 'card',
        highlight: 'facility',
      };

    if (var1.numof.condition) {
      const conditionConjunction = firstKey(var1.numof.condition);
      if (!conjunctions.includes(conditionConjunction)) {
        return {
          message: 'Expected a conjunction governing the conditions',
          info: ['Use either of ', conjunctions],
          currentString: conditionConjunction,
          currentStringAfter: 'condition',
          highlight: '"or"',
          highlightAfter: 'condition',
        };
      }

      const subRules = var1.numof.condition[conditionConjunction];

      const subRulesValid = subRulesHaveValidFormat(subRules, ui, {
        currentStringAfter: 'condition',
        highlightAfter: 'condition',
      });

      if (typeof subRulesValid === 'object') return subRulesValid;
    }

    const valueIsNumber = !isNaN(parseFloat(value));

    if (!valueIsNumber) {
      if (typeof value !== 'object')
        return {
          message: 'Expected last array item to be of type `number` or `object`',
          currentString: '',
          highlight: '1',
        };
      const valueFirst = firstKey(value);
      if (valueFirst !== 'numof')
        return {
          message: 'Expected last array item to be of type `number` or an object with key `numof`',
          currentString: '',
          highlight: '1',
        };
      const valueNumofFrist = firstKey(value.numof);
      if (valueNumofFrist !== 'card')
        return {
          message:
            'Expected last array item to be of type `number` or an object with key `numof` with key `card`',
          currentString: 'numof',
          currentStringAfter: 'condition',
          highlight: '1',
        };

      if (typeof value.numof.card !== 'string')
        return {
          message:
            'Expected last array item to be of type `number` or that the `card` property is of type string',
          currentString: 'card',
          currentStringAfter: 'condition',
          highlight: 'card',
        };

      if (value.numof.card === '')
        return {
          message: 'Expected card name of `card` to not be empty',
          currentString: 'card',
          currentStringAfter: 'condition',
          highlight: 'facility',
        };
    }

    return true;
  } catch (err) {
    console.log('failed validate count_repeatable err ', err);
    return false;
  }
}

function conjunction(rule, ui) {
  const conj = firstKey(rule);
  if (!conjunctions.includes(conj))
    return {
      message: 'Expected condition to begin with a conjunction',
      info: ['Use either of ', conjunctions],
      // currentString: operator,
      // highlight: '>',
    };
  if (!Array.isArray(rule[conj]))
    return {
      message: 'Content of the group should be an array (directly after the conjunction `' + conj + '`)',
    };

  // Check all children:
  for (let i = 0; i < rule[conj].length; i++) {
    const subRule = rule[conj][i];
    // for (const subRule of rule[conj]) {
    const subRuleType = ruleToType(subRule);
    if (!subRuleType) {
      console.log('Invalid ', subRule);
      return {
        message: 'Cannot validate rule type of rule number ' + (i + 1),
      };
    }
    const subRuleValid = validate(subRule, ui, subRuleType);
    if (subRuleValid.valid === true) continue;
    if (subRuleValid === false) return { valid: false, error: null, highlight: null };
    if (subRuleValid && typeof subRuleValid === 'object' && subRuleValid.error) {
      return subRuleValid.error;
    }
  }
  return true;
}

const validators = {
  any_in_card,
  all_in_card,
  setup,
  local,
  input,
  numof_state,
  numof,
  conjunction,
};

const examples = {
  any_in_card_example,
  all_in_card_example,
  setup_example,
  local_example,
  input_example,
  numof_state_example,
  numof_example,
};

export default function validate(rule, ui, type) {
  if (!validators.hasOwnProperty(type)) {
    console.log('No validator for type: ' + type);
    return false;
  }
  const isValid = validators[type](rule, ui);
  if (typeof isValid === 'boolean') return { valid: isValid, error: null, highlight: null };
  if (isValid && typeof isValid === 'object') {
    if (type === 'conjunction') return { valid: false, error: isValid };
    return {
      valid: false,
      error: { ...isValid, highlight: htmlError(type, isValid), line: lineError(type, rule, isValid) },
    };
  }
}

function htmlError(type, error) {
  if (!examples.hasOwnProperty(type + '_example')) {
    return '';
  }
  const example = examples[type + '_example'];
  let ruleText = JSON.stringify(example, null, 2);
  if (error.highlight) {
    let highlightAfterIndex;
    if (error.highlightAfter) {
      const tmp = ruleText.indexOf(error.highlightAfter);
      if (tmp > -1) highlightAfterIndex = tmp;
    }

    const index = ruleText.indexOf(error.highlight, highlightAfterIndex);
    if (index === -1) {
      // console.log('Did not find ', { needle: error.highlight, haystack: ruleText });
      return '';
    }
    const highlightLength = error.highlight.length;

    const highlightedRuleText =
      ruleText.substr(0, index) +
      '<span style="color: red;font-weight: 600;#ff5555;text-decoration: underline;">' +
      ruleText.substr(index, highlightLength) +
      '</span>' +
      ruleText.substr(index + highlightLength);
    return highlightedRuleText;
  }
  return ruleText;
}

function lineError(type, rule, error) {
  if (!error || !error.currentString) return 0;

  const stringedRule = JSON.stringify(rule, null, 2);

  const lines = stringedRule.split('\n');
  const linesLength = lines.length;
  let i = 0;

  if (error.currentStringAfter) {
    let stringAfterLine = lines.findIndex((x) => x.indexOf(error.currentStringAfter) > -1);
    if (stringAfterLine > -1) i = stringAfterLine + 1;
  }

  for (; i < linesLength; i++) {
    const line = lines[i];
    if (line.indexOf(error.currentString) > -1) {
      return i + 1;
    }
  }
  return 0;
}
