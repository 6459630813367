import uuid from 'uuid-random';
import { uniqueItemIds } from 'core/engine/utils';

function addTableKey(node) {
  if (node.type === 'table_row') node.key = `row_${uuid()}`;
  if (node.type === 'table_cell') node.key = `cell_${uuid()}`;
}

function finaliseFragment(children) {
  const newFragment = [];
  for (const child of children) {
    const { _styles, _pasteInfo, ...node } = child;
    addTableKey(node);
    if (node.children) {
      node.children = finaliseFragment(child.children);
    }
    newFragment.push(node);
  }
  return newFragment;
}

function wrapFragments(children) {
  const newFragment = [];
  const wrapLevelToStartIndex = {};

  const addWrapData = (type, level, index, wrapped) => {
    if (!wrapLevelToStartIndex[type]) wrapLevelToStartIndex[type] = {};
    if (!wrapLevelToStartIndex[type][level]) wrapLevelToStartIndex[type][level] = [];
    wrapLevelToStartIndex[type][level].push({ startIndex: index, wrapped });
  };

  for (let i = 0; i < children.length; i++) {
    let node = children[i];
    if (!node.children) {
      newFragment.push(node);
      continue;
    }
    node.children = wrapFragments(node.children);
    if (!node._pasteInfo?.wrap) {
      newFragment.push(node);
      continue;
    }

    const { type, level } = node._pasteInfo.wrap;

    if (wrapLevelToStartIndex[type]?.[level]) {
      const levelData = wrapLevelToStartIndex[type][level];
      for (const { startIndex, wrapped } of levelData) {
        if (startIndex < i) {
          wrapped.children.push(node);
        }
      }
    } else {
      const wrapper = {
        type,
        children: [node],
      };
      if (level > 1) {
        const levelAbove = level - 1;
        if (wrapLevelToStartIndex[type]?.[levelAbove]) {
          const levelData = wrapLevelToStartIndex[type][levelAbove];
          for (const { startIndex, wrapped } of levelData) {
            if (startIndex < i) {
              wrapped.children.push(wrapper);
              addWrapData(type, level, i, wrapper);
            }
          }
          continue;
        }
      }

      newFragment.push(wrapper);
      addWrapData(type, level, i, wrapper);
    }
  }
  return newFragment;
}

const TRANSFORM_ELEMENTS = [
  {
    if: (node) => node._styles && node._styles.listLevel,
    transform: (node, parents, index, siblings, actions) => {
      // console.log('Transform ', { node, parents });
      node.type = 'list_item';
      if (node._styles.msoLevelNumberFormat === 'bullet') {
        actions.wrapIn('bulleted_list', node, node._styles.listLevel);
      } else {
        actions.wrapIn('numbered_list', node, node._styles.listLevel);
      }
    },
  },
];

export const normalizePastedFragment = (children, parents = []) => {
  let newFragment = [];
  let onlyText = false;
  const newContentData = [];
  const actionData = {
    wrapInType: {},
    wrapped: {},
    removeIndices: [],
  };

  const addNode = (origin, node, index) => newContentData.push({ origin, node, index });
  const addFinalNode = (node) => newFragment.push(node);

  const makeActions = (index) => ({
    wrapIn: (type, node, level = 1) => {
      if (!node._pasteInfo) node._pasteInfo = {};
      node._pasteInfo.wrap = { type, level };
    },
    remove: () => actionData.removeIndices.push(index),
    removePrev: () => actionData.removeIndices.push(index - 1),
    removeNext: () => actionData.removeIndices.push(index + 1),
  });

  for (let i = 0; i < children.length; i++) {
    let node = children[i];
    if (node.hasOwnProperty('text')) {
      addNode('normal', node);
      onlyText = true;
      continue;
    }
    onlyText = false;
    if (node.children) node.children = normalizePastedFragment(node.children, [node, ...parents]);

    for (const transfomer of TRANSFORM_ELEMENTS) {
      if (transfomer.if(node)) {
        const transformedNode = transfomer.transform(node, parents, i, children, makeActions(i));
        if (transformedNode) {
          // console.log('Transformed node ', transformedNode);
        }
      }
    }
    addNode('normal', node);
  }

  for (const { origin, node, index } of newContentData) {
    if (origin === 'normal') addFinalNode(node);
    else if (origin === 'wrapped') addFinalNode(actionData.wrapped[index]);
  }

  if (onlyText) {
    newFragment = newFragment.filter((item, index, siblings) => {
      if (item.text === '' && siblings.find((sibling) => sibling.text !== '')) return false;
      return true;
    });
  } else {
    uniqueItemIds(newFragment);
  }

  const finalFragments = finaliseFragment(wrapFragments(newFragment));

  return finalFragments;
};
