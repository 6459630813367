export const eachesOnRepeatableRemoveSlate = {
  id: 'eachesOnRepeatableRemoveSlate',
  dependencies: { repeatableRemove: true },
  match: ({ entries, node }) => {
    if (!node.data || !node.data._path) return false;
    for (const entry of entries) {
      return node.data && node.data._path && node.data._path === entry.path;
    }
  },
  handler: function ({ matches }) {
    for (const { actions } of matches.default) {
      actions.removeNode();
    }
    // Do item join to clean up...?
  },
};
