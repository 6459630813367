import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Tooltip, Divider } from 'antd';
import { useIntlMessage } from 'util/IntlMessages';

const Reply = ({ setReply, setEdit, deleteThis, author }) => {
  const { id } = useSelector((state) => state.auth.user);
  const formatMessage = useIntlMessage();

  const replyLabel = formatMessage('app.messages.doReply', { cap: true });
  const removeLabel = formatMessage('desc.remove', { cap: true });
  const editLabel = formatMessage('app.general.Edit');

  const actionButtons = [
    {
      name: 'reply',
      tooltip: replyLabel,
      icon: 'reply',
      action: () => setReply(),
    },
    {
      name: 'remove',
      tooltip: removeLabel,
      icon: 'delete',
      action: () => deleteThis(),
    },
    {
      name: 'edit',
      tooltip: editLabel,
      icon: 'border-color',
      action: () => setEdit(),
    },
  ];

  const loadActionButtons = () => {
    const actionsLength = actionButtons.length;
    
    return actionButtons.map((btn, i) => {
      return (
        <span key={i}>
          <span>
            <Tooltip title={btn.tooltip} placement="top">
              <span className="link inverse fs-sm" onClick={() => btn.action()}>
                {btn.tooltip}
              </span>
            </Tooltip>
          </span>
          {i + 1 !== actionsLength && <Divider type="vertical" />}
        </span>
      );
    });
  };

  return <div>{loadActionButtons()}</div>;
};

export default Reply;
