import { useCallback, useEffect, useState, useMemo } from 'react';
import { Row, Col, Card, Tag } from 'antd';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { ActionButton, AutoModal, fixDate } from 'components/ui';
import { useFetch } from '../../../../../hooks/api';
import { SecondaryActionsSkeleton } from '../../../../../components/Skeletons/Overview';
import Files from 'components/document/Files';
import Export from 'components/document/Export';
import Shared from 'components/document/Shared';
import Signings from 'components/document/Signing';
import { hasFeatureFlag } from 'utils/general';
import Obligations from 'components/document/Obligations';
import EventSubscriptions from 'components/project/EventSubscriptions/EventSubscriptions';
import { ConversationsButton } from 'routes/studio/components/Conversations/ConversationsContainer';

export const SecondaryActions = ({
  doc,
  setDocument,
  currentVersion,
  versions,
  setVersions,
  selectVersion,
}) => {
  if (!doc || !currentVersion || !selectVersion) {
    return <SecondaryActionsSkeleton title={<IntlMessages id="app.general.Actions" cap />} />;
  }

  return (
    <Card className="widget bg-transparent" title={<IntlMessages id="app.general.Actions" cap />}>
      <div className="overview-actions">
        <FilesButton doc={doc} />
        <SharedButton doc={doc} currentVersion={currentVersion} />
        <SigningsButton doc={doc} currentVersion={currentVersion} setDocument={setDocument} />

        {hasFeatureFlag('deployment') && (
          <AutoModal
            trigger={
              <ActionButton
                type="secondary"
                title={<IntlMessages id="app.general.Export" cap />}
                icon="mdi mdi-file-export"
              />
            }
            title={<IntlMessages id="app.general.Export" cap />}
          >
            <Export versionId={currentVersion.id} />
          </AutoModal>
        )}

        <SubscriptionsButton doc={doc} currentVersion={currentVersion} />

        {/* <AutoModal
          trigger={
            <ActionButton
              type="secondary"
              title={<IntlMessages id="apply.obligations" cap />}
              icon="mdi mdi-shield-lock-open"
            />
          }
          title={<IntlMessages id="apply.obligations" cap />}
          className="modal-md"
        >
          <Obligations doc={doc} />
        </AutoModal> */}

        {/* <ConversationsAction /> */}
      </div>
    </Card>
  );
};

function getFilesUrl(doc) {
  if (!doc || !doc.id) return '';
  return `/superfiles?resourceType=Document&resourceId=${doc.id}&fetchType=resource&fetchLimit=0`;
}

function getSubscriptionsUrl(doc) {
  if (!doc || !doc.id) return '';
  return `/eventsubscribers/my?resourceType=Document&resourceId=${doc.id}&mode=count`;
}

function getInvitationsUrl(doc) {
  if (!doc?.id) return '';
  const include = encodeURI(JSON.stringify([{ model: 'Entity', as: 'Entity' }]));
  const query = `where=documentId_->_${doc.id}&include=${include}`;
  return `/entityinvitations?${query}`;
}

function getSigningsUrl(doc) {
  if (!doc?.id) return '';
  const include = encodeURI(JSON.stringify([{ model: 'Superfile' }]));
  const query = `include=${include}&where=status_!->_deleted`;
  return `/documents/${doc.id}/documentsignings?${query}`;
}

function FilesButton({ doc }) {
  const [files] = useFetch(getFilesUrl(doc));
  const [length, setLength] = useState(0);

  const updateLength = useCallback((num) => setLength(num), []);

  useEffect(() => updateLength(files?.length || 0), [files, updateLength]);

  return (
    <AutoModal
      trigger={
        <ActionButton
          type="secondary"
          title={<IntlMessages id="app.project.menus.Files" cap />}
          icon="mdi mdi-file-document"
          count={length}
        />
      }
      title={<IntlMessages id="app.project.menus.Files" cap />}
      className="modal-md"
    >
      <Files doc={doc} initialFiles={files} updateLength={updateLength} />
    </AutoModal>
  );
}

function SharedButton({ doc, currentVersion }) {
  const [invitations] = useFetch(getInvitationsUrl(doc));
  const [length, setLength] = useState(0);

  const updateLength = useCallback((num) => setLength(num), []);

  useEffect(() => updateLength(invitations?.length || 0), [invitations, updateLength]);

  return (
    <AutoModal
      trigger={
        <ActionButton
          type="secondary"
          title={<IntlMessages id="app.general.Shared" cap />}
          icon="mdi mdi-account-group"
          count={length}
        />
      }
      title={<IntlMessages id="app.general.Shared" cap />}
      className="modal-md"
    >
      <Shared
        documentId={doc.id}
        projectId={doc.projectId}
        currentVersion={currentVersion}
        initialInvitations={invitations}
        updateLength={updateLength}
      />
    </AutoModal>
  );
}

function SigningsButton({ doc, currentVersion, setDocument }) {
  const [signings] = useFetch(getSigningsUrl(doc));
  const [length, setLength] = useState(0);

  const updateLength = useCallback((num) => setLength(num), []);

  useEffect(() => updateLength(signings?.length || 0), [signings, updateLength]);

  return (
    <AutoModal
      trigger={
        <ActionButton
          type="secondary"
          title={<IntlMessages id="app.signing.sign" cap />}
          icon="mdi mdi-pen"
          count={length}
        />
      }
      title={<IntlMessages id="app.signing.sign" cap />}
      className="modal-md"
    >
      <Signings
        doc={doc}
        setDocument={setDocument}
        currentVersion={currentVersion}
        initialSignings={signings}
        updateLength={updateLength}
      />
    </AutoModal>
  );
}

function SubscriptionsButton({ doc, currentVersion }) {
  const [number] = useFetch(getSubscriptionsUrl(doc));

  const ownerResourcesData = useMemo(
    () => ({
      Project: doc.projectId,
    }),
    [doc.projectId]
  );
  const extraResource = useMemo(
    () => ({
      resourceType: 'Version',
      resourceId: currentVersion.id,
    }),
    [currentVersion.id]
  );
  return (
    <AutoModal
      trigger={
        <ActionButton
          type="secondary"
          title={<IntlMessages id="app.project.menus.Subscriptions" cap />}
          icon={<i className="mdi mdi-bell" />}
          count={number && number.count}
        />
      }
      title={<IntlMessages id="app.project.menus.Subscriptions" cap />}
      className="modal-lg"
    >
      <EventSubscriptions
        resourceType="Document"
        resourceId={doc?.id}
        ownerResourcesData={ownerResourcesData}
        extraResource={extraResource}
        extraResourceIntlId={'general.currentVersion'}
      />
    </AutoModal>
  );
}

/*
function InfoItem() {
  const { numConv = 0, numConvPosts = 0, numConvUncompleted = 0 } = useSelector(({ draft }) => draft);

  const solved = numConv - numConvUncompleted;

  return (
    <div className="mt-4 ml-3">
      <table>
        <tbody>
          <tr>
            <td className="fs-sm pb-2">
              <Tag color="">x / y</Tag>
            </td>
            <td className="fs-sm pb-2">parties approved document</td>
          </tr>
          <tr>
            <td className="fs-sm pb-2">
              <Tag color={'green'}>
                {solved} / {numConv}
              </Tag>{' '}
            </td>
            <td className="fs-sm pb-2">
              <ConversationsButton
                btn={<span className="link">conversations solved</span>}
                tooltipPosition="top"
                withBadge={false}
                withTooltip={false}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
function ConversationsAction() {
  const { numConv = 0, numConvPosts = 0, numConvUncompleted = 0 } = useSelector(({ draft }) => draft);

  return (
    <ConversationsButton
      btn={
        <ActionButton
          type="secondary"
          title={'Conversations'}
          icon="mdi mdi-comment"
          // count={numConv}
        />
      }
      tooltipPosition="top"
    />
  );
}
*/
