import { UPDATE_INPUT, UPDATE_INPUTS, ADD_REPEATABLE, REMOVE_REPEATABLE } from 'constants/ActionTypes';
import { getContractValues } from 'hooks';
import { InputPaths, State } from 'core/interfaces';
import config from 'config/config';
const { reduxEngine } = config;

let uiModules = {};
const REPEATABLE_TYPES = [ADD_REPEATABLE, REMOVE_REPEATABLE];
const { REPEATABLE_ADDED_TIMEOUT, MODULES_ADDED_TIMEOUT, MODULES_AWAIT_TIME } = reduxEngine;

const { INPUT_LOCATION_SEPARATOR } = InputPaths;

// action set when initially invoked.
// payloads set when invoked by itself (to collect payloads by previously invoked modiles).
function getAdditionalPayloads(action, state) {
  let additionalPayloads;
  if (action) {
    const moduleDataEntries = makeModuleDataEntries(action);
    // console.log('moduleDataEntries', { moduleDataEntries });
    if (moduleDataEntries.length === 0) return null;

    additionalPayloads = triggerInputModules(moduleDataEntries, state);

    if (additionalPayloads) {
      const extraAction = { type: UPDATE_INPUTS, payload: additionalPayloads };
      const subsequentPayloads = getAdditionalPayloads(extraAction, state);
      if (subsequentPayloads) {
        additionalPayloads = additionalPayloads.concat(subsequentPayloads);
        // console.log('subsequentPayloads ', subsequentPayloads);
      }
    }

    return additionalPayloads;
  }
}

export const inputModule = ({ getState, dispatch }) => {
  return (next) => (action) => {
    // return next(action);

    let additionalPayloads;
    const newActionRequired = ![UPDATE_INPUTS].includes(action.type);
    if ([UPDATE_INPUT, UPDATE_INPUTS, ADD_REPEATABLE, REMOVE_REPEATABLE].includes(action.type)) {
      const state = getState();
      additionalPayloads = getAdditionalPayloads(action, state);
      if (!additionalPayloads) return next(action);
      // console.log('Additional payloads . . .', additionalPayloads);
    }

    // If no additional payloads, return early
    // calling next middleware.
    if (!additionalPayloads || additionalPayloads.length === 0) return next(action);

    // If update_inputs, we can just add the
    // additional payloads to the current one(s).
    if (!newActionRequired) {
      action.payload = action.payload.concat(additionalPayloads);
    }

    // If new action is required, add information of that
    // to the current action. The draft-saga will then not
    // produce a draft session (which instead will be
    // initiated upon the following, new action)
    if (newActionRequired) {
      if (action.meta) action.meta.actionsToFollow = true;
      else action.meta = { actionsToFollow: true };
    }

    // Proceeding to next middleware and the dispatchment.
    const returnValue = next(action);

    // If we need to dispatch additional payloads,
    // but the original action was not suited to add
    // those payloads to, then we dispatch a new
    // action.
    // NOTE: We dispatch it within a setTimeout
    // to ensure that it is made in a new `tick`
    // and the original action having been properly
    // dispatched and propagated first.
    if (newActionRequired) {
      dispatch({
        type: UPDATE_INPUTS,
        payload: additionalPayloads,
      });
      return;
    }
    return returnValue;
  };
};

function makeModuleDataEntries(action) {
  const { payload, meta } = action;
  const contract = getContractValues();
  switch (action.type) {
    case UPDATE_INPUTS:
      return payload
        .map((item) => [
          {
            trigger: InputPaths.toTriggerPath(contract, item.path),
            path: item.path,
            reason: 'modified',
            value: item.value,
            card: InputPaths.getCardId(contract, item.path),
            fieldName: InputPaths.getFieldName(contract, item.path),
            uid: InputPaths.getCardUid(contract, item.path),
          },
          {
            trigger: InputPaths.getCardId(contract, item.path) + INPUT_LOCATION_SEPARATOR + '*',
            path: item.path,
            reason: 'modified',
            value: item.value,
            card: InputPaths.getCardId(contract, item.path),
            fieldName: InputPaths.getFieldName(contract, item.path),
            uid: InputPaths.getCardUid(contract, item.path),
          },
        ])
        .flat();
    case UPDATE_INPUT:
      return [
        {
          trigger: InputPaths.toTriggerPath(contract, payload.path),
          path: payload.path,
          reason: 'modified',
          value: payload.value,
          card: InputPaths.getCardId(contract, payload.path),
          fieldName: InputPaths.getFieldName(contract, payload.path),
          uid: InputPaths.getCardUid(contract, payload.path),
        },
        {
          trigger: InputPaths.getCardId(contract, payload.path) + INPUT_LOCATION_SEPARATOR + '*',
          path: payload.path,
          reason: 'modified',
          value: payload.value,
          card: InputPaths.getCardId(contract, payload.path),
          fieldName: InputPaths.getFieldName(contract, payload.path),
          uid: InputPaths.getCardUid(contract, payload.path),
        },
      ];
    case ADD_REPEATABLE:
      const cardId = payload.path.split('.').slice(-1)[0];
      return Object.keys(payload.values)
        .filter((fieldName) => fieldName.substr(0, 1) !== '_')
        .map((fieldName) => ({
          trigger: cardId + INPUT_LOCATION_SEPARATOR + fieldName,
          path: payload.path + '.*',
          reason: 'added',
          value: payload.values[fieldName],
          card: cardId,
          fieldName,
          uid: meta.uid,
        }))
        .concat({
          trigger: cardId + INPUT_LOCATION_SEPARATOR + '*',
          path: payload.path + '.*',
          reason: 'added',
          value: payload.values,
          card: cardId,
          fieldName: InputPaths.getFieldName(contract, payload.path),
          uid: meta.uid,
        });
    default:
      return [];
  }
}

function triggerInputModules(moduleDataEntries, state) {
  let additionalPayloads = [];

  const singleModeModules = [];
  const multipleModeModules = new Map();

  for (const entry of moduleDataEntries) {
    const inputModules = uiModules[entry.trigger];

    if (inputModules && inputModules.length > 0) {
      for (const { moduleFunction, mode, contract } of inputModules) {
        const entryWithContract = { ...entry, contract };
        if (mode === 'single') {
          singleModeModules.push([moduleFunction, entryWithContract]);
        } else {
          const moduleData = multipleModeModules.get(moduleFunction);
          if (moduleData) {
            multipleModeModules.set(moduleFunction, moduleData.concat(entryWithContract));
          } else {
            multipleModeModules.set(moduleFunction, [entryWithContract]);
          }
        }
      }
    }
  }
  /* console.log("Module Data Entries ", {
    moduleDataEntries,
    singleModeModules,
    multipleModeModules,
    uiModules,
  }); */

  const inputModuleToExecute = [...multipleModeModules, ...singleModeModules];

  for (const [moduleFunction, values] of inputModuleToExecute) {
    try {
      if (window?.debug) console.log('Run Module Function', { values });
      const additionalPayload = moduleFunction(values, State.produceContractState(state));

      if (!additionalPayload) continue;
      if (Array.isArray(additionalPayload)) {
        if (additionalPayload.length === 0) continue;
      } else if (typeof additionalPayload !== 'object') continue;
      additionalPayloads = additionalPayloads.concat(additionalPayload);
    } catch (err) {
      console.log('Error running module for trigger ', { values }, err);
    }
  }
  return additionalPayloads;
}

export function clearUiModules() {
  uiModules = {};
}

export function addUiModules(uiMods, contract) {
  uiModules = {};
  if (!Array.isArray(uiMods)) return;
  for (const uiModule of uiMods) {
    if (!uiModule.name) continue;
    // if (!uiModule.types || !uiModule.types.includes("ui")) continue;
    const path = (uiModule.folder ? uiModule.folder + '/' : '') + uiModule.name + '/inputs';
    try {
      let mod = require('../../core/modules/input/' + path);
      if (typeof mod !== 'object') {
        console.warn('Could not load module ' + uiModule.name + ' from ', path);
        continue;
      }
      if (!mod.default || !Array.isArray(mod.default)) {
        console.warn('module ' + uiModule.name + ' had no inputs');
        continue;
      }
      const moduleInputs = mod.default;
      if (Array.isArray(moduleInputs)) {
        for (const moduleInput of moduleInputs) {
          if (typeof moduleInput.trigger !== 'string') {
            console.warn(uiModule.name + ': Trigger not a string', moduleInput);
            continue;
          }
          if (typeof moduleInput.function !== 'function') {
            console.warn(uiModule.name + ': Function not a function', moduleInput);
            continue;
          }
          addUiModule(moduleInput, contract);
        }
        continue; // Go to next uiModule
      }
      if (typeof moduleInputs === 'object') {
        const moduleInput = moduleInputs;
        if (typeof moduleInput.trigger !== 'string') {
          console.warn(uiModule.name + ': Trigger not a string', moduleInput);
          continue;
        }
        if (typeof moduleInput.function !== 'function') {
          console.warn(uiModule.name + ': Function not a function', moduleInput);
          continue;
        }
        addUiModule(moduleInput, contract);
      }
      // console.log('Will load load module '+uiModule.name+' from ', mod);
    } catch (e) {
      console.warn('1. Failed module catching', uiModules, e);
    }
  }
  // console.log("Ui modules are", uiModules);
}

function addUiModule(inputModule, contract) {
  // console.log('Added trigger: ', trigger)
  const { trigger, function: moduleFunction, mode } = inputModule;
  if (!uiModules.hasOwnProperty(trigger)) uiModules[trigger] = [{ moduleFunction, mode, contract }];
  else uiModules[trigger].push({ moduleFunction, mode, contract });
}
