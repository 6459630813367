import React, { useState, useEffect, useCallback } from 'react';
import { Card, Table, Spin, Dropdown, Menu, Tooltip, Tag } from 'antd';
import api from 'utils/api';
import { useSearch } from 'hooks';
import NewMemberButton from 'components/project/NewMember/NewMemberButton';
import { RemoveModal } from 'components/ui';
import IntlMessages from 'util/IntlMessages';
import { EntityEmails } from 'components/ui';

const { Column } = Table;

const config = {
  pagination: false,
};

export default function ProjectMembers({ project, initialMembers, updateLength }) {
  const hasInitialMembers = !!initialMembers;
  const [members, setMembers] = useState(null || initialMembers);
  const [membersError, setMembersError] = useState(null);

  const fetchMembers = useCallback(() => {
    api
      .get(
        `/permissions/entries?resourceType=Project&resourceId=${project.id}&accessorType=User&actions&byEntities`
      )
      .then((result) => {
        if (!result?.data) return;
        setMembers(result.data);
        updateLength(result.data.length);
      })
      .catch((err) => setMembersError(JSON.stringify(err.response)));
  }, [project.id, updateLength]);

  useEffect(() => {
    if (hasInitialMembers) return;
    fetchMembers();
  }, [fetchMembers, hasInitialMembers]);

  const deleteMember = (entity) => {
    if (!entity) return;
    const { entries } = entity;
    if (!Array.isArray(entries)) return;
    const permissionIds = entries.map((e) => e.id);
    const promises = [];
    permissionIds.forEach((id) => {
      promises.push(api.delete(`/permissions/${id}`));
    });
    Promise.allSettled(promises).then((res) => {
      fetchMembers();
    });
  };

  const onAddMember = (data) => {
    fetchMembers();
    return;
  };

  const [filteredMembers, searchElement] = useSearch(members, {
    element: true,
    fields: ['firstName', 'lastName'],
    // fields: ['firstName', 'lastName', 'role_name', 'email'],
  });

  if (!members) {
    return (
      <Card title="" className="card">
        <Spin className="loader-container" />
      </Card>
    );
  }
  if (membersError) {
    return (
      <Card title="" className="card">
        {membersError}
      </Card>
    );
  }

  return (
    <Card
      className="full-table-view white-th"
      extra={
        <div className="d-flex">
          <div className="mr-3">{searchElement}</div>
          <div>
            <NewMemberButton project={project} onAddMember={onAddMember} />
          </div>
        </div>
      }
    >
      <Table
        rowKey={(record) => record.id}
        className="table-responsive documents-table"
        {...config}
        dataSource={filteredMembers}
      >
        <Column title={<IntlMessages id="app.general.FirstName" />} dataIndex="firstName" key="firstName" />
        <Column title={<IntlMessages id="app.general.LastName" />} dataIndex="lastName" key="lastName" />
        <Column
          title={<IntlMessages id="app.general.Email" />}
          dataIndex="email"
          key="email"
          render={(text, record) => {
            return <EntityEmails entity={record} />;
          }}
        />
        <Column
          title={
            <span>
              <IntlMessages id="app.general.ProjectRole" />{' '}
              <Tooltip title={<IntlMessages id="app.general.ProjectRoleDesc" />}>
                <i className="mdi mdi-help-circle" />
              </Tooltip>
            </span>
          }
          dataIndex="groupName"
          key="groupName"
          render={(text, record) => {
            const { entries } = record;
            return (
              entries &&
              entries.length > 0 &&
              entries.map((entry) => <Tag key={entry.id}>{entry.groupName}</Tag>)
            );
          }}
        />
        <Column
          title={<IntlMessages id="app.general.Actions" />}
          key="actions"
          render={(text, record) => {
            return (
              <span>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="remove">
                        <RemoveModal
                          onConfirm={() => deleteMember(record)}
                          confirmText={
                            <>
                              <IntlMessages id="app.general.confirmRemoval" />{' '}
                              <IntlMessages id="general.member" />?
                            </>
                          }
                        >
                          <span className="">
                            <IntlMessages id="desc.Remove" /> <IntlMessages id="general.member" />
                          </span>
                        </RemoveModal>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <span className="link ant-dropdown-link">
                    <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
                  </span>
                </Dropdown>
              </span>
            );
          }}
        />
      </Table>
    </Card>
  );
}
