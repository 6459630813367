import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDraft } from 'appRedux/actions';
import { notification } from 'antd';
import { useIntlMessage } from 'util/IntlMessages';

export function gatherEntityIds(conversations) {
  return [
    ...new Set(
      Object.values(conversations)
        .map((c) => [
          ...(c.posts?.length ? c.posts : []).map((p) => p.by.entityId),
          ...(c.eId ? [c.eId] : []),
        ])
        .flat()
    ),
  ];
}

function getNewConversations(previousConversations, nextConversations, entityId) {
  const newConvs = [];
  for (const [id, newValues] of Object.entries(nextConversations)) {
    const _newPosts = getNewConversationPosts(previousConversations[id], newValues, entityId);
    const _isNew = !previousConversations[id];
    if ((_isNew || _newPosts.length) && newValues.eId !== entityId) {
      newConvs.push({
        id,
        ...newValues,
        _isNew,
        _newPosts: getNewConversationPosts(previousConversations[id], newValues, entityId),
      });
    }
  }
  return newConvs;
}
function getNewConversationPosts(prevValues, newValues, entityId) {
  if (!newValues.posts || newValues.posts.length === 0) return [];

  if (!prevValues || !prevValues.posts || prevValues.posts.length === 0)
    return newValues.posts.filter((post) => post.by?.entityId !== entityId);

  return newValues.posts.filter(
    (newPost) =>
      newPost.by?.entityId !== entityId && !prevValues.posts.find((prevPost) => prevPost.id === newPost.id)
  );
}

export function useConversationsUpdater(conversations) {
  const previousConversationsRef = useRef(conversations);
  const isLoaded = useRef();
  const dispatch = useDispatch();
  const { entityId } = useSelector(({ auth }) => auth.user);
  const formatMessage = useIntlMessage();

  useEffect(() => {
    const clear = () => (previousConversationsRef.current = conversations);

    if (!isLoaded.current) {
      return clear();
    }

    const newConversations = getNewConversations(previousConversationsRef.current, conversations, entityId);
    if (!newConversations || newConversations.length === 0) return clear();

    const content = [];
    for (const newConv of newConversations) {
      const { _isNew, _newPosts = [] } = newConv;
      if (_isNew) {
        content.push(
          <li key={newConv.id}>
            {formatMessage('studio.conversations.newConversation', 'cap')}
            <small
              onClick={() => dispatch(setDraft('viewingConversationId', newConv.id))}
              className="ml-2 link"
            >
              {formatMessage('general.view', 'cap')}
            </small>
          </li>
        );
      } else if (_newPosts.length) {
        for (const newPost of _newPosts) {
          content.push(
            <li key={newPost.id}>
              {formatMessage('studio.conversations.newPost', 'cap')}
              <small
                onClick={() => dispatch(setDraft('viewingConversationId', newConv.id))}
                className="ml-2 link"
              >
                {formatMessage('general.view', 'cap')}
              </small>
            </li>
          );
        }
      }
      if (!content.length) return clear();
      notification.info({
        message: formatMessage('studio.conversations.conversationsUpdate', 'cap'),
        description: (
          <div>
            <ul>{content}</ul>
          </div>
        ),
      });
    }
    console.log('New conversations! ', { conversations, newConversations });
    clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations, dispatch]);

  useEffect(() => {
    if (!conversations || Object.keys(conversations).length === 0) return;
    isLoaded.current = true;
  }, [conversations]);
}
