import React from 'react';
import { useContract, setContract } from 'hooks';
import { Contract } from 'core/interfaces';
import CustomScrollbars from 'util/CustomScrollbars';
import { array_move } from 'core/utils/general';
import { Alert, Button } from 'antd';

export default function ArrangeInputs({ selected }) {
  const contract = useContract();
  const language = Contract.getLanguage(contract);

  const currentCard = contract.data.ui.cards[selected.card];

  return (
    <div className="h-100">
      <CustomScrollbars autoHeight autoHeightMin={600}>
        <div className="h-100">
          <ListPages contract={contract} card={currentCard} language={language} />
        </div>
      </CustomScrollbars>
    </div>
  );
}

function ListPages({ contract, card, language }) {
  return (
    <div>
      {card.inputs_order.map((inputOrder, index) => {
        return (
          <InputEntry
            key={'input_' + inputOrder}
            contract={contract}
            card={card}
            hepp={inputOrder}
            input={contract.data.ui.inputs[inputOrder]}
            language={language}
            index={index}
          />
        );
      })}
    </div>
  );
}

function InputEntry({ contract, card, input, hepp, language, index }) {
  const moveUp = () => {
    if (index === 0) return;
    array_move(card.inputs_order, index, index - 1);
    setContract({ ...contract });
  };
  const moveDown = () => {
    if (index + 1 === card.inputs_order.length) return;
    array_move(card.inputs_order, index, index + 1);
    setContract({ ...contract });
  };

  let inputName;
  if (input.label && input.label[language]) inputName = input.label[language];
  else if (input.label?.values && input.label.values[language]) inputName = input.label.values[language];
  else if (['hr', 'newline'].includes(input.type)) inputName = '<Line break>';
  else if (input.values && input.values[language]) inputName = input.values[language];
  else inputName = '<Unknown input>';

  return (
    <>
      <Alert
        message={
          <div className="flex-row" style={{ justifyContent: 'space-between' }}>
            <div>{inputName}</div>
            <div className="mr-1">
              <Button type="link" className="m-0" size="small" onClick={moveUp}>
                <i className="mdi mdi-arrow-up" />
              </Button>
              <Button type="link" className="m-0" size="small" onClick={moveDown}>
                <i className="mdi mdi-arrow-down" />
              </Button>
            </div>
          </div>
        }
        type="info"
        // closable
        // afterClose={() => deletePage(route)}
      />
    </>
  );
}
