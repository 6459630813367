import React from 'react';
import { Collapse, Card } from 'antd';
import WidgetHeader from 'components/WidgetHeader/index';
const Panel = Collapse.Panel;

const emptyMessage = 'Help Section data is being customised for your organisation.';

export default function Help(props) {
  return (
    <div className="help-page padded-page">
      <WidgetHeader title="Help &amp; Instructions" styleName="mb-4" />
      <Card>
        <div className="ant-card-head mb-3">
          <span className="ant-card-head-title mb-2">Organisation</span>
          <p className="text-grey fs-sm mb-0">
            The Organisation you belong to, typically which you are employed by
          </p>
        </div>
        <Collapse defaultActiveKey={[]}>
          <Panel header="Members" key="1">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="Counterparties and Guests" key="2">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="Organisation Permissions" key="3">
            <p>{emptyMessage}</p>
          </Panel>
        </Collapse>
      </Card>
      <Card>
        <div className="ant-card-head mb-3">
          <span className="ant-card-head-title mb-2">Projects</span>
          <p className="text-grey fs-sm">Containers for transactions, contracts and documents</p>
        </div>
        <h5 className="ml-2">Core</h5>
        <Collapse defaultActiveKey={[]}>
          <Panel header="How to create a Project?" key="1">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="How to remove a Project?" key="2">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="Project Members" key="3">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="Project Permissions" key="4">
            <p>{emptyMessage}</p>
          </Panel>
        </Collapse>
        <h5 className="ml-2 mt-4">Advanced</h5>
        <Collapse defaultActiveKey={[]}>
          <Panel header="Project Types" key="1">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="Project Templates" key="2">
            <p>{emptyMessage}</p>
          </Panel>
        </Collapse>
      </Card>

      <Card>
        <div className="ant-card-head mb-3">
          <span className="ant-card-head-title mb-2">Documents</span>
          <p className="text-grey fs-sm">Documents are containers for contracts and versions</p>
        </div>
        <Collapse defaultActiveKey={[]}>
          <Panel header="How to create a Document?" key="1">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="How to remove a Document?" key="2">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="Document Management" key="3">
            <p>{emptyMessage}</p>
          </Panel>
        </Collapse>
      </Card>

      <Card>
        <div className="ant-card-head mb-3">
          <span className="ant-card-head-title mb-2">Versions</span>
          <p className="text-grey fs-sm">
            Contracts are digital data containers, with versions and meta data
          </p>
        </div>
        <h5 className="ml-2">Core</h5>
        <Collapse defaultActiveKey={[]}>
          <Panel header="How to create a Version?" key="1">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="How to remove a Version?" key="2">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="Basic Contract Management" key="3">
            <p>{emptyMessage}</p>
          </Panel>
        </Collapse>
        <h5 className="ml-2 mt-4">Other</h5>
        <Collapse defaultActiveKey={[]}>
          <Panel header="Contract Application" key="1">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="Advance Contract Management" key="2">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="Export a Contract" key="3">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="Share a Contract" key="4">
            <p>{emptyMessage}</p>
          </Panel>
          <Panel header="Sign a Contract" key="5">
            <p>{emptyMessage}</p>
          </Panel>
        </Collapse>
      </Card>
    </div>
  );
}
