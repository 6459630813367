import React from "react";

const Items = {};

Items.Delete = {
  tooltip: "Remove table",
  Icon: () => <i className="mdi mdi-close"></i>,
};

Items.InsertRowAboveOutlined = {
  tooltip: "Insert row above",
  Icon: () => <i className="mdi mdi-table-row-plus-before"></i>,
};
Items.InsertRowBelowOutlined = {
  tooltip: "Insert row below",
  Icon: () => <i className="mdi mdi-table-row-plus-after"></i>,
};
Items.InsertRowLeftOutlined = {
  tooltip: "Insert column to the left",
  Icon: () => <i className="mdi mdi-table-column-plus-before"></i>,
};
Items.InsertRowRightOutlined = {
  tooltip: "Insert column to the right",
  Icon: () => <i className="mdi mdi-table-column-plus-after"></i>,
};
Items.MergeCellsOutlined = {
  tooltip: "Merge selected cells",
  Icon: () => <i className="mdi mdi-call-merge"></i>,
};
Items.DeleteColumnOutlined = {
  tooltip: "Remove column",
  Icon: () => <i className="mdi mdi-table-column-remove"></i>,
};
Items.DeleteRowOutlined = {
  tooltip: "Remove row",
  Icon: () => <i className="mdi mdi-table-row-remove"></i>,
};
Items.SplitCellsOutlined = {
  tooltip: "Split cells",
  Icon: () => <i className="mdi mdi-call-split"></i>,
};


export const Buttons = Items;
