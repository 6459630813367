import { Row, Col, Form, Input } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { CountrySelect, Label } from '.';

const { TextArea } = Input;

export const EntityFieldAddressBlock = ({ withOther = true, compact, ...rest }) => {
  const wrapperCol = compact ? { span: 24 } : undefined;
  return (
    <>
      <Row>
        <Col lg={12} md={24}>
          <Form.Item
            {...rest}
            wrapperCol={wrapperCol}
            label={
              !compact && (
                <Label>
                  <IntlMessages id="app.general.Street" />
                </Label>
              )
            }
            extra={
              compact && (
                <Label>
                  <IntlMessages id="app.general.Street" />
                </Label>
              )
            }
            name={['address', 'street']}
            fieldKey={['address', 'street']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={12} md={24}>
          <Form.Item
            {...rest}
            wrapperCol={wrapperCol}
            label={
              !compact && (
                <Label>
                  <IntlMessages id="app.general.Zip" />
                </Label>
              )
            }
            extra={
              compact && (
                <Label>
                  <IntlMessages id="app.general.Zip" />
                </Label>
              )
            }
            name={['address', 'zipCode']}
            fieldKey={['address', 'zipCode']}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={24}>
          <Form.Item
            {...rest}
            wrapperCol={wrapperCol}
            label={
              !compact && (
                <Label>
                  <IntlMessages id="app.general.City" />
                </Label>
              )
            }
            extra={
              compact && (
                <Label>
                  <IntlMessages id="app.general.City" />
                </Label>
              )
            }
            name={['address', 'city']}
            fieldKey={['address', 'city']}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={12} md={24}>
          <Form.Item
            {...rest}
            wrapperCol={wrapperCol}
            label={
              !compact && (
                <Label>
                  <IntlMessages id="app.general.Country" />
                </Label>
              )
            }
            extra={
              compact && (
                <Label>
                  <IntlMessages id="app.general.Country" />
                  <span style={{color: '#fff'}}>d</span> {/* force autoComplete="off" */}
                </Label>
              )
            }
            name={['address', 'country']}
            fieldKey={['address', 'country']}
          >
            <CountrySelect />
          </Form.Item>
        </Col>
      </Row>
      {withOther && (
        <Row>
          <Col lg={16} md={24}>
            <Form.Item
              {...rest}
              wrapperCol={wrapperCol}
              label={
                !compact && (
                  <Label>
                    <IntlMessages id="app.general.OtherAddressInfo" />
                  </Label>
                )
              }
              extra={
                compact && (
                  <Label>
                    <IntlMessages id="app.general.OtherAddressInfo" />
                  </Label>
                )
              }
              name={['address', 'other']}
              fieldKey={['address', 'other']}
            >
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      )}
    </>
  );
};
