import React from "react";
import { Button } from "antd";
import manager from "core/engine/manager";

function GotoProvision(props) {
  const { field, language, cardUid } = props;

  return (
    <Button
      color="blue"
      onClick={() => {
        if (!field.target) return
        const target = { ...field.target }
        
        if(target.each_label_id)
          target.each_label_id = 'el_'+target.each_label_id+'_'+cardUid

        manager.navigateTo(target);
          
      }}
    >
      {
          (field.label && field.label[language]) || 'Go to provision'
      }
    </Button>
  );
}

export default GotoProvision;
