const searchFields = {
  name: ["fastighet", "fastigheter", "fastigheten", "property", "properties", "the properties"],
  municipal: [
    "kommun",
    "kommuner",
    "kommunen",
    "läge",
    "stad",
    "ort",
    "municipal",
    "city",
    "location",
    "the city",
    "the location",
    "the municipal",
  ],
  owner: [
    "bolag",
    "bolaget",
    "bolagen",
    "fastighetsbolag",
    "fastighetsbolaget",
    "fastighetsbolagen",
    "propco",
    "fastighetsägande bolag",
    "fastighetsägare",
    "fastighetsägande bolagen",
    "ägare",
    "ägarna",
    "owner",
    "owners",
    "company",
    "companies",
    "the company",
    "the companies",
    "the propco",
    "propcos",
    "the propcos",
    "the owner",
  ],
  existingMN: [
    "pantbrev",
    "pantbrevsbelopp",
    "pb",
    "befintliga pantbrev",
    "existerande pantbrev",
    "inteckning",
    "inteckningar",
    "befintlig inteckning",
    "existerande inteckning",
    "befintliga inteckningar",
    "existerande inteckningar",
    "mortgage",
    "mortgages",
    "existing mortgage",
    "existing mortgages",
    "mortgage amount",
    "mortgage amounts",
    "mortgages amount",
    "mortgages amounts",
    "existing mortgage amount",
    "existing mortgage amounts",
    "existing mortgages amount",
    "existing mortgages amounts",
  ],
  newMN: [
    "nya pantbrev",
    "nya pantbrevsbelopp",
    "nya pb",
    "uttag pantbrev",
    "uttag pb",
    "ytterligare pantbrev",
    "ytterligare pb",
    "ny inteckning",
    "nya inteckningar",
    "ytterligare inteckningar",
    "new mortgage",
    "new mortgages",
    "new mortgage amount",
    "new mortgage amounts",
    "new mortgages amount",
    "new mortgages amounts",
  ],
  ALA: [
    "ala",
    "allokerat lånebelopp",
    "allokerade lånebelopp",
    "alor",
    "alan",
    "alas",
    "allocated loan amount",
    "allocated loan amounts",
  ],
  icloan: [
    "internlån",
    "internrevers",
    "internreverser",
    "internskuld",
    "vidareutlåning",
    "IGL",
    "intra-group loan",
    "intra-group loans",
  ],
};


export function filterRows(rows) {
    const newRows = []
    let matches = 0
    rowloop:
    for(const row of rows) {
        // console.log('Row iteration ', {row, matches})
        if(matches > 1) {
            if(row[0] === null) break

            newRows.push(row)
            continue;
        }

        for(const cell of row) {
            if(cell === null) continue
            const cellName = cell.toString().trim().toLocaleLowerCase();
            // console.log('Check cell ', cellName)

            for(const fieldName in searchFields) {
                if(searchFields[fieldName].includes(cellName)) {
                    // console.log('Match for ', cellName)
                    matches++
                }
                else if (matches > 1) {
                    // console.log('yes +1 match')
                    newRows.push(row)
                    continue rowloop
                }
            }
        }
    }

    return newRows
}


export function handleExcel(rows) {
  
  const firstSearchField = Object.keys(searchFields)[0]
  // Identify fields
  // Set to corresponding index in cells
  const fields = Object.keys(searchFields).reduce((acc, curr) => {
    acc[curr] = null
    return acc
    }, {})
  const data = [];
  const fieldsReverse = {};
  const fieldsFound = [];
  let dataStartRow = null;

  rowsloop: for (let i = 0; i < rows.length; i++) {
    const row = rows[i];

    for (let j = 0; j < row.length; j++) {
      const cell = row[j];
      if (fieldsFound[firstSearchField] && typeof cell !== "string") {
        // console.log("break due to empty line");
        break rowsloop;
      }
      for (const field in searchFields) {
        if (typeof cell !== "string") continue;
        if (fieldsFound.includes(field)) continue;
        const cellName = cell.trim().toLocaleLowerCase();
        if (!searchFields[field].includes(cellName)) continue;
        fields[field] = j;
        fieldsReverse[j] = field;
        fieldsFound[field] = true;
        dataStartRow = i + 1;
      }
    }
  }
  // console.log("rows are ", rows);
  if (dataStartRow === null || !rows[dataStartRow]) {
    console.log("Did not find property instruction column fields");
    return [];
  }

  for (let i = dataStartRow; i < rows.length; i++) {
    // console.log("Reading data row ", i, rows[i]);
    const itemData = {};
    const row = rows[i];
    for (const index in fieldsReverse) {
      if (row[index]) itemData[fieldsReverse[index]] = row[index];
    }
    data.push(itemData);
  }

  // console.log("excel data is ", data);
  return data;
}
