import { Modal, Drawer } from 'antd';
import IntlMessages from 'util/IntlMessages';

function DrawerContainer({ children, onCancel, placement, title }) {
  return (
    <Drawer
      title={title}
      placement={placement}
      width={720}
      onClose={onCancel}
      visible={true}
      bodyStyle={{ paddingTop: 40, paddingBottom: 80 }}
    >
      <div className="p-2">{children}</div>
    </Drawer>
  );
}

function ModalContainer({ children, onCancel, title, formId, saveIntlId }) {
  return (
    <Modal
      title={title}
      visible={true}
      onCancel={onCancel}
      cancelText={<IntlMessages id="desc.Cancel" />}
      okText={<IntlMessages id={saveIntlId} cap />}
      okButtonProps={{
        form: formId,
        key: 'submit',
        htmlType: 'submit',
      }}
      backdrop={'static'}
      size={'large'}
      className="modal-medium"
    >
      {children}
    </Modal>
  );
}

export default function Wrapper({ container, children, ...rest }) {
  if (!children) {
    console.warn('No children provided to Wrapper in EditEntity.');
    return null;
  }
  switch (container) {
    case 'modal':
      return <ModalContainer {...rest}>{children}</ModalContainer>;
    case 'drawer':
      return <DrawerContainer {...rest}>{children}</DrawerContainer>;
    default:
      return null;
  }
}
