import React, { useEffect, useCallback, useRef, memo } from 'react';
import { useDispatch } from 'react-redux';
import uuid from 'uuid-random';
import ihdStructure from 'external/chart2';
import { addMetaDataToNodes } from './utils';
import { Entity } from 'core/interfaces';
import { updateEntity, addEntity, removeEntity } from 'appRedux/actions';

function chartifyEntities(entities, metaData, layout, topCoId, topCoEntity) {
  function isTopCoPredicate(entity) {
    if (Array.isArray(entity.tags) && entity.tags.includes('_client')) {
      return false;
    }
    if (topCoId) return entity.id === topCoId;
    return !entity.parentId;
  }

  let newEntities = JSON.parse(JSON.stringify(entities));

  if (newEntities.find((e) => !e.tags.includes('_dummyOwner'))) {
    let newOwner;
    if (topCoEntity && topCoEntity.Parent) {
      newOwner = JSON.parse(JSON.stringify(topCoEntity.Parent));
    } else {
      const dummyOwnerId = uuid();
      newOwner = Entity.create({
        id: dummyOwnerId,
        firstName: 'Owner',
        lastName: 'X',
        name: 'test',
        tags: ['_dummyOwner'],
      });
    }

    newEntities = newEntities
      .map((entity) => {
        if (isTopCoPredicate(entity)) entity.parentId = newOwner.id;
        return entity;
      })
      .concat(newOwner);
  }

  newEntities = addMetaDataToNodes(newEntities, metaData, layout);

  return newEntities;
}

const AChart = memo((props) => {
  const { entities, idPrefix, editable, onEntityInsert, onEntityRemove, onEntityMove, layout, metaData } =
    props;

  const updateChart = useRef(null);
  const updateLayout = useRef(null);
  const hasMounted = useRef(false);
  const allowUpdates = useRef(false);
  const dispatch = useDispatch();

  const updateEntityEvent = useCallback(
    (sender, args) => {
      // console.log('EVENT');
      let id = args.node.id;
      if (editable) {
        dispatch(
          updateEntity(id, {
            ...args.data,
            parentId: args.pid,
          })
        );
      }
    },
    [dispatch]
  );

  const insertEntityEvent = useCallback(
    (sender, args) => {
      const parentId = args.pid;
      const data = Entity.create({ parentId, ...args.data });
      // console.log('EVENT', args);
      if (editable) {
        dispatch(addEntity(data.id, parentId, data));
        if (typeof onEntityInsert === 'function') onEntityInsert(data);
      }
    },
    [dispatch, entities]
  );

  const removeEntityEvent = useCallback(
    (sender, args) => {
      // console.log('EVENT', args);
      let id = args.id;
      if (editable) {
        dispatch(removeEntity(id));
        if (typeof onEntityRemove === 'function') onEntityRemove(id, args);
      }
    },
    [dispatch]
  );

  const moveEntityEvent = useCallback(
    (movedId, newParentId, oldParentId) => {
      // Cannot move `Owner`
      if (oldParentId === null) return;

      const entity = entities.find((x) => x.id === movedId);

      if (!entity) return console.log('Did not find entity with id ', movedId);

      dispatch(
        updateEntity(movedId, {
          ...entity,
          parentId: newParentId,
        })
      );

      if (typeof onEntityMove === 'function') onEntityMove(movedId, newParentId, oldParentId);
    },
    [dispatch, entities]
  );

  const clickEntityEvent = useCallback((sender, args) => {
    // console.log("click", sender, args);
  }, []);

  useEffect(() => {
    hasMounted.current = true;

    // Wait with allowing updates due to changed layout.
    setTimeout(() => {
      allowUpdates.current = true;
    }, 50);
  }, []);

  useEffect(() => {
    if (!updateChart.current) return;
    if (!allowUpdates.current) return;
    updateChart.current(entities);
  }, [layout, entities, metaData]);

  useEffect(() => {
    if (!updateChart.current) return;
    // console.log('Got new layout.');
    updateLayout.current(layout);
  }, [layout]);

  return (
    <ChartUnderlying
      idPrefix={idPrefix}
      entitiesData={entities}
      insertEntityEvent={insertEntityEvent}
      updateEntityEvent={updateEntityEvent}
      removeEntityEvent={removeEntityEvent}
      clickEntityEvent={props.clickEntityEvent}
      moveEntityEvent={moveEntityEvent}
      updateChart={updateChart}
      updateLayout={updateLayout}
      metaData={metaData}
      {...props}
    />
  );
});

export default AChart;

class ChartUnderlying extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.updateChart) this.props.updateChart.current = this.updateChart;
    if (this.props.updateLayout) this.props.updateLayout.current = this.updateLayout;
    this.layout = this.props.layout;
  }

  componentDidMount() {
    if (1) {
      // const {intl} = this.props;

      let searchPlaceholder = 'Search';
      /* try {
        searchPlaceholder = intl.formatMessage({id:'desc.Search'});
      } catch(err) {

      } */

      this.orgchart = new ihdStructure(this.refs.oc, {
        updateInternal: false,
        chartId: this.props.chartId || 'none',
        // createNodeEvent: this.props.createEntityEvent,
        insertNodeEvent: this.props.insertEntityEvent,
        updateNodeEvent: this.props.clickEntityEvent,
        removeNodeEvent: this.props.removeEntityEvent,
        // updatedEvent: this.props.updatedEvent,
        moveNodeEvent: this.props.moveEntityEvent,
        mouseOverEvent: this.props.mouseOverEvent,
        mouseLeaveEvent: this.props.mouseLeaveEvent,
        nodeDropZone: this.props.entityDropZone,
        onDrawCallback: this.props.onDrawCallback,
        searchPlaceholder,
        boxSizeInPercentage: {
          minBoxSize: {
            width: 5,
            height: 5,
          },
          boxSize: {
            width: 20,
            height: 20,
          },
          maxBoxSize: {
            width: 100,
            height: 100,
          },
        },
        color: 'lightblue',
        actions: {
          topLeft: this.props.topLeftAction || 'default', // function, 'default' or 'none'
          topRight: this.props.topRightAction || 'default',
          bottomLeft: this.props.bottomLeftAction || 'default',
          bottomRight: this.props.bottomRightAction || 'default',
          topLeftPredicate: this.props.topLeftPredicate,
          topRightPredicate: this.props.topRightPredicate,
          bottomLeftPredicate: this.props.bottomLeftPredicate,
          bottomRightPredicate: this.props.bottomRightPredicate,
        },
        buttonIcons: {
          topLeft: this.props.topLeftIcon || 'default',
          topRight: this.props.topRightIcon || 'default',
          bottomLeft: this.props.bottomLeftIcon || 'default',
          bottomRight: this.props.bottomRightIcon || 'default',
        },
        primaryFields: ['jurisdiction', 'name', 'identificationNumber'],
        primaryFieldsDefaultEmpty: ['jurisdiction', 'identificationNumber'],
        photoFields: ['image'],
        // theme: "ihd",
        scale: 'auto',
        enableMove: true,
        // enableDragDrop: true,
        enableZoom: true,
        enablePrint: true,
        enableExpand: true,

        enableExportToImage: true,
        // enableGridView: true,

        linkType: 'M',
        enableDragDrop: true,
        enableEdit: true,
        idField: 'id',
        parentIdField: 'parentId',
        secondParentIdField: 'secondParentId',
        thirdParentIdField: 'creditors',
        levelSeparation: 200,
        enableDetailsView: true,
        parentXadjust: -35,
        chartClass: this.props.chartClass ? this.props.chartClass : 'std-chart',
        dataSource: this.props.entitiesData
          ? chartifyEntities(
              this.props.entitiesData,
              this.props.metaData,
              this.layout,
              this.props.topCoId,
              this.props.topCoEntity
            )
          : [],
      });
      if (typeof this.props.setOrgChartRef === 'function') this.props.setOrgChartRef(this.orgchart);

      const topCo = Entity.findTopCo(this.props.entitiesData);
      const orgchart = this.orgchart;
      if (topCo && topCo.id) orgchart.highlightNode(topCo.id);
    }
  }
  shouldComponentUpdate() {
    return false;
  }

  updateChart = (entities) => {
    if (entities) {
      const newEntities = chartifyEntities(
        entities,
        this.props.metaData,
        this.layout,
        this.props.topCoId,
        this.props.topCoEntity
      );
      this.orgchart.loadFromJSON(newEntities, false, undefined, true);
    } else console.log('Cannot reload without data');
  };

  updateLayout = (layout) => {
    this.layout = layout;
    // console.log('Update layout');
    this.forceUpdate();
  };

  render() {
    const { chartId = 'no-chart-id' } = this.props;
    return (
      <React.Fragment>
        <div className="orgchart_holder">
          <div id={chartId + 'ihd_orgchart'} className="ihdchart-org-chart" ref="oc" style={{}}></div>
        </div>
      </React.Fragment>
    );
  }
}
