import React, { useState, useCallback } from 'react';
import { createBaseEditor } from 'core/utils/editor';
import { SlateReactPresentation } from 'slate-react-presentation';
import { RenderElements } from './elements';
import { RenderLeaf } from './marks';

import { Slate, Editable, withReact } from 'slate-react';

export default function PresentElement({ content }) {
  const [editor] = useState(withReact(createBaseEditor()));
  const elements = Array.isArray(content) ? content : [content];
  const renderElement = useCallback(
    (props) => <RenderElements {...props} editor={editor} isInActiveRepresentation />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderLeaf = useCallback((props) => <RenderLeaf {...props} editor={editor} />, []);

  return (
    <div className="slate-presentation">
      {/* <Slate editor={editor} value={elements}>
        <Editable readOnly renderElement={renderElement} renderLeaf={renderLeaf} />
      </Slate> */}
      <SlateReactPresentation value={elements} renderElement={renderElement} renderLeaf={renderLeaf} />
    </div>
  );
}
