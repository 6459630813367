import 'regenerator-runtime/runtime';
import { all, takeEvery, select, put } from 'redux-saga/effects';
// import { getStore } from 'appRedux/store';
import {
  REMOTE_APPLY_STATE,
  SET_CONVERSATIONS,
  ADD_CONVERSATION,
  DELETE_CONVERSATION,
  ADD_CONVERSATION_POST,
  DELETE_CONVERSATION_POST,
} from 'constants/ActionTypes';
import { setDraftValues } from '../actions';

/**
 *
 * Root saga. Set up.
 *
 */

function* rootSaga() {
  yield all([
    takeEvery(
      [
        REMOTE_APPLY_STATE,
        SET_CONVERSATIONS,
        ADD_CONVERSATION,
        DELETE_CONVERSATION,
        ADD_CONVERSATION_POST,
        DELETE_CONVERSATION_POST,
      ],
      keepConversationsCount
    ),
  ]);
}

/**
 * Catch remote sync action
 *
 * Upon changing or receiving remote updates of `conversations` part of
 * the state, calculate the totalt number and compare to our current record.
 *
 */

function* keepConversationsCount() {
  // REMOTE_REMOTE_APPLY_STATE
  const { numConv, numConvPosts, numConvUncompleted } = yield select(({ draft }) => draft);
  const conversations = yield select(({ conversations }) => conversations);
  const draftConvUpdates = [];
  if (!conversations) {
    if (numConv !== 0) draftConvUpdates.push(['numConv', 0]);
    if (numConvPosts !== 0) draftConvUpdates.push(['numConvPosts', 0]);
    if (numConvUncompleted !== 0) draftConvUpdates.push(['numConvUncompleted', 0]);
    return;
  }
  const newNumConversations = Object.keys(conversations).length;
  const newNumPosts = Object.values(conversations).reduce((acc, curr) => (acc += curr.posts?.length || 0), 0);
  const newNumUnCompleted = Object.values(conversations).filter((conv) => !conv.completed).length;
  if (numConv !== newNumConversations) draftConvUpdates.push(['numConv', newNumConversations]);
  if (numConvPosts !== newNumPosts) draftConvUpdates.push(['numConvPosts', newNumPosts]);
  if (numConvUncompleted !== newNumUnCompleted)
    draftConvUpdates.push(['numConvUncompleted', newNumUnCompleted]);

  if (draftConvUpdates.length > 0) {
    yield put(setDraftValues(draftConvUpdates));
  }
}

export default rootSaga;
