import { generateHandlersActions, validStates } from './draftSessionHelpers';

// TODO:
// - Sort definitions

/**
 * Finish Draft.
 * Called by redux-saga (via the manager) after a debouncer therein
 * has concluded that it is time for the draft session to come to an
 * end, and for the new contract data to be visually updated (and
 * displayed by Slate).
 *
 * @param {object} state Main redux state.
 */
export const finishDraft = async function (states, options = {}) {
  if (!validStates(states)) {
    console.warn('No states.');
    return;
  }
  if (!options.editor && !this.editor) {
    throw new Error('Finish draft was invoked without an editor');
  }

  this.editor = options.editor || this.editor;
  this._tmpDraft = {
    changedRules: new Set(),
    changedVariables: new Set(),
    currentRules: {},
    currentVariables: {},
  };

  const stateChanges = this.handleNewStates(states);
  await this.invokeHandlers(states, stateChanges, options);

  const draftResult = this._tmpDraft;
  this._tmpDraft = null;

  return draftResult;
};

function _reverseSet(set) {
  return new Set([...set].reverse());
}
function _reverseMatches(matches) {
  const newMatches = {};
  for (const [key, values] of Object.entries(matches)) {
    newMatches[key] = _reverseSet(values);
  }
  return newMatches;
}

// Overview all comments with `SLATE_ENGINE_FIX`

export const invokeHandlers = async function (states, stateChanges, options = {}) {
  const { isGenesis = false } = options;
  this._draftTime = isGenesis ? 0 : Date.now();
  const handlerActions = generateHandlersActions(this.handlers, stateChanges, this.contract, {
    isGenesis,
    filter: (handler) => {
      return !!handler.hasOwnProperty('match');
    },
  });

  if (typeof window !== 'undefined' && window.debug) {
    this.log('handlerActions are ', { handlerActions, stateChanges });
  }

  const api = this.api;
  const engine = this;
  const { editor } = this;

  editor._engineDraftSession = true;

  this.buildNodeEntries();

  this._updatedNodePaths = new Set();
  this._updateNodeItemJoin = new Set();

  const normaliseFn = engine.api.slate.Editor.normalize;
  engine.api.slate.Editor.normalize = () => {};

  // Run handlerActions and handlers.
  await new Promise((resolve) => {
    editor.unrestrictedApply(async () => {
      for (const action of handlerActions) {
        const { handler, handlerInvoked, entries, paths } = action;
        if (!handler.match) {
          continue;
        }

        const args = {
          engine: this,
          state: states.current,
          contract: this.contract,
          handlerInvoked,
          entries,
          paths,
          api,
          editor,
          stateChanges,
        };

        const [hasMatch, matches] = matchHandler(handler, entries, this._nodeEntriesList, args);
        if (hasMatch) {
          try {
            let handlerMatches = handler.reverseOrder ? _reverseMatches(matches) : matches;
            handler.handler.call(this, { ...args, matches: handlerMatches });
            await sleep(10);
          } catch (err) {
            console.log('Handler failed.', {
              handler,
              action,
            });
            console.error(err);
          }
        }
      }
      resolve();
    });
  });

  setTimeout(() => {
    editor._engineDraftSession = false;
  }, 1);

  engine.api.slate.Editor.normalize = normaliseFn;
  this.clearNodeEntries();
};

const sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

function matchHandler(handler, entries, nodeEntriesList, args) {
  let hasMatch = false;
  const matches = {};
  const handlerMatchType = typeof handler.match;
  if (typeof handler.preMatch === 'function') {
    const preMatchAnswer = handler.preMatch({ ...args, entries });
    if (!preMatchAnswer) {
      return [false, null];
    }
  }
  if (handler.match === true) {
    hasMatch = true;
  } else if (handlerMatchType === 'function') {
    matches.default = new Set();
    for (const [, nodeEntry] of nodeEntriesList) {
      if (matches.default.has(nodeEntry)) {
        continue;
      }
      const match = handler.match({ ...args, entries, node: nodeEntry.node });
      if (match) {
        hasMatch = true;
        matches.default.add(nodeEntry);
      }
    }
  } else if (handler.match && handlerMatchType === 'object') {
    for (const [matchCategory, matchFn] of Object.entries(handler.match)) {
      if (matchFn === true) {
        hasMatch = true;
        break;
      }
      // for (const tuple of nodeEntriesList) {
      for (const [, nodeEntry] of nodeEntriesList) {
        if (matches[matchCategory] && matches[matchCategory].has(nodeEntry)) {
          continue;
        }
        const match = matchFn({ ...args, entries, node: nodeEntry.node });
        if (match) {
          hasMatch = true;
          if (!matches[matchCategory]) matches[matchCategory] = new Set();
          matches[matchCategory].add(nodeEntry);
        }
      }
    }
  }
  return [hasMatch, matches];
}

/*****************************************************/

/****      SYNTHETIC DRAFT SESSION      ****/
/****    AND HELPERS FOR OUTSIDE USE    ****/

export const invokeMethod = function (method, ...args) {
  if (typeof this[method] !== 'function') {
    this.log('Engine has no method ', method);
    return;
  }
  return this[method](...args);
};

/****        DRAFTING GUIDANCE       ****/
/****  VARIABLES' SETTING FUNCTIONS  ****/

export const setRuleValue = function (name, value) {
  if (!name || value === undefined) return;

  const hasSameValue = this.getRuleValue(name) === value;

  this._tmpDraft.currentRules[name] = value;
  if (!hasSameValue) {
    this._tmpDraft.changedRules.add(name);
  }
};
export const getRuleValue = function (name) {
  if (this._tmpDraft && this._tmpDraft.currentRules && this._tmpDraft.currentRules.hasOwnProperty(name)) {
    return this._tmpDraft.currentRules[name];
  }
  if (
    this.states.current.contractData &&
    this.states.current.contractData.rules &&
    this.states.current.contractData.rules.hasOwnProperty(name)
  ) {
    return this.states.current.contractData.rules[name];
  }
  if (!this.contract.data.create.savedRules[name]) {
    console.log('Cannot find rule for ', name);
    return false;
  }
  return this.contract.data.create.savedRules[name].value;
};

export const setShortcut = function (name, value) {
  if (!name || value === undefined) return;

  this.setRuleValue(name, value);
};

export const setVariable = function (name, value) {
  if (!name) return;
  const hasSameValue = this.getVariable(name) === value;

  this._tmpDraft.currentVariables[name] = value;

  if (!hasSameValue) {
    this._tmpDraft.changedVariables.add(name);
  }
};
export const getVariable = function (name) {
  if (
    this._tmpDraft &&
    this._tmpDraft.currentVariables &&
    this._tmpDraft.currentVariables.hasOwnProperty(name)
  ) {
    return this._tmpDraft.currentVariables[name];
  }
  if (
    this.states.current.contractData &&
    this.states.current.contractData.variables &&
    this.states.current.contractData.variables.hasOwnProperty(name)
  ) {
    return this.states.current.contractData.variables[name];
  }
};
