import {
  imp_and as impAndUtil,
  imp_or as impOrUtil,
  combineNumber as combineNumberUtil,
  splitNumber as splitNumberUtil,
  format_number,
  ucfirst,
} from '../../../utils/general';
import langValues from '../../config/langValues.json';
import { IS_FRONTEND } from '../../../../import/core';

export const combineNumber = function (num) {
  return combineNumberUtil(num, this.language);
};
export const splitNumber = function (num) {
  return splitNumberUtil(num, this.language);
};
export const imp_and = function (collection) {
  return impAndUtil(collection, this.translateText('and', true) || 'and');
};
export const imp_or = function (collection) {
  return impOrUtil(collection, this.translateText('or', true) || 'or');
};
export const emptyContractString = function () {
  return (
    (this.settings &&
      this.settings.replaceEmptyContractStrings &&
      this.settings.hasOwnProperty('emptyReplacement') &&
      this.settings.emptyReplacement) ||
    ''
  );
};
export const formatListItem = function (number, level = 0) {
  const listFormat = this.getContractSettings() && this.getContractSettings().format.listFormat;
  if (!listFormat || !listFormat.formats || !listFormat.formats[level]) return '';
  return format_number(number, level, listFormat);
};
export const translateText = function (text, options = {}) {
  const { defaultEmpty = false, language, uc = false } = options;
  const lang = this && this.language ? this.language : language;

  if (!langValues[text] || !langValues[text].hasOwnProperty(lang))
    return defaultEmpty ? '' : '[' + text + ']';
  if (uc) {
    return ucfirst(langValues[text][lang]);
  }
  return langValues[text][lang];
};

export const log = function (...args) {
  if (IS_FRONTEND) console.log(...args);
};
export const warn = function (...args) {
  if (IS_FRONTEND) console.warn(...args);
};
export const trace = function (...args) {
  if (IS_FRONTEND) console.trace(...args);
};
