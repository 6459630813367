import { Col, Row } from 'antd';
import Summary from './Summary';
import Feedbacks from './Feedbacks';
import { PrimaryActions, SecondaryActions } from './Actions/';
import { gutter } from 'config';
import ProjectDocumentEvents from 'components/project/ProjectDocumentEvents';

export default function DocumentOverview(props) {
  const { project, doc, setDocument, versions, currentVersion, selectVersion, setVersions } = props;
  return (
    <div className="padded-page">
      <Row>
        <Col xl={18} lg={18} md={24} sm={24} xs={24} className="mb-4">
          <PrimaryActions
            project={project}
            doc={doc}
            currentVersion={currentVersion}
            setDocument={setDocument}
          />
        </Col>
      </Row>
      <Row>
        <Col xl={19} lg={19} md={24} sm={24} xs={24}>
          <Summary project={project} document={doc} contract={currentVersion} />
          <Row gutter={gutter}>
            <Col xl={14} lg={14} md={14} sm={24} xs={24}>
              <Feedbacks doc={doc} currentVersion={currentVersion} />
            </Col>
            <Col xl={10} lg={10} md={10} sm={24} xs={24}>
              <ProjectDocumentEvents mode="Document" document={doc} />
            </Col>
          </Row>
        </Col>
        <Col xl={5} lg={5} md={24} sm={24} xs={24} className="d-flex justify-content-center">
          <SecondaryActions
            doc={doc}
            documentId={doc?.id}
            setDocument={setDocument}
            currentVersion={currentVersion}
            versions={versions}
            setVersions={setVersions}
            selectVersion={selectVersion}
          />
        </Col>
      </Row>
    </div>
  );
}

/* 
// Note. This currently does not update when a change is made by someone in the studio. 
// If we're gonna use this, this should be analyzed and fixed.

// import { useSelector } from 'react-redux';
// import { EditorEditable } from 'components/YjsEditor/EditorEditable';
// <VersionPreview currentVersion={currentVersion} />

function VersionPreview({ currentVersion }) {
  const viewingConversationId = useSelector(({ draft }) => draft.viewingConversationId);
  return (
    <Card className="widget" title="Preview">
      {viewingConversationId ? <div className="version-preview-overlay"></div> : null}
      <div className={`version-preview ${viewingConversationId ? 'focus' : ''}`}>
        <EditorEditable
          previewUpdates={false}
          previewUpdatesText={false}
          // noHeaderCounting={settings.format && settings.format.noHeaderCounting}
          isEditingTemplate={false}
          // readOnly
        />
      </div>
    </Card>
  );
}
 */
