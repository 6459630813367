import React, { useEffect, useState, useMemo } from 'react';
import { Button, Form, Checkbox, notification } from 'antd';
import { setProject } from 'hooks';
import { useModalContent } from 'components/ui';
import api from 'utils/api';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

export default function AddTemplateAction({ project }) {
  const { isFolder } = project;
  const formatMessage = useIntlMessage();
  const intlType = isFolder ? 'folder' : 'project';
  const [Modal /* toggleModal */, , setModal, isOpen] = useModalContent(
    formatMessage('app.projects.AddTemplateTo') + ' ' + formatMessage('general.' + intlType)
  );
  return (
    <>
      <Button
        icon={<i className={'mdi mdi-note-plus fs-xl mr-2'} />}
        onClick={() => setModal(true)}
        block
        type="text"
        className="d-flex align-items-center justify-content-flex-start"
      >
        <IntlMessages id="app.projects.AddTemplateTo" />
        &nbsp;
        <IntlMessages id={'general.' + intlType} />
      </Button>

      {isOpen && (
        <Modal>
          <AddTemplatesToProject project={project} close={() => setModal(false)} noToggle />
        </Modal>
      )}
    </>
  );
}

function AddTemplatesToProject({ project, noToggle, close }) {
  const [open, setOpen] = useState(noToggle ? true : false);
  const [templates, setTemplates] = useState(null);
  const toggleOpen = () => setOpen(!open);
  const isFolder = project.isFolder;

  useEffect(() => {
    api
      .get('/documenttemplates?fields=id,name,description')
      .then((res) => res.data && setTemplates(res.data))
      .catch((err) => {
        console.log('Fetch err ', { err, response: err.response });
      });
  }, [setTemplates]);

  const initialValues = useMemo(() => {
    if (!project?.data?.documentTemplatesIds?.standalone) return {};
    return project.data.documentTemplatesIds.standalone.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
  }, [project]);

  const onFinish = (values) => {
    const selected = Object.keys(values).filter((key) => !!values[key]);
    if (selected.length === 0) {
      return notification.error({
        message: 'Failed to add',
        description: 'Please select at least one template to add',
      });
    }

    console.log('finish values ', { values, selected });

    const data = {
      ...project.data,
      documentTemplatesIds: {
        standalone: selected,
      },
    };

    api
      .put('/projects/' + project.id, { data })
      .then(function (res) {
        if (res.data) {
          setProject({ ...project, data });
          if (typeof close === 'function') {
            close();
          } else {
            setOpen(false);
          }
        } else {
          console.log('Error in putting project, ', res);
        }
      })
      .catch((err) => console.log('put err', err, err.response));
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      {!noToggle && (
        <div>
          <span className="link" onClick={toggleOpen}>
            {open ? 'Hide' : 'Show'} available templates
          </span>
        </div>
      )}

      {open && (
        <div className="p-3">
          {templates && templates.length > 0 && (
            <Form
              name="basic"
              initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              {templates.map((template) => (
                <Form.Item key={template.id} name={template.id} valuePropName="checked">
                  <Checkbox>
                    {template.name}
                    <small className="ml-3">{template.description}</small>
                  </Checkbox>
                </Form.Item>
              ))}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  <IntlMessages id="app.project.makeTemplateAvailable" />
                  &nbsp;
                  <IntlMessages id={`general.${isFolder ? 'folder' : 'project'}`} />
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      )}
    </div>
  );
}
