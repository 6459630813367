/*
const addMetaDataToNodes = (nodes) => {
    return nodes.map((node) => node.metaData = {});
}
*/
export const addMetaDataToNodes = (nodes, metaNodes = [], layout) => {
    if(!metaNodes) {
        console.log('No metaNodes');
        return [];
    }
    // console.log('metaNodes', metaNodes)
    return nodes.map((node, index) => {
        let metaNode = metaNodes.find(metaN => metaN.id === node.id)
        if(!metaNode) {
            metaNode = node;
            metaNodes.push(metaNode);
            node.metaData = {};
        }
        else
            node.metaData = metaNode.metaData;
        if(layout) {
            metaNode.metaData.render = _addRender(node, layout);
        }
        
        return node;
    });
}

const _addRender = (node, layout) => {
    let render = '';
    let contentLength = layout.length;
    let rowHeight = 200 / contentLength;
    for(let i=0;i<contentLength;i++) {
      let row = layout[i];
      let rowLength = row.length
      for(let col of row) {
        let columnWidth = col.col ? col.col * 500/12 : 500 / rowLength;
        let text = col.text ? col.text : '';
        text += (col.dataField && node[col.dataField] ? node[col.dataField] : '');
        text += (col.metaDataField && node.metaData.hasOwnProperty(col.metaDataField) ? node.metaData[col.metaDataField] : '');
        if(col.func)
            text += col.func(node)
        const y = (col.custom && col.custom.y) || (rowHeight*i);
        const x = (col.custom && col.custom.x) || '0';
        const width = (col.custom && col.custom.width) || columnWidth;
        const height = (col.custom && col.custom.height) || rowHeight;

        render += '<foreignObject node-id="'+node.id+'" '+(col.id?' id="'+col.id+'"':'')+(col.align?' style="text-align:'+col.align+'"':'')+' width="'+width+'" height="'+height+'" class="get-text get-text-0'+(col.class?(' '+col.class):'')+'" x="'+x+'" y="'+y+'">'+text+'</foreignObject>';
      }
    }
    return render;
}
