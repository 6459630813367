import React, { useState, useCallback, useRef, useEffect } from 'react';
import uuid from 'uuid-random';
import LogicsEditor from './LogicsEditor';
import {
  Modal,
  Drawer,
  Button,
  Alert,
  Avatar,
  Input,
  Select,
  Checkbox,
  Typography,
  notification,
} from 'antd';
import { LinkOutlined, SearchOutlined } from '@ant-design/icons';
import { ruleToType, ruleTypes, firstKey } from './core/common';
import PrintRuleComponent from './core/printRule';
import { fixDate } from 'components/ui';

const { Paragraph } = Typography;

function getRuleData(contract, id) {
  if (!contract.data.create.savedRules || !contract.data.create.savedRules[id]) return null;
  return contract.data.create.savedRules[id].data || null;
}

export const PrintRule = PrintRuleComponent;

export function RuleModal({
  contract,
  id,
  state,
  onChange: parentOnChange,
  onSave: parentOnSave,
  onSaveAs: parentOnSaveAs,
  onCancel,
  onOk: parentOnOk,
}) {
  const completeRule = contract.data.create.savedRules[id] || {};
  const [ruleId, setRuleId] = useState(id);
  const [currentRule, setCurrentRuleContent] = useState(getRuleData(contract, id));
  const [isValid, setIsValid] = useState(null);
  const [viewRule, setViewRule] = useState(false);
  const localCardRef = useRef(completeRule.localCard);

  const [editableLabel, setEditableLabel] = useState(completeRule.label || '');
  const [editableDescription, setEditableDescription] = useState(completeRule.description || '');

  if (ruleId && !contract.data.create.savedRules[ruleId])
    contract.data.create.savedRules[ruleId] = {}

  const onChange = useCallback(
    ([rule, valid]) => {
      setCurrentRuleContent(rule);
      setIsValid(valid);
      if (typeof parentOnChange === 'function') parentOnChange([rule, valid]);
    },
    [parentOnChange]
  );
  const onLocalCardChange = useCallback(
    (val) => {
      localCardRef.current = val;
    },
    [localCardRef]
  );

  const onSave = ({ ruleData, localCard }) => {
    if (!isValid) {
      return notification.error({ message: 'Cannot save Invalid rule' });
    }
    contract.data.create.savedRules[ruleId].updatedAt = new Date().toISOString();
    contract.data.create.savedRules[ruleId].data = ruleData;
    contract.data.create.savedRules[ruleId].localCard = localCard;
    if (typeof parentOnSave === 'function') parentOnSave(contract.data.create.savedRules[ruleId], ruleId);
  };
  const onSaveAs = ({ ruleData, label, description, localCard }) => {
    if (!isValid) {
      return notification.error({ message: 'Cannot save Invalid rule' });
    }
    const newId = uuid();
    const madeAt = new Date().toISOString();
    const newRule = {
      label,
      description,
      data: ruleData,
      createdAt: madeAt,
      updatedAt: madeAt,
      localCard,
    };
    // console.log('New rule set... ');
    if (!contract.data.create.savedRules) contract.data.create.savedRules = {};
    contract.data.create.savedRules[newId] = newRule;
    if (typeof parentOnSaveAs === 'function') parentOnSaveAs(newRule, newId);
    setEditableLabel(label);
    setEditableDescription(description);
    setRuleId(newId);
  };
  const onOk = () => {
    if (!isValid) {
      return notification.error({ message: 'Cannot save Invalid rule' });
    }
    onSave({ ruleData: currentRule, localCard: localCardRef.current });
    if (typeof parentOnOk === 'function') parentOnOk();
  };

  const onEditableLabelChange = (val) => {
    setEditableLabel(val);
    contract.data.create.savedRules[id].label = val;
  };
  const onEditableDescriptionChange = (val) => {
    setEditableDescription(val);
    contract.data.create.savedRules[id].description = val;
  };

  return (
    <Modal
      visible={true}
      title={
        <span className="rule-edit-header">
          <span>Edit Rule</span>
          <span className="rule-edit-header-label">
            <small>
              Name:{' '}
              <SmallEm disabled={!!editableLabel}>
                <Paragraph
                  onClick={(evt) => evt.stopPropagation()}
                  editable={{ onChange: onEditableLabelChange, tooltip: 'Click to edit label' }}
                  className="mb-0"
                >
                  {editableLabel || 'No label'}
                </Paragraph>
              </SmallEm>
            </small>
          </span>
          <span className="rule-edit-header-label">
            <small>
              Description:{' '}
              <SmallEm disabled={!!editableDescription}>
                <Paragraph
                  onClick={(evt) => evt.stopPropagation()}
                  editable={{ onChange: onEditableDescriptionChange, tooltip: 'Click to edit description' }}
                  className="mb-0"
                >
                  {editableDescription || 'No description'}
                </Paragraph>
              </SmallEm>
            </small>
          </span>
          <span className="rule-edit-header-description">
            <small>
              Id:{' '}
              <SmallEm disabled={false}>
                <Paragraph onClick={(evt) => evt.stopPropagation()} className="mb-0">
                  {ruleId || 'No id'}
                </Paragraph>
              </SmallEm>
            </small>
          </span>
        </span>
      }
      width={'90%'}
      className="rule-modal"
      wrapClassName="wrap-rule-modal"
      onCancel={onCancel}
      onOk={onOk}
      okText={'Save to Contract & Close'}
    >
      <div style={{ padding: '0px' }}>
        <LogicsEditor
          ruleId={ruleId}
          rule={currentRule}
          contract={contract}
          onChange={onChange}
          // localCard={localCard}
          localCard={completeRule.localCard}
          onLocalCardChange={onLocalCardChange}
          state={state}
          onSave={onSave}
          onSaveAs={onSaveAs}
        />
      </div>
      <div className="mt-4">
        <span className="link" onClick={() => setViewRule(!viewRule)}>
          {viewRule ? 'Hide Rule Code' : 'Show Rule code'}
        </span>
      </div>
      {viewRule && (
        <div
          style={{
            marginTop: '10px',
            border: '1px dashed #aaa',
            borderRadius: '4px',
          }}
        >
          <pre className="m-0" style={{ padding: '7px', background: isValid ? '#cfffda' : '#ffcfcf' }}>
            {JSON.stringify(currentRule, null, 2)}
          </pre>
        </div>
      )}
    </Modal>
  );
}

function NewRuleInput({ onAdd, onCancel }) {
  const [addingNewRuleLabel, setAddingNewRuleLabel] = useState('');
  const [addingNewRuleDescription, setAddingNewRuleDescription] = useState('');

  const onChangeNewLabel = (evt) => {
    setAddingNewRuleLabel(evt.target.value);
  };
  const onChangeNewDescription = (evt) => {
    setAddingNewRuleDescription(evt.target.value);
  };

  const handleOnAdd = () => {
    onAdd({
      label: addingNewRuleLabel,
      description: addingNewRuleDescription,
    });
  };

  return (
    <>
      <div>
        <Input type="text" placeholder="Rule label" value={addingNewRuleLabel} onChange={onChangeNewLabel} />
        <Input
          type="text"
          placeholder="Rule description"
          value={addingNewRuleDescription}
          onChange={onChangeNewDescription}
        />
      </div>
      <Button type="primary" onClick={handleOnAdd}>
        Add Rule
      </Button>
      <Button type="primary" onClick={onCancel}>
        Cancel
      </Button>
    </>
  );
}

function sortFirst(arr) {
  return function sortFunc(a, b) {
    return arr.indexOf(b) - arr.indexOf(a);
  };
}

export function ContractRulesEditor({
  contract,
  onClose,
  state,
  onSelect,
  onSave,
  onSaveAs,
  onNew,
  onDelete,
  currentRuleId,
}) {
  const [id, setId] = useState(null);
  const [addingNewRule, setIsAddingNewRule] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [showRuleContent, setShowRuleContent] = useState(false);
  const [filterType, setFilterType] = useState(null);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [dummy, setDummy] = useState(0);
  const [newlyAddedIds, setNewlyAddedIds] = useState([]);
  const stringifiedContents = useRef({});

  if (!contract.data.create.savedRules) contract.data.create.savedRules = {}

  const initNewRule = () => {
    setIsAddingNewRule(true);
  };
  const cancelAddRule = () => {
    setIsAddingNewRule(false);
  };
  const addNewRule = (values) => {
    const { label, description } = values;
    if (label === '' || description === '') {
      return notification.error({
        message: 'Enter details',
        description: 'Enter label and description for new rule',
      });
    }
    const newId = uuid();
    const newRule = {
      label: label,
      description: description,
      rule: { and: [] },
    };
    contract.data.create.savedRules[newId] = newRule;

    if (newRule.data) stringifiedContents.current[id] = JSON.stringify(newRule.data);

    if (typeof onNew === 'function') onNew(newId, newRule);
    cancelAddRule();
    setId(newId);
    if (typeof onSelect === 'function') onSelect(newId);
    setNewlyAddedIds([...newlyAddedIds, newId]);
  };

  const clearId = useCallback(() => {
    setId(null);
  }, [setId]);

  const deleteRule = (id) => {
    delete contract.data.create.savedRules[id];
    delete stringifiedContents.current[id];
    if (typeof onDelete === 'function') onDelete(id);
    setDummy(dummy + 1);
  };

  useEffect(() => {
    for (const id in contract.data.create.savedRules) {
      if (!contract.data.create.savedRules[id].data) continue;
      stringifiedContents.current[id] = JSON.stringify(contract.data.create.savedRules[id].data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (evt) => setSearchString(evt.target.value);

  const onChangeShowAllRuleContent = (evt) => setShowRuleContent(evt.target.checked);

  const handleFilterTypeChange = (value) => {
    setFilterType(value);
  };

  const onChangeFilterEnabled = (evt) => setFilterEnabled(evt.target.checked);

  const ruleKeys = Object.keys(contract.data.create.savedRules);

  const lowerSearchString = searchString.toLocaleLowerCase();

  let filteredRules = lowerSearchString
    ? ruleKeys.filter((id) => {
        if (typeof id === 'string' && id.toLocaleLowerCase().includes(lowerSearchString)) {
          return true;
        }

        const ruleItem = contract.data.create.savedRules[id];
        if (id.includes(lowerSearchString)) return true;
        if (
          typeof ruleItem.label === 'string' &&
          ruleItem.label.toLocaleLowerCase().includes(lowerSearchString)
        ) {
          return true;
        }
        if (
          typeof ruleItem.description === 'string' &&
          ruleItem.description.toLocaleLowerCase().includes(lowerSearchString)
        ) {
          return true;
        }
        if (stringifiedContents.current[id] && stringifiedContents.current[id].includes(lowerSearchString))
          return true;
        return false;
      })
    : ruleKeys;

  if (filterEnabled && filterType) {
    filteredRules = filteredRules.filter((id) => {
      const rule = contract.data.create.savedRules[id];
      if (!rule) return false;
      return matchRule(rule, filterType);
    });
  }

  filteredRules.sort(sortFirst(newlyAddedIds));

  const isSelectable = typeof onSelect === 'function';

  return (
    <div>
      <Drawer
        visible={true}
        onClose={onClose}
        title={
          <>
            <div>Contract Rules</div>
            {filteredRules.length > 0 && isSelectable && (
              <Alert
                type="info"
                className="mt-2"
                message={
                  <div>
                    <span>Click on the</span>
                    <Avatar icon={<LinkOutlined />} className="ml-1 mr-1" />
                    <span>icon to select that rule</span>
                  </div>
                }
              />
            )}
          </>
        }
        className={'all-rules-drawer' + (isSelectable ? ' is-selectable' : '')}
        width={500}
      >
        <div className="comment-top border-bottom py-3">
          <div className="p-3">
            <Input
              // size="large"
              placeholder="Search"
              prefix={<SearchOutlined />}
              value={searchString}
              onChange={handleSearchChange}
              allowClear
            />
          </div>
          <span className="link mr-2" onClick={initNewRule}>
            New Rule
          </span>
          <span className="mr-2">
            <Checkbox checked={showRuleContent} onChange={onChangeShowAllRuleContent}>
              Show contents
            </Checkbox>
          </span>
          <span>
            <Checkbox checked={filterEnabled} onChange={onChangeFilterEnabled}>
              Filter rules
            </Checkbox>
          </span>
          {filterEnabled && (
            <div className="rules-filter-select">
              <Select
                onChange={handleFilterTypeChange}
                value={filterType}
                style={{ minWidth: '150px' }}
                placeholder="Select filter"
              >
                {Object.keys(ruleTypes).map((ruleType) => {
                  const { label, sub } = ruleTypes[ruleType];
                  if (sub) return null;
                  return (
                    <Select.Option value={ruleType} key={ruleType}>
                      {label}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          )}
        </div>
        {addingNewRule && <NewRuleInput onAdd={addNewRule} onCancel={cancelAddRule} />}
        <div style={{ marginTop: '15px' }}>
          <h4>{filteredRules.length} Rules</h4>
        </div>
        {filteredRules.length === 0 && <div className="ml-2">No rules found</div>}
        {filteredRules.map((ruleId) => {
          return (
            <RuleItem
              key={ruleId}
              id={ruleId}
              contract={contract}
              setId={setId}
              showRuleContent={showRuleContent}
              deleteRule={deleteRule}
              onSelect={onSelect}
              currentRuleId={currentRuleId}
            />
          );
        })}
      </Drawer>

      {id && (
        <RuleModal
          contract={contract}
          id={id}
          onCancel={clearId}
          onOk={clearId}
          onSave={onSave}
          onSaveAs={onSaveAs}
        />
      )}
    </div>
  );
}

function SmallEm({ children, disabled }) {
  if (disabled) return children;
  return (
    <small>
      <em>{children}</em>
    </small>
  );
}

function RuleItem({ contract, id, setId, showRuleContent, deleteRule, onSelect, currentRuleId }) {
  const { label, description, createdAt, updatedAt, data } = contract.data.create.savedRules[id];
  const [showMore, setShowMode] = useState(false);
  const toggleMore = () => setShowMode(!showMore);
  const [editableLabel, setEditableLabel] = useState(label || '');
  const [editableDescription, setEditableDescription] = useState(description || '');

  const onEditableLabelChange = (val) => {
    setEditableLabel(val);
    contract.data.create.savedRules[id].label = val;
  };
  const onEditableDescriptionChange = (val) => {
    setEditableDescription(val);
    contract.data.create.savedRules[id].description = val;
  };

  const isSelectable = typeof onSelect === 'function';

  const selectItem = () => {
    if (isSelectable) onSelect(id);
  };

  return (
    <div className="display-rule-item-holder">
      <div
        className={
          'display-rule-item-avatar ' +
          (isSelectable ? 'is-selectable' : '') +
          (isSelectable && currentRuleId === id ? ' current-rule' : '')
        }
      >
        <Avatar onClick={selectItem} icon={<LinkOutlined />} />
      </div>
      <div className="display-rule-item-body">
        <div className={'display-rule-item-body-top'}>
          <div className="display-rule-item-label">
            <SmallEm disabled={!!editableLabel}>
              <Paragraph
                onClick={(evt) => evt.stopPropagation()}
                editable={{ onChange: onEditableLabelChange, tooltip: 'Click to edit label' }}
                className="mb-0"
              >
                {editableLabel || 'No label'}
              </Paragraph>
            </SmallEm>
          </div>
          <div>
            <div>
              <SmallEm disabled={!!editableDescription}>
                <Paragraph
                  onClick={(evt) => evt.stopPropagation()}
                  editable={{ onChange: onEditableDescriptionChange, tooltip: 'Click to edit description' }}
                >
                  {editableDescription || 'No description'}
                </Paragraph>
              </SmallEm>
            </div>
            {showRuleContent && (
              <div>
                <div>
                  <small>Content:</small>
                </div>
                <PrintRule rule={data} />
              </div>
            )}
            {showMore && (
              <div className="mt-2">
                {!showRuleContent && (
                  <div>
                    <div>
                      <small>Content:</small>
                    </div>
                    <PrintRule rule={data} />
                  </div>
                )}
                <div className="mt-2">
                  <small>Id: {id}</small>
                </div>
                <div>
                  <small>Created at: {fixDate(createdAt)}</small>
                </div>
                <div>
                  <small>Updated at: {fixDate(updatedAt)}</small>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="display-rule-item-menu">
        <div>
          <span
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              setId(id);
            }}
          >
            Edit
          </span>
        </div>
        <div>
          <span
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              toggleMore();
            }}
          >
            {showMore ? 'Less' : 'More'} info
          </span>
        </div>
        <div>
          <span
            onClick={(evt) => {
              evt.preventDefault();
              evt.stopPropagation();
              deleteRule(id);
            }}
          >
            Delete
          </span>
        </div>
      </div>
    </div>
  );
}

function matchRule(rule, filterType) {
  if (filterType === 'code' && rule.code) return true;
  const ruleType = ruleToType(rule.data);
  if (filterType === ruleType) return true;
  if (ruleType === 'conjunction') {
    const conj = firstKey(rule.data);
    for (const sub of rule.data[conj]) {
      const subType = ruleToType(sub);
      if (filterType === subType) return true;
    }
  }
}
