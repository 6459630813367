import React from 'react';
import { Button, Tooltip, Menu } from 'antd';
import { setEventState } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import Icon, {
  PlusSquareOutlined,
  EditOutlined,
  PlusCircleFilled,
  EditTwoTone,
  PlusCircleTwoTone,
} from '@ant-design/icons';

export default function TemplatePageActions({ page, menuType = 'button', ...rest }) {
  const openInputSectionMenu = (type, method, selected = null, disableModal = false) => {
    setEventState('template_edit_input_sections', { type, method, selected, disableModal, page });
  };

  if (menuType === 'items') {
    return (
      <>
        {page.collapse ? (
          <Menu.Item
            {...rest}
            key="add-child"
            eventKey="add-child"
            icon={<Icon component={() => <i className="mdi mdi-library-plus" />} />}
            onClick={() => openInputSectionMenu('page', 'add', { page, addChildPage: true })}
          >
            <IntlMessages id="studio.template.modal.page.addChild" />
          </Menu.Item>
        ) : (
          <Menu.Item
            {...rest}
            key="add-card"
            eventKey="add-card"
            icon={<PlusSquareOutlined />}
            onClick={() => openInputSectionMenu('card', 'add', { page })}
          >
            <IntlMessages id="studio.template.modal.card.add" />
          </Menu.Item>
        )}
        <Menu.Item
          {...rest}
          key="edit-page"
          eventKey="edit-page"
          icon={<EditOutlined />}
          onClick={() => openInputSectionMenu('page', 'edit', { editPage: true, page })}
        >
          <IntlMessages id="studio.template.modal.page.edit" />
        </Menu.Item>
      </>
    );
  }

  return (
    <div className="template-topbar-actions">
      {page.collapse ? (
        <Tooltip title={<IntlMessages id="studio.template.modal.page.addChild" />} placement="bottom">
          <Button
            shape="rounded"
            // size="small"
            type="primary"
            className="m-0"
            icon={<i className="mdi mdi-library-plus" />}
            onClick={() => openInputSectionMenu('page', 'add', { page, addChildPage: true })}
          />
        </Tooltip>
      ) : (
        <Tooltip title={<IntlMessages id="studio.template.modal.card.add" />} placement="bottom">
          <Button
            shape="rounded"
            type="primary"
            icon={<PlusSquareOutlined />}
            onClick={() => openInputSectionMenu('card', 'add', { page })}
            className="m-0"
          />
        </Tooltip>
      )}

      <Tooltip title={<IntlMessages id="studio.template.modal.page.edit" />} placement="bottom">
        <Button
          shape="rounded"
          type="primary"
          icon={<EditOutlined />}
          onClick={() => openInputSectionMenu('page', 'edit', { editPage: true, page })}
          className="m-0 ml-2"
        />
      </Tooltip>
    </div>
  );
}
