import React, { useState, memo, useEffect } from 'react';
import { Spin, Table, Dropdown, Menu, Checkbox, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setNumber } from 'appRedux/actions';
import { fixDate } from 'components/ui';
import { useEntityData } from '../../hooks/api';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import { RemoveModal } from 'components/ui';
import { Entity } from '../../core/interfaces';

const { Column } = Table;

function sortNewFirst(a, b) {
  return a.createdAt < b.createdAt ? 1 : -1;
}
function sortUnseenFirst(a, b) {
  if (!a.seenAt && b.seenAt) return -1;
  else if (a.seenAt && !b.seenAt) return 1;
  return 0;
}

function resourceTypeUrl(resourceType) {
  switch (resourceType) {
    case 'Project':
      return '/projects';
    case 'Document':
      return '/documents';
    case 'Version':
      return '/versions';
    default:
      return '';
  }
}

async function fetchAdditionalInfo(notifications) {
  const events = notifications
    .map((i) => i.Event)
    .filter((e) => !!e)
    .flat();
  const byResourceType = events.reduce((acc, { resourceType, resourceId }) => {
    if (!acc[resourceType]) acc[resourceType] = [resourceId];
    else acc[resourceType].push(resourceId);
    return acc;
  }, {});
  const eventEntityIds = [...new Set(events.map((evt) => evt.entityId).filter((id) => !!id))];

  const promises = [];
  const resourceTypesOrder = [];
  for (const [resourceType, ids] of Object.entries(byResourceType)) {
    const url = resourceTypeUrl(resourceType);
    if (!url) continue;
    resourceTypesOrder.push(resourceType);
    promises.push(api.get(`${url}?ids=${JSON.stringify(ids)}&fields=id,name,description`));
  }

  const resourcesInfo = (await Promise.all(promises)).reduce((acc, curr, i) => {
    acc[resourceTypesOrder[i]] = curr.data.reduce((xAcc, xCurr) => {
      xAcc[xCurr.id] = xCurr;
      return xAcc;
    }, {});
    return acc;
  }, {});

  return { resourcesInfo, eventEntityIds };
}

const Notifications = memo((props) => {
  const [notifications, setNotifications] = useState(null);
  const [notificationsError, setNotificationsError] = useState(null);
  const [resourcesInfo, setResourcesInfo] = useState(null);
  const [entityIds, setEntityIds] = useState(null);
  const dispatch = useDispatch();

  const notificationWasRemoved = (id) => {
    setNotifications([...notifications.filter((n) => n.id !== id)]);
  };

  const notificationWasSeen = (id, value) => {
    const seenAt = value ? new Date().toISOString() : null;
    setNotifications(
      notifications.map((n) => {
        if (n.id !== id) return n;
        return { ...n, seenAt };
      })
    );
  };

  useEffect(() => {
    if (!notifications) return;
    const newNotices = notifications.filter((n) => !n.seenAt);
    dispatch(setNumber('newNotifications', newNotices.length));
  }, [dispatch, notifications]);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/notifications/my?withEvents=true');
        if (response.data) {
          setNotifications(response.data.sort(sortNewFirst).sort(sortUnseenFirst));
          const { resourcesInfo, eventEntityIds } = await fetchAdditionalInfo(response.data);
          setResourcesInfo(resourcesInfo);
          setEntityIds(eventEntityIds);
        } else setNotificationsError(true);
      } catch (err) {
        setNotificationsError(true);
      }
    })();
  }, []);

  const entityData = useEntityData(entityIds);

  return (
    <div className="padded-page">
      <h2 className="entry-title ant-card-head-wrapper mb-3">
        <IntlMessages id="app.general.notifications" cap />
      </h2>

      {!notifications && (
        <div className="d-flex align-items-center justify-content-center h-100">
          <Spin size="large" />
        </div>
      )}

      {notificationsError && (
        <div className="d-flex align-items-center justify-content-center h-100">
          Cannot fetch notifications
        </div>
      )}
      {notifications && !notificationsError && (
        <div className="full-table-view transparent-th">
          <NotificationsTable
            notifications={notifications}
            notificationWasRemoved={notificationWasRemoved}
            notificationWasSeen={notificationWasSeen}
            resourcesInfo={resourcesInfo}
            entityData={entityData}
          />
        </div>
      )}
    </div>
  );
});

const config = {
  bordered: false,
  loading: false,
  pagination: { position: 'bottom' },
  size: 'default',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  scroll: undefined,
};

function getResourcePath(resourceType, resourceId) {
  const base = resourceTypeUrl(resourceType);
  if (!base) return '';
  return `${base}/${resourceId}`;
}

function GotoResource({ resourceType, resourceId, resourcesInfo }) {
  const history = useHistory();
  const resource = resourcesInfo?.[resourceType]?.[resourceId];
  if (!resourcesInfo) return <Skeleton.Input active={true} size="small" />;

  const { name = 'Unknown' } = resource || {};

  const path = getResourcePath(resourceType, resourceId);
  if (path) {
    return (
      <span className="link" onClick={() => history.push(path)}>
        {name}
      </span>
    );
  }

  return <div>{name}</div>;
}

export function NotificationsTable({
  notifications,
  notificationWasRemoved,
  notificationWasSeen,
  resourcesInfo,
  entityData,
}) {
  const rowSelection = null; /* {
    selectedRowKeys,
    onChange: onSelectChange,
  }; */

  const removeNotification = (id) => {
    api
      .delete('/notifications/' + id)
      .then((result) => {
        notificationWasRemoved(id);
      })
      .catch((err) => {
        console.log('Error removing notification ', id, err.response);
      });
  };

  const onChangeSeen = (id, value) => {
    api
      .put(`/notifications/${id}/seen`, { value })
      .then((result) => {
        notificationWasSeen(id, value);
      })
      .catch((err) => {
        console.log('Error toggling notification seen ', id, err, err.response);
      });
  };

  return (
    <Table
      rowKey="id"
      className="table-responsive ml-3"
      {...config}
      rowSelection={rowSelection}
      dataSource={notifications}
    >
      <Column
        title={<IntlMessages id="app.general.time" cap />}
        dataIndex="createdAt"
        key="createdAt"
        render={(text, record) => {
          return <small>{fixDate(record.Event.createdAt, { breakDateAndTime: false })}</small>;
        }}
      />
      <Column
        title={<IntlMessages id="app.events.target" cap />}
        dataIndex="resourceType"
        key="resourceType"
        render={(text, record) => {
          return <IntlMessages id={`app.resources.${record.Event.resourceType}`} className="fs-sm" cap />;
        }}
      />
      <Column
        title={<IntlMessages id="app.general.Name" cap />}
        key="document"
        render={(text, record) => {
          return (
            <GotoResource
              resourceType={record.Event.resourceType}
              resourceId={record.Event.resourceId}
              resourcesInfo={resourcesInfo}
            />
          );
        }}
      />
      <Column
        title={<IntlMessages id="general.event" cap />}
        dataIndex="eventKey"
        key="eventKey"
        render={(text, record) => (
          <small>
            <strong>
              <IntlMessages id={`app.events.types.${record.Event.eventKey}`} cap />
            </strong>
            {entityData && entityData[record.Event.entityId] && (
              <span>
                <IntlMessages id="desc.by" className="ml-1 mr-1" />
                {Entity.name(entityData[record.Event.entityId])}
              </span>
            )}
          </small>
        )}
      />
      {/*
      <small>
            <strong>{Entity.name(entityData[record.Event.entityId])}: </strong>
            <IntlMessages id={`app.events.types.${record.Event.eventKey}`} cap />
          </small>
                      */}
      <Column
        title={<IntlMessages id="app.general.Action" />}
        key="actions"
        render={(text, record) => (
          <Checkbox
            checked={record.seenAt !== null}
            onChange={(evt) => onChangeSeen(record.id, evt.target.checked)}
          >
            Seen
          </Checkbox>
        )}
      />
      <Column
        title={<IntlMessages id="app.general.More" />}
        dataIndex={null}
        key="menu"
        render={(text, record) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="remove">
                  <RemoveModal
                    onConfirm={() => removeNotification(record.id)}
                    confirmText={
                      <>
                        <IntlMessages id="app.general.confirmRemoval" />{' '}
                        <IntlMessages id={'app.general.notification'} />?
                      </>
                    }
                  >
                    <span className="">
                      <IntlMessages id="desc.Remove" /> <IntlMessages id={'app.general.notification'} />
                    </span>
                  </RemoveModal>
                </Menu.Item>
              </Menu>
            }
          >
            <span className="link ant-dropdown-link">
              <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
            </span>
          </Dropdown>
        )}
      />
    </Table>
  );
}

export default Notifications;
