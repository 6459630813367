import React, { useCallback, useEffect, memo, useState, useRef, forwardRef } from 'react';
import { ReactEditor, useSlate } from 'slate-react';
import { Editor, Element, Path, Range } from 'slate';
import { Button } from 'antd';
import Portal from '../Portal';
import { AVAILABLE_CMDS, executeCommand } from './commands';

const EDITOR_CMDS_ENABLED = true;
const CMD_CHAR = '/';

export const CmdSuggestionsWrapper = ({ onEscapeFnRef, isEditingTemplate, addOnKeyDown }) => {
  const WITH_CMDS = EDITOR_CMDS_ENABLED && !isEditingTemplate;

  if (!WITH_CMDS) return null;

  return <CmdSuggestions onEscapeFnRef={onEscapeFnRef} addOnKeyDown={addOnKeyDown} />;
};

function getEditorCmdCommands(editor) {
  const { selection } = editor;

  if (!selection?.anchor || !Range.isCollapsed(selection)) {
    return [];
  }
  const [start] = Range.edges(editor.selection);
  const wordBefore = Editor.before(editor, start, { unit: 'word' });
  if (start.offset === 1) {
    const oneRange = Editor.range(editor, wordBefore, start);
    const char = oneRange && Editor.string(editor, oneRange);
    if (char !== CMD_CHAR) {
      return [];
    }
    const elements = Array.from(Editor.nodes(editor)).filter(([element]) => Element.isElement(element)) || [];
    const matches = AVAILABLE_CMDS.filter((cmd) => {
      if (cmd.condition) {
        return cmd.condition(editor, elements);
      }
      return true;
    });
    return [oneRange, matches];
  }

  const before = wordBefore && Editor.before(editor, wordBefore);

  if (!before?.path || !Path.equals(before.path, selection.anchor.path)) {
    return [];
  }

  const beforeRange = Editor.range(editor, before, start);
  if (!beforeRange || beforeRange.anchor.offset !== 0) {
    return [];
  }
  const beforeText = beforeRange && Editor.string(editor, beforeRange);
  if (beforeText[0] !== CMD_CHAR) {
    return [];
  }
  const potentialCmd = beforeText.substr(1);
  const elements = Array.from(Editor.nodes(editor)).filter(([element]) => Element.isElement(element)) || [];
  const matches = AVAILABLE_CMDS.filter((cmd) => {
    if (!cmd.key.startsWith(potentialCmd) && !cmd.desc.includes(potentialCmd)) return false;
    if (cmd.condition) {
      return cmd.condition(editor, elements);
    }
    return true;
  });
  return [beforeRange, matches];
}

const CmdSuggestions = ({ addOnKeyDown }) => {
  const [targetRange, setTargetRangeState] = useState(null);
  const [commands, setCommandsState] = useState([]);
  const [index, setIndexState] = useState(0);

  const ref = useRef();
  const valuesRef = useRef({});
  const executionRef = useRef(executeCommand);
  const editor = useSlate();

  const setTargetRange = (value) => {
    valuesRef.current.targetRange = value;
    setTargetRangeState(value);
  };
  const setCommands = (value) => {
    valuesRef.current.commands = value;
    setCommandsState(value);
  };
  const setIndex = (value) => {
    valuesRef.current.index = value;
    setIndexState(value);
  };

  useEffect(() => {
    addOnKeyDown('cmdSuggestionActions', (event, editor, next) => {
      const { targetRange, commands, index } = valuesRef.current;
      if (!targetRange || !commands) {
        return next();
      }
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          console.log('Arrow down?');
          const prevIndex = index >= commands.length - 1 ? 0 : index + 1;
          console.log('Arrow down?', prevIndex);
          setIndex(prevIndex);
          break;
        case 'ArrowUp':
          event.preventDefault();
          const nextIndex = index <= 0 ? commands.length - 1 : index - 1;
          setIndex(nextIndex);
          break;
        case 'Tab':
        case 'Enter':
          event.preventDefault();
          executionRef.current(editor, commands[index], targetRange);
          setTargetRange(null);
          break;
        case 'Escape':
          event.preventDefault();
          setTargetRange(null);
          break;
        default:
          next();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const [range, cmds] = getEditorCmdCommands(editor);

    if (range && cmds.length) {
      setTargetRange(range);
      setCommands(cmds);
      return;
    }

    if (valuesRef.current.targetRange) setTargetRange(null);
    if (valuesRef.current.commands?.length) setCommands([]);
  }, [editor, editor.selection]);

  useEffect(() => {
    setIndex(0);
    valuesRef.current.index = 0;
  }, [commands]);

  useEffect(() => {
    // Update valuesRef.
    const el = ref.current;
    if (!el) return;
    if (targetRange && commands.length > 0) {
      valuesRef.current.targetRange = targetRange;
      valuesRef.current.commands = commands;

      const domRange = ReactEditor.toDOMRange(editor, targetRange);
      if (!domRange) return;
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
      el.style.opacity = 1;
    } else {
      el.style.opacity = 0;
      el.style.top = '-10000px';
      el.style.left = '-10000px';
    }
  }, [editor, commands, targetRange]);

  const close = useCallback(() => {
    setTargetRange(null);
    setCommands([]);
    setIndex(0);
  }, []);

  const onClickIndex = useCallback(
    (index) => {
      const { commands, targetRange } = valuesRef.current;
      executionRef.current(editor, commands[index], targetRange);
    },
    [editor]
  );

  return (
    <CmdSuggestionsRenderPortal
      commands={commands}
      ref={ref}
      onClickIndex={onClickIndex}
      close={close}
      index={index}
    />
  );
};

const CmdSuggestionsRenderPortal = memo(
  forwardRef(({ commands, onClickIndex, close, index }, ref) => {
    return (
      <Portal>
        <div ref={ref} className={'inline-editor-menu cmds'}>
          <div className="editor-cmds">
            <div className="header">Insert</div>
            <Button
              type="link"
              className="close"
              size="small"
              icon={<i className="mdi mdi-close" />}
              onClick={close}
            />
            <div className="content">
              <ul>
                {commands.map((command, i) => (
                  <li
                    key={command.key}
                    className={i === index ? 'active' : ''}
                    onClick={() => onClickIndex(i)}
                  >
                    {command.desc}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Portal>
    );
  })
);
