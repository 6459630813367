export const eachesHandleGroupVariantsSlate = {
  id: 'eachesHandleGroupVariantsSlate',
  dependencies: { repeatableChange: true, repeatableRemove: true, ordinary: true },
  // We include dependency `repeatableRemove` in order to update/reset certain `group` variants
  // upon the deletion of a repeatable. Hence, the somewhat weird code below.
  match: ({ entries, node }) => {
    return node.variant === 'group' && node.data && node.data.group_repeatable;
  },
  handler: function ({ state, handlerInvoked, entries, api, matches }) {
    let relevantDefinitions = [];
    const concepts = api.interfaces.Contract.getConcepts(this.contract);

    for (const { cardId } of entries) {
      const repeatable = cardId;
      for (const concept of concepts) {
        if (concept.stateId && concept.stateId === repeatable) {
          if (concept.definitionKey) relevantDefinitions.push(concept.definitionKey);
          // this.log('Got self rep concept !')
        }
        if (
          concept.id &&
          concept.inheritance &&
          concept.inheritance.inherit &&
          concept.inheritance.inherit.includes(repeatable)
        ) {
          if (concept.definitionKey) relevantDefinitions.push(concept.definitionKey);
        }
      }
    }

    const affectedConcepts = handlerInvoked.repeatableChange
      ? this._tmpDraft.allAffectedACP.changesAffectingDetailedFieldMapping
      : this._tmpDraft.allAffectedACP.changesInConceptNumber;

    relevantDefinitions = [...new Set(relevantDefinitions)];

    for (const { node, actions } of matches.default) {
      if (node.data.parent_repeatable_path && node.data.connected) {
        // this.log('yesss 2?', {entries})
        for (const entry of entries) {
          if (isConnectedGroupEvent(node, entry, state, this.api)) {
            const value = this.handleConnectedGroup({ node, state, entry });
            if (!value) {
              continue;
            }
            actions.replaceText(value, { setValue: true });
          }
        }
      } else if (affectedConcepts.includes(node.data.group_repeatable)) {
        for (const entry of entries) {
          const { field, path, fieldName, pathInvoked } = entry;
          let proceed = false;

          if (fieldName === '_uid') continue;

          if (node.data.key && node.data.key !== fieldName) proceed = false;
          else proceed = true;
          if (node.data.alt_keys && node.data.alt_keys.includes(fieldName)) proceed = true;
          else if (pathInvoked.repeatableRemove) proceed = true;
          else if (node.data.entityKey && (!field || !['entity', 'party'].includes(field.type))) {
            // this.log('Entity but not party')
            proceed = false;
          }
          /* 
          if (relevantDefinitions.includes(fieldName)) {
            proceed = true
          } */

          if (proceed) {
            const value = this.handleGroupVariant({ node, state });
            if (!value) {
              continue;
            }
            actions.replaceText(value, { setValue: true });
          }
        }
      } else if (node.data.filter) {
        const value = this.handleGroupVariant({ node, state }) || '[**]';

        if (value === node.data.value) {
          continue;
        }
        actions.replaceText(value, { setValue: true });
      }
    }
  },
};

function isConnectedGroupEvent(node, entry, state, api, contract) {
  const { cardId, fieldName, pathInvoked } = entry;

  if (pathInvoked.ordinary) {
    const { path } = entry;

    if (!path.includes('input.__connectedCards')) return false;
    const connectedData = api.utils.general.getByPath(state, api.interfaces.InputPaths.parentPath(path));
    if (!connectedData || !connectedData.cards) {
      if (typeof window !== 'undefined' && window.debug)
        console.log('No connected data or cards.. ', { path, state: JSON.parse(JSON.stringify(state)) });
      return false;
    }
    const { cards } = connectedData;
    if (
      cards.hasOwnProperty(node.data.connected) &&
      cards[node.data.parent_repeatable] === node.data.parent_repeatable_uid &&
      connectedData.key === node.data.groupKey
    ) {
      if (typeof window !== 'undefined' && window.debug)
        console.log('Yes __connectedData match.', {
          path,
          state: JSON.parse(JSON.stringify(state)),
          node: JSON.parse(JSON.stringify(node)),
          connectedData,
        });
      return true;
    } else {
      if (typeof window !== 'undefined' && window.debug)
        console.log('No connected data match.', {
          path,
          state: JSON.parse(JSON.stringify(state)),
          node: JSON.parse(JSON.stringify(node)),
          connectedData,
        });
    }
    return false;
  }

  if (node.data.group_repeatable !== cardId) return false;

  if (node.data.key === fieldName || pathInvoked.repeatableRemove) return true;

  const groupRepeatableConcept = api.interfaces.Contract.getConcept(contract, node.data.group_repeatable);
  if (!groupRepeatableConcept) return false;
  if (groupRepeatableConcept.definitionKey === fieldName) return true;

  return false;

  /* return (
    node.data.group_repeatable === cardId && (node.data.key === fieldName || pathInvoked.repeatableRemove)
  ); */
}

export const handleGroupVariant = function ({ node, state }) {
  const { group_repeatable } = node.data;
  const concept = this.api.interfaces.Contract.getConcept(this.contract, group_repeatable);
  // console.log('handle group variant ', group_repeatable)
  if (!concept) return;

  // this.log('Each Group Item ', { entry, iid: node.data.item_id });

  const conceptState = this.api.interfaces.Concept.getConceptState(concept, this.contract, state);

  /* this.log('Handle Group ? ', {
    node: JSON.parse(JSON.stringify(node)),
    conceptState,
    entry,
  }); */
  if (!conceptState) {
    if (typeof window !== 'undefined' && window.debug)
      this.log('No concept state for node: ', JSON.parse(JSON.stringify(node)));
    return;
  }
  let repeatable_states;
  if (node.data.filter) {
    repeatable_states = this.api.utils.general.ofilter(conceptState, (repState) =>
      this.applyLogic(node.data.filter, {
        state,
        local: repState,
      })
    );
  } else repeatable_states = conceptState;

  const values = this.eachGroupItem(node, repeatable_states, concept);

  return this.getGroupValue({ node, state }, values);
};

export const eachGroupItem = function (node, repeatableStates, concept) {
  if (!node || !node.data || !node.data.group_repeatable) {
    return;
  }

  const { entityKey, key } = node.data;

  let conceptInherits = false;

  let inheritedKeyMap = {};
  const inheritedKeys = [];
  if (concept.inheritance && Array.isArray(concept.inheritance.inherit)) {
    conceptInherits = true;
    for (const inheritedConceptId of concept.inheritance.inherit) {
      const inheritedConcept = this.api.interfaces.Contract.getConcept(this.contract, inheritedConceptId);
      if (!inheritedConcept || !inheritedConcept.stateKey) continue;
      inheritedKeyMap[inheritedConceptId] = inheritedConcept.stateKey;
      inheritedKeys.push(inheritedConcept.stateKey);
    }
  }

  // this.log('Group ? ', { repeatableStates, concept, inheritedKeyMap, key, entityKey });

  const values = this.api.utils.general
    .omap(repeatableStates, (repState) => {
      /* this.log('Rep state key ', {
        repState,
        krepState: repState[key],
        key,
      }); */
      if (key && repState[key]) {
        if (this.api.interfaces.Entity.isEntity(repState[key])) {
          return node.data.entityKey && repState[key][entityKey]
            ? repState[key][entityKey]
            : repState[key].name;
        }
        return this.cleanValue(repState[node.data.key]);
      } else if (Array.isArray(node.data.alt_keys)) {
        return node.data.alt_keys.map((altKey) => {
          if (repState[altKey]) {
            // console.log('Got alt key ', altKey, repState[altKey])
            if (this.api.interfaces.Entity.isEntity(repState[altKey])) {
              return node.data.entityKey && repState[altKey][entityKey]
                ? repState[altKey][entityKey]
                : repState[altKey].name;
            }
            return this.cleanValue(repState[altKey]);
          }
        });
      } else if (conceptInherits) {
        let relevantStateKey;
        if (repState._conceptId && inheritedKeyMap[repState._conceptId])
          relevantStateKey = inheritedKeyMap[repState._conceptId];
        else {
          relevantStateKey = inheritedKeys.find((inheritedKey) => repState.hasOwnProperty(inheritedKey));
        }
        if (!relevantStateKey || !repState[relevantStateKey]) {
          this.log('No relevantStateKey or no entry for it in the repeatable state', {
            repState,
            inheritedKeyMap,
            inheritedKeys,
          });
          return null;
        }
        // this.log('Concept inherits ', { repState, inheritedKeyMap, relevantStateKey });
        return this.cleanValue(repState[relevantStateKey]);
      }
    })
    .flat()
    .filter((item) => !!item);
  // this.log('each gorup item ', { values });
  if (values.length === 0) return ['[**]'];
  return values;
};

export const getGroupValue = function ({ node, state }, values) {
  if (!values || values.length === 0) {
    const value = this.emptyContractString();
    if (node.data.value === value) return value;

    return value;
  }

  let value;

  if (node.data.map) {
    const mappedCompanies = this.mapCompanies(values, state);
    // this.log('Mapped Companies .... ', {mappedCompanies, values})
    if (mappedCompanies && mappedCompanies.fullText_and) value = mappedCompanies.fullText_and;
  } else if (node.data.join === 'and') {
    value = this.imp_and(values);
  } else if (node.data.join === 'or') {
    value = this.imp_or(values);
  } else {
    value = values.join(', ');
  }

  if (node.data.value === value) {
    // console.log('value is same.');
    return value;
  }

  if (typeof window !== 'undefined' && window.debug) this.log('Get group value', value);

  return value;
};

///

export const handleConnectedGroup = function ({ node, state, entry }) {
  const { parent_repeatable_path } = node.data;
  const parentState = this.api.utils.general.getByPath(state, parent_repeatable_path);

  const values = this.eachConnectedGroupItem(node, parentState, state, entry);
  return this.getGroupValue({ node, state }, values);
};

// E.g. Group 'borrowers' within a 'facility' each repeatable
export const eachConnectedGroupItem = function (node, parentRepeatableState, state) {
  if (!parentRepeatableState) {
    if (typeof window !== 'undefined' && window.debug) this.log('eachConnectedGroupItem err 1');
    return;
  }
  if (!node || !node.data || !node.data.group_repeatable || !node.data.key || !node.data.connected) {
    if (typeof window !== 'undefined' && window.debug) this.log('eachConnectedGroupItem err 2');
    return;
  }
  if (!state.input.__connectedCards || state.input.__connectedCards.length === 0) {
    if (typeof window !== 'undefined' && window.debug) this.log('eachConnectedGroupItem err 3');
    return;
  }

  const { connected: connected_repeatable, parent_repeatable, groupKey, entityKey = 'name' } = node.data;

  // 'connected_repeatable' is the, local repeatable to be grouped if connected
  // to the master repeatable. I.e. if the template stipulates to group
  // all borrowers which are linked to a particular facility, then the
  // facility would be the parentRepeatable and the borrower would be
  // the local 'connected_repeatable'.

  const connectedPath = this.api.interfaces.InputPaths.construct(connected_repeatable);
  const connectedStates = this.api.utils.general.getByPath(state, connectedPath);

  if (!connectedPath || !connectedStates || Object.keys(connectedStates).length === 0) return;

  const { _uid: masterUid } = parentRepeatableState;
  const connectableUids = Object.keys(connectedStates); // All uids of the local group.

  const matches = state.input.__connectedCards
    .filter((item) => {
      if (!item.value) return false;
      if (item.key !== groupKey) return false;
      if (item.cards[parent_repeatable] !== masterUid) return false;
      if (!connectableUids.includes(item.cards[connected_repeatable])) return false;
      return true;
    })
    .map((item) => item.cards[connected_repeatable]);

  if (!matches || matches.length === 0) return;

  return matches
    .map((uid) => {
      if (!connectedStates[uid] || !connectedStates[uid][node.data.key]) return null;
      if (entityKey && connectedStates[uid][node.data.key]) {
        if (
          connectedStates[uid][node.data.key] &&
          connectedStates[uid][node.data.key].id &&
          this.api.interfaces.Entity.getFromStateById(
            this.states.current,
            connectedStates[uid][node.data.key].id
          )
        ) {
          const entity = this.api.interfaces.Entity.getFromStateById(
            this.states.current,
            connectedStates[uid][node.data.key].id
          );
          if (entity) {
            const entityValue = this.api.interfaces.Entity.getStringValue(entity, entityKey, {
              language: this.language,
            });
            return this.cleanValue(entityValue);
          }
        }
        // return connectedStates[uid][node.data.key][entityKey]
      }
      return this.cleanValue(connectedStates[uid] && connectedStates[uid][node.data.key]);
    })
    .filter((item) => !!item);
  // this.log('values are ', {values})

  /*
  "item_id": "0d4ba831-ad0b-4be0-ade0-34bc0xsad390",
  "key": "borrowerEntity",
  "connected": "borrower",
  "group_repeatable": "borrower",
  "map": true,
  "template_id": "4e76de7e-57ff-4058-b6fc-2fb3asdfcxry54"
  */
  /*
  __connectedCards =
  [
    {
      "cards": {
        "borrower": "b8051601319870821",
        "facility": "f3201601319768957"
      },
      "key": "facilityAvailableToBorrower",
      "value": true
    }
  ]
  */
};
