import React, { useState, useEffect } from 'react';
import { Card, Table } from 'antd';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import { useHistory } from 'react-router-dom';

const { Column } = Table

const EntityProjects = ({ entity }) => {
  const history = useHistory();
  const [projects, setProjects] = useState(null);

  useEffect(() => {
    if (!entity || !entity.id) return;
    api
      .get(
        '/entities/' +
          entity.id +
          '/projects?fields=id,name,description,updatedAt&includeDocumentRelations=true'
      )
      .then((res) => {
        if (res.data && res.data.EntityProjects) {
          setProjects(res.data.EntityProjects);
        }
      })
      .catch((err) => console.log('err fetch entity projects', err.response));
  }, [entity.id, entity]);

  return (
    <Card
      title={
        <span>
          <IntlMessages id="general.projects" cap />
        </span>
      }
      className="light-container widget"
    >
      <Table
        rowKey="id"
        dataSource={projects}
        pagination={false}
        locale={{ emptyText: <IntlMessages id="app.general.entity.noProjects" /> }}
      >
        <Column
          title="Name"
          dataIndex="name"
          ellipsis={true}
          render={(name, record) => (
            <div
              className="link"
              style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              onClick={() => { history.push('/project/' + record.id)}}
            >
              {name}
            </div>
          )}
        />
      </Table>
    </Card>
  );
};

export default EntityProjects;
