const isLogic = (logic) =>
  typeof logic === 'object' && // An object
  logic !== null && // but not null
  !Array.isArray(logic) && // and not an array
  Object.keys(logic).length === 1; // with exactly one key

function rounder(num, numDec) {
  const r = Math.pow(10, numDec);
  return Math.round((num + Number.EPSILON) * r) / r;
}

/*
TODO. Fix support for repeatables and local repeatable state
*/

export const logicModuleHandler = {
  id: 'logicModuleHandler',
  dependencies: { ordinary: true },
  match: ({ node }) => {
    return node.variant === 'logic' && node.data && isLogic(node.data.logic);
  },
  handler: function ({ api, matches }) {
    for (const { node, actions } of matches.default) {
      const { value, logic } = node.data;
      const logicValue = this.applyLogic(logic);

      let displayValue = logicValue;
      if (api.utils.general.isFloat(logicValue)) {
        const { round = 2 } = node.data;
        displayValue = rounder(logicValue, round);
      }
      if (value === displayValue) continue;

      actions.replaceText(displayValue, { setValue: true });
    }
  },
};
