import { Entity } from 'core/interfaces';
import { DEFAULT_ENTITY_VALUES } from 'core/config/constants';

export function getInitialEmails(entity) {
  if (!entity) return [];
  const { contact } = entity;
  if (!contact) return [];
  const contactEmails = contact.filter((c) => c.type === 'EMAIL').map((c) => c.address);
  return contactEmails;
}
function getInitialCapacities(entity, id, referringEntity, relationType) {
  if (referringEntity && id && entity) {
    const principal = relationType === 'Agent' ? referringEntity : entity;
    const agent = relationType === 'Agent' ? entity : referringEntity;
    const entityRelation = Entity.getRelation(principal, agent);
    if (entityRelation) {
      return entityRelation.capacities;
    }
  }
  return [];
}
function getInitialType(entity, relationType, defaultNewValues) {
  if (entity) return entity.type;
  if (defaultNewValues?.type) return defaultNewValues.type;
  if (relationType === 'Agent') return 'RealPerson';
  if (relationType === 'Principal') return 'LegalPerson';
  return DEFAULT_ENTITY_VALUES.type;
}

function get(entity, defaultNewValues, fieldName, defaultValue) {
  if (entity && entity[fieldName]) return entity[fieldName];
  if (defaultNewValues && defaultNewValues[fieldName]) return defaultNewValues[fieldName];
  return defaultValue;
}

export function getInitialValues(id, entity, referringEntity, relationType, defaultNewValues) {
  const emails = getInitialEmails(entity);
  const type = getInitialType(entity, relationType, defaultNewValues);
  const capacities = getInitialCapacities(entity, id, referringEntity, relationType);
  const contact = get(entity, defaultNewValues, 'contact', []);
  const identificationNumber = get(entity, defaultNewValues, 'identificationNumber', '');
  return { emails, type, capacities, contact, identificationNumber };
}
