import React from 'react';
import { Transforms } from 'slate';
import { useDispatch } from 'react-redux';
import { addRepeatable } from 'appRedux/actions';
import { setStudioEditorInputContent, setModalContext } from 'hooks';
import { Tooltip, Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const useRepeatableItem = ({ element, editor, contract }) => {
  // Fulhack
  if (!element?.data?._each_label_id) {
    return [];
  }

  const { _path } = element.data;

  if (!_path) return [];

  const openCard = () => {
    Transforms.forceDeselect(editor);
    setStudioEditorInputContent({
      path: _path,
    });
  };

  return ['Edit', 'mdi mdi-pencil-circle', openCard, element.data.item_id];
};

function addRepeatableWithEntityField(repeatable, entityField, dispatch) {
  function onFinishCallback(data) {
    if (data.entity && data.entity.id) {
      dispatch(addRepeatable(`input.${repeatable}`, { [entityField]: { id: data.entity.id } }));
    }
  }

  setModalContext({
    type: 'selectEntity',
    onFinishCallback,
  });
}

function getAddRepeatableItem(data, dispatch, element, editor) {
  const {
    addRepeatable: repeatable,
    icon = 'mdi mdi-plus-circle',
    label = 'Add',
    withEntityAsField,
  } = data || {};
  if (!repeatable) return [];

  const newRepeatable = () => {
    // Important. Otherwise the operations would get
    editor._isFocused = false;
    if (!withEntityAsField) {
      return dispatch(addRepeatable('input.' + repeatable));
    }

    addRepeatableWithEntityField(repeatable, withEntityAsField, dispatch);
  };

  return [label, icon, newRepeatable, element.data.item_id + repeatable];
}

const useAddRepeatable = ({ element, editor }) => {
  const dispatch = useDispatch();
  if (!element?.data?._ui) return [];
  if (!Array.isArray(element?.data?._ui?.values)) return [];

  const result = element.data._ui.values.map((value) =>
    getAddRepeatableItem(value, dispatch, element, editor)
  );
  if (element?.data?._ui.type === 'dropdown') {
    return [
      [
        'Add',
        'mdi mdi-plus-circle-outline',
        {
          dropdown: true,
          values: result,
        },
        element.data?.item_id
      ],
    ];
  }

  return result;
};

function getEditStudioPageItem(data, editor) {
  const { editPage, label = 'Edit' } = data || {};

  if (!editPage) return [];

  const openCard = () => {
    Transforms.forceDeselect(editor);
    setStudioEditorInputContent({
      studioPage: editPage,
    });
  };

  return [label, 'mdi mdi-pencil-circle', openCard, editPage];
}

const useEditStudioPage = ({ element, editor }) => {
  if (!element?.data?._ui) return [];
  if (Array.isArray(element?.data?._ui?.values))
    return element.data._ui.values.map((value) => getEditStudioPageItem(value, editor));
  return [];
};

function RenderEditItem({ item }) {
  const [, , instruction] = item;
  if (typeof instruction === 'object') {
    return <RenderEditDropdown item={item} />;
  }
  return <RenderEditItemPlain item={item} />;
}

function RenderEditDropdown({ item }) {
  const [label, icon, instruction] = item;
  const { values } = instruction;
  return (
    <Dropdown
      overlay={
        <Menu>
          {values.map((value) => {
            return (
              <Menu.Item key={value[3]}>
                <RenderEditItemPlain item={value} withText />
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      <Tooltip title={<>{label}</>}>
        <div
          className="offer-action-btn"
          contentEditable={false}
/*           onMouseDown={(evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            instruction();
          }} */
        >
          <i className={icon} />
          <DownOutlined />
        </div>
      </Tooltip>
      {/* <Button type="link" className="m-0 p-0">
        <i className={icon} />
        <DownOutlined />
      </Button> */}
    </Dropdown>
  );
}
function RenderEditItemPlain({ item, withText }) {
  const [label, icon, instruction] = item;
  return (
    <Tooltip title={<>{label}</>}>
      <div
        className="offer-action-btn"
        contentEditable={false}
        onMouseDown={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          instruction();
        }}
      >
        <i className={icon} />
        {withText && <span className="ml-1">{label}</span>}
      </div>
    </Tooltip>
  );
}

const useItems = (props) => {
  const repeatableItem = useRepeatableItem(props);
  const addRepeatable = useAddRepeatable(props);
  const editStudioPage = useEditStudioPage(props);

  const allItems = [...addRepeatable, repeatableItem, ...editStudioPage];

  const allActiveItems = allItems.filter((item) => item.length);
  const hasAnItem = allActiveItems.length > 0;

  if (hasAnItem) {
    return (
      <div className="elements-indicator" contentEditable={false}>
        <div className="offer-action-btn-holder">
          {allActiveItems.map((item) => (
            <RenderEditItem key={item[3]} item={item} />
          ))}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const OfferItems = ({ element, editor, contract }) => {
  // return <ActivesWrapper editor={editor} element={element} />;
  const items = useItems({ element, editor, contract });

  return items;
};
