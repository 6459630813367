import React from 'react';
import TopHeader from '../../project/Header/';
import { Entity } from 'core/interfaces';
import { useHistory } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';

const EntityHeader = (props) => {
  const history = useHistory();
  const { entity } = props;

  return (
    <TopHeader
      name={entity ? Entity.name(entity) : <em>Unknown person</em>}
      // description={<IntlMessages id={`app.persons.${Entity.getType(entity)}`} cap />}
      description={entity && entity.Principals.map((e, i) => {
        return (
          <span key={i}>
            {i > 0 && (
              <span>, </span>
            )}
            <a onClick={() => history.push(`/entity/${e.id}`)}>{e.firstName}</a>
          </span>
        )
      })}
      {...props}
    />
  );
};

export default EntityHeader;
