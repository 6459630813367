import { useState, useRef } from 'react';
import { Table, Button, Tag, Dropdown, Menu, Card } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { useSelector } from 'react-redux';
import { useMountFetch } from 'hooks';
import api from 'utils/api';
import { RemoveModal } from 'components/ui';
import Widget from 'components/Widget/index';
import EditEntity from 'components/entity/EditEntity';
import { Entity } from 'core/interfaces';
import FileList from 'components/File/FileList';
import WidgetHeader from 'components/WidgetHeader';
import NewFileUpload from 'components/File/NewFileUpload';

const { Column } = Table;

function NewUserButton({ onNewUserAdded }) {
  const [addingUser, setAddingUser] = useState('');
  const addUser = () => {
    setAddingUser(true);
  };
  const doAdd = (data) => {
    const { entity } = data;

    const newUser = {
      firstName: entity.firstName,
      lastName: entity.lastName,
      email: Entity.firstEmail(entity),
      entityId: entity.id,
    };

    api
      .post('/users', {
        user: newUser,
        entity,
      })
      .then((res) => {
        onNewUserAdded(res.data);
      })
      .catch((err) => {
        console.log('failed adding new user ', err.response);
      });

    setTimeout(() => {
      setAddingUser('');
    }, 40);
  };
  return (
    <>
      <Button type="primary" onClick={addUser}>
        <IntlMessages id="app.general.organisation.addUser" />
      </Button>
      {addingUser && (
        <EditEntity
          onCancel={() => setAddingUser('')}
          onFinishCallback={doAdd}
          redux={false}
          defaultNewValues={{
            type: 'RealPerson',
            jurisdiction: 'se',
          }}
          additionalRequiredFields={['email']}
          saveIntlId="app.general.create"
        />
      )}
    </>
  );
}

function OrganisationUsers({ user }) {
  const [users, setUsers] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  useMountFetch('/clients/userEntities', setUsers);
  const [mayEditClient] = useMountFetch(
    `/permissions/hasAccess?resourceType=Client&resourceId=${user.clientId}&action=edit`
  );

  const onNewUserAdded = (data) => {
    const newUserEntity = {
      ...data.entity,
      userId: data.user.id,
    };
    setUsers([...users, newUserEntity]);
  };

  const deleteUser = async (userId) => {
    try {
      const deletion = await api.delete(`/users/${userId}`);
      if (deletion.data?.deleted === userId) {
        setUsers(users.filter((u) => u.userId !== userId));
      }
    } catch (err) {
      console.log('Err deleting user', err);
    }
  };

  const handleDropdownChange = (val) => setDropdownVisible(val);

  return (
    <div className="px-5 my-5">
      <div className="light-container padded-page">
        <WidgetHeader
          title={<IntlMessages id="general.users" cap />}
        />
        <Card
          extra={mayEditClient && <NewUserButton onNewUserAdded={onNewUserAdded} />}
          headStyle={{paddingLeft: 0, marginTop: -16}}
        >
          <Table
            rowKey="id"
            className="table-responsive documents-table"
            dataSource={users && users.length > 0 ? users : null}
          >
            <Column
              title={<IntlMessages id="app.general.Name" />}
              dataIndex="firstName"
              render={(firstName, {lastName}) => `${firstName} ${lastName}`}
            />
            <Column
              title={<IntlMessages id="app.general.Email" />}
              dataIndex="email"
              key="email"
              render={(value, record) => {
                const emails = Entity.emails(record);
                return emails.join(', ');
              }}
            />
            {mayEditClient && (
              <Column
                title={<IntlMessages id="app.general.Actions" />}
                key="extraActions"
                render={(text, record) => {
                  return (
                    <div className="d-flex align-items-center">
                      <Dropdown
                        onVisibleChange={(val) => handleDropdownChange(val ? record.id : false)}
                        visible={dropdownVisible === record.id}
                        overlay={
                          <Menu>
                            <Menu.Item key="remove">
                              <RemoveModal
                                onConfirm={() => deleteUser(record.userId)}
                                confirmText={
                                  <>
                                    <IntlMessages id="app.general.confirmRemoval" />
                                    {` ${record.firstName} ${record.lastName}?`}
                                  </>
                                }
                              >
                                <span onClick={() => setDropdownVisible(false)}>
                                  <IntlMessages id="desc.Remove" /> <IntlMessages id="general.user" />
                                </span>
                              </RemoveModal>
                            </Menu.Item>
                          </Menu>
                        }
                      >
                        <span className="link ant-dropdown-link">
                          <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
                        </span>
                      </Dropdown>
                    </div>
                  );
                }}
              />
            )}
          </Table>
        </Card>
      </div>
    </div>
  );
}

function ClientFiles({ user }) {
  return (
    <div className="px-5 my-5">
      <div className="light-container padded-page">
        <WidgetHeader
          title={<IntlMessages id="app.file.files" />}
        />
        <FileList
          resourceId={user.clientId}
          resourceType="Client"
          apiRoute="clients"
          hideCard={false}
          hideNewUpload={true}
          uploadUiIfEmpty
        />
      </div>
    </div>
  );
}

export default function Organisation() {
  const { user, client } = useSelector((state) => state.auth);

  if (!user) {
    return <div>Not logged in</div>;
  }

  return (
    <div className="pb-5">
      <h2 className='pl-5 pt-4'>{client?.name}</h2>
      <OrganisationUsers user={user} />
      <ClientFiles user={user} />
    </div>
  );
}
