import React, { useState } from 'react';
import { Col, Row, Card, Button } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import InfoItem from './InfoItem';
import { EntityEmails } from 'components/ui';
import EditEntity from '../EditEntity';

export default function EntityInfo({ entity, updateEntity }) {
  const formatter = useIntlMessage();

  const [editingEntityId, setEditingEntityId] = useState(false);

  const onFinishEdit = (data) => {
    const { type, status } = data;
    if (type === 'update' && status === 'success') {
      updateEntity(data.entity);
    }

    setEditingEntityId(null);
  };

  if (!entity) {
    return <div>No main entity found for group.</div>;
  }

  const jurisdictionName = entity.jurisdiction ? formatter('app.countries.' + entity.jurisdiction) : 'none';
  const identificationNumber = entity.identificationNumber;

  return (
    <Card
      title={<IntlMessages id="app.general.Info" />}
      extra={
        <>
          <Button type="primary" onClick={() => setEditingEntityId(entity.id)}>
            <span>
              <IntlMessages id="general.edit" /> <IntlMessages id="app.general.Info" />
            </span>
          </Button>
          {editingEntityId && (
            <EditEntity
              id={editingEntityId}
              entity={entity}
              onCancel={() => setEditingEntityId(null)}
              onFinishCallback={onFinishEdit}
              redux={false}
            />
          )}
        </>
      }
      className="light-container widget"
    >
      <div className="mb-2">
        <Row>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <InfoItem
              data={{
                title: formatter('app.general.IdentificationNumber'),
                icon: 'mdi-album',
                desc: identificationNumber || (
                  <em>
                    <small>
                      <IntlMessages id="desc.None" cap />
                    </small>
                  </em>
                ),
              }}
            />
          </Col>
          <Col xl={8} lg={12} md={12} sm={12} xs={24}>
            <InfoItem
              data={{
                title: formatter('app.general.Jurisdiction'),
                icon: 'mdi-map-marker',
                desc: jurisdictionName || (
                  <em>
                    <small>
                      <IntlMessages id="desc.None" cap />
                    </small>
                  </em>
                ),
              }}
            />
          </Col>
        </Row>
        <p className="mt-4"></p>
        <EntityInfoItems entity={entity} />
      </div>
    </Card>
  );
}

function EntityInfoItems({ entity }) {
  const formatter = useIntlMessage();

  return (
    <>
      <Row>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <InfoItem
            data={{
              title: formatter('app.general.City'),
              icon: 'mdi-tag',
              desc:
                entity.address && entity.address.city ? (
                  entity.address.city
                ) : (
                  <em>
                    <small>
                      <IntlMessages id="desc.None" cap />
                    </small>
                  </em>
                ),
            }}
          />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <InfoItem
            data={{
              title: formatter('app.general.Street'),
              icon: 'mdi-tag',
              desc:
                entity.address && entity.address.street ? (
                  entity.address.street
                ) : (
                  <em>
                    <small>
                      <IntlMessages id="desc.None" cap />
                    </small>
                  </em>
                ),
            }}
          />
        </Col>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <InfoItem
            data={{
              title: formatter('app.general.Zip'),
              icon: 'mdi-tag',
              desc:
                entity.address && entity.address.zipCode ? (
                  entity.address.zipCode
                ) : (
                  <em>
                    <small>
                      <IntlMessages id="desc.None" cap />
                    </small>
                  </em>
                ),
            }}
          />
        </Col>
      </Row>
      <p className="mt-4"></p>
      <Row>
        <Col xl={8} lg={12} md={12} sm={12} xs={24}>
          <InfoItem
            data={{
              title: formatter('app.persons.emails', 'cap'),
              icon: 'mdi-email',
              desc: (
                <EntityEmails
                  entity={entity}
                  fallback={
                    <em>
                      <small>
                        <IntlMessages id="desc.None" cap />
                      </small>
                    </em>
                  }
                />
              ),
            }}
          />
        </Col>
      </Row>
    </>
  );
}
