// Slate handlers
export const QandA_addSlate = {
  id: 'QandA_addSlate',
  dependencies: {
    ordinaryQuestionAnswerAdd: true,
  },
  match: ({ entries, node }) => {
    for (const entry of entries) {
      const { cardId, fieldName, path } = entry;
      const fieldPath = 'input.' + cardId + '.' + fieldName;
      // const itemUid = this.api.interfaces.InputPaths.getLast(path);

      if (!['clause', 'table'].includes(node.type)) return false;

      if (node.data && node.data.qa && node.data.qa.fieldPath === fieldPath) return true;
    }
  },
  handler: function ({ entries, matches }) {
    return console.log('Fix Q and A.');

    /*  for (const entry of entries) {
      const { cardId, fieldName, path } = entry;
      const fieldPath = 'input.' + cardId + '.' + fieldName;
      const itemUid = this.api.interfaces.InputPaths.getLast(path);

      for (const { actions } of matches.default) {
        const newItem = this.makeParagraph(
          [
            { text: '' },
            this.makeInlineNode('field', 'variant', this.emptyContractString(), {
              qa: { fieldPath: fieldPath + '.' + itemUid + '.q' },
            }),
            { text: ' ' },
            this.makeInlineNode('field', 'variant', this.emptyContractString(), {
              qa: { fieldPath: fieldPath + '.' + itemUid + '.a' },
            }),
          ],
          { qa: { fieldPath: fieldPath + '.' + itemUid } }
        );
        actions.insertChildren(newItem);
      }
    } */
  },
};

export const QandA_removeSlate = {
  id: 'QandA_removeSlate',
  dependencies: {
    ordinaryQuestionAnswerRemove: true,
  },
  match: ({ entries, node }) => {},
  handler: function ({ matches }) {
    // Old flow-handler. Inspiration for new slate handler.
    /* const { cardId, fieldName } = entry;
    const itemUid = this.api.interfaces.InputPaths.getLast(path);
    const fieldPath = 'input.' + cardId + '.' + fieldName + '.' + itemUid;

    return ([node, nodePath], parents, index, options) => {
      if (!node.data || !node.data.qa || node.data.qa.fieldPath !== fieldPath) return;

      const { node: closestParentNode } = parents.slice(-1)[0];
      closestParentNode.children.splice(index, 1);
    }; */
  },
};

export const QandA_changeSlate = {
  id: 'QandA_changeSlate',
  dependencies: {
    ordinaryQuestionAnswerChange: true,
  },
  match: ({ entries, node }) => {},
  handler: function ({ matches }) {
    /* return ([node, nodePath], index, options) => {
      if (node.type !== 'field' || !node.data || !node.data.qa || node.data.qa.fieldPath !== path) return;

      const value = entry.value || this.emptyContractString();

      api.utils.engine.setFirstChild(node, value);
      
    }; */
  },
};

export const repeatableQandA_addSlate = {
  id: 'repeatableQandA_addSlate',
  dependencies: {
    repeatableQuestionAnswerAdd: true,
  },
  match: ({ entries, node }) => {},
  handler: function ({ matches }) {
    /* const { cardId, fieldName } = entry;

    const fieldPath = this.api.interfaces.InputPaths.removeLast(path);
    const itemUid = this.api.interfaces.InputPaths.getLast(path);

    return ([node, nodePath], index, options) => {
      if (
        (node.type !== 'clause' && node.type !== 'table') ||
        !node.data ||
        !node.data.qa ||
        node.data.qa.fieldPath !== fieldPath
      )
        return;
      const { type } = node;

      const newItem = this.makeParagraph(
        [
          { text: '' },
          this.makeInlineNode('field', 'variant', this.emptyContractString(), {
            qa: { fieldPath: fieldPath + '.' + itemUid + '.q' },
          }),
          { text: ' ' },
          this.makeInlineNode('field', 'variant', this.emptyContractString(), {
            qa: { fieldPath: fieldPath + '.' + itemUid + '.a' },
          }),
        ],
        { qa: { fieldPath: fieldPath + '.' + itemUid } }
      );

      node.children.push(newItem);
    }; */
  },
};

export const repeatableQandA_removeSlate = {
  id: 'repeatableQandA_removeSlate',
  dependencies: {
    repeatableQuestionAnswerRemove: true,
  },
  match: ({ entries, node }) => {},
  handler: function ({ matches }) {
    /* return ([node, nodePath], parents, index, options) => {
      if (!node.data || !node.data.qa || node.data.qa.fieldPath !== path) return;

      const { node: closestParentNode } = parents.slice(-1)[0];
      closestParentNode[0].children.splice(index, 1);
    }; */
  },
};

export const repeatableQandA_changeSlate = {
  id: 'repeatableQandA_changeSlate',
  dependencies: {
    repeatableQuestionAnswerChange: true,
  },
  match: ({ entries, node }) => {},
  handler: function ({ matches }) {
    /* return ([node, nodePath], index, options) => {
      if (node.type !== 'field' || !node.data || !node.data.qa || node.data.qa.fieldPath !== path) return;

      const value = entry.value || this.emptyContractString();

      api.utils.engine.setFirstChild(node, value);
      
    }; */
  },
};
