/* eslint-disable react/jsx-filename-extension */
import { useRemoteCursorOverlayPositions } from '@slate-yjs/react';
import { cx } from '@emotion/css';
import React, { useRef } from 'react';
import { Entity } from 'core/interfaces'

function Caret({ position, data }) {
  const caretStyle = {
    ...position,
    background: data.color,
    position: 'absolute',
    width: '0.125rem',
  };

  const labelStyle = {
    transform: 'translateY(-100%)',
    background: data.color,
    position: 'absolute',
    top: '0px',
    whiteSpace: 'nowrap',
    padding: '1px 3px',
    borderRadius: '2px',
    color: '#fff',
  };

  return (
    <div style={caretStyle} className="w-0.5 position-absolute">
      <div
        className="position-absolute text-xs text-white whitespace-nowrap top-0 rounded rounded-bl-none px-1.5 py-0.5"
        style={labelStyle}
      >
        {Entity.name(data)}
      </div>
    </div>
  );
}

function RemoteSelection({ data, selectionRects, caretPosition }) {
  if (!data) {
    return null;
  }
  const selectionStyle = {
    // Add a opacity to the background color
    backgroundColor: `${data.color}66`,
    position: 'absolute',
    pointerEvents: 'none',
  };
  
  return (
    <React.Fragment>
      {selectionRects.map((position, i) => (
        <div
          style={{ ...selectionStyle, ...position }}
          className="absolute pointer-events-none"
          // eslint-disable-next-line react/no-array-index-key
          key={i}
        />
      ))}
      {caretPosition && <Caret position={caretPosition} data={data} />}
    </React.Fragment>
  );
}

export function RemoteCursorOverlay({ className, children }) {
  const containerRef = useRef(null);
  const { cursors } = useRemoteCursorOverlayPositions({
    containerRef,
  });

  return (
    <div className={cx('position-relative', className)} ref={containerRef}>
      {children}
      {cursors.map((cursor) => (
        <RemoteSelection key={cursor.clientId} {...cursor} />
      ))}
    </div>
  );
}
