export const groupFieldModuleSlateX = {
  id: 'groupFieldModuleSlateX',
  dependencies: { entityAny: true, repeatableChange: true },
  match: ({ entry, node, api }) => {
    return api.utils.engine.matchModule(node, 'facilityAgreement', 'groupField');
  },
  handler: function ({ state, handlerInvoked, entries, paths, api, matches }) {
    const topCo = api.interfaces.Entity.findTopCo(state.entities);
    const name = api.utils.general.userToName(topCo);
    if (!topCo || !name) return;

    for (const { node, actions } of matches.default) {
      const args = (node.data && node.data.module && node.data.module.arguments) || {};
      const { useDefinition } = args;
      let value = name;
      if (useDefinition) {
        value = this.companyDefinition(name);
      } else {
        const mapped = this.mapCompanies(name);
        if (mapped && mapped.perfectMatch && mapped.fullText) value = mapped.fullText;
      }
      if (node.data.caps) value = api.utils.general.ucfirst(value);
      actions.replaceText(value, { setValue: true });
    }
  },
};