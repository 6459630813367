import { isElement } from 'core/types/elements';
import uuid from 'uuid-random';

export function generateItemIds(node) {
  if (!isElement(node)) return;
  if (!node.data) node.data = {};
  node.data.item_id = uuid();
  node.children.forEach((child) => generateItemIds(child));
}

/* import { Path } from 'slate'

export const nodePath = (root, node, path=[]) => {
    if(!root || !root.children || !node) return;
	const childLen = root.children.length;
	for(let i=0;i<childLen;i++) {
		const child = root.children[i];
        if(child === node) return [...path, i];
        const potential = child.children && nodePath(child, node, [...path, i]);
        if(potential) return potential;
	}
}
 */
