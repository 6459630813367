import { useSelector } from 'react-redux';
import { useContract, useIsTemplateStudio } from 'hooks';
import { Contract, InputPaths } from 'core/interfaces';
import { getByPath } from 'core/utils/general';
import applyLogic from 'core/utils/logic/applyLogic';

/**
 * useRulePassed:         Hook to check a specific acp (activeCondition) rule
 *                        against any state changes. Returns
 * @param {object} rule   JsonLogic rule.
 * @param {string} path   The calling item's state path, e.g. `input.facility.1`
 *                        so that we (if relevant) can use its "local state" for
 *                        the validation of the rule.
 * @returns {bool}        Whether the rule has passed (validated true) or not.
 */

export const useRulePassed = (rule, path, debug) => {
  const isTemplate = useIsTemplateStudio();
  const contract = useContract();
  const setup = Contract.getSetup(contract);
  const options = {
    _meta: {
      create: { setup },
      contract,
    },
  };

  const passed = useSelector((state) => {
    if (!rule) return true;

    let localStates = null;

    if (path) {
      const paths = InputPaths.extractPathParts(contract, path);
      if (debug) {
        console.log('paths ', { paths, path });
      }
      localStates = paths.reduce((acc, curr) => {
        acc = { ...acc, ...getByPath(state, curr) };
        return acc;
      }, {});
    }

    if (Array.isArray(rule)) {
      if (rule.length === 0) return true;
      for (const singleRule of rule) {
        if (!singleRule) continue;
        if (!applyLogic(singleRule, state, { ...options, data: { local: localStates } })) return false;
      }
      return true;
    }
    return applyLogic(rule, state, { ...options, data: { local: localStates } });
  });

  return isTemplate ? true : passed;
};

export const useLocalStateUid = (path) => {
  return useSelector((state) => getByPath(state, path + '._uid'));
};
