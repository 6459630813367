const entities = [
  {
    id: '8888aaaa-6538-4eda-8767-4c334d1e32da',
    parentId: null,
    form: null,
    name: 'Owner',
    jurisdiction: 'se',
    identificationNumber: '',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  },
  {
    id: 'f89895d1-6fca-48bb-8d77-52a6ebf63f61',
    parentId: '8888aaaa-6538-4eda-8767-4c334d1e32da',
    form: 'AB',
    name: 'Bolag AB',
    jurisdiction: 'se',
    identificationNumber: '5566771122',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  },
];

export default entities

export const development = [
  {
    id: '55cfbcfd-6538-4eda-8767-4c334d1e32da',
    parentId: null,
    form: null,
    name: 'Owner',
    jurisdiction: 'se',
    identificationNumber: '',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  },
  {
    id: 'f89895d1-6fca-48bb-8d77-52a6ebf63f61',
    parentId: '55cfbcfd-6538-4eda-8767-4c334d1e32da',
    form: 'AB',
    name: 'Brackent AB',
    jurisdiction: 'se',
    identificationNumber: '556677-1122',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  },
  {
    id: 'f26edec0-e7c6-4e14-8a1e-8c31ea6251b1',
    parentId: 'f89895d1-6fca-48bb-8d77-52a6ebf63f61',
    form: 'AB',
    name: 'Fastighets AB Skrået',
    jurisdiction: 'se',
    identificationNumber: '556677-1122',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  },
  {
    id: '58e12743-0bd6-45a9-8468-e4587760357b',
    parentId: 'f89895d1-6fca-48bb-8d77-52a6ebf63f61',
    form: 'AB',
    name: 'Stugan AB',
    jurisdiction: 'se',
    identificationNumber: '556677-1122',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  },
  {
    id: '41e26296-5918-413f-9996-8684e609bb23',
    parentId: 'f89895d1-6fca-48bb-8d77-52a6ebf63f61',
    form: 'AB',
    name: 'Boxen i Stockholm AB',
    jurisdiction: 'se',
    identificationNumber: '556677-1122',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  },
  {
    id: 'd2bf15e7-e98b-4242-9eac-fd9e735d16d5',
    parentId: 'f89895d1-6fca-48bb-8d77-52a6ebf63f61',
    form: 'AB',
    name: 'Stallet AB',
    jurisdiction: 'se',
    identificationNumber: '556677-1122',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  },
  {
    id: 'da20bf8a-d65b-4ed0-8493-35638e5d2588',
    parentId: 'f89895d1-6fca-48bb-8d77-52a6ebf63f61',
    form: 'AB',
    name: 'Katten AB',
    jurisdiction: 'se',
    identificationNumber: '556677-1122',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  },
  {
    id: '56948dd1-d9a5-48d2-b90d-fabfd1ea3031',
    parentId: '58e12743-0bd6-45a9-8468-e4587760357b',
    // secondParentId,
    form: 'KB',
    name: 'Humlan Förvaltning KB',
    jurisdiction: 'se',
    identificationNumber: '556677-1122',
    underTransfer: null,
    street: '',
    city: '',
    zipcode: '',
    emails: [],
    data: {},
  },
];
