import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useIsStudioCommentor, useContract } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { addConversation } from 'appRedux/actions/Conversations';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { useYjsState } from 'hooks';
import api from 'utils/api';

function InviteApproval() {
  const { connected } = useYjsState();
  const formatMessage = useIntlMessage();
  const contract = useContract();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth?.user);
  const { id, documentId, projectId } = contract;

  const onApprove = async () => {
    try {
      const response = await api.post('/versionfeedbacks', {
        versionId: id,
        documentId,
        projectId,
        status: 'approved',
      });
      const { data } = response;
      if (!data) return console.log('Invalid versionFeedback response.');
      if (!connected) return console.log('Offline. Not posting on websocket.');

      dispatch(addConversation({ type: 'FB', completed: true, status: data.status, eId: user.entityId }, data.id));
    } catch (err) {
      console.log('er ', err.response);
    }
    //
  };

  const onClickApprove = () => {
    Modal.confirm({
      title: formatMessage('app.external.initResponse.approveHeader', 'cap'),
      content: (
        <div>
          <div>{formatMessage('app.external.initResponse.approveDescription')}</div>
        </div>
      ),
      type: 'success',
      icon: <CheckCircleFilled className="text-success" />,
      onOk: onApprove,
    });
  };

  return (
    <Button onClick={onClickApprove} size="large" shape="round" type="success">
      <IntlMessages id="app.approval.approve" cap />
    </Button>
  );
}

export default function InviteApprovalButton() {
  const isCommentor = useIsStudioCommentor();
  if (!isCommentor) return null;

  return <InviteApproval />;
}
