export const namedPartyModuleSlate = {
  id: 'namedPartyModuleSlate',
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true },
  time: -1,
  match: true,
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    let name;
    const agreementIdName = api.interfaces.Contract.getIdName(this.contract);

    const obligorsAgentRealName = this.getVariable('obligorsAgentRealName');
    if (obligorsAgentRealName) {
      name = obligorsAgentRealName;
    } else {
      if (!this.getContractCreate().build || !this.getContractCreate().build.concepts) return;

      const concepts = this.getContractCreate().build.concepts;

      // Try to see if the topCo is a party.
      const topCo = api.interfaces.Entity.findTopCo(state.entities);
      const topCoName = api.utils.general.userToName(topCo);

      let topCoIsParty = false;
      if (topCo) {
        if (api.utils.entities.isParty(topCo, this.contract, state)) {
          topCoIsParty = true;
        }
        // Below temporary hotfix
        else if (
          agreementIdName === 'multi_pledge_shb' &&
          state.input.guarantor &&
          api.utils.general.ofindKey(
            state.input.guarantor,
            (x) => x.guarantorEntity && x.guarantorEntity.id === topCo.id
          )
        ) {
          topCoIsParty = true;
        }
      }

      if (topCoIsParty) {
        name = topCoName;
      } else {
        const borrowerConcept = concepts.find((concept) => concept.id === 'borrower');

        if (!borrowerConcept) {
          name = this.emptyContractString();
        } else {
          const borrowers = api.interfaces.Concept.getConceptState(borrowerConcept, this.contract, state);
          const numOfBorrowers = api.utils.general.ocount(borrowers);
          if (!borrowers || numOfBorrowers === 0) {
            name = this.emptyContractString();
          } else if (numOfBorrowers === 1) {
            const stateKey = borrowerConcept.stateKey || 'borrowerEntity';
            const uid = Object.keys(borrowers)[0];

            const stateKeyData = borrowers[uid][stateKey];

            if (!stateKeyData) name = this.emptyContractString();
            else if (typeof stateKeyData === 'string') name = stateKeyData;
            else if (typeof stateKeyData === 'object') {
              const entity = api.interfaces.Entity.getFromStateById(
                state,
                stateKeyData.id,
                stateKeyData.type
              );
              if (!entity) {
                this.log('Cannot locate party entity', { uid });
                name = this.emptyContractString();
              } else {
                name = api.utils.general.userToName(entity);
              }
            }
          } else if (numOfBorrowers < 4) {
            const stateKey = borrowerConcept.stateKey || 'borrowerEntity';
            name =
              '[' +
              api.utils.general
                .omap(borrowers, (borrower) => {
                  const stateKeyData = borrower[stateKey];
                  let borrowerName;
                  if (!stateKeyData) borrowerName = this.emptyContractString();
                  else if (typeof stateKeyData === 'string') borrowerName = stateKeyData;
                  else if (typeof stateKeyData === 'object') {
                    const entity = api.interfaces.Entity.getFromStateById(
                      state,
                      stateKeyData.id,
                      stateKeyData.type
                    );
                    if (!entity) {
                      this.log('Cannot locate party entity', { borrower, stateKeyData });
                      borrowerName = this.emptyContractString();
                    } else {
                      borrowerName = api.utils.general.userToName(entity);
                    }
                  }
                  return borrowerName;
                })
                .join(' / ') +
              ']';
          } else {
            name = this.describeConcept('relevant_borrower').text;
          }
        }
      }
    }
    const draftInfo = this.getDraftInfo();
    draftInfo.data.namedParty = name;
    this.setVariable('namedParty', name);

    const mapped = this.mapCompanies(name);
    if (mapped && mapped.fullText) {
      this.setVariable('namedPartyDescription', mapped.fullText);
    } else {
      this.setVariable('namedPartyDescription', name);
    }

    const definition = this.companyDefinition(name);
    if (definition) {
      if (definition === name && mapped.fullText) {
        this.setVariable('namedPartyDefinition', mapped.fullText);
      } else {
        this.setVariable('namedPartyDefinition', definition);
      }
    } else {
      this.setVariable('namedPartyDefinition', name);
    }
  },
};
