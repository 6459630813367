import React from 'react';
import { Button } from 'antd';
import { useSetStudioPage } from 'hooks';

function GotoProvision(props) {
  const { field, language } = props;
  const setStudioPage = useSetStudioPage();

  return (
    <Button
      type="primary"
      onClick={() => {
        if (!field?.target?.endpoint) return;
        const { endpoint } = field.target;

        const newStudioPage = endpoint.split('/').join(',');
        console.log('goto ', newStudioPage);
        setStudioPage(newStudioPage);
      }}
    >
      {(field.label && field.label[language]) || 'Go to input section'}
    </Button>
  );
}

export default GotoProvision;
