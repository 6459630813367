import { createReducer } from '@reduxjs/toolkit';
import { deepPatchJson } from '@sanalabs/json';

import {
  REMOTE_APPLY_STATE,
  SET_CONVERSATIONS,
  ADD_CONVERSATION,
  DELETE_CONVERSATION,
  ADD_CONVERSATION_POST,
  DELETE_CONVERSATION_POST,
  UPDATE_CONVERSATION_POST,
  SET_CONVERSATION_COMPLETED,
} from 'constants/ActionTypes';

const INIT_STATE = {};

export default createReducer(INIT_STATE, {
  [REMOTE_APPLY_STATE]: (state, action) => {
    const oldState = state;
    const newState = action.payload.conversations;
    deepPatchJson(oldState, newState);
    return oldState;
  },
  [SET_CONVERSATIONS]: (state, action) => {
    state = action.payload;
    return state;
  },
  [ADD_CONVERSATION]: (state, action) => {
    const { conversationId, data } = action.payload;
    state[conversationId] = data;
    return state;
  },
  [DELETE_CONVERSATION]: (state, action) => {
    const { conversationId } = action.payload;
    delete state[conversationId];
    return state;
  },
  [ADD_CONVERSATION_POST]: (state, action) => {
    const { conversationId, post } = action.payload;
    try {
      if (!state[conversationId].posts) state[conversationId].posts = [];
      state[conversationId].posts.push(post);
    } catch (err) {
      console.warn('Cannot add conversation post ', action, err);
    }
  },
  [DELETE_CONVERSATION_POST]: (state, action) => {
    const { conversationId, postId } = action.payload;
    state[conversationId].posts = state[conversationId].posts.filter((p) => p.id !== postId);
  },
  [UPDATE_CONVERSATION_POST]: (state, action) => {
    const { conversationId, postId, post } = action.payload;
    state[conversationId].posts = state[conversationId].posts.map((p) => (p.id !== postId ? p : post));
  },
  [SET_CONVERSATION_COMPLETED]: (state, action) => {
    const { conversationId, value } = action.payload;
    state[conversationId].completed = value;
  },
});
