import { Tag } from 'antd';
import { Entity } from 'core/interfaces';

export function EntityEmails({ entity, color, fallback = '' }) {
  if (!entity) return '';
  const contactEmails = Entity.emails(entity);
  if (!contactEmails || contactEmails.length === 0) return fallback;
  return contactEmails.map((email) => (
    <Tag key={email} color={color}>
      {email}
    </Tag>
  ));
}
