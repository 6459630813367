import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  SET_NUMBER,
  SET_NUMBERS,
} from 'constants/ActionTypes';

const INIT_STATE = {
  error: '',
  loading: false,
  message: '',
  numbers: {},
};

export default function commonReducer(state = INIT_STATE, action) {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, error: '', message: '', loading: true };
    }
    case FETCH_SUCCESS: {
      return { ...state, error: '', message: '', loading: false };
    }
    case SHOW_MESSAGE: {
      return { ...state, error: '', message: action.payload, loading: false };
    }
    case FETCH_ERROR: {
      return { ...state, loading: false, error: action.payload, message: '' };
    }
    case HIDE_MESSAGE: {
      return { ...state, loading: false, error: '', message: '' };
    }
    case SET_NUMBER: {
      const { id, value } = action.payload;
      return { ...state, numbers: { ...state.numbers, [id]: value } };
    }
    case SET_NUMBERS: {
      return { ...state, numbers: { ...state.numbers, ...action.payload } };
    }
    default:
      return state;
  }
}
