import { useState } from 'react';
import ErrorBoundary from 'containers/Error/index.js';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export default function RenderPDF({ url }) {
  const [numberOfPages, setNumberOfPages] = useState(0);

  const pdfLoaded = (info) => {
    if (!info || !info._pdfInfo || !info._pdfInfo.numPages) return;
    setNumberOfPages(info._pdfInfo.numPages);
  };

  let pages = [];
  if (numberOfPages) {
    for (let i = 0; i < numberOfPages; i++) {
      pages.push(<Page key={i} pageNumber={i + 1} />);
    }
  }

  return (
    <ErrorBoundary>
      <div className="pdf-display">
        <Document file={url} onLoadSuccess={pdfLoaded} onLoadError={console.error}>
          {pages}
        </Document>
      </div>
    </ErrorBoundary>
  );
}
