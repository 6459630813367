import React from 'react';
import { Tabs, Menu, Dropdown, Badge, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { getPageItemName, getStudioPageByPage, pageHasChildren } from '../../utils/pageMenu';
import { useContract, useIsTemplateStudio, useSetStudioPage, setStudioEditorInputContent } from 'hooks';
import { Contract } from 'core/interfaces';
import { MenuHeader } from 'components/ui';
import TemplatePageActions from '../../components/StudioInput/renderDraftUI/PageActions';
import { TemplateStudioSectionMenu } from '../../components/TemplateStudio/';

const { TabPane } = Tabs;

function getActivePagesIds(page, activePagesOrder) {
  if (!page.pages_order || !page.pages) return [];
  return page.pages_order.filter((id) => activePagesOrder[page.pages[id].paths]);
}

const tabItem = ({
  id,
  page,
  contract,
  activePagesOrder,
  activePagesOrderIndex,
  activePagePaths,
  isActive,
  isTemplate,
  studioPage,
  setStudioPage,
  studio_draftMode,
  ...rest
}) => {
  if (!page) return null;
  const title = getPageItemName(contract, page);

  const linkPath = getStudioPageByPage(page);

  const hasChildren = pageHasChildren(page);
  if (!isTemplate && !hasChildren) {
    return <TabPane tab={title} key={linkPath}></TabPane>;
  }

  const setCurrentPage = (page) => {
    if (studio_draftMode === 'inline') {
      setStudioEditorInputContent({ page });
    } else {
      const linkPathX = getStudioPageByPage(page);
      setTimeout(() => {
        setStudioPage(linkPathX);
      }, 10);
    }
  };

  const activeChildrenIds = hasChildren ? getActivePagesIds(page, activePagePaths) : [];
  let totalActiveChildren = activeChildrenIds.length,
    totalChildren = hasChildren ? page.pages_order.length : 0;
  let currentChild = 'x';

  const childrenMenu = (
    <Menu>
      <Menu.Item disabled={!isTemplate} key={'x' + linkPath} onClick={() => setCurrentPage(page)}>
        {isTemplate ? <span className="link text-muted">{title}</span> : title}
      </Menu.Item>
      {totalActiveChildren ? <Menu.Divider /> : null}
      {isTemplate && totalActiveChildren ? <MenuHeader title="Sub sections" /> : null}
      {activeChildrenIds.map((childId, index) => {
        const childPage = page.pages[childId];
        const childLinkPath = getStudioPageByPage(childPage);
        if (studioPage === childLinkPath) currentChild = index + 1;

        if (pageHasChildren(childPage)) {
          const activeGrandChildren = getActivePagesIds(childPage, activePagesOrder);
          totalActiveChildren += activeGrandChildren.length;
          totalChildren += childPage.pages_order.length;
          return (
            <Menu.SubMenu
              key={childLinkPath}
              title={getPageItemName(contract, childPage)}
              onTitleClick={() => {
                setCurrentPage(childPage);
              }}
            >
              {activeGrandChildren.map((grandchildId, grandchildIndex) => {
                const grandchildPage = childPage.pages[grandchildId];
                const grandChildLinkPath = getStudioPageByPage(grandchildPage);
                if (studioPage === grandChildLinkPath) currentChild = index + grandchildIndex + 1;
                return (
                  <Menu.Item key={grandChildLinkPath} onClick={() => setCurrentPage(grandchildPage)}>
                    <span className="link text-muted">{getPageItemName(contract, grandchildPage)}</span>
                  </Menu.Item>
                );
              })}
            </Menu.SubMenu>
          );
        }
        return (
          <Menu.Item key={childLinkPath} onClick={() => setCurrentPage(childPage)}>
            <span className="link text-muted">{getPageItemName(contract, childPage)}</span>
          </Menu.Item>
        );
      })}
      {isTemplate ? <Menu.Divider /> : null}
      {isTemplate && <MenuHeader title="Actions" key="actions-header" />}
      {isTemplate && <TemplatePageActions key={'page-actions' + page.id} page={page} menuType="items" />}
    </Menu>
  );

  const hasInActiveChildren = totalChildren > totalActiveChildren;

  return (
    <TabPane
      className=""
      active={isActive}
      tab={
        <Dropdown overlay={childrenMenu} className="position-relative">
          <Space>
            {title}
            {isActive && (
              <Badge
                className={'children-indicator-holder ' + (isActive ? 'active' : '')}
                count={currentChild + ' / ' + totalActiveChildren + (hasInActiveChildren ? '*' : '')}
                style={{
                  backgroundColor: '#e2e2e2',
                  position: 'absolute',
                  top: '-25px',
                  right: '-25px',
                  color: '#aaa',
                }}
              />
            )}

            <DownOutlined className="fs-xs" />
          </Space>
        </Dropdown>
      }
      key={linkPath}
      onClick={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
      }}
    ></TabPane>
  );
};

function InputTopbar({ activeFlatUiPages, activePagePaths }) {
  const isTemplate = useIsTemplateStudio();
  const contract = useContract();
  const pages = Contract.getUiPages(contract);
  const { pages_order } = Contract.getUi(contract);
  const studioPage = useSelector(({ draft }) => draft.studioPage);
  const studio_draftMode = useSelector(({ draft }) => draft.studio_draftMode);
  const setStudioPage = useSetStudioPage();

  if (!studioPage) {
    console.log('No studioPage');
    return null;
  }

  const activePagesOrder = pages_order.filter((id) => activePagePaths[pages[id]?.paths]);
  const activePagesOrderIndex = activePagesOrder.findIndex((id) => {
    const linkPath = getStudioPageByPage(pages[id]);
    return !studioPage ? 0 : studioPage.startsWith(linkPath);
  });

  const onChangeTab = (val) => {
    setStudioPage(val);
  };

  return (
    <div>
      { isTemplate && <TemplateStudioSectionMenu />}
      <Tabs
        activeKey={studioPage.split(',')[0]}
        onChange={onChangeTab}
        tabBarStyle={{margin: 0, paddingLeft: 24}}
        className="input-pages-menu"
      >
        {activePagesOrder.map((id, index) => {
          return tabItem({
            page: pages[id],
            contract,
            activePagesOrder,
            activePagePaths,
            isActive: activePagesOrderIndex === index,
            isTemplate,
            studioPage,
            setStudioPage,
            studio_draftMode,
          });
        })}
      </Tabs>
    </div>
  );
}

export default InputTopbar;
