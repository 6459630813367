import React, { useState, useEffect, useReducer, useRef } from 'react';
import { Form, Input, InputNumber, Select, Checkbox, Divider, Slider } from 'antd';
import { Contract } from 'core/interfaces';
import { useRerender } from 'hooks';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { ConditionActions, PrintCondition } from 'components/Rules/NodeConditions';
import { ShowHide } from 'components/ui';
// import IntlMessages from 'util/IntlMessages';
import { InsertImageButton, ShowSelectedImage } from 'components/editor/legal/toolbar/TopToolbar';

const { Option } = Select;

function reducer(state, action) {
  const { type, payload } = action;
  if (type === 'type')
    return { ...state, type: payload };
  if (type === 'variant')
    return { ...state, variant: payload };
  if (type === '_all_')
    return { ...payload };
  if (type === '_merge_')
    return { ...state, data: { ...state.data, ...payload } };
  return { ...state, data: { ...state.data, [type]: payload } };
}

export default function EditContractBlock({ data }) {
  const { node } = data;
  const { children, ...nodeProps } = node;

  const [state, dispatch] = useReducer(reducer, nodeProps);

  const updateAll = (values) => {
    dispatch({ type: '_all_', payload: values });
  };

  const onValuesChange = (values) => {
    const [key, value] = Object.entries(values)[0];
    dispatch({ type: key, payload: value });
  };

  useEffect(() => {
    data.tmpData.current = state;
  }, [data.tmpData, state]);

  // console.log('State is ', state);

  return (
    <div>
      <Divider orientation="left" className="mb-2">
        General
      </Divider>
      <EditManually data={state} updateAll={updateAll} />
      <Divider orientation="left" className="mb-2">
        Item Functions
      </Divider>
      <ACP data={data} state={state.data} dispatch={dispatch} />
      {nodeProps.type === 'clause' && <ClauseItems data={data} state={state.data} dispatch={dispatch} />}
      {/* {nodeProps.type === 'img' && <ImageItems data={data} state={state.data} dispatch={dispatch} />} */}
    </div>
  );
}

function ACP({ data, state, dispatch, ...rest }) {
  const { node, contract } = data;
  const [hasAcp, setHasAcp] = useState(!!node?.data?.acp);
  const stringPath = JSON.stringify(data.path);
  const currentRule = Contract.getRule(contract, state.acp);
  const rerender = useRerender();

  const onChangeRule = (id) => {
    console.log('new id ', id);
    dispatch({ type: 'acp', payload: id });
    rerender();
  };

  return (
    <>
      <Checkbox
        className="boxed-checkbox"
        checked={hasAcp}
        onChange={() => {
          dispatch({ type: 'acp', payload: null });
          setHasAcp(!hasAcp);
        }}
        {...rest}
      >
        Is conditioned
      </Checkbox>
      {hasAcp && (
        <div className="boxed-checkbox-border border-top-0 ml-2 mr-2 p-3">
          <ShowHide open>
            <div className={'mt-2 p-1'}>
              <strong>Rule Label</strong>
              <span className="d-block mt-1">{currentRule ? currentRule.label : <em>No rule</em>}</span>
            </div>
            <div className="mt-2 p-1">
              <strong>Rule Content</strong>
              <span className="d-block mt-1">
                <PrintCondition node={data.node} acp={state.acp} contract={data.contract} />
              </span>
            </div>
            <div className="mt-2 p-1">
              <strong>Actions</strong>
              <span className="d-block mt-1">
                <ConditionActions
                  node={data.node}
                  acp={state.acp}
                  stringPath={stringPath}
                  onChange={onChangeRule}
                  preventUpdate
                  contract={data.contract}
                />
              </span>
            </div>
          </ShowHide>
        </div>
      )}
      <div className="mb-3"></div>
    </>
  );
}

function ClauseItems({ data, state, dispatch, ...rest }) {
  const { contract } = data;
  const language = Contract.getLanguage(contract);
  const cardsWithState = Contract.getCardsWithState(contract).filter((card) => card.isRepeatable);

  return (
    <>
      <Divider orientation="left" className="mb-2 mt-3">
        Clause Functions
      </Divider>

      <div className="mt-3"></div>
      <Checkbox
        className="boxed-checkbox"
        checked={state.provision}
        onChange={() => {
          if (state.provision) dispatch({ type: 'provision', payload: null });
          else dispatch({ type: 'provision', payload: { x: 1 } });
        }}
        {...rest}
      >
        Contains contract provision
      </Checkbox>
      {state.provision && (
        <div className="boxed-checkbox-border border-top-0 ml-2 mr-2 p-3">
          <ShowHide open>
            <div className="">Provision...</div>
          </ShowHide>
        </div>
      )}
      <div className="mt-3"></div>
      <Checkbox
        className="boxed-checkbox"
        checked={state.each_repeatable}
        onChange={() => {
          if (state.each_repeatable) dispatch({ type: 'each_repeatable', payload: null });
          else dispatch({ type: 'each_repeatable', payload: { repeatable: null } });
        }}
        {...rest}
      >
        Has repeatable content
      </Checkbox>
      {state.each_repeatable && (
        <div className="boxed-checkbox-border border-top-0 ml-2 mr-2 p-3">
          <ShowHide open>
            <Form.Item label={'Repeat content with values from each instance of'} {...rest}>
              <Select
                onChange={(val) => {
                  dispatch({ type: 'each_repeatable', payload: { repeatable: val } });
                }}
                value={(state.each_repeatable && state.each_repeatable.repeatable) || null}
              >
                {cardsWithState.map((card) => {
                  return (
                    <Option key={card.id} value={card.id}>
                      <div className="option-multi-line">
                        <div>{card.header[language]}</div>
                        {Array.isArray(card._pages) && (
                          <div>
                            <small>
                              (in{' '}
                              {card._pages.map((page, index) => {
                                if (!page.header) console.log('no page header ', page);
                                return (
                                  <span key={page.id}>
                                    {page.header[language]}{' '}
                                    {index + 1 !== card._pages.length && (
                                      <i className="ml-1 mr-1 mdi mdi-arrow-right" />
                                    )}
                                  </span>
                                );
                              })}
                              )
                            </small>
                          </div>
                        )}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </ShowHide>
        </div>
      )}
      <div className="mt-3"></div>
      <Checkbox
        className="boxed-checkbox"
        checked={state.fill}
        onChange={() => {
          if (state.fill) dispatch({ type: 'fill', payload: null });
          else dispatch({ type: 'fill', payload: { type: null } });
        }}
        {...rest}
      >
        Has automatically filled content
      </Checkbox>
      {state.fill && (
        <div className="boxed-checkbox-border border-top-0 ml-2 mr-2 p-3">
          <ShowHide open>
            <div className="">asdf</div>
          </ShowHide>
        </div>
      )}
      <div className="mt-3"></div>
      <Checkbox
        className="boxed-checkbox"
        checked={state.module}
        onChange={() => {
          if (state.module) dispatch({ type: 'module', payload: null });
          else dispatch({ type: 'module', payload: { id: '', method: '', fieldType: '' } });
        }}
        {...rest}
      >
        Has module generated content
      </Checkbox>
      {state.module && (
        <div className="boxed-checkbox-border border-top-0 ml-2 mr-2 p-3">
          <ShowHide open>
            <div className="">asdf</div>
          </ShowHide>
        </div>
      )}
      <div className="mt-3"></div>
      <Checkbox
        className="boxed-checkbox"
        checked={state.qa}
        onChange={() => {
          if (state.qa) dispatch({ type: 'qa', payload: null });
          else dispatch({ type: 'qa', payload: { fieldPath: '' } });
        }}
        {...rest}
      >
        Has entries of Questions &amp; Answers
      </Checkbox>
      {state.qa && (
        <div className="boxed-checkbox-border border-top-0 ml-2 mr-2 p-3">
          <ShowHide open>
            <div className="">Q A s</div>
          </ShowHide>
        </div>
      )}
    </>
  );
}

function ImageItems({ data, state, dispatch, ...rest }) {
  const { node } = data;
  const width = state.width || node.data.width || 100;
  const height = state.height || node.data.height || 100;
  const commonSize = width + height;
  const initSize = commonSize / 10;

  const initialSize = useRef({ width, height, initSize });
  const [slider, setSlider] = useState(initSize);
  const [keepRatio, setKeepRatio] = useState(true);

  const onSelectImage = (selected) => {
    console.log('Image was selected.. ', { state, selected });
    dispatch({
      type: '_merge_',
      payload: selected,
    });
    if (selected.data.width) {
      dispatch({ type: 'width', payload: selected.data.width });
    }
    if (selected.data.height) {
      dispatch({ type: 'height', payload: selected.data.height });
    }
  };

  const onDimensionChange = (val) => {
    if (val > 100) return console.log('val exc 100');
    if (val < 0) return console.log('val below 0');
    const { width, height, initSize } = initialSize.current;
    const factor = val / initSize;
    const newWidth = Math.round(width * factor * 10) / 10;
    const newHeight = Math.round(height * factor * 10) / 10;
    setSlider(val);
    dispatch({ type: 'width', payload: newWidth });
    dispatch({ type: 'height', payload: newHeight });
  };

  const onWidthChange = (val) => {
    const commonSize = val + height;
    const initSize = commonSize / 10;
    setSlider(initSize);
    dispatch({ type: 'width', payload: val });
    if (keepRatio) {
      const factor = val / width;
      const newHeight = Math.round(height * factor * 10) / 10;
      dispatch({ type: 'height', payload: newHeight });
    }
  };
  const onHeightChange = (val) => {
    const commonSize = width + val;
    const initSize = commonSize / 10;
    setSlider(initSize);
    dispatch({ type: 'height', payload: val });
    if (keepRatio) {
      const factor = val / height;
      const newWidth = Math.round(width * factor * 10) / 10;
      dispatch({ type: 'width', payload: newWidth });
    }
  };

  return (
    <>
      <Divider orientation="left" className="mb-2">
        Image
      </Divider>
      <div className="mx-3">
        <small>
          <strong>Size</strong>
        </small>
        <div className="p-3"></div>
        <div>
          <Input.Group compact {...rest}>
            <Form.Item label={'Width'} {...rest}>
              <InputNumber step={5} min={10} max={1000} value={width} onChange={onWidthChange} />
            </Form.Item>
            <Form.Item label={'Height'} {...rest}>
              <InputNumber step={5} min={10} max={1000} value={height} onChange={onHeightChange} />
            </Form.Item>
            <Form.Item label={'Keep ratio'} {...rest}>
              <Checkbox checked={keepRatio} onChange={() => setKeepRatio(!keepRatio)} />
            </Form.Item>
          </Input.Group>
          <Slider
            style={{
              marginLeft: '0px',
            }}
            included={false}
            value={slider}
            tipFormatter={() => 'Slide to adjust size of image'}
            onChange={onDimensionChange}
            {...rest}
          />
        </div>
        <ShowSelectedImage element={{ data: { imgData: state.imgData } }} width={width} height={height} />
        <div>
          <InsertImageButton
            isChange
            onSelectImage={onSelectImage}
            currentImageId={state.image_id}
            className="mt-2"
          />
        </div>
      </div>
    </>
  );
}

function EditManually({ data, updateAll }) {
  const onEditChange = (change) => {
    if (change.error) return console.log('Error is ', change.error);
    updateAll(change.jsObject);
  };

  return (
    <div>
      <JSONInput
        id={'edit-node-data'}
        onChange={onEditChange}
        confirmGood={false}
        placeholder={data}
        theme="light_mitsuketa_tribute"
        locale={locale}
        /* error={{
                line:
                  (manualJsonError && manualJsonError.line) ||
                  (manualValidationError && manualValidationError.line),
              }} */
        colors={{
          error: '#ff0000',
        }}
        style={{
          outerBox: {
            width: 'calc(100% - 185px)',
            height: 'unset',
          },
          container: {
            width: '100%',
            height: 'unset',
            borderRadius: '6px',
          },
          body: {
            fontSize: '1em',
          },
          warningBox: {
            display: 'none',
          },
        }}
      />
    </div>
  );
}
