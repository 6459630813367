import { opByEntity } from './trackChanges';
import { addConversation } from '../../appRedux/actions/Conversations';

export const trackedChangeToConversation = (editor, entityId, dispatch) => {
  const { postApply } = editor;
  function handleOp(op) {
    if (op.type === 'insert_node') {
      if (!opByEntity(op, '_insertedBy', entityId)) return;
      const { id } = op.node._insertedBy;
      if (!id) return;
      dispatch(addConversation({ type: 'TC', eId: entityId }, id));
    }
  }

  editor.postApply = (op) => {
    handleOp(op);
    if (postApply) postApply(op);
  };
};
