import React from 'react';
import { Alert } from 'antd';
import { useAlertContext, setAlertContext } from 'hooks';
import { AlertContext } from 'contexts/contexts';

function CustomAlertProvider() {
  return (
    <AlertContext.Provider initialValue={null}>
      <CustomAlert />
    </AlertContext.Provider>
  );
}

const CustomAlert = (/* message, type */) => {
  const data = useAlertContext();

  if (!data || Object.keys(data).length === 0) return null;

  const onClose = () => {
    setAlertContext(null);
    if (data.onClose) data.onClose();
  };

  return (
    <Alert
      message={data.message}
      description={data.description}
      type={data.type || 'info'}
      onClose={onClose}
      className={data.className || 'custom-modal'}
      closeText={data.closeText}
      closable={true}
      style={{ zIndex: 1000 }}
    />
  );
};

export default CustomAlertProvider;
