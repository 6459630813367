import { useContract, setContract, setEventState } from 'hooks';
import { Button, Tooltip } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { PlusSquareOutlined, EditOutlined } from '@ant-design/icons';

export default function CardAction({ cardName, page }) {
  const contract = useContract();

  const setTemplateEditCard = (type, method) => {
    setEventState('template_edit_input_sections', { type, method, selected: { card: cardName, page } });
  };

  if (!page) {
    console.log('No page? ', { cardName });
    return null;
  }
  if (!page.cards) {
    console.log('Page has no cards', { page });
    return null;
  }

  const inputsLength = page.cards.length;
  const cardIndex = page.cards.findIndex((x) => x === cardName);
  const isFirst = cardIndex === 0;
  const isLast = cardIndex + 1 === inputsLength;
  const updatePage = (prop, value) => {
    contract.data.ui.pages[page.id][prop] = value;
    setContract({
      ...contract,
    });
  };
  const moveUp = () => {
    if (isFirst) return;
    const newOrder = [...page.cards];
    const otherValue = newOrder[cardIndex - 1];
    newOrder[cardIndex - 1] = newOrder[cardIndex];
    newOrder[cardIndex] = otherValue;
    updatePage('cards', newOrder);
  };
  const moveDown = () => {
    if (isLast) return;
    const newOrder = [...page.cards];
    const otherValue = newOrder[cardIndex + 1];
    newOrder[cardIndex + 1] = newOrder[cardIndex];
    newOrder[cardIndex] = otherValue;
    updatePage('cards', newOrder);
  };

  return (
    <div className="template-card-toolbar">
      <Tooltip title={<IntlMessages id="studio.template.modal.input.add" />} placement="top">
        <Button
          shape="circle"
          icon={<PlusSquareOutlined />}
          onClick={() => setTemplateEditCard('input', 'add')}
        />
      </Tooltip>
      <Tooltip title={<IntlMessages id="studio.template.modal.card.edit" />} placement="top">
        <Button shape="circle" icon={<EditOutlined />} onClick={() => setTemplateEditCard('card', 'edit')} />
      </Tooltip>
      <div className="template-card-toolbar-arrange">
        {!isFirst ? (
          <Tooltip title={'Move up'} placement="left">
            <span className="link" onClick={moveUp}>
              <i className="mdi mdi-arrow-up" />
            </span>
          </Tooltip>
        ) : (
          <span style={{ height: '18px' }}>&nbsp;</span>
        )}
        {!isLast && (
          <Tooltip title={'Move down'} placement="left">
            <span className="link" onClick={moveDown}>
              <i className="mdi mdi-arrow-down" />
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
}
