import React from 'react';
import { Row, Col } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { useSelector } from 'react-redux';
import { PlusCircleOutlined } from '@ant-design/icons';
import { AddOrSelectEntity } from 'components/entity/EditEntity';

export default function SelectEntityModal({ data, onClose }) {
  const { onFinishCallback } = data;
  const entities = useSelector((state) => state.entities);

  return (
    <div>
      <Row className="flex-row m-0 mt-4">
        <Col sm={24} md={4} className="d-flex align-items-center justify-content-center mb-3">
          <PlusCircleOutlined style={{ fontSize: '48px' }} />
        </Col>
        <Col sm={24} md={20} className="d-flex flex-sm-column flex-md-row align-items-center">
          <h3 className="mb-3">
            <IntlMessages id="app.signing.started.header" />
          </h3>
        </Col>
      </Row>
      <Row className="flex-row m-0 mt-4">
        <AddOrSelectEntity
          onFinishCallback={onFinishCallback}
          onCancel={onClose}
          standardResults={entities}
          // availableParents={entities}
          // updateApi={false}
          redux={true}
          autoSelectName={true}
          defaultNewValues={{
            jurisdiction: 'se',
          }}
        />
      </Row>
    </div>
  );
}
