
export function renderFunction(state, props, orgchart) {

}

export function customNodeLayout(node) {
    return 'a'+node.id;
}


export const requiredArguments = {
    customNodeLayout: ['input', 'facility'],

}
