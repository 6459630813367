import React, { memo, useMemo } from 'react';
import { Button, Card, Table, Tooltip } from 'antd';
import IntlMessages from 'util/IntlMessages';
import moment from 'moment';
import { DarkContainerListSkeleton } from '../../Skeletons/Overview';
import { GotoDocumentViewSpan } from 'components/DocumentsList/index';
import { fixDate, AutoModal } from 'components/ui';
import { useEntityData } from '../../../hooks/api';
import { sortByCreatedAt } from 'core/utils/general';
import { Entity } from 'core/interfaces';
import { InitialAvatar } from 'components/ui/userAvatar';
import { useSelector } from 'react-redux';

const LIMIT = 5;

function isLoaded(mode, project, document) {
  if (mode === 'Project' && project && project.id) return true;
  if (mode === 'Document' && document && document.id) return true;
  return false;
}

const ProjectDocumentEvents = memo(({ mode = 'Project', project, documents, document }) => {
  if (!isLoaded(mode, project, document))
    return <DarkContainerListSkeleton title={<IntlMessages id="general.events" cap />} />;
  return <ListEvents mode={mode} project={project} documents={documents} document={document} limit={LIMIT} />;
});

const ListEvents = memo(({ mode, project, documents, document, limit }) => {
  const { locale } = useSelector((state) => state.settings.locale);
  const isProjectMode = mode === 'Project';
  const [events, totalNumber] = useMemo(() => {
    let allEvents = [];
    if (isProjectMode && project) allEvents = project.Events ?? [];
    if (documents)
      allEvents = allEvents.concat(
        documents
          .map((d) => d.Events?.map((e) => ({ ...e, docName: d.name })))
          .filter((evts) => !!evts)
          .flat()
      );
    if (document)
      allEvents = allEvents.concat(
        document.Events?.map((e) => ({ ...e, docName: document.name })).filter((evts) => !!evts) ?? []
      );
    return [allEvents.sort(sortByCreatedAt), allEvents.length];
  }, [isProjectMode, project, documents, document]);

  const entityData = useEntityData(events && events.map((ev) => ev?.entityId));

  if (!events) return <DarkContainerListSkeleton title={<IntlMessages id="general.events" cap />} />;

  const displayEvents = events.slice(0, limit);
  const additionalItems = limit && totalNumber > limit ? totalNumber - limit : 0;

  return (
    <Card className="dark-container widget" title={<IntlMessages id="general.events" cap />}>
      {events === null ? (
        <p>Loading events . . .</p>
      ) : (
        <Table
          rowKey="id"
          dataSource={displayEvents}
          pagination={false}
          locale={{
            emptyText: (
              <span>
                <IntlMessages id="app.events.noEvents" cap />
              </span>
            ),
          }}
        >
          <Table.Column
            title={<IntlMessages id="app.general.Description" />}
            dataIndex="eventKey"
            ellipsis
            render={(eventKey, event) => {
              const { resourceType, docName } = event;
              let content = null;
              if (resourceType === 'Project') {
                content = (
                  <>
                    <IntlMessages id={`app.events.types.${eventKey}`} cap />{' '}
                    <IntlMessages id="general.thisProject" />
                  </>
                );
              } else if (resourceType === 'Document') {
                if (isProjectMode) {
                  content = (
                    <>
                      <GotoDocumentViewSpan
                        document={{ id: event.resourceId, projectId: project.id, name: event.docName }}
                        className="link"
                      >
                        <IntlMessages id={`app.events.types.${eventKey}`} cap /> <span>{docName}</span>
                      </GotoDocumentViewSpan>
                    </>
                  );
                } else {
                  content = (
                    <>
                      <IntlMessages id={`app.events.types.${eventKey}`} cap />{' '}
                      <IntlMessages id="general.thisDocument" />
                    </>
                  );
                }
              } else {
                content = '[Unknown event target]';
              }
              return (
                <Tooltip
                  key={event.id}
                  title={moment(event.createdAt).locale(locale).fromNow()}
                  placement="top"
                  color="#fff"
                  overlayInnerStyle={{ color: '#000' }}
                >
                  <span>{content}</span>
                </Tooltip>
              );
            }}
          />
          <Table.Column
            title=""
            width={50}
            dataIndex="entityId"
            key="by"
            render={(entityId) => {
              return <InitialAvatar mode="dark" ghost entity={entityData[entityId]} />;
            }}
          />
        </Table>
      )}
      <div className="d-inline" style={{ paddingLeft: 12, paddingTop: 24 }}>
        {additionalItems ? (
          <span className="text-white mr-3"> . . . and {additionalItems} more events. </span>
        ) : null}
        <AutoModal
          trigger={
            <Button type="secondary" className="m-0">
              <IntlMessages id="general.viewAll" cap />
            </Button>
          }
          title={<IntlMessages id="app.project.menus.Events" cap />}
          className="modal-lg"
        >
          <ListAll mode={mode} project={project} events={events} entityData={entityData} />
        </AutoModal>
      </div>
    </Card>
  );
});

function ListAll({ mode, project, events, entityData }) {
  if (!events || events.length === 0) {
    return <IntlMessages id="app.events.noEvents" />;
  }
  return (
    <Table
      rowKey="id"
      dataSource={events}
      locale={{
        emptyText: (
          <span>
            <IntlMessages id="app.events.noEvents" cap />
          </span>
        ),
      }}
    >
      <Table.Column
        title={<IntlMessages id="app.general.time" />}
        className="w-25"
        dataIndex="createdAt"
        render={(date) => (date ? <small>{fixDate(date)}</small> : null)}
      />
      <Table.Column
        title={<IntlMessages id="general.event" cap />}
        dataIndex="eventKey"
        render={(eventKey) => <IntlMessages id={`app.events.types.${eventKey}`} />}
      />
      {mode === 'Project' && (
        <Table.Column
          title={<IntlMessages id="app.general.Link" cap />}
          key="id"
          ellipsis
          render={(event) => {
            if (event.resourceType === 'Document') {
              return (
                <GotoDocumentViewSpan
                  useName
                  document={{ id: event.resourceId, projectId: project.id, name: event.docName }}
                  className="link"
                />
              );
            }
            return event.docName || '-';
          }}
        />
      )}
      <Table.Column
        title={
          <>
            <IntlMessages id="desc.by" cap /> <IntlMessages id="general.user" lower />
          </>
        }
        dataIndex="entityId"
        render={(entityId) => Entity.name(entityData[entityId])}
      />
    </Table>
  );
}

export default ProjectDocumentEvents;
