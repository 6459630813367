export const conceptMappingOnAddRemoveChangeSlate = {
  id: 'conceptMappingOnAddRemoveChangeSlate',
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true },
  match: true,
  handler: function ({ state, handlerInvoked, entries, paths, api }) {
    let mappers = [];
    // A completely new or removed repeatable, recalculate everything
    if (handlerInvoked.repeatableAdd || handlerInvoked.repeatableRemove) {
      mappers = this.getContractCreate().build.mapRepeatablesByConcepts.filter(
        (mapper) => mapper.source.length
      );
    } else if (handlerInvoked.repeatableChange) {
      for (const { cardId, fieldName } of entries) {
        mappers = mappers.concat(
          this.getContractCreate().build.mapRepeatablesByConcepts.filter(
            (mapper) =>
              mapper.source.filter((source) => source.stateId === cardId && source.key === fieldName).length
          )
        );
      }
    }

    if (mappers.length === 0) return;

    const mappedValues = this.mapConceptValues(mappers, state);
    this._tmpDraft.mappedConcepts = mappedValues;
    for (const [mappedKey, mappedValue] of Object.entries(mappedValues)) {
      this.setVariable(mappedKey, mappedValue);
    }
  },
};

export const mapConceptValues = function (mappers, state) {
  const results = {};
  const { Contract, Entity } = this.api.interfaces;

  for (const mapper of mappers) {
    const values = mapper.source
      .filter((s) => state.input[s.stateId] && this.api.utils.general.ocount(state.input[s.stateId]) > 0)
      .map((s) => {
        // this.log('Source value ', this.api.utils.general.omap(state.input[s.stateId], (rep) => rep[s.key]), {s})
        return this.api.utils.general.omap(state.input[s.stateId], (rep) => {
          const itemValue = rep[s.key];
          if (!itemValue) return this.emptyContractString();

          const inputField = Contract.getUiInput(this.contract, s.key);
          if (inputField && itemValue.id) {
            const entity = Entity.getById(state.entities, itemValue.id);
            if (entity) return Entity.name(entity);
          }
          return itemValue;
        });
      })
      .filter(
        (item) =>
          item && (typeof item === 'string' || (typeof item === 'object' && item.id) || Array.isArray(item))
      )
      .map((item) => {
        if (!item.id) return item;
      })

      .flat();

    // this.log('mapConceptValues values ', {mapper, values})

    for (const action of mapper.actions) {
      if (action.type === 'setInputReplacement') {
        if (!action.label) continue;
        if (values.length === 0) {
          results[action.label] = this.emptyContractString();
          continue;
        }

        let result;
        let mappedString = this.emptyContractString();

        if (action.preventConceptLabeling) {
          result = values;
          if (typeof action.join === 'string') mappedString = result.join(action.join);
          else if (typeof action.conjunction === 'string') {
            let conjunction = action.conjunction;
            let translatedConjunction = this.translateText(conjunction);
            if (translatedConjunction.substr(0, 1) !== '[') conjunction = translatedConjunction;
            mappedString = this.api.utils.general.imp_dyn(result, conjunction);
          }
        } else {
          // console.log('map the following for conceptBinding ', values)
          const mappedValues = this.mapCompanies(values, state);
          if (!mappedValues || !mappedValues.result || mappedValues.result.length === 0) {
            results[action.label] = this.emptyContractString();
            continue;
          }
          result = mappedValues.result;
          if (typeof action.join === 'string') mappedString = result.join(action.join);
          else if (typeof action.conjunction === 'string') {
            if (mappedValues['fullText_' + action.conjunction])
              mappedString = mappedValues['fullText_' + action.conjunction];
            else {
              mappedString = this.api.utils.general.imp_dyn(result, action.conjunction);
            }
          }
        }

        if (action.textLeft) mappedString = action.textLeft + mappedString;
        if (action.textRight) mappedString = mappedString + action.textRight;

        results[action.label] = mappedString;
      }
    }
  }
  // this.log('Mapped values ', result)
  return results;
};
