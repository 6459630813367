import { useState, useEffect, useCallback, useRef, memo, useReducer } from 'react';
import {
  Row,
  Col,
  Spin,
  Table,
  Card,
  Avatar,
  Popconfirm,
  Button,
  Dropdown,
  Menu,
  Tooltip,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Checkbox,
  Divider,
  notification,
} from 'antd';
import uuid from 'uuid-random';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import WidgetHeader from 'components/WidgetHeader/index';
import { fixDate, ShowHide } from 'components/ui';
import api from 'utils/api';
import { UnorderedListOutlined, AppstoreOutlined, SearchOutlined } from '@ant-design/icons';
import Widget from 'components/Widget/index';
import hooksExamples from './hooksExamples';
import clientSettingsReducer from './clientSettingsReducer';

const { Column } = Table;

export default function ClientSettings() {
  const clientSettingId = useRef(null);
  const [state, dispatch] = useReducer(clientSettingsReducer, null);

  console.log('client settings state ', state)
  useEffect(() => {
    (async function () {
      try {
        const settings = await api.get('/clientsettings');
        if (settings.data && settings.data[0] && settings.data[0].data) {
          clientSettingId.current = settings.data[0].id;
          dispatch({ type: '_all_', payload: settings.data[0].data });
        }
      } catch (err) {
        console.log('Fetch client settings error, ', err);
      }
    })();
  }, []);

  async function save() {
    try {
      const update = await api.put('/clientsettings/' + clientSettingId.current, { data: state });
      console.log('update is ', update);
      notification.success({ message: 'Saved' });
    } catch (err) {
      notification.error({ message: 'Failed saving settings' });
    }
  }

  // useMountFetch('/clientsettings', setClientSettings);

  if (!state) return <div>Loading . . .</div>;

  return (
    <div className="padded-page">
      <WidgetHeader
        title={'Organisation Settings'}
        styleName="mb-3"
        extra={
          <>
            <Button type="primary" onClick={save}>
              Save
            </Button>
          </>
        }
      />
      <div className="mt-4 mb-4">
        <small>Here you may update and set various options for your organisation</small>
      </div>
      {/* <Form layout={'vertical'}>
        
      </Form> */}
      <Form layout="vertical">
        <div>
          <Divider orientation="left" className="mb-2">
            General Settings
          </Divider>
          <div className="p-3">
            <Widget title={'Features'}>
              <Checkbox
                className="boxed-checkbox"
                checked={state.core.features.externalPowerUser.enabled}
                onChange={() => {
                  dispatch({
                    type: 'SET_POWER_USER_ENABLED',
                    payload: !state.core.features.externalPowerUser.enabled,
                  });
                }}
              >
                External Power User
              </Checkbox>
              {state.core.features.externalPowerUser.enabled && (
                <div className="boxed-checkbox-border border-top-0 ml-2 mr-2 p-3">
                  <Form.Item label={'Whitelisted IP addresses'} className="">
                    <Select
                      mode="tags"
                      value={state.core.features.externalPowerUser.whitelistedIPs}
                      onChange={(values) => {
                        dispatch({
                          type: 'SET_POWER_USER_WHITELIST',
                          payload: values,
                        });
                      }}
                    ></Select>
                  </Form.Item>
                </div>
              )}
            </Widget>
            <Widget title={'Project Settings'}>
              <Checkbox
                className="boxed-checkbox"
                checked={state.core.projects.allProjectsAccessible}
                onChange={() => {
                  dispatch({
                    type: 'SET_ALL_PROJECTS_ACCESSIBLE',
                    payload: !state.core.projects.allProjectsAccessible,
                  });
                }}
              >
                All Projects are accessible to all users
              </Checkbox>
            </Widget>
            <Widget title={'Password Policy'}>
              <div className="mb-1">
                <InputNumber
                  value={state.core.passwordPolicy.passwordLength}
                  onChange={(val) => {
                    dispatch({
                      type: 'SET_PASSWORD_POLICY_ITEM',
                      key: 'passwordLength',
                      payload: val,
                    });
                  }}
                />
                <small>Characters minimum length</small>
              </div>
              <div>
                <Checkbox
                  checked={state.core.passwordPolicy.withNumber}
                  onChange={() => {
                    dispatch({
                      type: 'SET_PASSWORD_POLICY_ITEM',
                      key: 'withNumber',
                      payload: !state.core.passwordPolicy.withNumber,
                    });
                  }}
                >
                  Require a <em>number</em> in the password
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={state.core.passwordPolicy.withUppercase}
                  onChange={() => {
                    dispatch({
                      type: 'SET_PASSWORD_POLICY_ITEM',
                      key: 'withUppercase',
                      payload: !state.core.passwordPolicy.withUppercase,
                    });
                  }}
                >
                  Require an <em>uppercase character</em> in the password
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={state.core.passwordPolicy.withSpecialCharacter}
                  onChange={() => {
                    dispatch({
                      type: 'SET_PASSWORD_POLICY_ITEM',
                      key: 'withSpecialCharacter',
                      payload: !state.core.passwordPolicy.withSpecialCharacter,
                    });
                  }}
                >
                  Require a <em>special character</em> in the password
                </Checkbox>
              </div>
            </Widget>
          </div>
        </div>
        <div>
          <Divider orientation="left" className="mb-2 mt-4">
            Web Hooks &amp; API
          </Divider>
          <div className="p-3">
            <WebHook
              title={'System Core Event'}
              description={'Such as new user being created in Maintract'}
              hookId={'core'}
              hookState={state.webHooks.core}
              dispatch={dispatch}
            />
            <WebHook
              title={'User Tasks Event'}
              description={'Such as review and approval of contracts'}
              hookId={'task'}
              hookState={state.webHooks.task}
              dispatch={dispatch}
            />
            <WebHook
              title={'Other Events'}
              description={'System events and user actions'}
              hookId={'events'}
              hookState={state.webHooks.events}
              dispatch={dispatch}
            />
            <WebHook
              title={'Contract Clause Statuses Updates'}
              description={'Status of Contract Clauses, breaches etc.'}
              hookId={'contractClauseStatus'}
              hookState={state.webHooks.contractClauseStatus}
              dispatch={dispatch}
            />
            <WebHook
              title={'Contract Tasks Updates'}
              description={'Contract Tasks, such as upcoming payments'}
              hookId={'contractTask'}
              hookState={state.webHooks.contractTask}
              dispatch={dispatch}
            />

            <WebHook
              title={'Contract Task Schedules Updates'}
              description={'Reminders and Deadline of Contract Tasks'}
              hookId={'contractTaskSchedule'}
              hookState={state.webHooks.contractTaskSchedule}
              dispatch={dispatch}
            />
          </div>
        </div>
      </Form>
      {/* <div className="w-100 mt-4">
        <div>{clientSettingId.current}</div>
        <pre>{JSON.stringify(state, null, 2)}</pre>
      </div> */}
    </div>
  );
}

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

function WebHook({ title, description, hookId, hookState, dispatch }) {
  const [url, setUrl] = useState('');
  const [method, setMethod] = useState('POST');

  function addHook() {
    if (!url) return notification.error({ message: 'Please provide an URL for the new hook' });
    if (!isValidHttpUrl(url)) return notification.error({ message: 'Please provide valid URL' });
    if (!['GET', 'POST', 'DELETE'].includes(method)) {
      return notification.error({ message: 'Please select a valid method' });
    }
    dispatch({
      type: 'ADD_HOOK',
      key: hookId,
      payload: {
        id: uuid(),
        method,
        url,
      },
    });
    setUrl('');
    setMethod('POST');
  }
  function removeHook(id) {
    dispatch({
      type: 'REMOVE_HOOK',
      key: hookId,
      payload: id,
    });
  }

  return (
    <Widget title={title} extra={description && <small>{description}</small>}>
      {hookState.hooks.length ? (
        <div>
          <h6>Hooks</h6>
          <Table
            rowKey="id"
            pagination={false}
            className="table-responsive documents-table"
            dataSource={hookState.hooks}
          >
            <Column title={'Method'} dataIndex="method" key="method" />
            <Column title={'URL'} dataIndex="url" key="url" />
            <Column
              title={'Example request'}
              key="example-request"
              render={(text, record) => (
                <div style={{ fontSize: '10px' }}>
                  <pre>x</pre>
                </div>
              )}
            />
            <Column
              title={<IntlMessages id="app.general.Action" />}
              key="actions"
              render={(text, record) => (
                <>
                  <span className="link" onClick={() => removeHook(record.id)}>
                    Remove
                  </span>
                </>
              )}
            />
          </Table>
        </div>
      ) : (
        <p>
          There are no web hooks added in category <em>{hookId}</em>
        </p>
      )}
      <div>
        <ShowHide title={{ show: 'Show example request', hide: 'Hide example request' }}>
          <div style={{ fontSize: '10px' }}>
            <pre>
              {hooksExamples[hookId] ? (
                <pre>{JSON.stringify(hooksExamples[hookId], null, 2)}</pre>
              ) : (
                <div>No example available</div>
              )}
            </pre>
          </div>
        </ShowHide>
      </div>
      <Divider orientation="left" className="mb-2 mt-4">
        Add new hook
      </Divider>
      <Input.Group compact>
        <Form.Item label={'Method'}>
          <Select value={method} onChange={(val) => setMethod(val)}>
            <Select.Option value={'POST'}>POST</Select.Option>
            <Select.Option value={'GET'}>GET</Select.Option>
            <Select.Option value={'DELETE'}>DELETE</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label={'Hook URL'}>
          <Input value={url} onChange={(evt) => setUrl(evt.target.value)} style={{ minWidth: '300px' }} />
        </Form.Item>
        <Button type="primary" className="m-0 compact-btn" onClick={addHook}>
          Add hook
        </Button>
      </Input.Group>
    </Widget>
  );
}
