import moment from 'moment';

export const dateModuleSlate = {
  id: 'dateModuleSlate',
  genesisHandler: true,
  match: ({ node, api }) => {
    return api.utils.engine.matchModule(node, 'facilityAgreement', 'dateAgreement');
  },
  handler: function ({ matches, api }) {
    moment.locale(api.interfaces.Contract.getLanguage(this.contract));

    if (matches.default) {
      for (const { node, actions } of matches.default) {
        const args = (node.data && node.data.module && node.data.module.arguments) || {};
        const { format, prefix, suffix } = args;

        if (!format) {
          console.log('No format for dateAgreement.');
          return;
        }
        let value = moment().format(format);

        if (prefix) {
          value = prefix + value;
        }
        if (suffix) {
          value = value + suffix;
        }
        actions.replaceText(value);
      }
    }
  },
};
