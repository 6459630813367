export const offerDocumentModule = {
  id: 'offerDocumentModule',
  time: -1,
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true },
  match: true,
  handler: function ({ state, handlerInvoked, entries, paths }) {
    if (!this.contract.data.outline || this.contract.data.outline.id_name !== 'offer_bank_loan') return;

    if (!state.input.facility) {
      return this.setShortcut('HAS_FLOATING_CHARGE_INTEREST', false);
    }

    let hasFloatingInterest = false;
    let hasExtension = false;

    mainFloatingLoop: for (const [, facility] of Object.entries(state.input.facility)) {
      if (facility['facility/offer_multiple_tenors'] && facility.offering_multiple_tenors) {
        for (const [, tenorOffer] of Object.entries(facility.offering_multiple_tenors)) {
          if (
            tenorOffer['interest_rates/offer_different_interests'] &&
            tenorOffer.offer_different_interests_card
          ) {
            for (const [, interestOffer] of Object.entries(tenorOffer.offer_different_interests_card)) {
              if (interestOffer['interest_rates/interesttype'] === 'floating') {
                hasFloatingInterest = true;
                break mainFloatingLoop;
              }
            }
          } else if (tenorOffer['interest_rates/interesttype'] === 'floating') {
            hasFloatingInterest = true;
            break mainFloatingLoop;
          }
        }
        // interest_rates/offer_different_interests: false
      } else if (
        facility['interest_rates/offer_different_interests'] &&
        facility.offer_different_interests_card
      ) {
        for (const [, interestOffer] of Object.entries(facility.offer_different_interests_card)) {
          if (interestOffer['interest_rates/interesttype'] === 'floating') {
            hasFloatingInterest = true;
            break mainFloatingLoop;
          }
        }
      } else if (facility['interest_rates/interesttype'] === 'floating') {
        hasFloatingInterest = true;
        break;
      }
    }

    mainExtensionLoop: for (const [, facility] of Object.entries(state.input.facility)) {
      if (facility['facility/offer_multiple_tenors'] && facility.offering_multiple_tenors) {
        for (const [, tenorOffer] of Object.entries(facility.offering_multiple_tenors)) {
          if (tenorOffer['facility/extension'] === true) {
            hasExtension = true;
            break mainExtensionLoop;
          }
        }
      } else if (facility['facility/extension'] === true) {
        hasExtension = true;
        break;
      }
    }

    this.setShortcut('HAS_FLOATING_CHARGE_INTEREST', hasFloatingInterest);
    this.setShortcut('HAS_FACILITY_EXTENSION', hasExtension);
  },
};
