import { useState, useEffect, useCallback } from 'react';

const globalEventState = {};

export const useEventState = (eventName, defaultData = null) => {
  if (typeof eventName !== 'string') throw new Error('useEvent expects eventName');

  const key = '_custom_' + eventName;

  let initData;
  if (globalEventState.hasOwnProperty(key)) {
    initData = globalEventState[key];
  } else {
    initData = defaultData;
    globalEventState[key] = defaultData;
  }

  const [data, setData] = useState(initData);

  const updateData = useCallback(
    (value) => {
      globalEventState[key] = value;
      setData(value);
    },
    [key]
  );

  const processEvent = useCallback(
    (evt) => {
      updateData(evt.detail);
    },
    [updateData]
  );

  useEffect(() => {
    window.addEventListener(key, processEvent);
    return () => {
      window.removeEventListener(key, processEvent);
    };
  }, [eventName, processEvent, key]);

  return data;
};

export const setEventState = (eventName, data) => {
  window.dispatchEvent(new CustomEvent('_custom_' + eventName, { detail: data }));
};
