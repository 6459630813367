import React, { useCallback, memo } from 'react';

import { Transforms } from 'slate';
import { ReactEditor, useSelected } from 'slate-react';
import { SlateReactPresentation } from 'slate-react-presentation';
import { RenderElements } from '../../elements';
import { RenderLeaf } from '../../marks';
import { find } from 'core/engine/utils/';

import { Popover } from 'antd';
import { Link } from 'react-router-dom';

const getInlineClassNames = (element, selected) => {
  return (
    'ol-inline ol-inline-' +
    element.type +
    (element.variant ? ' ol-inline-' + element.variant : '') +
    (element.data && element.data._inActive ? ' ol-inline-inactive' : '') +
    (selected ? ' inline_selected' : '')
  );
};
const getExtraAttributes = (element) => {
  if (!element.data || !element.data._updateTime) return {};
  return { 'data-updated': element.data._updateTime };
};

export const code = ({ attributes, children }) => (
  <code {...attributes} className="border-2 bg-gray-100">
    {children}
  </code>
);

export const link = ({ attributes, children, element, editor }) => {
  return (
    <a
      className={getInlineClassNames(element)}
      {...attributes}
      {...getExtraAttributes(element)}
      href={element.url}
    >
      {children}
    </a>
  );
};

/**
 * @description internal linking, like documents in projects
 * @param {*} param0
 * @returns
 */
export const internallink = ({ attributes, children, element, editor }) => {
  const { meta, url, resourceLinkType } = element;

  const text = <span>{meta.name}</span>;

  const content = (
    <div>
      {resourceLinkType === 'Document' && <p>Status: {meta.status}</p>}
      <p>Description: {meta.description}</p>
    </div>
  );

  let iconClass;
  switch (resourceLinkType) {
    case 'Project':
      iconClass = 'mdi mdi-cube-outline';
      break;
    default:
      iconClass = 'mdi mdi-file';
      break;
  }

  return (
    <Popover placement="topLeft" title={text} content={content}>
      <span style={{ padding: '3px 6px', backgroundColor: '#f9f9f9', borderRadius: '5px' }}>
        <Link
          className={getInlineClassNames(element)}
          {...attributes}
          {...getExtraAttributes(element)}
          to={url}
        >
          <i className={iconClass} style={{ marginRight: '7px' }} />
          {children}
        </Link>
      </span>
    </Popover>
  );
};

export const Tab = ({ attributes, children, element, editor }) => {
  const style = {};
  const selected = useSelected();
  if (element.size) style.paddingLeft = element.size + 'px';
  return (
    <span className={'editor-tab' + (selected ? ' tab-selected' : '')} style={style}>
      {children}
    </span>
  );
};

export const Field = memo(({ attributes, children, element, editor, isInActiveRepresentation }) => {
  const selected = useSelected();
  const onClick =
    element.variant === 'ref'
      ? () => {
          const target = find(
            editor.children,
            (n) => n.data?.bookmarks && n.data?.bookmarks.includes(element.data.name)
          );
          if (target && target[0]) editor.scrollToNodeOrDomNode(target[0]);
        }
      : undefined;

  return (
    <span
      className={getInlineClassNames(element, selected)}
      {...attributes}
      {...getExtraAttributes(element)}
      onMouseDown={onClick}
    >
      {/* {!isInActiveRepresentation && element.variant === 'opt' && element.data && element.data._inActive && (
        <InActiveOpt editor={editor} element={element} />
      )}
      {!isInActiveRepresentation && element.variant === 'opt' && element.data && !element.data._inActive && (
        <ActiveOpt editor={editor} element={element} />
      )} */}
      {children}
    </span>
  );
});

const ActiveOpt = ({ element, editor }) => {
  // const elem = useRef(null);
  const inactivateNode = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    try {
      const path = ReactEditor.findPath(editor, element);
      const data = { ...element.data, _inActive: true };
      Transforms.setNodes(editor, { data }, { at: path });

      // Reset the display to avoid bug in Chrome (and potentially others)
      // with a non-existing "element" in the beginning which has the same
      // background/other props as an opt
      // const parent = elem.current.parentElement;

      // parent.style.display = "none";
      /* let opacity = 0.0;
      function makeWhole() {
        setTimeout(() => {
          opacity += 0.1;
          if (opacity >= 1) return (parent.style.opacity = 1);
          parent.style.opacity = opacity;
          makeWhole();
        }, 40);
      }
      setTimeout(() => {
        parent.style.display = "inline";
        parent.style.opacity = "0";
        makeWhole();
      }, 10); */
    } catch (err) {
      console.log('Err finding inactive node path');
    }
  };

  return null;
  return (
    <>
      <div className="active-inline-inactivate-btn" onMouseDown={inactivateNode} style={{ zIndex: '1000' }}>
        InActivate
      </div>
    </>
  );
};

const InActiveOpt = ({ element, editor }) => {
  const activateNode = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    try {
      const path = ReactEditor.findPath(editor, element);
      const data = { ...element.data, _inActive: false };
      Transforms.setNodes(editor, { data }, { at: path });

      // Reset the display to avoid bug in Chrome (and potentially others)
      // with a non-existing "element" in the beginning which has the same
      // background/other props as an opt
      // const parent = elem.current.parentElement;
      /* 
      parent.style.display = "none";
      let opacity = 0.0;
      function makeWhole() {
        setTimeout(() => {
          opacity += 0.1;
          if (opacity >= 1) return (parent.style.opacity = 1);
          parent.style.opacity = opacity;
          makeWhole();
        }, 40);
      }
      setTimeout(() => {
        parent.style.display = "inline";
        parent.style.opacity = "0";
        makeWhole();
      }, 10); */
    } catch (err) {
      console.log('Err finding inactive node path');
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderElement = useCallback(
    (props) => <RenderElements {...props} editor={editor} isInActiveRepresentation />,
    []
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderLeaf = useCallback((props) => <RenderLeaf {...props} editor={editor} />, []);

  let renderedBlock = makeActive(JSON.parse(JSON.stringify(element)));

  return (
    <>
      <span className="opt-inactive-indicator">
        <>
          <div className="inactive-inline-view" onClick={activateNode}>
            {/* <div
              className="inactive-inline-activate-btn"
              onMouseDown={activateNode}
              style={{ zIndex: '1000' }}
            >
              Activate
            </div> */}
            <SlateReactPresentation
              value={[renderedBlock]}
              renderElement={renderElement}
              renderLeaf={renderLeaf}
            />
          </div>
        </>
      </span>
    </>
  );
};

function makeActive(elem) {
  if (elem.data && elem.data._inActive) elem.data._inActive = false;
  elem._isInActiveRepresentation = true;
  if (elem.children) elem.children.forEach((child) => makeActive(child));
  return elem;
}

export const user_input = ({ attributes, children, element, editor }) => {
  return (
    <span className={getInlineClassNames(element)} {...attributes} {...getExtraAttributes(element)}>
      {children}
    </span>
  );
};
