import React from "react";
import { Button } from "antd";
import { useModalContent } from "components/ui/modals";

export default function RemoveModal({ type, onRemove, setOpen }) {
  const [Modal] = useModalContent(`Remove ${type}`, {
    defaultOpen: true,
    setOpen
  });

  const parent = {
    page: 'this page', // Better word for the parent?
    card: 'this section',
    input: 'this box',
  }[type];

  return (
    <Modal>
      <p>Do you want to remove the {type} completely, or just remove it from {parent} so it can be used again?</p>
      <Button type="primary" onClick={() => onRemove(false)}>Remove only from {parent}</Button>
      <Button type="danger" onClick={() => onRemove(true)}>Remove completely</Button>
    </Modal>
  );
};
