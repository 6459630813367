import axios from "axios";
import { apiAddress } from "config/main";
import jwt_decode from "jwt-decode";
import { getStore } from "appRedux/store";
import { setAuthToken, setLocale } from "utils/auth"
import { authOverlay } from "appRedux/actions/Auth";
import { notification } from "antd"
import StackTrace from "stacktrace-js";

const isLoginError = (err) =>
  err.response &&
  err.response.status === 401 &&
  err.response.data &&
  err.response.data.type === "auth";

let isRefreshing = false;
let requestsPendingRefresh = [];

export const doRefresh = (path, arg) => {
  const encodedAuthToken = localStorage.jwtToken;
  const encodedRefreshToken = localStorage.refToken;
  if (!encodedAuthToken || !encodedRefreshToken) {
    const store = getStore();
    store.dispatch(authOverlay(true));
    return Promise.reject("Tokens not valid.");
  }

  const onRefreshPromise = new Promise((resolve, reject) => {
    requestsPendingRefresh.push([resolve, reject]);
  });

  if (isRefreshing) {
    return onRefreshPromise;
  }

  const authObj = jwt_decode(encodedAuthToken);

  isRefreshing = true;

  axios
    .post(apiAddress + "/users/refresh2", {
      ref_token: encodedRefreshToken,
      email: authObj.email,
    })
    .then((result) => {
      if (result && result.data && result.data.success && result.data.token) {
        const { token: newAuthToken, refreshToken: newRefreshToken } = result.data;

        localStorage.setItem("jwtToken", newAuthToken);
        if (newRefreshToken) localStorage.setItem("refToken", newRefreshToken);

        setAuthToken(newAuthToken)
        // axios.defaults.headers.common["Authorization"] = newAuthToken;

        console.log("Refresh successfull");
        requestsPendingRefresh.forEach(([promResolve]) => promResolve());
        requestsPendingRefresh = [];
        isRefreshing = false;
      } else {
        // console.log("No refresh result?? ", result, result.data);
        requestsPendingRefresh.forEach(([, promReject]) =>
          promReject({ response: "Re-authentication required." })
        );
        requestsPendingRefresh = [];
        isRefreshing = false;
      }
    })
    .catch((error) => {
      const store = getStore();
      store.dispatch(authOverlay(true));

      // console.log("do Refresh refresh error ", {error, errresp: error && error.response});
      requestsPendingRefresh.forEach(([, promReject]) =>
        promReject({ response: "Re-authentication required!" })
      );
      requestsPendingRefresh = [];
      isRefreshing = false;
    });

  return onRefreshPromise;
};

const handleError = (err, method, path, arg, resolve, reject) => {
  if (isLoginError(err)) {
    // console.log("login err  is ", err.response);
    if (err.response.data.action) {
      if (
        err.response.data.action === "try-refresh" &&
        localStorage.refToken &&
        localStorage.refToken
      ) {
        doRefresh(apiAddress + path, arg)
          .then(() => {
            // Re-run request
            axios[method](apiAddress + path, arg)
              .then((success) => {
                return resolve(success);
              })
              .catch((err) => {
                console.log("Ongoing auth err..", err.response);
                reject({ ...(typeof err === "object"?err:{}), response: (err ? err.response : 'Auth error')   });
              });
          })
          .catch((err) => {
            console.log("Do refresh err ", err);
            reject({ ...(typeof err === "object"?err:{}), response: (err ? err.response : 'Auth error')   });
          });
      } else {
        const store = getStore();
        store.dispatch(authOverlay(true));
      }
    }
  } else {
    if (err.response?.status === 403)
      notification.error({
        message: "No access",
        description: "Talk to your administrator if you need to perform this action.",
        duration: 10
      })
    if (err.response?.data?.errorMessage) {
      notification.error({
        message: err.response.data.errorHeader || null,
        description: err.response?.data?.errorMessage,
        duration: 10,
      });
    }
    reject(err);
  }
};

const apiDispatcher = (method, path, opts, settings = {}) => {
  // console.log('settings are ', settings)
  // Async refresh tokens

  /* if(method.toLowerCase() === "put") {
    console.log("===============================")
    console.log('API PUT ', {path, opts})
    console.trace('Trace')
    console.log("===============================")
  } */

  const locale = localStorage['settings.locale'];
  if (locale) {
    const jsonLocale = JSON.parse(locale);
    if ('locale' in jsonLocale &&
      axios.defaults.headers.common.ClientLocale !== jsonLocale.locale) {
        setLocale(jsonLocale.locale);
    }
  }

  if (
    localStorage.jwtToken &&
    axios.defaults.headers.common.Authorization !== localStorage.jwtToken
  ) {
    setAuthToken(localStorage.jwtToken)
    // axios.defaults.headers.common["Authorization"] = localStorage.jwtToken;
  }

  // Return api call
  return new Promise((resolve, reject) => {
    if (method === "_custom") {
      axios(opts)
        .then((success) => {
          resolve(success);
          if (!settings || !settings.skipAfterCheck) {
            setTimeout(() => {
              afterCheckAuth();
            }, 100);
          }
        })
        .catch((err) => {
          return handleError(err, method, path, opts, resolve, reject);
        });
    } else if (typeof axios[method] === "function") {
      axios[method](apiAddress + path, opts)
        .then((success) => resolve(success))
        .catch((err) => {
          return handleError(err, method, path, opts, resolve, reject);
        });
    } else {
      throw new Error("Invalid method for api", method);
    }
  });
};

let afterFix = false;

function afterCheckAuth() {
  const encodedAuthToken = localStorage.jwtToken;
  if (!afterFix && encodedAuthToken) {
    const authObj = jwt_decode(encodedAuthToken);
    const currentTime = Date.now() / 1000;
    if (authObj.exp - currentTime < 500 && localStorage.refToken) {
      console.log("Fix before hand...");
      afterFix = true;
      doRefresh()
        .then(() => {
          afterFix = false;
        })
        .catch((err) => {});
    }
  }
}

function api(opts) {
  if (opts.url) opts.url = apiAddress + opts.url;
  if (opts.fixUrl) opts.url = opts.fixUrl;
  return apiDispatcher("_custom", null, opts);
}
api.get = (path, opts, settings) => apiDispatcher("get", path, opts);
api.post = (path, opts, settings) => apiDispatcher("post", path, opts);
api.put = (path, opts, settings) => apiDispatcher("put", path, opts);
api.delete = (path, opts, settings) => apiDispatcher("delete", path, opts);
api.log = (logLevel, errorMessage, error, errorCode, extraInfo) => {
  StackTrace.fromError(error)
  .then((stack) => {
    const errorInfo = { level: logLevel, errorMessage, error: error.message, stacktrace: stack, errorCode, url: window.location?.href, extraInfo };
    console.log('Unhandled exception', errorInfo);
      apiDispatcher("post", "/log", errorInfo);
    });
}

export default api;

/* 
const doRefresh = () => {
  
  return new Promise((resolve, reject) => {
    const encodedAuthToken = localStorage.jwtToken;
    const encodedRefreshToken = localStorage.refToken;
    if (!encodedAuthToken || !encodedRefreshToken) return reject();
    const authObj = jwt_decode(encodedAuthToken);

    isRefreshing = true

    axios
      .post(apiAddress+"/users/refresh2", {
        ref_token: encodedRefreshToken,
        email: authObj.email
      })
      .then(result => {
        if (result && result.data && result.data.success && result.data.token) {
          const {
            token: newAuthToken,
            refreshToken: newRefreshToken
          } = result.data;

          localStorage.setItem("jwtToken", newAuthToken);
          if (newRefreshToken)
            localStorage.setItem("refToken", newRefreshToken);
          
          axios.defaults.headers.common["Authorization"] = newAuthToken
          
          console.log('Refresh successfull')
          resolve(true);
        } else {
          console.log("No result?? ", result, result.data);
          reject(null)
        }
      })
      .catch(error => {
        const store = getStore()
        store.dispatch(authOverlay(true))

        console.log('do Refresh refresh error ', error.response)
        reject(error.response)
      });
    
  });
}; */

const DEBOUNCE_TIME = 600;
const DEBOUNCE_TIME_MAX = 3000;

export const generateDebouncer = (dealy = DEBOUNCE_TIME, max_delay = DEBOUNCE_TIME_MAX) => {
  let releaseId = -1;
  let debounceId = -1;

  const debounceRequest = (fn) => {
    if (releaseId === -1) {
      releaseId = setTimeout(() => {
        releaseDebounce(fn, debounceId);
      }, DEBOUNCE_TIME_MAX);
    }
    clearTimeout(debounceId);
    debounceId = debounce(fn);
  };

  const debounce = (fn) => {
    return setTimeout(() => {
      releaseDebounce(fn, releaseId);
    }, DEBOUNCE_TIME);
  };

  const releaseDebounce = (fn, timeoutId) => {
    clearTimeout(timeoutId);
    releaseId = -1;
    fn();
  };
  return debounceRequest
};
