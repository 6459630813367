import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Select } from 'antd';
import { Entity } from 'core/interfaces'
import { getValue } from './utils/values';

const { Option } = Select;

function InputSelect(props) {
  const { label, handleChange, language, cardId, field, inputIndex, cardUid } = props;
  const entities = useSelector((state) => {
    if (field.content !== 'node-names') return [];
    return state.entities;
  });
  let value = getValue(props, field.value ? field.value : []);

  const id = field.name + inputIndex + cardId + cardUid;

  return (
    <Form.Item
      label={label}
      extra={field.info ? <small className="muted d-block">{field.info[language]}</small> : null}
    >
      <Select mode="tags" onChange={handleChange} name={field.name} id={id} value={value} placeholder="">
        {renderOptions(props, entities)}
      </Select>
    </Form.Item>
  );
}

function renderOptions(props, entities) {
  const { language, cardId, field, inputIndex } = props;
  if (field.content === 'node-names') {
    return [].concat(
      entities
        .filter((entity) => entity.parentId !== null)
        .map((entity, entityIndex) => (
          <Option key={entity.id + cardId + inputIndex + entityIndex + field.name + 'y'} value={entity.name}>
            {Entity.name(entity)}
          </Option>
        ))
    );
  }
  return field.content.map((option, contentIndex) => (
    <Option
      key={option.id + cardId + inputIndex + contentIndex + field.name + 'x'}
      disabled={option.disabled}
      value={
        option.id
        /* option.values && option.values.hasOwnProperty(language)
          ? option.values[language]
          : option.id */
      }
    >
      {option.label[language]}
    </Option>
  ));
}

export default InputSelect;
