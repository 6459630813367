// import React, { useState, useEffect, useMemo } from 'react';
import { notification, Form, Input, Button } from 'antd';

export function PasswordCredential({ onSubmit }) {
  const onFinishFailed = () => {
    notification.error({
      message: 'Error',
      description: 'Please ensure the form is filled in properly',
    });
  };

  return (
    <Form
      layout="vertical"
      className="mt-4 mb-4 external-pwd-form"
      onFinish={onSubmit}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your password' }]}
        className="mb-2"
      >
        <Input.Password id="credentialPassword" />
      </Form.Item>

      <Form.Item className="mt-4 pwd-btn">
        <Button type="primary" htmlType="submit">
          Authenticate
        </Button>
      </Form.Item>
    </Form>
  );
}

export function EmailCredential({ onSubmit }) {
  const onFinishFailed = () => {
    notification.error({
      message: 'Error',
      description: 'Please ensure the form is filled in properly',
    });
  };

  return (
    <Form
      layout="vertical"
      className="mt-4 mb-4 external-pwd-form"
      onFinish={onSubmit}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, tpye: 'email', message: 'Please input your email' }]}
        className="mb-2"
      >
        <Input />
      </Form.Item>

      <Form.Item className="mt-4 pwd-btn">
        <Button type="primary" htmlType="submit">
          Authenticate
        </Button>
      </Form.Item>
    </Form>
  );
}