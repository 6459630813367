import React from 'react';
import { Editor, Transforms, Node } from 'slate';
import { textAbstract } from 'core/utils/general';
import CustomScrollbars from 'util/CustomScrollbars';
import { Radio, Tooltip } from 'antd';

export default function EnumMenu({ editor, node, path }) {
  const { currentEnumIndex } = node.data;
  const hasCurrent = typeof currentEnumIndex === 'number';

  if (!node.data?.enums) return null;

  const onChange = (evt) => {
    if (path) {
      const value = evt.target.value;

      const newChildren = JSON.parse(JSON.stringify(node.data.enums[value].content));

      Editor.withoutNormalizing(editor, () => {
        const newData = { ...node.data };
        if (!node.data.ignoreChange && hasCurrent) {
          const allEnums = JSON.parse(JSON.stringify(node.data.enums));
          allEnums[currentEnumIndex].content = JSON.parse(JSON.stringify(node.children));
          newData.enums = allEnums;
        }
        Transforms.delete(editor, {
          at: { anchor: Editor.start(editor, path), focus: Editor.end(editor, path) },
        });
        Transforms.insertNodes(editor, newChildren, { at: [...path, 0] });
        Transforms.setNodes(editor, { data: { ...newData, currentEnumIndex: value } }, { at: path });
      });
    }
  };

  return (
    <div
      style={{
        height: '250px',
        width: '200px',
      }}
    >
      <CustomScrollbars>
        <Radio.Group
          value={node.data.currentEnumIndex}
          onChange={onChange}
          buttonStyle="solid"
          style={{ width: '100%' }}
        >
          {node.data.enums
            // .filter((en, index) => hasCurrent && index !== currentEnumIndex)
            .map((en, index) => {
              const text = Node.string({ children: en.content });
              return (
                <Tooltip title={text} key={index} placement="right">
                  <Radio.Button style={{ display: 'block' }} value={index}>
                    {textAbstract(text, 17)}
                  </Radio.Button>
                </Tooltip>
              );
            })}
        </Radio.Group>
      </CustomScrollbars>
    </div>
  );
}
