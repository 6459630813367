export const setupValuesSlate = {
  id: 'setupValuesSlate',
  genesisHandler: true,
  match: ({ node }) => {
    return node.variant === 'setupValue' && node.data && node.data.name;
  },
  handler: function ({ api, matches }) {
    if (matches.default) {
      const setup = api.interfaces.Contract.getSetup(this.contract);

      for (const { node, actions } of matches.default) {
        let value;
        if (setup[node.data.name]) value = setup[node.data.name];
        else value = this.emptyContractString();

        actions.replaceText(value);
      }
    }
  },
};
