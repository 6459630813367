const localeChar = ',' // TODO make locale, eg. add &nbsp; for swedish

export default function fixNumbers(amount, opts = {}) {
  return `${amount}`.replace(/\d(?=(\d{3})+([\D])*$)/g, `$&${localeChar}`)
}

export const fixCopyNumbersListener = (e) => {
  const copy = document.getSelection().toString();
  const cleanCopy = restoreNumbers(copy)
  if (copy !== cleanCopy && Number(cleanCopy)) {
    e.preventDefault();
    e.clipboardData.setData('text/plain', cleanCopy)
  }
}

const restoreNumbers = (text) => {
  return text.replaceAll(localeChar, '')
}
