import { Spin } from 'antd';
import { useYjsEditor } from 'hooks';
import { CloudOutlined, LoadingOutlined } from '@ant-design/icons';

export default function LoadEditorOverlay({ children }) {
  const yjsEditorData = useYjsEditor();
  if (!yjsEditorData) return children;

  const { onlineMode, onlineLoaded } = yjsEditorData;
  if (!onlineMode) return children;
  if (onlineLoaded) return children;

  return (
    <div className="connected-editor-loader">
      <div className="position-relative d-flex justify-content-center align-items-center">
        <CloudOutlined className="text-primary loading-icon"></CloudOutlined>
        <div className="position-absolute">
          <Spin className="m-0" indicator={<LoadingOutlined className="loading-indicator" spin />} />
        </div>
      </div>
      <div className="mt-4">Loading contract . . .</div>
    </div>
  );
}
