import React from "react";
import { useEventState, setEventState } from "hooks"
import { useModalContent } from "components/ui/modals";
import IntlMessages from 'util/IntlMessages';

import AddEditPage from "./Pages/AddEditPage";
import ArrangePages from "./Pages/ArrangePages";
import RemovePage from "./Pages/RemovePage";

import AddEditCard from "./Cards/AddEditCard";
import ArrangeCards from "./Cards/ArrangeCards";

import AddEditInput from "./Inputs/AddEditInput";
import ArrangeInputs from "./Inputs/ArrangeInputs";

const templateComponents = {
  page: {
    add: AddEditPage,
    arrange: ArrangePages,
    edit: AddEditPage,
    remove: RemovePage,
  },
  card: {
    add: AddEditCard,
    arrange: ArrangeCards,
    edit: AddEditCard,
  },
  input: {
    add: AddEditInput,
    arrange: ArrangeInputs,
    edit: AddEditInput,
  },
};

export default function TemplateInputSections() {
  const data = useEventState('template_edit_input_sections')
  if (!data) return null;
  return <MainMenu data={data} />;
}

function MainMenu({ data }) {
  const selected = data.selected;
  const disableModal = data.disableModal;
  const insertPosition = data.insertPosition;
  const TemplateComponent = templateComponents[data.type][data.method];

  const header = <IntlMessages id={`studio.template.modal.${data.type}.${data.method}`} />;

  const close = () => {
    setEventState('template_edit_input_sections', null)
  }

  const [Modal] = useModalContent(header, {
    defaultOpen: true,
    onCancel: close
  });  

  const windowWidth = window.innerWidth;
  let modalWidth = windowWidth - 50;

  /* const sidebarElem = document.getElementById("studio-sidebar");
  const sidebarWidth = (sidebarElem &&
    sidebarElem.getBoundingClientRect() &&
    sidebarElem.getBoundingClientRect().width) || 0;

  let modalLeft = 0;

  if (windowWidth > 900) {
    modalWidth = 800;
    if (windowWidth > 1400) {
      modalLeft = -280 - (280 - sidebarWidth);
    }
  } */
  
  const template = <TemplateComponent data={data} selected={selected} onFinish={close} insertPosition={insertPosition} />;
  if (disableModal) return template;
  return (
    <Modal onCancel={close} width={modalWidth} /* style={{ left: modalLeft }} */>
      {template}
    </Modal>
  );
}

export function ContentWrapper({ children }) {
  return <div className="t-input-wrapper">{children}</div>;
}
