import findIndex from 'find-index/findIndex';
import { Concept, Contract } from '../interfaces';
import { ocount, userToName } from './general';

/*
 * Get description for relevant concept (e.g. borrower).
 * By default returns singular value.
 */
export function getConceptDescr(concept, contract, state, defaultName, forcePlural = false) {
  const language = Contract.getLanguage(contract);
  const conceptState = Concept.getConceptState(concept, contract, state);
  const conceptStateLength = ocount(conceptState);
  if (!conceptState || conceptStateLength === 0) return defaultName;

  let numeris;
  if (forcePlural) numeris = 'pluralFixed';
  else {
    numeris = conceptStateLength > 1 ? 'pluralFixed' : 'singularFixed';
  }

  const { value, match: grammarMatch } = Concept.grammar(concept.id, numeris, language, { contract });

  return grammarMatch ? value : defaultName;

  // return concept.grammar[language].values[key] || defaultName;
}

export function isParty(node, contract, state) {
  if (!node) return null;
  const firstContractConcept = getFirstConcept(contract, node, state, { contractParty: true });
  // console.log('node is party', node, concepts, input, firstContractConcept)
  return firstContractConcept;
}

export function isPartyIndirectly(node, nodes, contract, state) {
  const firstConcept = getFirstConcept(contract, node, state);
  if (firstConcept) return { node, firstConcept };
  const parentNode = nodes.find((item) => item.id === node.parentId);
  if (!parentNode) return false;
  return isPartyIndirectly(parentNode, nodes, contract, state);
}

export function getFirstConcept(contract, entity, state, options = {}) {
  if (!entity) return false;
  const concepts = Contract.getConcepts(contract);
  const { id } = entity;
  for (const concept of concepts) {
    if (options && options.contractParty && !concept.contractParty) {
      continue;
    }
    if (!concept.stateKey) continue;
    const conceptState = Concept.getConceptState(concept, contract, state);
    if (ocount(conceptState) < 1) continue;
    for (const [, values] of Object.entries(conceptState)) {
      const stateKeyData = values[concept.stateKey];
      if (
        typeof stateKeyData === 'string' &&
        (entity.name === stateKeyData || userToName(entity) === stateKeyData || entity.id === stateKeyData)
      ) {
        return concept;
      } else if (typeof stateKeyData === 'object' && stateKeyData.id === id) {
        return concept;
      }
    }
    // if (ofindKey(conceptState, (item) => item[concept.stateKey] === testName)) return concept;
  }
  return false;
}

export function getExclusiveConcept(concepts, entity, contract, state) {
  const { id } = entity;
  for (const concept of concepts) {
    const conceptState = Concept.getConceptState(concept, contract, state);
    const conceptStateLength = ocount(conceptState);
    if (conceptStateLength !== 1) continue;
    const item = conceptState[Object.keys(conceptState)[0]];
    const stateKeyData = item[concept.stateKey];
    if (
      typeof stateKeyData === 'string' &&
      (entity.name === stateKeyData || userToName(entity) === stateKeyData || entity.id === stateKeyData)
    ) {
      return concept;
    } else if (typeof stateKeyData === 'object' && stateKeyData.id === id) {
      return concept;
    }
    /* if (conceptState[Object.keys(conceptState)[0]][concept.stateKey] === testName)
      return concept; */
  }
  return false;
}

export function firstIsParentOfSecond(parentNode, childNode, nodes, recursive = true) {
  if (typeof childNode === 'undefined') {
    console.log('No childNode supplied to isParentOf');
    return false;
  }

  var childsParentId = childNode.parentId;

  // If null, then childNode is the owner, e.g. has no parent.
  if (childsParentId === null) return false;
  // If childs parent is the explicit parent to check - return true
  else if (childsParentId === parentNode.id) {
    return true;
  } else if (recursive) {
    // Find intermediary parent
    const newParentNode = nodes.find((item) => item.id === childsParentId);
    // Compare intermediary parent to ultimate parent to check
    return firstIsParentOfSecond(parentNode, newParentNode, nodes, true);
  }

  return false;
}

export const findTopCo = (nodes) => {
  const topCoNode = nodes.find((node) => node.parentId === null);
  return topCoNode;
};
