import { useIsTemplateStudio } from 'hooks';
import updateStudioDrafts from "utils/saving/updateStudioDrafts";
import { ConversationsButtons } from './Conversations/ConversationsContainer';
import InviteApprovalButton from './BottomRight/InviteApprovalButton';
import { Button } from 'antd';
import { useState } from 'react';
import IntlMessages from 'util/IntlMessages';

export default function BottomRightActions() {
  const isTemplate = useIsTemplateStudio();
  const [loading, setLoading] = useState(false);

  const saveTemplate = async () => {
    setLoading(true);
    await updateStudioDrafts();
    setLoading(false);
  }

  if (isTemplate) return (
    <div className="bottom-right-actions">
      <Button type="primary" loading={loading} onClick={saveTemplate}>
        <IntlMessages id="desc.Save" cap />
      </Button>
    </div>
  );

  return (
    <div className="bottom-right-actions">
      <InviteApprovalButton />
      <ConversationsButtons />
    </div>
  );
}
