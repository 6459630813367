import React, { useMemo } from 'react';
import { Card, Table, Tag, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { BasicSkeleton } from '../../../../../components/Skeletons/Overview';
import IntlMessages from 'util/IntlMessages';
import moment from 'moment';
import { Entity } from '../../../../../core/interfaces';
import { sortByCreatedAt } from 'core/utils/general';
import { useEntityData, useFetch } from 'hooks';

const { Column } = Table;

function getFeedbackUrl(currentVersion) {
  if (!currentVersion || !currentVersion.id) return '';
  return `/versionfeedbacks?where=versionId_->_${currentVersion.id}&order=${encodeURI('desc|createdAt')}`;
}
function getEventUrl(currentVersion, eventKey, entityId) {
  if (!currentVersion || !currentVersion.id) return '';
  return `/events?resourceType=Version&resourceId=${currentVersion.id}&where=eventKey_->_${eventKey}_AND_entityId_!->_${entityId}`;
}

function useEventInfo(currentVersion, eventKey = 'made-version-changes') {
  const entityId = useSelector(({ auth }) => auth?.user?.entityId);
  const url = getEventUrl(currentVersion, eventKey, entityId);
  const [value] = useFetch(url);
  return value;
}

function getRelevantConversations(conversations) {
  return Object.entries(conversations)
    .filter(([, conv]) => {
      const { type, completed } = conv;
      if (type === 'FB') return false; // Already through feedbacks.
      if (type === 'TC') return false; // Not all track changes. Handled largely through event `made-version-changes`
      if (completed === true) return false; // Do not show completed conversations.
      return true;
    })
    .map(([id, conv]) => ({ id, entityId: conv.eId, _type: 'conv', ...conv }));
}

function combineResults(feedbacks, events, conversations) {
  let results = [];
  if (feedbacks) results = results.concat(feedbacks.map((item) => ({ _type: 'feedback', ...item })));
  if (events) results = results.concat(events.map((item) => ({ _type: 'event', ...item })));
  if (conversations) results = results.concat(getRelevantConversations(conversations));
  return results;
}

export default function VersionFeedbacks({ currentVersion, doc }) {
  const [feedbacks] = useFetch(getFeedbackUrl(currentVersion));
  const events = useEventInfo(currentVersion);
  const conversations = useSelector(({ conversations }) => conversations);

  const [allData, totalNumber] = useMemo(() => {
    const results = combineResults(feedbacks, events, conversations);
    return [results.sort(sortByCreatedAt), results.length];
  }, [feedbacks, events, conversations]);

  if (!doc || !allData) return <BasicSkeleton />;

  let content;
  if (totalNumber === 0) {
    content = <IntlMessages id="app.project.version.VersionFeedbacks.none" />;
  } else {
    content = <ListFeedbacks allData={allData} />;
  }
  return (
    <Card
      title={<IntlMessages id="app.project.version.VersionFeedbacks" />}
      className="widget light-containerx"
    >
      {content}
    </Card>
  );
}

function ListFeedbacks({ allData }) {
  const entityData = useEntityData(allData.map((f) => f.entityId));

  return (
    <Table rowKey="id" dataSource={allData} showHeader={false}>
      <Column
        // title={<IntlMessages id="app.general.time" cap />}
        dataIndex="createdAt"
        key="createdAt"
        width={30}
        render={(text) => (
          <Tooltip title={moment(text).format('HH:MM, D MMMM YYYY')}>
            <small>
              <i className="mdi mdi-clock text-muted" />
            </small>
          </Tooltip>
        )}
      />
      <Column
        // title={<IntlMessages id="app.general.Name" cap />}
        dataIndex="desc"
        key="desc"
        render={(name, record) => {
          const { _type, entityId } = record;
          let content = null;
          if (_type === 'event') {
            const { eventKey } = record;
            const color = eventKey === 'start-review-version' ? 'grey' : 'blue';
            content = <IntlMessages id={`app.events.types.${eventKey}`} />;
          } else if (_type === 'feedback') {
            const { status } = record;
            content = (
              <>
                <IntlMessages id={`app.approval.${status}`} /> version
              </>
            );
          } else if (_type === 'conv') {
            const { type } = record;
            if (type === 'CON') content = <>added a contract conversation</>;
            else if (type === 'C') content = <>made a contract comment</>;
            else content = <>xx conversation</>;
          }
          return (
            <div>
              <small>
                {Entity.name(entityData[entityId], '[Unknown]')} <strong>{content}</strong>
              </small>
            </div>
          );
        }}
      />
      {/* 
      <Column
        // title={<IntlMessages id="app.general.Name" cap />}
        dataIndex="entityId"
        key="entityId"
        render={(name, { entityId }) => Entity.name(entityData[entityId], '[Unknown]')}
      />
      <Column
        // title={<IntlMessages id="app.general.Action" cap />}
        dataIndex="status"
        key="status"
        render={(status, record) => {
          const { _type } = record;
          if (_type === 'event') {
            const { eventKey } = record;
            const color = eventKey === 'start-review-version' ? 'grey' : 'blue';
            return (
              <small className={'text-uppercase '}>
                <Tag color={color}>
                  <IntlMessages id={`app.events.types.${eventKey}`} />
                </Tag>
              </small>
            );
          } else if (_type === 'feedback') {
            return (
              <small className={'text-uppercase '}>
                <Tag color={status === 'approved' ? 'green' : 'red'}>
                  <IntlMessages id={`app.approval.${status}`} />
                </Tag>
              </small>
            );
          } else if (_type === 'conv') {
            const { type } = record;
            if (type === 'CON') return <small>Contract Conversation</small>;
            if (type === 'C') return <small>Contract Comment</small>;
            return <small>Other conversation</small>;
          }
        }}
      /> */}
    </Table>
  );
}
