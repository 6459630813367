import StatusTag from '../../ui/StatusTag';

export const OverviewHeader = ({
  icon = null,
  title = '',
  status = null,
  description = '',
  extra,
  info,
  alert,
  alertType,
}) => {
  return (
    <>
      {alert && <div className={`dark-container overview-alert ${alertType}`}>{alert}</div>}
      <div className="overview-header">
        <div className='"overview-header-info'>
          <div className="overview-header-title-row">
            {icon ? <div className="overview-header-title-icon">{icon}</div> : null}
            <h2 className="fs-xxl font-weight-light">{title}</h2>
            {typeof status === 'string' ? <StatusTag status={status} withColor /> : status}
          </div>
          <div className="overview-header-details-row">
            <h5>{description}</h5>
            {extra && <div>{extra}</div>}
          </div>
        </div>
        <div className="overview-header-content">{info || null}</div>
      </div>
    </>
  );
};
