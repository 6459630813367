export const entityChangeSlate = {
  id: 'entityChangeSlate',
  dependencies: { repeatableChange: true, ordinary: true, entityAny: true },
  match: ({ node }) => node.variant === 'entity',
  handler: function ({ state, entries, api, matches }) {
    for (const { node, actions } of matches.default) {
      const changedEntries = getChangedEntities(api, this.contract, state, node, entries, this.language);
      for (const { entityId, value, displayValue } of changedEntries) {
        actions.replaceText(displayValue || value, { setValue: true });
        actions.setNodeData({
          value: value,
          entity_id: entityId,
        });
      }
    }
  },
};

const getChangedEntities = (api, contract, state, node, entries, language) => {
  const changedEntities = entries
    .reduce((store, entry) => {
      if (entry.pathInvoked.ordinary || entry.pathInvoked.repeatableChange)
        return [...store, getReplacedEntities(api, contract, state, node.data, entry, language)];
      else if (entry.pathInvoked.entityAny)
        return [...store, getEditedEntities(api, node, state, entry, language)];
      return store;
    }, [])
    .filter((v) => v);
  if (node.data.instructions && node.data.instructions.allowConceptConversion) {
    return changedEntities.map((changeSet) => {
      const mappedValue = api.utils.engine.mapCompaniesToConcepts({
        companies: changeSet.value,
        contract,
        state,
      });
      if (mappedValue && mappedValue.perfectMatch && mappedValue.text) {
        let { text } = mappedValue;
        if (node.data.instructions.caps) text = api.utils.general.ucfirst(text);
        return { ...changeSet, displayValue: text };
      }
      return changeSet;
    });
  }

  return changedEntities;
};

const getReplacedEntities = (api, contract, state, data, entry, language) => {
  const { cardId, fieldName, field, path, value } = entry;
  const matches = data.fieldPath === `${cardId}.${fieldName}`;

  if (matches && field && path.endsWith('.id')) {
    const repeatableUid = api.interfaces.InputPaths.getCardUid(contract, path);
    const currentInput = !repeatableUid || repeatableUid === data.repeatable_uid;
    if (currentInput && ['entity', 'party'].includes(field.type))
      return parseReplaceValue(api, state, value, data.key, language);
  }
};

const getEditedEntities = (api, node, state, entry, language) => {
  const { path } = entry;
  const [entityType, entityIndex, ...key] = path.split('.');
  if (!entityType || entityIndex === undefined) {
    return;
  }
  const entity = state[entityType][entityIndex];
  if (
    entity &&
    node.data.entity_id === entity.id &&
    api.interfaces.Entity.isKeysConnected(node.data.key, key)
  )
    return parseEditValue(api, node, entity, language);
};

const parseReplaceValue = (api, state, entryValue, key, language) => {
  const entity = api.interfaces.Entity.getFromStateById(state, entryValue);
  const value = api.interfaces.Entity.getStringValue(entity, key, { fallback: '[**]', language });
  return entity ? { entityId: entity.id, value } : null;
};

const parseEditValue = (api, node, entity, language) => {
  const value = api.interfaces.Entity.getStringValue(entity, node.data.key, { fallback: '[**]', language });
  return { entityId: entity.id, value };
};
