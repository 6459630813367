import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "antd";

import NewMainEntityItem from "./NewMainEntityItem";
import IntlMessages from "util/IntlMessages";

export default function NewMainEntityButton(props) {
  const {
    open: propsOpen,
    setOpen: propsSetOpen,
    plusButton,
    className = "",
    size = "middle",
    type,
    withLabelType,
  } = props;
  const [open, setOpen] = useState(false);

  const isLegalPerson = type === "LegalPerson";
  const intlType = isLegalPerson ? "company" : "person";
  const btnLabel = withLabelType ? (
    <>
      <IntlMessages id="app.general.create" cap />{" "}
      <IntlMessages id={`general.${intlType}`} />
    </>
  ) : (
    <IntlMessages id="app.entity.createNew" />
  );

  useEffect(() => {
    if (propsOpen !== undefined) setOpen(propsOpen);
  }, [propsOpen]);

  const toggleOpen = useCallback(() => {
    if (propsSetOpen) propsSetOpen(!open);
    else setOpen(!open);
  }, [open, propsSetOpen]);

  const buttonClassName = className || "m-0 ml-2";

  return (
    <React.Fragment>
      {plusButton ? (
        <Button
          size={size}
          onClick={toggleOpen}
          className={"ml-auto " + buttonClassName}
          type={"primary"}
        >
          <i className="mdi mdi-plus" />
          {props.label || ""}
        </Button>
      ) : (
        <Button
          type="primary"
          icon={<i className={`mdi ${isLegalPerson ? 'mdi-city' : 'mdi-account'} mr-2`} />}
          className={buttonClassName}
          onClick={toggleOpen}
        >
          {btnLabel}
        </Button>
      )}
      <Modal
        title={
          <div>
            <div>
              <IntlMessages
                id={
                  isLegalPerson
                    ? "app.entity.createCompany"
                    : "app.entity.createPerson"
                }
              />
            </div>
          </div>
        }
        visible={open}
        backdrop={"static"}
        onOk={toggleOpen}
        onCancel={toggleOpen}
        /* footer={[
          <Button key="back" onClick={toggleOpen}>
            <IntlMessages id="desc.Cancel" />
          </Button>,
        ]} */
        footer={null}
        size={"large"}
        className="modal-medium"
      >
        <div className="creation-modal-body">
          <div>
            {open && (
              <div>
                <NewMainEntityItem
                  entityWasCreated={props.entityWasCreated}
                  close={toggleOpen}
                  type={type}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
