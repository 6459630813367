import { useMemo } from 'react';
import { Form, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { PERSON_RELATION_CAPACITIES } from 'core/config/constants';
import { Entity } from 'core/interfaces';

const { Option } = Select;

export const Capacities = ({
  additionalRequiredFields,
  skipFields,
  referringEntity,
  relationType,
  excludedFromSearch,
  ...rest
}) => {
  const formatMessage = useIntlMessage();
  const availableCapacityEntries = useMemo(
    () =>
      PERSON_RELATION_CAPACITIES.map((capacity) => {
        return {
          id: capacity,
          value: formatMessage(`app.persons.capacities.${capacity}`),
        };
      }),
    [formatMessage]
  );
  return (
    <Form.Item
      label={<IntlMessages id="app.persons.capacities" />}
      name="capacities"
      tooltip={{
        title: <IntlMessages id="app.persons.capacitiesTooltip" />,
        icon: <InfoCircleOutlined />,
      }}
      extra={
        <>
          <IntlMessages id={`app.entity.as${relationType}For`} /> {Entity.name(referringEntity)}
        </>
      }
      rules={[
        {
          required: additionalRequiredFields && additionalRequiredFields.includes('capacities'),
          message: <IntlMessages id="app.persons.validation.capacities" />,
        },
      ]}
      {...rest}
    >
      <Select mode="multiple">
        {availableCapacityEntries.map((cc) => (
          <Option key={cc.id} value={cc.id}>
            {cc.value}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
