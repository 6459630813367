import React, { PureComponent } from "react";
import { Row, Col, Radio } from "antd";
const RadioGroup = Radio.Group;

const structureEligibleJurisdictions = ["se"];

class RadioSelect extends React.PureComponent {
  render() {
    if (this.props.full) return this.renderFull();

    return <SingleRadio {...this.props} />;
  }

  renderFull = () => {
    const { radio, dark, language, settersValue, settersChange } = this.props;

    // if (!Array.isArray(radios)) return null;

    return (
      <Row className="mt-2" key={radio.id}>
        <Col span={10}>
          <span
            style={{ fontSize: "12px" }}
            className={dark ? "text-white" : ""}
          >
            {radio.label[language]}
            {radio.required && "*"}
          </span>
        </Col>
        <Col span={12}>
          <SingleRadio
            radio={radio}
            id={radio.id}
            language={language}
            settersValue={settersValue}
            settersChange={settersChange}
            dark={dark}
          />
        </Col>
      </Row>
    )
  };
}

export default RadioSelect;

class SingleRadio extends PureComponent {
  onRadioBtnClick(id, value) {
    this.props.settersChange(id, value);
  }

  setValue = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    this.props.settersChange(this.props.id, evt.target.value)
  }

  render() {
    const { id, radio, language, settersValue } = this.props;

    // const active = settersValue && settersValue[id] ? settersValue[id] : false;

    let disabled = radio.disabled || false;
    if (
      id === "fetchGroupStructure" &&
      !structureEligibleJurisdictions.includes(settersValue.jurisdiction)
    )
      disabled = true;

    return (
      <div>
        <div>
          {radio.options ? (
            <RadioGroup onChange={this.setValue} value={settersValue[id]}>
              {radio.options.map(option => (
                <Radio
                  key={"rbtn-option-" + option.id}
                  disabled={disabled || option.disabled}
                  value={option.id}
                >
                  {option.label[language]}
                </Radio>
              ))}
            </RadioGroup>
          ) : null}
          {radio.info && radio.info.en ? (
            <small className="muted d-block">{radio.info.en}</small>
          ) : null}
        </div>
      </div>
    );
  }
}
