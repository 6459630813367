import React from "react"
import api from "utils/api"
import IntlMessages from "util/IntlMessages";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.errorHandled = false;
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (!this.errorHandled) {
      api.log('error', 'Error boundary catch', error, 'ErrorBoundary', { errorInfo });
      this.errorHandled = true;
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="p-4 m-4">
          <h1 className="m-4">
            <IntlMessages id="errors.boundary.header" />
          </h1>
          <h5 className="m-4">
            <IntlMessages id="errors.boundary.description" />
          </h5>
          <h3 className="m-4">
            <IntlMessages id="errors.boundary.tryAgain" />
          </h3>
          <h3 className="m-4">
            <a href="/main">
              <IntlMessages id="errors.boundary.link" />
            </a>
          </h3>
        </div>
      );
    }

    return this.props.children; 
  }
}
