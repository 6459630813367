export const conceptOnAnySlate = {
  id: 'conceptOnAnySlate',
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true, ordinary: true },
  match: ({ node }) => {
    return isValidFieldConcept(node);
  },
  handler: function ({ state, handlerInvoked, api, matches }) {
    const concepts = api.interfaces.Contract.getConcepts(this.contract);
    if (!concepts || concepts.length === 0) return;

    if (!this._tmpDraft.allAffectedACP) return;

    const affectedConcepts =
      handlerInvoked.ordinary || handlerInvoked.repeatableChange
        ? this._tmpDraft.allAffectedACP.changesAffectingDetailedFieldMapping
        : this._tmpDraft.allAffectedACP.changesInConceptNumber;

    if (affectedConcepts.length === 0) return;

    for (const { node, parents, nodePathRef, actions } of matches.default) {
      const { key } = node.data;

      const [, id] = api.interfaces.Concept.handleKey(key);

      // Only apply if concept is affected by the change
      if (!affectedConcepts.includes(id)) continue;

      const { text } = api.interfaces.Concept.describe(key, state.input, this.language, {
        contract: this.contract,
        allowDefinition: true,
      });

      const shallBeCap = api.utils.engine.shallBeCapitalised(this.editor, nodePathRef.current);
      let value = shallBeCap ? api.utils.general.ucfirst(text) : text;

      if (typeof value !== 'string') {
        console.log('Concept value is not string ', value);
        continue;
      }
      actions.replaceText(value, { setValue: true });
    }
  },
};

function isValidFieldConcept(node) {
  if (node.type !== 'field' || node.variant !== 'concept' || !node.data || !node.data.key) return false;
  return true;
}
