import applyJsonLogic from '../../../utils/logic/applyLogic';
import { Contract } from '../../../interfaces';

export const applyLogic = function (rule, data) {
  const newData = {
    ...(data || {}),
  };

  return applyJsonLogic(rule, this.states.current, {
    _meta: {
      create: { setup: Contract.getSetup(this.contract) },
      contract: this.contract,
      ruleValues: this._tmpDraft && this._tmpDraft.currentRules,
    },
    data: newData,
    debug: false,
  });
};
