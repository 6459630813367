import { memo } from 'react';
import FileList from 'components/File/FileList';

const DocumentFiles = memo(function DocumentFiles({ doc, initialFiles, updateLength }) {
  return (
    <div>
      <FileList
        resourceId={doc.id}
        resourceType="Document"
        apiRoute="documents"
        tableType="normal"
        initialFiles={initialFiles}
        updateLength={updateLength}
        hideCard={false}
        hideNewUpload={true}
      />
    </div>
  );
});

export default DocumentFiles;
