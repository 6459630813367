import { Editor, Transforms, Path } from 'slate';
import { isBlock } from 'core/types/elements';
import { createElement, insertBlockByPath } from '../../legal/helpers/blocks';

export const AVAILABLE_CMDS = [
  {
    key: 'paragraph',
    desc: 'Insert paragraph',
    condition: (editor, elements) => {
      console.log('Check para', elements);
      const [lastElement] = elements[elements.length - 1];
      if (['paragraph', 'list_item'].includes(lastElement.type)) {
        console.log('Not insert para in para');
        return false;
      }
      return true;
    },
  },
  {
    key: 'clause',
    desc: 'Insert clause',
  },
  {
    key: 'break-clause',
    desc: 'Break out of clause',
    condition: (editor, elements) => {
      console.log('Check break', elements);
      const hasAncestorClause = elements.some(([element]) => element.type === 'clause');
      return hasAncestorClause;
    },
  },
  {
    key: 'numbered_list',
    desc: 'Insert numbered list',
  },
  {
    key: 'bulleted_list',
    desc: 'Insert bulleted list',
  },
];

function insertParagraph(editor, targetRange) {}
function insertClause(editor, targetRange) {
  const [blockTuple] = Array.from(Editor.nodes(editor, { match: isBlock, mode: 'lowest' }));
  if (!blockTuple) return console.log('No blockTuple for insert clause');
  const [currentElement, currentPath] = blockTuple;
  console.log('Current elementtype', currentElement.type);
  switch (currentElement.type) {
    case 'list_item':
      Transforms.select(editor, targetRange);
      Transforms.delete(editor);
      insertBlockByPath(editor, currentPath, 'clause', {
        mode: 'within',
        direction: 'above',
      });
      break;
    case 'paragraph':
      const newClause = createElement('clause');
      if (!newClause) return;
      Transforms.select(editor, targetRange);
      Transforms.delete(editor);
      Transforms.wrapNodes(editor, newClause);
      break;
    default:
      break;
  }
  // const elements = Array.from(Editor.nodes(editor)).filter(([element]) => isBlock(element)) || [];

  console.log('Insert clause', blockTuple);

  console.log('Insert at . . . ', { anpath: editor.selection.anchor.path, blockTuple });

  /* insertBlockByPath(editor, path, 'clause', {
        mode: 'within',
        direction: 'above',
      }); */
}
function breakClause(editor, targetRange) {
  const [blockTuple] = Array.from(
    Editor.nodes(editor, { match: (e) => e.type === 'clause', mode: 'lowest' })
  );
  if (!blockTuple) return console.log('No blockTuple for breack clause');

  console.log('Break at ', { targetRange, blockTuple });

  const [clauseElement, clausePath] = blockTuple;
  const { length } = clauseElement.children;
  if (length === 1) {
    // Only one child. Unwrap.
    Transforms.select(editor, targetRange);
    Transforms.delete(editor);
    Transforms.unwrapNodes(editor, { at: clausePath });
  } else if (length > 1) {
    // Several children. Move this particular element after the clause.
    const relevantPath = targetRange?.anchor?.path;
    if (!relevantPath) return console.log('No relevant path');
    const relevantParentPath = relevantPath.slice(0, clausePath.length + 1);
    const targetPath = Path.next(clausePath);

    // Before moving, select and remove command text.
    Transforms.select(editor, targetRange);
    Transforms.delete(editor);

    Transforms.moveNodes(editor, { at: relevantParentPath, to: targetPath });
    console.log('Move ', relevantParentPath, 'to ', targetPath);
  }
}
function insertNumberedList(editor, targetRange) {}
function insertBulletedList(editor, targetRange) {}

export function executeCommand(editor, cmd, targetRange) {
  const { key } = cmd;
  console.log('cmd key is ', key);
  switch (key) {
    case 'paragraph':
      insertParagraph(editor, targetRange);
      break;
    case 'clause':
      insertClause(editor, targetRange);
      break;
    case 'break-clause':
      console.log('Shall break clause');
      breakClause(editor, targetRange);
      break;
    case 'numbered_list':
      insertNumberedList(editor, targetRange);
      break;
    case 'bulleted_list':
      insertBulletedList(editor, targetRange);
      break;
    default:
      break;
  }
  console.log('Do command ', { editor, cmd, targetRange });
}
