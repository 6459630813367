import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useContract,
  useIsTemplateStudio,
  useHighlightContractUpdates,
  useEventState,
  useStudioMayEdit,
} from 'hooks/';
import { EditorEditable } from '../../../../components/YjsEditor/EditorEditable';
import { useSlateStatic } from 'slate-react';
import { Contract } from 'core/interfaces';

export default memo(function StudioEditor() {
  const editor = useSlateStatic();
  const contract = useContract();
  const location = useLocation();
  const isTemplate = useIsTemplateStudio();
  const mayEdit = useStudioMayEdit();
  const { highlight } = useHighlightContractUpdates({ isTemplate, disabled: !mayEdit });
  const liveUpdate = useEventState('liveUpdate', true);

  if (!contract) return <div>Nothing to preview</div>;

  const settings = Contract.getSettings(contract);

  if (location.search) {
    const search = location.search.substr(1);
    const parts = search.split('=');
    if (parts.length === 2 && (parts[0] === 'iid' || parts[0] === 'tid')) {
      setTimeout(() => {
        if (parts[0] === 'iid') editor.scrollToItemId(parts[1], { highlight: true });
        if (parts[0] === 'tid') editor.scrollToTemplateId(parts[1], { highlight: true });
      }, 250);
    }
  }

  return (
    <div className={'studio-editor-container-wrapper live-updates-' + liveUpdate.toString()}>
      <EditorEditable
        previewUpdates={!isTemplate}
        previewUpdatesText={!isTemplate}
        onScrollY={highlight}
        noHeaderCounting={settings.format && settings.format.noHeaderCounting}
        isEditingTemplate={isTemplate}
      />
    </div>
  );
});
