import React, { useState, useEffect, memo, useCallback } from 'react';
import { Row, Col, Card, Table, Spin, Tooltip, Button } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import api from 'utils/api';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { gutter } from 'config';
import WidgetHeader from 'components/WidgetHeader/index';
import StatusTag from '../../components/ui/StatusTag';
import { hasFeatureFlag } from 'utils/general';
import NewDocument from './NewDocument';
import Search from './Search';
import DownloadFiles from './DownloadFiles';
import { InitialAvatar } from 'components/ui/userAvatar';
import { useMountFetch } from 'hooks';

const PROPERTY_EXCEL_SUPPORTED = hasFeatureFlag('shb');

const { Column } = Table;

const config = {};

const Main = memo(() => {
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();
  const [projects, setProjects] = useState(null);
  const [projectsError, setProjectsError] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [documentsError, setDocumentsError] = useState(null);
  const [entities, setEntities] = useState([]);

  useMountFetch('/clients/userEntities', setEntities);

  const docType = user.isEntityUser ? 'versions' : 'documents';

  const projectWasCreated = useCallback((project) => setProjects([project, ...projects]), [projects]);
  const documentWasCreated = useCallback(
    (document) => {
      setDocuments([document, ...documents]);
      history.push('/project/' + document.projectId);
    },
    [documents]
  );

  useEffect(() => {
    api
      .get(
        '/projects?limit=5&order=desc&fields=id,userId,name,status,info,isPersonal,isFolder&where=isFolder_->_NULL-or-FALSE'
      )
      .then((result) => {
        result && result.data && setProjects(result.data.filter((project) => !project.isPersonal));
      })
      .catch((err) => setProjectsError(JSON.stringify(err.response)));

    api
      .get(`/${docType}?limit=5&order=desc&fields=id,userId,name,projectId,status`)
      .then((result) => result && result.data && setDocuments(result.data))
      .catch((err) => setDocumentsError(JSON.stringify(err.response)));
  }, []);

  const onDocumentClick = (r) => {
    if (!r.id) return history.push('/projects');
    if (user.isEntityUser) return history.push('/studio/draft/' + r.id);
    else return history.push('/project/' + r.projectId + '/document/' + r.id);
  };

  return (
    <>
      <DashboardTopbar />

      <div className="padded-page py-0">
        <Row gutter={gutter}>
          <Col span={24}>
            <Search />
          </Col>
        </Row>

        <Row gutter={gutter} className="overview-actions">
          <Col span={24}>
            <NewDocument
              buttonProps={{ type: 'secondary', size: 'lg' }}
              projectWasCreated={projectWasCreated}
              documentWasCreated={documentWasCreated}
            />
          </Col>
        </Row>

        <Row className="mt-2 pt-3" gutter={gutter}>
          <Col sm={24} md={13}>
            <div className="light-container p-5 mb-5">
              <WidgetHeader title={<IntlMessages id="app.main.latestDocuments" cap />} styleName="mb-3" />
              <ListDocuments
                documents={documents}
                documentsError={documentsError}
                entities={entities}
                onClick={onDocumentClick}
              />
            </div>
          </Col>
          <Col sm={24} md={11} className="">
            <div className="dark-container p-5 mb-5">
              <WidgetHeader title={<IntlMessages id="app.main.latestProjects" cap />} styleName="mb-3" />
              <ListProjects
                projects={projects}
                projectsError={projectsError}
                entities={entities}
                type="project"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
});

function ListProjects({ projects, projectsError, entities, type = 'project' }) {
  const history = useHistory();
  const format = useIntlMessage();

  const intTypeCap = type === 'folder' ? 'Folder' : 'Project';

  if (projectsError) {
    return (
      <Card>
        <IntlMessages id="app.main.notRetrieveLatest" /> <IntlMessages id={'general.' + type + 's'} cap />
      </Card>
    );
  }

  if (projects && projects.length === 0) {
    return (
      <div>
        <p>
          <IntlMessages id={'app.main.no' + intTypeCap + 'sCreated'} />.{' '}
          <Button className="mt-3 ml-3" onClick={() => history.push('/projects')}>
            <IntlMessages id={'app.main.createA' + type} />
          </Button>
        </p>
      </div>
    );
  }

  return (
    <div className="no-body-padding transparent-th th-fs-normal">
      {!projects && <Spin className="loader-container" />}
      {projects && (
        <Table
          rowKey="id"
          className="table-responsive documents-table"
          {...config}
          dataSource={[...projects, { id: '', name: `${format('app.general.More')}...` }]}
          pagination={false}
        >
          <Column
            title={<IntlMessages id="app.general.Name" />}
            dataIndex="name"
            render={(name, record) => (
              <span
                className="link"
                onClick={() => history.push(`/project${record.id ? '/' + record.id : 's'}`)}
              >
                {name}
              </span>
            )}
          />
          <Column
            dataIndex="userId"
            width={44}
            render={(id) => (
              <InitialAvatar ghost mode="dark" entity={entities?.find((e) => e.userId === id)} />
            )}
          />
        </Table>
      )}
    </div>
  );
}

function ListDocuments({ documents, documentsError, entities, onClick }) {
  const history = useHistory();
  const format = useIntlMessage();
  if (documentsError) {
    return (
      <Card>
        <IntlMessages id="app.main.notRetrieveLatest" /> <IntlMessages id="app.documents" />
      </Card>
    );
  }

  if (documents && documents.length === 0) {
    return (
      <div>
        <p>
          <IntlMessages id="app.main.noDocumentsCreated" />.{' '}
          {/* <Button className="mt-3" onClick={() => history.push('/projects')}>
            <IntlMessages id={'app.main.createA' + type} />
          </Button> */}
        </p>
      </div>
    );
  }

  return (
    <div className="no-body-padding w-100 transparent-th th-fs-normal">
      {!documents && <Spin className="loader-container" />}
      {documents && (
        <Table
          rowKey="id"
          className="table-responsive documents-table"
          {...config}
          dataSource={[...documents, { id: '', name: `${format('app.general.More')}...` }]}
          pagination={false}
        >
          <Column
            title={<IntlMessages id="app.general.Name" />}
            dataIndex="name"
            ellipsis={true}
            render={(name, record) => (
              <span className="link" onClick={() => onClick(record)}>
                {name}
              </span>
            )}
          />
          <Column
            title={<IntlMessages id="app.general.Status" />}
            width={100}
            dataIndex="status"
            render={(text) => (text ? <StatusTag ghost withColor status={text} /> : null)}
          />
          <Column
            dataIndex="userId"
            width={44}
            render={(id) => <InitialAvatar ghost entity={entities?.find((e) => e.userId === id)} />}
          />
        </Table>
      )}
    </div>
  );
}

const DashboardTopbar = () => {
  return (
    <div className="d-flex justify-content-space-between align-items-center mn-5">
      {PROPERTY_EXCEL_SUPPORTED && (
        <DownloadFiles
          data={[
            {
              id: 'app.main.downloadPropertyExcel',
              url: '/file_resources/Fastigheter.xlsx',
            },
          ]}
        />
      )}
      <PendingTasks />
    </div>
  );
};

const PendingTasks = () => {
  const history = useHistory();
  const pendingTasks = useSelector(({ common }) => common.numbers.pendingTasks);
  let content = null;
  if (typeof pendingTasks !== 'number') content = <span>&nbsp;</span>;
  else if (pendingTasks === 0) content = <span>Tasks all caught up</span>;
  else
    content = (
      <>
        <IntlMessages id="app.general.tasks.have" cap />{' '}
        <a className="link" onClick={() => history.push('/tasks')}>
          {pendingTasks} <IntlMessages id="app.general.tasks.tasks" />
        </a>
      </>
    );
  return <h2 className="ml-auto m-5">{content}</h2>;
};

export default Main;
