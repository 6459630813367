import React, { memo } from 'react';
import { getIsTemplateStudio } from 'hooks';
import EnumMenu from './EnumMenu';
import DateMenu from './DateMenu';
import ConceptMenu from './ConceptMenu';
import ConnectInputMenu from './ConnectInputMenu';
import AcpField from './AcpField';
export * from './TextSelect'


export const inlineHasMenu = (node) => {
  return !!inlineMenuComponent(node);
};

export const inlineMenuComponent = (node) => {
  const isTemplate = getIsTemplateStudio();
  if (!isTemplate) return null;
  if (!node?.data) return null;
  // if (node.type === "field" && node.variant === "enum") {
  //   return elements.enum;
  // }
  // if (node.type === "field" && node.variant === "concept") {
  //   return elements.concept;
  // }
  // if (node.type === "field" && node.data?.info?.type === "date") {
  //   return elements.date;
  // }
  if (node.type === 'field' && ['item', 'vari'].includes(node.variant)) {
    return elements.connect;
  }

  if (node.type === 'field' && (node.data?.acp || node.variant === 'opt')) {
    return elements.acpField;
  }

  return null;
};

const elements = {
  enum: {
    component: memo((props) => {
      return <EnumMenu {...props} />;
    }),
  },
  concept: {
    component: memo((props) => {
      return <ConceptMenu {...props} />;
    }),
  },
  date: {
    component: memo((props) => {
      return <DateMenu {...props} />;
    }),
  },
  connect: {
    component: memo((props) => {
      return <ConnectInputMenu {...props} />;
    }),
    placement: 'top',
  },
  acpField: {
    component: memo((props) => {
      return <AcpField {...props} />;
    }),
    // placement: 'top',
  },
};
