import * as Y from 'yjs';
import { yTextToSlateElement, YjsEditor } from '@slate-yjs/core';
import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStudioAccessLevel, useTrackChanges } from 'hooks';
import {
  createYjsEditor,
  createUserEditor,
  setTrackChanges,
  trackedChangeToConversation,
  createAwarenessUserData,
} from '../utils';
import { createProvider } from '../common';

export const useConnectedEditor = ({
  onlineMode = true,
  editor: providedEditor,
  setValue,
  versionId,
  withDefaultUserData = true,
}) => {
  const isSyncedRef = useRef(false);
  const isMounted = useRef(false);

  const dispatch = useDispatch();
  const [connected, setConnectedState] = useState(false);
  const [providerAuthStatus, setProviderAuthStatusState] = useState('NOT_AUTHED');
  const [onlineLoaded, setOnlineLoaded] = useState(false);
  const accessLevel = useStudioAccessLevel();
  const user = useSelector((state) => state.auth?.user || {});
  const [initialSnapshot, setInitialSnapshop] = useState(null);
  const permanentUserDataRef = useRef(null);

  const userData = useMemo(
    () => createAwarenessUserData(onlineMode, user, accessLevel),
    [onlineMode, user, accessLevel]
  );

  const setConnected = useCallback((value) => {
    if (!isMounted.current) return false;
    setConnectedState(value);
  }, []);

  const setProviderAuthStatus = useCallback((value) => {
    if (!isMounted.current) return false;
    setProviderAuthStatusState(value);
  }, []);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const provider = useMemo(
    () =>
      onlineMode ? createProvider(`content_${versionId}`, null, setConnected, setProviderAuthStatus) : null,
    [onlineMode, versionId, setConnected, setProviderAuthStatus]
  );

  useEffect(() => {
    isSyncedRef.current = false;
    setOnlineLoaded(false);
  }, [provider]);

  const editor = useMemo(
    () => {
      if (!onlineMode) return createUserEditor(providedEditor);
      const newEditor = createYjsEditor(provider, userData, providedEditor);
      return newEditor;
    },
    // Ignore updates of `userDate` (handled by awareness).
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onlineMode, providedEditor, provider]
  );

  useEffect(() => {
    if (!onlineMode) return;
    if (!provider) return;
    if (!window.xx) window.xx = {};
    window.xx.provider = provider;
    provider.connect();
    provider.on('synced', (data) => {
      if (data?.state !== true) return;
      editor._disableNormalizing = false;
      /* const _sharedType = provider.document.get('content', Y.XmlText);
      const slateContents = yTextToSlateElement(_sharedType).children;
      if (slateContents && slateContents.length) {
        editor.children = slateContents;
        setValue(slateContents);
      } */
      const ydoc = provider.document;
      const permanentUserData = new Y.PermanentUserData(ydoc);
      permanentUserDataRef.current = permanentUserData;
      ydoc.gc = false;
      permanentUserData.setUserMapping(ydoc, ydoc.clientID, user.entityId);
      setInitialSnapshop(Y.snapshot(provider.document));
      isSyncedRef.current = true;
      setOnlineLoaded(true);
    });

    return () => {
      provider.awareness.destroy();
      provider.disconnect();
    };
    // Ignore updates of `editor` (which is a singleton and we're only using its 'children')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineMode, provider]);

  // Re-connect when changing accessLevel
  useEffect(() => {
    if (!isSyncedRef.current) return;
    provider.disconnect();
    setTimeout(() => {
      provider.connect();
    }, 300);
  }, [accessLevel, provider]);

  // Exported funtionality
  const setUserData = useCallback(
    (data) => provider?.awareness && provider.awareness.setLocalStateField(editor.cursorDataField, data),
    [editor, provider]
  );
  const setAwarenessItem = useCallback(
    (key, value) => provider?.awareness && provider.awareness.setLocalStateField(key, value),
    [provider]
  );
  const toggleConnection = useCallback(() => {
    if (!provider) return;
    if (connected) {
      return provider.disconnect();
    }
    provider.connect();
  }, [provider, connected]);

  // Update awareness on websocket on userData change
  useEffect(() => {
    if (!onlineMode || !userData || !withDefaultUserData) return;
    setUserData(userData);
  }, [userData, onlineMode, setUserData, withDefaultUserData]);

  /** START: EDITOR SPECIFIC FUNCTIONALITY  **/
  const { trackingEnabled } = useTrackChanges();

  // Track changes
  useEffect(() => {
    setTrackChanges(editor, trackingEnabled, user.entityId);
  }, [editor, trackingEnabled, user.entityId]);
  useEffect(() => {
    trackedChangeToConversation(editor, user.entityId, dispatch);
  }, [dispatch, editor, user.entityId]);

  // Disconnect YjsEditor on unmount in order to free up resources
  useEffect(() => {
    if (!onlineMode) return;
    if (!YjsEditor.connected(editor)) {
      YjsEditor.connect(editor);
    }
    return () => {
      // This should probably be used in production.
      // YjsEditor.disconnect(editor);
    };
  }, [editor, onlineMode]);

  /** END: EDITOR SPECIFIC FUNCTIONALITY  **/

  return {
    provider,
    editor,
    connected,
    onlineLoaded,
    toggleConnection,
    providerAuthStatus,
    setUserData,
    setAwarenessItem,
    initialSnapshot,
    permanentUserDataRef,
  };
};
