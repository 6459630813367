import React from 'react';
import { useSelector } from 'react-redux';
import { UserAvatar, userToName } from 'components/ui';

const UserProfile = () => {
  const user = useSelector((state) => state.auth.user);
  const client = useSelector(({ auth }) => auth.client);

  return (
    <div className="sidebar-profile">
      <div className="avatar-name user-name">
        {user && userToName(user)}
        {'\n'}
        {client && client.shortName}
      </div>
    </div>
  );
};

export default UserProfile;
