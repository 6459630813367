import { notification, Form, Input, Button } from 'antd';

export default function SaveAsForm({ onSubmit, onCancel }) {
  const onFinishFailed = () => {
    notification.error({
      message: 'Error',
      description: 'Please ensure the form is filled in properly',
    });
  };

  return (
    <Form
      layout="vertical"
      className="mt-4 mb-4 external-pwd-form"
      onFinish={onSubmit}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="label"
        label="Label"
        rules={[{ required: true, message: 'Please input rule label' }]}
        className="mb-2"
      >
        <Input placeholder={'Rule label'} />
      </Form.Item>
      <Form.Item name="description" label="Description" placeholder="hej" className="mb-2">
        <Input placeholder={'Rule description'} />
      </Form.Item>
      <Form.Item className="mt-4 pwd-btn">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" htmlType="submit">
          Save As
        </Button>
      </Form.Item>
    </Form>
  );
}
