import React, { memo, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Drawer, Button, Tooltip, Badge } from 'antd';
import { Editor, Text, Range } from 'slate';
import { useSlate } from 'slate-react';
import { useSelector, useDispatch } from 'react-redux';
import { useEntityData } from 'hooks';
import { setDraft } from 'appRedux/actions';
import { CloseOutlined } from '@ant-design/icons';
import { useYjsState, useStudioMayEdit } from 'hooks';
import ConversationsContent from './ConversationsContent';
import IntlMessages from 'util/IntlMessages';
import { useAddConversation } from './useAddConversation';
import Portal from '../../../../components/editor/components/Portal';
import { useConversationsUpdater, gatherEntityIds } from './utils';

const DRAWER_WIDTH = 300;

export const ConversationsButtons = memo(() => {
  return (
    <div className="">
      <ConversationsButton />
      <NewConversationsButtonContainer />
    </div>
  );
});

export const ConversationsButton = memo(
  ({ btn, tooltipPosition = 'left', withBadge = true, withTooltip = true }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const viewingConversationId = useSelector(({ draft }) => draft.viewingConversationId);
    const { numConv = 0, numConvPosts = 0, numConvUncompleted = 0 } = useSelector(({ draft }) => draft);
    const { onlineMode, onlineLoaded } = useYjsState();
    const dispatch = useDispatch();

    const toggleDrawer = useCallback(() => {
      if (drawerOpen) {
        dispatch(setDraft('viewingConversationId', null));
        setDrawerOpen(false);
      } else {
        setDrawerOpen(true);
      }
    }, [dispatch, drawerOpen]);

    useEffect(() => {
      if (viewingConversationId && !drawerOpen) setDrawerOpen(true);
    }, [drawerOpen, viewingConversationId]);

    let renderBtn;
    if (btn) {
      renderBtn = React.cloneElement(btn, {
        onClick: (e) => {
          if (React.isValidElement(btn)) {
            btn?.props.onClick?.(e);
          }
          toggleDrawer(e);
        },
      });
    } else {
      renderBtn = (
        <Button
          className={`${onlineMode && !onlineLoaded ? 'loading' : ''}`}
          shape="circle"
          type="primary"
          size="large"
          icon={<i className="mdi mdi-comment" />}
          onClick={toggleDrawer}
        ></Button>
      );
    }

    if (withBadge)
      renderBtn = (
        <Badge
          count={numConvUncompleted}
          size="large"
          overflowCount={9}
          title={`${numConv} conversations. ${numConvPosts} posts`}
        >
          {renderBtn}
        </Badge>
      );
    if (withTooltip)
      renderBtn = (
        <Tooltip
          title={`${numConvUncompleted} conversations not marked completed`}
          placement={tooltipPosition}
        >
          {renderBtn}
        </Tooltip>
      );

    return (
      <div className="conversations-btn">
        {renderBtn}

        <ShowConversations
          toggleDrawer={toggleDrawer}
          drawerOpen={drawerOpen}
          viewingConversationId={viewingConversationId}
        />
      </div>
    );
  }
);

function NewConversationsButtonContainer() {
  const mayEdit = useStudioMayEdit();
  if (!mayEdit) return null;
  return <NewConversationsButton />;
}

function getSelectionInfo(editor) {
  const { selection } = editor;
  if (!selection) return [];
  if (Range.isExpanded(selection) && Editor.string(editor, selection) !== '') {
    return ['expanded'];
  }
  if (Range.isCollapsed(selection)) {
    const [conversationTuple] = Editor.nodes(editor, {
      match: (n) => Text.isText(n) && (n._insertedBy?.id || n._deletedBy?.id),
    });
    if (conversationTuple) {
      const [node] = conversationTuple;
      return ['comment', (node._deletedBy || node._insertedBy).id];
    }
  }
  return [];
}

const NewConversationsButton = memo(() => {
  const dispatch = useDispatch();
  const ref = useRef();
  const idRef = useRef();
  const editor = useSlate();
  const [selectionType, setSelectionType] = useState('');

  const clear = () => {
    const el = ref.current;
    if (el) return;
    el.removeAttribute('style');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;
    if (!el) {
      return;
    }

    if (!selection) return clear();

    const [type, id] = getSelectionInfo(editor);

    setSelectionType(type);
    if (id) {
      idRef.current = id;
    } else {
      idRef.current = '';
    }

    if (!type) {
      return clear();
    }

    const domSelection = window.getSelection();
    const editorElem = document.getElementById('editing-editor');
    const domRange = domSelection.getRangeAt(0);
    const htmlElem = document.querySelector('html');
    if (!editorElem || !domRange) return;
    const rangeRect = domRange.getBoundingClientRect();
    const editorRect = editorElem.getBoundingClientRect();
    const { scrollTop } = htmlElem;
    el.style.opacity = '1';
    el.style.top = `${rangeRect.top + scrollTop}px`;
    el.style.left = `${editorRect.left - 10}px`;
  });

  const [btn] = useAddConversation({
    conversationClass: 'new-conversation-box',
    button: {
      className: 'new-conversations-btn',
      shape: 'circle',
      type: 'primary',
      size: 'middle',
      icon: <i className="mdi mdi-comment-plus-outline" />,
    },
  });

  const gotoConversation = useCallback(() => {
    if (!idRef.current) return console.log('no id');
    dispatch(setDraft('viewingConversationId', idRef.current));
  }, [dispatch]);

  let component;
  if (selectionType === 'expanded') component = btn;
  else if (selectionType === 'comment')
    component = (
      <Tooltip title={<IntlMessages id="studio.conversations.tooltipInline" />} placement="left">
        <Button
          type="warning"
          shape="circle"
          onMouseDown={gotoConversation}
          icon={<i className="mdi mdi-comment" />}
        ></Button>
      </Tooltip>
    );
  else component = null;

  return (
    <Portal>
      <div ref={ref} className={'new-conversation-holder'}>
        {component}
      </div>
    </Portal>
  );
});

function completedStatus(conversations) {
  const values = Object.values(conversations);
  return [values.length, values.filter((c) => !!c.completed).length];
}

const ShowConversations = memo(({ toggleDrawer, drawerOpen, viewingConversationId }) => {
  const entityDataRef = useRef();
  const conversations = useSelector(({ conversations }) => conversations);
  const entityIds = gatherEntityIds(conversations);
  const entityData = useEntityData(entityIds);
  useConversationsUpdater(conversations, entityData);
  useEffect(() => {
    entityDataRef.current = entityData;
  }, [entityData]);
  const [totalLength, doneLength] = completedStatus(conversations);

  return (
    <div>
      <Drawer
        title={
          <div className="">
            <IntlMessages id="studio.conversations.conversations" cap /> ({doneLength}/{totalLength})
            <div className="top-btns">
              <Tooltip title="Close" placement="bottom">
                <Button className="border-0" onClick={toggleDrawer}>
                  <CloseOutlined />
                </Button>
              </Tooltip>
            </div>
          </div>
        }
        closable={false}
        mask={false}
        maskClosable={false}
        width={DRAWER_WIDTH}
        className={`conversations-holder`}
        placement={'left'}
        onClose={toggleDrawer}
        visible={drawerOpen}
      >
        <ConversationsContent viewingConversationId={viewingConversationId} entityDataRef={entityDataRef} />
      </Drawer>
    </div>
  );
});
