import { Transforms, Editor, Node, Path, Range, Point, Text, Operation, Element } from 'slate';
import { ReactEditor } from 'slate-react';
import { YjsEditor } from '@slate-yjs/core';

export const setupWindowVariables = (editor) => {
  window.xx = {
    editor,
    Transforms,
    Editor,
    Node,
    Path,
    Range,
    Point,
    Text,
    Element,
    Operation,
    YjsEditor,
    ReactEditor,
    ge: () => {
      if (!editor.selection?.anchor?.path) return;
      return Node.get(editor, editor.selection?.anchor?.path.slice(0, -1));
    },
  };
};
