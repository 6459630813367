import { all, takeEvery, select } from 'redux-saga/effects';
import { UPDATE_ENTITY, INSERT_ENTITY, REMOVE_ENTITY } from 'constants/ActionTypes';
import api from 'utils/api';

/**
 *
 * Root saga. Set up.
 *
 */

function* rootSaga() {
  yield all([takeEvery([UPDATE_ENTITY, INSERT_ENTITY, REMOVE_ENTITY], entitiesCatch)]);
}

/**
 * Entities catcher.
 *
 * @param {object} action State actions
 */
function entitiesCatch(action) {
  if (!action || !action.type) return;
  const { type, meta, payload } = action;
  if (meta && meta.noApi) return;

  switch (type) {
    case UPDATE_ENTITY:
      const { data, id } = payload;
      if (!data || !id) {
        console.warn('No data or provided to redux payload UPDATE_ENTITY', action);
        return;
      }
      console.log('Update entity to api ', action);
      api
        .put(`/entities/${id}`, data)
        .then((res) => {
          console.log('Update result', res);
        })
        .catch((err) => {
          console.log('Could not update entity within redux saga', err);
        });
      break;
    case INSERT_ENTITY:
      console.log('insert entity to api', action);
      api
        .post(`/entities`, payload)
        .then((res) => {
          console.log('Create result', res);
        })
        .catch((err) => {
          console.log('Could not create entity within redux saga', { err, response: err.response });
        });
      break;
    case REMOVE_ENTITY:
      payload.forEach((id) => {
        console.log('remove entity to api', action);
        api
          .delete(`/entities/${id}`)
          .then((res) => {
            console.log('Delete result', res);
          })
          .catch((err) => {
            console.log('Could not delete entity within redux saga', err);
          });
      });
      break;
    default:
      break;
  }
}

export default rootSaga;
