import { Button } from 'antd';

export const ActionButton = ({
  title = '',
  children,
  icon = null,
  extra = null,
  count = null,
  ...buttonProps
}) => {
  const content = title || <span>{children}</span>;

  if (!content) {
    throw new Error('ActionButton requires title or children prop');
  }

  const iconComponent = typeof icon === 'string' ? <i className={`mdi ${icon}`} /> : icon;

  return (
    <Button {...buttonProps} icon={iconComponent}>
      {content}
      {count !== null ? <div className="count">{count}</div> : null}
    </Button>
  );
};
