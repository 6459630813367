import React, { useState, useEffect } from 'react';
import { Button, Modal, Spin } from 'antd';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';
import { ContractContext } from 'contexts/contexts';
import ViewEditor from 'components/editor/views/ViewEditor';

export default function QuickDocumentView({
  documentId,
  finalVersion,
  versionId,
  setQuickViewDoc,
  title = null,
  footer = null,
  disableCloseButton = false,
  actionFields = {},
}) {
  const [contract, setContract] = useState(null);
  const [content, setContent] = useState(null);
  const [loadError, setLoadError] = useState(null);

  const modalTitle = title || 'Document View';

  useEffect(() => {
    async function init() {
      let version;

      try {
        if (documentId) {
          let endpoint;
          if (finalVersion) {
            endpoint = '/documents/' + documentId + '/final';
          } else {
            endpoint = '/documents/' + documentId + '/versions?last';
          }
          const x = await api.get(endpoint);
          version = x.data;
        }
        if (versionId) {
          const x = await api.get('/versions/' + versionId);
          version = x.data;
        }
        if (!version) return setQuickViewDoc(null);

        const contentFetch = await api.get(`/versions/${version.id}/content`);
        setContract(version);
        setContent(contentFetch.data);
      } catch (err) {
        console.log('Error fetching version data', err);
        setLoadError(true);
      }
    }
    init();
  }, [documentId, versionId, setQuickViewDoc, finalVersion]);

  const close = () => setQuickViewDoc(null);

  const renderLoadError = () => {
    return (
      <div className="text-center">
        Cannot load {documentId ? 'relevant ' : ''} version {documentId ? 'for document' : ''}.
        {documentId && finalVersion && (
          <div className="mt-2">
            It may be that the requested document went from status <strong>published</strong> to unpublished.
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      title={modalTitle}
      visible={true}
      onCancel={close}
      backdrop={'static'}
      size={'large'}
      className="modal-full"
      footer={
        <>
          {footer}
          {!disableCloseButton && (
            <Button key="closebtn" type="secondary" onClick={close}>
              <IntlMessages id="desc.Close" />
            </Button>
          )}
        </>
      }
    >
      {loadError ? (
        <div>{renderLoadError()}</div>
      ) : !contract || !content ? (
        <Spin size="large" className="spinner-center" />
      ) : (
        <ViewEditor contract={contract} mode="plain" content={content} actionFields={actionFields} />
      )}
    </Modal>
  );
}
