/* import shb_logo_web from "./shb_logo_web.png"
import shb_logo_small from "./shb_logo_small.png"
import shb_logo_medium from "./shb_logo_medium.png"
import shb_logo_large from "./shb_logo_large.png"
import shb_logo_white from "./shb_logo_white.png"

export default [
  {
    id: 'shb_logo_web',
    description: 'Handelsbanken web logo',
    pdfSize: '40%',
    image: shb_logo_web
  },
  {
    id: 'shb_logo_small',
    description: 'Handelsbanken small logo',
    pdfSize: '40%',
    image: shb_logo_small
  },
  {
    id: 'shb_logo_medium',
    pdfSize: '75%',
    description: 'Handelsbanken medium logo',
    image: shb_logo_medium
  },
  {
    id: 'shb_logo_large',
    pdfSize: '100%',
    description: 'Handelsbanken large logo',
    image: shb_logo_large
  },
  {
    id: 'shb_logo_white',
    pdfSize: '40%',
    description: 'Handelsbanken large logo (white)',
    image: shb_logo_white
  }
] */
const images = []
export default images
