import React from 'react';

import { Form, Radio } from 'antd';
import { getValue } from "./utils/values";

function InputRadio(props) {
    const {
      label,
      handleChange,
      language,
      cardId,
      cardUid,
      field,
      inputIndex,
      sourceUid,
    } = props;
  
    const value = getValue(props, field.value ? field.value : "");

    return (
      <Form.Item
      label={label}
      extra={
        field.info ? (
          <small className="muted">{field.info[language]}</small>
        ) : null
      }
    >
      <Radio.Group
        id={field.name + inputIndex + cardId + cardUid + sourceUid}
        name={field.name + inputIndex + cardId + cardUid + sourceUid}
        value={value}
        onChange={event => handleChange(event.target.value)}
      >
        {field.content.map((option, contentIndex) => (
          
            <Radio
            key={
              option.id +
              cardId +
              cardUid +
              inputIndex +
              contentIndex +
              field.name
            }
              value={
                option.id
              }
              
            >{option.label[language]}</Radio>
        ))}
      </Radio.Group>
    </Form.Item>
    )
  }
  
export default InputRadio;