const NEWLINES = ['↵', '\n', '\r', '\r\n'];

export function cssToObj(css) {
  const obj = {};
  if (!css || typeof css !== 'string') {
    return obj;
  }
  const s = css
    .toLowerCase()
    .replace(/-(.)/g, function (m, g) {
      return g.toUpperCase();
    })
    .replace(/;\s?$/g, '')
    .split(/:|;/g);
  for (var i = 0; i < s.length; i += 2) obj[s[i].replace(/\s/g, '')] = s[i + 1].replace(/^\s+|\s+$/g, '');
  return obj;
}

export const getPastedDocumentStyles = (head) => {
  const styles = Array.from(head.childNodes)
    .filter((child) => child.nodeName === 'STYLE')
    .map((child) => {
      const rawText = child.textContent;
      let text = '';
      for (let i in rawText) {
        if (!NEWLINES.includes(rawText[i])) text += rawText[i];
      }
      if (text.slice(0, 4) === '<!--') text = text.slice(4);
      if (text.slice(-3) === '-->') text = text.slice(0, -3);
      const cleansedText = text.replace(/(\/\*[^*]*\*\/)|(\/\/[^*]*)/g, '');
      return cleansedText
        .split('{')
        .map((y) => y.split('}'))
        .flat();
    })
    .flat()
    .filter((css) => !!css)
    .reduce((acc, curr, i, array) => {
      if (i % 2 === 0) {
        const value = cssToObj(array[i + 1]);
        const classNames = curr.split(',');
        for (const className of classNames) {
          acc[className.trim()] = value;
        }
      }
      return acc;
    }, {});
  return styles;
};

function getContentTypeValue(content) {
  if (content.includes('word')) return 'word';
  if (content.includes('powerpoint')) return 'powerpoint';
}

export function getContentType(html) {
  const metaTags = html.head.getElementsByTagName('meta');
  if (!metaTags) return '';
  for (const metaTag of metaTags) {
    const content = metaTag.content;
    if (!content) continue;
    const value = getContentTypeValue(content.toLocaleLowerCase());
    if (value) return value;
  }
  return '';
}
