import React from 'react';
import { Tooltip } from 'antd';
import { firstKey, conjunctions, allOperators, ruleToType } from './common';

const textAbstract = (text, length) => {
  if (!text) {
    return '';
  }
  if (text.length <= length) {
    return text;
  }
  return text.substring(0, length);
};

function DividedInputVariableName({ cardAndInputField }) {
  let card, inputField, inputFieldPart1, inputFieldPart2;
  let cardStripped = false;
  const parts = cardAndInputField.split('.');

  let isStripped = false;
  let content = [];

  if (parts.length === 1) {
    inputField = cardAndInputField;
  } else if (parts.length === 2) {
    card = parts[0];
    if (card.length > 5) {
      cardStripped = textAbstract(card, 5);
      content.push(
        <React.Fragment key={'input-card-stripped'}>
          <span className="input-card-stripped">{textAbstract(cardStripped, 5)}</span>
          <span className="stripped-dots">...</span>.
        </React.Fragment>
      );
      isStripped = true;
    } else {
      content.push(card);
    }
    inputField = parts[1];
  } else {
    // console.log('Errornous input field name: ', cardAndInputField);
    return cardAndInputField;
  }
  if (inputField) {
    const inputParts = inputField.split('/');
    if (inputParts.length === 2) {
      inputFieldPart1 = inputParts[0];
      inputFieldPart2 = inputParts[1];
      if (inputFieldPart1.length > 5) {
        content.push(
          <React.Fragment key={'input1Name'}>
            <span className="input-field1-stripped">{textAbstract(inputFieldPart1, 5)}</span>
            <span className="stripped-dots">...</span>/
          </React.Fragment>
        );
        isStripped = true;
      } else {
        content.push(inputFieldPart1);
      }
      if (inputFieldPart2.length > 5) {
        content.push(
          <React.Fragment key={'input2Name'}>
            <span className="input-field2-stripped">{textAbstract(inputFieldPart2, 5)}</span>
            <span className="stripped-dots">...</span>
          </React.Fragment>
        );
        isStripped = true;
      } else {
        content.push(inputFieldPart2);
      }
    } else {
      if (inputField.length > 8) {
        content.push(
          <React.Fragment key={'input-all-field-stripped'}>
            <span className="input-all-field-stripped">{textAbstract(inputField, 8)}</span>
            <span className="stripped-dots">...</span>
          </React.Fragment>
        );
        isStripped = true;
      } else {
        content.push(inputField);
      }
    }
  }

  if (isStripped) {
    content = <Tooltip title={cardAndInputField}>{content}</Tooltip>;
  }

  return <span className="printrule-variable rl">{content}</span>;
}

function PrintInputLocalSetup({ mode, content, operator, negated }) {
  let textValue = operator === 'in' ? stringValue(content[0]) : stringValue(content[1]);

  const cardAndInputField = operator === 'in' ? content[1][mode] : content[0][mode];

  return (
    <>
      <div className="printrule-data">
        {/* <span className="printrule-variable rl">{content[0][mode]}</span> */}
        <DividedInputVariableName cardAndInputField={cardAndInputField} />
        {negated && <span className='bg-danger'>NOT</span>}
        <span className="printrule-operator">{operator}</span>
        <span className="printrule-value rr">{textValue}</span>
      </div>
    </>
  );
}

function NumOfState({ content, operator }) {
  let textValue = stringValue(content[1]);
  return (
    <>
      <div className="printrule-data">
        <span className="printrule-text rl">Number of</span>
        <span className="printrule-variable">{content[0].numof_state}</span>
        <span className="printrule-operator">{operator}</span>
        <span className="printrule-value rr">{textValue}</span>
      </div>
    </>
  );
}

function PrintNumOf({ content, operator }) {
  const [first, second] = content;
  // const eqNum = typeof second === 'number';
  // const eqAll = !eqNum;

  let indicateAll = content[1] && typeof content[1] === 'object' && content[1].numof;

  const hasCondition = !!first.numof.condition;
  let manyConditions = false;
  if (hasCondition) {
    const firstKeyCondition = firstKey(first.numof.condition);

    manyConditions = first.numof.condition[firstKeyCondition].length > 1;
  }

  if (!first.numof || typeof first.numof !== 'object') {
    console.log('Invalid numof rule', content);
    return null;
  }

  // console.log('Numof first ', { content, first, indicateAll });

  return (
    <>
      <div className="printrule-numof-ex-cond">
        {indicateAll ? (
          <div>
            <span className="printrule-operator rl">ALL</span>
          </div>
        ) : null}
        <div>
          <span className={'printrule-card ' + (!indicateAll ? 'rl' : '')}>{first.numof.card}</span>
        </div>
        {!indicateAll && (
          <>
            <div>
              <span className="printrule-operator">{operator}</span>
            </div>
            <div>
              <span className="printrule-value">{second}</span>
            </div>
            <div>
              <span className={'printrule-text ' + (!hasCondition ? 'rr' : '')}>instances</span>
            </div>
          </>
        )}
        {hasCondition && (
          <>
            <div className={'printrule-condition-line ' + (manyConditions ? 'many' : 'solo')}></div>
            <div className={'printrule-condition-line2 ' + (manyConditions ? 'many' : 'solo')}></div>
          </>
        )}
      </div>
      {hasCondition && (
        <div>
          <span className="printrule-text pl-0 rr">where</span>
        </div>
      )}
      {first.numof.condition && <PrintNumOfCondition content={first.numof.condition} />}
    </>
  );

  // [{"numof":{"card":"facility","condition":{"and":[{"==":[{"var":"facility/type"},"rcf"]}]}}},{"numof":{"card":"facility"}}]
  // [{"numof":{"card":"facility"}},1]
}
function PrintNumOfCondition({ content }) {
  return (
    <div className="printrule-conditions">
      <PrintRule rule={content} sub />
    </div>
  );
}
function PrintSubRule({ content, operator }) {
  let textValue = stringValue(content[1]);
  return (
    <div>
      <span className="printrule-variable rl">{content[0].var}</span>
      <span className="printrule-operator">{operator}</span>
      <span className="printrule-value rr">{textValue}</span>
    </div>
  );
}

function stringValue(value) {
  let textValue = value;
  if (typeof value === 'boolean' || typeof value === 'number') textValue = value.toString();
  if (typeof value === 'string') return `"${value}"`;
  return textValue;
}

function PrintAllAny({ mode, content }) {
  const [rule] = content;
  const [card, value] = rule[mode + '_in_card'];

  let textValue = stringValue(value);

  return (
    <>
      <div>
        <span className="printrule-operator rl">{mode.toUpperCase()}</span>
        <span className="printrule-text">in</span>
        <span className="printrule-card">{card}</span>
        <span className="printrule-text">is</span>
        <span className="printrule-value rr">{textValue}</span>
      </div>
    </>
  );
}

function PrintRuleItem({ type, content, operator, negated = false }) {
  if (!type) {
    console.log('No type to PrintRuleItem', { content, operator });
    return <div>No rule data</div>;
  }
  let printedItem;
  switch (type) {
    case 'numof':
      printedItem = <PrintNumOf content={content} operator={operator} />;
      break;
    case 'input':
    case 'local':
    case 'setup':
      printedItem = (
        <PrintInputLocalSetup mode={type} content={content} operator={operator} negated={negated} />
      );
      break;
    case 'any_in_card':
      printedItem = <PrintAllAny mode="any" content={content} operator={operator} />;
      break;
    case 'all_in_card':
      printedItem = <PrintAllAny mode="all" content={content} operator={operator} />;
      break;
    case 'numof_state':
      printedItem = <NumOfState content={content} operator={operator} />;
      break;
    case 'subrule':
      printedItem = <PrintSubRule content={content} operator={operator} />;
      break;
    default:
      printedItem = null;
  }

  return <div className="printrule-container">{printedItem}</div>;
}

export default function PrintRule({ rule: inputRule, sub = false }) {
  if (!inputRule) return null;
  let rule = JSON.parse(JSON.stringify(inputRule));
  let key = firstKey(rule);

  if (!key) {
    console.log('No key for rule', key, rule);
    return <div>Invalid Rule</div>;
  }

  if (conjunctions.includes(key)) {
    if (!Array.isArray(rule[key])) {
      console.log('Expected rule[key] of conjunction to be an array', rule, key);
      return null;
    }
    const multipleRules = rule[key].length > 1;
    return (
      <div
        className={
          'printrule-conjunction ' +
          key +
          (multipleRules ? ' multiple-rules ' : '') +
          (!sub ? ' top-lvl' : '')
        }
      >
        {rule[key].map((item, index) => {
          return <PrintRule rule={item} key={index} />;
        })}
      </div>
    );
  }

  let negated = false;
  if (key === '!') {
    negated = true;
    rule = rule[key];
    key = firstKey(rule);
  }

  if (allOperators.includes(key)) {
    const ruleType = ruleToType(rule);
    return (
      <PrintRuleItem type={ruleType} content={rule[key]} operator={key} ir={inputRule} negated={negated} />
    );
  }
}
