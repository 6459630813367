import { REMOTE_APPLY_STATE } from '../../constants/ActionTypes';

export function setYjsSyncData(data) {
  return {
    type: REMOTE_APPLY_STATE,
    payload: data,
  };
}
export function selectYjsSyncData(state) {
  const { input, entities, conversations, contractData } = state;
  return { input, entities, conversations, contractData };
}
