import { SET_TASKS, UPDATE_TASK, DELETE_TASK } from 'constants/ActionTypes';
import api from 'utils/api';

export function setTasks(payload) {
  return { type: SET_TASKS, payload };
}

export const updateTask = (id, data, options = {}) => {
  const { noApi } = options;
  return async (dispatch, getState) => {
    if (noApi) {
      const tasks = getState().tasks;
      const task = tasks.find((t) => t.id === id);
      if (!task) return console.log('No such task.');
      const newTask = {
        ...task,
        ...data,
      };
      if (data.hasOwnProperty('outcomeStatus')) {
        if (data.outcomeStatus === 'pending' || data.outcomeStatus === 'uncertain') {
          newTask.status = 'pending';
        } else {
          newTask.status = 'completed';
        }
      }
      dispatch({
        type: UPDATE_TASK,
        payload: newTask,
      });
      return;
    }

    try {
      const updateResult = await api.put('/tasks/' + id, data);
      if (updateResult && updateResult.data) {
        dispatch({
          type: UPDATE_TASK,
          payload: updateResult.data,
        });
      }
    } catch (err) {
      console.log('Cannot update task outcome status', err);
    }
  };
};

export const setTaskOutcome = (id, value, options = {}) => {
  const { noApi } = options;
  return async (dispatch, getState) => {
    if (noApi) {
      const tasks = getState().tasks;
      const task = tasks.find((t) => t.id === id);
      if (!task) return console.log('No such task.');
      const newTask = {
        ...task,
        outcomeStatus: value,
      };

      if (value === 'pending' || value === 'uncertain') {
        newTask.status = 'pending';
      } else {
        newTask.status = 'completed';
      }

      dispatch({
        type: UPDATE_TASK,
        payload: newTask,
      });
      return;
    }

    try {
      const updateResult = await api.post('/tasks/' + id + '/outcomeStatus', { value });
      if (updateResult && updateResult.data) {
        dispatch({
          type: UPDATE_TASK,
          payload: updateResult.data,
        });
      }
    } catch (err) {
      console.log('Cannot update task outcome status', { err, resp: err.response });
    }
  };
};

export const deleteTask = (id, options = {}) => {
  const { noApi } = options;

  return async (dispatch, getState) => {
    if (noApi) {
      dispatch({
        type: DELETE_TASK,
        payload: id,
      });
      return;
    }

    try {
      const updateResult = await api.delete('/tasks/' + id);
      if (updateResult && updateResult.data) {
        dispatch({
          type: DELETE_TASK,
          payload: id,
        });
      }
    } catch (err) {
      console.log('Cannot update task outcome status', { err, resp: err.response });
    }
  };
};
