import { useState, useEffect, useRef } from 'react';
import { Modal, Button, Dropdown, Menu, Tabs, Spin } from 'antd';
import { useEventState, setEventState, useContract, setContract } from 'hooks';
import {
  BuildOutlined,
  SlidersFilled,
  ApiOutlined,
  SettingOutlined,
  PullRequestOutlined,
} from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import api from 'utils/api';
import EditTemplateSettings from 'components/TemplateEdit/GeneralSettings';
import { ContractRulesEditor } from 'components/RuleEditor/';

const { TabPane } = Tabs;

function ContractStructureMode() {
  const showEditorStructure = useEventState('ContractStructureModeSwitch') || false;

  const toggle = () => {
    setEventState('ContractStructureModeSwitch', !showEditorStructure);
  };

  return (
    <Menu.Item key="contract-structure-mode" icon={<BuildOutlined />} onClick={toggle}>
      <IntlMessages id={`desc.${showEditorStructure ? 'Close' : 'Open'}`} cap />
      <IntlMessages id="studio.sidebar.general.contractStructure" className="ml-1" />
    </Menu.Item>
  );
}

function TemplateAPIvalues({ toggleOpen, ...rest }) {
  return (
    <>
      <Menu.Item
        key="template-api-values"
        eventKey="template-api-values"
        icon={<ApiOutlined />}
        {...rest}
        onClick={toggleOpen}
      >
        API Values
      </Menu.Item>
    </>
  );
}

function TemplateAPIstateExample() {
  const [apiState, setApiState] = useState(null);
  const contract = useContract();
  useEffect(() => {
    api
      .post('/draft/inputValues?state=true', contract)
      .then((res) => setApiState(res.data))
      .catch((err) => console.log('err', err.response));
  }, [contract]);

  if (!apiState) return <Spin size="large" />;

  return (
    <div
      style={{
        fontSize: '9px',
        height: '85vh',
      }}
    >
      <div
        style={{
          fontSize: '9px',
          // height: '90vh'
        }}
      >
        <pre>{JSON.stringify(apiState, null, 2)}</pre>
      </div>
    </div>
  );
}

function TemplateAPIfullDraftExample() {
  const [apiState, setApiState] = useState(null);
  const contract = useContract();
  useEffect(() => {
    api
      .post('/draft/inputValues?fullDraft=true', contract)
      .then((res) => setApiState(res.data))
      .catch((err) => console.log('err', err.response));
  }, [contract]);

  if (!apiState) return <Spin size="large" />;

  return (
    <div
      style={{
        fontSize: '9px',
        height: '85vh',
      }}
    >
      <div className="mb-4 p-2 border" style={{ fontSize: '14px' }}>
        <div>
          POST request may be sent to:{' '}
          <span className="p-1 px-2" style={{ background: 'rgb(222 222 222)' }}>
            https://<b>customer</b>-api.com/external/api/v1.0/x/documents/draft
          </span>
        </div>
      </div>

      <div
        style={{
          fontSize: '9px',
          // height: '90vh'
        }}
      >
        <pre>{JSON.stringify(apiState, null, 2)}</pre>
      </div>
    </div>
  );
}

function TemplateSettings({ toggleSettingsModalOpen, getContractRef, ...rest }) {
  return (
    <>
      <Menu.Item
        key="template-settings"
        eventKey="template-settings"
        icon={<SettingOutlined />}
        {...rest}
        onClick={toggleSettingsModalOpen}
      >
        <IntlMessages id="studio.sidebar.settings.editGeneralSettings" />
      </Menu.Item>
    </>
  );
}

export function TemplateSettingsMenu() {
  const contract = useContract();
  const [apiModalOpen, setApiModalOpen] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [editRulesOpen, setEditRulesOpen] = useState(false);
  const getContractRef = useRef(null);

  const toggleApiModalOpen = () => {
    setApiModalOpen(!apiModalOpen);
  };
  const toggleSettingsModalOpen = () => {
    if (settingsModalOpen) getContractRef.current = null; // Clear function.
    setSettingsModalOpen(!settingsModalOpen);
  };
  const onSettingsModalOk = () => {
    const newContract = getContractRef.current();
    setContract(newContract);
    setSettingsModalOpen(false);
  };
  const toggleEditRulesOpen = () => {
    setEditRulesOpen(!editRulesOpen);
  };

  return (
    <>
      <Dropdown
        overlay={
          <Menu>
            <ContractStructureMode key="contract-structure" />
            <TemplateAPIvalues key="api-values" toggleOpen={toggleApiModalOpen} />
            <TemplateSettings
              key="template-settings"
              toggleSettingsModalOpen={toggleSettingsModalOpen}
              getContractRef={getContractRef}
            />
            <Menu.Item
              key="template-edit-rules"
              // eventKey="template-edit-rules"
              icon={<PullRequestOutlined />}
              onClick={toggleEditRulesOpen}
            >
              <IntlMessages id="studio.sidebar.fields.editRules" />
            </Menu.Item>
          </Menu>
        }
      >
        <Button icon={<SlidersFilled />} />
      </Dropdown>

      <Modal
        title={'API Values'}
        visible={apiModalOpen}
        onCancel={toggleApiModalOpen}
        onOk={toggleApiModalOpen}
        // okText={<IntlMessages id="desc.Save" />}
        width={'85vw'}
        maskClosable={false}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="State Example" key="1">
            <TemplateAPIstateExample />
          </TabPane>
          <TabPane tab="Full API Draft Example" key="2">
            <TemplateAPIfullDraftExample />
          </TabPane>
        </Tabs>
      </Modal>
      <Modal
        title={<IntlMessages id="studio.sidebar.settings.editGeneralSettings" />}
        visible={settingsModalOpen}
        onCancel={toggleSettingsModalOpen}
        onOk={onSettingsModalOk}
        okText={<IntlMessages id="desc.Save" />}
        width={'85vw'}
        maskClosable={false}
      >
        <EditTemplateSettings getContractRef={getContractRef} />
      </Modal>
      {editRulesOpen && <ContractRulesEditor contract={contract} onClose={() => setEditRulesOpen(false)} />}
    </>
  );
}
