export const nodeACPwasUpdated = function (elementData, rule, state, repeatable_state = null) {
  this.log('FIX NODE ACP WAS UPDATED: ', { elementData, rule, state, repeatable_state });

  const itemId = elementData.item_id;
  let currentlyInActive;

  const newRulePassed = this.applyLogic(rule, { local: repeatable_state });
  const shallBeInActive = !newRulePassed;

  // SLATE_ENGINE_FIX
  console.log('Fix ACP was updated.');

  return {
    isActive: newRulePassed,
    changed: currentlyInActive !== shallBeInActive,
  };
};
