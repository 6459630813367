import React, { useState, useEffect, useReducer, useMemo, useCallback } from 'react';
import {
  Card,
  Icon,
  Table,
  Spin,
  Dropdown,
  Menu,
  Popconfirm,
  Tooltip,
  Button,
  Checkbox,
  Divider,
  Collapse,
} from 'antd';
import produce from 'immer';
import { useSelector } from 'react-redux';
import api from 'utils/api';
import { Node } from 'slate';
import { setModalContext, useFetch } from 'hooks';
import { GotoDocumentViewSpan, GotoVersionViewSpan } from 'components/DocumentsList/index';
import { isList } from 'core/types/elements';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { RemoveModal, UserAvatar, fixDate, userToName } from 'components/ui';
import { LinkOutlined, InfoCircleOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { uuid } from '../../../core/utils/general';
import { find } from '../../../core/engine/utils/';

const { Panel } = Collapse;

function allSiblingsChecked(siblings, currentIndex, currentValue) {
  if (currentValue === false) return false;
  for (let i = 0; i < siblings.length; i++) {
    if (currentIndex !== undefined && i === currentIndex) {
      continue;
    }
    if (!siblings[i].data || !siblings[i].data.checked) return false;
  }
  return true;
}

function allChecklistChecked(checklist) {
  for (const item of checklist.children) {
    if (isList(item)) {
      if (!allChecklistChecked(item)) {
        return false;
      }
      continue;
    } else if (!item.data || !item.data.checked) {
      return false;
    }
  }
  return true;
}

function ChecklistItem({
  item,
  level,
  onChangeChecked,
  path,
  projectId,
  documentId,
  previousItem,
  nextItem,
  index,
  siblings,
  parentListItem,
  checklistState,
  checklistDispatch,
  user,
  checklistCompletedItems,
  setItemFiles,
}) {
  const formatMessage = useIntlMessage();
  const [files, setFiles] = useState((item.data && item.data.files) || []);
  const marginLeft = `${level * 30}px`;

  const { item_id } = item.data || {};
  const checked2 =
    checklistState[item_id] &&
    checklistState[item_id].me &&
    checklistState[item_id].me.opinionStatus === 'fulfilled';

  const myState = checklistState[item_id] || {};
  const { me } = myState || {};
  const { contractClauseStatusId } = me || {};

  const itemCompleted = checklistCompletedItems[item_id] || false;

  // console.log('item ', { item_id, checked2, item, checklistStateItem: checklistState[item_id] });
  const toggle = () => {
    /* const newValue = !checked;

    const changes = [];

    if (parentListItem) {
      const parentPath = path.slice(0, -1);
      parentPath[parentPath.length - 1] -= 1;
      const parentCheckValue = allSiblingsChecked(siblings, index, newValue);

      changes.push({
        path: parentPath,
        data: {
          checked: parentCheckValue,
        },
      });
    }
    if (nextItem && isList(nextItem) && newValue) {
      const nextPath = [...path];
      nextPath[nextPath.length - 1] += 1;
      for (let i = 0; i < nextItem.children.length; i++) {
        changes.push({
          path: [...nextPath, i],
          data: {
            checked: true,
          },
        });
      }
    }
    changes.push({
      path,
      data: {
        checked: newValue,
      },
    }); */

    const { item_id } = item.data || {};
    if (!item_id) return console.log('No item id for item.', item);

    const newValue2 = !checked2;

    onChangeChecked([{ itemId: item_id, data: { checked: newValue2 }, contractClauseStatusId }]);
  };

  const afterUploadFile = (uploadedFiles) => {
    const newFiles = [...files, ...uploadedFiles];
    setFiles(newFiles);
    setItemFiles({
      itemId: item_id,
      files: newFiles,
    });
  };

  const onDisconnectFile = (removeFile) => {
    const newFiles = (files || []).filter(
      (file) =>
        !(
          file.fileId === removeFile.fileId &&
          file.resourceId === removeFile.resourceId &&
          file.resourceType === removeFile.resourceType
        )
    );
    setFiles(newFiles);
    setItemFiles({
      itemId: item_id,
      files: newFiles,
    });
  };

  const uploadFile = () => {
    setModalContext({
      type: 'file-upload',
      projectId: projectId,
      documentId: documentId,
      nodeType: item.type,
      connectedFiles: files || [],
      onConnectFiles: afterUploadFile,
    });
  };

  const viewFiles = () => {
    setModalContext({
      type: 'files-connected',
      title: formatMessage('app.file.connectedFiles'),
      resourceId: documentId,
      files,
      onDisconnectFile,
    });
  };

  if (isList(item)) {
    const checklistLength = item.children.length;
    return (
      <>
        {item.children.map((child, index) => (
          <ChecklistItem
            key={child.data.item_id}
            item={child}
            level={level + 1}
            onChangeChecked={onChangeChecked}
            path={[...path, index]}
            projectId={projectId}
            documentId={documentId}
            index={index}
            siblings={item.children}
            previousItem={index === 0 ? null : item.children[index - 1]}
            nextItem={index + 1 === checklistLength ? null : item.children[index + 1]}
            parentListItem={previousItem}
            checklistState={checklistState}
            checklistDispatch={checklistDispatch}
            user={user}
            checklistCompletedItems={checklistCompletedItems}
            setItemFiles={setItemFiles}
          />
        ))}
      </>
    );
  }

  const string = Node.string(item);
  const filesLength = files.length;

  return (
    <tr
      className={`checklist-item checklist-item-level-${level} ${
        level ? ' checklist-item-child' : ''
      } checked-${!!checked2}`}
    >
      <td style={{ verticalAlign: 'top', width: '150px' }}>
        <div className="d-flex">
          <Tooltip title="Connect file" placement="top">
            <Button className="m-0 mr-1" style={{ height: '34px' }} onClick={uploadFile}>
              <LinkOutlined />
            </Button>
          </Tooltip>

          {filesLength ? (
            <Tooltip title="View files" placement="top">
              <Button className="m-0" style={{ height: '34px' }} onClick={viewFiles}>
                {filesLength} file(s)
              </Button>
            </Tooltip>
          ) : (
            <div className="d-flex justify-content-center align-items-center px-3 text-muted">
              <small>0 files</small>
            </div>
          )}
        </div>
      </td>
      {/* <td style={{ verticalAlign: 'top', paddingTop: '10px', textAlign: 'center' }}>
        <i
          className={`fs-lg mdi mdi-${
            itemCompleted ? 'check-circle text-success' : 'close-circle text-danger'
          }`}
        />
      </td> */}
      <td style={{ verticalAlign: 'top' }} className="">
        <div className="checklist-content d-flex mb-2" style={{ marginLeft }}>
          <Checkbox className="boxed-checkbox green" checked={checked2} onChange={toggle}>
            {string}
          </Checkbox>
        </div>
      </td>
      <td style={{ verticalAlign: 'top' }}>
        <div className="d-flex align-items-center ml-1">
          {checklistState[item_id] && (
            <ListOpinions item={item} opinions={checklistState[item_id]} user={user} />
          )}
          {/* {item.data._checkUpdated && item.data._checkUpdated.user && (
            <div className="flex-column align-items-center">
              <UserAvatar
                user={item.data._checkUpdated.user}
                withTooltip
                tooltipExtra={<small>{fixDate(item.data._checkUpdated.at)}</small>}
              />
            </div>
          )} */}
        </div>
      </td>
    </tr>
  );
}

function ClauseStatusIcon({ status }) {
  let icon, color;
  switch (status) {
    case 'fulfilled':
      icon = 'mdi-checkbox-marked-circle';
      break;
    case 'failed':
      icon = 'mdi-close-circle-outline';
      break;
    case 'pending':
      icon = 'mdi-checkbox-multiple-blank-circle-outline';
      break;
    case 'uncertain':
      icon = 'mdi-network-question';
      break;
    case 'waived':
      icon = 'mdi-checkbox-multiple-marked-circle';
      break;
    default:
      icon = 'mdi-octagon';
      break;
  }

  return (
    <Tooltip title={status} placement="top">
      <i className={`mdi ${icon} clause-status-${status}`} />
    </Tooltip>
  );
}

function ListOpinions({ item, user, opinions }) {
  const entityIdsStrings = opinions.all.map((opinion) => opinion.entityId).join('.');
  const [users, setUsers] = useState({});

  useEffect(() => {
    if (entityIdsStrings === user.id) {
      // console.log('Only me. No fetch.');
      // return;
    }
    if (!entityIdsStrings) {
      return;
    }
    async function fetch() {
      const ids = entityIdsStrings.split('.');
      if (ids.length === 0) return;
      try {
        const queryIds = encodeURI(JSON.stringify(ids));

        const users = await api.get(`/clients/userEntities?userIds=${queryIds}&fields=id,firstName,lastName`);
        if (users && users.data) {
          setUsers(
            users.data.reduce((acc, curr) => {
              acc[curr.id] = {
                id: curr.id,
                firstName: curr.firstName,
                lastName: curr.lastName,
              };
              return acc;
            }, {})
          );
        }
      } catch (err) {
        console.log('Fetch users err', err);
      }
    }
    fetch();
  }, [entityIdsStrings, user.id]);

  const handleMenuClick = () => console.log('menu click');

  const others = opinions.all.filter((opinion) => opinion.entityId !== user.id);
  const { me } = opinions;

  const menu = (
    <Menu onClick={handleMenuClick} className="menu-clause-status">
      <table border={0}>
        <tbody>
          {others.map((opinion) => (
            <tr key={opinion.id}>
              <td>
                <UserOutlined />
              </td>
              <td>
                <span>{users[opinion.entityId] && userToName(users[opinion.entityId])}:</span>
              </td>
              <td>
                <ClauseStatusIcon status={opinion.opinionStatus} />
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <UserOutlined />
            </td>
            <td>
              <span>{userToName(user)}:</span>
            </td>
            <td>
              <ClauseStatusIcon status={me.opinionStatus} />
            </td>
          </tr>
        </tbody>
      </table>
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menu}>
        <Button>
          <InfoCircleOutlined /> <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
}

function generateOpinions(statuses, user) {
  const values = {};
  for (const status of statuses) {
    if (!status.opinions) values[status.itemId] = { all: [], me: {} };
    else {
      values[status.itemId] = {
        all: status.opinions,
        me: status.opinions.find((op) => op.entityType === 'Entity' && op.entityId === user.entityId) || {
          id: uuid(),
          opinionStatus: 'pending',
          contractClauseStatusId: status.id,
          entityType: 'Entity',
          entityId: user.entityId,
          data: {},
          clientId: user.clientId,
          userId: user.id,
        },
      };
    }
  }
  return values;
}

function checklistReducer(state, action) {
  if (action.type === 'setAll') {
    return { ...action.payload };
  }

  const stateItem = state[action.itemId];
  const { me } = stateItem;

  return {
    ...state,
    [action.itemId]: {
      ...stateItem,
      me: {
        ...me,
        opinionStatus: action.payload,
      },
    },
  };
}

function RenderChecklist({ data, checklist, projectId, documentId, id, setChecklistIsComplete, user }) {
  const [checklistCompletedItems, setChecklistCompletedItems] = useState({});
  const [checklistState, checklistDispatch] = useReducer(checklistReducer, {});
  const [currentChecklist, setCurrentChecklist] = useState(checklist);

  const fetchChecklistDone = useCallback(async () => {
    try {
      const result = await api.get(
        `/contractclausestatus/done?resourceType=Checklist&resourceId=${id}&mapPartsBy=itemId`
      );
      if (result && result.data) {
        const { done, parts } = result.data;
        // console.log('Checklist done ? ', result.data);
        if (done) {
          setChecklistIsComplete(id, true);
        } else {
          setChecklistIsComplete(id, false);
        }
        console.log('Parts are ', parts);
        setChecklistCompletedItems(parts);
      }
    } catch (err) {
      console.log('Error fetching checklist complete', err);
    }
  }, [id]);

  useEffect(() => {
    fetchChecklistDone();
  }, [fetchChecklistDone]);

  useEffect(() => {
    if (!id || !user) return;
    async function fetch() {
      try {
        const include = [{ model: 'ContractClauseOpinion', as: 'opinions' }];
        const includeQuery = encodeURI(JSON.stringify(include));
        const statuses = await api.get(
          `/contractclausestatus?resourceType=Checklist&resourceId=${id}&include=${includeQuery}`
        );
        if (statuses && statuses.data) {
          console.log('Statuses are...', statuses.data);
          checklistDispatch({ type: 'setAll', payload: generateOpinions(statuses.data, user) });
        }
      } catch (err) {}
    }
    fetch();
  }, [id, user]);

  const setItemFiles = ({ itemId, files }) => {
    const newChecklist = produce(currentChecklist, (draftChecklist) => {
      const item = find(draftChecklist, (n) => n.data && n.data.item_id === itemId, { mode: 'one' });
      if (item) {
        item.data.files = files;
      }
      return draftChecklist;
    });
    // Api update particular checklist
    console.log('SetItemFiles. Update checklist files.. ', { newChecklist, data, id, itemId, files });

    api
      .put(`/checklists/${id}`, {
        data: {
          ...data,
          checklist: newChecklist,
        },
      })
      .then((res) => {
        if (res.data) {
          setCurrentChecklist(newChecklist);
          // console.log('res data is ? ', res.data);
        }
      })
      .catch((err) => {
        console.log('Error updating project checklist', { err, resp: err.response });
      });
  };

  console.log('Render checklist ', {
    id,
    checklistState,
    checklistCompletedItems,
    checklist,
  });

  const onChangeChecked = async (changes) => {
    const promises = [];
    for (const change of changes) {
      const { itemId, data, contractClauseStatusId } = change;
      if (data.hasOwnProperty('checked')) {
        const status = data.checked ? 'fulfilled' : 'pending';
        console.log('Set ', contractClauseStatusId, status);
        checklistDispatch({ itemId, payload: status });
        promises.push(
          api.post('/contractclauseopinions', {
            contractClauseStatusId,
            opinionStatus: status,
          })
        );
      }
    }
    const newChecklist = produce(checklist, (draftChecklist) => {
      for (const change of changes) {
        const { itemId, data, contractClauseStatusId } = change;
        if (data.hasOwnProperty('checked')) {
          const item = find(draftChecklist, (n) => n.data && n.data.item_id === itemId, { mode: 'one' });
          if (item) {
            item.data.checked = data.checked;
          }
        }
        return draftChecklist;
      }
    });
    promises.push(
      api.put(`/checklists/${id}`, {
        data: {
          ...data,
          checklist: newChecklist,
        },
      })
    );
    console.log('Check changes ', { changes, newChecklist });
    try {
      const allDone = await Promise.all(promises);
      /* 
      const newchecklistState = {
        ...checklistState,
        ...allDone.reduce((acc, curr) => {
          const myOpinion = curr.data;
          // `itemId` and `contractClauseStatusStatus` are added by the backend
          if (!myOpinion.itemId) return acc;
          if (!myOpinion.contractClauseStatusStatus) return acc;

          const { itemId } = myOpinion;

          const me = { ...myOpinion };
          delete me.itemId;
          delete me.contractClauseStatusStatus;
          const all = (checklistState[itemId].all || []).map((op) => {
            if (op.id === myOpinion.id) return myOpinion;
            return op;
          });
          acc[itemId] = {
            me,
            all,
          };
          return acc;
        }, {}),
      }; 
      console.log('newstate 2 is ', newchecklistState);
      */
      fetchChecklistDone();
      return {
        status: 'success',
        allDone,
      };
    } catch (err) {
      console.log('Error posting opinion update', err);
      return {
        status: 'error',
        error: err,
      };
    }
  };

  const checklistLength = checklist.children.length;

  return (
    <>
      <div className="px-4">
        <table border={0}>
          <tbody>
            <tr className="border-bottom font-bold">
              <td>Files</td>
              {/*               <td>
                <span className="mr-2">Status</span>
              </td> */}
              <td>Action / Icon</td>
              <td>Opinions</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
            </tr>
            {checklist.children.map((item, index) => (
              <ChecklistItem
                key={item.data.item_id}
                item={item}
                level={0}
                onChangeChecked={onChangeChecked}
                path={[index]}
                projectId={projectId}
                documentId={documentId}
                index={index}
                previousItem={index === 0 ? null : checklist.children[index - 1]}
                nextItem={index + 1 === checklistLength ? null : checklist.children[index + 1]}
                checklistState={checklistState}
                checklistDispatch={checklistDispatch}
                user={user}
                checklistCompletedItems={checklistCompletedItems}
                setItemFiles={setItemFiles}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

function sortChecklist(a, b) {
  if (!a || !b || !a.createdAt || !b.createdAt) return 0;
  if (a.createdAt > b.createdAt) return 1;
  return -1;
}

export default function ProjectChecklists({ project }) {
  const [checklists] = useFetch(`/checklists?where=projectId_->_${project.id}`);
  if (!checklists) return <p>Loading checklists . . .</p>;
  return <ViewProjectChecklists project={project} checklists={checklists} />;
}

function ViewProjectChecklists({ project, checklists: givenChecklists }) {
  const user = useSelector((state) => state.auth.user);

  const [checklists, setChecklists] = useState(givenChecklists ? givenChecklists.sort(sortChecklist) : null);
  const [documentNames, setDocumentNames] = useState(null);
  const [versionsInfo, setVersionsInfo] = useState(null);

  const initialChecklistStatus = useMemo(() => {
    if (!Array.isArray(givenChecklists)) return {};

    return givenChecklists.reduce((acc, curr) => {
      const cl = curr.data.checklist;
      const complete = allChecklistChecked(cl);
      acc[curr.id] = complete;
      return acc;
    }, {});
  }, [givenChecklists]);

  const [checklistStatus, setChecklistStatus] = useState(initialChecklistStatus);

  useEffect(() => {
    if (!Array.isArray(checklists)) return;
    async function check() {
      const promises = [];
      for (const checklist of checklists) {
        promises.push(
          api.get(
            `/contractclausestatus/done?resourceType=Checklist&resourceId=${checklist.id}&mapPartsBy=itemId`
          )
        );
      }
      Promise.all(promises).then((responses) => {
        const newChecklistStatus = { ...initialChecklistStatus };
        for (const itemRes of responses) {
          const { done, resourceId } = itemRes.data;
          newChecklistStatus[resourceId] = done;
        }
        setChecklistStatus(newChecklistStatus);
      });
    }
    check();
  }, [initialChecklistStatus, checklists]);

  const setIsComplete = useCallback(
    (id, value) => {
      setChecklistStatus({
        ...checklistStatus,
        [id]: value,
      });
    },
    [checklistStatus]
  );

  // console.log('checklists are ', { checklists, checklistStatus });

  useEffect(() => {
    if (!checklists) return;
    const ids = [...new Set(checklists.map((cl) => cl && cl.documentId).filter((cl) => !!cl))];

    api
      .get(`/documents?ids=${JSON.stringify(ids)}&fields=name`)
      .then((res) => {
        if (res.data) {
          setDocumentNames(
            res.data.reduce((acc, curr) => {
              acc[curr.id] = curr.name;
              return acc;
            }, {})
          );
        }
      })
      .catch((err) => {
        console.log('Error fetching doc names', err);
      });

    const versionIds = [...new Set(checklists.map((cl) => cl && cl.versionId).filter((cl) => !!cl))];
    api
      .get(`/versions?ids=${JSON.stringify(versionIds)}&fields=name,info`)
      .then((res) => {
        if (res.data) {
          setVersionsInfo(
            res.data.reduce((acc, curr) => {
              acc[curr.id] = { name: curr.name, info: curr.info };
              return acc;
            }, {})
          );
        }
      })
      .catch((err) => {
        console.log('Error fetching doc names', err);
      });
  }, [checklists]);

  const deleteChecklist = (id) => {
    api
      .delete(`/checklists/${id}`)
      .then((res) => {
        setChecklists(checklists.filter((cl) => cl.id !== id));
      })
      .catch((err) => console.log('Error removing checklist ', err.response));
  };

  if (!checklists || !documentNames || !versionsInfo) {
    return (
      <div>
        <IntlMessages id="app.checklist.noChecklistData" />
      </div>
    );
  }
  // console.log('doc names ', { documentNames, checklists, versionsInfo });

  return (
    <div>
      {(!checklists || checklists.length === 0) && (
        <div>
          <IntlMessages id="app.checklist.noChecklists" />.
        </div>
      )}

      {checklists && checklists.length > 0 && (
        <Collapse
          defaultActiveKey={[]}
          // ghost
          // className="no-end-border-radius"
        >
          {checklists.map((checklist) => {
            const { documentId, versionId } = checklist;
            return (
              <Panel
                header={
                  <div className="d-inline-block w-90">
                    <div className="d-flex justify-content-space-between">
                      <div className="d-flex align-items-center">
                        {checklistStatus[checklist.id] && (
                          <span className={'success-mark mr-2'}>
                            {<i className="mdi mdi-check-circle-outline" />}
                          </span>
                        )}
                        <span>{checklist.data.checklist.data.checklist.name}</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <Tooltip
                          title={
                            <>
                              <IntlMessages id="app.general.GoTo" />{' '}
                              <IntlMessages id="app.resources.Document" />
                            </>
                          }
                        >
                          <GotoDocumentViewSpan
                            document={{ id: documentId, projectId: project.id }}
                            className="link mr-2"
                          >
                            <span className="">{documentNames[documentId]}</span>
                          </GotoDocumentViewSpan>
                        </Tooltip>
                        <Divider type="vertical" />
                        <Tooltip
                          title={
                            <>
                              <IntlMessages id="app.general.GoTo" />{' '}
                              <IntlMessages id="app.resources.Version" />
                            </>
                          }
                        >
                          <GotoVersionViewSpan
                            document={{ id: documentId, projectId: project.id }}
                            versionId={versionId}
                            className="link ml-2"
                          >
                            <span>
                              # {versionsInfo[versionId].info.versionNumber} -{' '}
                              <em>{versionsInfo[versionId].name}</em>
                            </span>
                          </GotoVersionViewSpan>
                        </Tooltip>
                      </div>
                      <div>
                        <RemoveModal
                          onConfirm={(evt) => {
                            evt.preventDefault();
                            evt.stopPropagation();
                            deleteChecklist(checklist.id);
                          }}
                          onClick={(evt) => {
                            evt.preventDefault();
                            evt.stopPropagation();
                          }}
                          onCancel={(evt) => {
                            evt.preventDefault();
                            evt.stopPropagation();
                          }}
                          confirmText={
                            <>
                              <IntlMessages id="app.general.confirmRemoval" />{' '}
                              <IntlMessages id={'app.resources.Checklist'} />?
                            </>
                          }
                        >
                          <Button type="danger" size="sm" className="m-0">
                            <div className="">
                              <span className="mr-1">
                                <IntlMessages id="desc.Remove" />
                              </span>
                              <IntlMessages id={'app.resources.Checklist'} />
                            </div>
                          </Button>
                        </RemoveModal>
                      </div>
                    </div>
                  </div>
                }
                key={checklist.id}
              >
                <div key={checklist.id}>
                  <RenderChecklist
                    projectId={project.id}
                    documentId={documentId}
                    id={checklist.id}
                    checklistDbEntry={checklist}
                    data={checklist.data}
                    checklist={checklist.data.checklist}
                    setChecklistIsComplete={setIsComplete}
                    user={user}
                  />
                </div>
              </Panel>
            );
          })}
        </Collapse>
      )}
    </div>
  );
}

/*

apig('/contractclausestatus?resourceType=Version&resourceId=1233e448-dc12-4ccb-910a-73301827591b')
apig('/contractclausestatus?resourceType=Version&resourceId=1233e448-dc12-4ccb-910a-73301827591b&where=type_->_checklist')

include = [{ model: 'ContractClauseOpinion', as: 'opinions' }];
includeQuery = encodeURI(JSON.stringify(include));
apig('/contractclausestatus?resourceType=Checklist&resourceId=935bfe57-60bf-4d97-944f-a30fe87be6ed&include='+includeQuery)

api.post('/contractclauseopinions', {
  data: {y: 1},
  contractClauseStatusId: '833a52c9-6978-4719-a077-cada4e1144ba',
  opinionStatus: 'fulfilled'

}).then(res => console.log('res',res.data)).catch(err => console.log('err', err, err.response))

api.put('/contractclauseopinions/717c8cf1-cff5-48a4-babe-fd8444e704ad', {
  data: {y: 3},

}).then(res => console.log('res',res.data)).catch(err => console.log('err', err, err.response))


apig('/contractclausestatus/done?resourceType=Checklist&resourceId=935bfe57-60bf-4d97-944f-a30fe87be6ed')
apig('/contractclausestatus/done?resourceType=Checklist&resourceId=935bfe57-60bf-4d97-944f-a30fe87be6ed&mapPartsBy=itemId')

*/
