import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Row, Col, Card, Button, Spin, notification, Input } from 'antd';
import RadioSelect from '../../newInputs/RadioSelect';
import TextSelect from '../../newInputs/TextSelect';
import api from 'utils/api';
import { useSelector } from 'react-redux';
import { Entity } from 'core/interfaces';
import SelectEntities from '../../entity/SelectEntities';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

function renderSetup(template, language, settersValue, settersChange) {
  if (!template.data.init || !Array.isArray(template.data.init.setup)) {
    return (
      <div className="text-center">
        <IntlMessages id="app.projects.noSetupRequired" />
      </div>
    );
  }
  return template.data.init.setup.map((setup) => {
    if (setup.type === 'radio') {
      return (
        <RadioSelect
          key={setup.id}
          full={true}
          language={language}
          radio={setup}
          settersValue={settersValue}
          settersChange={settersChange}
        />
      );
    }
    if (setup.type === 'text') {
      return (
        <TextSelect
          key={setup.id}
          full={true}
          language={language}
          text={setup}
          settersValue={settersValue}
          settersChange={settersChange}
        />
      );
    }
    return null;
  });
}

function NewProjectItem(props) {
  const [isSettingUp, setIsSettingUp] = useState(false);
  const { projectTemplate } = props;
  const [newValues, setNewValues] = useState(props.fixedValues || {});
  const [loadedWithAllValues, setLoadedWithAllValues] = useState(false);
  const formatMessage = useIntlMessage();
  const systemLanguage = useSelector((state) => state.settings.locale.locale);
  const proposeNameBySelectedEntity = useSelector(
    (state) =>
      state.auth &&
      state.auth.client?.clientSettings?.data?.workflowSettings?.project?.proposeNameBySelectedEntity
  );
  const setNewValue = useCallback(
    (key, val) => {
      setNewValues({
        ...newValues,
        [key]: val,
      });
    },
    [newValues]
  );

  const requiredValues = useMemo(() => {
    const required = [];
    projectTemplate.data.init.setup.reduce((acc, curr) => {
      if (!curr.required) return acc;
      acc.push(curr.id);
      return acc;
    }, required);

    return required;
  }, [projectTemplate]);

  const unfulfilledRequirements = useMemo(() => {
    const tmp = [];
    if (!newValues.__name) tmp.push('__name');
    for (const required of requiredValues) {
      if (!newValues[required]) {
        tmp.push(required);
      }
    }
    return tmp;
  }, [requiredValues, newValues]);

  useEffect(() => {
    const val = { ...props.fixedValues };

    projectTemplate.data.init.setup.reduce((acc, curr) => {
      acc[curr.id] = curr.value || '';
      return acc;
    }, val);

    if (props.selectedEntityData)
      val.__selectedEntities = props.selectedEntityData;

    setNewValues(val);
    setLoadedWithAllValues(!unfulfilledRequirements.length);
  }, [projectTemplate.data.init.setup, props.selectedEntityData]);

  const setSelectedEntities = useCallback(
    (values) => {
      const updatedValues = {
        ...newValues,
        __selectedEntities: values,
      };

      if (values.length === 1 && proposeNameBySelectedEntity) {
        const entityName = Entity.name(values[0].entity);
        updatedValues.__name = entityName;
        updatedValues.__description = projectTemplate.description;
      }
      setNewValues(updatedValues);
    },
    [newValues, setNewValues, proposeNameBySelectedEntity, formatMessage]
  );

  useEffect(() => {
    if (loadedWithAllValues && props.fixedValues)
      createProject()
  }, [loadedWithAllValues])

  const storeNewProject = useCallback(
    (data) => {
      // console.log('Store new project data ', data);
      api
        .post('/projects', data)
        .then(function (res) {
          if (res.data) {
            // console.log('Stored new project.');
            // setIsSettingUp(false);
            props.projectWasCreated(res.data);
            props.close();
          } else {
            console.log('Error in posting project, ', res);
          }
        })
        .catch((err) => console.log('post err', err, err.response));
    },
    [props]
  );

  const createProject = useCallback(() => {
    if (!projectTemplate || !projectTemplate.data) {
      return console.log('No template data.');
    }

    if (unfulfilledRequirements.length > 0) {
      // console.log("Missing ", unfulfilledRequirements.join(" / "));
      notification.error({
        message: formatMessage('app.projects.fillOutAllRequired'),
        description: (
          <div>
            <div>{formatMessage('app.projects.missingFields')}:</div>
            <ul>
              {unfulfilledRequirements.map((req, index) => {
                const templateSetupItem = projectTemplate.data.init.setup.find((x) => x.id === req);
                if (templateSetupItem && templateSetupItem.label) {
                  if (templateSetupItem.label[systemLanguage]) {
                    return <li key={req}>{templateSetupItem.label[systemLanguage]}</li>;
                  } else if (templateSetupItem.label.en) {
                    return <li key={req}>{templateSetupItem.label.en}</li>;
                  } else if (Object.keys(templateSetupItem.label).length > 0) {
                    const firstKey = Object.keys(templateSetupItem.label)[0];
                    return <li key={req}>{templateSetupItem.label[firstKey]}</li>;
                  }
                }
                return <li key={req}>{formatMessage('app.projects.missingField.' + req)}</li>;
              })}
            </ul>
          </div>
        ),
      });
      return;
    }

    const cleanSelectedEntities = newValues.__selectedEntities.map(({ entityId, withSubsidiaries }) => ({
      entityId,
      withSubsidiaries,
    }));

    const setupValues = { ...newValues };
    delete setupValues.__selectedEntities;

    const data = {
      projectTemplateId: projectTemplate.id,
      name:
        newValues.__name ||
        projectTemplate.data.init.defaultName ||
        projectTemplate.name ||
        formatMessage('app.projects.ProjectName'),
      description:
        newValues.__description ||
        projectTemplate.data.init.description ||
        formatMessage('app.projects.ProjectDesc'),
      isSecret: false,
      data: {
        ...projectTemplate.data,
        setup: setupValues,
        roles: [],
      },
      info: projectTemplate.info,
      customerId: null,
      __selectedEntities: cleanSelectedEntities,
    };

    // console.log('new proj ', { data, newValues, cleanSelectedEntities });

    setIsSettingUp(true);

    // If the project is of the 'handle-docs' type, project creates the documents automatically
    if (projectTemplate.data.projectType === 'handle-docs') {
      // Provide documentTemplatesIds directly to the main data fields.
      // Then the server will collect and store the document templates
      // for us automatically.
      data.documentTemplatesIds = projectTemplate.data.documentTemplatesIds;
    }
    storeNewProject(data);

    return;
  }, [formatMessage, newValues, projectTemplate, storeNewProject, unfulfilledRequirements, systemLanguage]);

  if (isSettingUp)
    return (
      <Card>
        <Spin className="loader-container" />
      </Card>
    );

  return (
    <div>
      <div align={'center'}>
        <i className={'mdi mdi-cube-outline'} style={{ fontSize: 48 }} />
      </div>
      <div align={'center'}>{projectTemplate.name}</div>
      <div align={'center'}>{projectTemplate.description}</div>
      <div>
        <SelectEntities
          selectedEntities={newValues.__selectedEntities}
          setSelectedEntities={setSelectedEntities}
          disabled={props.selectedEntityData}
        />
      </div>
      <div>
        <Row className="mt-2">
          <Col span={10}>
            <span style={{ fontSize: '12px' }}>
              <IntlMessages id={'app.projects.ProjectName'} />
              {'*'}
            </span>
          </Col>
          <Col span={14}>
            <Input
              onChange={(evt) => {
                setNewValue('__name', evt.target.value);
              }}
              value={newValues.__name || ''}
            />
          </Col>
        </Row>
        <Row className="mt-2">
          <Col span={10}>
            <span style={{ fontSize: '12px' }}>
              <IntlMessages id={'app.projects.ProjectDesc'} />
            </span>
          </Col>
          <Col span={14}>
            <Input
              onChange={(evt) => {
                setNewValue('__description', evt.target.value);
              }}
              value={newValues.__description || ''}
            />
          </Col>
        </Row>
      </div>
      {renderSetup(projectTemplate, 'en', newValues, setNewValue)}

      <div className="text-center">
        <Button onClick={createProject} className="btn mt-4" type="primary">
          <IntlMessages id={'app.projects.CreateProject'} />
        </Button>
      </div>
    </div>
  );
}

/*
  

  {
    type: 'text',
    id: 'name',
    label: {
      sv: 'Projektnamn',
      en: 'Project name',
    },
    value: '',
    required: true,
  },
  {
    type: 'text',
    id: 'description',
    label: {
      sv: 'Beskrivning',
      en: 'Description',
    },
    value: '',
    required: false,
  }
*/

export default NewProjectItem;
