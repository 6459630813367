import React, { useState, useCallback, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Spin,
  notification,
  Form,
  Checkbox,
  Tooltip,
} from "antd";
import api from "utils/api";
import IntlMessages, { useIntlMessage } from "util/IntlMessages";
import {
  CollectEmails,
  EntityEditFieldType,
  EntityEditFieldJurisdiction,
  EntityEditFieldFirstname,
  EntityEditFieldLastname,
  EntityEditFieldIdentificationNumber,
  EntityFieldAddressBlock,
} from "components/entity/EditEntity/components";
import { DEFAULT_ENTITY_VALUES } from "core/config/constants";
import countries from "core/config/countries.json";
import { userToName } from "components/ui";

const { useForm } = Form;

const structureEligibleJurisdictions = ["se"];

function validRegno(jurisdiction, str) {
  if (jurisdiction === "se") {
    str = str.replace(/-/g, "");
    if (str.length !== 10) return "invalid";
    if (isNaN(parseInt(str))) return "invalid";
    return "valid";
  }
  return "unknown";
}

export default function NewMainEntityItem(props) {
  const [isSettingUp, setIsSettingUp] = useState(false);
  const formatMessage = useIntlMessage();
  const isLegalPerson = props.type === "LegalPerson";

  const storeNewMainEntity = useCallback(
    (data) => {
      // console.log('Data to be sent to entities/make ', data);
      api
        .post("/entities/make", data)
        .then(function (res) {
          if (res.data) {
            console.log("Stored new main entity.", res.data);
            setIsSettingUp(false);
            if (typeof props.entityWasCreated === "function")
              props.entityWasCreated(res.data);
            if (typeof props.close === "function") props.close();
          } else {
            console.log("Error in posting entity, ", res);
            notification.error({
              description: formatMessage("app.projects.couldNotCreateEntity"),
            });
          }
          // setIsSettingUp(false);
        })
        .catch((err) => {
          console.log("post err", err, err.response);
          notification.error({
            description: formatMessage("app.projects.couldNotCreateEntity"),
          });
          setIsSettingUp(false);
        });
    },
    [props, formatMessage]
  );

  const onFinish = (values) => {
    const { identificationNumber, jurisdiction, fetchGroup } = values;

    const name = userToName(values);

    if (!name && !fetchGroup) {
      return notification.error({
        message: formatMessage("app.projects.fillOutAllRequired"),
      });
    }

    if (fetchGroup) {
      if (!jurisdiction) {
        return notification.error({
          message: formatMessage("app.projects.fillOutAllRequired"),
        });
      }
      if (!structureEligibleJurisdictions.includes(jurisdiction)) {
        return notification.error({
          message: formatMessage(
            "app.general.entity.fetchNotAvailableJurisdiction"
          ),
        });
      }
      if (!identificationNumber) {
        return notification.error({
          message: formatMessage(
            "app.general.entity.incorrectIdentificationNumberFormat"
          ),
        });
      }
    }

    const data = { ...values, name };

    setIsSettingUp(true);
    storeNewMainEntity(data);
  };
  const onFinishFailed = (err) => {
    return notification.error({
      message: formatMessage("app.projects.fillOutAllRequired"),
    });
  };

  if (isSettingUp) return <Spin className="loader-container" />;

  return (
    <div>
      <div align={"center"}>
        <i
          className={`mdi ${isLegalPerson ? "mdi-city" : "mdi-account"}`}
          style={{ fontSize: 48 }}
        />
      </div>
      <div className="new-project-text">
        <IntlMessages
          id={
            isLegalPerson
              ? "app.entity.createNewCompany"
              : "app.entity.createNewPerson"
          }
        />
      </div>
      <NewEntityForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        type={props.type}
      />
    </div>
  );
}

function NewEntityForm({ onFinish, onFinishFailed, type }) {
  const [form] = useForm();
  const formatMessage = useIntlMessage();
  const [entityType, setEntityType] = useState(
    type || DEFAULT_ENTITY_VALUES.type
  );
  const [jurisdiction, setJurisdiction] = useState(
    DEFAULT_ENTITY_VALUES.jurisdiction
  );
  const [jurisdictionData, setJurisdictionData] = useState({});

  const [isFetchingGroup, setIsFetchingGroup] = useState(false);
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [isAddingEmail, setIsAddingEmail] = useState(false);

  useEffect(() => {
    const country = countries.find((c) => c.code === jurisdiction);
    if (country && country.data) {
      setJurisdictionData(country.data);
      const newFormValue = country.data.corporateForms?.defaultValue || "";
      form.setFieldsValue({ form: newFormValue });
    }
  }, [form, jurisdiction]);

  const onSearchSelect = (data) => {
    form.setFieldsValue({
      identificationNumber: data.identificationNumber,
      form: data.form.toUpperCase(),
      firstName: data.name,
    });
  }

  return (
    <Form
      form={form}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="new-entity"
      initialValues={{
        type: entityType,
        firstName: "",
        lastName: "",
        form: DEFAULT_ENTITY_VALUES.form,
        identificationNumber: "",
        jurisdiction: DEFAULT_ENTITY_VALUES.jurisdiction,
        fetchGroup: false,
        addAddress: false,
        addEmails: false,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row style={{ minHeight: 100 }}>
        <Col lg={12} md={24}>
          <EntityEditFieldType hidden={!!type} onChange={setEntityType} />
        </Col>
        <Col lg={12} md={24}>
          <EntityEditFieldJurisdiction
            onChange={setJurisdiction}
            required={false}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={24}>
          <EntityEditFieldFirstname
            entityType={entityType}
            required={!isFetchingGroup}
            disabled={isFetchingGroup}
            onSearchSelect={onSearchSelect}
          />
        </Col>
        <Col lg={12} md={24}>
          <EntityEditFieldLastname
            entityType={entityType}
            jurisdictionData={jurisdictionData}
            disabled={isFetchingGroup}
          />
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={24}>
          <EntityEditFieldIdentificationNumber
            entityType={entityType}
            onSearchSelect={onSearchSelect}
            required={isFetchingGroup}
            rules={[
              {
                validator: (rule, value) => {
                  const jurisdiction = form.getFieldValue("jurisdiction");
                  // console.log('valid ? ', {rule, value, form})
                  if (value && validRegno(jurisdiction, value) === "invalid")
                    return Promise.reject(new Error());

                  return Promise.resolve();
                },
                message: formatMessage(
                  "app.general.entity.incorrectIdentificationNumberFormat"
                ),
              },
            ]}
          />
        </Col>
        <Col lg={12} md={24}>
          {entityType === "LegalPerson" && (
            <Form.Item
              dependencies={["jurisdiction", "identificationNumber"]}
              label={<IntlMessages id="app.general.entity.fetchGroup" />}
              name="fetchGroup"
              onChange={(e) => setIsFetchingGroup(e.target.checked)}
              rules={[
                {
                  validator: (rule, value) => {
                    const jurisdiction = form.getFieldValue("jurisdiction");
                    const identificationNumber = form.getFieldValue(
                      "identificationNumber"
                    );
                    if (
                      value &&
                      !structureEligibleJurisdictions.includes(jurisdiction)
                    )
                      return Promise.reject(
                        new Error(
                          formatMessage(
                            "app.general.entity.fetchNotAvailableJurisdiction"
                          )
                        )
                      );
                    if (
                      value &&
                      validRegno(jurisdiction, identificationNumber) ===
                        "invalid"
                    )
                      return Promise.reject(
                        new Error(
                          formatMessage(
                            "app.general.entity.fetchRequireIdentificationNumber"
                          )
                        )
                      );
                    return Promise.resolve();
                  },
                },
              ]}
              valuePropName={"checked"}
            >
              <Checkbox>
                <div style={{ lineHeight: "initial" }}>
                  <IntlMessages id="app.general.entity.fetchGroupInfo" />
                  {" "}
                  <Tooltip
                    title={
                      <IntlMessages id="app.general.entity.fetchGroupDetailedInfo" />
                    }
                  >
                    <i className="mdi mdi-help-circle" />
                  </Tooltip>
                </div>
              </Checkbox>
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row>
        <Col lg={4} md={24}></Col>
        <Col lg={8} md={24}>
          <div>
            <Form.Item
              wrapperCol={{ span: 24 }}
              label={null}
              name="addAddress"
              valuePropName={"checked"}
              // onClick={() => setIsAddingAddress(!isAddingAddress)}
            >
              <Checkbox onChange={(e) => setIsAddingAddress(e.target.checked)} disabled={isFetchingGroup}>
                <div style={{ lineHeight: "initial" }}>
                  <IntlMessages id="desc.Add" />{" "}
                  <IntlMessages id="app.general.address" />
                </div>
              </Checkbox>
            </Form.Item>
          </div>
          {isAddingAddress && !isFetchingGroup && (
            <div>
              <EntityFieldAddressBlock withOther={false} compact={true} />
            </div>
          )}
        </Col>
        <Col lg={4} md={24}></Col>
        <Col lg={8} md={24}>
          <div>
            <Form.Item
              wrapperCol={{ span: 24 }}
              label={null}
              name="addEmails"
              valuePropName={"checked"}
            >
              <Checkbox onChange={(e) => setIsAddingEmail(e.target.checked)} disabled={isFetchingGroup}>
                <div style={{ lineHeight: "initial" }}>
                  <IntlMessages id="desc.Add" />{" "}
                  <IntlMessages id="app.persons.emails" />
                </div>
              </Checkbox>
            </Form.Item>
          </div>
          {isAddingEmail && !isFetchingGroup && (
            <div>
              <CollectEmails compact={true} />
            </div>
          )}
        </Col>
      </Row>
      <div>
        <Form.Item wrapperCol={{ offset: 0, span: 24 }} className="text-center">
          <Button type="primary" htmlType="submit" className="btn mt-4">
            <IntlMessages id="app.projects.CreateEntity" />
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}
