
export const options = {
  defaultWidth: 50,
  defaultHeight: 22,
  tableStyle: {
    borderSpacing: 0,
    Layout: 'fixed',
    wordBreak: 'break-word',
  },
  tableType: 'table',
  rowType: 'table_row',
  cellType: 'table_cell',
  tableContentType: 'table_content'
};
