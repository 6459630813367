import { uniqueStateChanges } from './draftSessionHelpers';

export const setCurrentState = function (state) {
  this.states.current = this.api.interfaces.State.produceContractState(state);
};
export const getCurrentState = function () {
  return this.states.current;
};

export const handleNewStates = function (states) {
  this.setCurrentState(states.current);
  const changesData = this.compareStates(states);
  const uniqueChanges = uniqueStateChanges(changesData, this.contract);
  return { changesData, uniqueChanges };
};

function isParentOfOtherPaths(keys, path) {
  return keys.filter((p) => p.startsWith(path + '.')).length > 0;
}
function isUnchangedContainer(keys, collection, path) {
  const isParent = isParentOfOtherPaths(keys, path);
  if (isParent) return true;
  if (collection[path] && typeof collection[path] === 'object' && Object.keys(collection[path]).length === 0)
    return true;
}
export const compareStates = function ({ previous, current }, deepCompare = false) {
  const doDeepCompare = deepCompare || this.isBackend;
  function filePaths(obj, prefix = '', store = {}, first = true) {
    for (let key in obj) {
      if (!obj.hasOwnProperty(key)) continue;
      const curPath = first ? key : `${prefix}.${key}`;
      if (typeof obj[key] === 'object') {
        store[curPath] = obj[key];

        filePaths(obj[key], curPath, store, false);
      } else {
        store[curPath] = obj[key];
      }
    }
    return store;
  }

  if (!current) return {};

  // No previous means no comparison.
  if (!previous) previous = {};

  const currentCollection = filePaths(current);
  const currentKeys = Object.keys(currentCollection);
  const previousCollection = filePaths(previous);
  const previousKeys = Object.keys(previousCollection);
  const allKeys = [...new Set(currentKeys.concat(previousKeys))];

  // If states (a and b) do not originate from same source (such as update on backend)
  // then they share no javascript object references and
  // a shallow compare will not work (i.e., all `!==` comparisons
  // between identical objects will be true). In such case,
  // we need to make a deep compare (not JSON.stringify as that
  // will give false positives if objects have same content but
  // in different order).
  const touched = doDeepCompare
    ? allKeys.filter(
        (k) =>
          currentCollection[k] !== previousCollection[k] &&
          !isUnchangedContainer(previousKeys, currentCollection, k)
      )
    : allKeys.filter((k) => currentCollection[k] !== previousCollection[k]);

  const added = currentKeys.filter((k) => !previousKeys.includes(k));
  const removed = previousKeys.filter((k) => !currentKeys.includes(k));
  const addedOrRemoved = added.concat(removed);
  const modified = touched.filter((k) => !addedOrRemoved.includes(k));

  const info = {};
  const values = {},
    previousValues = {};
  for (const path of touched) {
    values[path] = currentCollection[path];
    previousValues[path] = previousCollection[path];
    if (added.includes(path)) info[path] = 'added';
    else if (removed.includes(path)) info[path] = 'removed';
    else info[path] = 'modified';
  }

  return { added, removed, modified, touched, info, values, previousValues };
};
