import antdEn from "antd/lib/locale-provider/en_GB";
import enMessages from "../locales/en_GB.json";

const EnLang = {
  messages: {
    ...enMessages
  },
  antd: antdEn,
  locale: 'en-GB'
};
export default EnLang;
