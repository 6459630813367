import * as apps from './applications/';

export const applications = editor => {
  const { apply } = editor

  editor.apply = (app) => {
    // console.log('App is ', app)
    if(typeof apps[app.type] === 'function') {
      const applied = apps[app.type](editor, app, apply);
      if(applied === false) {
        console.log('Do not apply.')
        return;
      }
    }
    apply(app);
  }

  return editor
}
