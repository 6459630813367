import React from 'react';
import {
  Input,
  Row,
  Col,
} from 'antd';

class TextSelect extends React.PureComponent {

  render() {
    if(this.props.full) return this.renderFull();

    return (<SingleTextSet {...this.props} />);
  }

  renderFull = () => {
    const { text, dark, settersValue, settersChange, language } = this.props;
    // if(!Array.isArray(texts)) return null;
    return (
			<Row className="mt-2" key={text.id}>
				<Col span={10}>
					<span style={{ fontSize: "12px" }} className={dark?"text-white":""}>
						{text.label[language]}{text.required&&"*"}
					</span>
				</Col>
				<Col span={14}>
					<SingleTextSet id={text.id} settersValue={settersValue} settersChange={settersChange} text={text} />
				</Col>
			</Row>
		)
  }

}


export default TextSelect;

class SingleTextSet extends React.PureComponent {
  
  render() { 
    const { id, settersValue, settersChange, text } = this.props;
    const value = settersValue[id];
    return (
    <div>
      <Input type="text" onChange={(evt) => { settersChange(id, evt.target.value) }} value={value || ""} />
      {text && text.info && text.info.en ? <small className="muted">{text.info.en}</small> : null}
    </div>
      );
  }
}