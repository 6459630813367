import { deepPatchJson } from '@sanalabs/json';
import {
  REMOTE_APPLY_STATE,
  SET_ENTITIES,
  UPDATE_ENTITY,
  INSERT_ENTITY,
  REMOVE_ENTITY,
  BULK_UPSERT_ENTITIES,
} from 'constants/ActionTypes';

export const INIT_STATE = [];

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case REMOTE_APPLY_STATE:
      const oldState = state;
      const remoteState = action.payload.entities;
      deepPatchJson(oldState, remoteState);
      return [...oldState];
    case SET_ENTITIES:
      return action.payload;
    case UPDATE_ENTITY:
      const { index, data } = action.payload;
      const newState = [...state];
      const existingEntity = newState[index];
      if (!existingEntity) {
        console.warn('Cannot find existing entity in entities reducer.');
        return state;
      }

      newState[index] = { ...existingEntity, ...data };

      return newState;

    case BULK_UPSERT_ENTITIES:
      const { payload } = action;
      const updatedExistingEntities = state.map((existingEntity) => {
        const inPayload = payload.find((payloadEntity) => payloadEntity.id === existingEntity.id);
        if (inPayload) return inPayload;
        return existingEntity;
      });
      payload.forEach((entity) => {
        const inState = state.find((stateEntity) => stateEntity.id === entity.id);
        if (!inState) updatedExistingEntities.push(entity);
      });
      return updatedExistingEntities;
    case INSERT_ENTITY:
      return [...state, action.payload];
    case REMOVE_ENTITY:
      const payloadType = typeof action.payload;
      let removeIds = [];
      if (payloadType === 'number') {
        console.log('Remove_ENTITY got number..', action.payload);
        return state;
      }
      if (payloadType === 'string') {
        removeIds = [action.payload];
      } else if (Array.isArray(action.payload)) removeIds = action.payload;
      else return state;
      return state.filter((entity) => !removeIds.includes(entity.id));
    default:
      return state;
  }
}
