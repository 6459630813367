/* eslint-disable import/prefer-default-export */
import moment from 'moment-business-days';
import findIndex from 'find-index/findIndex';
import { mergeObj } from '../../utils/general';
import { find } from '../../engine/utils';
import { getByPath } from '../../utils/general';

/**
 * KeyDates. Dates pertaining to certain.
 *  E.g:
 *      {
 *        signing: '2022-06-05',
 *        NOW(): default date as of invokation of date functions.
 *      }
 *
 *
 *
 */

const Dates = {};
const DEFAULT_NODE_DATA_ID = '__DEFAULT__';
const ORDERED_DATE_ITEMS = ['year', 'quarter', 'month', 'date', 'hour', 'minute', 'second'];
const ADJUST_INSTRUCTONS = {
  businessdays: {
    methods: {
      before: 'businessSubtract',
      after: 'businessAdd',
    },
    formatAsArgument: false,
  },
  _default: {
    methods: {
      before: 'subtract',
      after: 'add',
    },
    formatAsArgument: true,
  },
};
const CUSTOM_INTERVAL_SETTINGS = {
  current: {
    quarter: {
      endOfTarget: 'month',
      resetDateIndex: 2,
    },
  },
};
const REQUIRED_ADJUST_KEYS = ['direction', 'format', 'value'];

function getNodeValue(node, value) {
  const { type: valueType } = value;
  if (valueType === 'in-clause') return getByPath(node, value.location || 'data.value');
  if (valueType === 'fixed') return value.value;
  return null;
}

function adjustDate(dateObj, format, value, direction) {
  const intValue = parseInt(value, 10);
  const clonedDateObj = dateObj.clone();

  const adjuster = ADJUST_INSTRUCTONS[format] || ADJUST_INSTRUCTONS._default;
  const { methods, formatAsArgument } = adjuster;
  const method = methods[direction];
  if (!method) {
    return dateObj;
  }

  const args = [intValue, formatAsArgument ? format : undefined];
  return clonedDateObj[method].apply(clonedDateObj, args);
}

Dates.applyModifier = function applyModifier(dateObj, instructions) {
  const clonedObj = dateObj.clone();
  const { value, format, direction } = instructions;
  if (!value || Number.isNaN(parseInt(value, 10))) return clonedObj;

  if (direction && format) {
    return adjustDate(clonedObj, format, value, direction);
  }
  return clonedObj;
};

const isAdjustmentData = (data) => data && REQUIRED_ADJUST_KEYS.every((v) => data.hasOwnProperty(v));

const instructionsIncludeAdjustment = ({ adjust }) => isAdjustmentData(adjust);
const instructionsIncludeGrace = ({ grace }) => isAdjustmentData(grace);

function createKeyDateObj(dateInstructions = {}, keyDates) {
  const { value, keyDate } = dateInstructions;
  let dateInstruction;

  if (keyDate && keyDates && keyDates[keyDate]) {
    dateInstruction = keyDates[keyDate];
  } else if (value) {
    dateInstruction = value;
  } else if (keyDate === 'NOW()') {
    return moment();
  }
  return dateInstruction ? moment(dateInstruction) : null;
}

Dates.newDateObj = function getKeyDateObj(keyDate, keyDates = {}) {
  if (keyDate) {
    try {
      const tryDate = moment(keyDate);
      if (tryDate && tryDate.isValid()) {
        return tryDate;
      }
    } catch (err) {}
    return createKeyDateObj({ keyDate }, keyDates);
  }
  return moment();
};

function getDateItemIndex(dateItem, adjust = 0) {
  const index = findIndex(ORDERED_DATE_ITEMS, (item) => item === dateItem);
  if (index === -1) throw new Error('Invalid dateItem: ' + dateItem);
  const targetIndex = index + adjust;
  if (targetIndex < 0 || targetIndex + 1 === ORDERED_DATE_ITEMS)
    throw new Error('Invalid dateItem: ' + dateItem);
  return targetIndex;
}

function resetDateObjAt(dateObj, dateItem, adjustIndex = 0) {
  const targetIndex = getDateItemIndex(dateItem, adjustIndex);
  const resetInstructionsObj = ORDERED_DATE_ITEMS.slice(targetIndex).reduce((acc, curr) => {
    acc[curr] = 0;
    return acc;
  }, {});

  dateObj.set(resetInstructionsObj);
  return dateObj;
}

function getProperDay(day) {
  let theDay;
  if (typeof day === 'number') theDay = day;
  else if (typeof day === 'string') {
    const parsedDay = parseInt(day, 10);
    if (!isNaN(parsedDay)) theDay = parsedDay;
  }
  if (theDay === undefined) return null;

  return theDay;
}

function getCustomIntervalSetting(startRelation, freq, key) {
  if (!CUSTOM_INTERVAL_SETTINGS[startRelation]) return null;
  if (!CUSTOM_INTERVAL_SETTINGS[startRelation][freq]) return null;
  return CUSTOM_INTERVAL_SETTINGS[startRelation][freq][key] || null;
}

function modifyBaseDateByInterval(dateObj, interval) {
  const { day = 0, freq, startRelation = 'calendar', shiftInstruction } = interval;

  const properDay = getProperDay(day);
  const endAtDateItem = getCustomIntervalSetting(startRelation, freq, 'endOfTarget') || freq;
  const resetDateIndex = getCustomIntervalSetting(startRelation, freq, 'resetDateIndex') || 0;

  const clonedObj = resetDateObjAt(dateObj.clone(), freq, resetDateIndex);

  while (clonedObj.isBefore(dateObj)) {
    // Break early if added days would cause us to otherwise to go far.
    if (properDay > 0 && clonedObj.clone().add(properDay, 'days').isAfter(dateObj)) {
      break;
    }

    clonedObj.add(1, freq).endOf(endAtDateItem);
  }

  if (startRelation !== 'current' && isAdjustmentData(shiftInstruction)) {
    const flipDate = Dates.applyModifier(dateObj, shiftInstruction);
    if (flipDate.isAfter(clonedObj)) {
      clonedObj.add(1, freq).endOf(endAtDateItem);
    }
  }

  if (properDay > 0) {
    clonedObj.add(properDay, 'days');
  }
  return clonedObj;
}

function getBaseDateObjForInstruction(dateInstructions, keyDates) {
  const { interval } = dateInstructions;
  const { start } = interval;

  if (!start) {
    console.warn('No start for interval.');
    return null;
  }

  const dateObj = createKeyDateObj(start, keyDates);

  const intervalledDateObj = modifyBaseDateByInterval(dateObj, interval);
  if (!intervalledDateObj) return null;

  return intervalledDateObj;
}

function getBaseDateObj(dateInstructions, keyDates) {
  if (dateInstructions.interval) {
    return getBaseDateObjForInstruction(dateInstructions, keyDates);
  }
  return createKeyDateObj(dateInstructions, keyDates);
}

function ensureObjectNesting(obj, levels) {
  let ref = obj;
  for (const lvl of levels) {
    if (!ref[lvl]) ref[lvl] = {};
    ref = ref[lvl];
  }
  return ref;
}

Dates.runInstructions = function runInstructions(instructions, keyDates) {
  if (!instructions || !instructions.date) return null;
  let baseDateObj = getBaseDateObj(instructions.date, keyDates);
  const isInterval = !!instructions.date.interval;

  let hasGrace = false;
  let isAdjusted = false;

  let deadlineDateObj = baseDateObj.clone();

  if (instructionsIncludeGrace(instructions)) {
    const dateObjWithGrace = Dates.applyModifier(deadlineDateObj, instructions.grace, keyDates);
    hasGrace = true;
    deadlineDateObj = dateObjWithGrace;
    baseDateObj = dateObjWithGrace; // Grace also updates baseDate.
  }

  if (instructionsIncludeAdjustment(instructions)) {
    const dateObjWithAdjustment = Dates.applyModifier(deadlineDateObj, instructions.adjust, keyDates);
    isAdjusted = true;
    deadlineDateObj = dateObjWithAdjustment;
  }

  const result = {
    deadlineDateObj,
    deadlineDate: deadlineDateObj.toISOString(),
    baseDateObj,
    baseDate: baseDateObj.toISOString(),
    isInterval,
    hasGrace,
    isAdjusted,
  };

  if (instructions.reminder) {
    const reminderDateObj = Dates.applyModifier(result.dateObj, instructions.reminder);
    if (reminderDateObj) result.reminder = { dateObj: reminderDateObj, date: reminderDateObj.toISOString() };
  }

  if (isInterval) result.interval = instructions.date.interval;
  return result;
};

function getProvisionReminders(clause, dateObj) {
  if (!clause.data.provision || !clause.data.provision.timing) return null;
  const { reminders } = clause.data.provision.timing;
  if (!Array.isArray(reminders)) return null;
  return reminders
    .map((r) => {
      const reminderDateObj = Dates.applyModifier(dateObj, r);
      if (reminderDateObj) return { dateObj: reminderDateObj, date: reminderDateObj.toISOString() };
      return null;
    })
    .filter((r) => !!r);
}

const getDateInstructionsFromNodes = (nodes) =>
  nodes.reduce((acc, node) => {
    const {
      date: { id = DEFAULT_NODE_DATA_ID, type, value },
    } = node;
    if (!type || !value) return acc;

    const parts = type.split('_');
    const allButLast = [id, ...parts.slice(0, -1)];
    const last = parts.slice(-1)[0];
    const target = ensureObjectNesting(acc, allButLast);
    target[last] = getNodeValue(node, value);
    return acc;
  }, {});

const arrayifyInstructionsObj = (instructionsObj) =>
  Object.entries(instructionsObj).map(([id, values]) => ({ id, ...values }));

function getInClauseTimingInstructionsArray(clause) {
  const dateInstructionNodes = find(clause, (node) => !!node.date);
  const allInstructionsObj = getDateInstructionsFromNodes(dateInstructionNodes);
  return arrayifyInstructionsObj(allInstructionsObj);
}

function getFixedTimingInstructionsObj(clause) {
  return (clause.data.provision.timing && clause.data.provision.timing.value) || {};
}

function handleProvision(clause, keyDates) {
  if (!clause || !clause.data || !clause.data.provision) return null;

  const fixedProvisionInstructionsObj = getFixedTimingInstructionsObj(clause);

  const inClauseInstructions = getInClauseTimingInstructionsArray(clause).map((instr) =>
    mergeObj(fixedProvisionInstructionsObj, instr)
  );

  return inClauseInstructions.map((instr) => Dates.runInstructions(instr, keyDates));
}

function combineReminders(provisionReminders, reminder) {
  if (!provisionReminders) return reminder ? [reminder] : null;
  if (reminder) return [...provisionReminders, reminder];
  return provisionReminders;
}

function structureDateResults(handledProvisionData, clause) {
  if (!handledProvisionData || handledProvisionData.length === 0) return null;
  return handledProvisionData
    .filter((result) => result && result.deadlineDateObj)
    .map((result) => {
      const provisionReminders = getProvisionReminders(clause, result.deadlineDateObj);
      const reminders = combineReminders(provisionReminders, result.reminder);
      if (reminders) return { ...result, reminders };
      return result;
    });
}

Dates.applyDate = function applyDate({ keyDates = {}, clause, language = 'en' }) {
  moment.updateLocale(language, {
    workingWeekdays: [1, 2, 3, 4, 5],
    holidays: ['24/12'],
    holidayFormat: 'DD/MM',
  });

  const handledProvisionData = handleProvision(clause, keyDates);
  return structureDateResults(handledProvisionData, clause);
};

export { Dates };

/* window.Dates = Dates;
window.moment = moment;
 */
/*
Dates.runInstructions({
  "date": {
    "type": "interval",
    "interval": {
      "freq": "quarter",
      "interval": 1,
      "day": 0,
      "start": {
        "keyDate": "signing"
      },
      "startRelation": "calendar",
      shiftInstruction: { value: 5, format: 'days', direction: 'after' }
    }
  },
    gracex: {
        "value": 1,
    "format": "quarters",
    "direction": "after"
    },
    reminder: { "value": "1", "format": "week", "direction": "before" }
}, { signing: '2022-03-28' })
*/

/*

Dates.applyDate({ keyDates: { signing: '2022-06-04' }, language: 'en', clause: 
{
  "data": {
    "provision": {
      "timing": {
        "type": "in-clause",
          "reminders": [{ "value": "6", "format": "day", "direction": "before" }]
      }
    }
  },
  "children": [
    {
      "data": {
        "value": 'quarter'
      },
      "date": {
        "type": "date_interval_freq",
        "value": {
          "type": "in-clause",
          "location": "data.value"
        }
      }
    },
    {
      "data": {
        "value": 1
      },
      "date": {
        "type": "date_interval_interval",
        "value": {
          "type": "in-clause",
          "location": "data.value"
        }
      }
    },
    {
      "data": {
        "value": 1
      },
      "date": {
        "type": "date_interval_day",
        "value": {
          "type": "in-clause",
          "location": "data.value"
        }
      }
    },
    {
      "data": {
        "value": 'signing'
      },
      "date": {
        "type": "date_interval_start_keyDate",
        "value": {
          "type": "in-clause",
          "location": "data.value"
        }
      }
    },
    {
      "data": {
        "value": 'calendar'
      },
      "date": {
        "type": "date_interval_startRelation",
        "value": {
          "type": "in-clause",
          "location": "data.value"
        }
      }
    },


      {
      "data": {
        "value": 'month'
      },
      "date": {
        "type": "date_interval_freq",
        "id": 2,
        "value": {
          "type": "in-clause",
          "location": "data.value"
        }
      }
    },
    {
      "data": {
        "value": 1
      },
      "date": {
        "type": "date_interval_interval",
        "id": 2,
        "value": {
          "type": "in-clause",
          "location": "data.value"
        }
      }
    },
    {
      "data": {
        "value": 0
      },
      "date": {
        "type": "date_interval_day",
          "id": 2,
        "value": {
          "type": "in-clause",
          "location": "data.value"
        }
      }
    },
    {
      "data": {
        "value": 'signing'
      },
      "date": {
        "type": "date_interval_start_keyDate",
          "id": 2,
        "value": {
          "type": "in-clause",
          "location": "data.value"
        }
      }
    },
    {
      "data": {
        "value": 'calendar'
      },
      "date": {
        "type": "date_interval_startRelation",
          "id": 2,
        "value": {
          "type": "in-clause",
          "location": "data.value"
        }
      }
    }
  ]
}    
})

*/

/*
  const task = {
    taskType: provision.data.provision.type,
    data: {
      provision: provision,
    },
    resourceType: 'Document',
    resourceId: 'xxx',
    assignedOrgId: 'xxx',
    assignedOrgType: 'Entity',
    source: 'auto',
  };
  const schedules = [
    {
      scheduleType: 'reminder',
      processAt: new Date(
        new Date(dateResult.date).setDate(new Date(dateResult.date).getDate() - 2)
      ).toISOString(),
    },
    {
      scheduleType: 'deadline',
      processAt: dateResult.date,
    },
  ];

  console.log('Date items', { dateItems, dateResult, task, schedules }); */
