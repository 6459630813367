import React, { memo } from 'react';
import { Col, Row } from 'antd';
import Info from '../../../components/entity/Info/index';
import Projects from '../../../components/entity/Projects/index';
import Comments from '../../../components/Comments/index';
import { gutter } from 'config';
import Agents from '../Agents';
import OrgChart from '../OrgChart';
import Subsidiaries from '../Subsidiaries';

export default memo(function ({ id, entity, entitiesStructure, updateEntity }) {
  return (
    <div className="padded-page">
      <Row gutter={gutter}>
        <Col xl={16} lg={14} md={14} sm={24} xs={24}>
          <Info entity={entity} updateEntity={updateEntity} />
          <Agents entity={entity} updateEntity={updateEntity} />
          <Comments entity={entity} id={id} type="Entity" />
        </Col>
        <Col xl={8} lg={10} md={10} sm={24} xs={24}>
          <Projects entity={entity} updateEntity={updateEntity} />
          {entitiesStructure && (
            <div className="overview-actions">
              <OrgChart
                id={id}
                entity={entity}
                entities={entitiesStructure}
                updateEntity={updateEntity}
              />
              <Subsidiaries
                id={id}
                entity={entity}
                entities={entitiesStructure}
                updateEntity={updateEntity}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
});