import { Editor } from 'slate';

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (node) => node.type === format,
    mode: 'all',
  })

  return !!match
}

export const isMarkActive = (editor, format) => {
  let marks;
  try {
    marks = Editor.marks(editor)
  } catch(err) { return false; }
  return marks ? marks[format] === true : false
}
