import { getContractValues } from 'hooks';
import { Contract } from 'core/interfaces';
import { splitNumber, combineNumber, floatify } from 'core/utils/general';

const prepValues = (data) =>
  data.reduce((acc, curr) => {
    if (curr.fieldName) {
      if (acc[curr.uid]) acc[curr.uid][curr.fieldName] = curr.value;
      else acc[curr.uid] = { [curr.fieldName]: curr.value };
    }
    return acc;
  }, {});

const getValue = (repeatableState, uidsAndValues, uid, fieldName) => {
  if (uidsAndValues[uid] && uidsAndValues[uid].hasOwnProperty(fieldName))
    return uidsAndValues[uid][fieldName];
  return repeatableState[fieldName];
};

const calcUpfront = (data, state) => {
  const { input } = state;
  let counterBlock = {};

  const contract = getContractValues();
  const language = Contract.getLanguage(contract);

  const facilities = input.facility;
  if (!facilities) return;

  const uidsAndValues = prepValues(data);

  for (const [uid, values] of Object.entries(facilities)) {
    const commitment = getValue(values, uidsAndValues, uid, 'facility/commitment');
    const upfront = getValue(values, uidsAndValues, uid, 'pricing/upfront');
    const currency = getValue(values, uidsAndValues, uid, 'facility/currency');
    if (!counterBlock.hasOwnProperty(currency)) counterBlock[currency] = 0;
    counterBlock[currency] += (parseInt(combineNumber(commitment, language)) * floatify(upfront)) / 100 || 0;
  }

  let upfrontString = '';
  for (let currency in counterBlock)
    upfrontString +=
      currency +
      ' ' +
      (isNaN(counterBlock[currency]) ? 0 : splitNumber(counterBlock[currency], language)) +
      ' ';

  return {
    path: 'input.__info_fields.totalupfront',
    value: upfrontString.trim(),
  };
};

export default calcUpfront;
