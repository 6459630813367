import { useState, useCallback } from 'react';
import { Button, Divider, Tag, Row, Col } from 'antd';
import { EntitySearch } from 'components/ui';
import IntlMessages from 'util/IntlMessages';
import { Entity } from 'core/interfaces';

function ListEntitySearchResults({
  results,
  selectResult,
  principalEntityId,
  standardResultsPrincipalDataForAgents,
}) {
  // standardResults
  if (!results || !results.length) return null;
  return (
    <Row gutter={[16, 16]}>
      {results.map((entity) => {
        if (!entity) {
          return null;
        }
        if (principalEntityId && entity.id === principalEntityId) {
          return null;
        }

        let extraData = null;
        if (
          entity.EntityRelations &&
          entity.EntityRelations.length > 0 &&
          standardResultsPrincipalDataForAgents
        ) {
          const relations = entity.EntityRelations.map((relation) => ({
            ...relation,
            principalEntity: standardResultsPrincipalDataForAgents.find(
              (e) => e.id === relation.principalEntityId
            ),
          }));
          extraData = (
            <div>
              {relations.map((relation) => (
                <div className="d-flex border mb-1" key={entity.id + relation.principalEntityId}>
                  <div>
                    {Array.isArray(relation.capacities) && relation.capacities.length ? (
                      relation.capacities.map((capacity) => (
                        <Tag color="purple" key={capacity + entity.id}>
                          {capacity}
                        </Tag>
                      ))
                    ) : (
                      <Tag color="grey" key={'none' + entity.id}>
                        N/A
                      </Tag>
                    )}
                  </div>
                  <div>
                    <small>@ {Entity.name(relation.principalEntity)}</small>
                  </div>
                </div>
              ))}
            </div>
          );
        }
        const email = Entity.firstEmail(entity);
        return (
          <Col md={12} lg={8} xxl={6} key={entity.id}>
            <div className="p-2 mt-1 border">
              <div className="d-flex align-items-center">
                <i className={`mdi ${Entity.isRealPerson(entity) ? 'mdi-account' : 'mdi-city'} fs-xl mr-2`} />
                <div style={{ overflow: 'hidden' }}>
                  <small style={{ display: 'block' }}>{Entity.name(entity)}</small>
                  <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    <small>{email}</small>
                  </p>
                </div>
              </div>
              {extraData}
              <div>
                <Button className="m-0" block onClick={() => selectResult(entity)}>
                  <IntlMessages id="desc.Select" cap />
                </Button>
              </div>
            </div>
          </Col>
        );
      })}
    </Row>
  );
}

export function SearchList({
  selectResult,
  principalEntity,
  excludedFromSearch,
  standardResults = [],
  ...restProps
}) {
  const [results, setResults] = useState([]);

  const updateResults = useCallback(
    (newResults) => {
      if (!Array.isArray(excludedFromSearch)) {
        setResults(newResults);
        return;
      }
      setResults(newResults.filter((e) => !excludedFromSearch.includes(e.id)));
    },
    [excludedFromSearch]
  );

  return (
    <>
      <div>
        <div className="mb-2">
          <EntitySearch
            setResults={updateResults}
            noDefaults
            small
            defaults={restProps.searchDefaults}
            forceType={restProps.searchForceType}
            includePrincipalsAndAgents={true}
          />
        </div>
        <ListEntitySearchResults
          results={results}
          selectResult={selectResult}
          principalEntityId={principalEntity && principalEntity.id}
          {...restProps}
        />
        {standardResults && standardResults.length > 0 && (
          <div>
            <Divider>
              <IntlMessages id="desc.or" cap /> <IntlMessages id="app.general.chooseBelow" />
            </Divider>
            <ListEntitySearchResults
              results={standardResults}
              selectResult={selectResult}
              principalEntityId={principalEntity && principalEntity.id}
              {...restProps}
            />
          </div>
        )}
      </div>
    </>
  );
}
