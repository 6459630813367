const Create = {};

Create.getPage = (ui, route, options = {}) => {
  let page = ui;
  const paths = options.paths ? route : route.paths.slice(1);

  for (const path of paths) {
    page = page.pages[path];
  }
  return page;
};

export { Create };
