import { HocuspocusProvider } from '@hocuspocus/provider';
import { wsAddress } from 'config/main';
import { doRefresh } from '../../utils/api';

// "YJS_AUTH_STATUSES": ["NOT_AUTHED", "AUTHED", "AUTH_ERROR"]

export const createProvider = (versionId, token, setConnected, setProviderAuthStatus, paramsRef) => {
  const provider = new HocuspocusProvider({
    url: wsAddress,
    parameters: {},
    name: versionId,
    connect: false,
    token: () => {
      const accessToken = localStorage.getItem('jwtToken');
      // console.log('Retrieving token . . .', { versionId, accessToken, usedToken: token || accessToken });
      return token || accessToken;
    },
    onConnect: () => {
      // console.log('connected', typeof setConnected === 'function', versionId)
      typeof setConnected === 'function' && setConnected(true);
    },
    onDisconnect: () => {
      // console.log('disconnected')
      typeof setConnected === 'function' && setConnected(false);
    },
    onAuthenticated: () => {
      // console.log('authed')
      typeof setProviderAuthStatus === 'function' && setProviderAuthStatus('AUTHED');
    },
    onAuthenticationFailed: (data) => {
      setProviderAuthStatus('AUTH_ERROR');
      console.log('Failed! ', data);
      if (data.reason === 'permission-denied') {
        doRefresh()
          .then(() => {
            // Is there a better alternative...?
            if (typeof setProviderAuthStatus === 'function') {
              // console.log('Authed for ', versionId);
              setConnected(true);
            }
          })
          .catch((err) => {
            console.log('Failed refresh.');
          });
      }
    },
    /* 
    onMessage: (evt) => {
      console.log('message ', evt);
    },
    onSynced: (x) => {
      console.log('Synced.', x);
    },
    */
    /* onStatus: (status) => {
      console.log('Got status ', status);
    }, */
  });

  provider.document.gc = false;

  return provider;
};
