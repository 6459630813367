import React from "react";

const AboutItem = ({data}) => {
  const {title, icon, desc, userList} = data;
  return (
    <>
      <div className="media flex-nowrap info-item">
        <div className="media-body">
          <h6 className="mr-3">
            <i className={`mdi ${icon} mr-1`} />
            {title}
          </h6>
          <div className='ml-1'>
            {desc}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutItem;
