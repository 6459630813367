import { createReducer } from '@reduxjs/toolkit';
import { deepPatchJson } from '@sanalabs/json';

import {
  REMOTE_APPLY_STATE,
  SET_CONTRACT_DATA_RULES,
  SET_CONTRACT_DATA_RULE,
  REMOVE_CONTRACT_DATA_RULE,
  SET_CONTRACT_DATA_VARIABLES,
  SET_CONTRACT_DATA_VARIABLE,
  REMOVE_CONTRACT_DATA_VARIABLE,
} from 'constants/ActionTypes';

const INIT_STATE = {
  rules: {},
  variables: {},
};

export default createReducer(INIT_STATE, {
  [REMOTE_APPLY_STATE]: (state, action) => {
    const oldState = state;
    const newState = action.payload.contractData || INIT_STATE;
    deepPatchJson(oldState, newState);
    return oldState;
  },
  [SET_CONTRACT_DATA_RULES]: (state, action) => {
    state.rules = { ...state.rules, ...action.payload };
    return state;
  },
  [SET_CONTRACT_DATA_RULE]: (state, action) => {
    const { key, value } = action.payload;
    state.rules[key] = value;
    return state;
  },
  [REMOVE_CONTRACT_DATA_RULE]: (state, action) => {
    delete state.rules[action.payload];
    return state;
  },
  [SET_CONTRACT_DATA_VARIABLES]: (state, action) => {
    state.variables = { ...state.variables, ...action.payload };
    return state;
  },
  [SET_CONTRACT_DATA_VARIABLE]: (state, action) => {
    const { key, value } = action.payload;
    state.variables[key] = value;
    return state;
  },
  [REMOVE_CONTRACT_DATA_VARIABLE]: (state, action) => {
    delete state.variables[action.payload];
    return state;
  },
});
