import { Transforms, Node, Editor, Path, Element, Text } from '../../../../import/slate';

export function replaceChildren(editor, path, children) {
  if (!Editor.isEditor(editor)) {
    console.warn('replaceChildren expects valid editor');
    return null;
  }
  if (!Path.isPath(path)) {
    console.warn('replaceChildren expects valid path');
    return null;
  }

  let newChildren;

  if (Node.isNode(children)) {
    newChildren = [children];
  } else if (Node.isNodeList(children)) {
    newChildren = children;
  }

  if (!newChildren) {
    console.warn('replaceChildren got invalid child/children');
  }

  const node = Node.get(editor, path);
  const currentLength = node.children.length;
  const newChildrenLength = newChildren.length;

  // First insert, then remove. In order to avoid having an empty node which causes issues.
  for (let i = 0; i < newChildrenLength; i++) {
    Transforms.insertNodes(editor, newChildren[i], { at: [...path, i], voids: true });
    // console.log('Add child ', [...path, i])
  }

  for (let i = currentLength + newChildrenLength - 1; i >= newChildrenLength; i--) {
    Transforms.removeNodes(editor, { at: [...path, i], voids: true });
    // console.log('Now remove ', [...path, i]);
  }
}
