import { useState, memo } from 'react';
import { Input } from 'antd';

export const SearchBar = memo(({ onChange, children }) => {
  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);
  const setSearchValue = (value) => {
    const lv = value.toLocaleLowerCase();
    setValue(lv);
    onChange(lv);
  };
  const onSearchChange = (evt) => {
    const { value: v } = evt.target;
    setSearchValue(v);
  };

  const onFocus = () => {
    setFocused(true);
    onChange(value);
  };
  const onBlur = () => {
    setFocused(false);
  };

  return (
    <div className="mt-search-bar">
      <div className={`mt-search-bar-rounded ${focused ? 'focused' : ''}`}>
        <i className="icon mdi mdi-magnify" style={{ fontSize: '1.6em' }} />
        <Input
          className="mt-search-bar-input"
          value={value}
          onChange={onSearchChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={(evt) => {
            if (evt.code === 'Escape') return setSearchValue('');
          }}
          placeholder="Search . . ."
        />
        <div className="w-line"></div>
      </div>
    </div>
  );
});
