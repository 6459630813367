import { List, Avatar, Tag } from 'antd';
import { Tablify, fixDate } from 'components/ui';
import IntlMessages from 'util/IntlMessages';

function getUrl(item) {
  const { _type } = item;
  if (_type === 'Project') return `/project/${item.id}`;
  if (_type === 'Document') return `/project/${item.projectId}/document/${item.id}`;
  return '';
}

export function ProjectOrDocumentItem({ item, history }) {
  const { _type } = item;

  const onClickItem = () => {
    const url = getUrl(item);
    if (!url) return;
    history.push(url);
  };

  const infoTable = [
    [
      <span>
        <IntlMessages id="app.general.Description" cap />
      </span>,
      <span>{item.description}</span>,
    ],
    [
      <span>
        <IntlMessages id="app.signing.list.createdAt" cap />
      </span>,
      <span>{fixDate(item.createdAt)}</span>,
    ],
    /*   [
      <span>
        <IntlMessages id="app.general.IdentificationNumber" cap />
      </span>,
      <span>{item.identificationNumber || 'N/A'}</span>,
    ],
    [
      <span>
        <IntlMessages id="app.general.Type" cap />
      </span>,
      <span>
        <Tag color="#0a232e">
          <IntlMessages id={`app.persons.${item.type}`} cap />
        </Tag>
      </span>,
    ], */
  ];

  return (
    <List.Item key={item.id} className="mt-search-result-item highlight-search-inlines" onClick={onClickItem}>
      <List.Item.Meta
        avatar={
          <Avatar
            src={
              <i
                className={`mdi ${_type === 'Project' ? 'mdi-cube-outline' : 'mdi-file-outline'} fs-xl mr-2`}
              />
            }
          />
        }
        title={
          <div>
            <div className="d-flex justify-content-space-between mb-1">
              <div className="mt-search-result-item-title">
                <div className="header">{item.name}</div>
              </div>
              <div className="mt-search-result-item-info">
                <Tag>
                  <IntlMessages id={`app.resources.${_type}`} />
                </Tag>
              </div>
            </div>
            <Tablify data={infoTable} />
          </div>
        }
      />
    </List.Item>
  );
}
