import React from 'react';
import { Row, Col } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { CheckCircleOutlined } from '@ant-design/icons';

export default function SigningStarted({ data, onClose }) {
  const { signUrl } = data;

  const openSignWindow = (signUrl) => {
    window.open(
      signUrl,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=768,height=1024'
    );
  };

  return (
    <div>
      <Row className="flex-row m-0 mt-4">
        <Col sm={24} md={4} className="d-flex align-items-center justify-content-center mb-3">
          <CheckCircleOutlined className="text-success" style={{ fontSize: '48px' }} />
        </Col>
        <Col sm={24} md={20} className="d-flex flex-sm-column flex-md-row align-items-center">
          <h3 className="mb-3">
            <IntlMessages id="app.signing.started.header" />
          </h3>
        </Col>
      </Row>
      <Row className="flex-row m-0 mt-4">
        <Col sm={24} md={4} className="d-flex align-items-center"></Col>
        <Col sm={24} md={20} className="flex-sm-row justify-content-center">
          <p className="">
            <IntlMessages id="app.signing.started.info" />.
          </p>
        </Col>
      </Row>
      <Row className="flex-row m-0 mt-4 pt-4 border-top">
        <Col>
          <span>
            <IntlMessages id="app.signing.started.checkStatus" />.
          </span>
        </Col>
      </Row>
    </div>
  );
}
