import React, { useState, useEffect } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import api from 'utils/api';

const DYNAMIC_OPTIONS = ['CLIENT_ENTITY_ID', 'USER_ENTITY_ID'];

export default function InputTypeText({ onChildUpdate, currentInput, inputs }) {
  const { locale } = useSelector((state) => state.settings.locale);
  const [target, setTarget] = useState(currentInput.target)
  const [defaultValue, setDefaultValue] = useState(
    currentInput?.dynamicValue || currentInput?.value?.id || null
  );
  const [entitiesOptions, setEntitiesOptions] = useState(null);
  const options = Object.values(inputs)
    .filter(input => input.type === 'entity' && input.name !== currentInput.name)
    .map(({name, label}) => ({name, label: label?.[locale] || label?.en || name}))

  useEffect(() => {
    (async () => {
      if (defaultValue !== null) onChildUpdate({ defaultValue: { id: defaultValue } });
      const response = await api.get(`/entities?fields=id,firstName,lastName`);
      const results = response.data ?? [];
      setEntitiesOptions(
        results.map(({ id, firstName, lastName }) => ({ id, label: `${firstName} ${lastName}` }))
      );
    })();
  }, []);

  const onChangeSuggestion = (values) => {
    setTarget({...target, ...values})
    onChildUpdate({target: {...target, ...values}})
  }

  const onChangeDefaultValue = (value) => {
    setDefaultValue(value);
    if (DYNAMIC_OPTIONS.includes(value)) {
      onChildUpdate({ dynamicValue: value });
    } else {
      onChildUpdate({ defaultValue: value === -1 || value === null ? null : { id: value } });
    }
  };

  return (
    <>
      <Form layout="vertical">
        <Form.Item label={<IntlMessages id="studio.template.inputs.suggest" />}>
          <Row>
            <Col xs={24} md={12}>
              <Select
                options={[{ name: '', label: 'Any' }, ...options]}
                fieldNames={{value: 'name'}}
                onSelect={fromEntityField => onChangeSuggestion({fromEntityField})}
                value={target?.fromEntityField ?? ''}
              />
            </Col>
            <Col xs={24} md={12}>
              <Select
                options={[
                  { value: '', label: 'Any' },
                  { value: 'Agent', label: 'Agents' },
                  { value: 'Principal', label: 'Principals' },
                ]}
                onSelect={targetRelative => onChangeSuggestion({targetRelative})}
                value={target?.targetRelative ?? ''}
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label={<IntlMessages id="studio.template.inputs.defaultValue" />}>
          <Select
            onChange={onChangeDefaultValue}
            value={defaultValue}
            placeholder=""
            showSearch
            filterOption={(input, option) =>
              typeof option.children === 'string' &&
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            <Select.Option value={null}>
              <IntlMessages id="studio.template.inputs.select.option.noDefault" className="font-italic" />
            </Select.Option>
            <Select.OptGroup label={<IntlMessages id="studio.template.inputs.entityDynamic.header" />}>
              {DYNAMIC_OPTIONS.map((option) => (
                <Select.Option value={option} key={option}>
                  <IntlMessages id={`studio.template.inputs.entityDynamic.${option}`} />
                </Select.Option>
              ))}
            </Select.OptGroup>
            {entitiesOptions && (
              <Select.OptGroup label={<IntlMessages id="studio.template.inputs.entityStatic.header" />}>
                {entitiesOptions.map((row) => (
                  <Select.Option value={row.id} key={row.id}>
                    {row.label}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            )}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
}
