import React, { useState } from "react";
import { Form, Input, AutoComplete, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { getValue } from "./utils/values";

export default function Text(props) {
  const { field, label, inputIndex, path, cardUid, handleChange, language } = props;
  const value = getValue(props, "");
  const helper =
    field.helper && field.helper[language] ? (
      <Tooltip title={field.helper[language]}>
        <i className="ml-1 mdi mdi-help-circle" />
      </Tooltip>
    ) : null;

  if (field.suggest && field.suggest.key) {

    return <CompanyConnectedTextInput
      {...props}
      value={value}
      helper={helper}
    />

  }

  return (
    <Form.Item
      label={
        <>
          {label}
          {helper}
        </>
      }
      extra={field.info ? <small className="muted">{field.info[language]}</small> : null}
    >
      <Input
        type="text"
        name={field.name}
        autoComplete="off"
        onChange={handleChange}
        id={field.name + inputIndex + path + cardUid}
        value={value}
        placeholder=""
        addonBefore={field.prefix}
        addonAfter={field.suffix}
      />
    </Form.Item>
  );
}

function CompanyConnectedTextInput(props) {
  const { field, label, path, cardUid, inputIndex, handleChange, language, value, helper } = props;

  const nodes = useSelector(state => state.nodes)

  const [options, setOptions] = useState([]);

  const onSearch = (searchVal) => {
    const search = searchVal.trim().toLowerCase();
    const { key } = field.suggest;

    setOptions(
      !search
        ? []
        : nodes.filter((item) => item[key].toLowerCase().includes(search)).map((item) => item[key])
    );
  };

  const isNodeConnected = (name = value) =>
    (field.suggest &&
      field.suggest.key &&
      nodes &&
      nodes.length > 1 &&
      nodes.find((node) => node[field.suggest.key] === name)) ||
    null;


  return (
    <Form.Item
      label={
        <>
          <span className={isNodeConnected() ? "text-warning" : ""}>{label}</span>
          {helper}
        </>
      }
      className="tests"
      extra={
        field.info && field.info[language] ? (
          <small className="muted">{field.info[language]}</small>
        ) : null
      }
    >
      <AutoComplete
        dataSource={options}
        // style={{ width: 200 }}
        onSearch={onSearch}
        placeholder=""
        name={field.name}
        autoComplete="off"
        value={value}
        onChange={(value) => {
          handleChange(value, {
            nodeConnected: isNodeConnected(value),
          });
        }}
        id={field.name + inputIndex + path + cardUid}
      />
    </Form.Item>
  );
}
