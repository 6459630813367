import { getStore } from 'appRedux/store';
import { setContractDataRules, setContractDataVariables } from 'appRedux/actions';
import { forceContractUpdatesHighlight } from 'hooks';
import Engine from './draft/engine';
import { Contract } from '../interfaces';
import { Transforms } from '../../import/slate';

function changesToUpdateData(changes, data) {
  return [...changes].reduce((acc, curr) => {
    acc[curr] = data[curr];
    return acc;
  }, {});
}

function Manager() {
  this.drafts = [];
  this.activeDraft = 0;
  this.finishDraftQueue = [];

  this.addDraft = function (contract, options = {}) {
    if (typeof contract !== 'object') return false;
    const { language = 'en' } = options;

    const id = Contract.getId(contract);

    const instance = new Engine(contract, { ...options, manager: this });

    const draft = {
      id,
      data: {},
      contract,
      instance,
      language,
      preventUpdate: false,
      editor: options.editor,
      invokeMethod: (method, ...args) => instance.invokeMethod(method, ...args),
    };
    draft.save = () => console.log('Draft save deprecated.');
    this.drafts.push(draft);
    return draft;
  };

  this.removeDraft = function (id) {
    const index = this.drafts.findIndex((draft) => draft.id === id);
    if (index === -1) return;
    this.drafts.splice(index, 1);
  };

  this.findDraftById = function (id) {
    return this.drafts.find((draft) => draft.id === id);
  };

  this.getActiveDraft = function () {
    return this.drafts[this.activeDraft];
  };

  this.clearDrafts = function () {
    this.drafts = [];
  };

  this.preventUpdate = function (contractId, value) {
    const draft = this.findDraftById(contractId);
    if (!draft) return;
    draft.preventUpdate = value;
    // console.log('Now preventing draft update: ', draft.preventUpdate);
  };

  this.syncNewState = function (state) {
    for (const draft of this.drafts) {
      draft.instance.syncNewState(state);
    }
  };

  this.finishDraft = function (states, options = {}) {
    this.finishDraftQueue.push(() => this._finishDraft(states, options));

    if (this.finishDraftQueue.length === 1) this._runNextFinishDraft();
  };

  this._runNextFinishDraft = async () => {
    if (this.finishDraftQueue.length) {
      const fn = this.finishDraftQueue[0];
      await fn.call();
      this.finishDraftQueue.shift();
      return this._runNextFinishDraft();
    }
  };

  this._finishDraft = async function (states, options) {
    for (const draft of this.drafts) {
      if (options.origin === 'redux' && draft.preventUpdate) {
        continue;
      }
      if (draft.editor) {
        // Ensure the selection is removed on the relevant editor,
        // to prevent any slate selection errors after update.
        Transforms.forceDeselect(draft.editor);
      }
      const draftResult = await draft.instance.finishDraft(states, { editor: draft.editor });
      this.postDraft(draft.instance.id, draftResult);
    }
  };

  this.setEditorOnChange = (id, onChange) => {
    const draft = this.findDraftById(id);
    if (!draft) return;

    draft.editorOnChange = onChange;
  };

  this.setHighlight = (id, highlight) => {
    const draft = this.findDraftById(id);
    if (!draft) return;
    draft.highlight = highlight;
  };

  this.loadEngines = async function (state, opts = {}) {
    for (const draft of this.drafts) {
      if (typeof draft.instance.load === 'function') {
        draft.instance.load(state, opts);
      }
    }
    return true;
  };

  // To be removed when testing is complete.
  this.genesisEngines = async function (state, opts = {}) {
    for (const draft of this.drafts) {
      await draft.instance.genesis(state, opts);
    }
    return true;
  };

  this.postDraft = async function (draftId, data) {
    try {
      if (forceContractUpdatesHighlight && forceContractUpdatesHighlight.highlight)
        forceContractUpdatesHighlight.highlight();

      const { changedVariables, currentVariables, changedRules, currentRules } = data;
      const { dispatch } = getStore();
      if (changedVariables.size) {
        const variableUpdateData = changesToUpdateData(changedVariables, currentVariables);
        dispatch(setContractDataVariables(variableUpdateData));
      }
      if (changedRules.size) {
        const rulesUpdateData = changesToUpdateData(changedRules, currentRules);
        dispatch(setContractDataRules(rulesUpdateData));
      }
    } catch (err) {
      console.log('Post draft error ', err);
    }
  };

  /*******/

  this.navigateTo = function (opts) {
    if (!opts || this.drafts.length === 0) return;

    const { template_id, each_label_id } = opts;

    const draft = this.getActiveDraft();

    if (!draft || !draft.editor) return;

    if (template_id)
      draft.editor.scrollToTemplateId(template_id, {
        highlight: true,
      });
    else if (each_label_id)
      draft.editor.scrollToDataMatch('_each_label_id', each_label_id, {
        highlight: true,
      });
  };

  /********/
}

const manager = new Manager();
export default manager;
