import React, { useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Form, Select } from 'antd';
import { getValue, useSiblingValues } from './utils/values';
import { useContract } from 'hooks';
import { Entity } from 'core/interfaces';

const { Option } = Select;

function InputSelect(props) {
  const { label, handleChange, language, path, cardUid, field, inputIndex } = props;

  const entities = useEntities();

  const value = getValue(props, field.value ? field.value : '');

  const id = field.name + inputIndex + path + cardUid;
  const { allowCustomOptions, target } = field;

  let content = null;
  if (allowCustomOptions && Array.isArray(field.content)) {
    content = (
      <CustomOptionsSelect
        defaultOptions={field.content}
        value={value}
        handleChange={handleChange}
        language={language}
        fieldName={props.fieldName}
      />
    );
  } else if (target && typeof target === 'object') {
    content = <TargetSelect {...props} />;
  } else {
    content = (
      <Select
        onChange={handleChange}
        className="w-100"
        name={field.name}
        id={id}
        value={value}
        placeholder=""
      >
        {renderOptions(props, entities)}
      </Select>
    );
  }

  return (
    <Form.Item
      label={label || null}
      className="w-100"
      extra={field.info ? <small className="muted d-block">{field.info[language]}</small> : null}
    >
      {content}
    </Form.Item>
  );
}

function renderOptions(props, entities) {
  const { language, path, cardUid, field, inputIndex } = props;

  if (field.content === 'node-names') {
    return [].concat(
      entities
        .filter((entity) => entity.parentId !== null)
        .map((entity, entityIndex) => {
          const name = Entity.name(entity);
          return (
            <Option key={entity.id + path + cardUid + inputIndex + entityIndex + field.name} value={name}>
              {name}
            </Option>
          );
        })
    );
  }

  if (!field.content) {
    console.log('No field content?? ', { field, props });
    return null;
  }

  return field.content.map((option, contentIndex) => (
    <Option
      key={option.id + path + cardUid + inputIndex + contentIndex + field.name}
      disabled={option.disabled}
      value={
        option.id
        /* option.values && option.values.hasOwnProperty(language)
          ? option.values[language]
          : option.id */
      }
    >
      {option.label[language]}
    </Option>
  ));
}

function useEntities() {
  return useSelector((state) => state.entities);
}

export default InputSelect;

function CustomOptionsSelect({ defaultOptions, value, handleChange, language, fieldName }) {
  const [options, setOptions] = useState(defaultOptions || []);
  const currentSearchTextRef = useRef();
  const contract = useContract();

  const handleNewOptions = (newOptions) => {
    // contract
    if (Array.isArray(contract.data.ui.inputs[fieldName]?.content)) {
      contract.data.ui.inputs[fieldName].content = newOptions;
    }
    setOptions(newOptions);
  };

  function onSearch(val) {
    const val_key = val.toLocaleUpperCase().replace(/ /g, '_');
    const existsInOptions = options.find(({ id }) => id === val_key);
    if (existsInOptions) return;
    currentSearchTextRef.current = val;
    handleNewOptions([
      ...defaultOptions,
      { id: val_key, values: { [language]: val }, label: { [language]: val } },
    ]);
  }
  function onChange(id) {
    if (currentSearchTextRef.current !== id && options.length > defaultOptions.length) {
      // A search was made, but another value (a proposal) was selected
      // Remove the added search text (last entry).
      handleNewOptions(options.slice(0, -1));
    }
    handleChange(id);
  }

  return (
    <Select
      showSearch
      value={value}
      className="w-100"
      placeholder=""
      optionFilterProp="children"
      onChange={onChange}
      onSearch={onSearch}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {options.map(({ id, value, label }) => (
        <Option key={id} value={id}>
          {label[language]}
        </Option>
      ))}
    </Select>
  );
}

function TargetSelect(props) {
  const { card, cardUid, field } = props;
  const { target } = field;
  if (target.type === 'entities') {
    if (
      target.fromEntityField &&
      typeof target.fromEntityField === 'string' &&
      Array.isArray(target.targetRelatives)
    ) {
      return <TargetEntitiesFromEntity {...props} />;
    }
  }
  console.log('Invalid target select data ', props);
  return null;
}

function TargetEntitiesFromEntity(props) {
  const { card, cardUid, field, value, handleChange } = props;
  const { target } = field;

  const entities = useEntities();
  const siblingValues = useSiblingValues(card, cardUid);

  const masterEntity = useMemo(() => {
    if (!entities) return null;
    if (!siblingValues) return null;
    const value = siblingValues[target.fromEntityField];
    if (!value || !value.id) return null;
    const entity = Entity.getById(entities, value.id);
    if (!entity) return null;
    return entity;
  }, [entities, siblingValues, target.fromEntityField]);

  const availableEntities = useMemo(() => {
    if (!masterEntity) return [];
    return target.targetRelatives.reduce((acc, curr) => {
      if (masterEntity[curr]) acc = [...acc, ...masterEntity[curr]];
      return acc;
    }, []);
  }, [masterEntity, target.targetRelatives]);

  function onChange(values) {
    handleChange(values);
    return;
  }

  return (
    <Select
      mode="multiple"
      showSearch
      value={value || []}
      className="w-100"
      placeholder=""
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {availableEntities.map((entity) => (
        <Option key={entity.id} value={entity.id}>
          {Entity.name(entity)}
        </Option>
      ))}
    </Select>
  );
}
