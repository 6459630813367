
import uuid from "uuid-random"
import { getContractValues } from "hooks"
import { nonSplittableElements, filterCopyAttributes, headingToNumber, numberToHeading } from "core/types/elements"
import { Contract } from "core/interfaces"

export const split_node = (editor, app) => {
    // console.log('split', app.properties)
    if (app.properties && nonSplittableElements(app.properties)) {
        // console.log('Split prevented, app', app)
        return false
    }
    if(app.properties && app.properties.type) { // The new node has properties including type.


        nextType(app.properties)

        

        // Set new data
        const newProps = {...app.properties};
        if(!newProps.data) newProps.data = {};
        else newProps.data = {...app.properties.data};
        // newProps.data.template_id = 'newtid';
        newProps.data.item_id = uuid();
        filterCopyAttributes(newProps)
        app.properties = newProps;
    }
    return true; // Return false to stop the default apply logic
}

function nextType(properties) {
    const headerLevelStartsAsText = Contract.getHeaderLevelStartsAsText(getContractValues())
    
    // console.log('Setting headerLevelStartsAsText is... ', headerLevelStartsAsText)

    if(typeof headerLevelStartsAsText === 'number') {
        const currentLevel = headingToNumber[properties.type]
        if(!currentLevel) return

        // If we are at the `headerLevelStartsAsText` level or above, where the header level is text writing,
        // then continue with another of the same type
        if(currentLevel >= headerLevelStartsAsText) return
        
        // Else, go to next level (if there's one) or make it a paragraph
        properties.type = numberToHeading[currentLevel+1] || "paragraph"

    }
    else {
        if(properties.type === 'heading_one')
            properties.type = 'heading_two';
        // Enter on h2 and h3 -> paragraph
        else if(properties.type === 'heading_two' || properties.type === 'heading_three' || properties.type === 'schedule_one' || properties.type === 'schedule_two')
            properties.type = 'paragraph';
    }
}