/* eslint-disable no-confusing-arrow */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
import * as Y from 'yjs';
import { yTextToSlateElement } from './ySlate';

const isVisible = (item, snapshot) =>
  snapshot === undefined
    ? !item.deleted
    : snapshot.sv.has(item.id.client) &&
      (snapshot.sv.get(item.id.client) || 0) > item.id.clock &&
      !Y.isDeleted(snapshot.ds, item.id);

const createComputeYChangeFn = (permanentUserData, entityIdsSet) => (type, id) => {
  const eId =
    type === 'added'
      ? permanentUserData.getUserByClientId(id.client)
      : permanentUserData.getUserByDeletedId(id);

  if (eId) {
    entityIdsSet.add(eId);
  }
  return {
    eId,
    type,
  };
};

export const compare = (sharedType, prevSnap, nextSnap, permanentUserData) => {
  const aS = Y.typeListToArraySnapshot(sharedType, new Y.Snapshot(prevSnap.ds, nextSnap.sv));

  const entityIdsSet = new Set();
  const computeYChange = createComputeYChangeFn(permanentUserData, entityIdsSet);

  const analyseTypeListElement = (t) => {
    if (!t._item.deleted || isVisible(t._item, nextSnap) || isVisible(t._item, prevSnap)) {
      const slateNode = yTextToSlateElement(t, nextSnap, prevSnap, computeYChange);
      return slateNode;
    }
    return null;
  };
  const markup = aS.map(analyseTypeListElement);

  return {
    entityIds: [...entityIdsSet],
    markup,
  };
};
