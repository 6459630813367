import findIndex from 'find-index/findIndex';
import { produce } from '../../../../import/immer';
import { Transforms, Node } from '../../../../import/slate';
import { setByPath } from '../../../utils/general';

function getSlatePathFromRef(ref) {
  return ref && ref.current;
}

export const _affectNodePath = function (nodePathRef) {
  // if (this.transformBeforeMutate)
  //   this._updatedNodePaths.add(nodePathRef);
};
export const _affectNodeListItems = function ({ nodePathRef }) {
  if (this.transformBeforeMutate) this._updateNodeItemJoin.add(nodePathRef);
};

function _mutateInsertChildren(node, content, index) {
  const { children } = node;
  let targetIndex = typeof index === 'number' ? index : children.length;
  if (Array.isArray(content)) {
    node.children = [...children.slice(0, targetIndex), ...content, ...children.slice(targetIndex)];
    return;
  }
  children.splice(targetIndex, 0, content);
}
function _mutateRemoveChildren(nodeEntry, closestParentNodeEntry) {
  const { node } = nodeEntry;
  const { node: parentNode } = closestParentNodeEntry;
  const targetIndex = findIndex(parentNode.children, (n) => n === node);
  parentNode.children.splice(targetIndex, 1);
}

function _mutateReplaceChildren(node, content) {
  node.children = content;
}

function _mutateReplaceText(node, text) {
  node.children[0].text = text;
}

function _setNodeUpdateTime(node, draftTime) {
  if (!node.data) node.data = { _updateTime: draftTime };
  else node.data._updateTime = draftTime;
}
function _setContentUpdateTime(content, draftTime) {
  if (!draftTime) return;
  if (Node.isNode(content)) {
    _setNodeUpdateTime(content, draftTime);
  } else if (Node.isNodeList(content)) {
    content.forEach((node) => _setNodeUpdateTime(node, draftTime));
  }
}
function _stripDraftIds(children) {
  if (!Node.isNodeList(children)) throw new Error('_stripDraftIds expected node children');
  const clearedChildren = [];
  for (const child of children) {
    const { __draftId, children: grandChildren, ...rest } = child;
    const newChild = rest;
    if (grandChildren) newChild.children = _stripDraftIds(grandChildren);
    clearedChildren.push(newChild);
  }
  return clearedChildren;
}
function _sameNewAndOldChildren(newChildren, oldChildren) {
  const _newChildren = _stripDraftIds(newChildren);
  const _oldChildren = _stripDraftIds(oldChildren);

  const stringedNew = JSON.stringify(Node.string({ children: _newChildren }));
  const stringedOld = JSON.stringify(Node.string({ children: _oldChildren }));

  return stringedNew === stringedOld;
}

function hasOneChild(parent, options = {}) {
  const { ifPlaceholder } = options;
  if (parent.children.length === 1) {
    if (ifPlaceholder) {
      return parent.children[0].data && parent.children[0].data.is_each_placeholder;
    }
    return true;
  }
  return false;
}

export const insertChildren = function (nodeEntry, content, index) {
  if (!Node.isNode(content) && !Node.isNodeList(content)) {
    return console.warn('Invalid arguments to insertChildren.', { content });
  }

  const { node, nodePathRef } = nodeEntry;
  const { children } = node;

  if (hasOneChild(node, { ifPlaceholder: true })) {
    return this.replaceChildren(nodeEntry, content);
  }

  const nodePath = getSlatePathFromRef(nodePathRef);
  const targetIndex = typeof index === 'number' ? index : children.length;
  const targetPath = [...nodePath, targetIndex];

  if (this.transformBeforeMutate) {
    if (!Array.isArray(children)) return;
    _setContentUpdateTime(content, this._draftTime);
    this.api.slate.Transforms.insertNodes(this.editor, content, {
      at: targetPath,
      voids: true, // Important, as parents node may be inActive = void
    });
    // this._affectNodePath(targetPath);
  }
  _mutateInsertChildren(node, content, targetIndex);

  this.updateNodeEntry(node, nodePath);
  this.addNodeEntries(targetPath, content, false);
};

export const removeChildren = function (nodeEntry) {
  const { node, parents, nodePathRef } = nodeEntry;
  const nodePath = getSlatePathFromRef(nodePathRef);

  const [closestParentNodeEntry] = parents;
  const { node: closestParent } = closestParentNodeEntry;
  if (hasOneChild(closestParent)) {
    const replacementContent = this.generateEachPlaceholderChild(closestParent);
    return this.replaceChildren(closestParentNodeEntry, replacementContent);
  }

  // Ensure old nodes are removed from `nodes` list
  this.removeFromNodeEntries(node);
  
  if (this.transformBeforeMutate) {
    this.api.slate.Transforms.removeNodes(this.editor, { at: nodePath, voids: true });
  }
  
  _mutateRemoveChildren(nodeEntry, closestParentNodeEntry);
};

export const replaceChildren = function (nodeEntry, initContent) {
  let content = initContent;
  if (!Node.isNodeList(content)) {
    if (Node.isNode(content)) {
      content = [content];
    } else {
      return console.warn('Invalid arguments to replaceChildren.', { content });
    }
  }

  const { node, nodePathRef } = nodeEntry;
  const { children } = node;
  const nodePath = getSlatePathFromRef(nodePathRef);

  if (_sameNewAndOldChildren(content, node.children)) {
    // console.log('Same children.');
    return;
  }

  this.removeFromNodeEntries(children);

  if (this.transformBeforeMutate) {
    _setContentUpdateTime(content, this._draftTime);
    this.api.slate.extra.replaceChildren(this.editor, nodePath, content);
    this._affectNodePath(nodePathRef);
  }
  _mutateReplaceChildren(node, content);

  this.addNodeEntries(nodePath, content, true);
  this.updateNodeEntry(node, nodePath);
};

export const replaceText = function (nodeEntry, text, options = {}) {
  let str = text;

  if (typeof text === 'number') {
    str = text.toString();
  }

  const { node, nodePathRef } = nodeEntry;
  const currentValue = this.api.slate.Node.string(node);
  if (currentValue === str) return;

  const nodePath = getSlatePathFromRef(nodePathRef);

  if (this.transformBeforeMutate) {
    this.api.slate.Transforms.insertText(this.editor, str, { at: nodePath, voids: true });
    _clearTextNodeOfTrackChanges(this.editor, node, nodePath);
    this._affectNodePath(nodePathRef);
  }
  _mutateReplaceText(node, str);

  if (options.setValue) {
    const update = { value: text };
    if (this.transformBeforeMutate) update._updateTime = this._draftTime;

    this.setNodeData(nodeEntry, update);
  }
  this.updateNodeEntry(node, nodePath);
};

function _clearTextNodeOfTrackChanges(editor, node, nodePath) {
  if (node.children[0]) {
    if (node.children[0]._insertedBy || node.children[0]._deletedBy) {
      try {
        Transforms.setNodes(
          editor,
          {
            _insertedBy: null,
            _deletedBy: null,
          },
          { at: [...nodePath, 0], voids: true }
        );
      } catch (err) {
        console.log('Error removing TC marks.', err);
      }
    }
  }
}

export const setNodeData = function (nodeEntry, dotPath, value, debug = false) {
  const { node, nodePathRef } = nodeEntry;
  const nodePath = getSlatePathFromRef(nodePathRef);

  let newData;

  if (typeof dotPath === 'string') {
    newData = produce(node.data || {}, (draftData) => {
      setByPath(draftData, dotPath, value);
    });
  } else if (dotPath && typeof dotPath === 'object') {
    newData = { ...node.data, ...dotPath };
  }

  if (this.transformBeforeMutate) {
    this.api.slate.Transforms.setNodes(this.editor, { data: newData }, { at: nodePath, voids: true });
  }
  node.data = newData;
};
