import React, { useEffect } from 'react';
import { Button, Form, Input, message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { hideMessage } from 'appRedux/actions/Common';
import { showAuthLoader, userSignIn } from 'appRedux/actions/Auth';
import { userSignOut } from 'appRedux/actions/Auth';
import IntlMessages from 'util/IntlMessages';
import { UserOutlined } from '@ant-design/icons';

const FormItem = Form.Item;

const SignIn = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, user } = useSelector(({ auth }) => auth);
  const [form] = Form.useForm();
  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 1000);
    }
  }, [dispatch, showMessage]);

  useEffect(() => {
    if (!user || !user.email) return;
    form.setFieldsValue({
      email: user.email,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, form]);

  const onFinish = (values) => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
  };

  const signOut = () => {
    dispatch(userSignOut());
    history.push('/signin');
  };

  const forgotPwd = () => {
    dispatch(userSignOut());
    history.push('/forgotpwd');
  };

  return (
    <>
      <div style={{ padding: '30px' }} className="flex-column justify-content-center align-items-center">
        <Spin tip="Authenticating..." size="large" spinning={loader}>
          <div className="flex-column justify-content-center align-items-center mb-3">
            <UserOutlined style={{ fontSize: '72px' }} className="text-primary" />
            {user && (
              <div className="text-muted mt-2 fs-sm">
                {user.firstName} {user.lastName}
              </div>
            )}
            <div className="mt-4 font-bold">Re-authentication required</div>
          </div>

          <Form onFinish={onFinish} form={form} className="mt-4" layout="vertical">
            <FormItem
              // label="Email"
              name="email"
              style={{
                display: user && user.email ? 'none' : 'flex',
              }}
              rules={[{ required: true, type: 'email', message: 'The input is not valid E-mail!' }]}
            >
              <Input placeholder="Email" />
            </FormItem>
            <FormItem
              name="password"
              // label="Password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
            >
              <Input type="password" placeholder="Password" />
            </FormItem>
            <FormItem noStyle>
              <div className="flex-column">
                <Button type="primary" className="m-0 mb-2" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
                <div className="d-flex">
                  <Button type="link" className="mb-0 m-0" onClick={signOut}>
                    <IntlMessages id="app.userAuth.signOut" />
                  </Button>
                  <Button type="link" className="mb-0 m-0" onClick={forgotPwd}>
                    <IntlMessages id="app.userAuth.forgotPassword" />
                  </Button>
                </div>
              </div>
            </FormItem>
          </Form>
        </Spin>
      </div>

      {showMessage ? message.error(alertMessage.toString()) : null}
    </>
  );
};

export default SignIn;
