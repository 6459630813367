import antdSv from "antd/lib/locale-provider/sv_SE";
import svMessages from "../locales/sv_SE.json";

const SvLang = {
  messages: {
    ...svMessages
  },
  antd: antdSv,
  locale: 'sv-SE'
};
export default SvLang;
