import React from 'react';
import { useGotoDraft } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import { PrimaryActionsSkeleton } from 'components/Skeletons/Overview';
import { ActionButton } from 'components/ui';

export function PrimaryActions({ doc, currentVersion, setDocument }) {
  const gotoDraft = useGotoDraft(currentVersion?.id, { target: 'version' });

  /*   const approveContract = () => {
    api
      .post("/tasks/approveVersion", {
        versionId: contract.id,
        projectId: project.id,
        data: {
          projectId: project.id,
          documentId: contract.documentId,
          versionId: contract.id,
          type: "version-approval",
        },
        name: "Approve " + contract.name,
        description: "Request for approval of the terms of contract",
      })
      .then((res) => {
        console.log("res data ", res.data);
        if (typeof reloadSeals === "function") reloadSeals();
      })
      .catch((err) => {
        console.log("err self-approving ", err.response);
      });
  }; */

  if (!doc || !currentVersion) {
    return <PrimaryActionsSkeleton />;
  }

  const isEditible = doc.status === 'draft';

  return <div className='d-flex justify-content-center'><OpenDocument isEditible={isEditible} gotoDraft={gotoDraft} /></div>;
}

const OpenDocument = ({ isEditible, gotoDraft }) => {
  return (
      <ActionButton
        type="primary"
        size="large"
        title={
          <>
            <IntlMessages id="desc.Open" /> <IntlMessages id="general.document" />
          </>
        }
        icon={isEditible ? 'mdi-pencil' : 'mdi-eye'}
        onClick={gotoDraft}
      />
  );
};
