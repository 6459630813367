import moment from 'moment';
import { VALUE_FORMAT } from 'core/config/dates';

const ADD_YEARS = 10;
const SUBTRACT_DAYS = 1;

const CARD_NAME = 'general';
const SIGNING_FIELD_NAME = 'signingdate';
const EXPIRATION_FIELD_NAME = 'expirationdate';

const autoSetTerminationDate = (data, state) => {
  const { value } = data;
  const terminationDate = moment(value).add(ADD_YEARS, 'Y').subtract(SUBTRACT_DAYS, 'd').format(VALUE_FORMAT);

  if (terminationDate) {
    return [
      {
        path: `input.${CARD_NAME}.${EXPIRATION_FIELD_NAME}`,
        value: terminationDate,
      },
    ];
  }
};

const inputs = [
  {
    trigger: `${CARD_NAME}->${SIGNING_FIELD_NAME}`,
    mode: 'single',
    function: autoSetTerminationDate,
  },
];

export default inputs;
