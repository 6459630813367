import { memo } from 'react';
import FileList from 'components/File/FileList';

const ProjectFiles = memo(function ProjectFiles({ project, initialFiles }) {
  return (
    <div>
      <FileList
        resourceId={project.id}
        resourceType="Project"
        apiRoute="documents"
        tableType="projects"
        fetchType="all"
        initialFiles={initialFiles}
        hideCard={false}
        hideNewUpload={true}
      />
    </div>
  );
});

export default ProjectFiles;
