import { cssToObj } from './getPastedStyles';
export function onlySpaces(str) {
  return str.trim().length === 0;
}

const CUSTOM_STYLE_CLASSNAME_CONVERTERS = {
  msoList: (value) => {
    const [levelKey, levelValue] = value.split(' ');
    return `@list ${levelKey}:${levelValue}`;
  },

  // l0 level1 lfo1
  // @list l0:level1
};
const CUSTOM_ADDITIONAL_STYLES = {
  msoList: (value) => {
    if (value === 'ignore') return { ignoreItem: true };
    const [, levelValue] = value.split(' ');
    if (!levelValue) return {};
    const level = levelValue.substr(5);
    const intLevel = parseInt(level, 10);
    if (!isNaN(intLevel)) return { listLevel: intLevel };
    return {};
  },
  msoSpecialFormat: (value) => {
    return { listFormat: value };
  },
};

function converStyleToCssData(key, value, styles) {
  let data = {};
  if (CUSTOM_STYLE_CLASSNAME_CONVERTERS[key]) {
    const customClassName = CUSTOM_STYLE_CLASSNAME_CONVERTERS[key](value);
    if (customClassName && styles[customClassName]) {
      data = { ...data, ...styles[customClassName] };
    }
  }
  if (CUSTOM_ADDITIONAL_STYLES[key]) {
    const customStyleValues = CUSTOM_ADDITIONAL_STYLES[key](value);
    if (customStyleValues) {
      data = { ...data, ...customStyleValues };
    }
  }
  return data;
}

function getMatchingStyles(el) {
  if (!el.attributes?.style) return {};
  const { value } = el.attributes.style;
  return cssToObj(value);
}

export function getElementStyles(el, styles) {
  const lowerNodeName = el.nodeName.toLowerCase();
  if (!window.pasteTest) window.pasteTest = [];
  window.pasteTest.push(el);
  // console.log('Get matching styles ', lowerNodeName, el);
  let matches = {};
  if (styles[lowerNodeName]) matches = { ...matches, ...styles[lowerNodeName] };

  for (const className of el.classList) {
    let fullPath = lowerNodeName + '.' + className;
    if (styles[fullPath]) matches = { ...matches, ...styles[fullPath] };
  }

  const matchingStyles = getMatchingStyles(el);
  matches = { ...matches, ...matchingStyles };
  for (const [styleKey, styleValue] of Object.entries(matchingStyles)) {
    const styleCssData = converStyleToCssData(styleKey, styleValue, styles);
    if (styleCssData) {
      matches = { ...matches, ...styleCssData };
    }
  }

  return matches;
}
