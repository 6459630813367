// Default prototype config.

export const settings = {
  replaceEmptyInputs: true,
  replaceEmptyContractStrings: true,
  emptyReplacement: "[**]", // Required if `replaceEmptyInputs` or `replaceEmptyContractString`
  defaultLanguage: "en"
}

export const language = "en"
