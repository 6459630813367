import React, { useState, createContext, useContext, useEffect, useRef } from 'react';

let _globals = {};

// https://codesandbox.io/s/wrap-usecontext-in-react-hooks-drhu2?from-embed
const superContext = (id) => {
  const Context = createContext();
  let setSuperState, addSuperState;
  if (id) _globals[id] = {};

  const clearContext = () => {
    setSuperState = null;
    addSuperState = null;
    if (id) delete _globals[id];
  };

  const Provider = ({ initialValue, value, children }) => {
    const initialState = initialValue || value || (id && _globals[id]) || {};
    const [state, setState] = useState(initialState);
    const isMounted = useRef(false);

    useEffect(() => {
      isMounted.current = true;
      return () => {
        isMounted.current = false;
        clearContext();
      };
    }, []);

    useEffect(() => {
      if (value && value !== state) {
        console.log('Update context master state by forced.');
        setState(value);
      }
    }, [value, state]);

    if (id) {
      _globals[id] = state;
    }
    setSuperState = (newValue) => {
      if (id) {
        if (newValue === null) delete _globals[id];
        else _globals[id] = newValue;
      }
      setState(newValue);
    };
    addSuperState = (newValue) => {
      if (id) {
        _globals[id] = { ..._globals[id], newValue };
      }
      setState({ ...state, ...newValue });
    };

    return <Context.Provider value={state}>{children}</Context.Provider>;
  };

  return {
    Provider,
    useContext: () => useContext(Context),
    setContext: (value) => {
      if (typeof setSuperState === 'function') setSuperState(value);
      else if (_globals[id]) _globals[id] = value;
    },
    addContext: (value) => {
      if (typeof setSuperState === 'function') addSuperState(value);
      else if (_globals[id]) _globals[id] = { ..._globals[id], ...value };
    },
    getGlobal: () => (id ? _globals[id] : undefined),
    get: (arg) => (id ? _globals[id] && _globals[id][arg] : undefined),
    clearContext,
  };
};

export const ModalContext = superContext();
export const AlertContext = superContext();
export const ProjectContext = superContext('project');
export const ContractContext = superContext('contract');
export const DraftContext = superContext('draft');
export const ContractUpdateContext = superContext('contractUpdateContext');
export const StudioEditorInputContent = superContext('studioEditorInputContent');
export const WarningsContext = superContext('warnings');

// Regular contexts
const _YjsEditorContext = createContext();
export const YjsEditorContext = {
  Provider: _YjsEditorContext.Provider,
  useContext: () => useContext(_YjsEditorContext),
};

const _YjsStateContext = createContext();
export const YjsStateContext = {
  Provider: _YjsStateContext.Provider,
  useContext: () => useContext(_YjsStateContext),
};

const _ContractContentContext = createContext();
export const ContractContentContext = {
  Provider: _ContractContentContext.Provider,
  useContext: () => useContext(_ContractContentContext),
};

const _ContractStateContext = createContext();
export const ContractStateContext = {
  Provider: _ContractStateContext.Provider,
  useContext: () => useContext(_ContractStateContext),
};
