import React, { memo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { ConfigProvider, Modal } from 'antd';

import { IntlProvider } from 'react-intl';
import OverlaySignIn from './OverlaySignIn';
import { ProjectContext, ModalContext } from 'contexts/contexts';

import AppLocale from 'lngProvider';
import MainApp from './MainApp';

import Studio from '../../routes/studio';

import Print from '../../routes/print';

import SignIn from '../SignIn';
// import SignUp from "../SignUp";

import { setInitUrl, setTasks, updateClient, setClient } from 'appRedux/actions';

import VerifyUser from 'routes/users/VerifyUser';
import ForgotPassword from 'routes/users/ForgotPassword';
import ResetPassword from 'routes/users/ResetPassword';
import InvitedEntity from 'routes/invite';

import CustomModal from 'components/CustomModal/index';
import CustomAlert from 'components/CustomAlert/index';

import api from 'utils/api';

const DEBUG = process.env.REACT_APP_NODE_ENV === 'development';

const RestrictedRoute = ({ component: Component, hasUser, location, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        hasUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const locale = useSelector((state) => state.settings.locale);
  const { user, initURL } = auth;

  const location = useLocation();
  const history = useHistory();

  const hasUser = user && !!user.id;

  ConfigProvider.config({
    theme: {
      successColor: '#00211f'
    },
  });

  // console.log('My App/index re-rendered.', { history });

  useEffect(() => {
    if (!auth.user || location.search === '?passthrough') return;
    api
      .get('/tasks/my')
      .then((res) => {
        if (!res.data) return;
        dispatch(setTasks(res.data));
      })
      .catch((err) => {
        console.log('Err setting tasks ', err);
      });
  }, [dispatch]);

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(history.location.pathname));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const userWillChange = location.pathname === '/in'
    if (user && user.clientId && !user.isEntityUser && !userWillChange) dispatch(updateClient(user.clientId));
    else dispatch(setClient(null));
  }, [dispatch, user]);

  const goto = useCallback(
    (path, initURL, why, currentPath) => {
      if (DEBUG) console.log('Goto: ', { path, initURL, why, currentPath });
      history.push(path);
    },
    [history]
  );

  useEffect(() => {
    if (location.search === '?passthrough') {
      return;
    } else if (location.pathname === '/') {
      if (!hasUser) {
        goto('/signin', initURL, 1, location.pathname);
      } else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/verify') {
        // console.log('Shall change to main')
        goto('/main', initURL, 2, location.pathname);
      } else {
        goto(initURL, initURL, 3, location.pathname);
      }
    } else if (location.pathname === '/signin' && hasUser) {
      if (initURL && initURL !== '/') {
        if (initURL === '/verify' || initURL === '/resetpwd') {
          goto('/main', '/main', 6, location.pathname);
        } else {
          goto(initURL, initURL, 4, location.pathname);
        }
        dispatch(setInitUrl('/main'));
      } else goto('/main', initURL, 5, location.pathname);
    }
  }, [hasUser, initURL, location, history, goto, dispatch]);

  const currentAppLocale = AppLocale[locale.locale];

  const showReAuth = auth.authOverlay;

  return (
    <ConfigProvider locale={currentAppLocale.antd} theme={{ primaryColor: '#00211f' }}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <ModalContext.Provider initialValue={null}>
          <CustomModal />
          <CustomAlert />
          <ProjectContext.Provider initialValue={null}>
            <Switch>
              <Route exact path="/signin" component={SignIn} />
              {/* <Route exact path="/signup" component={SignUp} /> */}
              <Route exact path="/verify" component={VerifyUser} />
              <Route exact path="/forgotpwd" component={ForgotPassword} />
              <Route exact path="/resetpwd" component={ResetPassword} />
              <Route exact path="/in" component={InvitedEntity} />
              <Route path="/print/project/:project/document/:document/version/:version/" component={Print} />
              {showReAuth && (
                <Modal title={null} visible={showReAuth} closable={false} footer={null}>
                  <OverlaySignIn />
                </Modal>
              )}
              <RestrictedRoute path="/studio/:subject/:id" hasUser={hasUser} component={Studio} />
              <Redirect exact from="/draft/:id" to="/studio/draft/:id" />
              <RestrictedRoute path={`/`} hasUser={hasUser} component={MainApp} />
            </Switch>
          </ProjectContext.Provider>
        </ModalContext.Provider>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
