import { Form, Select } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { Label } from '.';

const { Option } = Select;

export const EntityEditFieldType = ({ onChange, required = true, ...rest }) => {
  return (
    <Form.Item
      {...rest}
      label={
        <Label>
          <IntlMessages id="app.general.Type" />
        </Label>
      }
      name="type"
      rules={[
        {
          required,
          whitespace: true,
          message: 'Type required',
        },
      ]}
    >
      <Select onChange={onChange}>
        <Option value="LegalPerson">
          <IntlMessages id="app.persons.LegalPerson" cap />
        </Option>
        <Option value="RealPerson">
          <IntlMessages id="app.persons.RealPerson" cap />
        </Option>
      </Select>
    </Form.Item>
  );
};
