export default function fixDate(str, opts = { onlyDate: false, breakDateAndTime: false, includeSeconds: false }) {
  if (!str) return '(Unknown date)';
  let fixedDate;
  if (str instanceof Date) fixedDate = str;
  else {
    const dates = str.split(/[- T : .]/);
    fixedDate = new Date(Date.UTC(dates[0], dates[1] - 1, dates[2], dates[3], dates[4], dates[5]));
  }
  let month = fixedDate.getMonth() + 1;
  let day = fixedDate.getDate();
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  let date = fixedDate.getFullYear() + '-' + month + '-' + day;

  if (opts.onlyDate) return date;

  let time =
    ' ' +
    (fixedDate.getHours() < 10 ? '0' + fixedDate.getHours() : fixedDate.getHours()) +
    ':' +
    (fixedDate.getMinutes() < 10 ? '0' + fixedDate.getMinutes() : fixedDate.getMinutes()) +
    (opts.includeSeconds
      ? ':' + (fixedDate.getSeconds() < 10 ? '0' + fixedDate.getSeconds() : fixedDate.getSeconds())
      : '');

  if (!opts.breakDateAndTime) return date + time;

  return (
    <div className="fixed-date">
      <div className="date">{date}</div>
      <div className="time">{time}</div>
    </div>
  );
}