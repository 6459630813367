import { Checkbox, Tooltip, Popover } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';

export function GrayCheckbox({
  checked,
  onChange,
  label,
  children = null,
  snug = false,
  help = null,
  helpType,
}) {
  const style = {
    margin: '0 10px',
    border: '1px solid #d2d2d2',
    borderRadius: '4px',
    padding: '5px',
    paddingRight: '0',
    background: 'rgba(206, 206, 206, 0.27)',
    display: 'inline-block',
    height: '32px',
  };
  if (snug) {
    style.margin = '0 10px 0 -3px';
    style.background = 'rgba(255, 255, 255, 0.27)';
  }

  return (
    <div style={style} className="d-flex align-items-center">
      <Checkbox
        checked={checked}
        onChange={onChange}
        style={{
          margin: !help ? '5px' : '5px 0px 5px 5px',
        }}
      >
        <small>{label}</small>
      </Checkbox>
      {children}
      {help && helpType !== 'popover' && <Helper desc={help} className="mr-1" />}
      {help && helpType === 'popover' && <PopHelper desc={help} className="mr-1" />}
    </div>
  );
}

export function Helper({ desc = null, className = '' }) {
  if (desc === null) return null;

  return (
    <Tooltip title={desc} placement="top">
      <QuestionCircleOutlined className={className} />
    </Tooltip>
  );
}

export function PopHelper({ desc = null, className = '' }) {
  if (desc === null) return null;

  return (
    <Popover content={desc} placement="top">
      <div style={{ maxWidth: '200px' }}>
        <QuestionCircleOutlined className={className} />
      </div>
    </Popover>
  );
}
