import React, { useState, useEffect, useCallback, useRef } from 'react';
import { message, Button, Modal, Upload, notification } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import api from 'utils/api';
import { uploadFile } from 'utils/services/s3';
import { FILE_UPLOAD_ALLOWED_TYPES } from 'core/config/constants';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';

const { Dragger } = Upload;

export default function NewFileUpload({
  children,
  resourceType,
  resourceId,
  onFileUpload,
  showFromStart = false,
  keepModalAfterUpload = false,
  setOpenUploadDialogue,
  showRenderButton = true,
  openFileDialogOnClick = true,
}) {
  const [open, setOpen] = useState(showFromStart);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const fileUploadTarget = useRef(0);
  const filesUploaded = useRef({ list: [] });

  const formatMessage = useIntlMessage();

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    if (typeof setOpenUploadDialogue === 'function') setOpenUploadDialogue(setOpen);
  }, [setOpenUploadDialogue]);

  const customRequest = async ({ onSuccess, onError, file, onProgress }) => {
    const rawFiles = [file];

    const files = rawFiles.map((rawFile) => ({
      name: file.name,
      type: file.type,
      size: file.size,
      uid: file.uid,
    }));

    const data = {
      resourceId,
      resourceType,
      files,
    };

    try {
      const prepareResponse = await api.post('/superfiles/prepare', data);

      if (!prepareResponse.data) {
        console.log('No data in response when creating files');
        message.error(formatMessage('app.file.errors.upload'));
      } else {
        // Map files to be uploaded to presigned urls from server response
        await Promise.all(
          prepareResponse.data.map(async (fileResult) => {
            const rawFile = rawFiles.find((file) => file.uid === fileResult.uid);
            if (!rawFile) throw new Error(`No rawfile for file with uid ${file.uid}`);
            onProgress(rawFile);

            const uploadResult = await uploadFile(rawFile, fileResult.url);
            if (uploadResult.status === 200) {
              const confirmData = {
                resourceId,
                resourceType,
                fileIds: prepareResponse.data.map((data) => data.fileId),
              };
              const confirmResponse = await api.post('/superfiles/confirm', confirmData);
              onSuccess(rawFile);

              filesUploaded.current = { list: [...filesUploaded.current.list, confirmResponse.data[0]] };
              if (filesUploaded.current.list.length === fileUploadTarget.current) {
                onFileUpload(filesUploaded.current.list);
              }
            } else onError(rawFile);
          })
        );

        // All uploads succeeded
        if (keepModalAfterUpload) {
          setUploadCompleted(true);
          filesUploaded.current = { list: [] };
        } else {
          setOpen(false);
          return notification.info({
            message: formatMessage('app.file.uploadSuccess'),
          });
        }
      }
    } catch (err) {
      message.error(formatMessage('app.file.errors.upload'));
    }
  };

  const fileuploadProps = {
    name: 'file',
    multiple: true,
    showUploadList: { showRemoveIcon: false, showDownloadIcon: false },
    accept: FILE_UPLOAD_ALLOWED_TYPES.join(','),
    openFileDialogOnClick: openFileDialogOnClick,
    customRequest: customRequest,
    beforeUpload: (file, fileList) => {
      fileUploadTarget.current = fileList.length;
    },
  };

  const renderButton = () => {
    if (showFromStart) return null;
    return (
      <>
        <Dragger {...fileuploadProps} showUploadList={false}>
          {children ? (
            <div className="clickable upload-file-link" onClick={toggleOpen}>
              {children}
            </div>
          ) : (
            <Button type="primary upload-file-btn">
              <IntlMessages id="app.file.upload" />
            </Button>
          )}
        </Dragger>
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        {open && (
          <div>
            <Dragger {...fileuploadProps}>
              {children ? (
                <>{children}</>
              ) : (
                <>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    <IntlMessages id="app.file.uploadDialougeFirst" />{' '}
                    {uploadCompleted ? <IntlMessages id="app.file.uploadDialougeConsecutive" /> : ''}
                  </p>
                </>
              )}
            </Dragger>
          </div>
        )}
      </>
    );
  };

  const content = renderContent();
  if (showFromStart) return content;

  return (
    <>
      {showRenderButton ? (
        renderButton()
      ) : (
        <Modal
          title={
            <div>
              <div>
                <IntlMessages id="app.file.upload" />
              </div>
            </div>
          }
          visible={open}
          backdrop={'static'}
          onOk={toggleOpen}
          onCancel={toggleOpen}
          footer={[
            <Button key="back" onClick={toggleOpen}>
              {uploadCompleted ? <IntlMessages id="desc.Close" /> : <IntlMessages id="desc.Cancel" />}
            </Button>,
          ]}
          >
            {content}
          </Modal>
        )
      }
    </>
  );
}
