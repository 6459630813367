import React from 'react';
import { Button } from 'antd';
import { useGotoDocumentView, useGotoDraft, useGotoVersionView } from 'hooks';
import IntlMessages from 'util/IntlMessages';

export function MainActionButton({ document }) {
  const gotoDraft = useGotoDraft(document.id);
  const gotoView = useGotoDocumentView({ documentId: document.id, projectId: document.projectId });
  if (document.status === 'draft') {
    return (
      <Button className="m-0" onClick={gotoDraft}>
        <IntlMessages id="app.general.Edit" />
      </Button>
    );
  }
  if (document.status === 'published') {
    return (
      <Button className="m-0" onClick={gotoView}>
        <IntlMessages id="general.view" />
      </Button>
    );
  }
  return null;
}

export function GotoVersionViewSpan({ document, title, children, versionId = null, ...rest }) {
  const gotoView = useGotoVersionView({ documentId: document.id, projectId: document.projectId, versionId });
  if (children) {
    return (
      <span {...rest} onClick={gotoView}>
        {children}
      </span>
    );
  }
  return (
    <div className="d-block w-100 link" {...rest} onClick={gotoView}>
      {title || (
        <>
          <IntlMessages id="app.general.GoTo" /> <IntlMessages id="app.resources.Version" />
        </>
      )}
    </div>
  );
}

export function GotoDocumentViewSpan({ document, title, useName, children, ...rest }) {
  const gotoDocument = useGotoDocumentView({
    documentId: document.id,
    projectId: document.projectId,
  });
  if (children) {
    return (
      <span {...rest} onClick={gotoDocument}>
        {children}
      </span>
    );
  }
  if (useName) {
    return (
      <span {...rest} className="link" onClick={gotoDocument}>
        {document.name}
      </span>
    );
  }
  return (
    <div className="d-block w-100 link" {...rest} onClick={gotoDocument}>
      {title || (
        <>
          <IntlMessages id="app.general.GoTo" /> <IntlMessages id="app.resources.Document" />
        </>
      )}
    </div>
  );
}

export function GotoDraftSpan({ document, title, children, ...rest }) {
  const gotoDraft = useGotoDraft(document.id);
  if (children) {
    return (
      <span {...rest} onClick={gotoDraft}>
        {children}
      </span>
    );
  }
  return (
    <div className="d-block w-100 link" {...rest} onClick={gotoDraft}>
      {title || (
        <>
          <IntlMessages id="app.general.Edit" /> <IntlMessages id="app.resources.Document" />
        </>
      )}
    </div>
  );
}
