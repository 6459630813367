import React, { useState, useEffect } from 'react';
import { Table, Spin, Button } from 'antd';
import { fixDate } from 'components/ui';
import api from 'utils/api';
import IntlMessages from 'util/IntlMessages';

const { Column } = Table;

const config = {
  bordered: false,
  loading: false,
  pagination: { position: 'bottom' },
  size: 'default',
  expandedRowRender: false,
  title: undefined,
  showHeader: true,
  footer: false,
  scroll: undefined,
};

export default function ListVersions({ documentId, onSelect, selectText }) {
  const [versions, setVersions] = useState(null);
  const [versionsError, setVersionsError] = useState(null);

  useEffect(() => {
    api
      .get(
        `/documents/${documentId}/versions?fields=id,name,description,info,status,documentTemplateId,documentId,projectId,createdAt,updatedAt&order=${encodeURI(
          'desc|createdAt'
        )}`
      )
      .then((result) => result && result.data && setVersions(result.data))
      .catch((err) => setVersionsError(err.response));
  }, [documentId]);

  if (!versions) {
    return <Spin className="loader-container" />;
  }
  if (versionsError) {
    return (
      <div>
        <div>Error:</div>
        <pre>{JSON.stringify(versionsError, null, 2)}</pre>
      </div>
    );
  }

  return (
    <Table rowKey="id" className="table-responsive documents-table" {...config} dataSource={versions}>
      <Column
        title="#"
        // dataIndex="versionNumber"
        key="versionNumber"
        sorter={(a, b) => a.info?.versionNumber < b.info?.versionNumber}
        render={(text, record) => record.info?.versionNumber || 'x'}
      />
      <Column
        title={<IntlMessages id="app.general.Name" />}
        dataIndex="name"
        key="name"
        sorter={(a, b) => a.name < b.name}
      />
      <Column
        title={<IntlMessages id="app.general.Description" />}
        dataIndex="description"
        key="description"
      />
      <Column
        title={<IntlMessages id="app.general.Created" />}
        dataIndex="createdAt"
        key="createdAt"
        render={(text) => <small>{fixDate(text, { breakDateAndTime: true })}</small>}
        sorter={(a, b) => a.createdAt < b.createdAt}
      />
      <Column
        title={<IntlMessages id="app.general.LastModified" />}
        dataIndex="updatedAt"
        key="updatedAt"
        render={(text) => <small>{fixDate(text, { breakDateAndTime: true })}</small>}
        sorter={(a, b) => a.updatedAt < b.updatedAt}
      />
      <Column
        title={<IntlMessages id="app.general.Action" />}
        key="actions"
        render={(text, record) => <Button onClick={() => onSelect(record.id)}>{selectText}</Button>}
      />
    </Table>
  );
}
