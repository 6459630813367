import { mapCompaniesToConcepts } from '../../../engine/utils';
export const mapCompanies = function (companies, state) {
  if (!this.getContractCreate().build || !this.getContractCreate().build.concepts) return;

  if (!state) {
    if (!this.states || !this.states.current) return { error: 'NO_STATE' };
    state = this.states.current;
  }

  return mapCompaniesToConcepts({
    companies,
    contract: this.contract,
    state,
  });
};
