import { useState, useEffect, useRef } from 'react';
import { Tooltip, Avatar } from 'antd';
import { Entity } from 'core/interfaces';
import { useYjsEditor } from 'hooks';
// import { awarenessStatesToEntitiesInfo } from 'yjs/utils';

function ConnectedUsers() {
  const { provider } = useYjsEditor();
  const isMounted = useRef(false);

  // const [entitiesInfo, setEntitiesInfo] = useState();
  const [allUsers, setAllUsers] = useState([]);
  
  useEffect(() => {
    // Initially, get states
    // setEntitiesInfo(awarenessStatesToEntitiesInfo(provider.awareness.getStates()));
    setAllUsers(Array.from(provider.awareness.getStates()));
    provider.awareness.on('change', () => {
      if (!isMounted.current) return;
      const { states } = provider.awareness;
      // const _entitiesInfo = awarenessStatesToEntitiesInfo(states);

      // setEntitiesInfo(_entitiesInfo);
      setAllUsers(Array.from(states));
    });
  }, [provider]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Avatar.Group
      className="connected-users-group"
      maxCount={2}
      maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
    >
      {allUsers.map((entry) => {
        const [id, { data }] = entry;
        if (!data) return null;
        return (
          <Tooltip key={id} title={Entity.name(data)} placement="top">
            <Avatar style={{ backgroundColor: data.color }}>{Entity.initials(data)}</Avatar>
          </Tooltip>
        );
      })}
    </Avatar.Group>
  );
}

const ConnectedUsersHolder = () => {
  const { onlineMode, connected } = useYjsEditor();
  if (!onlineMode || !connected) return null;
  return <ConnectedUsers />;
};

export default ConnectedUsersHolder;
