import uuid from 'uuid-random';
import { Contract } from 'core/interfaces';

export const eligibleInputs = [
  'select',
  'radio',
  'text',
  'numeric',
  'numeric-steps',
  'checkbox',
  'date',
  'QA',
];
export const sameValueTypes = [['numeric', 'numeric-steps']];
export const inputTypesWithContentValue = ['select', 'radio', 'grid'];
export const operators = ['==', '!=', '>', '>=', '<', '<=', 'like', 'in'];
export const numOfOperators = ['==', '!=', '>', '>=', '<', '<='];
export const allOperators = [...operators, '===', '!==', '!', '!!'];
export const conjunctions = ['and', 'or'];

export const ruleTypes = {
  conjunction: {
    label: 'Group',
    resolver: (content, conjunctionOrOperator) => {
      return conjunctions.includes(conjunctionOrOperator);
    },
  },
  local: {
    label: 'Current repeatable',
    resolver: (content, conjunctionOrOperator) => {
      const typeIndexInContent = conjunctionOrOperator === 'in' ? 1 : 0;
      return (
        operators.includes(conjunctionOrOperator) &&
        Array.isArray(content) &&
        content[typeIndexInContent].hasOwnProperty('local')
      );
    },
    getCard: (content) => {
      return '__local';
    },
  },
  setup: {
    label: 'Setup value',
    resolver: (content, conjunctionOrOperator) => {
      const typeIndexInContent = conjunctionOrOperator === 'in' ? 1 : 0;
      return (
        operators.includes(conjunctionOrOperator) &&
        Array.isArray(content) &&
        content[typeIndexInContent].hasOwnProperty('setup')
      );
    },
    getCard: (content) => {
      return '__setup';
    },
  },
  input: {
    label: 'Input',
    resolver: (content, conjunctionOrOperator) => {
      const typeIndexInContent = conjunctionOrOperator === 'in' ? 1 : 0;
      return (
        operators.includes(conjunctionOrOperator) &&
        Array.isArray(content) &&
        content[typeIndexInContent].hasOwnProperty('input')
      );
    },
    getCard: (content) => {
      // { '==': [{ input: 'general.general/jurisdiction' }, 'arbitration'] }

      const op = firstKey(content);
      const typeIndexInContent = op === 'in' ? 1 : 0;
      const path = content[op][typeIndexInContent].input;
      if (Array.isArray(path)) return path[0];
      if (typeof path !== 'string') return null;
      const parts = path.split('.');
      return parts && parts[0];
    },
  },
  subrule: {
    label: 'Sub Rule',
    resolver: (content, conjunctionOrOperator) => {
      return (
        operators.includes(conjunctionOrOperator) &&
        Array.isArray(content) &&
        content[0].hasOwnProperty('var')
      );
    },
    sub: true,
  },
  numof_state: {
    label: 'Number of State Entries',
    resolver: (content, conjunctionOrOperator) => {
      return (
        operators.includes(conjunctionOrOperator) &&
        Array.isArray(content) &&
        content[0].hasOwnProperty('numof_state')
      );
    },
    getCard: (content) => {
      // { '<=': [{ numof_state: 'legalPersons' }, 2] },
      const op = firstKey(content);
      const card = content[op][0].numof_state;
      if (Array.isArray(card)) return card[0];
      return card;
    },
  },
  numof: {
    label: 'Number of repeatables',
    resolver: (content, conjunctionOrOperator) => {
      return firstKey(content[0]) === 'numof';
    },
    getCard: (content) => {
      const op = firstKey(content);
      const { card } = content[op][0].numof;
      if (Array.isArray(card)) return card[0];
      return card;
      /* {
        '>': [
          {
            numof: {
              card: 'facility',
              condition: { and: [{ '==': [{ var: 'facility/type' }, 'term'] }] },
            },
          },
          0,
        ],
      }, */
    },
  },
  any_in_card: {
    label: 'Any input value',
    resolver: (content, conjunctionOrOperator) => {
      return (
        operators.includes(conjunctionOrOperator) &&
        Array.isArray(content) &&
        content[0].hasOwnProperty('any_in_card')
      );
    },
    getCard: (content) => {
      // { '==': [{ any_in_card: ['activeCovenants', true] }, true] }
      const op = firstKey(content);
      const [card] = content[op][0].any_in_card;
      if (Array.isArray(card)) return card[0];
      return card;
    },
  },
  all_in_card: {
    label: 'All input values',
    resolver: (content, conjunctionOrOperator) => {
      return (
        operators.includes(conjunctionOrOperator) &&
        Array.isArray(content) &&
        content[0].hasOwnProperty('all_in_card')
      );
    },
    getCard: (content) => {
      // { '==': [{ all_in_card: ['activeCovenants', true] }, true] }
      const op = firstKey(content);
      const [card] = content[op][0].all_in_card;
      if (Array.isArray(card)) return card[0];
      return card;
    },
  },
  no_op: {
    label: ' - No operator -',
    resolver: (content, conjunctionOrOperator) => {
      return conjunctionOrOperator === '_op_op';
    },
    getCard: (content) => {
      return null;
    },
  },
};

export function getType(rule) {
  if (!rule || typeof rule !== 'object' || Object.keys(rule).length !== 1) return null;
  const conjunctionOrOperator = firstKey(rule);
  const content = rule[conjunctionOrOperator];

  for (const type in ruleTypes) {
    if (ruleTypes[type].resolver(content, conjunctionOrOperator)) return type;
  }
}

export function getRuleCard(rule) {
  const type = getType(rule);
  if (!type) return null;
  if (!ruleTypes[type].getCard) return null;
  return ruleTypes[type].getCard(rule);
}

export function getLabel(rule) {
  if (!rule || typeof rule !== 'object' || Object.keys(rule).length !== 1) return null;
  const conjunctionOrOperator = firstKey(rule);
  const content = rule[conjunctionOrOperator];

  for (const type in ruleTypes) {
    if (ruleTypes[type].resolver(content, conjunctionOrOperator)) return ruleTypes[type].label;
  }
}

export function generateEmptyRule(type) {
  let newRule;
  switch (type) {
    case 'input':
      newRule = { '==': [{ input: null }, null] };
      break;
    case 'setup':
      newRule = { '==': [{ setup: null }, null] };
      break;
    case 'local':
      newRule = { '==': [{ local: null }, null] };
      break;
    case 'numof_state':
      newRule = { '==': [{ numof_state: null }, null] };
      break;
    case 'any_in_card':
      newRule = { '==': [{ any_in_card: [null, null] }, true] };
      break;
    case 'all_in_card':
      newRule = { '==': [{ all_in_card: [null, null] }, true] };
      break;
    case 'numof':
      newRule = {
        '>': [{ numof: { card: null } }, 0],
      };
      break;
    default:
      newRule = { _no_op: [] };
      break;
  }
  return newRule;
}

export function getAllCardInputFields(cardName, ui, contract) {
  let all = [];
  const concept = Contract.getConcept(contract, cardName);
  if (
    contract &&
    concept &&
    concept.type === 'reference' &&
    concept.inheritance &&
    Array.isArray(concept.inheritance.inherit)
  ) {
    for (const inherit of concept.inheritance.inherit) {
      all = all.concat(getAllCardInputFields(inherit, ui, contract));
    }
    return [...new Set(all)];
  }
  if (!cardName || !ui || !ui.cards || !ui.cards[cardName]) return all;
  if (!Array.isArray(ui.cards[cardName].inputs_order)) return all;
  all = ui.cards[cardName].inputs_order;
  for (const anotherCardName in ui.cards) {
    if (anotherCardName === cardName) continue;
    const card = ui.cards[anotherCardName];
    for (const inputField of card.inputs_order) {
      if (all.includes(inputField)) continue;
      const input = ui.inputs[inputField];
      if (!input) continue;
      if (input.function === 'connect') continue;
      if (input.linked === cardName && Array.isArray(input.inputs_order)) {
        all = all.concat(input.inputs_order);
      }
    }
  }
  return [...new Set(all.filter((item) => !!item))];
}

export function allowSameValue(input1, input2) {
  if (!input1 || !input1.type || !input2 || !input2.type) return false;
  if (input1.type === input2.type) return true;
  for (const sameValueType of sameValueTypes) {
    if (sameValueType.includes(input1.type) && sameValueType.includes(input2.type)) return true;
  }
  return false;
}

export function defaultValue(input) {
  if (input.type === 'checkbox' && input.multiple) return [];
  if (input.type === 'checkbox' || input.type === 'boolean') return false;
  if (input.type === 'party') return {};
  // if (input.hasOwnProperty('value') && !Number.isNaN(input.value)) return input.value;
  if (inputTypesWithContentValue.indexOf(input.type) > -1) {
    const options = input.content || input.options;
    if (Array.isArray(options)) {
      const validContent = options.filter((item) => !item.disabled);
      return validContent[0].id || '';
    }
  }
  if (input.type === 'multipleText') return [];
  return '';
}

export function hasValue(value) {
  return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
}

export function getAvailableOperators(input) {
  if (!input || !input.type) return ['==', '!='];
  if (input.multiple) return ['in'];
  if (input.type === 'numeric' || input.type === 'numeric-steps') {
    return ['==', '!=', '>', '>=', '<', '<='];
  }
  if (input.type === 'QA') return ['==', '!=', 'like'];
  return ['==', '!='];
}
export function getAvailableOperatorText(operator, options = {}) {
  const { numof = false } = options;
  switch (operator) {
    case '==':
      if (numof) return 'exactly';
      return 'Equals';
    case '!=':
      if (numof) return 'not';
      return 'Not equals';
    case '>':
      if (numof) return 'more than';
      return 'More than';
    case '>=':
      if (numof) return 'equal or more than';
      return 'Equal or more than';
    case '<':
      if (numof) return 'less than';
      return 'Less than';
    case '<=':
      if (numof) return 'equal or less than';
      return 'Equal or less than';
    case 'like':
      return 'LIKE';
    case 'in':
      return 'Contains';
    default:
      return '--';
  }
}

export function ruleToType(rule) {
  if (!rule || typeof rule !== 'object' || Object.keys(rule).length !== 1) return null;
  const conjunctionOrOperator = Object.keys(rule)[0];

  if (conjunctions.includes(conjunctionOrOperator)) return 'conjunction';

  const content = rule[conjunctionOrOperator];

  if (
    operators.includes(conjunctionOrOperator) &&
    Array.isArray(content) &&
    content[0].hasOwnProperty('input')
  )
    return 'input';

  if (operators.includes(conjunctionOrOperator) && Array.isArray(content) && content[0].hasOwnProperty('var'))
    return 'subrule';

  if (
    operators.includes(conjunctionOrOperator) &&
    Array.isArray(content) &&
    content[0].hasOwnProperty('numof_state')
  )
    return 'numof_state';

  if (firstKey(content[0]) === 'numof') {
    return 'numof';
  }

  if (
    operators.includes(conjunctionOrOperator) &&
    Array.isArray(content) &&
    content[0].hasOwnProperty('any_in_card')
  ) {
    return 'any_in_card';
  }
  if (
    operators.includes(conjunctionOrOperator) &&
    Array.isArray(content) &&
    content[0].hasOwnProperty('all_in_card')
  ) {
    return 'all_in_card';
  }

  if (
    operators.includes(conjunctionOrOperator) &&
    Array.isArray(content) &&
    content[0].hasOwnProperty('local')
  ) {
    return 'local';
  }
  if (
    operators.includes(conjunctionOrOperator) &&
    Array.isArray(content) &&
    content[0].hasOwnProperty('setup')
  ) {
    return 'setup';
  }
  if (
    operators.includes(conjunctionOrOperator) &&
    Array.isArray(content) &&
    conjunctionOrOperator === 'in' &&
    ['input', 'setup', 'local'].includes(firstKey(content[1]))
  ) {
    return firstKey(content[1]);
  }

  // {"in":["admin",{"input":"beskri-boyt.scope"}]}]}
}

export function typeToText(type) {
  switch (type) {
    case 'conjunction':
      return 'group';
    case 'input':
      return 'input';
    case 'numof':
      return 'number of repeatables';
    case 'local':
      return 'current repeatable';
    case 'setup':
      return 'setup value';
    case 'any_in_card':
      return 'any input value';
    case 'all_in_card':
      return 'all input values';
    case 'numof_state':
      return 'Number of State Entries';
    default:
      return type;
  }
}

export function isRepeatable(cardName, ui) {
  const card = ui.cards && ui.cards[cardName];
  if (!card) return false;
  return card.isRepeatable;
}

export function rulesArray(rules) {
  return Object.values(rules);
}

export function firstKey(obj) {
  return !!obj && typeof obj === 'object' && Object.keys(obj)[0];
}

/*
0: "select"
5: "radio"
1: "text"
2: "numeric"
3: "numeric-steps"
7: "checkbox"

4: "hr"

6: "newline"

8: "header"
9: "goto_provision"
10: "info"
11: "linkBlock"
12: "date"
13: "grid"
14: "party"
15: "goto_route"
*/

/* FOR SUB RULES */

export function getSubRuleDataById(state, id) {
  const item = state[id];
  if (!item) {
    console.log('no item on ', { state, id });
    return {};
  }
  return getSubRuleData(item);
}

export function getSubRuleData(subRule) {
  const operator = Object.keys(subRule)[0];
  const data = subRule[operator];
  const [varData, value] = data;
  const inputField = varData.var;
  return {
    item: subRule,
    operator,
    data,
    inputField,
    value,
  };
}

export function subRulesAreValid(subRules, ui) {
  const subRulesLength = Object.keys(subRules).length;
  return (
    subRulesLength > 0 &&
    Object.values(subRules).filter((subRule) => subRuleIsValid(subRule, ui)).length === subRulesLength
  );
}

export function subRuleIsValid(subRule, ui) {
  const { operator, inputField, value } = getSubRuleData(subRule);
  return !!(
    operator &&
    inputField &&
    ui.inputs &&
    ui.inputs[inputField] &&
    value !== undefined &&
    value !== null
  );
}

export function valueAcceptedForSubRule(ui, inputField, value, suppliedInputObj) {
  const inputObj =
    typeof suppliedInputObj === 'object' ? suppliedInputObj : ui.inputs && ui.inputs[inputField];
  if (!inputObj) return console.log('valueAcceptedForSubRule: Could not resolve inputObj.');

  switch (inputObj.type) {
    case 'text':
      return typeof value === 'string';
    case 'number':
    case 'numeric':
    case 'numeric-steps':
      return typeof value === 'number' || !isNaN(parseFloat(value));
    case 'radio':
    case 'select':
      const options = Array.isArray(inputObj.content) ? inputObj.content : inputObj.options;
      return !!(Array.isArray(options) && options.find((item) => !item.disabled && item.id === value));
    case 'checkbox':
      if (inputObj.multiple) return typeof value === 'string';
      return typeof value === 'boolean';
    case 'QA':
      return typeof value === 'string';
    default:
      return false;
  }
}

export function typeAccepts(type, value) {
  switch (type) {
    case 'text':
    case 'radio':
    case 'select':
      return typeof value === 'string';
    case 'number':
    case 'numeric':
    case 'numeric-steps':
      return typeof value === 'number' || !isNaN(parseFloat(value));
    case 'checkbox':
    case 'boolean':
      return typeof value === 'boolean';
    default:
      return false;
  }
}

export function subRuleReducer(state, action) {
  switch (action.type) {
    case 'reset':
      return [];
    case 'add':
      return { ...state, [uuid()]: { '==': [{ var: null }, null] } };
    case 'remove':
      if (!action.hasOwnProperty('id')) return state;

      const copyState = { ...state };
      delete copyState[action.id];
      return copyState;

    case 'set_operator':
      if (!action.hasOwnProperty('id')) return state;
      const { data } = getSubRuleDataById(state, action.id);
      if (!data) return state;

      const newItemX = { [action.payload]: data };

      return { ...state, [action.id]: newItemX };

    case 'set_input':
      if (!action.hasOwnProperty('id')) return state;

      const { operator: operatorX, value } = getSubRuleDataById(state, action.id);
      if (!operatorX) return state;

      const newItemY = { [operatorX]: [{ var: action.payload }, value] };
      return { ...state, [action.id]: newItemY };
    case 'set_value':
      if (!action.hasOwnProperty('id')) return state;

      const { operator, inputField } = getSubRuleDataById(state, action.id);
      if (!operator) return state;

      if (!action.ui) {
        // console.warn('set_value should receive action.ui');
      } else {
        if (!valueAcceptedForSubRule(action.ui, inputField, action.payload)) {
          return state;
        }
      }

      const newItemZ = { [operator]: [{ var: inputField }, action.payload] };
      return { ...state, [action.id]: newItemZ };

    default:
      throw new Error();
  }
}

// Example rule, all repeatables conform to the condition.
/* 
const all_rep = {
  '==': [
    { numof: { card: 'facility', condition: { and: [{ '==': [{ var: 'facility/type' }, 'rcf'] }] } } },
    { numof: { card: 'facility' } },
  ],
}; */
