import React, { useRef, useEffect, useState, memo } from 'react';
import { Path } from 'slate';
import { ReactEditor } from 'slate-react';
import { useSelector, useDispatch } from 'react-redux';
import { setDraft } from 'appRedux/actions';
import { Tooltip } from 'antd';
import { fixDate } from 'components/ui';

export const Conversations = memo(({ leaf, children, attributes, editor, extraAttributes }) => {
  const dispatch = useDispatch();
  const ref = useRef();
  const [currentPath, setCurrentPath] = useState(null);

  // Ensure that we receive the proper slate path of the leaf.
  useEffect(() => {
    if (ref.current !== undefined) {
      try {
        const slateNode = ReactEditor.toSlateNode(editor, ref.current);
        const path = ReactEditor.findPath(editor, slateNode);
        if ((currentPath === null && path !== null) || !Path.equals(currentPath, path)) {
          setCurrentPath(path);
        }
      } catch (err) {}
    }
  }, [editor, setCurrentPath, currentPath]);

  const onClick = () => {
    if (!Array.isArray(leaf._conv) || leaf._conv.length === 0) return;
    if (ref.current && ref.current.closest('.element-with-conversation-items.active')) {
      const lastId = leaf._conv.slice(-1)[0];
      dispatch(setDraft('viewingConversationId', lastId));
    }
  };

  const conversationClassName = Array.isArray(leaf._conv)
    ? `_convs ${leaf._conv.map((id) => 'conv_' + id).join(' ')}`
    : '';
  const extraAttributesClassName = extraAttributes.className ? ' ' + extraAttributes.className : '';

  const className = `${conversationClassName} ${extraAttributesClassName}`;

  return (
    <span className="editor-comment-text position-relative">
      {leaf._conv.map((id) => {
        return (
          <Conversation
            key={id}
            id={id}
            fontSize={attributes.style?.fontSize}
            path={currentPath}
            editor={editor}
          />
        );
      })}
      <span {...attributes} {...extraAttributes} ref={ref} className={className} onClick={onClick}>
        {children}
      </span>
    </span>
  );
});

const Conversation = memo(({ id, fontSize = '1em', path, editor }) => {
  const dispatch = useDispatch();
  const posts = useSelector(({ conversations }) => conversations[id]?.posts);
  if (!posts) return null;
  if (!path) {
    return null;
  }
  // We only want to display the conversation indicator
  // for the first leaf that has the relevant conversation
  // (in case of nested conversations).
  if (!editor._convFirstLeafs[id]) {
    editor._convFirstLeafs[id] = path;
  } else {
    const otherPath = editor._convFirstLeafs[id];
    if (Path.isBefore(path, otherPath) || Path.equals(path, otherPath)) {
      editor._convFirstLeafs[id] = path;
    } else {
      return null;
    }
  }

  const dates = posts.map((post, index) => (
    <div key={index}>
      {fixDate(new Date(post.time).toISOString())}: {getPostUserName(post)}
    </div>
  ));

  return (
    <Tooltip
      // title={dates}
      title="Click to open conversation"
      placement="left"
      className="editor-comment-tooltip"
      contentEditable={false}
      onClick={() => {
        dispatch(setDraft('viewingConversationId', id));
      }}
      /* onMouseEnter={() => {
        for (const elem of document.getElementsByClassName('conv_' + id))
          elem.style.borderBottom = '2px solid rgb(255, 170, 68)';
      }}
      onMouseLeave={() => {
        for (const elem of document.getElementsByClassName('conv_' + id)) elem.style.borderBottom = 'none';
      }} */
    >
      <i
        className="mdi mdi-comment editor-comment-icon"
        contentEditable={false}
        style={{
          marginTop: '-' + fontSize,
          userSelect: 'none',
        }}
      />
    </Tooltip>
  );
});

function getPostUserName(post) {
  // console.log('Post user ? ', post)
  if (!post || !post.by || !post.by.firstName) return 'Unknown';
  return post.by.firstName + ' ' + post.by.lastName;
}
