import { Contract, Concept } from '../../../../interfaces';
import findIndex from 'find-index/findIndex';

const fieldNamesConcerned = ['party/definition'];

const entityPropertiesConcerned = ['name', 'firstName', 'lastName', 'identificationNumber'];

export const listPartiesModuleSlate = {
  id: 'listPartiesModuleSlate',
  dependencies: { repeatableAdd: true, repeatableRemove: true, repeatableChange: true, entityAny: true },
  match: ({ entry, node, api }) => {
    return api.utils.engine.matchModule(node, 'facilityAgreement', 'listParties');
  },
  handler: function ({ state, handlerInvoked, entries, paths, api, matches }) {
    let shallReGenerate = false;
    const concepts = api.interfaces.Contract.getConcepts(this.contract);

    for (const entry of entries) {
      const { cardId, fieldName, path, pathInvoked } = entry;

      if (pathInvoked.repeatableChange) {
        if (path.endsWith('id') || fieldNamesConcerned.includes(fieldName)) {
          shallReGenerate = true;
          break;
        }
      } else if (pathInvoked.entityAny) {
        const propertyConcerned = api.interfaces.InputPaths.getLast(path);
        if (entityPropertiesConcerned.includes(propertyConcerned)) {
          shallReGenerate = true;
          break;
        }
      } else {
        const matchingConcept = concepts.find((c) => c.stateId === cardId);
        if (matchingConcept && matchingConcept.contractParty) {
          shallReGenerate = true;
          break;
        }
      }
    }
    // this.log('Shall regenerate list of parties: ', shallReGenerate);
    if (!shallReGenerate) return;

    for (const { node, actions } of matches.default) {
      // SLATE_ENGINE_FIX

      // FIX: Now we recieve entirely new nodes. Write more granular function to figure
      // out if we shall add a list_item (on repeatableAdd), remove a list item (on repeatableRemove)
      // or update a particular list item (on repeatableChange or entityAny).
      // The trailing paragraph (currently received from `listTheParties`) shall

      const listedParties = this.listTheParties(node, state);
      if (!Array.isArray(listedParties)) continue;
      api.utils.engine.itemJoiner(listedParties, { language: this.language });
      actions.replaceChildren(listedParties);
    }
  },
};

function getContractPartyConcept(contract, id) {
  const concept = Concept.getByStateId(contract, id);
  return concept && concept.contractParty && concept;
}
function getContractPartyConcepts(contract) {
  return Contract.getConcepts(contract).filter((c) => c.contractParty);
}

function producePartyFields(engine, fieldPath) {
  const makeField = (key) =>
    engine.makeField('entity', `[${key}]`, {
      fieldPath,
      key,
    });
  return [
    engine.makeTextNode(''),
    makeField('name'),
    engine.makeTextNode(', reg no '),
    makeField('identificationNumber'),
    engine.makeTextNode(', a limited liability company incorporated and existing under the laws of '),
    makeField('jurisdiction'),
    engine.makeTextNode(' whose registered office is at '),
    makeField('address'),
    engine.makeTextNode(''),
  ];
}

function findTargetIndex(contract, closestParent, cardId) {
  const concepts = Contract.getConcepts(contract);
  const { children } = closestParent;
  const cardsConceptIndex = findIndex(concepts, (c) => c.stateId === cardId);

  // Place amongst the others.
  for (var i = children.length - 1; i >= 0; i--) {
    const child = children[i];
    if (!child.data) continue;
    if (child.data._each_repeatable_name === cardId) {
      return i + 1;
    }
  }

  // Place according to its concept's index.
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (!child.data) continue;
    const otherConceptIndex = findIndex(concepts, (c) => c.stateId === child.data._each_repeatable_name);
    if (otherConceptIndex === -1) continue; // Wtf.
    if (cardsConceptIndex < otherConceptIndex) {
      return i;
    }
  }
  return children.length;
}

export const listPartiesAddRepeatableParty = {
  id: 'listPartiesAddRepeatableParty',
  dependencies: { repeatableAdd: true },
  match: ({ node }) => {
    return node && node.data && node.data.fill && node.data.fill.method === 'listTheParties';
  },
  handler: function ({ state, entries, matches }) {
    for (const entry of entries) {
      const { cardId, path, value: values } = entry;
      let { _uid } = values;
      const concept = getContractPartyConcept(this.contract, cardId);
      if (!concept) continue;
      const { stateKey: entityKey } = concept;

      const fieldPath = `${cardId}.${entityKey}`;

      for (const { node, actions } of matches.default) {
        const content = producePartyFields(this, fieldPath);
        this.populateEachContent(cardId, content, values, state, entry);

        const listData = {
          _each_repeatable_name: cardId,
          _each_uid: _uid,
          _path: path,
        };
        const listItem = this.makeListItem(content, listData);
        const index = findTargetIndex(this.contract, node, cardId);
        actions.insertChildren([listItem], index);
      }
    }
  },
};

// listThePartiesDefinitions

export const listPartiesAddRepeatablePartyDescriptions = {
  id: 'listPartiesAddRepeatablePartyDescriptions',
  dependencies: { repeatableAdd: true, repeatableRemove: true },
  match: ({ node }) => {
    return node && node.data && node.data.fill && node.data.fill.method === 'listThePartiesDefinitions';
  },
  time: 1,
  handler: function ({ state, entries, matches, api, editor }) {
    getContractPartyConcepts(this.contract);

    const [partiesList, partiesListPath] = api.utils.engine.find(
      editor.children,
      (n) => n.data && n.data.fill && n.data.fill.method === 'listTheParties',
      { mode: 'one', tuple: true }
    );

    if (!partiesList) return console.log('No parties list');

    const numTracker = {};
    const listItems = api.utils.engine.find(
      partiesList,
      (n) => n.type === 'list_item' && n.data && n.data._each_repeatable_name
    );
    for (let i = 0; i < listItems.length; i++) {
      const listItem = listItems[i];
      const { _each_repeatable_name } = listItem.data;
      if (!numTracker[_each_repeatable_name])
        numTracker[_each_repeatable_name] = { num: 1, startIndex: i, endIndex: i };
      else {
        numTracker[_each_repeatable_name].num += 1;
        numTracker[_each_repeatable_name].endIndex = i;
      }
    }
    const dataItems = Object.entries(numTracker)
      .map(([key, val]) => ({ ...val, key }))
      .sort((a, b) => a.startIndex - b.startIndex);

    dataItems.push({
      key: 'party',
      num: listItems.length,
      startIndex: 0,
      endIndex: listItems.length - 1,
    });

    const content = [];
    const format_num = (num) => {
      return api.utils.general.format_number(num + 1, 0, this.contract.data.settings.format.listFormat);
    };
    const conceptValue = (key, many) => {
      const { value = '[**]' } = api.interfaces.Concept.describe(key, many ? 2 : 1, 'en', {
        mock: true,
        contract: this.contract,
      });
      return value;
    };
    for (let i = 0; i < dataItems.length; i++) {
      const dataItem = dataItems[i];
      const isLastItem = i + 1 === dataItems.length;
      const { num, startIndex, endIndex, key } = dataItem;
      const many = num > 1;

      const itemsContent = [];

      const intro = `${many ? 'Parties' : 'Party'} ${
        many ? `${format_num(startIndex)} - ${format_num(endIndex)}` : format_num(startIndex)
      } above ${many ? 'are jointly' : 'is'} referred to as "`;

      itemsContent.push(this.makeTextNode(intro));
      if (many) {
        itemsContent.push(this.makeTextNode(conceptValue(key, true), 'bold'));
        itemsContent.push(this.makeTextNode('" and individually as "'));
      }
      itemsContent.push(this.makeTextNode(conceptValue(key, false), 'bold'));
      itemsContent.push(this.makeTextNode(`"${!isLastItem ? '. ' : ''}`));

      content.push(itemsContent);
    }
    const newCombinedContent = content.flat();
    for (const { node, nodePathRef, actions } of matches.default) {
      actions.replaceChildren(newCombinedContent);
    }

    // engine.api.utils.general.format_number(0, 1, engine.contract.data.settings.format.listFormat)

    // engine.api.interfaces.Concept.describe('the_investor', 1, 'en', { mock: true})

    /*
    Parties [●] – [●] above are jointly referred to as “Founders” and individually as “Founder”. 
    Party [●] above is referred to as “Investor”. 
    Parties [●] – [●] above are jointly referred to as “Parties” and individually as “Party”. 
    
    Each Party should also be referred to as a “Shareholder” or collectively as “Shareholders”.

    */
  },
};
