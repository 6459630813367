import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';

export function getLocalAuth() {
  const encodedAuthToken = localStorage.jwtToken;
  if (!encodedAuthToken) return 'none';

  const authObj = jwt_decode(encodedAuthToken);

  if (authObj.exp < Date.now() / 1000) return 'expired';

  return authObj;
}

export function useUser() {
  const user = useSelector(({ auth }) => auth.user);
  return user;
}
