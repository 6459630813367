/******* HELPER FUNCTIONS ******/
import { useSelector } from 'react-redux';
import { Entity } from 'core/interfaces';
import { splitNumber, combineNumber, getByPath } from 'core/utils/general';

export function useSiblingValues(card, cardUid) {
  const path = `input.${card.id}${cardUid ? `.${cardUid}` : ''}`;
  const siblingValues = useSelector((state) => getByPath(state, path));
  return siblingValues;
}

export function useSiblingEntity(card, cardUid, fieldName) {
  const siblingValues = useSiblingValues(card, cardUid);
  const entities = useSelector(({ entities }) => entities);
  if (!entities) return null;
  if (!siblingValues) return null;
  const value = siblingValues[fieldName];
  if (!value || !value.id) return null;
  const entity = Entity.getById(entities, value.id);
  if (!entity) return null;
  return entity;
}

export function getValue(props = {}, defaultValue) {
  if (props.field && props.value && props.language && props.field.format === 'amount')
    return splitNumber(combineNumber(props.value, props.language), props.language);
  else if (
    props.value &&
    props.field &&
    (props.field.type === 'numeric' || props.field.type === 'numeric-steps') &&
    typeof props.value.toString === 'function'
  ) {
    return props.value.toString();
  } else if (
    (props.field.type === 'numeric' || props.field.type === 'numeric-steps' || props.field.type === 'text') &&
    props.value === 0 &&
    typeof props.value.toString === 'function'
  ) {
    return props.value.toString();
  }

  return props.value || defaultValue;
}
