import React, { useCallback, useMemo, useEffect, useRef } from 'react';
import { Editable, useSlateStatic } from 'slate-react';
import { useSelector } from 'react-redux';
import { ContractUpdateContext } from 'contexts/contexts';
import { RenderElements } from '../editor/legal/elements';
import { RenderLeaf } from '../editor/legal/marks';
import onKeyDown from '../editor/legal/onKeyDown';
import customClass from '../editor/legal/utils/customClass';
import contractDefaults from 'core/config/contractDefaults';
import {
  useContract,
  useContractUpdates,
  useHighlightContractUpdates,
  useStudioMayEdit,
  useStudioAccessLevel,
} from 'hooks';
import { Contract } from 'core/interfaces';
import { CmdSuggestionsWrapper } from '../editor/components/CmdSuggestions/';
import { HoveringField } from '../editor/components/HoveringField/';
import { RemoteCursorOverlay } from '../../yjs/Editor/CursorOverlay';
import LoadEditorOverlay from '../../yjs/Editor/components/LoadEditorOverlay';
import IntlMessages from 'util/IntlMessages';

const { formatting } = contractDefaults;
const { fontFamilies, defaultFont } = formatting;

export const EditorEditable = (props) => {
  const editor = useSlateStatic();
  const onKeyDownRefFunctions = useRef({});
  const contract = useContract();
  const studio_draftMode = useSelector(({ draft }) => draft.studio_draftMode);
  const mayEdit = useStudioMayEdit();
  const accessLevel = useStudioAccessLevel();
  const settings = Contract.getSettings(contract);

  const onEscapeFnRef = useRef();

  editor.onKeyDown = onKeyDown;
  if (!editor.meta) editor.meta = {}; 
  editor.meta.view = props.view;
  editor.meta.external = props.external;
  editor.meta.isEditingTemplate = props.isEditingTemplate;
  editor.meta.markup = props.markup;
  if (!editor.tmp) editor.tmp = {};

  const renderElement = useCallback(
    (props) => (
      <RenderElements {...props} contract={contract} documentDraftMode={studio_draftMode} editor={editor} />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderLeaf = useCallback((props) => <RenderLeaf {...props} editor={editor} />, [editor]);

  /* useEffect(() => {
    if (props.view || props.isEditingTemplate) return;
    manager.setHighlight(Contract.getId(contract), highlight);
  }, [contract, props.view, highlight, props.isEditingTemplate]); */

  const className = useMemo(() => {
    return customClass(editor, props, settings || {}, { accessLevel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, settings, accessLevel]);

  const style = {
    fontFamily:
      (settings && settings.format && fontFamilies[settings.format.fontFace].css) ||
      fontFamilies[defaultFont].css,
  };

  window.usedContract = contract;

  if (typeof props.onInlineCommentChange === 'function') {
    editor.onInlineCommentChange = props.onInlineCommentChange;
  }

  let externalScrollControlY = useMemo(
    () => ({
      fn: null,
    }),
    []
  );

  const onScrollY = useCallback(
    (...args) => {
      // console.log('scroll target ', args)
      // localStorage.setItem('contractScrollTop', args[0].target.scrollTop);
      if (typeof props.onScrollY === 'function') props.onScrollY(...args);
      if (typeof externalScrollControlY.fn === 'function') externalScrollControlY.fn(...args);
    },
    [props, externalScrollControlY]
  );

  const addOnKeyDown = useCallback((id, fn, force) => {
    if (onKeyDownRefFunctions.current[id] && !force) return;
    onKeyDownRefFunctions.current[id] = fn;
  }, []);

  const editorOnKeyDown = useCallback(
    (event) => {
      const allowNext = { current: false };
      const next = () => {
        allowNext.current = true;
      };

      for (const fn of Object.values(onKeyDownRefFunctions.current)) {
        fn(event, editor, next);

        if (!allowNext.current) return;
      }

      onKeyDown(event, editor, { onEscapeFnRef });
    },
    [editor]
  );

  const clearTmpSelection = () => {
    editor.tmp._nextRange = null;
  };

  return (
    <LoadEditorOverlay>
      <div id="editing-editor" className="editing">
        {props.previewUpdates && (
          <>
            <PreviewUpdatesHolder contract={contract} props={props} />
          </>
        )}

        {/* <CustomScrollbars
          disabled={props.print}
          className="editor-scroller"
          onScroll={onScrollY}
          autoHide={false}
        > */}
        <div id="top-editor-holder" className="top-editor-holder" ref={props.holderRef}>
          <HoveringField
            isEditingTemplate={props.isEditingTemplate}
            externalScrollControlY={externalScrollControlY}
            onEscapeFnRef={onEscapeFnRef}
          />
          {accessLevel === 'write' && (
            <CmdSuggestionsWrapper
              isEditingTemplate={props.isEditingTemplate}
              externalScrollControlY={externalScrollControlY}
              onEscapeFnRef={onEscapeFnRef}
              addOnKeyDown={addOnKeyDown}
            />
          )}
          <RemoteCursorOverlay>
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              id="editor-holder"
              className={className}
              style={style}
              placeholder="Enter some text…"
              autoFocus={!props.isEditingTemplate}
              readOnly={props.print || props.readOnly || !mayEdit}
              spellCheck={false}
              onBlur={() => (editor._isFocused = false)}
              onFocus={() => (editor._isFocused = true)}
              onKeyDown={editorOnKeyDown}
              onClick={clearTmpSelection}
              onMouseDown={clearTmpSelection}
              // decorate={decorator}
            />
          </RemoteCursorOverlay>
        </div>
        {/* </CustomScrollbars> */}
      </div>
    </LoadEditorOverlay>
  );
};

export function PreviewUpdatesHolder({ props, contract }) {
  const mayEdit = useStudioMayEdit();
  if (!mayEdit) return null;
  return (
    <ContractUpdateContext.Provider initialValue={{}}>
      <PreviewUpdates contract={contract} props={props} />
    </ContractUpdateContext.Provider>
  );
}

function PreviewUpdates() {
  const { step } = useHighlightContractUpdates();
  const previewUpdates = useContractUpdates();
  const updates = previewUpdates;
  const updatesAbove = (updates && updates.above) || 0;
  const updatesBelow = (updates && updates.below) || 0;

  const goAbove = () => step('above');
  const goBelow = () => step('below');

  return (
    <>
      <div
        onMouseDown={goAbove}
        className={'preview-updates preview-updates-above' + (updatesAbove > 0 ? ' content' : '')}
      >
        <span className="mr-1">{updatesAbove}</span>
        {updatesAbove === 1 ? <IntlMessages id="desc.change" /> : <IntlMessages id="desc.changes" />}
      </div>
      <div
        onMouseDown={goBelow}
        className={'preview-updates preview-updates-below' + (updatesBelow > 0 ? ' content' : '')}
      >
        <span className="mr-1">{updatesBelow}</span>
        {updatesBelow === 1 ? <IntlMessages id="desc.change" /> : <IntlMessages id="desc.changes" />}{' '}
      </div>
    </>
  );
}
