export default function userToName(user) {
  let name = '';
  if (!user) return <em>No name</em>;
  if (user.firstName && user.lastName) name = user.firstName + ' ' + user.lastName;
  else {
    if (user.firstName) name += user.firstName;
    if (user.lastName) name += user.lastName;
  }
  return user.name || name;
}
