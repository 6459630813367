import { Editor, Transforms, Element, Node, Text } from 'slate';
import uuid from 'uuid-random';

function _hasRelevantCurrentMarks(editor) {
  const currentMarks = Editor.marks(editor);
  if (!currentMarks) return null;
  if (currentMarks._deletedBy || currentMarks._insertedBy) return currentMarks;
  return null;
}

function hasTCmarks(editor, entityId, key) {
  const relevantMarks = _hasRelevantCurrentMarks(editor);
  if (relevantMarks && byEntity(relevantMarks, key, entityId)) {
    return relevantMarks[key];
  }
  return null;
}

function changeNotation(editor, eId, key) {
  const existingNotation = hasTCmarks(editor, eId, key);
  if (existingNotation) return existingNotation;
  return {
    eId,
    id: uuid(),
    at: new Date().toISOString(),
  };
}

function addTrackingChangesMark(editor, entityId) {
  // Ensure we have the mark _insertedBy set by default.
  if (!entityId) return;

  const newMarks = {
    ...(Editor.marks(editor) || {}),
    ...(editor.marks || {}),
    _insertedBy: changeNotation(editor, entityId, '_insertedBy'),
  };

  editor.marks = newMarks;
}

function removeTrackingChangesMark(editor) {
  delete editor.marks?._insertedBy;
  delete editor.marks?._deletedBy;
}

function updateTrackingChangesMark(editor, entityId) {
  if (editor._trackChangesEnabled) addTrackingChangesMark(editor, entityId);
  else removeTrackingChangesMark(editor);
}

function byEntity(node, key, entityId) {
  return node[key]?.eId === entityId;
}

export function opByEntity(op, key, entityId) {
  return byEntity(op.node, key, entityId);
}

function textNodeInsertedByEntity(op, entityId) {
  const { node } = op;
  if (!node || !Text.isText(node)) return false;
  return opByEntity(op, '_insertedBy', entityId);
}
function elementInsertedByEntity(op, entityId) {
  const { node } = op;
  if (!node || !Element.isElement(node)) return false;
  return opByEntity(op, '_insertedBy', entityId);
}
function textNodeDeletedByEntity(op, entityId) {
  const { node } = op;
  if (!node || !Text.isText(node)) return false;
  return opByEntity(op, '_deletedBy', entityId);
}
function elementDeletedByEntity(op, entityId) {
  const { node } = op;
  if (!node || !Element.isElement(node)) return false;
  return opByEntity(op, '_deletedBy', entityId);
}

export function setTrackChanges(editor, enabled, entityId) {
  if (!enabled) {
    return disableTrackChanges(editor, entityId);
  }
  enableTrackChanges(editor, entityId);
}

function disableTrackChanges(editor, entityId) {
  editor._trackChangesEnabled = false;
  initTrackingOperationsHandler(editor, entityId);
  removeTrackingChangesMark(editor);
}
function enableTrackChanges(editor, entityId) {
  editor._trackChangesEnabled = true;
  initTrackingOperationsHandler(editor, entityId);
  removeTrackingChangesMark(editor);
  addTrackingChangesMark(editor, entityId);
}

function applyWithoutTrackChanges(editor, entityId, apply, op) {
  if (op.type === 'insert_text') {
    const relevantCurrentMarks = _hasRelevantCurrentMarks(editor);
    if (relevantCurrentMarks) {
      const { _deletedBy, _insertedBy, ...restMarks } = relevantCurrentMarks;
      Transforms.insertNodes(
        editor,
        { text: op.text, ...restMarks },
        { at: { path: op.path, offset: op.offset } }
      );
      return;
    }
  }

  apply(op);
  return;
}

function preventMergeWhenTracking(op) {
  // Allow if merging two matching track changes text nodes
  if (op.properties && (op.properties._insertedBy || op.properties._deletedBy)) return false;
  return true;
}

// Remove node following (1) insert_node and (2) set_selection, is typically a normal
// consequence of the node insertion. Thus, allow that and do not use the custom
// track changes handling.
function ignoreRemoveAfterInsertOp(trackingInfo) {
  const opIndex = trackingInfo.opCount;
  if (opIndex > 1) {
    const firstOp = trackingInfo.prevOps[opIndex - 2];
    const secondOp = trackingInfo.prevOps[opIndex - 1];
    if (firstOp.type === 'insert_node' && secondOp.type === 'set_selection') {
      return true;
    }
  }
  return false;
}

function applyWithTrackChanges(editor, entityId, apply, op, trackingInfo) {
  /* console.log('Do op', op);
  apply(op);
  return; */

  if (op.type === 'remove_text') {
    const { path, offset, text } = op;
    const leaf = Node.get(editor, path);
    if (leaf && leaf._insertedBy?.eId === entityId) {
      apply(op);
    } else {
      const at = {
        anchor: { path, offset },
        focus: { path, offset: offset + text.length },
      };
      Transforms.setNodes(
        editor,
        { _deletedBy: changeNotation(editor, entityId, '_deletedBy') },
        { match: Text.isText, split: true, at }
      );
    }
    return;
  }
  if (op.type === 'remove_node' && !ignoreRemoveAfterInsertOp(trackingInfo)) {
    if (textNodeInsertedByEntity(op, entityId) || elementDeletedByEntity(op, entityId)) {
      apply(op);
      return;
    }
    if (elementDeletedByEntity(op, entityId) || textNodeDeletedByEntity(op, entityId)) {
      console.log('Already marked as deleted by me', op);
      return;
    }
    Transforms.setNodes(
      editor,
      { _deletedBy: changeNotation(editor, entityId, '_deletedBy') },
      { at: op.path }
    );
    return;
  }
  if (op.type === 'insert_text') {
    const currentMarks = Editor.marks(editor);
    if (!currentMarks._insertedBy) editor.marks = changeNotation(editor, entityId, '_insertedBy');
    if (currentMarks._deletedBy) delete editor.marks._deletedBy;
  }
  if (op.type === 'insert_node') {
    Array.from(Node.texts(op.node)).forEach(([leaf]) => {
      leaf._insertedBy = changeNotation(editor, entityId, '_insertedBy');
      delete leaf._deletedBy;
    });
  }
  if (op.type === 'merge_node') {
    if (preventMergeWhenTracking(op)) {
      console.log('Debug note: No merging while track changing . . .');
      return;
    }
  }
  if (op.type === 'move_node') {
    console.log('Debug note: No moving while track changing . . .');
    return;
  }
  trackingInfo.opCount++;
  trackingInfo.prevOps.push(op);
  apply(op);
}

function initTrackingOperationsHandler(editor, entityId) {
  const {
    apply: currentApplyFn,
    _originalApply,
    applyRemoteEvents: currentApplyRemoteEvents,
    _originalApplyRemoteEvents,
  } = editor;

  if (!_originalApply) {
    editor._originalApply = (op) => {
      currentApplyFn(op);
      if (typeof editor.postApply === 'function') {
        editor.postApply(op);
      }
    };
  }
  if (!_originalApplyRemoteEvents) {
    editor._originalApplyRemoteEvents = currentApplyRemoteEvents;
  }

  // Ensure that remote events are applied as is,
  // i.e. not using our apply modifications (which would
  // otherwise make them changes of the current user)
  editor.applyRemoteEvents = (...args) => {
    editor.unrestrictedApply(() => {
      editor._originalApplyRemoteEvents(...args);
    });
  };

  const apply = editor._originalApply;
  const { onChange } = editor;

  const trackingInfo = {
    opCount: 0,
    prevOps: [],
  };

  editor.onChange = (...args) => {
    trackingInfo.opCount = 0;
    trackingInfo.prevOps = [];
    onChange(...args);
  };

  editor.apply = (op) => {
    if (editor._isApplyingUnrestricted) return apply(op);
    if (editor._trackChangesEnabled) applyWithTrackChanges(editor, entityId, apply, op, trackingInfo);
    else applyWithoutTrackChanges(editor, entityId, apply, op);
    updateTrackingChangesMark(editor, entityId);
  };

  return editor;
}
