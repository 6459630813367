import { useState, useEffect, useRef } from 'react';
import URLSearchParams from 'url-search-params';
import { addUiModules } from 'appRedux/inputModule/';
import manager from 'core/engine/manager';
import { useSelector, useDispatch } from 'react-redux';
import { setDraftValues } from '../../../appRedux/actions/Draft';
import useStudioLoad from 'utils/initDraft/1-studioLoad'; // Loads a template or an existing contract draft.
import { Contract } from 'core/interfaces';
import config from 'config/config';

const { ENGINE_SUBJECTS } = config.studio;

function getAccessLevelDraftValues(effectiveAccessLevel, originalAccessLevel) {
  switch (effectiveAccessLevel) {
    case 'read':
      return [['contractFocus', true]];
    case 'comment':
      return [
        ['contractFocus', true],
        ['trackChanges', true],
      ];
    case 'write':
      return [];
    default:
      return [];
  }
}

const useSearchParamDraftValues = (search) => {
  if (!search) return [];
  const searchData = new URLSearchParams(search);
  const studioMode = searchData.get('mode');
  if (studioMode === 'contract') {
    return [
      ['contractFocus', true],
      ['studio_draftMode', 'inline'],
    ];
  }
  return [];
};

export const useStudioSetup = ({ id, subject, search }) => {
  const dispatch = useDispatch();
  const studioLoad = useStudioLoad(subject);
  const [contract, setContract] = useState(null);
  const [editor, setEditor] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadError, setLoadError] = useState(null);
  const draftValues = useRef([]);
  const searchParamDraftValues = useSearchParamDraftValues(search);
  const isTemplate = subject === 'template';
  const addDraftValue = (key, value) => draftValues.current.push([key, value]);
  const addDraftValues = (values) => (draftValues.current = [...draftValues.current, ...values]);

  useEffect(() => {
    (async () => {
      const load = await studioLoad.loadFuntion(id);
      if (load) {
        const { studioPage, editor, contract, originalAccessLevel, effectiveAccessLevel } = load;
        addDraftValue('accessLevel', effectiveAccessLevel);
        addDraftValue('originalAccessLevel', originalAccessLevel);
        addDraftValues(getAccessLevelDraftValues(effectiveAccessLevel, originalAccessLevel));
        addDraftValue('studioPage', studioPage);
        const documentDraftMode = contract && Contract.getDraftMode(contract);
        addDraftValue('studio_draftMode', documentDraftMode);

        if (editor) setEditor(editor);
        setContract(contract);

        const contractModules = contract?.data?.create?.contractModules?.input;

        if (!isTemplate) {
          addUiModules(contractModules, contract);
        }
        document.title = contract.name;
      } else {
        console.log('Load version error: ', load);
        setLoadError(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!contract) return;
    addDraftValues(searchParamDraftValues);
    dispatch(setDraftValues(draftValues.current));
    setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract, dispatch]);

  return { contract, editor, isLoaded, loadError };
};

export const useHandleActiveDraft = (contract, editor, subject) => {
  const isTemplate = subject === 'template';
  const accessLevel = useSelector((state) => state.draft.accessLevel);
  const [activeDraft, setActiveDraft] = useState(null);
  useEffect(() => {
    if (!contract || !editor) return;

    if (ENGINE_SUBJECTS.includes(subject) && accessLevel === 'write') {
      const draft = manager.addDraft(contract, { editor });
      setActiveDraft(draft);
    }
    // Load engine.
    else {
      manager.removeDraft(contract.id);
    }
  }, [accessLevel, editor, contract, subject]);

  return {
    activeDraft,
    doneHandlingDraft: isTemplate || !!activeDraft || (accessLevel && accessLevel !== 'write'),
  };
};
