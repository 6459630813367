import { Form, Input, Select } from 'antd';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { Label } from '.';

const { Option } = Select;

export const EntityEditFieldLastname = ({
  entityType,
  jurisdictionData,
  disabled,
  ...rest
}) => {
  const formatMessage = useIntlMessage();
  return entityType === 'RealPerson' ? (
    <Form.Item
      {...rest}
      label={
        <Label>
          <IntlMessages id="app.general.LastName" />
        </Label>
      }
      name="lastName"
      rules={[
        {
          required: entityType === 'RealPerson',
          whitespace: true,
          message: formatMessage('app.persons.validation.lastName'),
        },
      ]}
    >
      <Input />
    </Form.Item>
  ) : (
    <Form.Item
      {...rest}
      label={
        <Label>
          <IntlMessages id="app.persons.corporateForm" />
        </Label>
      }
      name="form"
    >
      {jurisdictionData && jurisdictionData.corporateForms && jurisdictionData.corporateForms.values ? (
        <Select disabled={disabled}>
          {jurisdictionData.corporateForms.values.map(({ id, code, label }) => (
            <Option key={id} value={code}>
              {label}
            </Option>
          ))}
        </Select>
      ) : (
        <Input />
      )}
    </Form.Item>
  );
};
