import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Button, Modal, Select } from 'antd';
import api from 'utils/api';
import { Entity } from 'core/interfaces';
import IntlMessages from 'util/IntlMessages';
import { EntityEmails } from 'components/ui';

const ALL_ROLES = ['owner', 'admin', 'manager', 'member', 'guest'];
const DEFAULT_ADD_ROLE = 'manager';

function getAssignableRoles(userGroupEntries) {
  if (!userGroupEntries) {
    return [];
  }
  let lowestUserGroupIndex;
  userGroupEntries.forEach(({ groupName }) => {
    const userRoleIndex = ALL_ROLES.findIndex((role) => role === groupName);
    if (userRoleIndex === -1) return;
    if (!lowestUserGroupIndex || userRoleIndex < lowestUserGroupIndex) {
      lowestUserGroupIndex = userRoleIndex;
    }
  });
  if (lowestUserGroupIndex === undefined) {
    return [];
  }
  return ALL_ROLES.slice(lowestUserGroupIndex);
}

export default function NewMemberButton(props) {
  const { project, children } = props;
  const [open, setOpen] = useState(false);

  const user = useSelector((state) => state.auth && state.auth.user);

  const [userEntities, setUserEntities] = useState(null);
  const [userEntitiesError, setUserEntitiesError] = useState(null);

  const [memberEntities, setMemberEntities] = useState(null);
  const [memberEntitiesError, setMemberEntitiesError] = useState(null);

  const [newEntityRoles, setNewEntityRoles] = useState({});

  useEffect(() => {
    api
      .get('/clients/userEntities')
      .then((result) => {
        if (!result || !result.data) return;
        setUserEntities(result.data);
        setNewEntityRoles(
          result.data.reduce((acc, curr) => {
            acc[curr.id] = DEFAULT_ADD_ROLE;
            return acc;
          }, {})
        );
      })
      .catch((err) => setUserEntitiesError(JSON.stringify(err.response)));
  }, [project.id]);

  useEffect(() => {
    api
      .get(
        `/permissions/entries?resourceType=Project&resourceId=${project.id}&accessorType=User&actions&byEntities`
      )
      .then((result) => {
        if (!result || !result.data) return;
        setMemberEntities(result.data);
      })
      .catch((err) => setMemberEntitiesError(JSON.stringify(err.response)));
  }, [project.id]);

  const userMemberEntity = useMemo(() => {
    if (!memberEntities || !user) return null;
    return memberEntities.find((entity) => entity.id === user.entityId);
  }, [user, memberEntities]);

  const userAssignableRoles = useMemo(() => {
    if (!userMemberEntity) return [];
    return getAssignableRoles(userMemberEntity.entries);
  }, [userMemberEntity]);

  // console.log('userMemberEntity', { userMemberEntity, newEntityRoles, userAssignableRoles });

  const onChangeEntityRole = useCallback(
    (entityId, role) => {
      setNewEntityRoles({
        ...newEntityRoles,
        [entityId]: role,
      });
    },
    [newEntityRoles]
  );

  const toggleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const nonMemberUserEntities = useMemo(() => {
    if (!userEntities || !memberEntities) return [];
    const memberIds = memberEntities.map((e) => e.id);
    return userEntities.filter((e) => !memberIds.includes(e.id));
  }, [userEntities, memberEntities]);

  if (!project) {
    return <div>No Project information</div>;
  }

  if (!userEntities) {
    return <div>Could not fetch information of colleagues</div>;
  }

  if (userEntitiesError) {
    return (
      <Card title="" className="card">
        <p>{userEntitiesError}</p>
      </Card>
    );
  }

  const addMember = (entity) => {
    const newMemberRole = newEntityRoles[entity.id] || 'member';

    api
      .post('/permissions/addMember', {
        accessorType: 'User',
        accessorId: entity.userId,
        resourceType: 'Project',
        resourceId: project.id,
        groupName: newMemberRole,
      })
      .then((res) => {
        toggleOpen();
        if (typeof props.onAddMember === 'function') props.onAddMember(res && res.data);
      })
      .catch((err) => console.log('Error adding user ', err.response));

    /* api
      .post('/permissions/addMember?resource=Project&id=' + project.id + '&uid=' + user.userId)
      .then((res) => {
        toggleOpen();
        if (typeof props.onAddMember === 'function') props.onAddMember();
      })
      .catch((err) => console.log('Error adding user ', err.response)); */
  };

  return (
    <>
      {children ? (
        <div className="clickable" onClick={toggleOpen}>
          {children}
        </div>
      ) : (
        <Button type="primary" onClick={toggleOpen}>
          <>
            <IntlMessages id="desc.Add" /> <IntlMessages id="general.Member" />
          </>
        </Button>
      )}
      <Modal
        title={
          <>
            <IntlMessages id="desc.Add" /> <IntlMessages id="general.members" />
          </>
        }
        className="modal-lg"
        visible={open}
        backdrop={'static'}
        onOk={toggleOpen}
        onCancel={toggleOpen}
        footer={[
          <Button key="back" onClick={toggleOpen}>
            <IntlMessages id="desc.Cancel" />
          </Button>,
        ]}
      >
        {open && (
          <div>
            <div className="text-center">
              <div className="card-title mb-4">
                <h6>
                  <IntlMessages id="app.project.members.userWithinOrg" />
                </h6>
              </div>
            </div>
            <Row>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>
                      <b>
                        <IntlMessages id="app.general.Name" />
                      </b>
                    </td>
                    <td>
                      <b>
                        <IntlMessages id="app.general.Email" />
                      </b>
                    </td>
                    <td style={{ minWidth: '200px' }}>
                      <b>
                        <IntlMessages id="app.project.members.addAsRole" />
                      </b>
                    </td>
                    <td></td>
                  </tr>
                  {nonMemberUserEntities.map((entity) => (
                    <tr key={entity.id}>
                      <td>{Entity.name(entity)}</td>
                      <td>
                        <EntityEmails entity={entity} />
                      </td>
                      <td>
                        <Select
                          onChange={(value) => onChangeEntityRole(entity.id, value)}
                          value={newEntityRoles[entity.id]}
                        >
                          {userAssignableRoles.map((role) => (
                            <Select.Option key={role} value={role}>
                              <IntlMessages id={`app.permissions.roles.${role}`} />
                            </Select.Option>
                          ))}
                        </Select>
                      </td>
                      <td>
                        <Button
                          type="primary"
                          onClick={() => {
                            addMember(entity);
                          }}
                        >
                          <>
                            <IntlMessages id="desc.Add" /> <IntlMessages id="desc.as" />{' '}
                            <IntlMessages id="general.member" />
                          </>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Row>
          </div>
        )}
      </Modal>
    </>
  );
}
