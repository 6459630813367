/* eslint-disable import/no-default-export */
import * as Y from 'yjs';
import { withCursors, withYHistory, withYjs } from '@slate-yjs/core';
import { withReact } from 'slate-react';
import { createBaseEditor } from '../../core/utils/editor';
import { withPlugins } from '../../components/editor/legal/plugins';

export function createUserEditor(providedEditor) {
  const editor = providedEditor ? providedEditor : createBaseEditor();
  return withPlugins(withReact(editor));
}

export const createYjsEditor = (provider, userData, providedEditor) => {
  if (!userData) {
    throw new Error('Invalid arguments to createYjsEditor');
  }

  let baseEditor;
  if (provider) {
    const sharedType = provider.document.get('content', Y.XmlText);
    baseEditor = baseEditor = withYHistory(
      withCursors(
        withYjs(createUserEditor(providedEditor), sharedType, { autoConnect: false }),
        provider.awareness,
        {
          data: userData,
        }
      )
    );
  } else {
    baseEditor = createUserEditor(providedEditor);
  }

  baseEditor.tmp = {};
  baseEditor.meta = {};

  return baseEditor;
};
