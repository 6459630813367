import { useHistory } from 'react-router-dom';
import InviteEntity from 'components/document/InviteEntity';
import Export from 'components/document/Export';
import { ActionButton, AutoModal } from 'components/ui';
import IntlMessages from 'util/IntlMessages';
import { hasFeatureFlag } from 'utils/general';
import { Divider } from 'antd';

function getUrl(contract) {
  const { projectId, documentId, id } = contract;
  return `/project/${projectId}/document/${documentId}/version/${id}`;
}

function FinishModalContent({ contract }) {
  const history = useHistory();
  const gotoDoc = () => {
    history.push(getUrl(contract));
  };

  return (
    <div>
      <div>
        <p>
          You have completed all input sections. Your document has been <strong>automatically saved</strong>{' '}
          and you may safely close this tab or{' '}
          <span className="link" onClick={gotoDoc}>
            click here go to the document overview
          </span>
          .
        </p>
      </div>
      <div className="mt-4">
        <Divider>More actions</Divider>
        <div className="finished-studio-action-btns">
          {hasFeatureFlag('deployment') && (
            <AutoModal
              trigger={
                <ActionButton
                  type="secondary"
                  className="mb-1"
                  title={<IntlMessages id="app.general.Export" cap />}
                  icon="mdi mdi-file-export"
                />
              }
              title={<IntlMessages id="app.general.Export" cap />}
            >
              <Export versionId={contract.id} />
            </AutoModal>
          )}
          <InviteEntity
            btnType="secondary"
            documentId={contract.documentId}
            projectId={contract.projectId}
            versionId={contract.id}
          />
          <ActionButton
            type="secondary"
            title={
              <>
                <IntlMessages id="desc.BackTo" cap /> <IntlMessages id="general.document" />{' '}
                <IntlMessages id="general.overview" />
              </>
            }
            icon="mdi mdi-arrow-right"
            onClick={gotoDoc}
          />
        </div>
      </div>
    </div>
  );
}

export default function FinishedInputs({ contract }) {
  return (
    <div className="finished-studio">
      <div>
        <AutoModal
          trigger={
            <ActionButton
              type="primary"
              title={<IntlMessages id="desc.done" cap />}
              icon="mdi mdi-check"
              size="large"
            />
          }
          title={<IntlMessages id="desc.done" cap />}
        >
          <FinishModalContent contract={contract} />
        </AutoModal>
      </div>
    </div>
  );
}
