/* eslint-disable no-use-before-define */
import { yTextToInsertDelta } from './delta';

export function yTextToSlateElement(yText, nextSnap, prevSnap, computeYChange) {
  const delta = yTextToInsertDelta(yText, nextSnap, prevSnap, computeYChange);

  const children =
    delta.length > 0
      ? delta.map((d) => deltaInsertToSlateNode(d, nextSnap, prevSnap, computeYChange))
      : [{ text: '' }];

  return { ...yText.getAttributes(nextSnap), children };
}

export function deltaInsertToSlateNode(insert, nextSnap, prevSnap, computeYChange) {
  if (typeof insert.insert === 'string') {
    return { ...insert.attributes, text: insert.insert };
  }

  return yTextToSlateElement(insert.insert, nextSnap, prevSnap, computeYChange);
}
