import React, { memo } from 'react';
import { Card } from 'antd';
import Chart from 'routes/studio/components/StudioInput/renderDraftUI/components/orgchart/views/edit';
import IntlMessages from 'util/IntlMessages';
import { ActionButton, AutoModal } from 'components/ui';

export default memo(({ entity, entities }) => {
  const onEntityInsert = (entity) => {
    console.log('was inserted ', entity);
  };

  const onEntityRemove = (id) => {
    console.log('was removed ', id);
  };

  const onEntityMove = (entityId, newParentId, oldParentId) => {
    console.log('was moved ', entityId, newParentId, oldParentId);
  };

  if (!entities) return <IntlMessages id="app.general.Loading" />;

  return (
    <AutoModal
      trigger={
        <ActionButton
          type="secondary"
          title={<IntlMessages id="app.project.menus.StructureChart" cap />}
          icon="mdi mdi-sitemap"
        />
      }
      title={<IntlMessages id="app.project.menus.StructureChart" cap />}
      className="modal-lg"
    >
      <div className="padded-page">
        <Card
          title={
            <span>
              <IntlMessages id="app.orgchart.header" />
            </span>
          }
        >
          <Chart
            entities={entities}
            modules={[]}
            onlyView
            topCoId={entity.id}
            topCoEntity={entity}
            onEntityInsert={onEntityInsert}
            onEntityRemove={onEntityRemove}
            onEntityMove={onEntityMove}
          />
        </Card>
      </div>
    </AutoModal>
  );
});
