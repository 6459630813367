import { USE_CALCULATED_AFFECTED_ACP_KEYS } from '../../../../config/draft';

export const activeConditionPreparationSlate = {
  id: 'activeConditionPreparationSlate',
  dependencies: {
    repeatableAdd: true,
    repeatableRemove: true,
    repeatableChange: true,
    ordinary: true,
    entityAny: true,
  },
  time: -10,
  match: true,
  handler: function ({ state, handlerInvoked, entries, paths }) {
    this.updateAffectedACPkeys(entries);
    this._tmpDraft.allAffectedACP = this.analyseAffectedConcepts(entries);
  },
};

export const analyseAffectedConcepts = function (entries) {
  const changesInConceptNumber = [];
  const changesAffectingDetailedFieldMapping = [];

  for (const entry of entries) {
    const { cardId, fieldName, pathInvoked } = entry;
    if (pathInvoked.repeatableAdd || pathInvoked.repeatableRemove) {
      const affected = this.api.interfaces.Concept.affectedConcepts(this.contract, cardId, {
        changedConceptNumber: true,
      });
      if (Array.isArray(affected)) {
        changesInConceptNumber.push(...affected);
        // Fill changesAffectingDetailedFieldMapping with all items in changesInConceptNumber.
        // Reason: If any concept is changed in general (such as number of concept entries/items),
        // then the more detailed changes (affecting mappings etc) will also need to update.
        changesAffectingDetailedFieldMapping.push(...affected);
      }
    } else if (pathInvoked.ordinary || pathInvoked.repeatableChange) {
      const affected = this.api.interfaces.Concept.affectedConcepts(this.contract, cardId, {
        changedConceptNumber: false,
        key: fieldName,
        allowDefinition: true,
      });
      if (Array.isArray(affected)) {
        changesAffectingDetailedFieldMapping.push(...affected);
      }
    }
  }
  return {
    changesInConceptNumber: [...new Set(changesInConceptNumber)],
    changesAffectingDetailedFieldMapping: [...new Set(changesAffectingDetailedFieldMapping)],
  };
};

export const updateAffectedACPkeys = function (entries) {
  if (!USE_CALCULATED_AFFECTED_ACP_KEYS) {
    return;
  }

  // let keys = []
  let keys = this.getAffectedACPkeys(entries);

  // Set key generated condition keys to the temporary draft object.
  // To be used by other activeCondition handlers (as well as enum's, and others)
  this._tmpDraft.updatedACPkeys = Array.isArray(this._tmpDraft.updatedACPkeys)
    ? this._tmpDraft.updatedACPkeys.concat(keys)
    : keys;
};
