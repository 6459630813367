import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setDraft } from 'appRedux/actions/Draft';
import TopToolbar from 'components/editor/legal/toolbar/TopToolbar';
import ZoomComponent from 'components/editor/legal/toolbar/ZoomComponent';
import { useIsTemplateStudio, useStudioMayEdit, useContract } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import { Button } from 'antd';
import api from 'utils/api';

export default function EditTopbar() {
  const mayEdit = useStudioMayEdit();
  const isTemplate = useIsTemplateStudio();

  if (mayEdit || isTemplate) return <TopToolbar isTemplate={isTemplate} />;

  return <ReadOnlyHeader />;
}

function ReadOnlyHeader() {
  const dispatch = useDispatch();
  const originalAccessLevel = useSelector((state) => state.draft.originalAccessLevel);
  const { documentId } = useContract();

  const makeDraftable = async () => {
    const response = await api.post('/documents/' + documentId + '/unpublish', { inStudio: true });
    if (response?.data?.status === 'success') {
      dispatch(setDraft('accessLevel', originalAccessLevel));
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-space-between h-100 w-100">
      <div className="d-flex align-items-center h-100">
        <IntlMessages id="studio.contractPreview" />
        <Button onClick={makeDraftable} className="ml-1">
          Enable editing (unpublish document)
        </Button>
      </div>
      <ZoomComponent />
    </div>
  );
}
