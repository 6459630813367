export const buildRepaymentClauseOffer = function (node, state, environment) {
  if (!state.input || !state.input.facility) return;

  const termFacilities = this.api.utils.general.ofilter(
    state.input.facility,
    (facility) => facility['facility/type'] === 'term'
  );
  const revolvingFacilities = this.api.utils.general.ofilter(
    state.input.facility,
    (facility) => facility['facility/type'] === 'rcf'
  );

  const termLength = this.api.utils.general.ocount(termFacilities);
  const rcfLength = this.api.utils.general.ocount(revolvingFacilities);

  if (termLength === 0) {
    if (node.data.module.type === 'inline') {
      return [this.makeTextNode('[**]')];
    }
    return [this.makeParagraph('[**]')];
  }

  const nums = {
    rcf: rcfLength,
    term: termLength,
    total: rcfLength + termLength,
  };

  let hasIntro = false;
  var repaymentTexts = [];
  for (const [, facility] of Object.entries(termFacilities)) {
    var repaymentText = this.buildRepaymentTextOffer(facility, nums);
    if (nums.total > 1) {
      repaymentText =
        this.api.utils.general.ucfirst(this.translateText('inRelationTo')) +
        ' ' +
        facility['facility/name'] +
        ',' +
        repaymentText;
      hasIntro = true;
    } else repaymentText = this.api.utils.general.ucfirst(repaymentText);

    if (!repaymentText || repaymentText === '') continue;

    repaymentTexts.push(repaymentText);
  }
  console.log('repaym texts ', repaymentTexts);
  if (repaymentTexts.length === 0) {
    if (node.data.module.type === 'inline') {
      return [this.makeTextNode('[**]')];
    }
    return [this.makeParagraph('[**]')];
  }

  if (repaymentTexts.length > 1) {
    let tlIntro =
      this.language === 'en'
        ? 'Repayment in relation to '
        : this.language === 'sv'
        ? 'Återbetalning av '
        : '';
    return [
      this.makeParagraph(tlIntro + this.describeConcept('the_termFacilityOrFacility').text + ':'),
      this.makeBulletList(
        repaymentTexts.map((repayment) => this.makeListItemOneText(repayment)),
        { item_join: 'and' }
      ),
    ];
  } else if (repaymentTexts.length === 1) {
    const nodes = [];
    if (!hasIntro) {
      const the_borrower = this.makeField('concept', '[The Borrower]', { key: 'the_borrower', caps: true });
      const facName = Object.values(termFacilities).map((tf) => tf['facility/name'])[0];
      const the_fac = this.makeField('concept', facName, { key: 'the_termFacilityOrFacility' });
      nodes.push([{ text: '' }, the_borrower, { text: ' shall repay ' }, the_fac]);
    }
    nodes.push({ text: repaymentTexts[0] });
    return [this.makeParagraph(nodes)];
    /* const intro =
      this.describeConcept('the_borrower').text +
      ' shall repay ' +
      this.describeConcept('the_termFacilityOrFacility').text;
    return [this.makeParagraph(intro + repaymentTexts[0])]; */
  }

  return [
    this.makeList(
      repaymentTexts.map((repayment) => this.makeListItemOneText(repayment)),
      { item_join: 'and' }
    ),
  ];
};

export const buildRepaymentTextOffer = function (facility) {
  var returnText = '';
  var datetext = '[**]';
  var divider, mytenor, adjust;
  var deferredPaymentText;

  if (!facility) {
    this.log('Build repayment text got no facility? ', { facility });
    return '[**]';
  }

  const name = facility['facility/name'];
  const fullname = name;
  const type = facility['facility/type'];
  const repayment = facility['facility/repayment'];
  const deferredrepayment = facility['facility/deferredrepayment'];
  const compensaterepaymentdeferral = facility['facility/compensaterepaymentdeferral'];
  const repaymentinterval = facility['facility/repaymentinterval'];
  const tenor = facility['facility/tenor'];
  const offer_multiple_tenors = facility['facility/offer_multiple_tenors'];
  const currency = facility['facility/currency'];
  const termdate = facility['facility/termdate'];
  const tenorformat = facility['facility/tenorformat'];
  const commitment = facility['facility/commitment'];
  const repaymentamount = facility['facility/repaymentamount'];

  if (!repayment || repayment === '' || type !== 'term') return false;

  switch (deferredrepayment) {
    case 'none':
      if (compensaterepaymentdeferral) adjust = 0;
      deferredPaymentText = '';
      break;
    case '6m':
      if (compensaterepaymentdeferral) adjust = 0.5;
      deferredPaymentText =
        ', ' +
        this.translateText('providedThat') +
        ' ' +
        this.translateText('repayment') +
        ' ' +
        this.translateText('repaymentDeferral') +
        ' ' +
        this.num2strnum(6) +
        ' ' +
        this.translateText('months'); /* +
        " " +
        this.translateText("from") +
        " " +
        this.translateText("dateOfThisAgreement"); */
      break;
    case '9m':
      if (compensaterepaymentdeferral) adjust = 0.75;
      deferredPaymentText =
        ', ' +
        this.translateText('providedThat') +
        ' ' +
        this.translateText('repayment') +
        ' ' +
        this.translateText('repaymentDeferral') +
        ' ' +
        this.num2strnum(9) +
        ' ' +
        this.translateText('months'); /* +
        " " +
        this.translateText("from") +
        " " +
        this.translateText("dateOfThisAgreement"); */
      break;
    case '12m':
      if (compensaterepaymentdeferral) adjust = 1;
      deferredPaymentText =
        ', ' +
        this.translateText('providedThat') +
        ' ' +
        this.translateText('repayment') +
        ' ' +
        this.translateText('repaymentDeferral') +
        ' ' +
        this.num2strnum(12) +
        ' ' +
        this.translateText('months'); /* +
        " " +
        this.translateText("from") +
        " " +
        this.translateText("dateOfThisAgreement"); */
      break;
    default:
      adjust = 0;
      deferredPaymentText = '';
  }
  switch (repaymentinterval) {
    case 'interestperiod':
    case 'quarterly':
      divider = 4;
      datetext = this.translateText('quarterly'); /* +
        " " +
        this.translateText("on") +
        " " +
        this.translateText("each") +
        " " +
        this.translateText("interestPaymentDate"); */
      break;
    case 'monthly':
      divider = 12;
      datetext = this.translateText('monthly');
      break;
    case 'semiannually':
      divider = 2;
      datetext = this.translateText('semiAnnually');
      break;
    case 'annually':
      divider = 1;
      datetext = this.translateText('annually');
      break;
    default:
      divider = 1;
      datetext = '[**]';
  }

  if (repayment === 'bullet') {
    returnText +=
      ' ' +
      this.translateText('inItsEntirety') +
      ' ' +
      this.translateText('on') +
      ' ' +
      this.translateText('theTerminationDate') +
      (this.numFacilities > 1 ? ' ' + this.translateText('inRelationTo') + ' ' + fullname : '');
  } else if (repayment === 'linear') {
    if (tenor === 'other') {
      returnText += '[-- REPAYMENT TYPE "other" NOT YET IMPLEMENTED --]';
    } else if (offer_multiple_tenors) {
      returnText +=
        ' ' +
        this.translateText('byLinearRepayment') +
        ' ' +
        datetext +
        /* " " +
          this.translateText("soItIsRepaid") +
          " " +
          this.translateText("inItsEntirety") +
          " " +
          this.translateText("on") +
          " " +
          this.translateText("theTerminationDate") +
          (this.numFacilities > 1
            ? " " + this.translateText("inRelationTo") + " " + fullname
            : "") + */
        deferredPaymentText;
    } else {
      if (tenor === '[**]') {
        returnText +=
          ' ' +
          this.translateText('byRepayingAnAmountOf') +
          ' ' +
          currency +
          ' [**] ' +
          datetext +
          /* " (" +
          this.translateText("and") +
          " " +
          this.translateText("potentialResidualAmount") +
          " " +
          this.translateText("inItsEntirety") +
          " " +
          this.translateText("on") +
          " " +
          this.translateText("theTerminationDate") +
          (this.numFacilities > 1
            ? " " + this.translateText("inRelationTo") + " " + fullname
            : "") +
          ")" + */
          deferredPaymentText;
      } else {
        if (tenor === 'date') {
          let tenorDate = new Date(termdate);
          if (isNaN(tenorDate.valueOf())) {
            return '[enter repayment provision manually]';
          }
          let nowDate = new Date();
          var Difference_In_Time = tenorDate.getTime() - nowDate.getTime();

          // To calculate the no. of years between two dates
          mytenor = Difference_In_Time / (1000 * 3600 * 24 * 365.25);
          // this.log('mytenor', mytenor);
        } else {
          mytenor = parseFloat(tenor);
          if (tenorformat === 'months') mytenor = parseFloat(mytenor / 12);
          if (adjust > 0) mytenor = parseFloat(mytenor - adjust);
        }
        let repaymentAmount;
        if (mytenor === 0) repaymentAmount = '[**]';
        else {
          if (isNaN(mytenor)) repaymentAmount = '[**]';
          else if (commitment !== '' && commitment !== 0) {
            repaymentAmount = this.splitNumber(
              Math.round(
                Math.ceil(
                  parseFloat(parseInt(this.combineNumber(commitment)) / parseFloat(mytenor) / divider)
                ) / 1000
              ) * 1000
            );
          } else repaymentAmount = '[**]';
        }

        returnText +=
          ' ' +
          this.translateText('byRepayingAnAmountOf') +
          ' ' +
          currency +
          ' ' +
          repaymentAmount +
          ' ' +
          datetext +
          /* " (" +
          this.translateText("and") +
          " " +
          this.translateText("potentialResidualAmount") +
          " " +
          this.translateText("inItsEntirety") +
          " " +
          this.translateText("on") +
          " " +
          this.translateText("theTerminationDate") +
          (this.numFacilities > 1
            ? " " + this.translateText("inRelationTo") + " " + fullname
            : "") +
          ")" + */
          deferredPaymentText;
      }
    }

    return returnText;
  } else if (repayment === 'amount') {
    switch (repaymentinterval) {
      case 'interestperiod':
      case 'quarterly':
        datetext = this.translateText('quarterly'); /* +
          " " +
          this.translateText("on") +
          " " +
          this.translateText("each") +
          " " +
          this.translateText("interestPaymentDate"); */
        break;
      case 'monthly':
        datetext = this.translateText('monthly');
        break;
      case 'semiannually':
        datetext = this.translateText('semiAnnually');
        break;
      case 'annually':
        datetext = this.translateText('annually');
        break;
      default:
        datetext = '';
    }

    returnText +=
      ' ' +
      this.translateText('byRepayingAnAmountOf') +
      ' ' +
      currency +
      ' ' +
      (repaymentamount ? this.splitNumber(repaymentamount) : '[**]') +
      ' ' +
      datetext +
      /*  " (" +
      this.translateText("and") +
      " " +
      this.translateText("potentialResidualAmount") +
      " " +
      this.translateText("inItsEntirety") +
      " " +
      this.translateText("on") +
      " " +
      this.translateText("theTerminationDate") +
      (this.numFacilities > 1 ? " " + (this.translateText("inRelationTo") + " " + fullname) : "") +
      ")" + */
      deferredPaymentText;
  }
  return returnText;
};
