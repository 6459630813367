import React, { useMemo } from 'react';
import { Row, Col, Button, Input } from 'antd';
import uuid from 'uuid-random';
import {
  addGridRow,
  removeGridRow,
  updateInput,
  updateInputs,
} from '../../../../../../../appRedux/actions/Input';
import { useSelector, useDispatch } from 'react-redux';
import { getValue } from './utils/values';

function InputGrid(props) {
  const { label, handleChange, language, path, cardUid, field, inputIndex } = props;
  const { name, settings } = field;
  const { repeatable, nameKey, label: gridLabel = 'Net Detb to EBITDA' } = settings;
  const existingRepeatables = useSelector(({ input }) => input[repeatable]);
  const gridId = `grid_${name}`;
  const values = getValue(props, []);
  const dispatch = useDispatch();

  const onValueChange = (id, value) => {
    handleChange(
      values.map((vObj) => {
        if (vObj.id === id) return { id, value };
        return vObj;
      })
    );
  };
  const onRepeatableValueChange = (uid, valueId, value) => {
    console.log('set ', uid, value);
    dispatch(updateInput(`input.${repeatable}.${uid}.${gridId}.${valueId}`, value));
  };
  const addRow = () => {
    const newId = uuid();
    handleChange([...values, { id: newId, value: '' }]);
    setTimeout(() => {
      // FIX.
      dispatch(addGridRow(gridId, newId, settings));
    }, 500);
  };
  const removeRow = (id) => {
    handleChange(values.filter((vObj) => vObj.id !== id));
    setTimeout(() => {
      dispatch(removeGridRow(gridId, id, settings));
    }, 500);
  };
  const resetRows = () => {
    handleChange([]);
    const entries = Object.keys(existingRepeatables).map((key) => {
      return {
        path: `input.${repeatable}.${key}.${gridId}`,
        value: {},
      };
    });
    dispatch(updateInputs(entries));
  };

  const repValues = Object.values(existingRepeatables);

  return (
    <>
      <table className="w-100" border={'0'}>
        <thead>
          <tr>
            <td>{gridLabel}</td>
            {repValues.map((rep) => (
              <td key={rep._uid}>{rep[nameKey]}</td>
            ))}
          </tr>

          {values.map(({ id, value }) => (
            <tr key={gridId + id} className="position-relative">
              <td>
                <span
                  style={{
                    position: 'absolute',
                    left: '-20px',
                    marginTop: '4px',
                  }}
                  className="clickable"
                  onClick={() => removeRow(id)}
                >
                  <i className="mdi mdi-close" />
                </span>
                <Input value={value} onChange={(evt) => onValueChange(id, evt.target.value)} />
              </td>
              {repValues.map((rep) => (
                <td key={rep._uid + id}>
                  <Input
                    value={rep[gridId]?.[id] || ''}
                    onChange={(evt) => onRepeatableValueChange(rep._uid, id, evt.target.value)}
                  />
                </td>
              ))}
            </tr>
          ))}
          {values.length === 0 && (
            <tr>
              <td>
                <em className="pl-2" style={{ lineHeight: '42px' }}>
                  No entries added. Click <strong>Add row</strong>
                </em>
              </td>
            </tr>
          )}
        </thead>
      </table>
      <div className="d-flex align-items-center">
        <Button type="primary" onClick={addRow}>
          Add row
        </Button>
        <span className="ml-2 link" onClick={resetRows}>
          Reset grid
        </span>
      </div>
    </>
  );
}

export default InputGrid;
