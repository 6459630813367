import React, { useState, useEffect, useRef } from 'react';
import {
  eligibleInputs,
  allowSameValue,
  defaultValue,
  getAvailableOperators,
  getAvailableOperatorText,
  valueAcceptedForSubRule,
  getAllCardInputFields,
  firstKey,
} from '../../core/common';
import { Select, Input, InputNumber, Checkbox } from 'antd';

export default function InputRule({
  contract,
  ui,
  language,
  setters,
  ruleId,
  rule,
  contractSetup,
  mode,
  currentCard,
}) {
  let startOperator = '==',
    startCurrentInputObject = null,
    startCurrentInputField = null,
    startValue = '',
    startNegated = false;

  const isInput = mode === 'input';
  const isSetup = mode === 'setup';
  const isLocal = mode === 'local';

  if (rule) {
    startOperator = firstKey(rule);

    if (startOperator === '!') {
      rule = rule['!'];
      startOperator = firstKey(rule);
      startNegated = true;
    }

    let [tmpVars, tmpValue] = rule[startOperator];

    if (startOperator === 'in') {
      // Switch places.
      const _tmpVars = tmpValue;
      const _tmpValue = tmpVars;
      tmpVars = _tmpVars;
      tmpValue = _tmpValue;
    }

    if (isInput && typeof tmpVars.input === 'string') {
      const [, tmpField] = tmpVars.input.split('.');
      const tmpInputObject = ui.inputs && ui.inputs[tmpField];

      startCurrentInputField = tmpField;
      startCurrentInputObject = tmpInputObject;
    } else if (isLocal && typeof tmpVars.hasOwnProperty('local')) {
      startCurrentInputField = tmpVars.local;
      startCurrentInputObject = (ui.inputs && ui.inputs[startCurrentInputField]) || null;
    } else if (isSetup && typeof tmpVars.hasOwnProperty('setup')) {
      startCurrentInputField = tmpVars.setup;
      startCurrentInputObject =
        (contractSetup && contractSetup.find((item) => item.id === startCurrentInputField)) || null;
    }
    startValue = tmpValue;
  }

  const initialCard = useRef(currentCard);
  const hasMounted = useRef(false);
  const [operator, setOperator] = useState(startOperator);
  const [currentInputObject, setCurrentInputObject] = useState(startCurrentInputObject);
  const [currentInputField, setCurrentInputField] = useState(startCurrentInputField);
  const [value, setValue] = useState(startValue);
  const [negated, setNegated] = useState(startNegated);

  useEffect(() => {
    if (isLocal && initialCard.current === '__local') return
    if (initialCard.current !== currentCard) {
      setCurrentInputField(null);
      setCurrentInputObject(null);
      setValue(null);
    }
  }, [currentCard, isLocal]);

  const onInputFieldChange = (newInputName) => {
    let newInput;

    if (isSetup) {
      newInput = contractSetup.find((item) => item.id === newInputName) || null;
    } else {
      newInput = (ui.inputs && ui.inputs[newInputName]) || null;
    }

    if (!newInput)
      return console.log('Input [' + newInputName + '] not present amongst data.ui.inputs!', { isSetup });

    // Check if new input supports current operator. Otherwise, reset to ==
    const availableOperators = getAvailableOperators(newInput);
    if (!availableOperators.includes(operator)) {
      setOperator('==');
    }

    if (!allowSameValue(currentInputObject, newInput)) {
      setValue(defaultValue(newInput));
    }

    // Set input object
    setCurrentInputObject(newInput);
    // Set input field name
    setCurrentInputField(newInputName);
  };
  const onOperatorChange = (value) => {
    setOperator(value);
  };
  const onValueChange = (evt) => {
    if (evt === undefined || evt === null) return;
    if (currentInputObject.type === 'checkbox' && !currentInputObject.multiple) {
      return setValue(evt.target.checked);
    }
    const val = typeof evt === 'string' || typeof evt === 'number' ? evt : evt.target.value;

    if (!valueAcceptedForSubRule(ui, currentInputField, val, currentInputObject)) {
      console.log('Invalid value', val);
      return;
    }

    setValue(val);
  };

  let validRule;

  if (isSetup) {
    validRule = (operator && currentInputField && typeof value !== 'undefined' && value !== null) || false;
  } else {
    validRule =
      (operator && currentCard && currentInputField && typeof value !== 'undefined' && value !== null) ||
      false;
  }

  useEffect(() => {
    if (!hasMounted.current) return;
    if (!validRule) return;
    let rule;

    const valuePathPredix = isInput ? currentCard + '.' : '';
    const valuePath = valuePathPredix + currentInputField;

    if (operator === 'in') {
      // {"in":[ "Ringo", ["John", "Paul", "George", "Ringo"] ]}
      rule = { [operator]: [value, { [mode]: valuePath }] };
    } else {
      rule = { [operator]: [{ [mode]: valuePath }, value] };
    }

    if (negated) {
      rule = { '!': rule };
    }

    setters.current.setRule(ruleId, rule);
  }, [
    negated,
    validRule,
    setters,
    ruleId,
    operator,
    currentCard,
    currentInputField,
    value,
    hasMounted,
    isInput,
    mode,
  ]);

  window.neg = () => setNegated(true);

  useEffect(() => {
    setters.current.setValid(ruleId, validRule);
  }, [ruleId, validRule, setters]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  return (
    <div className="d-flex align-items-center">
      <InputField
        contract={contract}
        ui={ui}
        currentCard={currentCard}
        currentInputField={currentInputField}
        onInputFieldChange={onInputFieldChange}
        language={language}
        contractSetup={contractSetup}
        mode={mode}
      />
      <Operators
        ui={ui}
        currentCard={currentCard}
        currentInputField={currentInputField}
        operator={operator}
        onOperatorChange={onOperatorChange}
        language={language}
        contractSetup={contractSetup}
        mode={mode}
      />
      <Not
        ui={ui}
        currentCard={currentCard}
        currentInputField={currentInputField}
        operator={operator}
        value={value}
        negated={negated}
        setNegated={setNegated}
        onValueChange={onValueChange}
        language={language}
        contractSetup={contractSetup}
        mode={mode}
      />
      <Value
        ui={ui}
        currentCard={currentCard}
        currentInputField={currentInputField}
        operator={operator}
        value={value}
        onValueChange={onValueChange}
        language={language}
        contractSetup={contractSetup}
        mode={mode}
      />
    </div>
  );
}

function InputField({
  contract,
  ui,
  currentCard,
  currentInputField,
  onInputFieldChange,
  language,
  mode,
  contractSetup,
}) {
  if (mode !== 'setup' && (!currentCard || !ui.inputs)) return null;

  let inputs_order;

  if (mode === 'setup' && contractSetup) {
    inputs_order = contractSetup.map((setupItem) => setupItem.id);
  } else {
    inputs_order = getAllCardInputFields(currentCard, ui, contract);
  }

  // console.log('Inputs order ? ', {inputs_order, contractSetup})

  if (!inputs_order) return null;
  return (
    <div>
      <Select
        onChange={onInputFieldChange}
        value={currentInputField || undefined}
        placeholder={<span>Select input field</span>}
      >
        {inputs_order.map((inputFieldName) => {
          let input;
          if (mode === 'setup' && contractSetup) {
            input = contractSetup.find((item) => item.id === inputFieldName) || null;
          } else {
            input = ui.inputs[inputFieldName];
          }
          if (!input || !input.type) {
            console.log('Missing input data for ', { inputFieldName });
            return null;
          }
          if (!eligibleInputs.includes(input.type)) return null;
          const label = (input.label && input.label[language]) || inputFieldName;
          return (
            <Select.Option value={inputFieldName} key={inputFieldName}>
              {label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

function Operators({
  ui,
  currentCard,
  currentInputField,
  operator,
  onOperatorChange,
  // language,
  mode,
  contractSetup,
}) {
  if (mode !== 'setup' && (!currentCard || !ui.inputs)) return null;

  let input;

  if (mode === 'setup' && contractSetup) {
    input = contractSetup.find((item) => item.id === currentInputField) || null;
  } else {
    input = (ui.inputs && ui.inputs[currentInputField]) || null;
  }

  if (!input) return null;

  const availableOperators = getAvailableOperators(input);

  return (
    <div>
      <Select onChange={onOperatorChange} value={operator}>
        {availableOperators.map((availableOperator) => {
          const label = getAvailableOperatorText(availableOperator);
          return (
            <Select.Option value={availableOperator} key={availableOperator}>
              {label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

function Not({
  ui,
  currentInputField,
  value,
  onValueChange,
  language,
  mode,
  contractSetup,
  negated,
  setNegated,
}) {
  return (
    <div>
      <Checkbox
        checked={negated}
        onChange={() => setNegated(!negated)}
        className="border rounded-sm"
        style={{
          margin: '5px',
          padding: '3px',
        }}
      >
        Not
      </Checkbox>
    </div>
  );
}

function Value({ ui, currentInputField, value, onValueChange, language, mode, contractSetup }) {
  let input;

  if (mode === 'setup' && contractSetup) {
    input = contractSetup.find((item) => item.id === currentInputField) || null;
  } else {
    input = (ui.inputs && ui.inputs[currentInputField]) || null;
  }

  if (!input) return null;

  if (input.type === 'select' || input.type === 'radio' || input.multiple) {
    let options;

    if (contractSetup) {
      options = input.options;
    } else {
      options = input.content;
    }

    if (!options) {
      console.log('Invalid input.');
      return '-XXX-';
    }
    return (
      <div>
        <Select
          value={value || undefined}
          onChange={onValueChange}
          placeholder={<span>Select input value</span>}
        >
          {options.map((item) => {
            if (item.disabled) return null;
            const label = (item.label && item.label[language]) || item;
            return (
              <Select.Option value={item.id} key={item.id}>
                {label}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  }

  if (input.multiple) {
    return (
      <div>
        <Input type="text" value={value} onChange={onValueChange} />
      </div>
    );
  }

  if (input.type === 'checkbox') {
    return (
      <div>
        <Checkbox
          checked={value}
          onChange={onValueChange}
          style={{
            margin: '5px',
          }}
        />
      </div>
    );
  }

  if (input.type === 'numeric' || input.type === 'numeric-steps') {
    return (
      <div>
        <InputNumber value={value} onChange={onValueChange} />
      </div>
    );
  }

  if (input.type === 'QA') {
  }

  return (
    <div>
      <Input type="text" value={value} onChange={onValueChange} />
    </div>
  );
}
