// import React from 'react'
import { DropTarget } from 'react-dnd'

function DropZone(props) {
    
    const { 
      connectDropTarget, 
      targetNode, 
      // id, 
      // dropFunc
     } = props;

    let ht = connectDropTarget(targetNode)
    return ht;
}


export default DropTarget(
  ['facility', 'guarantor', 'other', 'test'],
  {
    drop: (props, monitor, component) => {
      if(!props.dropFunc) return;
      props.dropFunc(props.id, monitor.getItem())
      // console.log('DROP!', props, monitor.getItem())
    }
    /*...*/
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(DropZone)