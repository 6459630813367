import React, { useState, useEffect } from 'react';
import { Button, Select, Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import api from 'utils/api';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { generateUID } from 'core/utils/general';
import { supportedLanguages } from 'lngProvider/';

function NewDocumentTemplateItem({ documentTemplateId }) {
  const [documentTemplate, setDocumentTemplate] = useState(null);
  const formatMessage = useIntlMessage();
  const history = useHistory();

  useEffect(() => {
    api
      .get('/documenttemplates/' + documentTemplateId)
      .then((res) => {
        if (res.data) {
          setDocumentTemplate(res.data);
        } else {
          console.log('Error in fetching template, ', res);
        }
      })
      .catch((err) => console.log('get err', err, err.response));
    return;
  }, [documentTemplateId]);

  const onFinish = (values) => {
    const newTemplate = {
      ...documentTemplate,
      id: undefined,
      name: values.name,
      description: values.description,
      isCoreTemplate: false,
    };
    newTemplate.data.settings.language = values.language;
    newTemplate.data.outline.id_name = values.idName;
    newTemplate.data.outline.shortName = values.shortName;

    api
      .post('/documenttemplates', newTemplate)
      .then((res) => {
        history.push(`/studio/template/${res.data.id}/input`);
      })
      .catch((err) => {
        console.log('Failed creating ', err.response);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('form values failed: ', errorInfo);
  };

  if (!documentTemplate) {
    return null;
  }

  const initialValues = {
    name: '',
    description: '',
    language: documentTemplate.data.settings.language,
    shortName: 'UISDPL',
    idName: `doc_${generateUID(4)}`,
  };

  return (
    <div>
      <div align={'center'}>
        <i className={'mdi mdi-settings-box'} style={{ fontSize: 48 }} />
      </div>
      <div align={'center'}>{documentTemplate.name}</div>
      <div align={'center'}>{documentTemplate.description}</div>
      <br />
      <Form
        initialValues={initialValues}
        layout="horizontal"
        labelAlign="left"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 24 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label={<IntlMessages id="app.template.name" />}
          name="name"
          rules={[{ required: true, message: formatMessage('app.template.nameMissing') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="app.template.description" />}
          name="description"
          rules={[{ required: true, message: formatMessage('app.template.descriptionMissing') }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={<IntlMessages id="app.template.language" />} name="language">
          <Select>
            {Object.entries(supportedLanguages).map(([key, { name }]) => (
              <Select.Option key={`language_${key}`} value={key}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="app.template.shortName" />}
          name="shortName"
          rules={[{ required: true, message: formatMessage('app.template.shortNameMissing') }]} // Fixa 18n
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={<IntlMessages id="app.template.idName" />}
          name="idName"
          rules={[{ required: true, message: formatMessage('app.template.idNameMissing') }]} // Fixa 18n
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <div align={'center'}>
            <Button type="primary" htmlType="submit">
              <IntlMessages id="app.template.createButton" />
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default NewDocumentTemplateItem;
