import { Contract } from 'core/interfaces';

function firstStudioPage(page) {
  const { pages, pages_order } = page;
  if (!pages || !Array.isArray(pages_order)) return '';
  const firstPage = pages[pages_order[0]];
  if (!firstPage) return '';
  if (firstPage.collapse) {
    return firstStudioPage(firstPage);
  }
  return firstPage.paths.toString();
}

export function getFirstStudioPage(contract) {
  const ui = Contract.getUi(contract);
  return firstStudioPage(ui);
}

export function findPageByStudioPage(contract, studioPage) {
  let target = contract.data.ui;
  const parts = studioPage.split(',');
  try {
    for (const part of parts) {
      target = target.pages[part];
    }
    return target;
  } catch (err) {
    console.log('Error in findPageByStudioPage', { studioPage, parts, err });
    return null;
  }
}
