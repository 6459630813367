import React, { memo } from 'react';
import { useContract } from 'hooks';
import { Contract } from 'core/interfaces';
import { useRulePassed } from './utils/hooks';
import RepeatableCard from './RepeatableCard';
import OrdinaryCard from './OrdinaryCard';

export default memo(function CardsContainer({ page, language }) {
  const contract = useContract();
  const ui = Contract.getUi(contract);
  const { cards } = page;

  return cards.map((cardName) => {
    if (!ui.cards[cardName]) return null;
    return <ACard key={cardName} card={ui.cards[cardName]} cardName={cardName} language={language} page={page} />;
  });
});

export function ACard(props) {
  const { card, cardName } = props;
  const contract = useContract();
  const passed = useRulePassed(card.acp);
  if (!passed) {
    console.log('card didnt pass', cardName);
    return null;
  }

  const isRepeatable = Contract.getUiIsCardRepeatable(contract, cardName);

  if (isRepeatable) return <RepeatableCard {...props} />;
  else return <OrdinaryCard {...props} />;
}
