import React, { useState } from 'react';
import { useContract } from 'hooks';
import { Contract } from 'core/interfaces';
import { RuleModal, ContractRulesEditor, PrintRule } from 'components/RuleEditor/';

export function ConditionActions({ onChange, linksInline, acp: givenAcp, ...rest }) {
  const [acp, setAcp] = useState(givenAcp || rest.item?.acp || null);

  const onChangeRule = (id) => {
    setAcp(id);
    if (typeof onChange === 'function') onChange(id);
  };

  const style = {
    display: 'flex',
    flexDirection: linksInline ? 'row' : 'column',
    justifyContent: 'space-between',
  };

  return (
    <div style={style} className={rest.className || ''}>
      <InputRuleEdit {...rest} acp={acp} onChange={onChangeRule} />
      <InputRuleChanger {...rest} acp={acp} onChange={onChangeRule} />
    </div>
  );
}

function InputRuleEdit({ title, ...rest }) {
  if (!rest.acp) return null;
  let title2 = !title ? 'Edit Rule' : title;
  return <InputRule type="edit" {...rest} title={title2} />;
}

function InputRuleChanger({ title, ...rest }) {
  const action = rest.acp ? 'Change' : 'Set';
  let title2 = !title ? `${action} Rule` : title;
  return <InputRule type="change" {...rest} title={title2} />;
}

function InputRule({
  type,
  title,
  item,
  acp: givenAcp,
  stringPath: inputStringPath,
  open: defaultOpen = false,
  onChange,
  onOpen,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const contract = useContract();
  const [acp, setAcp] = useState(givenAcp || item?.acp || null);

  const updateRuleid = (id) => {
    if (typeof onChange === 'function') onChange(id);
    setAcp(id);
  };

  const onSave = (rule, ruleId) => {
    if (typeof onChange === 'function') onChange(acp);
  };

  const onSaveAs = (rule, ruleId) => {
    updateRuleid(ruleId);
  };
  const onSelect = (ruleId) => {
    updateRuleid(ruleId);
  };

  const close = () => {
    setIsOpen(false);
  };
  const toggle = () => {
    if (!isOpen && typeof onOpen === 'function') onOpen();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <span className="link" onClick={toggle}>
        {title || type.toUpperCase()}
      </span>
      {isOpen && type === 'edit' && (
        <RuleModal
          contract={contract}
          id={acp}
          onSave={onSave}
          onSaveAs={onSaveAs}
          onOk={close}
          onCancel={close}
        />
      )}
      {isOpen && type === 'change' && (
        <ContractRulesEditor
          contract={contract}
          onSelect={onSelect}
          onClose={close}
          currentRuleId={acp}
          onSave={onSave}
          onSaveAs={onSaveAs}
        />
      )}
    </>
  );
}

export function PrintCondition({ item, acp: givenAcp }) {
  const contract = useContract();
  const acp = givenAcp || item?.acp;
  if (!acp) return <div>No condition</div>;
  const rule = Contract.getRule(contract, acp);
  return <PrintRule rule={rule.data} />;
}
