import React, { memo, useState } from 'react';
import { Button, Table, Card, Popconfirm, Tag, Dropdown, Menu } from 'antd';
import { Entity } from 'core/interfaces';
import api from 'utils/api';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import EditEntity, { AddAgentModal } from '../../../components/entity/EditEntity';
import { EntityEmails, RemoveModal } from 'components/ui';

const { Column } = Table;

export default memo(({ entity, updateEntity }) => {
  const [isAddingAgent, setIsAddingAgent] = useState(false);
  const [editingAgentEntityId, setEditingAgentEntityId] = useState(null);

  const formatMessage = useIntlMessage();

  const onFinishCallback = (data) => {
    const { status, referringEntity, errorMessage } = data;
    if (status !== 'success') {
      return console.log('Error: ', errorMessage);
    }
    console.log('Update success? ', data);
    if (referringEntity) {
      updateEntity(referringEntity);
    }
    if (editingAgentEntityId) setEditingAgentEntityId(null);
    if (isAddingAgent) setIsAddingAgent(false);
  };

  const disconnectPerson = (id) => {
    api
      .delete(`/entities/${id}/disconnectAgent?principalEntityId=${entity.id}`)
      .then(() => {
        const updatedEntity = {
          ...entity,
          Agents: entity.Agents.filter((e) => e.id !== id),
        };
        updateEntity(updatedEntity);
      })
      .catch((err) => {
        console.log('error disconnecting ', err);
      });
  };

  const config = {
    bordered: false,
    loading: !entity,
    pagination: { position: 'bottom' },
    size: 'default',
    expandedRowRender: false,
    showHeader: true,
    footer: false,
    scroll: undefined,
  };

  const renderTable = (dataSource, title, disconnectButton, hideCompanyColumn, noDataText, extra) => {
    // config.title = () => <h3>{title}</h3>;
    return (
      <Card title={title} extra={extra} className="light-container widget">
        <Table
          rowKey="id"
          className="table-responsive documents-table"
          {...config}
          dataSource={dataSource}
          locale={{ emptyText: noDataText || 'No data' }}
        >
          <Column
            title={<IntlMessages id="app.general.Name" />}
            dataIndex="firstName"
            key="firstName"
            render={(firstName, { lastName }) => `${firstName} ${lastName}`}
          />
          {!hideCompanyColumn && (
            <Column
              title={<IntlMessages id="app.general.Company" />}
              dataIndex="LegalPersons"
              key="LegalPersons"
              render={(value) => (Array.isArray(value) ? value.map((c) => c.name).join(', ') : null)}
            />
          )}
          <Column
            title={<IntlMessages id="app.general.Email" />}
            dataIndex="emails"
            key="emails"
            render={(value, record) => {
              return <EntityEmails entity={record} />;
            }}
          />
          <Column
            title={<IntlMessages id="app.general.capacities" cap />}
            // dataIndex="capacities"
            key="capacities"
            render={(value, record) => {
              const relation = Entity.getRelation(entity, record);
              if (!relation || !Array.isArray(relation.capacities)) return <em>Unknown</em>;
              return relation.capacities.map((capacity) => (
                <Tag color="purple" key={capacity}>
                  {capacity}
                </Tag>
              ));
            }}
          />
          <Column
            title={<IntlMessages id="app.general.Action" />}
            key="actions"
            render={(text, record) => (
              <span>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="edit">
                        <span onClick={() => setEditingAgentEntityId(record.id)}>
                          <IntlMessages id="app.persons.editPerson" />
                        </span>
                      </Menu.Item>
                      {disconnectButton && (
                        <Menu.Item key="remove">
                          <RemoveModal
                            okText={<IntlMessages id="app.persons.disconnect" cap />}
                            onConfirm={() => disconnectPerson(record.id)}
                            confirmText={
                              <>
                                <IntlMessages id="app.persons.disconnectConfirm" />
                                {` ${record.firstName} ${record.lastName} `}
                                <IntlMessages id="desc.from" />
                                {` ${entity.firstName}`}?
                              </>
                            }
                          >
                            <span>
                              <IntlMessages id="app.persons.disconnect" cap />
                            </span>
                          </RemoveModal>
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <span className="link ant-dropdown-link">
                    <i className="icon-btn fs-xxl mdi mdi-dots-vertical" />
                  </span>
                </Dropdown>
              </span>
            )}
          />
        </Table>
      </Card>
    );
  };

  return (
    <div>
      {entity.Agents &&
        renderTable(
          entity.Agents,
          `${formatMessage('app.entity.Agents', 'cap')}`,
          true,
          true,
          formatMessage('app.persons.noRealPersonsAssociated'),
          <Button type="primary" className="m-0" onClick={() => setIsAddingAgent(true)}>
            <IntlMessages id="app.persons.addPerson" />
          </Button>
        )}

      {editingAgentEntityId && (
        <EditEntity
          id={editingAgentEntityId}
          onCancel={() => setEditingAgentEntityId(null)}
          onFinishCallback={onFinishCallback}
          referringEntity={entity}
          relationType="Agent"
        />
      )}
      {isAddingAgent && (
        <AddAgentModal
          onCancel={() => setIsAddingAgent(false)}
          onFinishCallback={onFinishCallback}
          referringEntity={entity}
          excludedFromSearch={entity && entity.Agents ? entity.Agents.map((a) => a.id) : []}
        />
      )}
    </div>
  );
});
