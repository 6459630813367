import React, { useState, useEffect } from 'react';
import { Row, Col, Radio, Input, Checkbox, Alert } from 'antd';
import { useEntitySearch } from 'hooks';
import IntlMessages, { useIntlMessage } from 'util/IntlMessages';
import { SearchOutlined } from '@ant-design/icons';
import { generateDebouncer } from 'utils/api';
const debounce = generateDebouncer();

export default function EntitySearch({
  className = '',
  setResults,
  small = false,
  noDefaults = false,
  defaults = {},
  forceType = 'none',
  onChangeValue,
  showName = true,
  showTypes = true,
  showIsTopCo = true,
  showSearch = false,
  showIdentificationNumber = false,
  includePrincipalsAndAgents = false,
}) {
  const [search, setSearch] = useState('');
  const [searchInstructions, setSearchInstructions] = useState({
    isTopCo: defaults.isTopCo,
    name: defaults.name || '',
    type: forceType && forceType !== 'none' ? forceType : defaults.type || '',
    identificationNumber: '',
    search: '',
  });
  const [results, searchError] = useEntitySearch(searchInstructions, {
    defaultAll: !noDefaults,
    forceType,
    includePrincipalsAndAgents,
  });
  const formatMessage = useIntlMessage();

  useEffect(() => {
    if (!results) {
      return setResults([]);
    }
    setResults(results.filter(fastFilter));
  }, [search, results, setResults]);

  useEffect(() => {
    if (searchError) console.warn('Search error: ', searchError);
  }, [searchError]);

  const fastFilter = ({firstName, lastName, identificationNumber}) => {
    const name = `${firstName} ${lastName}`.toLowerCase()
    const lowerCaseSearch = search.toLocaleLowerCase()
    return name.includes(lowerCaseSearch) || identificationNumber.includes(lowerCaseSearch)
  }

  const onChange = (field, value) => {
    const newValues = { ...searchInstructions, [field]: value };
    if (typeof onChangeValue === 'function') {
      onChangeValue(field, value, newValues);
    }

    setSearchInstructions(newValues);
  };

  const onIsTopCoChange = (evt) => {
    onChange('isTopCo', evt.target.checked);
  };
  const onNameChange = (evt) => {
    onChange('name', evt.target.value);
  };
  const onTypeChange = (evt) => {
    onChange('type', evt.target.value);
  };
  const onIdentificationNumberChange = (evt) => {
    onChange('identificationNumber', evt.target.value);
  };
  const onSearchChange = (evt) => {
    setSearch(evt.target.value);
    debounce(() => onChange('search', evt.target.value));
  };

  let colTopSizes;
  let colBottomSizes;
  if (small) {
    colTopSizes = { lg: 24, md: 24, sm: 24 };
    colBottomSizes = { lg: 12, md: 24, sm: 24 };
  } else {
    colTopSizes = { lg: 6, md: 12, sm: 12 };
    colBottomSizes = { lg: 12, md: 24, sm: 24 };
  }

  return (
    <>
      <Row className={className}>
        {showName && (
          <Col {...colTopSizes}>
            <Input
              allowClear
              value={searchInstructions.name}
              placeholder={formatMessage('app.general.Name', 'cap')}
              onChange={onNameChange}
            />
          </Col>
        )}
        {showIdentificationNumber && (
          <Col {...colTopSizes}>
            <Input
              allowClear
              value={searchInstructions.identificationNumber}
              placeholder={formatMessage('app.general.IdentificationNumber', 'cap')}
              onChange={onIdentificationNumberChange}
            />
          </Col>
        )}
        {showIsTopCo && (
          <Col {...colBottomSizes}>
            <Checkbox
              checked={searchInstructions.isTopCo}
              onChange={onIsTopCoChange}
            >
              <IntlMessages id="app.general.entity.onlyGroupParents" />
            </Checkbox>
          </Col>
        )}
        {showTypes && (
          <Col {...colBottomSizes} className="d-flex align-items-center pl-2">
            <Radio.Group onChange={onTypeChange} value={searchInstructions.type}>
              <Radio value={''}>
                <IntlMessages id="app.general.allTypes" cap />
              </Radio>
              <Radio value={'LegalPerson'}>
                <IntlMessages id="app.persons.LegalPerson" cap />
              </Radio>
              <Radio value={'RealPerson'}>
                <IntlMessages id="app.persons.RealPerson" cap />
              </Radio>
            </Radio.Group>
          </Col>
        )}
      </Row>
      {showSearch && (
        <Input
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder={formatMessage('desc.Search') + '...'}
          value={search}
          onChange={onSearchChange}
          style={{ maxWidth: 300 }}
          allowClear
        />
      )}
      {searchError && <Alert type="error" message="Search error. Please try again." />}
    </>
  );
}
