import { getContractValues } from 'hooks';
import { Contract, InputPaths } from 'core/interfaces';
import { ocount } from 'core/utils/general';

const defaultNames = {
  en: {
    defaultFacilityName: 'Facility',
    defaultFacilityPrefix: 'Facility',
    defaultFacilityNameRCF: 'Revolving Facility',
    defaultFacilityNameTL: 'Term Facility',
    defaultSingleFacilityPrefix: 'the',

    defaultCommitmentName: 'Commitment',
    defaultCommitmentPrefix: 'Commitment',
    defaultCommitmentNameRCF: 'Revolving Commitment',
    defaultCommitmentNameTL: 'Term Commitment',
    defaultSingleCommitmentPrefix: 'the',
  },
  sv: {
    defaultFacilityName: 'Faciliteten',
    defaultFacilityPrefix: 'Facilitet',
    defaultFacilityNameRCF: 'Revolverande Faciliteten',
    defaultFacilityNameTL: 'Periodfaciliteten',
    defaultSingleFacilityPrefix: '',

    defaultCommitmentName: 'Åtagandet',
    defaultCommitmentPrefix: 'Åtagande',
    defaultCommitmentNameRCF: 'Revolverande Åtagandet',
    defaultCommitmentNameTL: 'Periodåtagandet',
    defaultSingleCommitmentPrefix: '',
  },
};

const nameFacilities = (data, state) => {
  const { input } = state;

  // Check if there is a facility entry amongst inputs
  if (!input.facility || ocount(input.facility) === 0) return;
  const contract = getContractValues();
  const language = Contract.getLanguage(contract);

  const uidsAndValues = data.reduce((acc, curr) => {
    if (curr.fieldName !== 'facility/type') return acc;
    acc[curr.uid] = curr.value;
    return acc;
  }, {});

  // Call the name generation function using the facility information (input.facility)
  const nameData = generateNames(input.facility, language, uidsAndValues);
  // Return early if no names generated
  if (!nameData || ocount(nameData) === 0) return;

  const payloads = [];
  for (const uid in nameData) {
    const newNames = nameData[uid];
    if (newNames.name)
      payloads.push({
        path: InputPaths.construct('input', 'facility', uid, 'facility/name'),
        value: newNames.name,
      });
    if (newNames.comname)
      payloads.push({
        path: InputPaths.construct('input', 'facility', uid, 'facility/comname'),
        value: newNames.comname,
      });
  }
  return payloads;
};

const generateNames = (facilities, language, uidsAndValues) => {
  let numOfFacilities = Object.keys(facilities).length;
  // If only one facility, set the default name
  if (numOfFacilities === 1)
    return {
      [Object.keys(facilities)[0]]: {
        name: defaultNames[language].defaultFacilityName,
        comname: defaultNames[language].defaultCommitmentName,
      },
    };

  const names = {};

  // Multiple facilities, generate names accordingly
  let atoz = range('A', 'Z');
  let numOfType = { term: 0, rcf: 0 };
  let occupiedNames = [];
  for (const uid in facilities) {
    // for(let i = 0;i<numOfFacilities;i++) {
    let facility = facilities[uid];
    let type = uidsAndValues[uid] ? uidsAndValues[uid] : facility['facility/type'];
    numOfType[type]++;
  }
  for (const uid in facilities) {
    // for(let i = 0;i<numOfFacilities;i++) {
    let facility = facilities[uid];

    let type = uidsAndValues[uid] ? uidsAndValues[uid] : facility['facility/type'];
    if (type === 'term') {
      // If alone,
      if (numOfType.term === 1) {
        names[uid] = {
          name: defaultNames[language].defaultFacilityNameTL,
          comname: defaultNames[language].defaultCommitmentNameTL,
        };
        continue;
      } else {
        for (let j = 0; j < atoz.length; j++) {
          let testName = defaultNames[language].defaultFacilityPrefix + ' ' + atoz[j];
          if (occupiedNames.indexOf(testName) > -1) {
            continue;
          }
          names[uid] = {
            name: testName,
            comname: defaultNames[language].defaultCommitmentPrefix + ' ' + atoz[j],
          };
          occupiedNames.push(testName);
          break;
        }
      }
    } else if (type === 'rcf') {
      if (numOfType.rcf === 1) {
        names[uid] = {
          name: defaultNames[language].defaultFacilityNameRCF,
          comname: defaultNames[language].defaultCommitmentNameRCF,
        };
        continue;
      } else {
        for (let j = 0; j < atoz.length; j++) {
          let testName = defaultNames[language].defaultFacilityPrefix + ' ' + atoz[j];

          if (occupiedNames.indexOf(testName) > -1) {
            continue;
          }
          names[uid] = {
            name: testName,
            comname: defaultNames[language].defaultCommitmentPrefix + ' ' + atoz[j],
          };
          occupiedNames.push(testName);
          break;
        }
      }
    } else {
      names[uid] = {
        name: '',
        comname: '',
      };
    }
  }
  return names;
};

const range = (first, last) => {
  var a = first.charCodeAt(0);
  var b = last.charCodeAt(0) + 1;
  return Array.apply(null, { length: Math.abs(b - a) }).map(function (x, i) {
    return String.fromCharCode(Math.min(a, b) + i);
  });
};

export default nameFacilities;
