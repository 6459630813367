import React, { useEffect, useState, useCallback, memo } from 'react';
import DocumentHeader from 'components/project/DocumentHeader/index';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';
import { setDraftValues } from '../../../appRedux/actions/Draft';
import { useMountFetch, useFetch, setContract } from 'hooks';
import { ContractContext, ContractContentContext, ContractStateContext } from 'contexts/contexts';
import { sortByCreatedAt } from 'core/utils/general';
import DocumentOverview from './Overview/DocumentOverview';
import ConnectedState from 'yjs/State/ConnectedState';
import api from '../../../utils/api';

/* import { createBaseEditor } from 'core/utils/editor';
import { EditorWrapper } from 'components/YjsEditor/EditorWrapper'; */

export function getLatestVersion(versions, defaultSelectedVersionId) {
  if (!Array.isArray(versions)) return null;
  if (defaultSelectedVersionId) {
    return versions.find((v) => v.id === defaultSelectedVersionId);
  }
  return versions.sort(sortByCreatedAt)[0] || null;
}

export function getActiveVersion(versions, id) {
  return versions.find((v) => v.id === id);
}

function useCurrentVersionAccessLevels(currentVersion) {
  const dispatch = useDispatch();
  const url = currentVersion && currentVersion.id ? `/versions/${currentVersion.id}/accessLevels` : '';
  const [accessLevels] = useFetch(url);
  useEffect(() => {
    if (!accessLevels) return;
    dispatch(setDraftValues(Object.entries(accessLevels)));
  }, [accessLevels, dispatch]);
}

const Document = memo(({ project, documentId, document, setDocument, defaultSelectedVersionId }) => {
  const [versions, setVersions] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);

  // const [editor] = useState(createBaseEditor());

  useCurrentVersionAccessLevels(currentVersion);

  useMountFetch(
    `/documents/${documentId}/versions?fields=id,name,data,description,status,info,documentTemplateId,createdAt,updatedAt&order=${encodeURI(
      'desc|createdAt'
    )}`,
    setVersions
  );

  const addVersion = useCallback(
    (newVersion, selectNewVersion = true) => {
      setVersions([newVersion, ...versions]);
      if (selectNewVersion) setCurrentVersion(newVersion);
    },
    [versions]
  );

  const selectVersion = useCallback(
    (id) => {
      const selected = versions.find((v) => v.id === id);
      if (!selected) {
        notification.error({
          message: 'Invalid version',
        });
        return null;
      }
      setCurrentVersion(selected);
    },
    [versions]
  );

  useEffect(() => {
    if (!currentVersion) return;
    setContract(currentVersion);
  }, [currentVersion]);

  useEffect(() => {
    if (currentVersion || !document || !versions) return false;
    const active = getActiveVersion(versions, defaultSelectedVersionId || document.activeVersionId);
    setCurrentVersion(active);
  }, [defaultSelectedVersionId, currentVersion, versions, document]);

  useEffect(() => {
    // Move to backend.
    if (!document || !versions) return;
    if (document.activeVersionId) return;
    const latestVersion = getLatestVersion(versions);
    (async () => {
      try {
        await api.post('/documents/' + document.id + '/activateVersion', {
          activeVersionId: latestVersion.id,
        });
        setDocument({ activeVersionId: latestVersion.id });
      } catch (err) {
        console.log('err setting active version ', err);
      }
    })();
  }, [document, setDocument, versions]);

  const [versionState] = useFetch(currentVersion ? `/versions/${currentVersion.id}/state` : '');
  const [versionContent] = useFetch(currentVersion ? `/versions/${currentVersion.id}/content` : '');

  return (
    <div className="version-view-container">
      <ContractContext.Provider initialValue={currentVersion}>
        <ContractContentContext.Provider value={versionContent}>
          <ContractStateContext.Provider value={versionState}>
            {/* <EditorWrapper editor={editor} onlineMode={true}> */}
              <ConnectedState versionId={currentVersion && currentVersion.id}>
                <DocumentHeader
                  project={project}
                  doc={document}
                  versions={versions}
                  currentVersion={currentVersion}
                  selectVersion={selectVersion}
                  setDocument={setDocument}
                  addVersion={addVersion}
                />
                <DocumentOverview
                  project={project}
                  doc={document}
                  setDocument={setDocument}
                  currentVersion={currentVersion}
                  selectVersion={selectVersion}
                />
              </ConnectedState>
            {/* </EditorWrapper> */}
          </ContractStateContext.Provider>
        </ContractContentContext.Provider>
      </ContractContext.Provider>
    </div>
  );
});

export default Document;
