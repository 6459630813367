import jsonLogic from 'json-logic-js';
import { Contract, Concept } from '../../interfaces';

function getJsonLogicData(path, data, fullState) {
  if (fullState && fullState._meta && fullState._meta.contract) {
    const { contract } = fullState._meta;
    const concept = Contract.getConcept(contract, path);
    if (concept) {
      const conceptState = Concept.getConceptState(concept, contract, fullState);
      return { match: conceptState || {} };
    }
  }

  var not_found = { err: null }; // Removed the possibility to return default value b
  if (typeof data !== 'object' || typeof path === 'undefined' || path === '' || path === null) {
    return { err: data };
  }
  var sub_props = String(path).split('.');
  for (var i = 0; i < sub_props.length; i++) {
    if (data === null) {
      return not_found;
    }
    // Descending into data
    data = data[sub_props[i]];
    if (data === undefined) {
      return not_found;
    }
  }
  if (Array.isArray(data)) return { match: data };
  else if (typeof data === 'object') {
    return {
      match: Object.keys(data)
        .filter((item) => item.substr(0, 7) !== '__data_')
        .reduce((acc, curr) => {
          acc[curr] = data[curr];
          return acc;
        }, {}),
    };
  }
  return { match: data };
}

// {"==":[{"input":"pricing.upfrontPaymentOn"},"signing"]}
export function input(path) {
  if (!this.input) return null;
  var data = getJsonLogicData(path, this.input);
  if (data.err || !data.hasOwnProperty('match')) return null;
  return data.match;
}

// {"==":[{"setup":"financingType"},"propertyFinancing"]}
export function setup(path) {
  const setup = Contract.getSetup(this._meta.contract);
  if (!setup) return null;
  var data = getJsonLogicData(path, setup);
  if (data.err || !data.hasOwnProperty('match')) return null;
  return data.match;
}

// {"==":[{"local":"type"},"rcf"]}
export function local(path) {
  if (!this._meta || !this._meta.data || !this._meta.data.local) return null;
  var data = getJsonLogicData(path, this._meta.data.local);
  if (data.err || !data.hasOwnProperty('match')) return null;
  // console.log('Local logic is ', data.match)
  return data.match;
}

export function length(instruction) {
  const data = jsonLogic.apply(instruction, this);
  if (!data) return 0;
  if (Array.isArray(data)) return data.length;
  if (typeof data === 'object') return Object.keys(data).length;
  return 0;
}

export function card(card) {
  // console.log('Get card ', card)
  var data = getJsonLogicData(card, this.input, this);
  if (!data || data.err || !data.match || typeof data.match !== 'object' || Array.isArray(data.match)) {
    return null;
  }

  return data.match;
}

export function numof(path) {
  if (!this.input || !path) return null;

  let cardValues, condition;

  if (path.hasOwnProperty('card') && typeof path.card === 'string') {
    cardValues = jsonLogic.apply({ card: path.card }, this);
    condition = path.condition;
  } else {
    cardValues = path;
  }

  if (!cardValues || typeof cardValues !== 'object') return false;

  let count = 0;
  if (condition) {
    for (const rep_item of Object.values(cardValues)) {
      if (jsonLogic.apply(condition, rep_item)) {
        count++;
      }
    }
  } else {
    count = Object.keys(cardValues).length;
  }

  return count;
}

export function collection(collection) {
  var data = getJsonLogicData(collection, this, this);
  if (!data || data.err || !data.match || (typeof data.match !== 'object' && !Array.isArray(data.match))) {
    return null;
  }
  return data.match;
}

export function numof_state(path) {
  if (!this.input || !path) return null;

  let collectionValues, condition;

  if (path.hasOwnProperty('collection') && typeof path.collection === 'string') {
    collectionValues = jsonLogic.apply({ collection: path.collection }, this);
    condition = path.condition;
  } else {
    collectionValues = path;
  }

  if (!collectionValues || typeof collectionValues !== 'object') return false;

  let count = 0;
  if (condition) {
    for (const rep_item of Object.values(collectionValues)) {
      if (jsonLogic.apply(condition, rep_item)) {
        count++;
      }
    }
  } else {
    count = Object.keys(collectionValues).length;
  }

  return count;
}

// { '==': [{ any_in_card: ['activeCovenants', true] }, true] },

export function any_in_card(path, value) {
  if (!this.input || !path) return null;

  var data = getJsonLogicData(path, this.input);
  if (data.err || !data.match || typeof data.match !== 'object' || Array.isArray(data.match)) return null;

  return Object.values(data.match).some((item) => item === value);
}

// { '==': [{ all_in_card: ['activeCovenants', true] }, true] },
export function all_in_card(path, value) {
  if (!this.input || !path) return null;

  var data = getJsonLogicData(path, this.input);
  if (data.err || !data.match || typeof data.match !== 'object' || Array.isArray(data.match)) return null;

  return Object.values(data.match).every((item) => item === value);
}

// Might be implemented for Q and A functionality.
/* export function like(search, matches) {
  if (typeof search !== 'string' || matches === null) {
    return false;
  }
  // Remove special chars
  search = search.replace(
    new RegExp('([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])', 'g'),
    '\\$1'
  );
  // Replace % and _ with equivalent regex
  search = search.replace(/%/g, '.*').replace(/_/g, '.');
  // Check matches
  return RegExp('^' + search + '$', 'gi').test(matches);
}

export function qAndA(...args) {
  console.log('qAndA args ', { args, thisX: this });
  return null;
}
 */

// Might be useful. NOTE: Work in Progress.
/* 

export function objectValues(x) {
  console.log('Obj Values...', this, x);
  if (!this[x]) return this;
  const target = this[x];
  if (target && typeof target === 'object' && !Array.isArray(target)) {
    console.log('Yes array is ', Object.values(target));
  }
  if (target && typeof target === 'object' && !Array.isArray(target)) return Object.values(target);
  return target;
}

function getObjectDataAsArray(a, data) {
  var not_found = null;
  if (typeof a === 'undefined' || a === '' || a === null) {
    return data;
  }
  var sub_props = String(a).split('.');
  for (var i = 0; i < sub_props.length; i++) {
    if (data === null) {
      return not_found;
    }
    // Descending into data
    data = data[sub_props[i]];
    if (data === undefined) {
      return not_found;
    }
  }
  if (!!data && !Array.isArray(data) && typeof data === 'object') return Object.values(data);
  return data;
}

export function varValues(a, b) {
  var not_found = b === undefined ? null : b;
  var data = this;
  if (typeof a === 'undefined' || a === '' || a === null) {
    return data;
  }
  var sub_props = String(a).split('.');
  for (var i = 0; i < sub_props.length; i++) {
    if (data === null) {
      return not_found;
    }
    // Descending into data
    data = data[sub_props[i]];
    if (data === undefined) {
      return not_found;
    }
  }
  if (!!data && !Array.isArray(data) && typeof data === 'object') return Object.values(data);
  return data;
}

export function firstSet(a, b) {
  const arrayData = getObjectDataAsArray(a, this);
  if (!Array.isArray(arrayData)) return null;
  return arrayData[0];
}
export function firstSetItem(a, b) {
  const arrayData = getObjectDataAsArray(a, this);
  if (!Array.isArray(arrayData)) return null;
  return (arrayData[0] && arrayData[0][b]) || null;
} */
