/**
 * Enable deferring of state updates.
 */

const deferPredicate = (action) => {
  if (action.meta?.actionsToFollow) return true;
  return false;
};

const statePairFactory = () => {
  const deferredPreviousState = {
    data: null,
  };

  const get = () => deferredPreviousState.data;
  const clear = () => (deferredPreviousState.data = null);
  const defer = (states) => {
    if (!deferredPreviousState.data) deferredPreviousState.data = states.previous;
  };
  const hasDeferred = () => !!deferredPreviousState.data;

  return (action, states) => {
    if (deferPredicate(action)) {
      defer(states);
      return null;
    }
    if (hasDeferred()) {
      const previous = get();
      clear();
      return {
        previous,
        current: states.current,
      };
    }
    return states;
  };
};

export const statePairs = statePairFactory();
