/* Editor */
import Editor from './Editor'

/**
 * 
 * @param {*} param0 
 * @returns another editor that isn't main editor... 
 */
const SubEditor = ({show, hide, parentId, editor, defaultValue, id, type}) => {

    return <>{show && <Editor hide={hide} editor={editor} parentId={parentId} defaultValue={defaultValue} id={id} type={type} />}</>
}

export default SubEditor