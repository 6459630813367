import React from "react";

function InputHR(props) {
  return (
    <div
      className="mt-2 border-top pt-2 mb-2 w-100"
      style={{ borderColor: "#dee2e6" }}
    />
  );
}

export default InputHR;
