import React, { useEffect, useMemo, useCallback } from 'react';
import { Form, Button, notification } from 'antd';
import { useMountedState } from 'hooks';
import IntlMessages from 'util/IntlMessages';
import { fetchEntity } from '../../../utils/entities';
import { EntityEditFields } from './components';
import {
  getInitialValues,
  entityFromValues,
  updateExistingEntityFn,
  createNewEntityFn,
  useEntitySideEffects,
  addOrUpdateRelation,
  removeRelation,
} from './utils';

function fetchAndSetEntity(id, suppliedEntity, setEntity) {
  (async () => {
    if (suppliedEntity) return;
    const data = await fetchEntity(id);
    setEntity(data);
  })();
}

export default function Edit({
  redux = false,
  formId,
  id,
  entity: suppliedEntity,
  referringEntity,
  onCancel,
  withButtons = false,
  placement,
  onFinishCallback,
  defaultNewValues = {},
  saveIntlId = 'desc.Save',
  ...restProps
}) {
  const { relationType } = restProps;
  const [entity, setEntity] = useMountedState(suppliedEntity || null);
  const [form] = Form.useForm();
  const sideEffects = useEntitySideEffects({ redux });
  useEffect(() => fetchAndSetEntity(id, suppliedEntity, setEntity), [suppliedEntity, id, setEntity]);
  const initialValues = useMemo(
    () => getInitialValues(id, entity, referringEntity, relationType, defaultNewValues),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entity]
  );

  const addAgent = useCallback(
    (agentEntity, capacities = []) => {
      if (!entity) return console.warn('Cannot add agent to entity which does not yet exist');
      const { updatedPrincipalEntity, updatedAgentEntity } = addOrUpdateRelation(
        entity,
        agentEntity,
        capacities
      );
      sideEffects.add({ action: 'CONNECT_AGENT', targetEntity: updatedAgentEntity, capacities });
      setEntity(updatedPrincipalEntity);
    },
    [entity, setEntity, sideEffects]
  );
  const setAgentCapacities = useCallback(
    (agentEntity, capacities = []) => {
      if (!entity) return console.warn('Cannot set agent capacities for entity which does not yet exist');
      const { updatedPrincipalEntity, updatedAgentEntity } = addOrUpdateRelation(
        entity,
        agentEntity,
        capacities
      );
      sideEffects.add({ action: 'UPDATE_AGENT_CAPACITIES', targetEntity: updatedAgentEntity, capacities });
      setEntity(updatedPrincipalEntity);
    },
    [entity, setEntity, sideEffects]
  );
  const removeAgent = useCallback(
    (agentEntity) => {
      if (!entity) return console.warn('Cannot remove agent for entity which does not yet exist');

      const { updatedPrincipalEntity, updatedAgentEntity } = removeRelation(entity, agentEntity, 'Agent');

      sideEffects.add({ action: 'DISCONNECT_AGENT', targetEntity: updatedAgentEntity });
      setEntity(updatedPrincipalEntity);
    },
    [entity, setEntity, sideEffects]
  );
  const addPrincipal = useCallback(
    (principalEntity, capacities = []) => {
      if (!entity) return console.warn('Cannot add principal for entity which does not yet exist');
      const { updatedPrincipalEntity, updatedAgentEntity } = addOrUpdateRelation(
        principalEntity,
        entity,
        capacities
      );
      sideEffects.add({ action: 'CONNECT_PRINCIPAL', targetEntity: updatedPrincipalEntity, capacities });
      setEntity(updatedAgentEntity);
    },
    [entity, setEntity, sideEffects]
  );
  const setPrincipalCapacities = useCallback(
    (principalEntity, capacities = []) => {
      if (!entity) return console.warn('Cannot set principal capacities for entity which does not yet exist');

      const { updatedPrincipalEntity, updatedAgentEntity } = addOrUpdateRelation(
        principalEntity,
        entity,
        capacities
      );
      sideEffects.add({
        action: 'UPDATE_PRINCIPAL_CAPACITIES',
        targetEntity: updatedPrincipalEntity,
        capacities,
      });
      setEntity(updatedAgentEntity);
    },
    [entity, setEntity, sideEffects]
  );
  const removePrincipal = useCallback(
    (principalEntity) => {
      if (!entity) return console.warn('Cannot remove principal for entity which does not yet exist');

      const { updatedPrincipalEntity, updatedAgentEntity } = removeRelation(
        entity,
        principalEntity,
        'Principal'
      );

      sideEffects.add({ action: 'DISCONNECT_PRINCIPAL', targetEntity: updatedPrincipalEntity });
      setEntity(updatedAgentEntity);
    },
    [entity, setEntity, sideEffects]
  );

  // If editing, but no entity object
  if (id && !entity) return null;

  const updateExistingEntity = async (values, triggerCallback) => {
    return updateExistingEntityFn(entity, values, {
      onFinishCallback,
      triggerCallback,
      referringEntity,
      relationType,
    });
  };

  const createNewEntity = async (values, triggerCallback) => {
    return createNewEntityFn(values, {
      onFinishCallback,
      triggerCallback,
    });
  };

  const save = async (manual = true) => {
    try {
      await form.validateFields();
    } catch (err) {
      if (manual) {
        notification.error({
          message: 'Cannot save',
          description: 'Please fill in all the required fields',
        });
      }
      return;
    }

    const triggerCallback = !manual;
    const allValues = await form.getFieldsValue();
    const entityValues = entityFromValues(allValues);
    const isUpdate = !!entity;

    const saveFn = isUpdate ? updateExistingEntity : createNewEntity;
    const result = await saveFn(entityValues, triggerCallback).catch(onFinishFailed);

    // console.log('Result is ', result);

    const { currentEntity } = await sideEffects.execute(result.entity);
    setEntity(currentEntity);

    if (triggerCallback && onFinishCallback) {
      onFinishCallback({
        ...result,
        entity: currentEntity,
      });
    }

    return result;
  };

  const onFinish = async () => {
    await save(false);
    onCancel();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('finish failed ', {
      entity,
      referringEntity,
      errorInfo,
    });
  };

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name={formId}
        initialValues={{
          ...(entity ? entity : defaultNewValues || {}),
          ...initialValues,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <EntityEditFields
          entity={entity}
          referringEntity={referringEntity}
          form={form}
          save={save}
          // formId={formId}
          type={initialValues.types}
          defaultNewValues={defaultNewValues}
          addAgent={addAgent}
          removeAgent={removeAgent}
          addPrincipal={addPrincipal}
          removePrincipal={removePrincipal}
          setAgentCapacities={setAgentCapacities}
          setPrincipalCapacities={setPrincipalCapacities}
          {...restProps}
        />
        {withButtons && (
          <div className={'m-3 float-' + (placement === 'left' ? 'right' : 'left')}>
            <Button onClick={onCancel}>
              <IntlMessages id="desc.Cancel" />
            </Button>
            <Button type="primary" htmlType="submit">
              <IntlMessages id={saveIntlId} cap />
            </Button>
          </div>
        )}
      </Form>
    </>
  );
}
